import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { IPickerData } from 'src/app/models_new/types/picker-data';
import { ClientApiService } from './client-api.service';
import { HardwareApiService } from './hardware-api.service';
import { PatternApiService } from './pattern-api.service';
import { ProdlineApiService } from './prodline-api.service';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { ProductApiService } from './product-api.service';
import { IProductionLineData } from 'src/app/models_new/classes/api-models/ApiProduction_line';
import { RobotConfigApiService } from './robot-config-api.service';

@Injectable({
  providedIn: 'root',
})
export class PickerApiService {
  constructor(
    private clientApi: ClientApiService,
    private hardwareApi: HardwareApiService,
    private patternApi: PatternApiService,
    private productApi: ProductApiService,
    private prodlineApi: ProdlineApiService,
    private softwareApi: SoftwareApiService,
    private robotApi: RobotConfigApiService
  ) {}

  fetchDataForType(
    type: PickerType,
    org_id: string
  ): Observable<IPickerData[]> {
    switch (type) {
      case PickerType.PRODUCTION_LINE:
        return this.prodlineApi.fetchProductionLines(org_id);
      case PickerType.PRODUCT:
        return this.productApi.fetchProductsLight(org_id);
      case PickerType.HARDWARE_CONFIGURATION:
        return this.hardwareApi.fetchHardwares(org_id);
      case PickerType.PATTERN:
        return this.patternApi.fetchInventoryPatterns(org_id);
      case PickerType.SOFTWARE_CONFIGURATION:
        return this.softwareApi.fetchStrategies(org_id);
      case PickerType.ROBOT_CONFIGURATION:
        return this.robotApi.fetchRobotconfigs();
      case PickerType.ROBOT_LICENSE:
        throw new Error('Not implemented');
    }
  }

  removeFromCacheWithId(id: string, field: string) {
    const cache = this.clientApi.useClient('org_view').client.cache;
    let fields: Record<string, any> = {};
    fields[field] = (existing: any[], { readField }: any) => {
      return existing.filter((ref) => readField('id', ref) != id);
    };
    cache.modify({
      id: 'ROOT_QUERY',
      fields: fields,
    });
  }

  modifyInCacheWithId(id: string, field: string, changes: object) {
    const cache = this.clientApi.useClient('org_view').client.cache;

    const newHwRef = cache.writeFragment({
      data: changes,
      fragment: gql`
        fragment Update on ${field} {
          id
          name
          data
        }
      `,
    });

    let fields: Record<string, any> = {};
    fields[field] = (existing: any[], { readField }: any) => {
      return existing.map((ref) =>
        readField('id', ref) == id ? newHwRef : ref
      );
    };

    cache.modify({
      id: 'ROOT_QUERY',
      fields: fields,
    });
  }

  removeForTypeWithId(type: PickerType, id: string): Observable<boolean> {
    switch (type) {
      case PickerType.PRODUCTION_LINE:
        return this.prodlineApi.deleteProdline(id).pipe(
          map((prodline) => {
            const success = prodline !== null;
            if (success) this.removeFromCacheWithId(id, 'production_line');
            return success;
          })
        );
      case PickerType.PRODUCT:
        return this.productApi.deleteProduct(id).pipe(
          map((product) => {
            const success = product !== null;
            if (success) this.removeFromCacheWithId(id, 'product');
            return success;
          })
        );
      case PickerType.HARDWARE_CONFIGURATION:
        // I believe these (name) will throw if already deleted, or failure
        return this.hardwareApi.deleteSceneById(id).pipe(
          map((name) => {
            const success = name !== null;
            if (success) this.removeFromCacheWithId(id, 'scene');
            return success;
          })
        );
      case PickerType.PATTERN:
        return this.patternApi.deletePatternById(id).pipe(
          map((name) => {
            const success = name !== null;
            if (success) this.removeFromCacheWithId(id, 'pattern');
            return success;
          })
        );
      case PickerType.SOFTWARE_CONFIGURATION:
        return this.softwareApi.deleteStrategyById(id).pipe(
          map((name) => {
            const success = name !== null;
            if (success) this.removeFromCacheWithId(id, 'strategy');
            return success;
          })
        );
      case PickerType.ROBOT_CONFIGURATION:
        return this.robotApi.deleteRobotConfig(id).pipe(
          map((robotConfig) => {
            const success = robotConfig !== null;
            if (success) this.removeFromCacheWithId(id, 'robot_configuration');
            return success;
          })
        );
      case PickerType.ROBOT_LICENSE:
        throw new Error('Not implemented');
    }
  }

  editForTypeWidthId(
    type: PickerType,
    id: string,
    changes: IPickerData
  ): Observable<boolean> {
    switch (type) {
      case PickerType.PRODUCTION_LINE:
        return this.prodlineApi
          .updateProductionLine(
            id,
            changes.name,
            changes.data as IProductionLineData
          )
          .pipe(
            map((result) => {
              let success = result !== null;
              if (success)
                this.modifyInCacheWithId(id, 'production_line', changes);
              return success;
            })
          );
      case PickerType.PRODUCT:
        console.debug(changes.name, changes.data);
        return this.productApi
          .updateProduct(id, changes.name, changes.data)
          .pipe(
            map((result) => {
              let success = result !== null;
              if (success) this.modifyInCacheWithId(id, 'product', changes);
              return success;
            })
          );
      case PickerType.HARDWARE_CONFIGURATION:
        return this.hardwareApi
          .updateScene(
            id,
            changes.name,
            changes['description'] ? changes['description'] : null,
            changes.data
          )
          .pipe(
            map((result) => {
              let success = result !== null;
              if (success) this.modifyInCacheWithId(id, 'scene', changes);
              return success;
            })
          );
      case PickerType.PATTERN:
        changes.data['name'] = changes.name;
        return this.patternApi
          .updatePattern(
            id,
            changes.data,
            changes['product_id'] ? changes['product_id'] : null
          )
          .pipe(
            map((result) => {
              let success = result !== null;
              if (success) this.modifyInCacheWithId(id, 'pattern', changes);
              return success;
            })
          );
      case PickerType.SOFTWARE_CONFIGURATION:
        return this.softwareApi
          .updateStrategy(
            id,
            changes.name,
            changes['description'] ? changes['description'] : null,
            changes.data
          )
          .pipe(
            map((result) => {
              let success = result !== null;
              if (success) this.modifyInCacheWithId(id, 'strategy', changes);
              return success;
            })
          );
      case PickerType.ROBOT_CONFIGURATION:
        throw new Error('Not implemented');
      case PickerType.ROBOT_LICENSE:
        throw new Error('Not implemented');
    }
  }
}
