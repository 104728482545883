import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hardware-dialog',
  templateUrl: './hardware-dialog.component.html',
  styleUrls: ['./hardware-dialog.component.scss'],
})
export class HardwareDialogComponent {
  existingHwId: string = undefined;
  constructor(
    public dialogRef: MatDialogRef<HardwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.data) {
      this.existingHwId = data.data.hw_id;
    }
  }

  saveHwConfig(event: any) {
    this.dialogRef.close(event);
  }
}
