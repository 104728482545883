import { LocalStorageService } from 'src/app/services/local-storage.service';
import { getConvertedValueAndUnit } from '../../utils/unit-utils';
import { LocalStorageKey } from '../enums/local-storage-keys';

export interface IStandardPallet {
  name: string;
  width: number;
  length: number;
  palletHeight: number;
  loadHeight: number;
  totalHeight?: number;
}

export class StandardPallet implements IStandardPallet {
  name: string;
  width: number;
  length: number;
  palletHeight: number;
  loadHeight: number;
  totalHeight?: number;

  constructor(
    obj: IStandardPallet,
    private localStorageService: LocalStorageService
  ) {
    for (const key of Object.keys(obj)) {
      this[key] = obj[key];
    }
  }

  get palletOptionLabel(): string {
    const unit =
      this.localStorageService.getData(LocalStorageKey.PREFERRED_UNIT_SYSTEM) ||
      'metric';
    let length = getConvertedValueAndUnit(this.length, 'mm', unit);
    let width = getConvertedValueAndUnit(this.width, 'mm', unit);
    return `${this.name} — ${length.value} ✕ ${width.value} ${width.unit}`;
  }
}
