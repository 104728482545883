import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document as RichtextDocument } from '@contentful/rich-text-types';

@Pipe({
  name: 'richtextDocumentToHtml',
})
export class RichtextDocumentToHtmlPipe implements PipeTransform {
  htmlString: string;
  transform(value: RichtextDocument): string {
    this.htmlString = documentToHtmlString(value);
    return this.htmlString;
  }
}
