import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { defaultApiPattern } from 'src/app/models_new/config/default/api-default/default-api-pattern';
import { defaultApiProduct } from 'src/app/models_new/config/default/api-default/default-api-product';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import {
  LocalStorageService,
  StorageMethod,
} from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import { OpenSimService } from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { NewProductCardComponent } from '../../products/product/new-product-card/new-product-card.component';
import { UnitSystemType } from 'src/app/utils/unit-utils';

@Component({
  selector: 'app-fast-track-product',
  templateUrl: './fast-track-product.component.html',
  styleUrls: ['./fast-track-product.component.scss'],
})
export class FastTrackProductComponent implements OnInit {
  product$: Observable<DataRequestState<ApiProduct>>;
  unit: UnitSystemType = 'metric';
  isCardVisible$ = new BehaviorSubject<boolean>(true);

  @ViewChild('productCard')
  productCard: NewProductCardComponent;

  constructor(
    private localStorage: LocalStorageService,
    public openSimService: OpenSimService,
    public appLayout: AppLayoutService
  ) {}

  ngOnInit(): void {
    this.unit = this.localStorage.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
    this.openSimService.reset();

    this.product$ = of(
      this.localStorage.getData(LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN)
    ).pipe(
      take(1),
      map((opp: ApiPattern) => {
        let product = opp?.product;

        if (!product) {
          product = new ApiProduct(defaultApiProduct);
          product.id = '1';
          product.name = 'My product';
        }

        this.updateValid(product);
        this.save(product);

        return product;
      }),
      toRequestState()
    );
  }

  productUpdate(product: ApiProduct): void {
    if (!product) {
      return;
    }

    if (!product.id) {
      product.id = '1';
    }

    this.updateValid(product);
    this.save(product);
  }

  updateValid(product: ApiProduct) {
    if (
      product.name &&
      product.data.height > 0.9 &&
      product.data.length &&
      product.data.weight &&
      product.data.width &&
      product.data.label_orientation !== undefined
    ) {
      this.openSimService.setStepIsValid(true);
    } else {
      this.openSimService.setStepIsValid(false);
    }
  }

  public save(product: ApiProduct): void {
    const palletizingPattern = new ApiPattern(
      ObjectUtils.cloneObject(defaultApiPattern)
    );

    // No decimals
    for (let d in product.data) {
      product.data[d] = Math.ceil(product.data[d]);
    }

    palletizingPattern.product = new ApiProduct(product);

    palletizingPattern.data.productDimensions = product.data;
    palletizingPattern.data.labelOrientation = product.data.label_orientation;

    // Since we have "Math.ceil()" above on imperial units, add more pallet space to accommodate this.
    if (this.unit === 'imperial') {
      palletizingPattern.data.guiSettings.overhangEnds = 10;
      palletizingPattern.data.guiSettings.overhangSides = 5;
    } else {
      palletizingPattern.data.guiSettings.overhangEnds = 0;
      palletizingPattern.data.guiSettings.overhangSides = 0;
    }

    this.localStorage.setData(
      LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN,
      palletizingPattern
    );
  }

  public toggleUnits(isImperial: boolean): void {
    this.unit = isImperial ? 'imperial' : 'metric';
    this.localStorage.setData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM,
      this.unit,
      StorageMethod.LOCAL
    );
    this.isCardVisible$.next(false);
    setTimeout(() => this.isCardVisible$.next(true), 1);
  }
}
