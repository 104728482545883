import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { HardwareSortingType } from '../../../../models_new/enums/hardware-sorting-type';
import { IHwSwType } from '../../../../models_new/types/robot-config/hw-sw-type';

@Component({
  selector: 'app-hardware-card-list',
  templateUrl: './hardware-card-list.component.html',
  styleUrls: ['./hardware-card-list.component.scss'],
})
export class HardwareCardListComponent implements OnChanges {
  @Input() title;
  @Input() sortedContent: IHwSwType[];
  @Input() sortOptions: {
    label: string;
    value: HardwareSortingType;
    selected: boolean;
  }[];
  @Input() isBackoffice: boolean = false;
  @Input() isGlobal: boolean;
  @Input() clickedAsSelected: boolean = false;
  @Input() selectMultiple: boolean = false;
  @Input() selected: IHwSwType[] = [];
  @Input() pagination: number;
  @Input() actionButtons: { id: string; label: string; icon: string }[] = [
    {
      id: 'new',
      label: 'create',
      icon: 'add',
    },
  ];
  @Input() isLoading: boolean = false;
  @Output() actionClicked: EventEmitter<{
    actionId: string;
    actionValue: string;
  }> = new EventEmitter();
  @Output() cardClicked: EventEmitter<{
    hardware: IHwSwType;
  }> = new EventEmitter();
  @Output() sortSelected: EventEmitter<{
    label: string;
    value: HardwareSortingType;
    selected: boolean;
  }> = new EventEmitter();
  @Output() uploadNewComponent = new EventEmitter();
  @Output() addFromGlobalLibrary = new EventEmitter();
  @Output() selected$: EventEmitter<IHwSwType[]> = new EventEmitter();
  pagesPATH = pagesPATH;

  page: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortedContent?.currentValue) {
      // Reset the page number if new content arrives.
      this.page = 0;
    }
  }

  sorterSelected(e: {
    label: string;
    value: HardwareSortingType;
    selected: boolean;
  }) {
    this.sortOptions.find((f) => f.selected).selected = false;
    this.sortOptions.find((f) => f.value === e.value).selected = true;
    this.sortSelected.emit(e);
  }

  clickCard(hardware: IHwSwType): void {
    this.cardClicked.emit({ hardware: hardware });

    if (this.clickedAsSelected) {
      if (this.selectMultiple) {
        if (!this.isSelected(hardware)) {
          this.selected.push(hardware);
        } else {
          const index = this.selected.indexOf(hardware);
          if (index >= 0) {
            this.selected.splice(index, 1);
          }
        }
      } else {
        const hw = this.selected.find(
          (h: IHwSwType) => h.hw_sw_type.name === hardware.hw_sw_type.name
        );
        if (hw) {
          const index = this.selected.indexOf(hw);
          this.selected[index] = hardware;
        } else {
          this.selected.push(hardware);
        }
      }
      this.selected$.emit(this.selected);
    }
  }

  isSelected(hardware: IHwSwType): boolean {
    return Boolean(this.selected.find((h: IHwSwType) => h.id === hardware.id));
  }

  getPaginatedContent(page: number): IHwSwType[] {
    const list = [];
    for (
      let i = page * this.pagination;
      i < (page + 1) * this.pagination && i < this.sortedContent.length;
      i++
    ) {
      list.push(this.sortedContent[i]);
    }
    return list;
  }
}
