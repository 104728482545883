import { Component } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { Location } from '@angular/common';
import {
  IReleaseNotesContent,
  UpdateService,
} from '../../services/update.service';
import * as PPBTimeUtil from 'src/app/utils/time-utils';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent {
  contentfulContent$: Observable<IReleaseNotesContent>;
  release$: Observable<{
    tag: string;
    publishedAt: number;
  }>;
  timeUtil = PPBTimeUtil;

  constructor(
    private updateService: UpdateService,
    private location: Location
  ) {
    this.release$ = this.updateService.getReleases().pipe(take(1));

    this.contentfulContent$ = this.release$.pipe(
      switchMap((release) => {
        return this.updateService.getReleaseNotesContent(release.tag);
      }),
      take(1)
    );
  }

  public navigateBack(): void {
    this.location.back();
  }
}
