import { Injectable, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { RenderTrigger, ResizeEvent } from 'src/app/public/render-trigger';
import { CanvasStore } from 'src/app/public/canvas-store';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { View3DEventsService } from './view3d-events.service';
import isEqual from 'lodash-es/isEqual';
import { settings } from '../../models_new/config/application-settings';

export enum CameraType {
  Perspective = 'perspective',
  Orthographic = 'orthographic',
}

@Injectable({
  providedIn: 'root',
})
export class InputHandlerService implements OnDestroy {
  public readonly threeID$: BehaviorSubject<string> =
    new BehaviorSubject<string>(undefined);
  get ID(): string {
    return this.threeID$.getValue();
  }

  public canvas: HTMLCanvasElement;
  public canvasParent: HTMLElement;

  private controls: OrbitControls;
  private mainCamera: THREE.PerspectiveCamera | THREE.OrthographicCamera;

  private panAllowed = true;
  private rotationAllowed = true;
  private zoomAllowed = true;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  perspective: CameraType;

  constructor(private threeEvents: View3DEventsService) {
    this.threeEvents.canvasFetched$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe((_: any) => {
        this.canvas = CanvasStore.getCanvas(this.ID);
        this.canvasParent = CanvasStore.getCanvasParent(this.ID);
      });

    this.threeEvents.cameraMade$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe((_: any) => {
        this.mainCamera.position.set(0, 0, 5);

        this.controls = new OrbitControls(this.mainCamera, this.canvas);
      });

    this.threeEvents.setup3DViewFinished$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe((_: any) => {
        RenderTrigger.enableRenderingLoop$
          .pipe(takeUntil(this.destroy$))
          .subscribe((enabled: boolean) => {
            if (enabled) {
              this.setupChangeCallback();
            } else {
              this.removeChangeCallback();
            }
          });

        RenderTrigger.resize$
          .pipe(
            takeUntil(this.destroy$),
            filter((event) => event.id === this.ID),
            // Resizing is an expensive operation.
            // We only want to do that if it actually changes
            distinctUntilChanged(isEqual) // isEqual compares object values
          )
          .subscribe((event) => this.onResize(event));
      });

    this.destroy$.pipe(take(1)).subscribe((value: boolean) => {
      this.controls.dispose();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /* -------------------------------- Controls -------------------------------- */

  setupChangeCallback(): void {
    this.controls.removeEventListener(
      'change',
      this.controlsChangeCB.bind(this)
    );
  }

  removeChangeCallback(): void {
    this.controls.addEventListener('change', this.controlsChangeCB.bind(this));
  }

  private controlsChangeCB(): void {
    this.threeID$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((id: string) => {
        RenderTrigger.render$.next(id);
      });
  }

  updateControls(): void {
    if (!this.controls) {
      return;
    }

    this.controls.update();
  }

  resetControls(): void {
    if (!this.controls) {
      return;
    }

    this.controls.reset();
  }

  activateControls(): void {
    if (!this.controls) {
      return;
    }

    this.enablePan();
    this.enableRotate();
    this.enableZoom();
  }

  deactivateControls(): void {
    if (!this.controls) {
      return;
    }

    this.disablePan();
    this.disableRotate();
    this.disableZoom();
  }

  enablePan(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enablePan = this.panAllowed;
  }

  disablePan(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enablePan = false;
  }

  enableRotate(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enableRotate = this.rotationAllowed;
  }

  disableRotate(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enableRotate = false;
  }

  enableZoom(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enableZoom = this.zoomAllowed;
  }

  disableZoom(): void {
    if (!this.controls) {
      return;
    }

    this.controls.enableZoom = false;
  }

  disposeControls(): void {
    if (!this.controls) {
      return;
    }

    this.controls.dispose();
  }

  allowAll(): void {
    this.allowPan();
    this.allowRotate();
    this.allowZoom();
  }

  allowPan(): void {
    this.panAllowed = true;
  }

  allowRotate(): void {
    this.rotationAllowed = true;
  }

  allowZoom(): void {
    this.zoomAllowed = true;
  }

  forbidAll(): void {
    this.forbidPan();
    this.forbidRotate();
    this.forbidZoom();
  }

  forbidPan(): void {
    this.panAllowed = false;
    this.disablePan();
  }

  forbidRotate(): void {
    this.rotationAllowed = false;
    this.disableRotate();
  }

  forbidZoom(): void {
    this.zoomAllowed = false;
    this.disableZoom();
  }

  /* ----------------------------- Camera ---------------------------- */

  getControls(): OrbitControls {
    return this.controls;
  }

  getMainCamera(): THREE.PerspectiveCamera | THREE.OrthographicCamera {
    return this.mainCamera;
  }

  setAspect(aspect: number): void {
    if ('isPerspectiveCamera' in this.mainCamera) {
      this.mainCamera.aspect = aspect;
    }
  }

  setCameraPostion(pos?: THREE.Vector3): void {
    if (!pos) {
      this.setDefaultCameraPostion();
    } else {
      this.mainCamera.position.copy(pos);
    }
  }

  setDefaultCameraPostion(): void {
    this.mainCamera.position.copy(settings.view3d.oldDefaultCameraPosition);
  }

  setCameraUp(up: THREE.Vector3): void {
    if (!up) {
      this.resetCameraUp();
    } else {
      this.mainCamera.up.copy(up);
    }
  }

  resetCameraUp(): void {
    this.mainCamera.up.set(0, 1, 0);
  }

  setCameraTarget(pos: THREE.Vector3): void {
    if (!pos) {
      this.resetCameraTarget();
    } else {
      this.controls.target.copy(pos);
      this.updateControls();
    }
  }

  resetCameraTarget(): void {
    this.controls.target.set(0, 0, 0);
    this.updateControls();
  }

  setMaxCameraDistance(dist: number) {
    this.controls.maxDistance = dist;
  }

  setCamera(type: CameraType) {
    this.perspective = type;

    if (type === CameraType.Perspective) {
      const perspectiveConfig = settings.view3d.defaultPerspectiveCamera;

      this.mainCamera = new THREE.PerspectiveCamera(
        perspectiveConfig.fov,
        this.canvasParent.clientWidth / this.canvasParent.clientHeight,
        perspectiveConfig.near,
        perspectiveConfig.far
      );
    } else if (type === CameraType.Orthographic) {
      const aspect =
        this.canvasParent.clientWidth / this.canvasParent.clientHeight;

      const orthoConfig = settings.view3d.defaultOrthographicCamera;

      this.mainCamera = new THREE.OrthographicCamera(
        (-orthoConfig.width * aspect) / 2,
        (orthoConfig.width * aspect) / 2,
        orthoConfig.height / 2,
        -orthoConfig.height / 2,
        orthoConfig.near,
        orthoConfig.far
      );
    }

    if (this.controls) {
      this.controls.dispose();
      this.controls = new OrbitControls(this.mainCamera, this.canvas);
      this.setupChangeCallback();
    }
  }

  onResize(event: ResizeEvent) {
    const width = event.width * window.devicePixelRatio;
    const height = event.height * window.devicePixelRatio;
    const aspect = width / height;

    if (this.mainCamera)
      if ('isPerspectiveCamera' in this.mainCamera) {
        this.mainCamera.aspect = aspect;
        this.mainCamera.updateProjectionMatrix();
      } else {
        const orthoConfig = settings.view3d.defaultOrthographicCamera;

        this.mainCamera.left = (-orthoConfig.width * aspect) / 2;
        this.mainCamera.right = (orthoConfig.width * aspect) / 2;
        this.mainCamera.top = orthoConfig.height / 2;
        this.mainCamera.bottom = -orthoConfig.height / 2;
        this.mainCamera.updateProjectionMatrix();
      }
  }
}
