export const pallyBoxType = {
  x: 'number',
  y: 'number',
  r: 'array',
  g: 'array',
  f: 'number',
};

export const pallyLayerType = {
  id: 'string',
  name: 'string',
  class: 'string',
  pattern: 'array',
  altPattern: 'array',
  height: 'number',
  approach: ['string', 'null'],
  altApproach: ['string', 'null'],
};

export const pallyLayerName = 'array';

export const pallyPalletDimensionType = {
  length: 'number',
  width: 'number',
  height: 'number',
};

export const pallyProductDimensionType = {
  width: 'number',
  height: 'number',
  length: 'number',
  weight: 'number',
};

export const pallyGuiSettingsType = {
  PPB_VERSION_NO: 'string',
  boxPadding: 'number',
  units: 'string',
  overhangSides: 'number',
  overhangEnds: 'number',
  altLayout: 'string',
};

export const pallyDataType = {
  name: 'string',
  description: 'string',
  dimensions: 'object',
  productDimensions: 'object',
  maxGrip: 'number',
  maxGripAuto: 'boolean',
  labelOrientation: ['number', 'null'],
  guiSettings: 'object',
  dateModified: 'string',
  layerTypes: 'array',
};
