import * as THREE from 'three';
import { MathUtils } from 'three';
import { Injector, SimpleChanges } from '@angular/core';
import { ThreeHandler } from '../three-handler';
import { ThreePerspective } from 'src/app/models_new/enums/three-perspective';
import { DataService } from 'src/app/services/data.service';
import { View3DEventsService } from 'src/app/services/3dview/view3d-events.service';
import { ProjectThreeFactory } from 'src/app/services/3dview/project-three-factory.service';
import { filter, take, takeUntil, map, switchMap } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { ThreePallet } from '../three-pallet';
import { PalletPosition } from 'src/app/models_new/enums/pallet-position';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { PalletView } from '../../pallet-view';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { Project } from '../../project';
import { track } from 'src/app/utils/resource-tracker';
import { Asset } from '../../asset/asset';
import {
  AssetIDs,
  composeBoxAssetID,
} from 'src/app/models_new/enums/asset-ids';
import { DynamicBox } from '../dynamic-box';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';
import { settings } from '../../../config/application-settings';

export class PalletViewHandler extends ThreeHandler {
  protected dataService: DataService;
  protected threeEvents: View3DEventsService;
  protected factory = new ProjectThreeFactory();

  pallets: Map<PalletPosition, ThreePallet> = new Map<
    PalletPosition,
    ThreePallet
  >();
  private readonly palletsReady$ = new BehaviorSubject<boolean>(false);

  private boxAsset: Asset<any>;
  private conveyor: THREE.Object3D;
  private conveyor_box: DynamicBox;

  private palletViewSettings: PalletView;
  private perspective: ThreePerspective;

  private palletFrontRight: THREE.Object3D;
  private palletFrontLeft: THREE.Object3D;
  private readonly palletFrontName = 'pallet_front';

  private project$ = new BehaviorSubject<Project>(null);

  sticker$: Observable<THREE.Texture>;

  constructor(ID: string, injector: Injector, project?: Project) {
    super(ID, injector, project);
    this.dataService = injector.get(DataService);
    this.threeEvents = injector.get(View3DEventsService);

    this.sticker$ = injector
      .get(OrganizationLogoService)
      .eitherLabelOrLogo$.pipe(
        takeUntil(this.destroy$),
        map((res) => res.texture)
      );

    AssetStoreService.whenAssetIsReady(AssetIDs.Box)
      .pipe(RXJSUtils.filterUndefinedAndNull(), take(1))
      .subscribe((asset: Asset<any>) => {
        this.boxAsset = asset;
      });

    this.palletViewSettings = this.dataService.getProject().getPalletView();

    this.project$
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((p: Project) => {
        this.factory.setProject(p);
      });

    this.destroy$.pipe(take(1)).subscribe((_: any) => {
      for (const [_, pallet] of this.pallets) {
        this.scene.remove(pallet);
        ThreeUtils.disposeObject(pallet);
      }
      this.boxAsset.resetMode();
    });
  }

  public init(): void {
    this.inputHandler.setDefaultCameraPostion();
    this.inputHandler.resetCameraTarget();
    this.inputHandler.forbidPan();
    this.inputHandler.setMaxCameraDistance(5);
    this.inputHandler.updateControls();
  }

  public afterViewInit(): void {
    // Ready conveyor
    this.makeConveyor();
    this.threeEvents.sceneBuilt$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse())
      .subscribe((_: any) => {
        this.scene.add(this.conveyor);
      });

    // Make pallet front
    this.palletFrontRight = this.makePalletFrontCone();
    this.palletFrontLeft = this.makePalletFrontCone();
    this.scene.add(this.palletFrontRight, this.palletFrontLeft);

    combineLatest([
      this.threeEvents.sceneBuilt$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse()
      ),
      AssetStoreService.loadingCompleted$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse(),
        take(1)
      ),
      this.factory.pallets$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([_, __, pallets]) => {
          if (this.pallets.size > 1) {
            for (const [pos, pallet] of this.pallets) {
              this.scene.remove(pallet);
              this.pallets.delete(pos);
            }
          }

          for (const [pos, pallet] of pallets) {
            this.pallets.set(pos, pallet);
            this.scene.add(pallet);

            if (pos === PalletPosition.LEFT) {
              (pallet as THREE.Object3D).visible =
                this.palletViewSettings.palletViewSettings.showLeftPallet;
            }
          }
          this.updateActivePallet();

          const rightPallet = this.pallets.get(PalletPosition.RIGHT);
          const leftPallet = this.pallets.get(PalletPosition.LEFT);

          // Position pallet fronts
          const frontRightPos = (
            rightPallet as THREE.Object3D
          ).position.clone();
          frontRightPos.z = rightPallet.edgeOffset.front + 0.4;
          this.palletFrontRight.position.copy(frontRightPos);
          this.palletFrontRight.rotation.x = MathUtils.degToRad(-90);
          const frontLeftPos = (leftPallet as THREE.Object3D).position.clone();
          frontLeftPos.z = leftPallet.edgeOffset.front + 0.4;
          this.palletFrontLeft.position.copy(frontLeftPos);
          this.palletFrontLeft.rotation.x = MathUtils.degToRad(-90);

          this.render();
        }),
        switchMap(() => this.sticker$)
      )
      .subscribe((texture: THREE.Texture) => {
        for (const [_, pallet] of this.pallets) {
          pallet.setBoxStickerTexture(texture);
        }
      });

    combineLatest([
      this.threeEvents.setup3DViewFinished$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse(),
        take(1)
      ),
      AssetStoreService.loadingCompleted$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse(),
        take(1)
      ),
      this.factory.pallets$,
    ])
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(([setupDone, loadingDone, _]) => {
        this.updateActivePallet();
        this.onViewSettingsChange(
          this.dataService.getProject().getPalletView()
        );
        this.render();
      });

    // View settings change
    combineLatest([
      this.dataService.getProject().getPalletView().update$,
      this.project$,
    ])
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterUndefinedAndNull())
      .subscribe((_: any) => {
        this.onViewSettingsChange(
          this.getProject()
            ? this.getProject().getPalletView()
            : this.dataService.getProject().getPalletView()
        );
        this.render();
      });
  }

  onChanges(changes: SimpleChanges): void {
    if (changes.project?.currentValue) {
      this.factory.setProject(changes.project.currentValue);
    }
  }

  public onViewSettingsChange(settings: PalletView): void {
    this.palletViewSettings = settings;

    this.updateCameraTarget(this.palletViewSettings);
    if (this.perspective === ThreePerspective.TWO) {
      this.updateCameraPosition(this.palletViewSettings);
    }

    this.inputHandler.updateControls();

    this.updatePalletFront(settings);
    this.updateConveyor(settings);
    this.updateBox(settings);

    this.updateActivePallet();
  }

  isPalletsReady(): boolean {
    return this.palletsReady$.getValue();
  }

  updateActivePallet(): void {
    for (const [pos, pallet] of this.pallets) {
      if (this.dataService.getProject().getActivePalletPosition() === pos) {
        pallet.enable();
      } else {
        pallet.disable();
      }
    }
  }

  addLighting(): void {
    const ambientIntensity = 0.25;
    const directionalIntensity = 1 - ambientIntensity;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity - 0.1);
    this.scene.add(ambient);

    const directional = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional.position.set(10, 2, 0);
    directional.lookAt(new THREE.Vector3());
    this.scene.add(directional);
    const directional2 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional2.position.set(-10, 2, 0);
    directional2.lookAt(new THREE.Vector3());
    this.scene.add(directional2);
    const directional3 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional3.position.set(0, 2, 10);
    directional3.lookAt(new THREE.Vector3());
    this.scene.add(directional3);
    const directional4 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional4.position.set(0, 2, -10);
    directional4.lookAt(new THREE.Vector3());
    this.scene.add(directional4);
  }

  public handlePerspective(p: ThreePerspective): void {
    combineLatest([
      this.threeEvents.setup3DViewFinished$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse(),
        take(1)
      ),
      AssetStoreService.loadingCompleted$.pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterFalse(),
        take(1)
      ),
    ])
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(([setupDone, loadingDone]) => {
        this.perspective = p;

        if (this.perspective === ThreePerspective.TWO) {
          this.setTopPerspective(this.palletViewSettings);
          this.updateCameraPosition(this.palletViewSettings);
        } else {
          this.setThreePerspective(this.palletViewSettings);
        }

        this.updateCameraTarget(this.palletViewSettings);

        this.inputHandler.updateControls();

        this.render();
      });
  }

  private setTopPerspective(settings: PalletView): void {
    this.inputHandler.setCameraUp(new THREE.Vector3(0, 0, -1));
    this.updateCameraPosition(settings);

    this.inputHandler.deactivateControls();
  }

  private setThreePerspective(s: PalletView): void {
    this.inputHandler.resetControls();

    const cameraUp = new THREE.Vector3(0, 1, 0);

    this.inputHandler.setCameraUp(cameraUp);
    this.inputHandler.setCameraPostion(
      settings.view3d.oldDefaultCameraPosition
    );

    this.inputHandler.activateControls();
  }

  private updateCameraPosition(settings: PalletView): void {
    const palletPos = (
      this.pallets.get(PalletPosition.RIGHT) as THREE.Object3D
    ).position.clone();
    const cameraHeight =
      this.pallets.get(PalletPosition.RIGHT).getTotalHeight() + 2.5;

    let pos: THREE.Vector3;
    if (settings.palletViewSettings.showLeftPallet) {
      pos = new THREE.Vector3(0, cameraHeight, 0);
    } else {
      pos = new THREE.Vector3(palletPos.x, cameraHeight, palletPos.z);
    }
    this.inputHandler.setCameraPostion(pos);
  }

  private updateCameraTarget(settings: PalletView): void {
    const pallet = this.pallets.get(PalletPosition.RIGHT) as THREE.Object3D;
    if (!pallet) return;
    const palletPos = pallet.position.clone();

    this.inputHandler.setCameraTarget(
      settings.palletViewSettings.showLeftPallet
        ? new THREE.Vector3(
            0,
            this.pallets.get(PalletPosition.RIGHT).getTotalHeight() / 2,
            0
          )
        : palletPos
            .clone()
            .add(
              new THREE.Vector3(
                0,
                this.pallets.get(PalletPosition.RIGHT).getTotalHeight() / 2,
                0
              )
            )
    );
  }

  private makePalletFrontCone(): THREE.Mesh {
    const geo = new THREE.ConeGeometry(0.075, 0.15);
    const mat = new THREE.MeshLambertMaterial({ color: '#000000' });
    const mesh = track(new THREE.Mesh(geo, mat));
    mesh.name = this.palletFrontName;
    mesh.visible = true; // Assume they're hidden
    return mesh;
  }

  private updatePalletFront(settings: PalletView): void {
    const palletFront = settings.palletViewSettings.showPalletFront;
    const showLeft = settings.palletViewSettings.showLeftPallet;

    this.palletFrontRight.visible = palletFront;
    this.palletFrontLeft.visible = palletFront && showLeft;

    this.render();
  }

  private showConveyor(settings: PalletView): boolean {
    return (
      settings.palletViewSettings.showConveyorFront ||
      settings.palletViewSettings.showConveyorRight ||
      settings.palletViewSettings.showConveyorLeft
    );
  }

  private makeConveyor(): void {
    this.conveyor = new THREE.Object3D();
    this.conveyor.position.set(0, 0.75, -1);

    AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(
      AssetIDs.ProjectViewConveyor
    )
      .pipe(RXJSUtils.filterUndefinedAndNull(), take(1))
      .subscribe((model) => {
        model.rotation.set(MathUtils.degToRad(-90), 0, MathUtils.degToRad(-90));

        this.conveyor.add(model);
        this.conveyor.visible = this.showConveyor(this.palletViewSettings);
      });

    combineLatest([
      AssetStoreService.getAsset(AssetIDs.Box).onModel$(),
      this.sticker$,
    ])
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterUndefinedAndNull())
      .subscribe(([asset, texture]) => {
        // Extract the actual mesh.
        // Initially this mesh is rotated inside the asset group. Which switches the x/z axis, which is hard to understand.
        const boxMesh = asset.children[0] as THREE.Mesh;

        // Convert to dynamic box
        this.conveyor_box = new DynamicBox(boxMesh);
        this.conveyor_box.setLabels(texture);
        this.conveyor_box.setLabelOrientations([LabelOrientation.RIGHT]);

        // Store the asset ID. We need it later to see
        // if it needs update
        this.conveyor_box.userData.currentBoxId = AssetIDs.Box;

        this.conveyor_box.setSize({
          width: this.project.data.box.dimensions.width / 1000,
          height: this.project.data.box.dimensions.height / 1000,
          length: this.project.data.box.dimensions.length / 1000,
        });

        this.conveyor_box.position.set(
          0,
          this.conveyor_box.height / 2,
          -(this.conveyor_box.length / 2) - 0.01
        );

        this.conveyor.add(this.conveyor_box);
      });
  }

  private updateConveyor(settings: PalletView): void {
    const showConveyor = this.showConveyor(settings);

    this.conveyor.visible = showConveyor;
    if (showConveyor) {
      let rotation = 0;
      if (settings.palletViewSettings.showConveyorFront) {
        rotation = 0;
      } else if (settings.palletViewSettings.showConveyorRight) {
        rotation = -90;
      } else if (settings.palletViewSettings.showConveyorLeft) {
        rotation = 90;
      }
      this.conveyor.rotation.y = MathUtils.degToRad(rotation);
    }

    const boxID = composeBoxAssetID({
      front: settings.palletViewSettings.showFront,
      label:
        settings.palletViewSettings.showLabelOrientation &&
        this.project.getBoxData().label.enabled,
      orientations: [this.project.getBoxData().label.orientation],
      lockedOverride: true,
      disabled: false,
    });

    if (
      this.conveyor_box &&
      this.conveyor_box.userData.currentBoxId !== boxID
    ) {
      this.conveyor_box.userData.currentBoxId = boxID;
    }

    this.render();
  }

  private updateBox(settings: PalletView): void {
    for (const [_, pallet] of this.pallets) {
      const palletView = this.project.getPalletView();
      const showFront = palletView.palletViewSettings.showFront;
      const labelEnabled =
        palletView.palletViewSettings.showLabelOrientation &&
        this.project.getProjectData().box.label.enabled;
      pallet.updateBoxes(labelEnabled, showFront);
    }

    this.render();
  }

  setProject(p: Project) {
    this.project$.next(p);
  }

  getProject(): Project {
    return this.project$.getValue();
  }
}
