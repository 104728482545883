import * as THREE from 'three';

export class SceneStore {
  private static readonly sVMap: Map<string, THREE.Scene> = new Map<
    string,
    THREE.Scene
  >();

  public static getScene(id: string): THREE.Scene {
    if (!SceneStore.sVMap.has(id)) {
      return undefined;
    }

    return SceneStore.sVMap.get(id);
  }

  public static addScene(id: string, scene: THREE.Scene): THREE.Scene {
    SceneStore.sVMap.set(id, scene);
    return scene;
  }

  public static removeScene(id: string): THREE.Scene {
    const scene = SceneStore.getScene(id);
    if (!scene) {
      return scene;
    }

    SceneStore.sVMap.delete(id);
    return scene;
  }
}
