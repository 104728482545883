import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaultApiProduct } from '../../../config/default/api-default/default-api-product';

@Injectable({
  providedIn: 'root',
})
export class ProductHandlerDataService {
  /** @deprecated Use app-product-view instead of app-three-view */
  productLength$: BehaviorSubject<number> = new BehaviorSubject<number>(
    defaultApiProduct.data.length
  );
  /** @deprecated Use app-product-view instead of app-three-view */
  productWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    defaultApiProduct.data.width
  );
  /** @deprecated Use app-product-view instead of app-three-view */
  productHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(
    defaultApiProduct.data.height
  );
}
