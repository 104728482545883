<div class="filters-wrapper">
  <mat-form-field appearance="fill">
    <mat-label>Search:</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Status</mat-label>
    <mat-select [formControl]="status" multiple>
      <mat-option
        *ngFor="let status of statuses"
        [value]="status"
        color="primary"
        >{{ status }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>
</div>
