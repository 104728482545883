import { ISortingOption } from './sorting-option';

export interface IFilterData {
  filterType: FilterType;
  id: string;
  label: string;
  value?: any;
  options?: ISortingOption[];
  min?: number;
  max?: number;
}

export enum FilterType {
  SLIDER = 'slider',
  SELECT = 'select',
}
