import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductionLineHandlerData {
  casesPerMinute = new BehaviorSubject(0);

  getCasesPerMinute() {
    return this.casesPerMinute;
  }
}
