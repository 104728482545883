<div class="landing-footer dark-theme">
  <div class="landing-footer-wrapper">
    <div class="landing-footer-content">
      <ng-container
        *ngIf="openSimService.contentFulFooter$ | async as footerColumn"
      >
        <div *ngFor="let c of footerColumn">
          <h4>{{ c.title }}</h4>
          <ng-container *ngIf="c?.content">
            <p [innerHtml]="c.content.json | richtextDocumentToHtml"></p>
          </ng-container>
          <ng-container *ngIf="c?.linkList">
            <p *ngFor="let link of c.linkList">
              <ng-container
                *ngIf="link.value?.includes('http'); else queryParam"
              >
                <a href="{{ link.value }}"> {{ link.label }}</a>
              </ng-container>
              <ng-template #queryParam>
                <a (click)="openAboutLink(link.value)"> {{ link.label }}</a>
              </ng-template>
            </p>
          </ng-container>
        </div>
        <div class="powered-by">
          <h4>Powered by</h4>
          <div class="powered-by-logo-wrapper">
            <img src="/assets/icons/rf_logo.png" />
            <img src="/assets/icons/ur_logo.png" />
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container>
      <mat-divider></mat-divider>
      <div class="landing-footer-copyright">
        <img [src]="publicApi.mrcLogoWhite" />
        <span>Rocketfarm AS © 2024</span>
      </div>
    </ng-container>
  </div>
</div>
