<div
  [class.directionalRule]="input.gui.centerVert || input.gui.centerHoriz"
  [class.centerHoriz]="input.gui.centerHoriz"
  [class.centerVert]="input.gui.centerVert"
  mat-dialog
>
  <h1 *ngIf="input.title" mat-dialog-title class="float-left w-100">
    {{ input.title }}
  </h1>
  <div
    mat-dialog-content
    style="max-height: 80vh !important"
    class="float-left w-100 m-0"
  >
    <ng-template #dynamicComponent class="float-left w-100"></ng-template>
    <ng-container *ngIf="contentAsString" class="float-left w-100">
      <p style="white-space: pre-line">{{ contentAsString }}</p>
    </ng-container>
  </div>
  <div mat-dialog-actions class="float-left w-100" *ngIf="input.button">
    <app-button
      *ngIf="input.button.enableCancel"
      [mat-dialog-close]
      [label]="buttonConfig[0].text"
    >
    </app-button>
    <span class="actions-spacer"></span>
    <mat-spinner
      *ngIf="isLoading()"
      [strokeWidth]="3"
      [diameter]="25"
      class="float-right"
    ></mat-spinner>

    <app-button
      [disabled]="checkValidity() || isLoading()"
      [label]="input.button.text"
      (buttonClick)="onOkClick()"
      [type]="input.button.type"
    >
    </app-button>
  </div>
</div>
