import * as THREE from 'three';
import { Type } from './type';
import { IQuaternion } from '../models_new/types/simulation-api-file-type';
import { Sticker } from '../models_new/classes/3dview/sticker';

export function toQuarternion(obj: IQuaternion) {
  return new THREE.Quaternion(+obj.x, +obj.y, +obj.z, +obj.w);
}

export function toIQuarternion(quarternion: THREE.Quaternion) {
  return {
    x: +quarternion.x,
    y: +quarternion.y,
    z: +quarternion.z,
    w: +quarternion.w,
  } as IQuaternion;
}

// Replaces all THREE.QUATERNION properties in obj with IQuaternion objects
export function replaceQuaternions(obj: any) {
  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof THREE.Quaternion) {
      obj[key] = toIQuarternion(value);
    } else if (value instanceof Object) {
      replaceQuaternions(value);
    }
  }
  return obj;
}

export class ThreeUtils {
  // Overload signature (makes sure the input type matches the output type)
  public static clone(src: THREE.Object3D, recursive?: boolean): THREE.Object3D;
  public static clone(src: THREE.Group, recursive?: boolean): THREE.Group;
  public static clone(src: THREE.Mesh, recursive?: boolean): THREE.Mesh;

  // Implementation signature
  public static clone(
    src: THREE.Object3D | THREE.Group | THREE.Mesh,
    recursive: boolean = true
  ): THREE.Object3D | THREE.Group | THREE.Mesh {
    if (!src) {
      return src;
    }

    let obj;
    if (src instanceof THREE.Mesh) {
      obj = ThreeUtils.cloneMesh(src, recursive);
    } else if (
      (Type.isDefined(src.type) && src.type === 'Object3D') ||
      (Type.isDefined(src.type) && src.type === 'Group')
    ) {
      obj = ThreeUtils.cloneObject(src, recursive);
    }

    return obj;
  }

  private static handleChildren(
    src: THREE.Object3D,
    parent: THREE.Object3D
  ): void {
    for (let i = 0; i < src.children.length; i++) {
      const child = ThreeUtils.clone(src.children[i]);
      if (Type.isDefined(child)) {
        parent.add(child);
      }
    }
  }

  // Overload signatures (ensures the input type matches the output type)
  public static cloneObject(
    src: THREE.Mesh,
    recursive?: boolean,
    updateWorldMatrix?: boolean
  ): THREE.Mesh;
  public static cloneObject(
    src: THREE.Group,
    recursive?: boolean,
    updateWorldMatrix?: boolean
  ): THREE.Group;
  public static cloneObject(
    src: THREE.Object3D,
    recursive?: boolean,
    updateWorldMatrix?: boolean
  ): THREE.Object3D;

  // Implementation signature
  public static cloneObject(
    src: THREE.Object3D | THREE.Group | THREE.Mesh,
    recursive: boolean = true,
    updateWorldMatrix: boolean = true
  ): THREE.Object3D | THREE.Group | THREE.Mesh {
    if (!src) {
      return src;
    }

    let obj;
    if (Type.isInstanceOf(src, THREE.Group)) {
      obj = new THREE.Group();
    } else if (Type.isInstanceOf(src, THREE.Mesh)) {
      obj = new THREE.Mesh();
    } else {
      obj = new THREE.Object3D();
    }

    obj.name = src.name;
    obj.up.copy(src.up);
    obj.position.copy(src.position);
    obj.quaternion.copy(src.quaternion);
    obj.scale.copy(src.scale);
    obj.matrix.copy(src.matrix);
    obj.matrixWorld.copy(src.matrixWorld);

    if (src instanceof THREE.Mesh && src.material) {
      let material;
      if (Array.isArray(src.material)) {
        material = [];
        for (let i = 0; i < src.material.length; i++) {
          material.push(src.material[i].clone());
        }
      } else {
        material = src.material.clone();
      }
      (obj as any).material = material;
    }
    if (src instanceof THREE.Mesh && src.geometry) {
      (obj as any).geometry = src.geometry.clone();
    }

    obj.matrixAutoUpdate = src.matrixAutoUpdate;
    obj.matrixWorldNeedsUpdate = src.matrixWorldNeedsUpdate;
    obj.layers.mask = src.layers.mask;
    obj.visible = src.visible;
    obj.castShadow = src.castShadow;
    obj.receiveShadow = src.receiveShadow;
    obj.frustumCulled = src.frustumCulled;
    obj.renderOrder = src.renderOrder;

    obj.userData = JSON.parse(JSON.stringify(src.userData));

    if (recursive) {
      ThreeUtils.handleChildren(src, obj);
    }

    if (updateWorldMatrix) {
      obj.updateMatrixWorld();
    }

    return obj;
  }

  public static cloneMesh(
    src: THREE.Mesh,
    recursive: boolean = true
  ): THREE.Mesh {
    const mesh = src.clone(); // To get other THREE properties than geometry and materials
    const geo = src.geometry.clone();
    let mat;
    if (Array.isArray(src.material)) {
      mat = [];
      for (let i = 0; i < src.material.length; i++) {
        mat[i] = src.material[i].clone();
      }
    } else {
      mat = src.material.clone();
    }
    mesh.geometry = geo;
    mesh.material = mat;

    if (recursive) {
      ThreeUtils.handleChildren(src, mesh);
    }

    return mesh;
  }

  public static cloneMaterial(
    src: Array<THREE.Material> | THREE.Material
  ): Array<THREE.Material> | THREE.Material {
    if (Array.isArray(src)) {
      return src.map((m) => m.clone());
    }
    return src.clone();
  }

  static base64ToThreeTexture(b64: string): THREE.Texture {
    const image = new Image(); // or document.createElement('img' );
    // Create texture
    const texture = new THREE.Texture(image);
    // On image load, update texture
    image.onload = () => {
      texture.needsUpdate = true;
    };
    // Set image source
    image.src = b64;

    return texture;
  }

  private static getImageToCanvasScale(image: any, canvas: any): number {
    return Math.min(canvas.width / image.width, canvas.height / image.height);
  }

  private static getImageAspect(image: any): number {
    return image.height / image.width;
  }

  private static getImagePosAndDimension(image: any, canvas: any) {
    const scale = ThreeUtils.getImageToCanvasScale(image, canvas);
    const logoAspect = ThreeUtils.getImageAspect(image);

    let width;
    let height;
    if (canvas.width / image.width < canvas.height / image.height) {
      width = image.width * (scale * 0.5);
      height = width * logoAspect;
    } else {
      height = image.height * (scale * 0.5);
      width = height / logoAspect;
    }
    const x = canvas.width / 2 - width / 2;
    const y = canvas.height / 2 - height / 2;

    return [x, y, width, height];
  }

  static mergeImages(
    images: any[],
    options?: { endSize: THREE.Vector2; baseColor: string }
  ): any {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (Type.isDefined(options)) {
      canvas.width = options.endSize.x;
      canvas.height = options.endSize.y;
      ctx.fillStyle = options.baseColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    ctx.fillStyle = '#ffffff';
    for (const image of images) {
      if (image instanceof THREE.Texture && Type.isDefined(image.image)) {
        const posDim = ThreeUtils.getImagePosAndDimension(image.image, canvas);
        ctx.fillRect(
          posDim[0] - 10,
          posDim[1] - 10,
          posDim[2] + 20,
          posDim[3] + 20
        );
        ctx.drawImage(image.image, posDim[0], posDim[1], posDim[2], posDim[3]);
      } else if (image instanceof Image) {
        const posDim = ThreeUtils.getImagePosAndDimension(image, canvas);
        ctx.fillRect(
          posDim[0] - 10,
          posDim[1] - 10,
          posDim[2] + 20,
          posDim[3] + 20
        );
        ctx.drawImage(image, posDim[0], posDim[1], posDim[2], posDim[3]);
      }
    }

    const image = new Image();
    image.src = canvas.toDataURL();
    return image;
  }

  /**
   * Returns a copy of the vertecies in a given geometry.
   *
   * @param geometry { THREE.BufferGeometry } - Geometry to fetch vertecies from.
   *
   * @returns { THREE.Vector3[] } - List of points representing vertecites of the geometry.
   */
  static getVertecies(geometry: THREE.BufferGeometry): THREE.Vector3[] {
    const list = [];
    const buffer = geometry.getAttribute('position');
    for (let i = 0; i < buffer.count; i++) {
      list.push(
        new THREE.Vector3(buffer.getX(i), buffer.getY(i), buffer.getZ(i))
      );
    }
    return list;
  }

  /**
   * Takes a texture and fits it in a given aspect ratio.
   *
   * @param texture { THREE.Texture } - Texture to fit in.
   * @param endAspect { number } - Resulting aspect ration to fit texture in.
   * @param baseColor { string (css-color) } - Color to fill the background with.
   *
   * @returns { THREE.CanvasTexture } - Containing the resulting texture.
   */
  static prepLogoForDisplay(
    texture: THREE.Texture,
    endAspect: number,
    baseColor: string = '#ffffff'
  ): any {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imageAspect = texture.image.width / texture.image.height;
    if (imageAspect > endAspect) {
      canvas.width = texture.image.width;
      canvas.height = texture.image.width / endAspect;
    } else {
      canvas.width = texture.image.height * endAspect;
      canvas.height = texture.image.height;
    }

    ctx.fillStyle = baseColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Calculate the amount of padding to add to the image
    const xPadding = Math.abs(canvas.width - texture.image.width) / 2;
    const yPadding = Math.abs(canvas.height - texture.image.height) / 2;

    // Draw the original image onto the canvas with padding
    ctx.drawImage(texture.image, xPadding, yPadding);

    const result = new THREE.CanvasTexture(canvas, THREE.UVMapping);
    return result;
  }

  /**
   * Builds a visualization of UV's on the given texture
   *
   * @param texture { THREE.Texture } - Texture uv's are mapped to.
   * @param geometry { THREE.Geometry } - Geometry containing UV attribute buffer.
   *
   * @returns { TRHEE.Group } - Sticker with a texture and spheres representing the uv's.
   */
  static visualizeUVsOnTexture(
    texture: THREE.Texture,
    geometry: THREE.BufferGeometry
  ): THREE.Group {
    const parent = new THREE.Group();

    const sticker = new Sticker(texture);
    parent.add(sticker);

    const size = new THREE.Box3()
      .expandByObject(sticker)
      .getSize(new THREE.Vector3());

    const uvs = geometry.getAttribute('uv');
    for (let i = 0; i < uvs.count; i++) {
      const uv = ThreeUtils.makeUVObject();
      uv.position.set(
        uvs.getX(i) * size.x - size.x / 2,
        uvs.getY(i) * size.y - size.y / 2,
        0
      );
      parent.add(uv);
    }

    return parent;
  }

  private static makeUVObject(
    size: number = 0.005,
    color: string = '#00ff00'
  ): THREE.Mesh {
    return new THREE.Mesh(
      new THREE.SphereGeometry(size),
      new THREE.MeshLambertMaterial({ color: color })
    );
  }

  static makeLine(
    start: THREE.Vector3,
    end: THREE.Vector3,
    color: string,
    options?: { linewidth?: number; dashed?: boolean }
  ): THREE.Line {
    const optDef = Type.isDefined(options);
    const width =
      optDef && Type.isDefined(options.linewidth) ? options.linewidth : 2;
    const dashed =
      optDef && Type.isDefined(options.dashed) ? options.dashed : false;
    const geo = new THREE.BufferGeometry().setFromPoints([start, end]);
    geo.computeBoundingBox();
    const line = new THREE.Line(
      geo,
      dashed
        ? new THREE.LineDashedMaterial({
            color: color,
            linewidth: width,
            dashSize: 0.01,
            gapSize: 0.01,
          })
        : new THREE.LineBasicMaterial({
            color: color,
            linewidth: width,
          })
    );
    line.computeLineDistances();
    return line;
  }

  static addDebugAxies(
    target: THREE.Object3D,
    length?: number
  ): THREE.Object3D[] {
    length = !Type.isDefined(length) ? 1 : length;
    const axisX = new THREE.ArrowHelper(
      new THREE.Vector3(length, 0, 0),
      new THREE.Vector3(0, 0, 0),
      length,
      0xff0000
    );
    const axisY = new THREE.ArrowHelper(
      new THREE.Vector3(0, length, 0),
      new THREE.Vector3(0, 0, 0),
      length,
      0x00ff00
    );
    const axisZ = new THREE.ArrowHelper(
      new THREE.Vector3(0, 0, length),
      new THREE.Vector3(0, 0, 0),
      length,
      0x0000ff
    );
    target.add(axisX, axisY, axisZ);
    return [axisX, axisY, axisZ];
  }

  static visualizePosition(
    parent: THREE.Object3D,
    pos: THREE.Vector3,
    color: string | number | THREE.Color = '#ff0000'
  ) {
    parent.add(
      new THREE.ArrowHelper(pos, parent.position, pos.length(), color)
    );
  }

  static addDebugUnitGrid(
    target: THREE.Object3D,
    option: { position?: THREE.Vector3; size?: number }
  ) {
    const position = !Type.isDefined(option.position)
      ? new THREE.Vector3()
      : option.position;
    const size = !Type.isDefined(option.size) ? 5 : option.size;
    for (let x = 0; x < size; x++) {
      const pos = x - Math.floor(size / 2);
      target.add(
        ThreeUtils.makeLine(
          new THREE.Vector3(
            position.x + Math.floor(size / 2),
            position.y,
            position.z + pos
          ),
          new THREE.Vector3(
            position.x - Math.floor(size / 2),
            position.y,
            position.z + pos
          ),
          '#ff0000'
        )
      );
    }
    for (let z = 0; z < size; z++) {
      const pos = z - Math.floor(size / 2);
      target.add(
        ThreeUtils.makeLine(
          new THREE.Vector3(
            position.x + pos,
            position.y,
            position.z + Math.floor(size / 2)
          ),
          new THREE.Vector3(
            position.x + pos,
            position.y,
            position.z - Math.floor(size / 2)
          ),
          '#0000ff'
        )
      );
    }
  }

  static dispose(object: any) {
    if (object.material) {
      if (Array.isArray(object.material)) {
        for (const material of object.material) {
          material.dispose();
        }
      } else {
        object.material.dispose();
      }
    }

    if (object.geometry) {
      object.geometry.dispose();
    }

    if (
      object instanceof THREE.BufferGeometry ||
      object instanceof THREE.Material
    ) {
      object.dispose();
    }
  }

  static disposeObject(
    object: THREE.Object3D | Array<THREE.Object3D>,
    recursive: boolean = true
  ): void {
    if (!object) {
      return;
    }

    if (Array.isArray(object)) {
      for (const obj of object) {
        ThreeUtils.dispose(obj);

        // Dont care about children
        if (!recursive) {
          continue;
        }

        obj.traverse((child) => {
          ThreeUtils.dispose(child);
        });
      }
    } else {
      ThreeUtils.dispose(object);

      // Dont care about children
      if (!recursive) {
        return;
      }

      object.traverse((child) => {
        ThreeUtils.dispose(child);
      });
    }
  }

  public static enableShadows(
    obj: THREE.Object3D,
    options?: Partial<{
      camNear: number;
      camFar: number;
      camTarget?: THREE.Vector3;
    }>
  ): void {
    // Defaults
    if (!options) {
      options = {
        camNear: 0.1,
        camFar: 500,
      };
    } else {
      if (!options.camNear) {
        options.camNear = 0.1;
      }
      if (!options.camFar) {
        options.camFar = 500;
      }
    }

    if (obj instanceof THREE.Light) {
      obj.castShadow = true;
      obj.shadow.mapSize.width = 1024;
      obj.shadow.mapSize.height = 1024;
      obj.shadow.bias = -0.0002;
      obj.shadow.radius = 4;
      obj.shadow.blurSamples = 8;

      // Shadow camera settings
      (obj.shadow.camera as any).near = options.camNear;
      (obj.shadow.camera as any).far = options.camFar;
      obj.shadow.camera.position.copy(obj.position);
      if (!options.camTarget && Object.keys(obj).includes('target')) {
        obj.shadow.camera.lookAt((obj as any).target.position);
      } else {
        obj.shadow.camera.lookAt(options.camTarget);
      }
    }

    if (obj instanceof THREE.Mesh) {
      obj.castShadow = true;
      obj.receiveShadow = true;
    }

    if (obj.children.length > 0) {
      obj.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
    }
  }

  public static enableObjectShadows(
    obj: THREE.Object3D,
    onlyReceive: boolean = false
  ): void {
    if (obj instanceof THREE.Mesh) {
      obj.castShadow = !onlyReceive;
      obj.receiveShadow = true;
    }

    if (obj.children.length > 0) {
      obj.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = !onlyReceive;
          child.receiveShadow = true;
        }
      });
    }
  }

  public static enablePerspectiveTypeShadows(
    obj: THREE.Object3D,
    options?: Partial<{
      camNear: number;
      camFar: number;
      camTarget?: THREE.Vector3;
    }>
  ): void {
    // Defaults
    if (!options) {
      options = {
        camNear: 0.1,
        camFar: 500,
      };
    } else {
      if (!options.camNear) {
        options.camNear = 0.1;
      }
      if (!options.camFar) {
        options.camFar = 500;
      }
    }

    if (obj instanceof THREE.Light) {
      obj.castShadow = true;
      obj.shadow.mapSize.width = 1024;
      obj.shadow.mapSize.height = 1024;
      obj.shadow.bias = -0.0002;
      obj.shadow.radius = 4;
      obj.shadow.blurSamples = 8;

      // Shadow camera settings
      (obj.shadow.camera as any).near = options.camNear;
      (obj.shadow.camera as any).far = options.camFar;
      obj.shadow.camera.position.copy(obj.position);
      if (!options.camTarget && Object.keys(obj).includes('target')) {
        obj.shadow.camera.lookAt((obj as any).target.position);
      } else {
        obj.shadow.camera.lookAt(options.camTarget);
      }
    }
  }

  public static enableOrtographicTypeShadows(
    obj: THREE.Object3D,
    options?: Partial<{
      camNear: number;
      camFar: number;
      camTop: number;
      camLeft: number;
      camBottom: number;
      camRight: number;
      camTarget?: THREE.Vector3;
    }>
  ): void {
    // Defaults
    if (!options) {
      options = {
        camNear: 0.1,
        camFar: 500,
        camTop: 17,
        camLeft: -17,
        camBottom: -17,
        camRight: 17,
      };
    } else {
      if (!options.camNear) {
        options.camNear = 0.1;
      }
      if (!options.camFar) {
        options.camFar = 500;
      }
      if (!options.camTop) {
        options.camTop = 17;
      }
      if (!options.camLeft) {
        options.camLeft = -17;
      }
      if (!options.camBottom) {
        options.camBottom = -17;
      }
      if (!options.camRight) {
        options.camRight = 17;
      }
    }

    if (obj instanceof THREE.Light) {
      obj.castShadow = true;
      obj.shadow.mapSize.width = 1024;
      obj.shadow.mapSize.height = 1024;
      obj.shadow.bias = -0.0002;
      obj.shadow.radius = 4;
      obj.shadow.blurSamples = 8;

      // Shadow camera settings
      (obj.shadow.camera as any).near = options.camNear;
      (obj.shadow.camera as any).far = options.camFar;
      (obj.shadow.camera as any).left = options.camTop;
      (obj.shadow.camera as any).top = options.camLeft;
      (obj.shadow.camera as any).bottom = options.camBottom;
      (obj.shadow.camera as any).right = options.camRight;
      obj.shadow.camera.position.copy(obj.position);
      if (!options.camTarget && Object.keys(obj).includes('target')) {
        obj.shadow.camera.lookAt((obj as any).target.position);
      } else {
        obj.shadow.camera.lookAt(options.camTarget);
      }
    }
  }

  public static resizeCameraToSize(
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
    size: THREE.Vector2
  ): void {
    if (camera instanceof THREE.PerspectiveCamera) {
      const width = size.x * window.devicePixelRatio;
      const height = size.y * window.devicePixelRatio;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    } else if (camera instanceof THREE.OrthographicCamera) {
      const width = size.x * window.devicePixelRatio;
      const height = size.y * window.devicePixelRatio;
      const aspect = width / height;

      camera.left = (-width * aspect) / 2;
      camera.right = (width * aspect) / 2;
      camera.top = height / 2;
      camera.bottom = -height / 2;
      camera.updateProjectionMatrix();
    }
  }

  public static copyTransform(
    obj: THREE.Object3D,
    src: THREE.Object3D,
    ignoreScale: boolean = false
  ) {
    obj.position.copy(src.position);
    obj.rotation.copy(src.rotation);
    if (!ignoreScale) {
      obj.scale.copy(src.scale);
    }
  }

  public static zeroTransform(
    obj: THREE.Object3D,
    ignoreScale: boolean = false
  ) {
    obj.position.set(0, 0, 0);
    obj.rotation.set(0, 0, 0);
    if (!ignoreScale) {
      obj.scale.set(1, 1, 1);
    }
  }
}
