import { IApiOrganizationMember } from 'src/app/models_new/classes/api-models/ApiOrganizationMember';
import { ApiUser } from 'src/app/models_new/classes/api-models/ApiUser';
import { OrganizationMemberType } from 'src/app/models_new/enums/organization-member-type';
import { defaultApiUser } from './default-api-user';

export const defaultApiOrganizationMember: IApiOrganizationMember = {
  user_id: new ApiUser(defaultApiUser).id,
  organization_id: '001',
  created_at: new Date().getTime(),
  updated_at: new Date().getTime(),
  type: OrganizationMemberType.MANAGER,
  invitation_id: null,
};
