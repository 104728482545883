<div
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  class="landing-step-item-wrapper"
  [ngStyle]="
    lowHeigth
      ? {
          'padding-top': '0.5em',
          'padding-bottom': '1em',
          'margin-top': '0.5em'
        }
      : {}
  "
>
  <ng-container *ngIf="dataReady$ | async as dataReady">
    <!-- Loading state -->
    <app-loading *ngIf="dataReady.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="dataReady.error as error" [error]="error"></app-error>

    <ng-container *ngIf="dataReady.didSucceed && dataReady.value">
      <h1
        *appVar="openSimService.pagesSortedList$ | async as pagesSortedList"
        [ngStyle]="
          lowHeigth ? { 'margin-bottom': '0.5em', 'margin-top': '0.5em' } : {}
        "
      >
        {{
          pagesSortedList[openSimService.selectedPageIndex].content.cardTitle
        }}
      </h1>

      <div
        class="landing-region-cards-wrapper"
        [ngStyle]="lowHeigth ? { height: '480px' } : {}"
      >
        <mat-card appearance="outlined" class="landing-region-cards-left-card">
          <mat-card-content>
            <!-- country field -->
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Country</mat-label>
              <input
                type="text"
                data-testid="fastTrackContryInputField"
                matInput
                [formControl]="countryControl"
                [matAutocomplete]="autoCountry"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autoCountry="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredCountryOptions$ | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- country field -->
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              *ngIf="showStateField"
            >
              <mat-label>State</mat-label>
              <input
                type="text"
                data-testid="fastTrackRegionInputField"
                matInput
                [formControl]="stateControl"
                [matAutocomplete]="autoState"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autoState="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredStateOptions$ | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" class="landing-region-cards-right-card">
          <app-earth-globe-view
            style="width: 100%; height: 100%; border-radius: 8px"
            [countryCode]="countryCode"
          ></app-earth-globe-view>
        </mat-card>
      </div>
    </ng-container>
  </ng-container>
</div>
