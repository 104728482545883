import * as THREE from 'three';
import { Subject } from 'rxjs';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { ITimed3DObject } from '../../types/timed-3d-object';
import { SceneStore } from 'src/app/public/scene-store';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { take } from 'rxjs/operators';

export class TimedObject3D extends THREE.Object3D implements ITimed3DObject {
  readonly ID: string;
  threeID: string;
  triggerRender: boolean;
  oneTimeUse: boolean;
  duration: number;

  timerID: any;
  active: boolean;
  disposed: boolean;

  remove$: Subject<boolean> = new Subject<boolean>();
  dispose$: Subject<boolean> = new Subject<boolean>();

  /**
   * TimedObject3D
   * @param ID - string - Identifier for this visualizer.
   * @param threeID - string - Accociated ThreeViews id.
   * @param triggerRender - boolean - Whether this object should trigger rendering once activated.
   * @param oneTimeUse - boolean - Whether this object should be disposed on removal.
   */
  constructor(
    ID: string,
    threeID: string,
    triggerRender: boolean = true,
    oneTimeUse: boolean = false
  ) {
    super();

    this.ID = ID;
    this.threeID = threeID;
    this.oneTimeUse = oneTimeUse;
    this.triggerRender = triggerRender;
    this.name = 'TimedObject3D-' + this.ID;

    this.remove$.pipe(RXJSUtils.filterFalse()).subscribe((_: any) => {
      this.removeFromParent();
      this.render();
    });

    this.dispose$.pipe(RXJSUtils.filterFalse(), take(1)).subscribe((_: any) => {
      this.disposed = true;
    });

    if (this.oneTimeUse) {
      this.remove$.subscribe(this.dispose$);
    }
  }

  isActive(): boolean {
    return this.active;
  }

  make(): void {
    //
  }

  update(durationMS?: number): void {
    this.children = [];
    this.make();

    this.render();

    if (durationMS < 0) {
      this.clear(); // Clear the timer if given -1.
    }

    const d = durationMS ? durationMS : this.duration;
    if (d > -1) {
      this.setRemoveTimer(d);
    }
  }

  /**
   * Activates the timed 3D Object
   * @param durationMS - number - Time in milliseconds object should exist. Use -1 to disable timed removal.
   * @param triggerRenderOverride - boolean - Override render trigger setting.
   */
  activate(durationMS?: number, addToScene: boolean = true): void {
    // Can't activate if disposed
    if (this.disposed) {
      console.debug("Can't activate if disposed");
      return;
    }

    if (addToScene) {
      SceneStore.getScene(this.threeID).add(this);
    }

    const d = durationMS ? durationMS : this.duration;
    this.active = true;
    this.update(d);
  }

  remove() {
    this.remove$.next(true);
    return this;
  }

  dispose(): void {
    this.dispose$.next(true);
  }

  cancel(remove: boolean = true) {
    if (remove) {
      this.remove();
    }
    this.clear();
  }

  clear() {
    clearTimeout(this.timerID);
    this.active = false;
    return this;
  }

  render() {
    RenderTrigger.render$.next(this.threeID);
  }

  private setRemoveTimer(durationMS: number): void {
    this.duration = durationMS;

    this.cancel(false);

    this.timerID = setTimeout(() => {
      this.active = false;
      this.cancel();
    }, durationMS);
    this.active = true;
  }
}
