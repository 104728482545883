<div class="self">
  <!-- Header -->
  <div style="position: relative">
    <app-header
      *ngIf="stateService.sales_organization$ | async as org"
      [logoLayout]="true"
    >
      <h1 app-overline>Inventory</h1>
      <h2 app-title>{{ title }}s</h2>
      <app-company-logo [activeOrganizationType]="org.type"></app-company-logo>
    </app-header>
    <div
      class="hstack"
      style="margin-top: 30px; position: absolute; right: 8px; bottom: 0px"
    >
      <button class="circularButton" (click)="didClickChangeDisplayMode()">
        <div>
          <mat-icon *ngIf="displayMode === 'TABLE'" [@inOutAnimation]
            >view_list</mat-icon
          >
          <mat-icon *ngIf="displayMode === 'LIST'" [@inOutAnimation]
            >table_view</mat-icon
          >
        </div>
      </button>
    </div>
  </div>

  <hr class="separator" />

  <div class="animation-container">
    <!-- Loading state -->
    <app-loading [message]="' '" *ngIf="isLoading"></app-loading>

    <!-- Table view -->
    <div *ngIf="displayMode === 'TABLE' && !isLoading">
      <app-table
        #table
        [clickableRows]="true"
        [clickToSelect]="true"
        [topRow]="false"
        [inputData]="listData"
        [displayedColumns]="listColumns"
        [actions]="doesAllowActions"
        [shouldDoTitleCase]="false"
        [noDataText]="noDataText"
        [showUpdatedAt]="true"
        [shouldShowButtonToCreateIfNoData]="shouldDisplayCreateButton"
        [preSelectedRows]="selectedTableData"
        [selectionAmount]="selectionAmount"
        (blockSelectedChange)="didSelectRow($event)"
        (actionClicked)="didClickAction($event)"
      ></app-table>
    </div>

    <!-- List view -->
    <div *ngIf="displayMode === 'LIST' && !isLoading">
      <div
        class="hstack"
        style="justify-content: space-between; margin-bottom: 16px"
      >
        <div class="hstack" style="gap: 8px">
          <h3 style="padding-right: 8px">Sort By</h3>
          <button
            *ngFor="let sortOption of allSortingTypes"
            class="pillButton"
            [ngClass]="sortOption.key === sortingType ? 'selected' : ''"
            (click)="didSelectSortOption(sortOption.key)"
          >
            {{ sortOption.value }}
          </button>
        </div>

        <div class="hstack">
          <input
            class="searchField"
            placeholder="Search"
            (input)="didChangeSearch($event)"
          />
          <button class="silentButton">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <ng-container
        *ngFor="
          let cell of listData
            | slice: page * numItemsInPage:(page + 1) * numItemsInPage
        "
      >
        <ng-container *ngIf="type !== PickerType.ROBOT_CONFIGURATION">
          <app-card-picker-list
            [type]="type"
            [listData]="cell"
            [data]="data[cell.data.id]"
            [doesAllowActions]="doesAllowActions"
            [listColumns]="listColumns"
            [columnColors]="columnColors"
            [listColumnUnits]="listColumnUnits"
            [selected]="selected.includes(data[cell.data.id])"
            (click)="selectCard(data[cell.data.id])"
            (actionHandler)="didClickAction($event)"
          ></app-card-picker-list>
        </ng-container>

        <ng-container *ngIf="type === PickerType.ROBOT_CONFIGURATION">
          <app-card-robot-config
            [robotConfig]="$any(data[cell.data.id])"
            [selected]="selected.includes(data[cell.data.id])"
            (click)="selectCard(data[cell.data.id])"
          >
          </app-card-robot-config>
        </ng-container>
      </ng-container>
      <div *ngIf="listData.length === 0">
        <p>
          <span style="text-align: center">{{ noDataText }}</span>
          <button *ngIf="shouldDisplayCreateButton" class="linkButton">
            Create one?
          </button>
        </p>
      </div>
      <mat-paginator
        [length]="listData.length"
        [pageSize]="8"
        [pageSizeOptions]="[3, 5, 8, 13]"
        (page)="didChangePage($event)"
      ></mat-paginator>
    </div>
  </div>
</div>
