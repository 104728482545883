import { Injectable, Injector } from '@angular/core';
import { pagesPATH } from '../models_new/config/pages';
import { NotificationService } from './notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorContext, ErrorLogEntry } from '../models_new/types/error';
import {
  Observable,
  combineLatest,
  debounceTime,
  map,
  startWith,
  take,
} from 'rxjs';
import { LocalStorageService, StorageMethod } from './local-storage.service';
import { StateService } from '../auth/state.service';
import { IHasuraUser } from '../models_new/types/hasura-user';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { UpdateService } from './update.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  maxErrorLogSize = 100;

  constructor(
    private notifier: NotificationService,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private injector: Injector
  ) {}

  handleError(error: Error) {
    if (!this.isTokenRelatedError(error)) {
      this.logError(error);
      if (error.message !== 'http exception when calling webhook') {
        this.notifier.showError(error.message);
      }
    }
  }

  /**
   * @desc Prints the error on console, and stores it to the local error log, through setLocalErrorLog
   * @param {Error} error
   */
  public logError(error: Error): void {
    console.error(error);
    this.setLocalErrorLog(error);
  }

  /**
   * TODO: Keep track of this, so can be removed when Auth0's silent login is fixed.
   * @description patch to check for session-related issues, so it would force a log-out this case.
   * */
  private isTokenRelatedError(error: Error): boolean {
    if (
      error.message.includes('Unknown or invalid refresh token') ||
      error.message.includes('Missing Refresh Token')
    ) {
      /**
       * Once logged out, refresh instead of redirect => in case of public sims with session token broken,
       * on refresh will fetch via public, instead of getting stuck when trying to get the session details.
       * If otherwise, the refresh happens on a url where no un-logged user is authorised, will redirect to landingpage.
       */
      this.auth.logout({
        openUrl() {
          window.location.replace(window.location.origin);
        },
      });
      return true;
    } else {
      return false;
    }
  }

  navigateToUserErrors(errors: Error[]) {
    errors.forEach((error: Error) => {
      this.setLocalErrorLog(error);
    });
    this.router.navigate([pagesPATH.USER, pagesPATH.ERROR_PAGE]);
  }

  /**
   * @desc Given the error and a series of optional data, it composes a ErrorLogEntry type object.
   * @param {Error} err Original raw error thrown.
   * @param {string} id (Optional) Id of the error if any (This is used to avoid the same error being persisted twice when comming from ErrorComponent)
   * @param {ErrorContext} context (Optional) Details of the situation where the error occurred.
   * @param {string} customSuggestion (Optional) The given suggestions to the user, if any.
   * @param {string} customMessage (Optional) The  given error message to the user, if any
   * @returns {ErrorLogEntry}
   */
  public composeErrorLogEntry$(
    err: Error,
    id?: string,
    context?: ErrorContext,
    customSuggestion?: string,
    customMessage?: string
  ): Observable<ErrorLogEntry> {
    return combineLatest([
      this.injector
        .get<StateService>(StateService)
        .user$.pipe(take(1), startWith(null)),
      this.injector.get<UpdateService>(UpdateService).getReleases(),
    ]).pipe(
      debounceTime(1000),
      map(([user, ver]) => {
        return this.composeErrorLogEntryObject(
          err,
          id,
          context,
          customSuggestion,
          customMessage,
          user,
          ver?.tag
        );
      })
    );
  }

  /**
   * @desc Sets entries for the locallly stored error log.
   * @param {Error} err Raw error, to be formatted and logged.
   * @param {ErrorLogEntry} errEntry (Optional) Already formattted errorLog entry to be logged.
   * @returns {void}
   */
  public setLocalErrorLog(err: Error, errEntry?: ErrorLogEntry): void {
    const errorLog = this.getLocalErrorLog();
    const errEntryExists = errorLog?.find(
      (entry: any) => entry?.id === errEntry?.id
    );
    if (errEntryExists) {
      return;
    } else {
      if (!err && errEntry) {
        delete errEntry['guiText'];
        errorLog.push(errEntry);
      } else {
        errorLog.push(delete this.composeErrorLogEntryObject(err)['guiText']);
      }
      if (errorLog.length > this.maxErrorLogSize) {
        errorLog.shift();
      }
      this.localStorageService.setData(
        LocalStorageKey.ERROR_LOG,
        errorLog,
        StorageMethod.LOCAL
      );
    }
  }

  /**
   * @desc Returns an error log as an array of entries. Fallsback to empty array if none.
   * @returns {String[]}
   */
  public getLocalErrorLog() {
    return this.localStorageService.getData(LocalStorageKey.ERROR_LOG) ?? [];
  }

  /**
   * @desc It generates a valid Error log entry to be used internally.
   * @param {Error} err Original raw error thrown.
   * @param {string} id (Optional) Id of the error if any (This is used to avoid the same error being persisted twice when comming from ErrorComponent)
   * @param {ErrorContext} context (Optional) Details of the situation where the error occurred.
   * @param {string} customSuggestion (Optional) The given suggestions to the user, if any.
   * @param {string} customMessage (Optional) The  given error message to the user, if any
   * @param {string} user (Optional) Logged User's data to be binded to the error for further analysis
   * @param {string} org (Optional) Active organization's data object to be binded to the error for further analysis
   * @returns {ErrorLogEntry}
   */
  private composeErrorLogEntryObject(
    err: Error,
    id?: string,
    context?: ErrorContext,
    customSuggestion?: string,
    customMessage?: string,
    user?: IHasuraUser,
    ver?: string
  ): ErrorLogEntry {
    let processedContext = [];
    let guiMessage: ErrorLogEntry['guiText'] = {
      message: '',
      suggestion: '',
    };
    // Error message and suggestion
    switch (err?.name) {
      case 'SyntaxError':
        guiMessage.message =
          customMessage || 'Data seems to be corrupted (bad Syntax/Format)';
        guiMessage.suggestion =
          customSuggestion ||
          'Please try to generate a new item ' + context?.operation
            ? `(${context?.operation})`
            : '';
        break;
      case 'TypeError':
      case 'ReferenceError':
        guiMessage.message =
          customMessage || 'Some data appears to be missing...';
        guiMessage.suggestion =
          customSuggestion ||
          'Please go back and try again in a while. Contact support if the problem persists.';
        break;
      default:
        guiMessage.message = customMessage || 'Something went wrong...';
        guiMessage.suggestion =
          customSuggestion ||
          'Please go back and try again in a while. Contact support if the problem persists.';
        break;
    }
    // Error context assembly
    if (ver) {
      processedContext.push(`ver: ${ver}`);
    }
    const currentOrgState = this.localStorageService.getData(
      LocalStorageKey.STATE
    );
    const orgId = currentOrgState?.customerOrg || currentOrgState?.salesOrg;
    processedContext.push(`org: ${orgId ?? 'N/A'}`);
    processedContext.push(`usr: ${user?.id ?? 'N/A'}`);
    if (context) {
      processedContext.push(`fnc: ${context.operation}`);
      context.params?.forEach((item) =>
        processedContext.push(`${item.key}: ${item.value}`)
      );
    }
    for (const [key, value] of Object.entries(this.route.snapshot?.data)) {
      if (key === 'breadcrumb') {
        processedContext.push(`uri: ${value}`);
      } else {
        processedContext.push(`${key}: ${value}`);
      }
    }
    for (const [key, value] of Object.entries(this.route.snapshot?.params)) {
      processedContext.push(`${key}: ${value}`);
    }
    return {
      id: id,
      error: err.stack || err.stack,
      userId: user?.id,
      legacyLog: `${err.message} // ${new Date().toISOString()}`,
      context: [...processedContext, `url: ${this.router?.url}`],
      guiText: guiMessage,
      timestamp: new Date().getTime(),
    };
  }
}
