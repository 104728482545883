import { IHwSwType } from '../models_new/types/robot-config/hw-sw-type';
import { ISceneApiFileType } from '../models_new/types/simulation-api-file-type';

export class HardwareUtils {
  static labelifyScene(
    scene: ISceneApiFileType,
    hw_types: IHwSwType[]
  ): ISceneApiFileType {
    if (!scene.robot.frame.label) {
      scene.robot.frame.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.frame.type,
        'frame'
      );
    }
    if (!scene.robot.lift_kit.label) {
      scene.robot.lift_kit.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.lift_kit.type,
        'liftkit'
      );
    }
    if (!scene.robot.label) {
      scene.robot.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.type,
        'robot'
      );
    }
    if (!scene.robot.gripper.label) {
      scene.robot.gripper.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.gripper.type,
        'gripper'
      );
    }
    for (const conveyor of scene.conveyors) {
      if (!conveyor.label) {
        conveyor.label = HardwareUtils.findHwTypeLabel(
          hw_types,
          conveyor.type,
          'conveyor'
        );
      }
    }
    return scene;
  }

  static findHwTypeLabel(
    hw_types: IHwSwType[],
    name: string,
    type: string
  ): string {
    return (
      hw_types.find(
        (hw: IHwSwType) => hw.hw_sw_type.name === type && hw.name === name
      )?.label || 'N/A'
    );
  }
}
