<ng-container *ngIf="state || contextLost">
  <div
    class="loading-indicator"
    *ngIf="state.value || state.error || contextLost"
  >
    <!-- Loading state -->
    <mat-progress-spinner
      *ngIf="state?.value && !state?.error && !contextLost"
      mode="indeterminate"
      color="primary"
    ></mat-progress-spinner>

    <!-- Error state -->
    <app-highlighted-message-card
      *ngIf="state?.error && !contextLost"
      style="max-width: 95%"
      [type]="'error'"
      [title]="'Something went wrong...'"
      [message]="
        'Please refresh the page and try again. Contact support if the problem persists.'
      "
      [solidBackground]="true"
    ></app-highlighted-message-card>

    <!-- Crash state state -->
    <div *ngIf="contextLost" class="context-loss-container">
      <app-highlighted-message-card
        style="margin-bottom: 1em"
        [type]="'error'"
        [title]="'3D View crashed'"
        [message]="'Attempt restart?'"
        [solidBackground]="true"
      ></app-highlighted-message-card>
      <button
        mat-flat-button
        color="primary"
        pally-button-s
        (click)="clickedRestore$.emit(true)"
      >
        try to restart
      </button>
    </div>
  </div>
</ng-container>
