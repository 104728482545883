import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FilterTableData,
  ISortingOption,
} from '../../../models_new/types/sorting-option';
import { FilterType, IFilterData } from '../../../models_new/types/filter-data';
import { SortDirection } from '@angular/material/sort';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-sort-menu',
  template: `
    <div style="min-width: 400px;">
      <div class="mat-tab-label">
        <mat-icon class="tab-icon">sort</mat-icon>
        SORT BY
      </div>
      <mat-divider></mat-divider>

      <mat-action-list *ngIf="orderBy$ | async as orderBy">
        <button
          mat-list-item
          *ngFor="let column of orderByColumns"
          (click)="
            orderBy$.emit({
              column: column.id,
              order: changeMode(orderBy.order)
            })
          "
        >
          <mat-icon
            matListIcon
            class="order-icon"
            [ngStyle]="{
              transform: orderBy.order === 'asc' ? 'rotate(180deg)' : ''
            }"
            >{{
              orderBy.column === column.id && orderBy.order !== ''
                ? 'arrow_downward'
                : ''
            }}</mat-icon
          >
          {{ column.label }}
        </button>
      </mat-action-list>
    </div>
  `,
  styleUrls: ['./sorting.component.scss'],
})
export class SortMenuComponent implements OnInit {
  @Input() orderByColumns: ISortingOption[];
  @Input() initSort?: {
    column: string;
    order: SortDirection;
  };

  @Output() orderBy$: EventEmitter<{
    column: string;
    order: SortDirection;
  }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    /**
     *  TODO: Temporary fix with timeout at 0ms to prevent NG0100 errors.
     *  This prevents the Angular's change detection runtime error, caused by the
     *  orderByColumns not being initialized at the time of the first change detection.
     *  This is caused by the async pipe in the template; The orderByColumns are initialized
     *  in the parent component, but the async pipe in the template is executed before the
     *  parent component's ngOnInit.
     *  @ibern-rf
     */
    setTimeout(() => {
      if (this.orderByColumns && !this.initSort) {
        this.orderBy$.emit({ column: this.orderByColumns[0].id, order: '' });
      } else if (this.initSort) {
        this.orderBy$.emit(this.initSort);
      }
    });
  }

  changeMode(order: SortDirection) {
    if (order === 'desc') {
      return '';
    } else if (order === 'asc') {
      return 'desc';
    } else {
      return 'asc';
    }
  }
}

// Filter Menu
@Component({
  selector: 'app-filter-menu',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss'],
})
export class FilterMenuComponent {
  @Input() setupFilter: ISortingOption[];
  @Input() resultFilter: IFilterData[];
  @Input() orderByColumns: ISortingOption[];
  @Input() tab: number = 0;

  @Input() type: 'filter' | 'sort' = 'filter';

  private currentSetupFilterSingle: string[] = [];
  private currentSetupFilter: Map<string, string[]> = new Map<
    string,
    string[]
  >();
  private currentResultFilter: Map<string, string[]> = new Map<
    string,
    string[]
  >();
  private currentResultSlider: Map<string, number> = new Map<string, number>();

  @Output() menuClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() filtering$: EventEmitter<FilterTableData> = new EventEmitter();

  filterType = FilterType;
  showFilters: boolean = true;
  sliderValue: number = 100;

  filterObj: FilterTableData = new FilterTableData();

  constructor() {}

  public onChipClick(filterId: string, chipValue: string): void {
    let currentFilter = this.currentResultFilter.get(filterId) ?? [];

    if (currentFilter.find((o) => o == chipValue)) {
      currentFilter = currentFilter.filter((o) => o !== chipValue);
    } else {
      currentFilter.push(chipValue);
    }
    this.currentResultFilter.set(filterId, currentFilter);
    this.filterObj.resultFilter = this.currentResultFilter;
    this.filtering$.emit(this.filterObj);
  }

  public sliderDrag(sliderId: string, event): void {
    let value: number;
    if (event?.value) {
      value = event.value;
    } else if (event?.target) {
      value = Number((event?.target as HTMLInputElement)?.value);
    }
    if (value) {
      this.currentResultSlider.set(sliderId, value);
      this.filterObj.resultSlider = this.currentResultSlider;
      this.filtering$.emit(this.filterObj);
    }
  }

  public setupSingleClick(filterId: string, selected: boolean): void {
    if (selected) {
      this.currentSetupFilterSingle.push(filterId);
    } else {
      this.currentSetupFilterSingle = this.currentSetupFilterSingle.filter(
        (o) => o !== filterId
      );
    }
    this.filterObj.setupFilterSingle = this.currentSetupFilterSingle;
    this.filtering$.emit(this.filterObj);
  }

  public setupClick(filterId: string, selectedOptions: MatListOption[]): void {
    let options: string[] = selectedOptions.map((o) =>
      o.getLabel().substring(1, o.getLabel().length - 1)
    );
    this.currentSetupFilter.set(filterId, options);
    this.filterObj.setupFilter = this.currentSetupFilter;
    this.filtering$.emit(this.filterObj);
  }

  public getFilterAmount(): number {
    let setupAmount = 0;
    for (let val of this.currentSetupFilter.values()) {
      setupAmount += val.length;
    }

    let resultAmount = 0;
    for (let val of this.currentResultFilter.values()) {
      resultAmount += val.length;
    }

    let sliderAmount = this.currentResultSlider.size;
    return (
      setupAmount +
      resultAmount +
      sliderAmount +
      this.currentSetupFilterSingle.length
    );
  }

  public clearFilters(): void {
    this.currentSetupFilter = new Map<string, string[]>();
    this.currentSetupFilterSingle = [];
    this.currentResultFilter = new Map<string, string[]>();
    this.currentResultSlider = new Map<string, number>();
    this.filterObj = new FilterTableData();
    this.filtering$.emit(this.filterObj);

    this.menuClose.emit();

    //Clears UI filters
    setTimeout(() => {
      this.showFilters = false;
    }, 300);

    setTimeout(() => {
      this.showFilters = true;
    }, 350);
  }
}
