import { ModelUploadDescriptions } from './model-upload-descriptions';
import { ModelType } from '../classes/model-type';

export const ModelTypes = {
  GRIPPER: new ModelType(
    'Gripper',
    'gripper',
    1,
    ModelUploadDescriptions.editor.gripper,
    true
  ),
  CONVEYOR: new ModelType(
    'Conveyor',
    'conveyor',
    2,
    ModelUploadDescriptions.editor.any
  ),
  PALLET: new ModelType(
    'Pallet',
    'pallet',
    1,
    ModelUploadDescriptions.editor.any
  ),
  FRAME: new ModelType(
    'Base',
    'frame',
    2,
    ModelUploadDescriptions.editor.frame
  ),
  LIFTKIT: new ModelType(
    'Lifting column',
    'liftkit',
    2,
    ModelUploadDescriptions.editor.any
  ),
  ROBOT: new ModelType('Robot', 'robot', 1, ModelUploadDescriptions.editor.any),
};
