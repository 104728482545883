import * as THREE from 'three';
import { AltLayout } from '../enums/alt-layout';
import { PalletPosition } from '../enums/pallet-position';
import { ConveyorDirection } from '../enums/sim-config-conveyor-dir';
import { StackingMethod } from '../enums/stacking-method';
import { pagesPATH } from './pages';
import { IOrthographicCameraConfig } from '../types/3dview/orthographic-camera-config';
import { IPerspectiveCameraConfig } from '../types/3dview/perspective-camera-config';

/**
 *
 * @param {String} settings.defaultGlobalDateLocale Preferred timestamp format global for project
 *
 */

export const settings = {
  primaryPallet: PalletPosition.RIGHT,
  secondaryPallet: PalletPosition.LEFT,
  defaultNoOfLayers: 5,
  defaultAltLayout: AltLayout.MIRROR,
  defaultStackingMethod: StackingMethod.ROTATE,
  defaultSimConfigConveyorDir: ConveyorDirection.FRONT,
  defaultProjectName: 'Unnamed project',
  pallyDescriptionsURL: 'https://pallydescriptions.azureedge.net/models/',
  publicmodelsPallyDescriptionsURL:
    'https://pallydescriptions.azureedge.net/public-models/',
  /**
   * Kept for legacy purposes, will be removed once 3dview is fully refactored.
   * @deprecated Use "publicmodelsPallyDescriptionsURL" instead.
   */
  publicPallyDescriptionsURL:
    'https://pallydescriptions.azureedge.net/publicmodels/',
  defaultLabelDirection: 'optimized',
  defaultGlobalDateLocale: 'en-GB',

  // 3D View settings
  view3d: {
    /**
     * @deprecated Use the settings.defaultCameraPosition in application-settings.ts instead!
     * Will be removed as 3dview's get's refactored
     */
    oldDefaultCameraPosition: new THREE.Vector3(-0.75, 1.75, 1.5),
    defaultCameraPosition: new THREE.Vector3(-1, 1.5, 2),
    simvisCameraPosition: new THREE.Vector3(-1.25, 1.75, 2.5),
    myURCameraPosition: new THREE.Vector3(-1.5, 1.75, 1.5),
    defaultPerspectiveCamera: {
      fov: 70,
      near: 0.01,
      far: 10000,
    } as IPerspectiveCameraConfig,
    defaultOrthographicCamera: {
      width: 3,
      height: 3,
      near: 0.01,
      far: 3,
    } as IOrthographicCameraConfig,
    hwImageCameraPosition: new THREE.Vector3(
      -2.60548625689798,
      1.8210483806834288,
      2.9840108500634424
    ),
    globePointsUrl:
      'https://pallydescriptions.blob.core.windows.net/public-models/globe-points-3d.json',
  },

  /**
   * Custom pallet settings, such as;
   * - default name,
   * - dimensions (default, max and min) set in millimeters
   */
  customPalletSettings: {
    name: {
      default: 'Custom pallet',
    },
    palletHeight: {
      min: 1,
      max: 2000,
      default: 150,
    },
    length: {
      min: 1,
      max: 5000,
      default: 1000,
    },
    width: {
      min: 1,
      max: 5000,
      default: 600,
    },
  },

  // Fast track
  fastTrackMainRoute: pagesPATH.FAST_TRACK,
  fastTrackRoutes: [
    pagesPATH.FAST_TRACK_EXPLORE,
    pagesPATH.FAST_TRACK_PATTERN,
    pagesPATH.FAST_TRACK_PRODUCT,
    pagesPATH.FAST_TRACK_REGION,
    pagesPATH.FAST_TRACK_USER_INFO,
  ],
  privacyPolicyUrl: 'https://www.rocketfarm.no/privacy-policy',
  eulaUrl:
    'https://www.rocketfarm.no/software-products/myrobotcloud/myrobot-cloud-eula',
  mrcLogoWhite: '2MWT9Kl06VYZIqW11BnBxg',
  mrcLogoBlue: '58ibDnhQkF8bGzYwR90eoC',
};
