import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import {
  shareReplay,
  take,
  tap,
  map,
  switchMap,
  filter,
  startWith,
  skipWhile,
} from 'rxjs/operators';
import { settings } from 'src/app/models_new/config/application-settings';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import {
  InfoApiService,
  IOpenSimContent,
} from 'src/app/services/api/info-api.service';
import {
  IFastTrackQueryParams,
  PublicApiService,
} from 'src/app/services/api/public-api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import { MarketingTagID } from '../../models_new/config/marketing-tags';
import { GoogleAnalyticsService } from '../../services/google-analytics-service.service';
import { IApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';

export interface IReadyOpenSimulation {
  id?: string;
  leadOwner?: string;
  leadOwnerType?: 'vendor' | 'backoffice' | 'provider';
  solutionId?: string;
  name: string;
  // Filled if fetched with pattern id
  solution?: {
    name: string;
    solution_provider_id?: string;
    solution_provider?: {
      name: string;
    };
    metadata?: {
      [key: string]: string;
    };
    externalUrl?: string;
  };
  sceneId?: string;
  strategyId?: string;
  progress?: IApiSimulation['simulation_status']['progress'] | string;
  cpm?: IApiSimulation['simulation_status']['cpm'] | string;
  eta?: IApiSimulation['simulation_status']['eta'] | string;
  status?: IApiSimulation['simulation_state'];
  metadata?: {
    [key: string]: string;
  };
  externalUrl?: string;
  startingPrice?: string;
}

export interface IOpenSimPage {
  path: string;
  step: number;
  valid: boolean;
  content?: IOpenSimContent;
  done: boolean;
  analyticsTags?: {
    backClick?: MarketingTagID;
    nextClick?: MarketingTagID;
  };
}

@Injectable({
  providedIn: 'root',
})
export class OpenSimService {
  queriedParams: IFastTrackQueryParams = {};
  #selectedPageIndex: number = 0;
  #selectedStepIndex: number = 0;

  set selectedPageIndex(newIndex: number) {
    if (newIndex < 0) {
      return;
    }

    this.#selectedPageIndex = newIndex;
    this.#updateSelectedStepIndex(newIndex);
  }

  get selectedPageIndex() {
    return this.#selectedPageIndex;
  }

  get selectedStepIndex() {
    return this.#selectedStepIndex;
  }

  // Updates the selected step by finding the nearest available index.
  #updateSelectedStepIndex(newIndex: number, previousIndex?: number) {
    this.pagesSortedList$
      .pipe(
        take(1),
        map((pagesSortedList) => pagesSortedList.map((page) => page.step - 1))
      )
      .subscribe((availableIndices) => {
        if (availableIndices.includes(newIndex)) {
          this.#selectedStepIndex = newIndex;
        } else {
          let newIndexLocation = availableIndices.find(
            (index) => index > newIndex
          );
          if (!newIndexLocation) {
            return;
          }

          const oldIndex = previousIndex || this.#selectedStepIndex;
          if (newIndex - oldIndex < 0) {
            newIndexLocation--;
          }

          // Recurse in order to only set a valid index location.
          this.#updateSelectedStepIndex(newIndexLocation, newIndex);
        }
      });
  }

  // This is the default set of pages which can be shown to the user.
  // A filtering and sorting is made based on the route, and set to
  // `pagesSortedList`. This is the pages actally shown to the user.
  defaultPages: IOpenSimPage[] = [
    {
      path: pagesPATH.FAST_TRACK_PRODUCT,
      step: 1,
      valid: false,
      content: null,
      done: true,
      analyticsTags: {
        backClick:
          'Product Specifications - Fast Track - MyRobot.cloud - Back - Click',
        nextClick:
          'Product Specifications - Fast Track - MyRobot.cloud - Next - Click',
      },
    },
    {
      path: pagesPATH.FAST_TRACK_PATTERN,
      step: 2,
      valid: false,
      content: null,
      done: false,
      analyticsTags: {
        backClick:
          'Pallet Specifications - Fast Track - MyRobot.cloud - Back - Click',
        nextClick:
          'Pallet Specifications - Fast Track - MyRobot.cloud - Next - Click',
      },
    },
    {
      path: pagesPATH.FAST_TRACK_REGION,
      step: 3,
      valid: false,
      content: null,
      done: false,
      analyticsTags: {
        backClick: 'Region - Fast Track - MyRobot.cloud - Back - Click',
        nextClick: 'Region - Fast Track - MyRobot.cloud - Next - Click',
      },
    },
    {
      path: pagesPATH.FAST_TRACK_USER_INFO,
      step: 4,
      valid: false,
      content: null,
      done: false,
      analyticsTags: {
        backClick: 'User Info - Fast Track - MyRobot.cloud - Back - Click',
      },
    },
  ];

  // This is the filtered and sorted pages actally shown to the user.
  pagesSortedList$: Observable<IOpenSimPage[]>;

  contentFulContent$: Observable<IOpenSimContent[]>;
  contentFulFooter$: Observable<IInfoCard[]>;
  readySimulations$: Observable<IReadyOpenSimulation[]>;
  embedHandler$: Observable<DataRequestState<Boolean>>;

  embed: boolean = false;
  started: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private infoApi: InfoApiService,
    private localStorage: LocalStorageService,
    private errorHandler: ErrorHandlerService,
    private publicApi: PublicApiService,
    private gas: GoogleAnalyticsService
  ) {
    if (this.pageInIframe()) {
      this.embed = true;
      this.started = true;
    }

    this.embedHandler$ = combineLatest([
      of(this.route.snapshot.queryParams.apiKey || null),
      of(this.checkReferrer()),
    ]).pipe(
      take(1),
      switchMap(([apiKey, referrer]) => {
        if (this.embed && this.pageInIframe()) {
          if (!apiKey || !referrer) {
            return of(false);
          } else {
            console.debug('Referrer: ', referrer);
            return this.publicApi.validateEmbedApiKey(apiKey, referrer);
          }
        } else {
          return of(true);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      toRequestState()
    );

    this.pagesSortedList$ = this.router.events.pipe(
      filter((route_event) => route_event instanceof NavigationEnd),
      startWith(null),
      map((_) => {
        const providerId = this.route.snapshot.queryParams.sp;
        const solutionId = this.route.snapshot.queryParams.si;

        if (providerId || solutionId) {
          // Skip the Region step if the query params already point
          // to a solution or priovider.
          return this.defaultPages.filter(
            (page) => page.path !== pagesPATH.FAST_TRACK_REGION
          );
        } else {
          return this.defaultPages;
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    /**
     * When navigate back or forward catch which step is active.
     */
    combineLatest([this.router.events, this.pagesSortedList$])
      .pipe(
        filter(([route_event, _]) => route_event instanceof NavigationEnd),
        take(1),
        tap((_) => {
          this.queriedParams.vendorName =
            this.route.snapshot.queryParams.vn || null;
          this.queriedParams.vendorId =
            this.route.snapshot.queryParams.vi || null;
          this.queriedParams.providerId =
            this.route.snapshot.queryParams.sp || null;
          this.queriedParams.solutionId =
            this.route.snapshot.queryParams.si || null;
        }),
        startWith([{ url: this.router.url }, this.defaultPages]),
        tap(([p1, p2]) => {
          const navigation = p1 as NavigationEnd;
          const pagesSortedList = p2 as IOpenSimPage[];

          let route;
          if (navigation.url.includes('?')) {
            route = navigation.url.slice(
              navigation.url.lastIndexOf('/') + 1,
              navigation.url.indexOf('?')
            );
          } else {
            route = navigation.url.slice(
              navigation.url.lastIndexOf('/') + 1,
              navigation.url.length
            );
          }

          if (route !== settings.fastTrackMainRoute && !this.embed) {
            const newIndex = route
              ? pagesSortedList.map((sl) => sl.path).indexOf(route)
              : 0;
            // If selectedPageIndex is not in sync, update it.
            if (newIndex !== this.selectedPageIndex) {
              this.selectedPageIndex = newIndex;
            }
          }
        })
      )
      .subscribe();

    this.contentFulContent$ = this.embedHandler$.pipe(
      skipWhile((_) => !_),
      switchMap((_) =>
        combineLatest([
          this.infoApi.getOpenSimInfo(),
          this.pagesSortedList$.pipe(startWith(this.defaultPages)),
        ])
      ),
      take(1),
      tap(([content, pagesSortedList]) => {
        content.forEach((c) => {
          const item = pagesSortedList.find((psl) => psl.step === c.step);
          item ? (item.content = c) : null;
        });
      }),
      map(([content, _]) => {
        return content;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.contentFulFooter$ = this.infoApi.getLandingPageFooterInfo().pipe(
      take(1),
      map((v: IInfoCard[]) => {
        const clone = ObjectUtils.cloneObject(v);
        clone.forEach((v) => {
          v.text = v.text?.split('\n') as any;
        });
        return clone;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  pageInIframe() {
    return window.location !== window.parent.location;
  }

  checkReferrer(): string {
    return document.referrer ? document.referrer : null;
  }

  reset() {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      pagesSortedList.forEach((s) => {
        s.done = false;
        s.valid = false;
      });
      this.selectedPageIndex = 0;
    });
  }

  showNavTopBottom() {
    return settings.fastTrackRoutes.some((v) =>
      this.router.routerState.snapshot.url.includes(v)
    );
  }

  setStep(step: number) {
    this.selectedPageIndex = step - 1;
  }

  routerNavigate(commands: any[], extras?: NavigationExtras) {
    if (!this.embed) {
      this.router.navigate(commands, extras);
    }
  }

  start() {
    this.started = !this.started;
  }

  back() {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      const analyticsTag =
        pagesSortedList[this.selectedPageIndex].analyticsTags.backClick;
      if (analyticsTag) {
        this.gas.addEvent(analyticsTag, null, this.gas.getLastParam());
      }

      if (this.selectedPageIndex > 0) {
        this.navigateToPreviousPage(pagesSortedList);
      } else if (this.selectedPageIndex === 0 && this.started) {
        this.started = false;
      } else {
        this.routerNavigate([pagesPATH.FAST_TRACK], {
          queryParamsHandling: 'preserve',
        });
      }
    });
  }

  next() {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      const analyticsTag =
        pagesSortedList[this.selectedPageIndex].analyticsTags.nextClick;

      if (analyticsTag) {
        this.gas.addEvent(analyticsTag, null, this.gas.getLastParam());
      }

      const pageIndexOfSimulationStep = pagesSortedList.findIndex(
        (page) => page.path === pagesPATH.FAST_TRACK_USER_INFO
      );
      const pageIndexBeforeSimulationStep = pageIndexOfSimulationStep - 1;

      if (
        this.selectedPageIndex < pagesSortedList.length - 1 &&
        this.selectedPageIndex < pageIndexBeforeSimulationStep
      ) {
        this.navigateToNextPage(pagesSortedList);
      } else if (this.selectedPageIndex === pageIndexBeforeSimulationStep) {
        const os = this.localStorage.getData(LocalStorageKey.OPEN_SIM);
        const providerId = this.queriedParams.providerId;
        const solutionId = this.queriedParams.solutionId;
        if (os || providerId || solutionId) {
          this.initSimulationsThenNavigateToNextPage(os, pagesSortedList);
        } else {
          this.errorHandler.handleError(
            new Error('We are missing your info or region...')
          );
        }
      } else {
        return;
      }
    });
  }

  initSimulationsThenNavigateToNextPage(
    os: any,
    pagesSortedList: IOpenSimPage[]
  ) {
    this.initSimulations(os ?? {});
    this.readySimulations$.pipe(take(1)).subscribe({
      next: (_) => {
        this.navigateToNextPage(pagesSortedList);
      },
      error: (err) => {
        this.errorHandler.handleError(err);
      },
    });
  }

  navigateToNextPage(pagesSortedList: IOpenSimPage[]) {
    if (this.selectedPageIndex === pagesSortedList.length - 1) {
      return;
    }

    this.selectedPageIndex++;
    this.routerNavigate(
      [pagesPATH.FAST_TRACK, pagesSortedList[this.selectedPageIndex].path],
      { queryParamsHandling: 'preserve' }
    );
  }

  navigateToPreviousPage(pagesSortedList: IOpenSimPage[]) {
    if (this.selectedPageIndex === 0) {
      return;
    }

    this.selectedPageIndex--;
    this.routerNavigate(
      [pagesPATH.FAST_TRACK, pagesSortedList[this.selectedPageIndex].path],
      { queryParamsHandling: 'preserve' }
    );
  }

  findAndNavigateToPreviousPage(currentStep: number) {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      const pageIndexOfStep = pagesSortedList.findIndex(
        (page) => page.step === currentStep
      );
      const previousPageIndexStep = pageIndexOfStep - 1;
      this.routerNavigate(
        [pagesPATH.FAST_TRACK, pagesSortedList[previousPageIndexStep].path],
        { queryParamsHandling: 'preserve' }
      );
    });
  }

  prevIsValid(currentStep: number): Observable<boolean> {
    return this.pagesSortedList$.pipe(
      map((pagesSortedList) => {
        const pageIndexOfStep = pagesSortedList.findIndex(
          (page) => page.step === currentStep
        );
        const previousPageIndexStep = pageIndexOfStep - 1;
        const prevIsValid = pagesSortedList[previousPageIndexStep].valid;
        if (!prevIsValid) {
          this.selectedPageIndex = previousPageIndexStep;
        }
        return prevIsValid;
      })
    );
  }

  setStepIsValid(valid: boolean) {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      pagesSortedList[this.selectedPageIndex].valid = valid;
      pagesSortedList[this.selectedPageIndex].done = valid;
    });
  }

  initSimulations(os: {
    continent: string;
    country: string;
    email: string;
    fullName: string;
    region: string;
  }) {
    this.readySimulations$ = this.getVendorIdFromVendorName(
      this.queriedParams.vendorName
    ).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          this.queriedParams.vendorId = vendorId;
          this.queriedParams.vendorName = null;
        }
        return this.publicApi.getAvailableSolutions(
          os.continent,
          os.country,
          os.email,
          os.fullName,
          os.region,
          this.queriedParams
        );
      }),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private getVendorIdFromVendorName(vendorName: string): Observable<string> {
    return vendorName
      ? this.publicApi
          .getVendorOrganizations()
          .pipe(
            map(
              (vendorOrganizations) =>
                vendorOrganizations.find((vendor) => vendor.name === vendorName)
                  ?.id || null
            )
          )
      : of(null);
  }

  startSimulations(): Observable<string> {
    const pp = this.localStorage.getData(
      LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
    );
    const os = this.localStorage.getData(LocalStorageKey.OPEN_SIM);

    if (!pp) {
      this.errorHandler.handleError(
        new Error('We are missing your product or pattern configuration...')
      );
      return of('');
    }
    if (!os) {
      this.errorHandler.handleError(
        new Error('We are missing your info or region...')
      );
      return of('');
    } else {
      return this.readySimulations$.pipe(
        switchMap((as: IReadyOpenSimulation[]) =>
          this.publicApi.insertOpenSimulations(pp, os, as, this.queriedParams)
        )
        // switchMap((openSimId: string) => combineLatest([
        //   of(openSimId),
        //   this.embedData$.pipe(take(1), switchMap((_embedData) => this.publicApi.updateEmbedKeyUsage(_embedData.apiKey)))
        // ])),
        // map(([openSimId, updateEmbedKey]) => {

        // return openSimId;
        // })
      );
    }
  }
}
