import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { getGPUTier } from 'detect-gpu';

// Look here for more info about these properties: https://github.com/pmndrs/detect-gpu
export interface GpuInfo {
  tier: number;
  isMobile: boolean;
  type: string;
  fps: number;
  gpu: string;
}

@Injectable({
  providedIn: 'root',
})
export class GpuDetectService implements OnDestroy {
  public gpuInfo$ = new ReplaySubject<GpuInfo>(1);

  constructor() {
    (async () => {
      const gpuInfo = (await getGPUTier()) as GpuInfo;
      this.gpuInfo$.next(gpuInfo);
    })();
  }

  ngOnDestroy(): void {
    this.gpuInfo$.complete();
  }
}
