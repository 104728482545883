import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-bottom-navbar',
  templateUrl: './dialog-bottom-navbar.component.html',
  styleUrls: ['./dialog-bottom-navbar.component.scss'],
})
export class DialogBottomNavbarComponent {
  @Input() title: string = 'Missing title input';
  @Input() dialogMode: boolean = false;
  @Input() primaryButton: 'next' | 'confirm' = 'next';
  @Input() secondaryButton: 'skip' | 'back' | 'hidden' | 'cancel' = 'hidden';
  @Input() tertiaryButton?: string;
  @Output() nextClick = new EventEmitter<boolean>();
  @Output() backClick = new EventEmitter<boolean>();
  @Output() skipClick = new EventEmitter<boolean>();
  @Output() tertiaryButtonClick = new EventEmitter<boolean>();

  constructor() {}
}
