import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import {
  combineLatestWith,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { pagesPATH } from '../../../../models_new/config/pages';
import {
  ApiStrategy,
  IApiStrategy,
} from 'src/app/models_new/classes/api-models/ApiStrategy';
import { defaultApiStrategy } from 'src/app/models_new/config/default/api-default/default-api-strategy';
import { NotificationService } from 'src/app/services/notification.service';
import { StateService } from 'src/app/auth/state.service';
import { FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { compareVersions } from 'compare-versions';
import { InfoPopupComponent } from 'src/app/components/dialogs/info-popup/info-popup.component';
import {
  softwareSmartExitDefaultValues,
  ISmartExitConfig,
} from 'src/app/models_new/config/softwareConfigurationFields';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { ConveyorDirection } from 'src/app/models_new/enums/sim-config-conveyor-dir';
import { PresetPickerData } from 'src/app/models_new/types/preset-picker-data';
import { IHwSwType } from 'src/app/models_new/types/robot-config/hw-sw-type';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { toIQuarternion } from 'src/app/utils/three-utils';
import { ISoftwareFields, ISoftwareFormGroup } from './software-fields-type';
import { IStrategyApiFileType } from 'src/app/models_new/types/simulation-api-file-type';
import { ObjectUtils } from 'src/app/utils/object';

/**
 * Can be used as:
 * - A child component with [presetStrategy] input.
 * - Routed to with queryParams.origin = url to origin.
 * - As a standalone component (REQUIRES queryParams.strategyId).
 */
@Component({
  selector: 'app-software-details',
  templateUrl: './software-details.component.html',
  styleUrls: ['./software-details.component.scss'],
})
export class SoftwareDetailsComponent implements OnInit, OnDestroy {
  @Input() presetStrategy: ApiStrategy;

  /**
   * isDialog & configId & xxx Meant to be used when the component is used as a dialog.
   */
  @Input() isDialog: boolean = false;
  @Input() configId: string;
  @Output() dialogEvent = new EventEmitter<ApiStrategy>();

  @Output() saveStrategy = new EventEmitter<{
    name: string;
    strategy: IStrategyApiFileType;
  }>();

  title: string = 'Software Configuration';
  action$: Observable<{ label: string; value: string; icon?: string }>;
  isCreating$: Observable<boolean>;

  destroy$ = new ReplaySubject<void>();
  strategy$: Observable<ApiStrategy>;

  singlePallet: boolean = false;
  initializedPanel: boolean = false;

  expandedTab: null | 'advanced' | 'smart_exits' = null;
  safetyPresets: IHwSwType[];
  polyscopeVersions$: Observable<IHwSwType[]>;
  pallyVersions$: Observable<IHwSwType[]>;
  gripperOptimizations$: Observable<IHwSwType[]>;
  smartAccelerations$: Observable<string[]> = of([
    'Slowest',
    'Slower',
    'Medium',
    'Faster',
    'Fastest',
  ]);
  fixedApproaches$: Observable<string[]> = of(['Enabled', 'Disabled']);
  smartExits$: Observable<PresetPickerData[]>;
  safetyPresets$: Observable<PresetPickerData[]>;

  changedFields: string[] = [];

  formGroup: ISoftwareFormGroup;

  smartExitsOverrideState: boolean;

  // Temporary workaround for smart exits so its data can be read when the group is disabled.
  private smartExitsData: ISoftwareFields['smart_exits'];

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private softwareApi: SoftwareApiService,
    private notification: NotificationService,
    private stateService: StateService,
    private infoApi: InfoApiService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
    this.polyscopeVersions$ = this.softwareApi
      .fetchSwTypeByName('polyscope version')
      .pipe(map((m) => this.getSortedVersions(m)));
    this.pallyVersions$ = this.softwareApi
      .fetchSwTypeByName('pally version')
      .pipe(map((m) => this.getSortedVersions(m)));
    this.gripperOptimizations$ = this.softwareApi.fetchSwTypeByName(
      'gripper optimization'
    );
  }

  ngOnInit(): void {
    // If the ID of the item is 'new' then its a request to create a new item
    this.isCreating$ = this.route.params.pipe(
      map((params) => this.configId === 'new' || params.id === 'new')
    );

    if (this.presetStrategy) {
      this.strategy$ = of(this.presetStrategy).pipe(
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    } else {
      this.strategy$ = this.route.params.pipe(
        switchMap((params) => {
          if (this.configId === 'new' || params.id === 'new') {
            return of(ObjectUtils.cloneObject(defaultApiStrategy));
          } else {
            return this.softwareApi.fetchStrategy(
              this.route.snapshot.queryParams.strategyId ||
                this.route.snapshot.params?.id
            );
          }
        }),
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }

    this.safetyPresets$ = this.strategy$.pipe(
      take(1),
      combineLatestWith(this.softwareApi.fetchSwTypeByName('safety preset')),
      take(1),
      map(([strategy, m]): PresetPickerData[] => {
        this.safetyPresets = m;
        return m.map((m) => {
          return {
            id: m.name,
            label: m.label,
            selected: strategy.data.safety_preset === m.name,
            metadata: m.metadata,
            pallyVersion: m.label,
          };
        });
      }),
      tap((presets) =>
        this.updateField(
          presets.find((preset) => preset.selected) || null,
          'robot_safety',
          'safety_preset'
        )
      )
    );

    this.smartExits$ = this.strategy$.pipe(
      take(1),
      combineLatestWith(of(softwareSmartExitDefaultValues)),
      take(1),
      map(([strategy, m]) => {
        return m.map((m) => {
          return {
            id: m.id,
            label: 'From ' + m.direction,
            selected:
              JSON.stringify(strategy.data.smart_exits) ===
              JSON.stringify(m.fields),
            image: m.image,
          };
        });
      })
    );

    this.strategy$
      .pipe(
        take(1),
        tap((strategy: IApiStrategy) => this.createFormFields(strategy)),
        switchMap(
          (_) => this.getField('advanced', 'single_pallet').valueChanges
        ),
        takeUntil(this.destroy$),
        tap((v) => (this.singlePallet = v))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @returns  Returns the strategy object and the strategy name
   */
  getStrategyOnDemand(): { name: string; strategy: IStrategyApiFileType } {
    if (this.formGroup.invalid) {
      this.notification.showError('Some fields have errors!');
      return null;
    }

    const fieldValues = this.formGroup.value;
    const strategy = this.fieldToStrategy(fieldValues);

    return {
      name: fieldValues.general.name,
      strategy: strategy.data,
    };
  }

  saveSoftwareConfiguration(): void {
    if (this.formGroup.invalid) {
      this.notification.showError('Some fields have errors!');
      return;
    }

    const fieldValues = this.formGroup.value;
    const strategy = this.fieldToStrategy(fieldValues);
    if (this.presetStrategy) {
      this.saveStrategy.emit({
        name: fieldValues.general.name,
        strategy: strategy.data,
      });
    } else {
      this.route.params
        .pipe(
          switchMap((params) =>
            combineLatest([
              of(params),
              this.stateService.getCustomerOrSalesOrganization(),
              this.isCreating$,
            ])
          ),
          switchMap(([params, org, isCreating]) => {
            if (isCreating) {
              return this.softwareApi.insertStrategy(
                strategy.name,
                null,
                strategy.data,
                org.id
              );
            } else {
              return this.softwareApi.updateStrategy(
                params.id,
                strategy.name,
                null,
                strategy.data
              );
            }
          }),
          take(1)
        )
        .subscribe({
          next: (res) => {
            this.notification.showSuccess(
              `${strategy.name} saved to your Software Configurations!`
            );
            if (this.isDialog) {
              this.dialogEvent.emit({ ...strategy, id: res.id });
            } else if (this.route.snapshot.queryParams.origin) {
              this.router.navigateByUrl(this.route.snapshot.queryParams.origin);
            } else {
              this.router.navigate([
                pagesPATH.INVENTORY,
                pagesPATH.SOFTWARE_LIST,
              ]);
            }
          },
          error: (e) => {
            this.notification.showError(e.message);
          },
        });
    }
  }

  /**
   * @desc Returns the requested field from the form group.
   * @method getField
   * @param {string} formGroupName
   * @param {string} formControlName
   * @returns {AbstractControl}
   */
  private getField(
    formGroupName: string,
    formControlName: string
  ): AbstractControl {
    return this.formGroup.get(formGroupName).get(formControlName);
  }

  /**
   * @desc Returns the value of the requested field from the form group. Meant to be used in the template.
   * @method getFieldValue
   * @param {string} formGroupName
   * @param {string} formControlName
   * @returns {PresetPickerData}
   */
  public getFieldValue(
    formGroupName: string,
    formControlName: string
  ): PresetPickerData {
    return (
      this.formGroup.get(formGroupName)?.get(formControlName)?.value || null
    );
  }

  /**
   * @desc Updates the given field with the given value.
   * @param {string | number | boolean} value
   * @param {string} formGroupName
   * @param {string} controlName
   */
  private updateField(
    value: string | number | boolean | PresetPickerData,
    formGroupName: string,
    controlName: string
  ): void {
    this.getField(formGroupName, controlName).setValue(value);
  }

  /**
   * @desc Updates the smart exits field with the selected smart exit.
   * @method updateSmartExits
   * @param {string} smartExitId
   */
  public updateSmartExits(smartExitId: string): void {
    const smartExitConfig: ISmartExitConfig =
      softwareSmartExitDefaultValues.find((f) => f.id === smartExitId);
    if (smartExitConfig) {
      for (let group in smartExitConfig.fields.left_pallet) {
        for (let control in smartExitConfig.fields.left_pallet[group]) {
          this.formGroup
            .get('smart_exits')
            .get('left_pallet')
            .get(group)
            .get(control)
            .setValue(smartExitConfig.fields.left_pallet[group][control]);
          this.changedFields.push('left_' + group + '_' + control);
          setTimeout(() => {
            this.changedFields.pop();
          }, 2000);
        }
      }
      for (let group in smartExitConfig.fields.right_pallet) {
        for (let control in smartExitConfig.fields.right_pallet[group]) {
          this.formGroup
            .get('smart_exits')
            .get('right_pallet')
            .get(group)
            .get(control)
            .setValue(smartExitConfig.fields.right_pallet[group][control]);
          this.changedFields.push('right_' + group + '_' + control);
          setTimeout(() => {
            this.changedFields.pop();
          }, 2000);
        }
      }
    }

    this.updateField(smartExitId, 'smart_exits', 'smart_exits_preset');
  }

  /**
   * @desc Updates the safety preset field with the selected safety preset.
   * @method updateSafetyPresets
   * @param {string} safetyPresetId
   */
  public updateSafetyPresets(safetyPresetId: PresetPickerData): void {
    this.updateField(
      safetyPresetId.metadata.value,
      'specifications',
      'max_speed'
    );
    this.changedFields.push('max_speed');
    setTimeout(() => {
      this.changedFields.pop();
    }, 2000);
  }

  /**
   * @desc Shows the info popup for the given field.
   * @method showInfo
   * @param {string} fieldId
   */
  public showInfo(fieldId: string): void {
    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      {
        id: fieldId,
        dataSource: this.infoApi.getSoftwareInfoCards(fieldId),
      },
      true
    );
  }

  /**
   * @desc Converts the form group values to a strategy object.
   * @method fieldToStrategy
   * @param {ISoftwareFields} fieldValues
   * @returns {ApiStrategy}
   */
  private fieldToStrategy(fieldValues: ISoftwareFields): ApiStrategy {
    const strategy = new ApiStrategy(
      ObjectUtils.cloneObject(defaultApiStrategy)
    );
    strategy.name = fieldValues.general.name;
    strategy.data.above_pick_position_mm =
      fieldValues.advanced.above_pick_position_mm;
    strategy.data.approach_distance = fieldValues.advanced.approach_distance;
    strategy.data.box_free_height = fieldValues.advanced.box_free_height;
    strategy.data.fixed_approach = fieldValues.advanced.fixed_approach
      ? true
      : false;
    strategy.data.gripper_optimization =
      fieldValues.specifications.gripper_optimization;
    strategy.data.high_approach_boost_percentage =
      fieldValues.advanced.high_approach_boost_percentage;
    strategy.data.max_acceleration =
      fieldValues.specifications.max_acceleration;
    strategy.data.max_speed = fieldValues.specifications.max_speed;
    strategy.data.number_of_pallets = fieldValues.advanced.single_pallet
      ? 1
      : 2;
    strategy.data.safety_preset = (
      fieldValues.robot_safety.safety_preset as PresetPickerData
    ).id;
    strategy.data.smart_acceleration = fieldValues.advanced.smart_acceleration;
    strategy.data.smart_exits = this.getSmartExitGroupValues(
      fieldValues.smart_exits
    );
    strategy.data.smart_exits_override =
      fieldValues.smart_exits.smart_exits_override;
    strategy.data.software.pally_version = fieldValues.software.pally_version;
    strategy.data.software.polyscope_version =
      fieldValues.software.polyscope_version;
    strategy.data.waiting_position.orientation = toIQuarternion(
      strategy.data.waiting_position.orientation as THREE.Quaternion
    );
    return strategy;
  }

  /**
   * @desc Temporary workaround for smart exits so its data can be read when the group is disabled.
   * @todo Restore to the original method when the fields are set back to Field type.
   * Checkout more details on why this happens {@link https://rocketfarm.atlassian.net/browse/PALLY-3895 here}.
   * @method strategyToField
   * @param {ISoftwareFields.smart_exits} formSmartExits
   * @returns {IStrategyApiFileType.smart_exits}
   */
  private getSmartExitGroupValues(
    formSmartExits: ISoftwareFields['smart_exits']
  ): IStrategyApiFileType['smart_exits'] {
    if (!formSmartExits.left_pallet || !formSmartExits.right_pallet)
      formSmartExits = this.smartExitsData;
    return {
      left_pallet: {
        x: {
          from: formSmartExits.left_pallet.x.from,
          to: formSmartExits.left_pallet.x.to,
        },
        y: {
          from: formSmartExits.left_pallet.y.from,
          to: formSmartExits.left_pallet.y.to,
        },
      },
      right_pallet: {
        x: {
          from: formSmartExits.right_pallet.x.from,
          to: formSmartExits.right_pallet.x.to,
        },
        y: {
          from: formSmartExits.right_pallet.y.from,
          to: formSmartExits.right_pallet.y.to,
        },
      },
    };
  }

  /**
   * @method getSortedVersions Sorts the incoming version object arrays in asc order
   * @param {IHwSwType[]} versions
   * @returns {IHwSwType[]}
   */
  private getSortedVersions(versions: IHwSwType[]): IHwSwType[] {
    let versionNames = versions.map((ver) => ver.name);
    // 'latest' version tag is not sortable. Store aside, and push later 1st pos.
    const hasLatest = versionNames.indexOf('latest');
    if (hasLatest > -1) versionNames.splice(hasLatest, 1);
    let sortedNames = versionNames.sort(compareVersions);
    if (hasLatest > -1) versionNames.push('latest');
    // Sorting is desc by default. Array reversed on return.
    return sortedNames
      .map((element) => versions.find((ver) => ver.name === element))
      .reverse();
  }

  /**
   * @desc Creates the form group and form fields for the software configuration form.
   * @method createFormFields
   * @param {IApiStrategy} strategy
   */
  private createFormFields(strategy?: IApiStrategy): void {
    this.formGroup = this.formBuilder.group({
      general: this.formBuilder.group({
        name: [strategy.name, Validators.required],
      }),
      software: this.formBuilder.group({
        polyscope_version: [
          strategy.data.software.polyscope_version,
          Validators.required,
        ],
        pally_version: [
          strategy.data.software.pally_version,
          Validators.required,
        ],
      }),
      robot_safety: this.formBuilder.group({
        safety_preset: [strategy.data.safety_preset, Validators.required],
      }),
      specifications: this.formBuilder.group({
        gripper_optimization: [
          strategy.data.gripper_optimization,
          Validators.required,
        ],
        max_speed: [strategy.data.max_speed, Validators.required],
        max_acceleration: [strategy.data.max_acceleration, Validators.required],
      }),
      advanced: this.formBuilder.group({
        smart_acceleration: [
          strategy.data.smart_acceleration,
          Validators.required,
        ],
        approach_distance: [
          strategy.data.approach_distance,
          Validators.required,
        ],
        above_pick_position_mm: [
          strategy.data.above_pick_position_mm,
          Validators.required,
        ],
        high_approach_boost_percentage: [
          strategy.data.high_approach_boost_percentage,
          Validators.required,
        ],
        fixed_approach: [
          strategy.data.fixed_approach ? 'Enabled' : 'Disabled',
          Validators.required,
        ],
        box_free_height: [strategy.data.box_free_height, Validators.required],
        single_pallet: [strategy.data.number_of_pallets === 1 ? true : false],
      }),
      smart_exits: this.formBuilder.group({
        smart_exits_override: [
          (this.smartExitsOverrideState =
            strategy.data.smart_exits_override || false),
          Validators.required,
        ],
        smart_exits_preset: [ConveyorDirection.FRONT, Validators.required],
        left_pallet: this.formBuilder.group({
          x: this.formBuilder.group({
            from: [
              strategy.data.smart_exits.left_pallet.x.from,
              Validators.required,
            ],
            to: [
              strategy.data.smart_exits.left_pallet.x.to,
              Validators.required,
            ],
          }),
          y: this.formBuilder.group({
            from: [
              strategy.data.smart_exits.left_pallet.y.from,
              Validators.required,
            ],
            to: [
              strategy.data.smart_exits.left_pallet.y.to,
              Validators.required,
            ],
          }),
        }),
        right_pallet: this.formBuilder.group({
          x: this.formBuilder.group({
            from: [
              strategy.data.smart_exits.right_pallet.x.from,
              Validators.required,
            ],
            to: [
              strategy.data.smart_exits.right_pallet.x.to,
              Validators.required,
            ],
          }),
          y: this.formBuilder.group({
            from: [
              strategy.data.smart_exits.right_pallet.y.from,
              Validators.required,
            ],
            to: [
              strategy.data.smart_exits.right_pallet.y.to,
              Validators.required,
            ],
          }),
        }),
      }),
    }) as unknown as ISoftwareFormGroup;
    // Initialize smart exits override state to disabled as defined by default.
    this.toggleSmartExitsOverride(this.smartExitsOverrideState);
  }

  /**
   * @desc Toggles the smart exits override state and enables/disables the smart exits
   * form group accordingly.
   * @method toggleSmartExitsOverride
   * @param {boolean} checked
   */
  public toggleSmartExitsOverride(checked): void {
    // Storing the group state in a variable so it can be read when the group is disabled.
    // Part of the temporary work-around to read smart exits values when disabled.
    const readableFields = this.formGroup.get('smart_exits').value;
    if (readableFields?.left_pallet) this.smartExitsData = readableFields;

    this.updateField(checked, 'smart_exits', 'smart_exits_override');
    if ((this.smartExitsOverrideState = checked)) {
      this.formGroup.get('smart_exits').enable();
    } else {
      this.formGroup.get('smart_exits').disable();
      this.formGroup.get('smart_exits').get('smart_exits_override').enable();
    }
  }

  /**
   * Closes the dialog and emits a null value through the saveStrategy event emitter.
   */
  public closeDialog(): void {
    this.dialogEvent.emit(null);
  }
}
