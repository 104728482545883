<mat-card
  appearance="outlined"
  *ngIf="updateListData | async as listData"
  class="split listCell"
  [ngClass]="selected ? 'selected' : ''"
  [style]="getCardStyle(listData)"
>
  <!-- INSTALLED ROBOT LEFT SIDE -->

  <div
    *ngIf="type === PickerType.ROBOT_LICENSE; else defaultPickerLeft"
    class="left"
    style="
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    "
  >
    <div>
      <div
        class="hstack"
        style="justify-content: space-between; align-items: flex-start"
      >
        <div class="vstack" style="margin-bottom: 8px">
          <h3>{{ title }}</h3>
          <h1>{{ listData.data[listColumns[1]] }}</h1>

          <div *ngIf="listData.data['updated_at']" class="hstack">
            <h3 class="tagline">Last edited</h3>
            <h3 class="tagline" style="color: #070a17">
              {{
                timeUtils.getFormattedDate(
                  listData.data['updated_at'],
                  'time-since'
                ) + ' ago'
              }}
            </h3>
          </div>
        </div>
        <ng-container *ngIf="favouriteAction">
          <button
            style="margin-left: auto"
            mat-icon-button
            (click)="
              didClickAction({
                actionId: favouriteAction(listData).actionId,
                element: listData.data
              })
            "
          >
            <mat-icon
              class="material-icons-outlined"
              [ngStyle]="{
                color: listData.data['favourite']
                  ? '#E03131'
                  : 'rgba(7, 10, 23, 0.54)'
              }"
            >
              {{ 'favorite' + (!listData.data['favourite'] ? '_outline' : '') }}
            </mat-icon>
          </button>
        </ng-container>
        <button
          mat-icon-button
          *ngIf="doesAllowActions"
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let action of listData.actions">
            <button
              *ngIf="action.label !== 'favourite'"
              [disabled]="action.disabled"
              mat-menu-item
              (click)="
                didClickAction({
                  actionId: action.actionId,
                  element: listData.data
                })
              "
            >
              <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
              <span class="label">{{ action.label }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>

      <p class="card-kv-row">
        <ng-container
          *ngIf="getElementErrors(listData)?.['robot_name'] as robotConfError; else noErrorRoboConfig"
        >
          <span class="error-label span-label">
            <mat-icon>info</mat-icon> Robot name
          </span>

          <div
            class="error-container"
            (click)="
              didClickAction({
                actionId: robotConfError?.onClickActionId,
                element: listData.data
              })
            "
          >
            <mat-icon *ngIf="robotConfError?.materialIconTag">
              {{ robotConfError.materialIconTag }}
            </mat-icon>
            {{ robotConfError?.actionText }}
          </div>
        </ng-container>

        <ng-template #noErrorRoboConfig>
          <span class="span-label"> Robot name </span>

          <span style="text-align: end; word-wrap: anywhere">
            {{ listData.data[listColumns[2]] }}
          </span>
        </ng-template>
      </p>

      <p
        style="
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          align-items: center;
        "
      >
        <span class="span-label"> License status </span>

        <ng-container
          *ngIf="listData?.info?.['license_status'] || listData?.error?.['license_status']; else licenseStatus"
        >
          <app-info-error-badge
            [type]="listData?.info?.['license_status'] ? 'info' : 'error'"
            [data]="listData?.info?.['license_status'] ? listData?.info?.['license_status'] : listData?.error?.['license_status']"
            (actionClicked)="
              didClickAction({
                actionId: $event.actionId,
                element: listData.data
              })
            "
          ></app-info-error-badge>
        </ng-container>

        <ng-template #licenseStatus>
          <span
            [ngClass]="getCellStyling(listData)?.(listData.data, 'license_status')"
            (click)="
              didClickAction({
                actionId: listData.data[listColumns[3]]?.onClickActionId,
                element: listData.data
              })
            "
          >
            {{ listData.data[listColumns[3]]?.actionText }}
          </span>
        </ng-template>
      </p>
    </div>

    <div class="card-simulation-start">
      <ng-container
        *ngIf="
          listData.data?.robot_config_id &&
            !listData.error['robot_configuration'];
          else disabledButton
        "
      >
        <button
          mat-raised-button
          pally-rounded-button-m
          style="height: 50px"
          class="button-active"
          color="accent"
          (click)="onSimulationClick(listData.data?.robot_config_id)"
        >
          <span class="start-label"
            >START SIMULATION <mat-icon>play_arrow</mat-icon></span
          >
        </button>
      </ng-container>
      <ng-template #disabledButton>
        <button
          mat-raised-button
          pally-rounded-button-m
          style="height: 50px"
          disabled="true"
        >
          <span class="start-label"
            >START SIMULATION <mat-icon>play_arrow</mat-icon></span
          >
        </button>
      </ng-template>
    </div>
  </div>

  <!-- DEFAULT LEFT SIDE -->

  <ng-template #defaultPickerLeft>
    <div class="left">
      <div class="hstack" style="justify-content: space-between">
        <div class="vstack" style="margin-bottom: 8px">
          <h3>{{ title }}</h3>
          <h1>{{ listData.data[listColumns[0]] }}</h1>

          <div *ngIf="listData.data['updated_at']" class="hstack">
            <h3 class="tagline">Last edited</h3>
            <h3 class="tagline" style="color: #070a17">
              {{
                timeUtils.getFormattedDate(
                  listData.data['updated_at'],
                  'time-since'
                ) + ' ago'
              }}
            </h3>
          </div>
        </div>
        <ng-container *ngIf="favouriteAction">
          <button
            style="margin-left: auto"
            mat-icon-button
            (click)="
              didClickAction({
                actionId: favouriteAction(listData).actionId,
                element: listData.data
              })
            "
          >
            <mat-icon
              class="material-icons-outlined"
              [ngStyle]="{
                color: listData.data['favourite']
                  ? '#E03131'
                  : 'rgba(7, 10, 23, 0.54)'
              }"
            >
              {{ 'favorite' + (!listData.data['favourite'] ? '_outline' : '') }}
            </mat-icon>
          </button>
        </ng-container>
        <button
          mat-icon-button
          *ngIf="doesAllowActions"
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let action of listData.actions">
            <button
              *ngIf="action.label !== 'favourite'"
              mat-menu-item
              (click)="
                didClickAction({
                  actionId: action.actionId,
                  element: listData.data
                })
              "
            >
              <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
              <span class="label">{{ action.label }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div *ngFor="let key of listColumns; let i = index">
        <div *ngIf="i !== 0" class="keyvalPair">
          <p style="display: flex; justify-content: space-between">
            <span class="key" [style.color]="columnColors[i]">{{
              key.replaceAll('_', ' ') | titlecase
            }}</span>
            <span>
              <span class="val" [style.color]="columnColors[i]">
                {{ listData.data[key] }}
              </span>
              <span
                class="unit"
                [innerHTML]="listColumnUnits[i]"
                [style.color]="columnColors[i]"
              ></span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- CONTENT RIGHT SIDE -->

  <div class="right" mat-card-image *ngIf="type === PickerType.ROBOT_LICENSE">
    <ng-container *ngIf="data?.image?.url; else noSceneImage">
      <img
        mat-card-image
        [src]="data?.image?.url"
        style="
          object-fit: cover;
          height: 100%;
          max-width: 640px;
          max-height: 380px;
          border-radius: 8px;
          margin: 15px 15px 15px 0;
          width: 100%;
        "
      />
    </ng-container>
    <ng-template #noSceneImage>
      <div class="card-picker-no-scene-img">No robot selected</div>
    </ng-template>
  </div>

  <div class="right" *ngIf="type === PickerType.SOFTWARE_CONFIGURATION">
    <div
      #URVersion
      class="ur-version no-select"
      [ngStyle]="
        polyscopeVersionBackground(data.data.software.polyscope_version)
      "
    >
      <img
        width="40%"
        src="../../../../assets/icons/ur_logo.png"
        alt="ur_logo"
      />
      <div
        class="version-text"
        *ngIf="data.data.software.polyscope_version !== 'latest'"
      >
        {{ 'V' + data.data.software.polyscope_version }}
      </div>
    </div>
    <div
      #PallyVersion
      class="pally-version no-select"
      [ngStyle]="pallyVersionBackground(data.data.software.pally_version)"
    >
      <img
        width="70%"
        src="../../../../assets/icons/Pally_logo.png"
        alt="pally_logo"
      />
      <div
        class="version-text"
        *ngIf="data.data.software.pally_version !== 'latest'"
      >
        {{ 'V' + data.data.software.pally_version }}
      </div>
    </div>
  </div>
  <div
    class="right"
    mat-card-image
    *ngIf="type === PickerType.HARDWARE_CONFIGURATION"
  >
    <img
      mat-card-image
      [src]="
        data.image?.url || '../../../../assets/dummy/simulation-teaser.jpg'
      "
      style="
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: 380px;
        margin: 0;
      "
    />
  </div>
</mat-card>
