import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { OpenSimService } from '../open-sim.service';
import { PublicApiService } from 'src/app/services/api/public-api.service';

@Component({
  selector: 'app-fast-track-footer',
  templateUrl: './fast-track-footer.component.html',
  styleUrls: ['./fast-track-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FastTrackFooterComponent {
  constructor(
    private router: Router,
    public openSimService: OpenSimService,
    public publicApi: PublicApiService
  ) {}

  public openAboutLink(param: string) {
    this.router.navigate([pagesPATH.ABOUT], {
      queryParams: { content: param },
    });
  }
}
