import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { Field } from 'src/app/models_new/classes/field';
import { SimConfigService } from 'src/app/services/sim-config.service';

@Component({
  selector: 'app-sim-config-form',
  templateUrl: './sim-config-form.component.html',
  styleUrls: ['./sim-config-form.component.scss'],
})
export class SimConfigFormComponent {
  fields$: Observable<Field[]>;
  constructor(
    private simConfigService: SimConfigService,
    private activatedRoute: ActivatedRoute
  ) {
    this.fields$ = this.activatedRoute.data.pipe(
      map((data) => data.simConfigFieldIds),
      switchMap((ids) => this.simConfigService.getFields(ids))
    );
  }
}
