import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-stepper-radio',
  templateUrl: './stepper-radio.component.html',
  styleUrls: ['./stepper-radio.component.scss'],
})
export class StepperRadioComponent implements OnInit, OnChanges {
  @Input() initStep?: number;
  @Input() disableButtons?: boolean;
  @Input() disabledNext?: boolean;
  @Input() showStepNo?: boolean;
  @Input() clickableButtons?: boolean = true;
  @Input() radioSteps: number[];
  @Input() stepperText?: string;
  @Output() selectedStep: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (!this.initStep) {
      this.initStep = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initStep']) {
      this.initStep = changes['initStep'].currentValue;
    }
  }

  selectStep(step: number) {
    if (step > this.initStep && !this.disabledNext) {
      this.selectedStep.emit(step);
    }
    if (step < this.initStep) {
      this.selectedStep.emit(step);
    }
  }

  prevStep() {
    this.initStep = this.initStep - 1 > -1 ? this.initStep - 1 : this.initStep;
    this.selectedStep.emit(this.initStep);
  }

  nextStep() {
    this.initStep =
      this.initStep + 1 <= this.radioSteps.length - 1
        ? this.initStep + 1
        : this.initStep;
    this.selectedStep.emit(this.initStep);
  }
}
