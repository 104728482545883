import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class View3DEventsService {
  public canvasFetched$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // Signal before camera should be setup
  public beforeCameraSetup$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // Signal to know when camera is made
  public cameraMade$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // Signal when controls should be setup
  public controlSetup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // Signal to know when controls is made
  public controlMade$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  // Wether a scene instance is made yet for this three handler.
  public sceneBuilt$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public threeHandlerConstructed$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public threeHandlerInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public threeHandlerAfterViewInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public setup3DViewFinished$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public update$: Subject<number> = new Subject<number>();
}
