import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  ApiScene,
  IApiScene,
} from 'src/app/models_new/classes/api-models/ApiScene';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { HardwareApiService } from '../../../../services/api/hardware-api.service';
import { NotificationService } from '../../../../services/notification.service';
import { ITableAction, ITableData } from '../../../gui/table/table.component';
import { PickerType } from '../../../../models_new/enums/picker-type';
import { DataRequestState } from '../../../../data-request/model';
import { toRequestState } from '../../../../data-request/operators';
import { ObjectUtils } from 'src/app/utils/object';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import { HardwareUtils } from '../../../../utils/hardware-utils';
import { getConvertedLabelValue } from 'src/app/utils/unit-utils';
import { IHwSwType } from '../../../../models_new/types/robot-config/hw-sw-type';

type HardwareActions = 'favourite' | 'modify' | 'delete';

@Component({
  selector: 'app-inventory-hardware-wrapper',
  templateUrl: './inventory-hardwares.component.html',
  styleUrls: ['./inventory-hardwares.component.scss'],
})
export class InventoryHardwaresComponent implements OnInit, OnDestroy {
  inputData$: Observable<DataRequestState<ITableData<HardwareActions>[]>>;
  blockSelected: ApiScene[] = [];

  sortBy$: BehaviorSubject<string> = new BehaviorSubject<string>('name');
  pickerType: PickerType = PickerType.HARDWARE_CONFIGURATION;
  destroy$: Subject<boolean> = new Subject<boolean>();

  listColumns: string[] = [
    'select',
    'name',
    'gripper',
    'base',
    'conveyor',
    'lifting_column',
    'robot_name',
  ];
  cardColumns: string[] = [
    'name',
    'gripper',
    'base',
    'conveyor',
    'lifting_column',
    'robot_name',
  ];
  sortingTypes: string[] = ['name', 'updated_at', 'created_at'];

  objUtil = ObjectUtils;

  setupFilter: ISortingOption[] = [
    {
      id: 'favourite',
      label: 'Favourite',
    },
    {
      id: 'gripper',
      label: 'Gripper',
      children: [],
    },
    {
      id: 'base',
      label: 'Base',
      children: [],
    },
    {
      id: 'conveyor',
      label: 'Conveyor',
      children: [],
    },
    {
      id: 'lifting_column',
      label: 'Lifting Column',
      children: [],
    },
    {
      id: 'robot_name',
      label: 'Robot Name',
      children: [],
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'gripper',
      label: 'Gripper',
    },
    {
      id: 'base',
      label: 'Base',
    },
    {
      id: 'conveyor',
      label: 'Conveyor',
    },
    {
      id: 'lifting_column',
      label: 'Lifting Column',
    },
    {
      id: 'robot_name',
      label: 'Robot',
    },
  ];

  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hardwareApi: HardwareApiService,
    private notifier: NotificationService,
    private assetApi: AssetApiService
  ) {}

  ngOnInit(): void {
    this.inputData$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        combineLatest([
          this.hardwareApi.fetchHardwares(
            (data as IOrganizationContextResolverData).organization_id
          ),
          this.sortBy$,
          this.hardwareApi.fetchHwTypes(),
        ])
      ),
      takeUntil(this.destroy$),
      map(([s, sortBy, hw_types]) => {
        return this.mapToInputData(s, sortBy, hw_types);
      }),
      tap((configs) => {
        // Populate result filter
        for (let hw of configs) {
          for (let option of this.setupFilter) {
            if (option.id === 'favourite') continue;
            const id = ObjectUtils.getNested(hw.data, option.id);
            const child: ISortingOption = {
              id: id,
              label: id,
            };
            // Add option child if it doesn't exist in option children
            if (option.children.filter((o) => o.id === id).length === 0)
              option.children.push(child);
          }
        }
      }),
      toRequestState()
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  mapToInputData(
    scenes: IApiScene[],
    orderBy: string,
    hw_types: IHwSwType[]
  ): ITableData<HardwareActions>[] {
    let data: ITableData<HardwareActions>[] = [];

    scenes.sort((a, b) => {
      if (orderBy === 'name') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    });
    scenes.forEach((p: ApiScene) => {
      HardwareUtils.labelifyScene(p.data, hw_types);
      data.push({
        data: {
          id: p.id,
          name: p.name,
          description: p.description,
          data: p.data,
          updated_at: p.updated_at,
          //Column values
          gripper: p.data.robot.gripper.label,
          base: p.data.robot.frame.label,
          conveyor: p.data.conveyors[0].label,
          lifting_column: p.data.robot.lift_kit.label,
          height_extender: getConvertedLabelValue(
            p.data.robot_mounting_height.position.z * 1000 + ' mm'
          ),

          robot_name: p.data.robot.label,
          favourite: p.data['favourite'] || p['favourite'],
          image: p.image,
        },
        actions: [
          {
            label: 'favourite',
            actionId: 'favourite',
          },
          {
            label: 'Modify',
            actionId: 'modify',
            icon: 'edit',
            roleAction: 'modify_hardware_configuration',
          },
          {
            label: 'Delete',
            actionId: 'delete',
            txtColor: 'red',
            roleAction: 'delete_hardware_configuration',
            icon: 'delete',
          },
        ],
      });
    });
    return data;
  }

  deleteSelected() {
    if (this.blockSelected.length === 0) {
      this.notifier.showError('Nothing is selected!');
      return;
    }

    this.onDelete(this.blockSelected);
  }

  actionClicked(action: ITableAction<HardwareActions>) {
    // Favourite action
    if (action.actionId === 'favourite') {
      action.element.favourite = !action.element.favourite;
      action.element.data.favourite = action.element.favourite;
      this.hardwareApi
        .updateScene(
          action.element.id,
          action.element.name,
          action.element.description,
          action.element.data
        )
        .subscribe();
    }
    // Modify action
    if (action.actionId === 'modify') {
      this.navigateToHardware(action.element.id);
    }
    // Delete action
    if (action.actionId === 'delete') {
      this.onDelete([action.element]);
    }
  }

  rowClicked(e: ITableData['data']) {
    this.navigateToHardware(e.id);
  }

  navigateToHardware(id: string): void {
    this.router.navigate(
      [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS, id],
      {
        queryParams: { edit: true },
      }
    );
  }

  onDelete(elements: ApiScene[]) {
    this.notifier
      .deletePrompt(
        'Delete',
        'hardware configuration',
        elements.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((_) => this.deleteHardwares(elements.map((m) => m.id)))
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.notifier.showMessage(
              'The hardwares were deleted successfully!'
            );
            this.blockSelected = [];
          }
        },
        error: (error) => {
          this.notifier.showError(error.message);
        },
      });
  }

  deleteHardwares(ids: string[]): Observable<any> {
    const operations = {};
    for (let i = 0; i < ids.length; i++) {
      operations[i] = this.hardwareApi.deleteSceneById(ids[i]).pipe(
        map((response) => {
          if (!response) {
            this.notifier.showError(
              'Could not delete the hardware configuration. Check that you have permission to delete them.'
            );
          }

          return response;
        }),
        switchMap((response) =>
          response.image
            ? this.assetApi.deleteAsset(response.image.id)
            : of(response)
        )
      );
    }
    return forkJoin(operations);
  }
}
