import { AssetType } from '../../classes/asset/asset';
import { AssetIDs } from '../../enums/asset-ids';
import { IAssetStoreLoadingConfig } from '../../types/asset/asset-store-loading-config';

const openPallyDescriptionsURL =
  'https://pallydescriptions.blob.core.windows.net/public-models/';

export const globalAssetConfig: IAssetStoreLoadingConfig[] = [
  // Composite asset! (Multiple "models" (urdf structure is counted as model) per asset, model determined by mode)
  {
    id: AssetIDs.Box,
    urls: new Map<string, string>([
      [AssetIDs.Box, openPallyDescriptionsURL + 'boxes/box_sized.dae'],
      [
        AssetIDs.Box_Disabled,
        openPallyDescriptionsURL + 'boxes/box_disabled.dae',
      ],
      [
        AssetIDs.Box_Label_Front_Locked,
        openPallyDescriptionsURL + 'boxes/box_label_front_locked.dae',
      ],
      [
        AssetIDs.Box_Label_Back_Locked,
        openPallyDescriptionsURL + 'boxes/box_label_back_locked.dae',
      ],
      [
        AssetIDs.Box_Label_Front,
        openPallyDescriptionsURL + 'boxes/box_label_front.dae',
      ],
      [
        AssetIDs.Box_Label_Right_Locked,
        openPallyDescriptionsURL + 'boxes/box_label_right_locked.dae',
      ],
      [
        AssetIDs.Box_Label_Left_Locked,
        openPallyDescriptionsURL + 'boxes/box_label_left_locked.dae',
      ],
      [
        AssetIDs.Box_Label_Right,
        openPallyDescriptionsURL + 'boxes/box_label_right.dae',
      ],
      [AssetIDs.Box_Front, openPallyDescriptionsURL + 'boxes/box_front.dae'],
      [
        AssetIDs.Box_Front_Label_Front_Locked,
        openPallyDescriptionsURL + 'boxes/box_front_label_front_locked.dae',
      ],
      [
        AssetIDs.Box_Front_Label_Back_Locked,
        openPallyDescriptionsURL + 'boxes/box_front_label_back_locked.dae',
      ],
      [
        AssetIDs.Box_Front_Label_Front,
        openPallyDescriptionsURL + 'boxes/box_front_label_front.dae',
      ],
      [
        AssetIDs.Box_Front_Label_Right_Locked,
        openPallyDescriptionsURL + 'boxes/box_front_label_right_locked.dae',
      ],
      [
        AssetIDs.Box_Front_Label_Left_Locked,
        openPallyDescriptionsURL + 'boxes/box_front_label_left_locked.dae',
      ],
      [
        AssetIDs.Box_Front_Label_Right,
        openPallyDescriptionsURL + 'boxes/box_front_label_right.dae',
      ],
    ]),
    type: AssetType.DAEModel,
  },
  {
    id: AssetIDs.CompanyLogo,
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.CompanyLogoPreppedBox,
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.LabelTexture,
    urls: new Map<string, string>([
      [AssetIDs.LabelTexture, '../assets/illustrative/label.png'],
    ]),
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.LabelCompactTexture,
    urls: new Map<string, string>([
      [
        AssetIDs.LabelCompactTexture,
        '../assets/illustrative/label_compact.png',
      ],
    ]),
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.HelvetikerFont,
    urls: new Map<string, string>([
      [
        AssetIDs.HelvetikerFont,
        '../assets/design-elements/helvetiker.typeface.json',
      ],
    ]),
    type: AssetType.Font,
  },
  {
    id: AssetIDs.EURPallet,
    urls: new Map<string, string>([
      [
        AssetIDs.EURPallet,
        openPallyDescriptionsURL +
          'pallet/EUR/EUR1/meshes/visual/euro_pallet.dae',
      ],
    ]),
    type: AssetType.DAEModel,
  },
];
