import { AssetStoreService } from 'src/app/services/asset-store.service';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { palletViewColors } from '../../config/pallet-view-colors';
import { AssetIDs } from '../../enums/asset-ids';
import { PalletPosition } from '../../enums/pallet-position';
import { IPalletEdgeOffset } from '../../types/pallet-edge-offset';
import { Pallet } from '../pallet';
import { ThreeLayer } from './three-layer';
import { LayerType } from '../../enums/layer-type';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap, take } from 'rxjs/operators';
import { InstancedDynamicBox } from './dynamic-box';
import { Box } from '../box';
import { radians } from 'src/app/utils/geometry-utils';
import { LabelOrientation } from '../../enums/label-orientation';
import { calcPalletEdgeOffset } from '../../../utils/div';

export class ThreePallet extends THREE.Object3D {
  width: number;
  length: number;
  height: number;
  edgeOffset: IPalletEdgeOffset;
  palletPosition: PalletPosition;
  active: boolean;
  nrBoxes = 0;
  labelOrientations: LabelOrientation[];

  showLabel = false;
  showFront = false;

  layerList: ThreeLayer[] = [];
  palletModel: Observable<THREE.Object3D>;
  boxesModel: Observable<InstancedDynamicBox>;

  defaultBoxMaterials: THREE.Material | THREE.Material[];

  constructor(
    pallet: Pallet,
    labelOrientations: LabelOrientation[],
    active: boolean = true,
    showLabel: boolean = false,
    showFront: boolean = false
  ) {
    super();

    this.width = pallet.dimensions.width / 1000;
    this.height = pallet.dimensions.palletHeight / 1000;
    this.length = pallet.dimensions.length / 1000;

    this.edgeOffset = calcPalletEdgeOffset(this.width, this.length);
    this.palletPosition = pallet.position;
    this.active = active;
    this.showLabel = showLabel;
    this.showFront = showFront;

    if (labelOrientations) {
      this.labelOrientations = labelOrientations;
    }

    // Create layers
    for (const [index, layerData] of pallet.layers.entries()) {
      // Pallet dimensions are defined as millimeters
      // Define layer dimensions (in meters)
      const layerDimensions = {
        width: pallet.dimensions.width / 1000,
        height: layerData.height / 1000,
        length: pallet.dimensions.length / 1000,
      };

      const threeLayer = new ThreeLayer(
        layerData.type,
        layerData.zPosition / 1000, // Convert to meters
        layerDimensions
      );

      threeLayer.name = `Layer ${index}`;

      this.setLayer(index, threeLayer);

      if (layerData.type !== LayerType.SHIMPAPER) {
        this.nrBoxes += layerData.boxes.length;
      }
    }

    // Create 3D models and store an async ref for everyone that needs one.
    this.palletModel = this.makePalletModel().pipe(
      shareReplay({ bufferSize: 1, refCount: false })
    );
    this.boxesModel = this.makeBoxModel(pallet); // Do not want to shareReplay here, as its done earlier.
  }

  updateBoxes(showLabel: boolean, showFront: boolean): void {
    this.showLabel = showLabel;
    this.showFront = showFront;

    // Wait for model to be ready
    this.boxesModel.pipe(take(1)).subscribe((boxes) => {
      boxes.showLabels(showLabel);

      if (showFront) {
        boxes.showFrontIndicator();
      } else {
        boxes.hideFrontIndicator();
      }
    });
  }

  setBoxStickerTexture(texture: THREE.Texture): void {
    this.boxesModel.pipe(take(1)).subscribe((boxes: InstancedDynamicBox) => {
      boxes.setLabels(
        texture,
        // Remove any null values
        this.labelOrientations?.filter((orient) => orient !== null)
      );

      // Set visibility state immideatly to hide if needed
      boxes.showLabels(this.showLabel && this.active);
      this.updateMaterial();
    });
  }

  /**
   * Gets height
   * @returns Total pallet height in meters
   */
  getTotalHeight(): number {
    let totalHeight = this.height;
    for (const layer of this.layerList) {
      totalHeight += layer.getHeight();
    }
    return totalHeight;
  }

  private makePalletModel(): Observable<THREE.Object3D> {
    if (this.length === 1.2 && this.width === 0.8) {
      return AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(
        AssetIDs.EURPallet
      ).pipe(
        take(1),
        map((model) => {
          model = model;
          model.rotation.z = MathUtils.degToRad(90);
          model.position.set(0, -this.height / 2, 0);
          this.add(model);

          return model;
        })
      );
    } else {
      const model = new THREE.Mesh(
        new THREE.BoxGeometry(this.width, this.height, this.length),
        new THREE.MeshLambertMaterial({
          color: this.active
            ? palletViewColors.selectedPallet
            : palletViewColors.unselectedPallet,
        })
      );
      this.add(model);

      return of(model);
    }
  }

  /**
   *
   * Creates the instanced box object.
   *
   * Note: The stream completes after the first value
   */
  private makeBoxModel(pallet: Pallet): Observable<InstancedDynamicBox> {
    return AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(
      AssetIDs.Box
    ).pipe(
      tap(
        (box) =>
          (this.defaultBoxMaterials = (
            (box as THREE.Object3D).children[0] as THREE.Mesh
          ).material)
      ),
      map((box) => {
        // We know the structure of the box model, the first child is the actual mesh
        const boxes = new InstancedDynamicBox(
          box.children[0] as THREE.Mesh,
          this.nrBoxes
        );

        boxes.position.copy(this.getLayerPosition());

        let i = 0;
        for (const layer of pallet.layers) {
          // Shim papers are handeled separatly
          if (layer.type === LayerType.SHIMPAPER) {
            continue;
          }

          for (const boxData of layer.boxes) {
            boxes.setMatrixAt(
              i,
              this.computeBoxMatrix(boxData),
              boxData.labelOrientations.length < 2
            );
            i += 1;
          }
        }

        this.add(boxes);

        return boxes;
      }),
      // Boxes are duplicated, this prevents re-addition of boxes
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private setLayer(index: number, layer: ThreeLayer): void {
    (layer as THREE.Object3D).position.copy(this.getLayerPosition());
    this.layerList[index] = layer;
    this.add(layer);
  }

  private getLayerPosition(): THREE.Vector3 {
    return new THREE.Vector3(
      this.edgeOffset.left,
      -this.height / 2,
      this.length / 2
    );
  }

  setYPosition(y: number): void {
    this.position.y = this.height / 2 + y;
  }

  setXPosition(x: number): void {
    this.position.x = x;
  }

  setZPosition(z: number): void {
    this.position.z = z;
  }

  enable(): void {
    this.active = true;
    this.updateMaterial();
  }

  disable(): void {
    this.active = false;
    this.updateMaterial();
  }

  private updateMaterial(): void {
    // Update boxes
    this.boxesModel.pipe(take(1)).subscribe((boxes) => {
      boxes.boxMesh.material = this.active
        ? this.defaultBoxMaterials
        : new THREE.MeshLambertMaterial({
            color: palletViewColors.unselectedPallet,
          });
    });

    // Update pallet
    this.palletModel.pipe(take(1)).subscribe((model) => {
      model.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = new THREE.MeshLambertMaterial({
            color: this.active
              ? palletViewColors.selectedPallet
              : palletViewColors.unselectedPallet,
          });
        }
      });
    });
  }

  /**
   * Computes the matrix for a box.
   */
  private computeBoxMatrix(b: Box): THREE.Matrix4 {
    const UP = new THREE.Vector3(0, 1, 0);

    // Convert from millimeters to meters
    const position = new THREE.Vector3(
      b.position.x,
      b.position.z,
      -b.position.y // The z axis is fliped between three.js and pattern space
    ).divideScalar(1000);
    const scale = new THREE.Vector3(
      b.dimensions.width,
      b.dimensions.height,
      b.dimensions.length
    ).divideScalar(1000);
    const orientation = new THREE.Quaternion().setFromAxisAngle(
      UP,
      radians(b.rotation[0])
    );

    return new THREE.Matrix4().compose(position, orientation, scale);
  }
}
