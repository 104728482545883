import { IAssetStoreLoadingConfig } from '../../types/asset/new-asset-store-loading-config';
import { settings } from '../application-settings';

export const openAssetConfig: IAssetStoreLoadingConfig<any>[] = [
  {
    id: 'font',
    src: '../../../../assets/design-elements/helvetiker.typeface.json',
    type: 'three_font',
    prefetch: true,
  },
  {
    id: 'label',
    src: '../../../../assets/illustrative/label.png',
    type: 'texture',
    prefetch: true,
  },
  {
    id: 'box',
    //src: settings.publicPallyDescriptionsURL + 'pally_descriptions/scene/boxes/default/box_sized.dae',
    src: settings.publicmodelsPallyDescriptionsURL + 'boxes/box_sized.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: 'pallet-eur1',
    src:
      settings.publicmodelsPallyDescriptionsURL +
      'pallet/EUR/EUR1/meshes/visual/euro_pallet.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: 'conveyor',
    src:
      settings.pallyDescriptionsURL +
      'pally_descriptions/conveyor/standard/conveyor/meshes/visual/conveyor.dae',
    type: 'dae',
    prefetch: true,
    needsSASToken: true,
  },
  {
    id: 'simulation-scene',
    src:
      settings.publicmodelsPallyDescriptionsURL +
      'studio-scene-redesign_v9/MAIN_studio-scene-redesign_v9_fix.fbx',
    type: 'fbx',
    prefetch: false,
  },
  /*
    TODO: Should be moved to seperate config, saved for convenience
  {
    id: 'sim-config-urdf',
    src: settings.pallyDescriptionsURL +
      'pally_descriptions/urdfs/simconfig_robot.urdf',
    type: 'urdf',
    prefetch: true,
    needsSASToken: true,
  },*/
];
