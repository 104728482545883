export const dialogSizesConfig = {
  small: {
    width: '400px',
    height: '400px',
  },
  medium: {
    width: '800px',
    height: '800px',
  },
  large: {
    width: '1200px',
    height: '1200px',
  },
};
