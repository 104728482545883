import { Component } from '@angular/core';

/**
 * Provides a routing point for the software configuration module. Children will provide the actual content
 */
@Component({
  selector: 'app-software-wrapper',
  templateUrl: './software-wrapper.component.html',
  styleUrls: ['./software-wrapper.component.scss'],
})
export class SoftwareWrapperComponent {}
