<div class="delete_prompt_wrapper">
  <h1 *ngIf="data.items.length < 2">
    {{ data.title || 'Delete' }} {{ data.type }}?
  </h1>
  <h1 *ngIf="data.items.length >= 2">
    {{ data.title || 'Delete' }} {{ data.items.length }} {{ data.type }}s?
  </h1>
  <p>
    Are you sure you want to {{ data.title || 'delete' }}: <br />
    <span *ngFor="let i of data.items; let last = last">
      <b *ngIf="last">“{{ i }}”</b>
      <b *ngIf="!last">“{{ i }}”, </b> </span
    >?
  </p>

  <ng-container *ngIf="data.extraWarning">
    <mat-card appearance="outlined">
      <div mat-card-avatar>
        <mat-icon>warning</mat-icon>
      </div>
      <div>
        <mat-card-title>{{ data.extraWarning.title }}</mat-card-title>
        <mat-card-content>{{ data.extraWarning.text }}</mat-card-content>
      </div>
    </mat-card>
  </ng-container>

  <mat-card appearance="outlined">
    <div mat-card-avatar>
      <mat-icon>warning</mat-icon>
    </div>
    <div>
      <mat-card-title>Warning</mat-card-title>
      <mat-card-content>You can’t undo this action.</mat-card-content>
    </div>
  </mat-card>

  <div class="actions_wrapper">
    <button
      mat-raised-button
      color="primary"
      pally-rounded-button-m
      (click)="cancelDeletion()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      pally-rounded-button-m
      color="warn"
      (click)="deleteItems()"
      style="white-space: nowrap"
    >
      <mat-icon>delete</mat-icon
      ><span *ngIf="data.items.length < 2">Delete {{ data.type }}</span
      ><span *ngIf="data.items.length >= 2">Delete {{ data.type }}s</span>
    </button>
  </div>
</div>
