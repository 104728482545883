import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as PPBUnitUtils from '../../../../utils/unit-utils';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { PalletSummary } from 'src/app/models_new/classes/pallet-summary';

export interface PatternInfoInput {
  boxCount: number;
  boxWeight: number;
  boxHeight: number;
  boxWidth: number;
  boxLength: number;
  numberOfLayers: number;
  palletCubeEfficiency: number;
  palletWeight: number;
  palletHeight: number;
  palletCenterOfMass: PalletSummary['palletCenterOfMass'];
}

interface PatternInfo {
  boxCount: number;
  boxWeight: number;
  boxHeight: number;
  boxWidth: number;
  boxLength: number;
  numberOfLayers: number;
  palletCubeEfficiency: number;
  palletWeight: string;
  palletHeight: string;
  palletCenterOfMass: string;
}

@Component({
  selector: 'app-pattern-info',
  templateUrl: './pattern-info.component.html',
  styleUrls: ['./pattern-info.component.scss'],
})
export class PatternInfoComponent implements OnChanges {
  private unit: PPBUnitUtils.UnitSystemType = 'metric';
  @Input() PatternInfoInput: PatternInfoInput;

  patternInfo: PatternInfo = {
    boxCount: 0,
    boxWeight: 0,
    boxHeight: 0,
    boxWidth: 0,
    boxLength: 0,
    numberOfLayers: 0,
    palletCubeEfficiency: 0,
    palletWeight: '',
    palletHeight: '',
    palletCenterOfMass: '',
  };

  constructor(private localStorageService: LocalStorageService) {
    this.unit = this.localStorageService.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.patternInfo.boxCount = this.PatternInfoInput.boxCount;
    this.patternInfo.numberOfLayers = this.PatternInfoInput.numberOfLayers;
    this.patternInfo.palletCubeEfficiency =
      this.PatternInfoInput.palletCubeEfficiency;

    this.patternInfo.palletHeight = PPBUnitUtils.getConvertedValue(
      this.PatternInfoInput.palletHeight / 1000 + ' m',
      this.unit
    );

    this.patternInfo.palletWeight = PPBUnitUtils.getConvertedValue(
      this.PatternInfoInput.palletWeight / 1000 + ' kg',
      this.unit
    );

    this.patternInfo.palletCenterOfMass = PPBUnitUtils.getConvertedValue(
      ((this.PatternInfoInput.palletCenterOfMass.z as number) / 1000).toFixed(
        2
      ) + ' m',
      this.unit
    );
  }
}
