<div class="d-flex flex-column" style="margin-bottom: 10px">
  <div class="d-flex flex-row">
    <div class="logo">
      <ng-content select="img"></ng-content>
      <ng-content select="app-company-logo"></ng-content>
    </div>

    <div
      class="flex text-area"
      [ngClass]="{ verticalLayout: verticalLayout, logoLayout: logoLayout }"
    >
      <ng-content select="[app-overline]"></ng-content>

      <div [ngClass]="{ 'd-flex': !verticalLayout }" class="title-wrapper">
        <ng-content select="[app-title]"></ng-content>
        <div *ngIf="!verticalLayout" class="spacer"></div>
        <ng-content select="app-header-actions"></ng-content>
      </div>
    </div>
  </div>

  <div>
    <ng-content select="app-underline-actions"></ng-content>
  </div>
</div>
