import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable, Subject } from 'rxjs';
import {
  ApiProductionLine,
  IApiProductionLine,
  IProductionLineData,
} from '../../models_new/classes/api-models/ApiProduction_line';
import { ObjectUtils } from '../../utils/object';
import { ErrorHandlerService } from '../error-handler.service';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProdlineApiService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /* ------------------------ Database query functions ------------------------ */

  fetchProductionLinesQuery(
    organizationId: string
  ): Observable<ApiProductionLine[]> {
    const qf = gql`
      query getProductionLine {
        production_line(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          data
          name
          organization_id
          owner_id
          created_at
          updated_at
          product_ids: production_line_products {
            product_id
          }
          production_line_palletizing_projects {
            palletizing_project {
              name
              id
            }
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.production_line;
          }
        })
      );
  }

  fetchProductionLines(
    organizationId: string = null
  ): Observable<ApiProductionLine[]> {
    const q = gql`
      subscription getProductionLine {
        production_line {
          id
          data
          name
          updated_at
          organization_id
          owner_id
          created_at
          updated_at
          product_ids: production_line_products {
            product_id
          }
          production_line_palletizing_projects {
            palletizing_project {
              name
              id
            }
          }
        }
      }
    `;

    const qf = gql`
      subscription getProductionLine {
        production_line(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          data
          name
          organization_id
          owner_id
          created_at
          updated_at
          product_ids: production_line_products {
            product_id
          }
          production_line_palletizing_projects {
            palletizing_project {
              name
              id
            }
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId ? qf : q,
      })
      .pipe(
        map((data) => {
          const list = [];
          for (const p of data.data.production_line) {
            const pp = new ApiProductionLine(ObjectUtils.cloneObject(p));
            pp.organization_id = p.organization_id;
            pp.product_ids = pp.product_ids.map((m) => m['product_id']);
            list.push(pp);
          }
          return list;
        })
      );
  }

  fetchProductionLine(id: string): Observable<IApiProductionLine> {
    const q = gql`
    query getProductionLine {
      production_line_by_pk(id: "${id}") {
        id
        name
        data
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.production_line_by_pk));
  }

  createProductionLine(
    name: string,
    data: IProductionLineData,
    orgId: string
  ): Observable<IApiProductionLine> {
    const m = gql`
      mutation createProductionLine(
        $name: String!
        $orgId: uuid!
        $data: json
      ) {
        insert_production_line_one(
          object: { name: $name, organization_id: $orgId, data: $data }
        ) {
          id
        }
      }
    `;

    const variables = {
      name,
      orgId: orgId,
      data,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.insert_production_line_one));
  }

  createProductionLineProduct(product_id: string, product_line_id: string) {
    const m = gql`
      mutation createProductionLineProduct(
        $product_id: uuid!
        $product_line_id: uuid!
      ) {
        insert_production_line_products_one(
          object: { product_id: $product_id, product_line_id: $product_line_id }
        ) {
          id
        }
      }
    `;

    const variables = {
      product_id: product_id,
      product_line_id: product_line_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.insert_production_line_products_one));
  }

  updateProductionLine(
    id: string,
    name: string,
    data: IProductionLineData
  ): Observable<IApiProductionLine> {
    const m = gql`
    mutation updateProductionLine($name: String!, $data: json!) {
      update_production_line_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, data: $data}) {
        id
        name
        data
      }
    }
    `;

    const variables = {
      name,
      data,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((value) => value.data.production_line_by_pk));
  }

  deleteProdline(id: string): Observable<IApiProductionLine> {
    const m = gql`
    mutation deleteProdline {
      delete_production_line_by_pk(id: "${id}") {
        name
        id
      }
    }`;

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data.delete_production_line_by_pk));
  }
}
