<ng-container *ngIf="notify.messages$ | async as messages">
  <div class="messages-wrapper">
    <mat-card
      appearance="outlined"
      [class]="m.class"
      [style.background]="m.bgColor"
      [style.margin]="'.3em 0'"
      *ngFor="let m of messages"
    >
      <mat-card-content>
        <p
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-break: normal;
          "
        >
          <mat-icon [style.color]="m.iconColor">{{ m.icon }}</mat-icon>

          <span style="overflow-wrap: break-word; width: 70%">
            <span>{{ m.message }}</span>
            <a target="_blank" [href]="m.link.url" *ngIf="m.link"
              ><br />{{ m.link.text }}</a
            >
          </span>

          <ng-container *ngIf="m.type === 'error'">
            <span class="spacer" style="flex-grow: 1"></span>
            <button mat-button (click)="notify.removeMessage$.next(m.id)">
              OK
            </button>
          </ng-container>
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
