<ng-container *ngIf="field.type === fieldType.TEXT">
  <mat-form-field
    [appearance]="appearance"
    [ngClass]="{ highlight: highlightField }"
  >
    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <input
      (data-lpignore)="(true)"
      [errorStateMatcher]="eagerMatcher"
      matInput
      name="field.text.name"
      [type]="field.type"
      [autocomplete]="field.text.name"
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [required]="field.required"
    />

    <span matSuffix>
      <span *ngIf="field.text && field.text.suffix">{{
        field.text.suffix
      }}</span>
      <button
        class="infoPopupButton"
        *ngIf="openInfoPopup.observed"
        (click)="doOpenInfoPopup($event)"
      >
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-hint
      *ngIf="!field.text?.hint && highlightField"
      class="restore-value-hint"
      [ngClass]="field.reactive.enabled ? '' : 'field-hint-disabled'"
    >
      Value changed.
      <u (click)="restoreDefaultValue()">Reset</u>
    </mat-hint>
    <mat-error *ngIf="field.formControl.errors?.email"
      >Must be a valid e-mail address</mat-error
    >
    <mat-error *ngIf="field.formControl.errors?.required"
      >This field is required</mat-error
    >
  </mat-form-field>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.TEXTAREA">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >

    <textarea
      [errorStateMatcher]="eagerMatcher"
      matInput
      name="field.text.name"
      [type]="field.type"
      [autocomplete]="field.text.name"
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [required]="field.required"
    ></textarea>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-error *ngIf="field.formControl.errors?.required"
      >This field is required</mat-error
    >
  </mat-form-field>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.PW">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >

    <input
      (data-lpignore)="(true)"
      [errorStateMatcher]="eagerMatcher"
      matInput
      name="field.text.name"
      [type]="hide ? 'password' : 'text'"
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [required]="field.required"
      (focus)="pwInputFocused = true"
      (blur)="pwInputFocused = false"
      autocomplete="on"
    />

    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="hide = !hide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide"
    >
      <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>

    <span matSuffix>
      <span *ngIf="field.text && field.text.suffix">{{
        field.text.suffix
      }}</span>
      <button
        class="infoPopupButton"
        *ngIf="openInfoPopup.observed"
        (click)="doOpenInfoPopup($event)"
      >
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-error *ngIf="field.formControl.errors?.required"
      >This field is required</mat-error
    >

    <div class="formcontrol-error-box" *ngIf="errors.length && pwInputFocused">
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let error of errors"
              [ngStyle]="{ color: !error.valid ? '#ff5722' : 'green' }"
            >
              <mat-icon mat-list-icon *ngIf="error.valid">check</mat-icon>
              <mat-icon mat-list-icon *ngIf="!error.valid">close</mat-icon>
              <div mat-line>
                {{ error.message }}
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-form-field>
</ng-container>

<ng-container
  *ngIf="field.type === fieldType.NUMBER"
  class="number-field-wrapper"
>
  <mat-form-field
    [appearance]="appearance"
    class="center-suffix number-field"
    [ngClass]="{
      highlight: highlightField,
      fractional: numberFieldFractional
    }"
  >
    <mat-label *ngIf="numberField?.text?.label" [style.color]="labelColor"
      >{{ numberField.text.label }}:</mat-label
    >
    <input
      matInput
      name="field.text.name"
      [errorStateMatcher]="eagerMatcher"
      [step]="numberField?.step"
      [type]="numberField.type"
      [formControl]="numberField.formControl"
      [value]="numberField.formControl.value"
      [required]="numberField.required"
      [min]="numberField.min"
      [max]="numberField.max"
      [style]="'text-align: right; ' + numberField?.inputStyling"
      (data-lpignore)="(true)"
      (blur)="doPersistNumberFieldValue()"
      (keypress)="ignoreCharacters($event)"
    />
    <!-- Imperial decimal dropdown -->
    <button
      *ngIf="deletable"
      (click)="deleteClick.emit(numberField.id)"
      matPrefix
      mat-icon-button
      aria-label="Clear"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <span matSuffix>
      <span *ngIf="numberField?.text?.suffix">{{
        numberField.text.suffix
      }}</span>
      <button
        class="infoPopupButton"
        *ngIf="openInfoPopup.observed"
        (click)="doOpenInfoPopup($event)"
      >
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>
    <ng-container *ngIf="numberFieldFractional?.options">
      <mat-select
        class="fractional-dropdown"
        (closed)="doPersistNumberFieldValue()"
        [formControl]="numberFieldFractional.formControl"
        [value]="numberFieldFractional.formControl.value"
      >
        <mat-option
          *ngFor="let option of $any(numberFieldFractional.options)"
          [value]="option"
          >{{ option }}</mat-option
        >
      </mat-select>
    </ng-container>
    <mat-hint *ngIf="numberField?.text?.hint">{{
      numberField.text.hint
    }}</mat-hint>
    <mat-hint
      *ngIf="!numberField.text?.hint && highlightField"
      class="restore-value-hint"
    >
      Value changed.
      <u (click)="restoreDefaultValue()">Reset</u>
    </mat-hint>
    <mat-error *ngIf="numberField.formControl.errors?.required"
      >This field is required</mat-error
    >
    <mat-error *ngIf="numberField.formControl.errors?.min"
      >Is less than the allowed amount:
      {{ numberField.formControl.errors.min.min }}
      {{ numberField.text.suffix ?? '' }}</mat-error
    >
    <mat-error *ngIf="numberField.formControl.errors?.max"
      >Is more than the allowed amount:
      {{ numberField.formControl.errors.max.max }}
      {{ numberField.text.suffix ?? '' }}</mat-error
    >
  </mat-form-field>
  <ng-template
    *ngIf="numberField.formControl.invalid; then fieldError"
  ></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.CHECKBOX">
  <mat-checkbox
    [formControl]="field.formControl"
    [value]="field.formControl.value"
    color="primary"
  >
    <span
      *ngIf="field.text && field.text.label"
      [innerHTML]="field.text.label"
      [style.color]="labelColor"
    ></span>
  </mat-checkbox>
</ng-container>

<ng-container *ngIf="field.type === fieldType.SLIDETOGGLE">
  <mat-slide-toggle
    [formControl]="field.formControl"
    [checked]="field.formControl.value"
    [title]="field.text.hint"
    [style.color]="labelColor || 'primary'"
  >
    {{ field.text.label }}
  </mat-slide-toggle>
</ng-container>

<ng-container *ngIf="field.type === fieldType.SELECT_MULTIPLE_OBJECT">
  <mat-form-field [appearance]="appearance">
    <span matPrefix *ngIf="field.prefixIcon" class="prefixIcon">
      <mat-icon *ngIf="field.prefixIcon?.material">{{
        field.prefixIcon.icon
      }}</mat-icon>
      <mat-icon
        *ngIf="!field.prefixIcon?.material"
        [svgIcon]="field.prefixIcon.icon"
      ></mat-icon>
    </span>

    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <span matSuffix *ngIf="openInfoPopup.observed">
      <button class="infoPopupButton" (click)="doOpenInfoPopup($event)">
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>

    <mat-select
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      multiple
    >
      <mat-select-trigger *ngIf="field.formControl.value">
        {{ selectMultipleValueDisplay(field.formControl.value) }}
      </mat-select-trigger>
      <mat-option
        [disabled]="!field.reactive.enabled && option.disabled"
        [value]="option"
        *ngFor="let option of $any(field.options)"
      >
        <mat-icon *ngIf="field.prefixIcon">{{ field.prefixIcon }}</mat-icon>
        {{ option[field.text.showProperty] }}
        <span matSuffix *ngIf="field.text && field.text.suffix">{{
          field.text.suffix
        }}</span>
      </mat-option>
    </mat-select>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-hint
      *ngIf="!field.text?.hint && highlightField"
      class="restore-value-hint"
    >
      Value changed.
      <u (click)="restoreDefaultValue()">Reset</u>
    </mat-hint>
  </mat-form-field>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container
  *ngIf="
    field.type === fieldType.SELECT_SINGLE ||
    field.type === fieldType.SELECT_MULTIPLE
  "
>
  <mat-form-field
    [appearance]="appearance"
    [style]="{ 'margin-bottom': field.text.hint ? '15px' : 'auto' }"
    [ngClass]="{ highlight: highlightField }"
  >
    <span matPrefix *ngIf="field.prefixIcon" class="prefixIcon">
      <mat-icon *ngIf="field.prefixIcon?.material">{{
        field.prefixIcon.icon
      }}</mat-icon>
      <mat-icon
        *ngIf="!field.prefixIcon?.material"
        [svgIcon]="field.prefixIcon.icon"
      ></mat-icon>
    </span>

    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <span matSuffix *ngIf="openInfoPopup.observed">
      <button class="infoPopupButton" (click)="doOpenInfoPopup($event)">
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>

    <mat-select
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [multiple]="field.type === fieldType.SELECT_MULTIPLE"
    >
      <mat-option
        [disabled]="!field.reactive.enabled && option.disabled"
        [value]="option"
        *ngFor="let option of $any(field.options)"
      >
        {{ isConvertible ? (option | unitSystemLabel) : option }}
        <span matSuffix *ngIf="field.text && field.text.suffix">{{
          field.text.suffix
        }}</span>
      </mat-option>
    </mat-select>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-hint
      *ngIf="!field.text?.hint && highlightField"
      class="restore-value-hint"
    >
      Value changed.
      <u (click)="restoreDefaultValue()">Reset</u>
    </mat-hint>
  </mat-form-field>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.AUTOCOMPLETE">
  <form>
    <mat-form-field [appearance]="appearance">
      <mat-label
        *ngIf="field.text && field.text.label"
        [style.color]="labelColor"
        >{{ field.text.label }}:</mat-label
      >
      <input
        type="text"
        matInput
        [formControl]="field.formControl"
        [matAutocomplete]="auto"
        (keyup)="applyTextFilter($event)"
        (focusout)="
          autocompleteDisplayFn(field.formControl.value) === ''
            ? field.formControl.setValue('')
            : true
        "
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="autocompleteDisplayFn"
      >
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.MINI_SELECT_SINGLE">
  <button mat-stroked-button style="padding: 2px">
    <mat-select
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [style.width.px]="field.formControl.value < 1 ? 75 : 50"
    >
      <mat-option [value]="option" *ngFor="let option of $any(field.options)">
        {{ isConvertible ? (option | unitSystemLabel) : option }}
        <span matSuffix *ngIf="field.text && field.text.suffix">{{
          field.text.suffix
        }}</span>
      </mat-option>
    </mat-select>
  </button>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.SELECT_SINGLE_OBJECT">
  <mat-form-field
    [appearance]="appearance"
    [ngClass]="{
      subMenuTitle: 'mat-form-field-sub-menu',
      highlight: highlightField
    }"
  >
    <span matPrefix *ngIf="field.prefixIcon" class="prefixIcon">
      <mat-icon *ngIf="field.prefixIcon?.material">{{
        field.prefixIcon.icon
      }}</mat-icon>
      <mat-icon
        *ngIf="!field.prefixIcon?.material"
        [svgIcon]="field.prefixIcon.icon"
      ></mat-icon>
    </span>

    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <span matSuffix *ngIf="openInfoPopup.observed">
      <button class="infoPopupButton" (click)="doOpenInfoPopup($event)">
        <mat-icon svgIcon="info"></mat-icon>
      </button>
    </span>

    <mat-select
      [formControl]="field.formControl"
      [value]="field.formControl.value"
    >
      <mat-select-trigger *ngIf="field.formControl.value">
        {{
          isConvertible
            ? (field.formControl.value[field.text.showProperty]
              | unitSystemLabel)
            : field.formControl.value[field.text.showProperty]
        }}
      </mat-select-trigger>
      <mat-option
        [disabled]="!field.reactive.enabled && option.disabled"
        [value]="option"
        *ngFor="let option of $any(field.options)"
      >
        <mat-icon *ngIf="field.prefixIcon">{{ field.prefixIcon }}</mat-icon>
        {{
          isConvertible
            ? (option[field.text.showProperty] | unitSystemLabel)
            : option[field.text.showProperty]
        }}
        <span matSuffix *ngIf="field.text && field.text.suffix">{{
          field.text.suffix
        }}</span>
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
    <mat-hint
      *ngIf="!field.text?.hint && highlightField"
      class="restore-value-hint"
    >
      Value changed.
      <u (click)="restoreDefaultValue()">Reset</u>
    </mat-hint>
  </mat-form-field>

  <ng-container *ngIf="field.text && subMenuTitle">
    <div class="form-field-sub-menu">
      <a (click)="subMenuHandler($event)">
        {{ subMenuTitle }}
      </a>
    </div>
  </ng-container>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.SELECT_SINGLE_OBJECT_COMPARE">
  <mat-form-field [appearance]="appearance">
    <span matPrefix *ngIf="field.prefixIcon" class="prefixIcon">
      <mat-icon *ngIf="field.prefixIcon?.material">{{
        field.prefixIcon.icon
      }}</mat-icon>
      <mat-icon
        *ngIf="!field.prefixIcon?.material"
        [svgIcon]="field.prefixIcon.icon"
      ></mat-icon>
    </span>

    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >

    <mat-select
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [compareWith]="compareFn"
    >
      <mat-select-trigger>
        {{ field.formControl.value[field.text.showProperty] }}
      </mat-select-trigger>
      <mat-option
        [disabled]="!field.reactive.enabled && option.disabled"
        [value]="option"
        *ngFor="let option of $any(field.options)"
      >
        <mat-icon *ngIf="field.prefixIcon">{{ field.prefixIcon }}</mat-icon>
        {{ option[field.text.showProperty] }}
        <span matSuffix *ngIf="field.text && field.text.suffix">{{
          field.text.suffix
        }}</span>
      </mat-option>
    </mat-select>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
  </mat-form-field>

  <ng-template *ngIf="field.formControl.invalid; then fieldError"></ng-template>
</ng-container>

<ng-container *ngIf="field.type === fieldType.DRAG_AND_DROP_TARGET">
  <app-import-file
    [multiple]="field.multiple"
    [label]="field.text.label"
    [fileCategory]="field.fileCategory"
    [loading]="field.loading"
    [accept]="field.accept"
    [sizeLimit]="field.sizeLimit"
    [includeOrgIdInFileName]="field.includeOrgIdInFileName"
    (assets)="field.formControl.setValue($event, { emitEvent: true })"
  ></app-import-file>
</ng-container>

<ng-container *ngIf="field.type === fieldType.CAPTCHA">
  <div class="captcha-wrapper">
    <div *ngIf="field.text.name === 'robot'" class="captcha-overlay-text">
      I'm not a robot, but I might want to get one
    </div>
    <ngx-recaptcha2
      [formControl]="field.formControl"
      [id]="field.id"
      [siteKey]="siteKey"
      [useGlobalDomain]="false"
    >
    </ngx-recaptcha2>
  </div>
</ng-container>

<ng-container *ngIf="field.type === fieldType.HR">
  <hr />
  <span *ngIf="field.text?.label" [style.color]="labelColor">{{
    field.text.label
  }}</span>
</ng-container>

<ng-container *ngIf="field.type === fieldType.DATE">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <input
      [errorStateMatcher]="eagerMatcher"
      matInput
      name="field.text.name"
      [matDatepicker]="picker"
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [required]="field.required"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="field.type === fieldType.TIME">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="field.text && field.text.label" [style.color]="labelColor"
      >{{ field.text.label }}:</mat-label
    >
    <input
      [errorStateMatcher]="eagerMatcher"
      matInput
      name="field.text.name"
      type="time"
      [formControl]="field.formControl"
      [value]="field.formControl.value"
      [required]="field.required"
    />

    <mat-hint *ngIf="field.text && field.text.hint">{{
      field.text.hint
    }}</mat-hint>
  </mat-form-field>
</ng-container>

<ng-template #fieldError>
  <ng-container *ngFor="let errorMsg of field.errorMsgs | keyvalue">
    <mat-error
      style="
        font-size: 75%;
        font-family: 'Montserrat', sans-serif !important;
        margin-left: 0.9em;
      "
      *ngIf="field.formControl.errors?.[errorMsg.key]"
    >
      {{ field.errorMsgs.get(errorMsg.key) }}
    </mat-error>
  </ng-container>
</ng-template>
