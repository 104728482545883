import { Component } from '@angular/core';
import { pagesPATH } from '../../../../models_new/config/pages';

@Component({
  selector: 'app-hardware-wrapper',
  templateUrl: './hardware-wrapper.component.html',
  styleUrls: ['./hardware-wrapper.component.scss'],
})
export class HardwareWrapperComponent {
  pagePath = pagesPATH;
}
