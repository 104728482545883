import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  MarketingPageTagID,
  MarketingTagID,
} from '../models_new/config/marketing-tags';

declare let gtag: Function;

interface IMarketingTag {
  id: MarketingTagID;
  eventAction: string;
  eventName?: string;
  eventCategory?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  getTagConfig(id: MarketingTagID): IMarketingTag {
    return { id: id, eventAction: id };
  }

  getLastParam(): string {
    const lastParamIndex: number = window.location.href.lastIndexOf('/') + 1;
    return window.location.href.substring(
      lastParamIndex,
      window.location.href.length
    );
  }

  /**
   * @param extra // (optional. Ex: box count when autostack)
   */
  addEvent(id: MarketingTagID, extra?: string, label?: string): void {
    // Ignore spec test
    if (window.location.origin === 'http://localhost:9876') {
      return;
    }

    const marketingTag: IMarketingTag = this.getTagConfig(id);

    if (marketingTag.eventAction) {
      marketingTag.eventCategory = 'MRC';
      marketingTag.eventName = 'navigation';
      let action = JSON.parse(JSON.stringify(marketingTag.eventAction));
      if (extra) {
        action = action + ' - ' + extra;
      }

      if (!environment.development) {
        this.eventEmitter(
          marketingTag.eventName,
          marketingTag.eventCategory,
          action,
          label
        );

        // In a development environment, pretend to send analytics.
      } else {
        console.debug(
          `Name: ${marketingTag.eventName} ,\nCategory: ${marketingTag.eventCategory},\nAction: ${action},\nLabel: ${label}`
        );
      }
    }
  }

  addPageview(pagePath: MarketingPageTagID, pageTitle: string): void {
    // Ignore spec test
    if (window.location.origin === 'http://localhost:9876') {
      return;
    }

    if (!environment.development) {
      this.pageViewEmitter(pagePath, pageTitle);

      // In a development environment, pretend to send analytics.
    } else {
      console.debug(`Path: ${pagePath},\nTitle: ${pageTitle}`);
    }
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      event_action: eventAction,
      value: eventValue,
    });
  }

  public pageViewEmitter(pagePath: string, pageTitle: string) {
    gtag('config', environment.googleAnalyticsGtag, {
      page_path: pagePath,
      page_title: pageTitle,
    });
  }

  public sendVirtualPageView(pagePath: string, pageTitle: string) {
    gtag('config', environment.googleAnalyticsGtag, {
      page_path: pagePath,
      page_title: pageTitle,
    });
  }

  gtag_report_conversion() {
    gtag('event', 'conversion', {
      send_to: 'AW-607112064/aaHzCNqA4twBEICXv6EC',
    });
    return false;
  }
}
