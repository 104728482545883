import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import * as PPBTimeUtil from 'src/app/utils/time-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PickerSelectionAmount } from 'src/app/models_new/enums/picker-selection-amount';
import { ITableAction, ITableData } from '../table/table.component';
import { IExpandableTableData } from 'src/app/models_new/types/expandable-table-data';
import { FilterTableData } from 'src/app/models_new/types/sorting-option';

@Component({
  selector: 'app-table-expandable',
  templateUrl: './table-expandable.component.html',
  styleUrls: ['./table-expandable.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('* <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableExpandableComponent<ActionType>
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() inputData: ITableData<ActionType>[];
  @Input() displayedColumns: string[];
  @Input() tableName: string;
  @Input() actions?: boolean;
  @Input() showUpdatedAt?: boolean;
  @Input() blockActions?: ITableAction[];
  @Input() clickableRows?: boolean;
  @Input() topRow = true;
  @Input() preSelectedRows: ITableData['data'][] = [];
  @Input() showPaginator = true;
  @Input() strictFirstColWidth?: boolean;
  @Input() shouldDoTitleCase: boolean = true;
  @Input() shouldShowButtonToCreateIfNoData: boolean = false;
  @Input() noDataText = 'This seems to be empty. Try adding some data!';
  @Input() selectionAmount: PickerSelectionAmount = 'SELECT_MULTIPLE';

  @Input() set filter(filter: FilterTableData) {
    this.currentFilter = filter;
    if (this.dataSource && this.inputData?.length) {
      this.dataSource.filter = 'trigger_filter';
    }
  }

  @Input() set sortBy(sort: { column: string; order: SortDirection }) {
    if (sort && this.dataSource) {
      this.setSorting(sort.column, sort.order);
    }
  }

  @Output() actionClicked: EventEmitter<ITableAction> = new EventEmitter();
  @Output() rowClicked: EventEmitter<ITableData['data']> = new EventEmitter();
  @Output() blockSelectedChange: EventEmitter<any[]> = new EventEmitter();
  @Output() didPressCreate: EventEmitter<any> = new EventEmitter();

  timeUtils = PPBTimeUtil;

  fallbackSceneImage: string = '../../../../assets/illustrative/simulation.png';

  dataSource: MatTableDataSource<IExpandableTableData>;

  currentFilter: FilterTableData = new FilterTableData();
  destroy$: Subject<boolean> = new Subject<boolean>();
  columnsToDisplay: string[];
  selection = new SelectionModel<ITableData['data']>(true, []);
  expandedElement: MatCellDef | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    if (this.showUpdatedAt && !this.displayedColumns.includes('updated_at')) {
      this.displayedColumns.push('updated_at');
    }
    if (this.actions) {
      this.displayedColumns.push('actions');
    }
    this.columnsToDisplay = this.displayedColumns
      ? this.displayedColumns.slice()
      : null;

    this.columnsToDisplay.unshift(' ');

    // Put select column first
    if (this.columnsToDisplay.find((val) => val === 'select')) {
      this.columnsToDisplay = this.columnsToDisplay.filter(
        (element) => element !== 'select'
      );
      this.columnsToDisplay.unshift('select');
    }

    this.dataSource = new MatTableDataSource(this.inputData.map((m) => m.data));
    this.dataSource.filterPredicate = (data: IExpandableTableData) => {
      return (
        this.currentFilter.keywordFilter(data.data) &&
        this.currentFilter.sliderFilter(data.data) &&
        this.currentFilter.textFilterExandable(data)
      );
    };
    //Trigger filter at initialization
    this.dataSource.filter = 'trigger_filter';

    this.selection = new SelectionModel(
      this.selectionAmount === 'SELECT_MULTIPLE',
      []
    );
    if (this.preSelectedRows) {
      for (let pre_select_row of this.preSelectedRows) {
        for (let row of this.inputData) {
          if (pre_select_row.id === row.data.id) {
            this.selection.select(row.data);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData) {
      if (!changes.inputData.firstChange) {
        if (this.actions && !this.displayedColumns.includes('actions')) {
          this.displayedColumns.push('actions');
        }
        this.updateChangedValuesOnly(
          changes.inputData?.previousValue,
          changes.inputData?.currentValue
        );
        this.paginateContent();
      }
    }
  }

  ngAfterViewInit() {
    this.paginateContent();
    this.selection.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) =>
        this.blockSelectedChange.emit(s.source.selected as any[])
      );
  }

  setSorting(column: string, direction: SortDirection) {
    this.sort.active = column;
    this.sort.direction = direction;
    this.sort.sortChange.emit({ active: column, direction: direction });
  }

  updateChangedValuesOnly(
    inputDataPrevious: ITableData<ActionType>[],
    inputDataUpdate: ITableData<ActionType>[]
  ) {
    if (inputDataPrevious.length !== inputDataUpdate.length) {
      for (let row of inputDataPrevious) {
        if (
          !inputDataUpdate.find(
            (currentValue) => currentValue.data?.id === row.data?.id
          )
        ) {
          this.selection.deselect(row.data);
        }
      }
    }
    if (inputDataUpdate.length !== this.dataSource?.data?.length) {
      this.dataSource = new MatTableDataSource(
        this.inputData.map((m) => m.data)
      );
    } else {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        let newVal = inputDataUpdate.find(
          (currentValue) =>
            this.dataSource.data[i]['id'] === currentValue.data?.id
        ).data;
        if (newVal) {
          this.dataSource.data[i]['items'] = newVal.items;
          this.dataSource.data[i]['error'] = newVal.error;
          this.dataSource.data[i]['expandableContent'] =
            newVal.expandableContent;
          this.dataSource.data[i]['rowButtons'] = newVal.rowButtons;
        }
      }
    }
  }

  private paginateContent(): void {
    if (this.showPaginator) {
      setTimeout(() => {
        this.paginator.pageSize = 10;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
          const value = item['items'].find((f) => f.label === property).value;

          if (typeof value === 'string') {
            const asFloat = parseFloat(value);
            return isNaN(asFloat) ||
              property === 'updated_at' ||
              property === 'created_at'
              ? value.toLocaleLowerCase()
              : asFloat;
          } else if (typeof value === 'function') {
            return value();
          } else {
            return value;
          }
        };
        this.dataSource.sort = this.sort;
      }, 10);
    }
  }

  getActions(element: any) {
    return this.inputData.find((f) => f.data?.id === element.id).actions;
  }

  isLoading(element: any) {
    return this.inputData.find((f) => f.data?.id === element.id).isLoading;
  }

  getBgColor(element: any) {
    return this.inputData.find((f) => f.data?.id === element.id)?.data?.bgColor;
  }

  // angular bootstrap grid system uses 12 columns, use left-side content width to set width of right-side
  getRightColumnWidth(
    left_column: 'col-3' | 'col-4' | 'col-5' | 'col-6' | 'col-7' | 'col-8'
  ) {
    let rightSideWidth = 'col-4';
    switch (left_column) {
      case 'col-3':
        rightSideWidth = 'col-9';
        break;
      case 'col-4':
        rightSideWidth = 'col-8';
        break;
      case 'col-5':
        rightSideWidth = 'col-7';
        break;
      case 'col-6':
        rightSideWidth = 'col-6';
        break;
      default:
        break;
    }
    return rightSideWidth;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.filteredData);
  }

  returnValue(value) {
    return typeof value !== 'undefined' ? value : 'N/A';
  }
}
