import { Component, Input, OnInit } from '@angular/core';
import { OpenSimService } from '../open-sim.service';

@Component({
  selector: 'app-fast-track-outlet',
  templateUrl: './fast-track-outlet.component.html',
  styleUrls: ['./fast-track-outlet.component.scss'],
})
export class FastTrackOutletComponent implements OnInit {
  @Input() embed: boolean = false;
  public deviceDialogDismissed: boolean = false;

  constructor(public openSimService: OpenSimService) {}

  ngOnInit(): void {
    if (this.embed) {
      this.openSimService.embed = true;
    }
  }
}
