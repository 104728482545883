import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogoViewComponent } from '../components/gui/3dview/views/logo-view/logo-view.component';
import { ThreePerspectiveComponent } from '../components/gui/three-perspective/three-perspective.component';
import { ThreeViewComponent } from '../components/gui/three-view/three-view.component';
import { MaterialModule } from './material.module';
import { ModelPreviewComponent } from '../components/gui/3dview/views/model-preview/model-preview.component';
import { New_ThreeViewComponent } from '../components/gui/3dview/three-view/three-view.component';
import { DemoViewComponent } from '../components/gui/3dview/views/demo-view/demo-view.component';
import { AssetComponent } from '../components/gui/3dview/content/core/asset/asset/asset.component';
import { OrthographicCameraComponent } from '../components/gui/3dview/content/core/cameras/orthographic-camera/orthographic-camera.component';
import { PerspectiveCameraComponent } from '../components/gui/3dview/content/core/cameras/perspective-camera/perspective-camera.component';
import { OrbitControlsComponent } from '../components/gui/3dview/content/core/controls/orbit-controls/orbit-controls.component';
import { DebugRenderingStatsComponent } from '../components/gui/3dview/content/core/debug/debug-rendering-stats/debug-rendering-stats.component';
import { AmbientLightComponent } from '../components/gui/3dview/content/core/lights/ambient-light/ambient-light.component';
import { DirectionalLightComponent } from '../components/gui/3dview/content/core/lights/directional-light/directional-light.component';
import { ModelComponent } from '../components/gui/3dview/content/core/model/model.component';
import { ThreePalletComponent } from '../components/gui/3dview/content/three-pallet/three-pallet.component';
import { NewProductViewComponent } from '../components/gui/3dview/views/product-view/product-view.component';
import { LitePatternViewComponent } from '../components/gui/3dview/views/lite-pattern-view/lite-pattern-view.component';
import { ProductionLineViewComponent } from '../components/gui/3dview/views/production-line-view/production-line-view.component';
import { SimulationViewComponent } from '../components/gui/3dview/views/simulation-view/simulation-view.component';
import { PatternViewComponent } from '../components/gui/3dview/views/pattern-view/pattern-view.component';
import { AssetPipe } from '../pipes/asset.pipe';
import { TemplateVariableDirective } from '../directives/templateVariable.directive';
import { EarthGlobeViewComponent } from '../components/gui/3dview/views/earth-globe-view/earth-globe-view.component';
import { PalletReportViewComponent } from '../components/gui/3dview/views/pallet-report-view/pallet-report-view.component';
import { ErrorComponent } from '../components/gui/error/error.component';
import { ThreeStateComponent } from '../components/gui/3dview/three-view/three-state/three-state.component';
import { MyURViewComponent } from '../components/gui/3dview/views/my-ur-view/my-ur-view.component';
import { HighligtedMessageCardComponent } from '../components/gui/highligted-message-card/highligted-message-card.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    ThreeViewComponent,
    ThreePerspectiveComponent,
    ThreePerspectiveComponent,
    TemplateVariableDirective,
    ErrorComponent,
    HighligtedMessageCardComponent,

    // New 3dview components
    ThreeStateComponent,
    New_ThreeViewComponent,
    AssetComponent,
    OrthographicCameraComponent,
    PerspectiveCameraComponent,
    OrbitControlsComponent,
    DebugRenderingStatsComponent,
    //DebugTreeVisualizerComponent, // Will be introduced later, debug tool to visualize object trees.
    AmbientLightComponent,
    DirectionalLightComponent,
    ModelComponent,
    // PerspectiveComponent, // Will be introduced later. Perspective components for moving camera to specific positions / perspectives.
    // NavigationCubeComponent, // Will be introduced later.
    ThreePalletComponent,

    // Pipes
    AssetPipe,

    // New 3dviews
    DemoViewComponent,
    LogoViewComponent,
    NewProductViewComponent,
    LitePatternViewComponent,
    ProductionLineViewComponent,
    SimulationViewComponent,
    PatternViewComponent,
    EarthGlobeViewComponent,
    PalletReportViewComponent,
    ModelPreviewComponent,
    MyURViewComponent,
  ],
  exports: [
    ThreeViewComponent,
    ThreePerspectiveComponent,
    ThreePerspectiveComponent,
    TemplateVariableDirective,
    ErrorComponent,
    HighligtedMessageCardComponent,

    // New 3dview components
    ThreeStateComponent,
    New_ThreeViewComponent,
    AssetComponent,
    OrthographicCameraComponent,
    PerspectiveCameraComponent,
    OrbitControlsComponent,
    DebugRenderingStatsComponent,
    //DebugTreeVisualizerComponent, // Will be introduced later, debug tool to visualize object trees.
    AmbientLightComponent,
    DirectionalLightComponent,
    ModelComponent,
    // PerspectiveComponent, // Will be introduced later. Perspective components for moving camera to specific positions / perspectives.
    // NavigationCubeComponent, // Will be introduced later.
    ThreePalletComponent,

    // Pipes
    AssetPipe,

    // New 3dviews
    DemoViewComponent,
    LogoViewComponent,
    NewProductViewComponent,
    LitePatternViewComponent,
    ProductionLineViewComponent,
    SimulationViewComponent,
    PatternViewComponent,
    EarthGlobeViewComponent,
    PalletReportViewComponent,
    ModelPreviewComponent,
    MyURViewComponent,
  ],
  providers: [HighligtedMessageCardComponent],
})
export class ThreeModule {}
