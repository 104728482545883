import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { AssetStoreService } from '../../../services/asset-store.service';
import { palletViewColors } from '../../config/pallet-view-colors';
import { AssetIDs } from '../../enums/asset-ids';

export class AssetSelectionHelper extends THREE.Object3D {
  threeID: string;

  assets: string[];
  models: Map<string, THREE.Object3D> = new Map<string, THREE.Object3D>();
  rotations: Map<string, number> = new Map<string, number>();
  // speed: number;
  radius: number;
  axis: 'x' | 'y' | 'z';
  objectAxis: string;
  rotateObjects: boolean;
  includeID: boolean;

  prevSelectedID: string; // Previously selected asset
  selectedID: string; // Currently selected asset
  animationProgress = 0;

  font$: Observable<Font>;

  destroy$: ReplaySubject<boolean>;

  constructor(options?: {
    radius?: number;
    axis?: 'x' | 'y' | 'z';
    objectAxis?: string;
    rotateObjects?: boolean;
    includeID?: boolean;
  }) {
    super();

    if (options) {
      this.radius = options.radius;
      this.axis = options.axis;
      this.objectAxis = options.objectAxis;
      this.rotateObjects = options.rotateObjects;
      this.includeID = options.includeID;
    }

    if (!this.radius) {
      this.radius = 1;
    }
    if (!this.axis) {
      this.axis = 'y';
    }
    if (!this.objectAxis) {
      this.objectAxis = this.axis;
    }
    if (!this.rotateObjects) {
      this.rotateObjects = true;
    }
    if (!this.includeID) {
      this.includeID = true;
    }

    // Load font
    this.font$ = AssetStoreService.onAssetLoadedWithID<Font>(
      AssetIDs.HelvetikerFont
    ).pipe(take(1), shareReplay({ bufferSize: 1, refCount: true }));
  }

  update(dt: number): void {
    const target = this.rotations.get(this.selectedID);
    const current = this.rotations.get(this.prevSelectedID);
    this.animationProgress = Math.min(this.animationProgress + dt, 1);

    const amount = MathUtils.smoothstep(this.animationProgress, 0, 1);
    this.rotation[this.axis] = MathUtils.lerp(current, target, amount);
  }

  select(ID: string): void {
    this.prevSelectedID = this.selectedID ? this.selectedID : ID;
    this.selectedID = ID;

    this.animationProgress = 0;
  }

  place(): void {
    this.children = [];
    if (this.models.size > 0) {
      const angle = MathUtils.degToRad(360 / this.models.size);
      let i = 0;
      for (const [key, model] of this.models) {
        const bb = new THREE.Box3().expandByObject(model);
        const palletSize = new THREE.Vector3();
        bb.getSize(palletSize);

        const parent = new THREE.Object3D();
        parent.name = key;
        parent.add(model);

        this.add(parent);

        const otherAxis = this.getOtherAxis3(this.axis);
        const pos = new THREE.Vector3();
        pos[otherAxis[0]] = Math.cos(angle * i) * this.radius;
        pos[otherAxis[1]] = Math.sin(angle * i) * this.radius;
        parent.position.copy(pos);

        if (this.rotateObjects) {
          parent.rotation[this.objectAxis] = -angle * i;
        }

        if (this.includeID) {
          const textParent = new THREE.Object3D();
          textParent.setRotationFromEuler(
            new THREE.Euler(MathUtils.degToRad(-90), 0, 0)
          );
          parent.add(textParent);

          this.font$.subscribe((font) => {
            const namegeo = new TextGeometry(key, {
              font,
              size: 0.1,
              height: 0.01,
              curveSegments: 6,
              bevelEnabled: false,
            });
            const nametag = new THREE.Mesh(
              namegeo,
              new THREE.MeshLambertMaterial({
                color: palletViewColors.selectedPallet,
              })
            );
            nametag.setRotationFromEuler(
              new THREE.Euler(0, MathUtils.degToRad(45), MathUtils.degToRad(90))
            );
            namegeo.computeBoundingBox();

            const nametagSize = new THREE.Vector3();
            namegeo.boundingBox.getSize(nametagSize);

            nametag.position.z = nametagSize.z / 2;
            nametag.position.y = -nametagSize.x / 2;
            textParent.add(nametag);

            textParent.position.x = palletSize.x / 2 + nametagSize.z + 0.2;
          });
        }

        this.rotations.set(key, angle * i);
        i++;
      }
    }
  }

  getOtherAxis3(axis: 'x' | 'y' | 'z'): string[] {
    if (axis === 'x') {
      return ['y', 'z'];
    }
    if (axis === 'y') {
      return ['x', 'z'];
    }
    if (axis === 'z') {
      return ['x', 'y'];
    }

    throw new Error('getOtherAxis3: Invalid axis: ' + axis);
  }

  addModel(ID: string, model: THREE.Object3D): void {
    this.models.set(ID, model);
    this.place();
  }

  removeModel(ID: string): void {
    this.models.delete(ID);
    this.place();
  }

  clear() {
    this.models.clear();
    this.rotations.clear();
    this.assets = [];
    this.place();
    return this;
  }
}
