import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-toolbar',
  templateUrl: './landing-toolbar.component.html',
  styleUrls: ['./landing-toolbar.component.scss'],
})
export class LandingToolbarComponent {
  pagesPath = pagesPATH;
  logoutPath = window.location.href;
  environment = environment;
  scrolled = false;

  @Input() logo?: string; // LOGO URL

  constructor(
    public auth: AuthService,
    private router: Router,
    public publicApi: PublicApiService
  ) {}

  toDashboard() {
    this.router.navigate([pagesPATH.CUSTOMERS]);
  }

  toSignup() {
    this.auth.loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
    });
  }
}
