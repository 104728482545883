import {
  defaultPallyFileLayer,
  defaultPallyFileType,
} from '../../config/default/default-pally-file-type';
import {
  pallyFileCouldHaveKeys,
  pallyFileShouldHaveKeys,
} from '../../config/validation/pally-file-keys';
import { ErrorType } from '../../enums/error-type';
import { LayerType } from '../../enums/layer-type';
import { IExportImportResult } from '../../types/export-import-result';
import {
  IPallyBox,
  IPallyFileType,
  IPallyLayer,
} from '../../types/pally-file-type';

interface IlayerError {
  layerIndex?: number;
  layerName?: string;
  boxNo?: number;
  message: string;
  type: ErrorType;
}

interface IvalidKeys {
  projectData: string[];
  dimensions: string[];
  productDimensions: string[];
  guiSettings: string[];
  layer: string[];
  shimPaper: string[];
  pattern: string[];
  allLayersTypes: string[];
}

export class ImportKeyValidator {
  project: IPallyFileType;

  shouldBeDefined: IvalidKeys = pallyFileShouldHaveKeys;
  couldBeDefined: IvalidKeys = pallyFileCouldHaveKeys;

  layerErrors: IlayerError[] = [];
  errors: IExportImportResult[] = [];

  constructor(json: IPallyFileType) {
    this.project = json;

    this.hasUnvalidKeys(this.project);

    if (!this.hasLayers()) {
      this.addError({
        message: 'Project has no layers',
        type: ErrorType.BREAKING,
      });
    }

    if (!this.hasLayerTypes()) {
      this.addError({
        message: 'Project has no layerTypes',
        type: ErrorType.BREAKING,
      });
    } else {
      this.validateLayers();
    }
  }

  validateMissingKeys(keys, validValues, where, fix?): string[] {
    const shouldBeFixed = [];

    validValues.forEach((validKey) => {
      if (!keys.includes(validKey)) {
        // Ignore zone keys.
        if (validKey === 'zones' || validKey === 'altZones') {
          return;
        }
        if (fix && validKey !== 'id') {
          // Id is critical now
          shouldBeFixed.push(validKey);
          this.addError({
            message: `Key: '${validKey}' in ${where} was missing. We added a default value`,
            type: ErrorType.MINOR,
          });
        } else {
          this.addError({
            message: `Key: '${validKey}' in ${where} is missing`,
            type: ErrorType.BREAKING,
          });
        }
      }
    });

    return shouldBeFixed;
  }

  validateValidKeys(keys, validValues, where, fix?): string[] {
    const shouldBeFixed = [];

    keys.forEach((key) => {
      if (!validValues.includes(key)) {
        if (fix) {
          shouldBeFixed.push(key);
        } else {
          // Ignore zone keys.
          if (key === 'zones' || key === 'altZones') {
            return;
          }

          this.addError({
            message: `Key: '${key}' in ${where} is not a valid key and will be lost`,
            type: ErrorType.MINOR,
          });
        }
      }
    });

    return shouldBeFixed;
  }

  hasUnvalidKeys(value: IPallyFileType) {
    // Concat should and could objects
    const concatObj = {};
    for (const v in this.shouldBeDefined) {
      if (this.shouldBeDefined.hasOwnProperty(v)) {
        concatObj[v] = [...this.shouldBeDefined[v], ...this.couldBeDefined[v]];
      }
    }

    const valid: IvalidKeys = concatObj as IvalidKeys;

    const projectDataKeys = Object.keys(value);

    // Validate if keys are missing
    const projectMissingKeys: string[] = this.validateMissingKeys(
      projectDataKeys,
      pallyFileShouldHaveKeys.projectData,
      'project data',
      true
    );

    // Fix missing keys
    projectMissingKeys.forEach((key) => {
      this.fixError(value, key, defaultPallyFileType[key]);
    });

    const dimensionsKeys = Object.keys(value.dimensions);

    const dimensionsMissingKeys: string[] = this.validateMissingKeys(
      dimensionsKeys,
      pallyFileShouldHaveKeys.dimensions,
      'pallet dimensions',
      true
    );

    const productDimensionsKeys = Object.keys(value.productDimensions);

    const productMissingKeys: string[] = this.validateMissingKeys(
      productDimensionsKeys,
      pallyFileShouldHaveKeys.productDimensions,
      'product dimensions',
      true
    );

    const guiSettingsKeys = Object.keys(value.guiSettings);

    const guiSettingsMissingKeys: string[] = this.validateMissingKeys(
      guiSettingsKeys,
      pallyFileShouldHaveKeys.guiSettings,
      'gui settings',
      true
    );

    dimensionsMissingKeys.forEach((key) => {
      this.fixError(
        value.dimensions,
        key,
        defaultPallyFileType.dimensions[key]
      );
    });

    productMissingKeys.forEach((key) => {
      this.fixError(
        value.productDimensions,
        key,
        defaultPallyFileType.productDimensions[key]
      );
    });

    guiSettingsMissingKeys.forEach((key) => {
      this.fixError(
        value.guiSettings,
        key,
        defaultPallyFileType.guiSettings[key]
      );
    });

    // Validate keys found are valid
    this.validateValidKeys(projectDataKeys, valid.projectData, 'project data');
    this.validateValidKeys(
      dimensionsKeys,
      valid.dimensions,
      'pallet dimensions'
    );
    this.validateValidKeys(
      productDimensionsKeys,
      valid.productDimensions,
      'product dimensions'
    );
    this.validateValidKeys(guiSettingsKeys, valid.guiSettings, 'gui settings');

    if (!this.hasLayerTypes()) {
      this.addError({
        message: 'Project has no layerTypes',
        type: ErrorType.BREAKING,
      });
    } else {
      value.layerTypes.forEach((layer: IPallyLayer) => {
        let layerKeys = Object.keys(layer);

        // Validate if keys are missing for layers before checking found keys.
        const layerMissingKey = this.validateMissingKeys(
          layerKeys,
          pallyFileShouldHaveKeys.allLayersTypes,
          `layer named '${layer.name}'`,
          true
        );
        layerMissingKey.forEach((key) => {
          /**
           * Special rule for 'class' key
           * If layer has pattern key, assume its a "layer", else assume its an error
           */

          if (key === 'class') {
            if (layer.pattern) {
              this.fixError(layer, key, defaultPallyFileLayer[key]);
            }
          } else {
            this.fixError(layer, key, defaultPallyFileLayer[key]);
          }
        });

        layerKeys = Object.keys(layer);

        this.validateMissingKeys(
          layerKeys,
          pallyFileShouldHaveKeys.allLayersTypes,
          `layer named '${layer.name}'`
        );

        // Validate keys for layer and separator class
        if (layer.class === LayerType.LAYER) {
          this.validateValidKeys(
            layerKeys,
            valid.layer,
            `layer named '${layer.name}' (Type: layer)`
          );
        } else {
          this.validateValidKeys(
            layerKeys,
            valid.shimPaper,
            `layer named '${layer.name}' (Type: separator)`
          );
        }

        if (layer.pattern) {
          layer.pattern.forEach((box, i) => {
            const boxKeys = Object.keys(box);
            this.validateValidKeys(
              boxKeys,
              valid.pattern,
              `box no. ${i + 1} in layer named ${layer.name}`
            );
          });
        }

        if (layer.altPattern) {
          layer.altPattern.forEach((box, i) => {
            const boxKeys = Object.keys(box);
            this.validateValidKeys(
              boxKeys,
              valid.pattern,
              `box no. ${i + 1} in layer named ${layer.name}`
            );
          });
        }
      });
    }
  }

  fixError(object: any, key: string, fix: any): any {
    object[key] = fix;
    return object;
  }

  addError(layerError: IlayerError) {
    this.layerErrors.push(layerError);
    const error: IExportImportResult = {
      message: layerError.message,
      type: layerError.type,
    };

    this.errors.push(error);
  }

  /**
   * @param array: any[]
   */
  hasLength(array: any[]): boolean {
    return array.length > 0;
  }

  /**
   * @param object: any
   * @param key: string
   */
  isDefined(object: any, key: string) {
    return object[key] !== undefined && object[key] !== null;
  }

  hasLayers(): boolean {
    if (this.isDefined(this.project, 'layers')) {
      return this.hasLength(this.project.layers);
    } else {
      return false;
    }
  }

  hasLayerTypes(): boolean {
    if (this.isDefined(this.project, 'layerTypes')) {
      return this.hasLength(this.project.layerTypes);
    } else {
      return false;
    }
  }

  validateLayers(): void {
    this.project.layerTypes.forEach(
      (layer: IPallyLayer, layerIndex: number) => {
        const error: IlayerError = {
          layerIndex: layerIndex,
          layerName: layer.name,
          message: '',
          boxNo: null,
          type: ErrorType.MINOR,
        };

        if (layer.class === LayerType.LAYER) {
          // Check values to be defined in layer
          this.shouldBeDefined.layer.forEach((lv: string) => {
            if (!this.isDefined(layer, lv)) {
              if (lv === 'pattern') {
                error.message = `${lv} is not defined in '${error.layerName}'`;
                error.type = ErrorType.MAJOR;
              } else {
                error.message = `${lv} is not defined in '${error.layerName}'`;
              }

              this.addError(error);
            }

            if (
              this.isDefined(layer, lv) &&
              (lv === 'pattern' || lv === 'altPattern')
            ) {
              // Loop over pattern or altPattern in layer
              layer[lv].forEach((box: IPallyBox, boxIndex: number) => {
                // Check values to be defined in box
                this.shouldBeDefined.pattern.forEach((pv: string) => {
                  if (!this.isDefined(box, pv)) {
                    error.boxNo = boxIndex;
                    error.message = `${pv} is not defined in 'box: ${error.boxNo}' in '${error.layerName}'`;
                    error.type = ErrorType.MAJOR;
                    this.addError(error);
                  }
                });
              });
            }
          });
        }

        if (layer.class === LayerType.SHIMPAPER) {
          // Check values to be defined in shimpaper
          this.shouldBeDefined.shimPaper.forEach((lv: string) => {
            if (!this.isDefined(layer, lv)) {
              error.message = `${lv} is not defined in '${error.layerName}'`;
              error.type = ErrorType.MAJOR;
              this.addError(error);
            }
          });
        }
      }
    );
  }
}
