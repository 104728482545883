import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThreeUtils } from '../../../utils/three-utils';
import { URDFUtils } from '../../../utils/urdf-utils';
import { AssetType, IAsset } from '../../types/asset/new-asset';

export class Asset<Ttype> implements IAsset<Ttype> {
  id: string;
  src: string | undefined;
  type: AssetType;
  needsSASToken: boolean;

  progress$: BehaviorSubject<number>;

  private _data$: ReplaySubject<Ttype>;

  data$: Observable<Ttype>;

  constructor(id: string, type: AssetType, src?: string) {
    this.id = id;
    this.src = src;
    this.type = type;
    this.progress$ = new BehaviorSubject<number>(-1); // -1 indicates loading hasn't started.
    this._data$ = new ReplaySubject<Ttype>(1);
    this.data$ = this._data$.pipe(
      map((value: any) => {
        if (this.type === 'urdf') {
          return URDFUtils.clone(value);
        } else if (
          this.type === 'fbx' ||
          this.type === 'dae' ||
          this.type === 'stl'
        ) {
          return ThreeUtils.clone(value);
        } else {
          return value;
        }
      })
    );
  }

  set(obj: Ttype): void {
    this._data$.next(obj);
  }

  isLoaded(): boolean {
    return this.progress$.getValue() > 0.999;
  }

  isLoading(): boolean {
    return this.progress$.getValue() >= 0 && this.progress$.getValue() < 99.9;
  }

  reportError(error: Error): void {
    this._data$.error(error);
  }
}
