<div class="delete_prompt_wrapper">
  <ng-container *ngIf="!deleteUsagePrompt">
    <h3>
      Delete {{ type }} <b>{{ title }}</b
      >?
    </h3>

    <p>
      This action <b>cannot</b> be undone.<br />
      This will <b>permanently delete</b> the {{ type }} {{ title }}.<br />
    </p>

    <p>Please type the name of the {{ type }} to confirm.</p>

    <app-field [field]="fields[0]"></app-field>

    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button
      [disabled]="!checkValidity(0, title)"
      style="float: right"
      color="warn"
      mat-raised-button
      (click)="delete()"
    >
      Delete {{ type }}
    </button>
  </ng-container>
  <ng-container *ngIf="deleteUsagePrompt">
    <h3>
      Delete {{ usageType }} <b>{{ usageTitle }}</b
      >?
    </h3>

    <p>
      The {{ type }} <b>{{ title }}</b> is used by the {{ usageType }}
      <b>{{ usageTitle }}</b>
    </p>

    <p>
      This action <b>cannot</b> be undone.<br />
      This will <b>permanently delete</b> the {{ usageType }}
      {{ usageTitle }}.<br />
    </p>

    <p>Please type the name of the {{ usageType }} to confirm.</p>

    <app-field [field]="fields[1]"></app-field>

    <button (click)="skipDeleteUsage()" mat-raised-button>
      Don`t delete {{ usageType }}
    </button>
    <button
      [disabled]="!checkValidity(1, usageTitle)"
      style="float: right"
      color="warn"
      mat-raised-button
      (click)="deleteUsage()"
    >
      Delete {{ usageType }}
    </button>
  </ng-container>
</div>
