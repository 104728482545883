import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PickerSelectionAmount } from 'src/app/models_new/enums/picker-selection-amount';
import { SimulationStatus } from 'src/app/models_new/enums/simulation-status';
import { RoleAction } from '../../../models_new/config/role-gui/available-actions';
import * as PPBTimeUtil from 'src/app/utils/time-utils';
import { FilterTableData } from 'src/app/models_new/types/sorting-option';
import { RoleApiService } from '../../../services/api/role-api.service';
import { RXJSUtils } from '../../../utils/rxjs-utils';
import { isCommandAvailable } from '../../../directives/if-command-available.directive';
import { InstalledRobotsActions } from '../../inventory/installed-robots/installed-robots.component';
import { InfoPopupComponent } from '../../dialogs/info-popup/info-popup.component';
import { DialogSize } from '../../../models_new/enums/dialogSize';
import { DialogService } from '../../../services/dialog.service';

/**
 * @typedef ITableData
 * @prop {Function} getCellClass A function which shall return a string of CSS class(es) for
 * styling selected column in a row. First parameter should be row data element and last should be the column id.
 *
 * @prop {object} error A object containing ITableDataError objects keyed according to the column/field for which the error
 * is related.
 *
 * Tip: Include id inside the data element when constructing a new table.
 * Actions, background-color and loading status depends on this id.
 */
export interface ITableData<ActionType = never> {
  data: any & {
    bgColor?: string;
  };
  actions?: ITableAction<ActionType>[];
  error?: ITableDataError<ActionType>;
  info?: ITableDataError<ActionType>;
  getCellClass?: Function;
  isLoading?: boolean;
  infoPopup?: {
    id: string;
    dataSource: Observable<any>;
    substitutions?: { [key: string]: string };
  };
  origin?: any;
}

export interface ITableDataError<ActionType> {
  id: string;
  errorMessage?: string;
  onClickActionId?: ActionType | InstalledRobotsActions;
  actionText?: string;
  materialIconTag?: string;
}

export interface ITableAction<ActionType = never> {
  actionId:
    | string
    | ActionType
    | InstalledRobotsActions
    | 'divider'
    | 'project'
    | 'favourite';
  label?: string;
  element?: any;
  icon?: string;
  iconPosition?: 'left' | 'right';
  customIcon?: string;
  bgColor?: string;
  txtColor?: string;
  button?: string;
  disabled?: boolean;
  roleAction?: RoleAction;
  children?: ITableAction<ActionType>[];
  buttonTheme?: 'accent' | 'primary';
}

export interface ITableColumnOverrideTitle {
  columnId: string;
  columnTitle: string;
}

/**
 * @interface ITableHeaderAction Adds a triggerable action along with a header's title.
 * @param {string} actionId Id or command of the action to trigger.
 * @param {string} columnId Id of the header for this action to be shown along with. Has to macth with a "displayedColumns" element.
 * @param {string} label (Optional) Text to be displayed as action button.
 * @param {string} icon (Optional)  Icon to be displayed as/with the action button.
 */
export interface ITableHeaderAction {
  actionId: string;
  columnId: string;
  label?: string;
  icon?: string;
}

/**
 * @function hideColumns Simple function to remove columns id from an array of columns.
 * @param {string[]} allColumns An array of all column ids for a table
 * @param {string[]} columnsToHide  An array of all columns id to remove for the allColumns list
 */
export function hideColumns(allColumns: string[], columnsToHide: string[]) {
  const cols = [];
  allColumns.forEach((col) => {
    if (!columnsToHide.find((hide) => col == hide)) {
      cols.push(col);
    }
  });
  return cols;
}

/**
 * @component
 * Generic table component
 *
 * @description Generic table component for use project wide.
 *
 * @input {array} overrideColumnTitles - Input to override column titles where shouldDoTitleCase
 * (true or false) does not produce the wanted title casing for all columns. This input takes in
 * an array of objects where the columnId is the target column and columnTitle takes the exact
 * title which will be shown. The column titles for the ones defined in this array is overridden.
 * The other column titles will be rendred according to shouldDoTitleCase setting.
 *
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<ActionType>
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() inputData: ITableData<ActionType>[];
  @Input() displayedColumns: string[];
  @Input() tableName: string;
  @Input() actions?: boolean;
  @Input() showUpdatedAt?: boolean;
  @Input() blockActions?: ITableAction[];
  @Input() disableBlockSelection?: boolean;
  @Input() clickableRows?: boolean;
  @Input() clickToSelect?: boolean;
  @Input() topRow = true;
  @Input() headerActions?: ITableHeaderAction[];

  @Input() preSelectedRows: ITableData['data'][] = [];
  @Input() showPaginator = true;
  @Input() strictFirstColWidth?: boolean;
  @Input() shouldShowButtonToCreateIfNoData: boolean = false;
  @Input() noDataText = 'This seems to be empty. Try adding some data!';
  @Input() selectionAmount: PickerSelectionAmount = 'SELECT_MULTIPLE';
  @Input() shouldDoTitleCase: boolean = true;
  @Input() overrideColumnTitles?: ITableColumnOverrideTitle[] = [];
  /**
   *  @desc If screen-size is lower than officially supported (1200px), this will hide the
   *  rest of actions, just leving the '︙' btn, where all actions will be shown.
   */
  @Input() hideRowActions?: boolean = false;
  /**
   * An existing SelectionModel can be sent as input if an external selection model is preferred
   * instead of the table component creating its own internal one. This is the case if used with
   * the content-switch wrapper.
   */
  @Input() existingSelectionModel?: SelectionModel<ITableData['data']>;

  @Input() set textFilter(val: string) {
    if (this.dataSource && this.inputData?.length) {
      this.applyFilter(val);
    }
  }
  @Input() set statusFilter(_: any) {
    if (this.dataSource && this.inputData?.length) {
    }
  }
  @Input() set dateFilter(_: any) {
    if (this.dataSource && this.inputData?.length) {
    }
  }

  @Input() set filter(filter: FilterTableData) {
    this.currentFilter = filter;
    if (this.dataSource && this.inputData?.length) {
      this.dataSource.filter = 'trigger_filter';
    }
  }
  @Input() set sortBy(sort: { column: string; order: SortDirection }) {
    if (sort && this.dataSource) {
      this.setSorting(sort.column, sort.order);
    }
  }

  @Output() actionClicked: EventEmitter<ITableAction<ActionType>> =
    new EventEmitter();
  @Output() rowClicked: EventEmitter<ITableData['data']> = new EventEmitter();
  @Output() blockSelectedChange: EventEmitter<any[]> = new EventEmitter();
  @Output() customButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() didPressCreate: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  destroy$: Subject<boolean> = new Subject<boolean>();
  columnsToDisplay: string[];
  dataSource: MatTableDataSource<ITableData<ActionType>>;
  selection = new SelectionModel<ITableData['data']>(true, []);
  lastClicked: any;
  currentFilter: FilterTableData = new FilterTableData();

  timeUtils = PPBTimeUtil;

  availableRoles$: Observable<RoleAction[]>;

  resolvedElActions: {
    id: string;
    actions: ITableAction<ActionType>[];
  }[] = [];

  constructor(
    private roleApi: RoleApiService,
    private dialogService: DialogService
  ) {
    this.availableRoles$ = this.roleApi.availableActions$.pipe(
      RXJSUtils.filterUndefined()
    );
  }

  ngOnInit(): void {
    if (this.showUpdatedAt && !this.displayedColumns.includes('updated_at')) {
      this.displayedColumns.push('updated_at');
    }
    if (this.actions && !this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
    this.columnsToDisplay = this.displayedColumns
      ? this.displayedColumns.slice()
      : null;

    if (this.inputData) {
      this.dataSource = new MatTableDataSource(
        this.inputData.map((m) => {
          if (m.data.data) {
            this.displayedColumns.forEach((s) => {
              if (Object.keys(m.data.data).includes(s)) {
                m.data[s] = m.data.data[s];
              }
            });
          }
          return m.data;
        })
      );
    } else {
      this.dataSource = new MatTableDataSource();
    }

    this.dataSource.filterPredicate = (data: ITableData<any>) => {
      return (
        this.currentFilter.keywordFilter(data) &&
        this.currentFilter.sliderFilter(data) &&
        this.currentFilter.textFilter(data)
      );
    };

    //Trigger filter at initialization
    this.dataSource.filter = 'trigger_filter';

    this.selection =
      this.existingSelectionModel ??
      new SelectionModel(this.selectionAmount === 'SELECT_MULTIPLE', []);

    if (this.preSelectedRows) {
      for (let pre_select_row of this.preSelectedRows) {
        for (let row of this.inputData) {
          if (pre_select_row?.id === row.data.id) {
            this.selection.select(row.data);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preSelectedRows) {
      this.preSelectedRows = changes.preSelectedRows.currentValue;
    }

    if (changes.inputData && changes.inputData.currentValue) {
      const newData = changes.inputData.currentValue;

      this.dataSource = new MatTableDataSource(
        newData?.map((m) => {
          if (m.data.data) {
            this.displayedColumns.forEach((s) => {
              if (Object.keys(m.data.data).includes(s)) {
                m.data[s] = m.data.data[s];
              }
            });
          }
          return m.data;
        })
      );
      this.paginateContent();
    }

    if (changes.displayedColumns) {
      if (!changes.displayedColumns.firstChange) {
        this.displayedColumns = changes.displayedColumns.currentValue;

        this.dataSource = new MatTableDataSource(
          this.inputData.map((m) => {
            if (m.data.data) {
              this.displayedColumns.forEach((s) => {
                if (Object.keys(m.data.data).includes(s)) {
                  m.data[s] = m.data.data[s];
                }
              });
            }
            return m.data;
          })
        );
      }
    }

    // Updates actions on row updates.
    changes.inputData?.currentValue?.forEach((current, index) => {
      const previousState = changes.inputData?.previousValue?.length
        ? changes.inputData.previousValue[index]
        : null;
      if (
        previousState &&
        JSON.stringify(current) !== JSON.stringify(previousState)
      ) {
        this.resolvedElActions.find(
          (row) => row?.id === previousState.data?.id
        ).actions = current.actions;
      }
    });

    if (!this.dataSource) {
      return;
    }
    this.dataSource.filterPredicate = (data: ITableData<any>) => {
      return (
        this.currentFilter.keywordFilter(data) &&
        this.currentFilter.sliderFilter(data) &&
        this.currentFilter.textFilter(data)
      );
    };
    if (changes.filter) {
      this.selection.selected.forEach((selectedRow) => {
        if (
          !this.dataSource.filteredData
            .map((data) => data['id'])
            .includes(selectedRow.id)
        )
          this.selection.toggle(selectedRow);
      });
    }
  }

  ngAfterViewInit() {
    this.paginateContent();
    this.selection.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) =>
        this.blockSelectedChange.emit(s.source.selected as any[])
      );

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, property) => {
      const value =
        data[property] instanceof Object && data[property].name
          ? data[property].name
          : data[property];
      if (typeof value === 'string') {
        const asFloat = parseFloat(value);
        return isNaN(asFloat) ||
          property === 'updated_at' ||
          property === 'created_at'
          ? value.toLocaleLowerCase()
          : asFloat;
      } else if (typeof value === 'function') {
        return value();
      } else {
        return value;
      }
    };
  }

  selectRow(row: ITableData['data']) {
    if (!this.disableBlockSelection) {
      this.selection.toggle(row);
    } else {
      this.selection.clear();
      this.selection.toggle(row);
    }
  }

  getCellStyling(element) {
    return this.inputData.find((e) => {
      return e.data.id === element.id;
    })?.getCellClass;
  }

  overrideTitle(columnId: string): ITableColumnOverrideTitle {
    return this.overrideColumnTitles.find((col) => columnId === col.columnId);
  }

  private paginateContent(): void {
    if (this.showPaginator) {
      setTimeout(() => {
        this.paginator.pageSize = 10;
        this.dataSource.paginator = this.paginator;

        this.dataSource.sort = this.sort;
      }, 10);
    }
  }

  setSorting(column: string, direction: SortDirection) {
    this.sort.active = column;
    this.sort.direction = direction;
    this.sort.sortChange.emit({ active: column, direction: direction });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  doBlockAction(action: ITableAction) {
    this.selection.selected.forEach((selectedRow) => {
      this.actionClicked.emit({
        actionId: action.actionId,
        element: selectedRow,
      });
    });
  }

  getActions(
    element: any,
    menuOnly: boolean = false
  ): Observable<ITableAction<ActionType>[]> {
    if (this.resolvedElActions.map((m) => m.id).includes(element.id)) {
      return of(
        this.resolvedElActions.find((f) => f.id === element.id).actions
      );
    } else {
      return this.availableRoles$.pipe(
        map((roles) => {
          let actions = this.inputData.find(
            (f) => f.data?.id === element.id
          ).actions;

          actions = actions?.filter((action) =>
            isCommandAvailable([action.roleAction, roles])
          );

          if (menuOnly) {
            actions = actions?.filter((a) => a.label !== 'favourite');
          }

          this.resolvedElActions.push({
            id: element.id,
            actions: actions,
          });

          return actions;
        })
      );
    }
  }

  canShowActionMenuButtonWithActions(
    actions: ITableAction<ActionType>[]
  ): boolean {
    let numItems = actions?.length ?? 0;

    actions?.forEach((action) => {
      if (action.actionId === 'favourite') {
        numItems -= 1;
      }
    });

    return numItems > 0;
  }

  getInfoPopup(element: any) {
    return this.inputData.find((f) => f.data === element)?.infoPopup;
  }

  openInfoPopup(element) {
    const data = this.getInfoPopup(element);
    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      data,
      true
    );
  }

  getBgColor(element: any) {
    return this.inputData.find((f) => f.data?.id === element.id)?.data?.bgColor;
  }

  applyDateFilter(dates: { start: string; end: string }) {
    const parsedStart = Date.parse(dates.start);
    const parsedEnd = Date.parse(dates.end);

    if (!this.dataSource.filter) {
      this.dataSource.data = this.inputData.filter((f) => {
        const parsedDate = Date.parse(f.data.createdAt);
        return parsedDate > parsedStart && parsedDate < parsedEnd;
      });
    } else {
      this.dataSource.filteredData = this.dataSource.filteredData.filter(
        (f) => {
          const parsedDate = Date.parse(f.data.createdAt);
          return parsedDate > parsedStart && parsedDate < parsedEnd;
        }
      );
    }
  }

  applyStatusFilter(statuses: SimulationStatus[]) {
    if (!this.dataSource.filter) {
      this.dataSource.data = this.inputData.filter((f) =>
        statuses.includes(f.data.status)
      );
    } else {
      this.dataSource.filteredData = this.dataSource.filteredData.filter((f) =>
        statuses.includes(f.data.status)
      );
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.dataSource.filter = null;
    }
  }

  rowClick(column, element: ITableData['data']) {
    if (
      column !== 'select' &&
      column !== 'actions' &&
      column !== 'simulations' &&
      column !== 'projects'
    ) {
      this.lastClicked = element;
      this.rowClicked.emit(element);
    }
  }

  typeOf(something: any): string {
    return typeof something;
  }

  getHeaderAction(columnId: string): ITableHeaderAction {
    return this.headerActions.find((action) => action?.columnId == columnId);
  }

  getElementData(element, tableElements: any): ITableData<any> {
    return tableElements.find((e) => {
      return e.data.id === element.id;
    });
  }

  elementHasInfoOrError(
    element: any,
    column: string,
    tableElements: any
  ): boolean {
    const elementData = this.getElementData(element, tableElements);
    if (elementData === undefined) {
      return false;
    }

    if (elementData.info?.[column] || elementData.error?.[column]) {
      return true;
    }

    return false;
  }
}
