import { ApiOrganization } from './ApiOrganization';
import { ApiRobotConfiguration } from './ApiRobotConfiguration';

export interface IInstalledRobotLicenceInfo {
  id: string;
  name?: string;
  organization: {
    id: ApiOrganization['id'];
    name: ApiOrganization['name'];
  };
  robot_serial_number?: string;
}

export interface IApiInstalledRobot {
  id: string;
  organization: ApiOrganization;
  name?: string;
  robot_configuration?: ApiRobotConfiguration;

  mac_address?: string;
  robot_serial_number?: string;

  installed_robot_sw_licenses?: {
    id?: string;
    robot_serial_number?: string;
    license_valid_to?: string;
  }[];

  license_id?: string;
  backup_zip_id?: string;

  created_at?: string;
  updated_at?: number;
}

export class ApiInstalledRobot implements IApiInstalledRobot {
  id: string;
  organization: ApiOrganization;
  name?: string;
  robot_configuration?: ApiRobotConfiguration;

  mac_address?: string;
  robot_serial_number?: string;

  license_id?: string;
  backup_zip_id?: string;

  created_at?: string;
  updated_at?: number;

  constructor(installation: IApiInstalledRobot) {
    for (const d in installation) {
      if (installation.hasOwnProperty(d)) {
        this[d] = installation[d];
      }
    }
  }
}
