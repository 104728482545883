import { IPalletSummary } from '../types/pallet-summary';

export class PalletSummary implements IPalletSummary {
  boxCount: number;
  palletHeight: number;
  palletLoadHeight: number;
  palletWeight: number;
  numberOfLayers: number;
  palletAreaEfficiency: number;
  palletCubeEfficiency: number;
  palletCenterOfMass: {
    x: number;
    y: number;
    z: number;
  };

  constructor(summary: IPalletSummary) {
    for (const s in summary) {
      if (summary.hasOwnProperty(s)) {
        this[s] = summary[s];
      }
    }
  }
}
