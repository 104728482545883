import { Injector } from '@angular/core';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { ThreeHandler } from '../three-handler';
import { URDFRobot } from '@rocketfarm/urdf-loader';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { takeUntil, take } from 'rxjs/operators';
import { AssetIDs } from 'src/app/models_new/enums/asset-ids';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { defaultRobotPose } from 'src/app/models_new/config/default-robot-pose';
import { ProjectRobotStructureHandler } from '../../simconf/new-project-robot-structure-handler';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { ProjectRobotDescriptorService } from 'src/app/services/project-robot-descriptor.service';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { Project } from '../../project';
import { ProcessingOverlayService } from '../../../../services/processing-overlay.service';

/**
 * @deprecated Use RobotConfigHandler instead.
 */
export class SimConfigViewHandler extends ThreeHandler {
  private simConfigService: SimConfigService;
  private poService: ProcessingOverlayService;

  robotModel: URDFRobot;
  public robot: ProjectRobotDescriptorService;
  private robotHandler: ProjectRobotStructureHandler;
  private robotModelReady$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  shouldResizeLoop: boolean;
  resizeFrameID: any;

  progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(ID: string, protected injector: Injector, project?: Project) {
    super(ID, injector, project);

    this.robot = injector.get(ProjectRobotDescriptorService);
    this.simConfigService = injector.get(SimConfigService);
    this.poService = injector.get(ProcessingOverlayService);

    combineLatest([this.progress$, AssetStoreService.loadingProgress$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([tasks, assetstore]) => {
        this.poService.setLoadProgress(((tasks + assetstore) / 200) * 100);
      });

    AssetStoreService.onAssetLoadedWithID<URDFRobot>(
      AssetIDs.SimconfigViewRobot
    )
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((model) => {
        this.robotModel = model;

        (this.robotModel as THREE.Object3D).setRotationFromEuler(
          new THREE.Euler((-90 * Math.PI) / 180, 0, 0)
        );

        this.robot.mapRobotStructure(this.robotModel);

        console.debug(this.robot);

        // Set to default pose
        for (const jointname of Object.keys(defaultRobotPose)) {
          URDFUtils.setJointAngle(
            this.robotModel.joints[jointname],
            MathUtils.degToRad(defaultRobotPose[jointname])
          );
        }

        this.robotModelReady$.next(true);
      });
  }

  public init(): void {
    this.inputHandler.setDefaultCameraPostion();
    this.inputHandler.resetCameraTarget();
    this.inputHandler.setMaxCameraDistance(5);
    this.inputHandler.updateControls();

    this.robotModelReady$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe((_: boolean) => {
        this.scene.add(this.robotModel);
        this.render();

        this.robotHandler = new ProjectRobotStructureHandler(
          this.ID,
          this.injector,
          this.destroy$
        );
        this.robotHandler.progress$
          .pipe(takeUntil(this.destroy$))
          .subscribe(this.progress$);
      });
  }

  public afterViewInit(): void {
    this.setupNavResizeHandlers();
  }

  addLighting(): void {
    const directionalIntensity = 0.35;
    const ambientIntensity = 0.2;
    const spotLightIntensity = 0.45;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity);
    this.scene.add(ambient);

    const directional = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional.position.set(4, 0, 4);
    directional.lookAt(new THREE.Vector3());
    this.scene.add(directional);
    const directional2 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional2.position.set(-4, 0, 4);
    directional2.lookAt(new THREE.Vector3());
    this.scene.add(directional2);
    const directional3 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional3.position.set(4, 0, -4);
    directional3.lookAt(new THREE.Vector3());
    this.scene.add(directional3);
    const directional4 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional4.position.set(-4, 0, -4);
    directional4.lookAt(new THREE.Vector3());
    this.scene.add(directional4);

    const spotLight = new THREE.SpotLight('#ffffff');
    spotLight.position.set(0, 10, 0);
    spotLight.angle = Math.PI / 4.5;
    spotLight.intensity = spotLightIntensity / 2;
    spotLight.penumbra = 0.5;
    this.scene.add(spotLight);
    const spotLight2 = new THREE.SpotLight('#ffffff');
    spotLight2.position.set(0, 5, 0);
    spotLight2.angle = Math.PI / 7;
    spotLight2.intensity = spotLightIntensity / 2;
    spotLight2.penumbra = 0.5;
    this.scene.add(spotLight2);
    const spotLight3 = new THREE.SpotLight('#ffffff');
    spotLight3.position.set(4, 5, 4);
    spotLight3.angle = Math.PI / 6;
    spotLight3.intensity = spotLightIntensity / 3;
    spotLight3.decay = 1;
    spotLight3.penumbra = 0.5;
    this.scene.add(spotLight3);

    const spotLightLogo = new THREE.SpotLight('#ffffff');
    spotLightLogo.position.set(0, 5, -2);
    spotLightLogo.target.position.set(0, 0, -5);
    spotLightLogo.target.updateWorldMatrix(false, false);
    spotLightLogo.angle = Math.PI / 10;
    spotLightLogo.intensity = spotLightIntensity / 3;
    spotLightLogo.decay = 1;
    spotLightLogo.penumbra = 0.5;
    this.scene.add(spotLightLogo);
  }

  setupNavResizeHandlers(): void {
    merge(
      this.simConfigService.navItemOpenedStart$,
      this.simConfigService.navItemClosedStart$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.shouldResizeLoop = true;
        this.resizeLoop();
      });

    this.simConfigService.navItemOpenedChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_: boolean) => {
        this.shouldResizeLoop = false;
        cancelAnimationFrame(this.resizeFrameID);
        this.resize();
      });
  }

  resizeLoop(): void {
    if (this.shouldResizeLoop) {
      this.resizeFrameID = requestAnimationFrame(this.resizeLoop.bind(this));
    }

    this.resize();
  }
}
