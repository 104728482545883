import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  /**
   * The message shown under the loading animation
   */
  @Input()
  message: string | undefined | null;

  /**
   * Sets the size of the loading animation icon
   */
  @Input()
  size: number = 100;
  /**
   * Sets the color of the loading animation icon to white
   */
  @Input()
  whiteColor?: boolean;

  defaultMessage = ' ';
}
