import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { Field } from 'src/app/models_new/classes/field';
import { FieldType } from 'src/app/models_new/types/field-type';
import { FileUtils } from 'src/app/utils/file-utils';

@Component({
  selector: 'app-customer-edit-url-prompt',
  templateUrl: './customer-edit-url-prompt.component.html',
  styleUrls: ['./customer-edit-url-prompt.component.scss'],
})
export class CustomerEditUrlPromptComponent implements OnInit, OnDestroy {
  field = new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required],
    null,
    null,
    null,
    { label: 'URL', name: 'url', hint: 'Ex: github.com' },
    'url'
  );
  state: 'search' | 'save' = 'search';
  logo$: Observable<DataRequestState<string>>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<CustomerEditUrlPromptComponent>) {}

  ngOnInit(): void {
    this.field.formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => (this.state = 'search'),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  enterHit(event: KeyboardEvent) {
    if (event.code === 'Enter' && !event.repeat) {
      this.findLogo();
    }
  }

  findLogo() {
    const domain = this.field.formControl.value;
    if (domain) {
      this.logo$ = FileUtils.urlToBase64(
        `https://logo.clearbit.com/${domain}?size=200`
      ).pipe(toRequestState());
      this.state = 'save';
    }
  }

  selectLogo() {
    this.logo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((logo: DataRequestState<string>) => {
        if (logo.value) {
          this.dialogRef.close(logo.value);
        }
      });
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
