<div class="pallet-info">
  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Box Count"
  >
    <mat-icon svgIcon="boxes"></mat-icon>
    <span>{{ patternInfo.boxCount }}</span>
  </button>

  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Pallet Height (Pallet Load Height + {{
      patternInfo.palletHeight
    }} mm)"
  >
    <mat-icon>height</mat-icon>
    <span>{{ patternInfo.palletHeight }}</span>
  </button>

  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Pallet Weight"
  >
    <mat-icon svgIcon="pallet_weight"></mat-icon>
    <span>{{ patternInfo.palletWeight }}</span>
  </button>

  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Total Number of Layers"
  >
    <mat-icon>layers</mat-icon>
    <span>{{ patternInfo.numberOfLayers }}</span>
  </button>

  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Pallet Cube Efficiency"
  >
    <mat-icon svgIcon="pallet_cube"></mat-icon>
    <span>{{ (patternInfo.palletCubeEfficiency | number: '1.0-2') || 0 }}</span>
  </button>

  <button
    class="pallet_info_item"
    disableRipple="true"
    mat-button
    matTooltip="Center of Mass Height"
  >
    <mat-icon>center_focus_strong</mat-icon>
    <span>{{ patternInfo.palletCenterOfMass }}</span>
  </button>
</div>
