<div class="container">
  <h1>{{ title }}</h1>

  <div class="vstack">
    <div *ngFor="let f of matFields">
      <app-field [field]="f"></app-field>
    </div>
  </div>

  <div class="bottom">
    <button mat-button class="bottomButton" (click)="closeWithChanges()">
      Save
    </button>
    <button mat-button class="bottomButton cancel" (click)="close()">
      Cancel
    </button>
  </div>
</div>
