<div
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  class="landing-step-item-wrapper"
  [ngClass]="{
    'low-height': lowHeigth,
    'justify-content': cardState !== 'explore'
  }"
>
  <ng-container *ngIf="dataReady$ | async as dataReady">
    <!-- Loading state -->
    <app-loading *ngIf="dataReady.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="dataReady.error as error" [error]="error"></app-error>

    <ng-container *ngIf="dataReady.didSucceed && dataReady.value">
      <div class="page-header">
        <ng-container *ngIf="cardState === 'explore'; else userInfo">
          <ng-container *ngIf="exploreContent$ | async as content">
            <h1
              class="page-index-title"
              [ngClass]="{ 'low-height': lowHeigth }"
            >
              {{ content.cardTitle }}
            </h1>
            <div
              class="sub-title-container"
              [ngClass]="{ 'slide-in-blurred': cardState === 'explore' }"
            >
              <div
                [innerHtml]="
                  content?.cardLongText.json | richtextDocumentToHtml
                "
              ></div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #userInfo>
          <h1
            *appVar="openSimService.pagesSortedList$ | async as pagesSortedList"
            class="page-index-title"
            [ngClass]="{ 'low-height': lowHeigth }"
          >
            {{
              pagesSortedList[openSimService.selectedPageIndex].content
                .cardTitle
            }}
          </h1>
        </ng-template>
      </div>
      <div
        class="landing-user-info-cards-wrapper"
        [ngClass]="{ 'low-height': lowHeigth }"
      >
        <ng-container *ngIf="cardState === 'fields'">
          <mat-card
            appearance="outlined"
            class="landing-user-info-cards-left-card"
            [ngStyle]="
              lowHeigth ? { height: 'auto', 'justify-content': 'start' } : {}
            "
          >
            <mat-card-content>
              <app-field
                *ngFor="let field of dataReady.value"
                [field]="field"
              ></app-field>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="cardState === 'loading'">
          <app-loading class="loading-card-state"></app-loading>
        </ng-container>

        <ng-container *ngIf="simulations$ | async as simulations">
          <div class="landing-user-info-cards-right-card">
            <div
              class="progress"
              *ngIf="
                cardState === 'explore' && overalProgress$
                  | async as batchProgress
              "
            >
              <ng-container *ngFor="let stage of batchProgress">
                <div class="simulation-state" [matTooltip]="stage.tooltip">
                  {{ stage.key?.replaceAll('_', ' ') }} &nbsp;
                  <app-radio-check
                    class="radio-check-comp"
                    [isLoading]="stage.value !== 0 && !stage.isComplete"
                    [isChecked]="stage.isComplete && !generalError"
                  ></app-radio-check>
                </div>
              </ng-container>
            </div>
            <mat-card appearance="outlined">
              <!-- Error state -->
              <app-error
                *ngIf="simulations.error as error"
                [error]="error"
                [hideActions]="true"
              ></app-error>
              <ng-container *ngIf="simulations.didSucceed && simulations.value">
                <ng-container
                  [ngTemplateOutlet]="
                    cardState === 'fields'
                      ? simulationsTableReady
                      : simulationsTableRunning
                  "
                >
                </ng-container>
              </ng-container>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #simulationsTableReady>
  <mat-card-content>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="tableDataSource"
        matSort
        matSortActive="name"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Solution name</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.externalUrl; else nameOnly">
              <div
                class="link-like-container"
                (click)="navigateTo(element.externalUrl)"
                [matTooltip]="element.externalUrl"
              >
                {{ element.name }} &nbsp;
                <mat-icon> open_in_new</mat-icon>
              </div>
            </ng-container>
            <ng-template #nameOnly> {{ element.name }} </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="starting_price">
          <th mat-header-cell *matHeaderCellDef>Starting price</th>
          <td mat-cell *matCellDef="let element" style="text-align: left">
            {{ element.startingPrice }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <ng-container
      *appVar="openSimService.pagesSortedList$ | async as pagesSortedList"
    >
      <button
        mat-raised-button
        color="accent"
        style="padding: 0.5em 2em; width: 100%; margin: 1em 0"
        (click)="checkCaptchaAndSubmit()"
        data-testId="fastTrackStartSimulations"
        [disabled]="
          !pagesSortedList[this.openSimService.selectedPageIndex].valid
        "
      >
        {{
          pagesSortedList[this.openSimService.selectedPageIndex].content
            .callToActionButton1 || 'START SIMULATIONS'
        }}
      </button>
    </ng-container>
  </mat-card-content>
</ng-template>

<ng-template #simulationsTableRunning>
  <mat-card-content>
    <div class="table-container running-sims">
      <table
        mat-table
        [dataSource]="tableDataSource"
        matSort
        matSortActive="name"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Solution name</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.externalUrl; else nameOnly">
              <div
                class="link-like-container"
                (click)="navigateTo(element.externalUrl)"
                [matTooltip]="element.externalUrl"
              >
                {{ element.name }} &nbsp;
                <mat-icon> open_in_new</mat-icon>
              </div>
            </ng-container>
            <ng-template #nameOnly> {{ element.name }} </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="progress">
          <th mat-header-cell *matHeaderCellDef>Progress</th>
          <td mat-cell *matCellDef="let element">
            {{ element.progress }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.status | removeLodash }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cpm">
          <th mat-header-cell *matHeaderCellDef>CPM</th>
          <td mat-cell *matCellDef="let element">
            {{ element.cpm }}
          </td>
        </ng-container>

        <ng-container matColumnDef="eta">
          <th mat-header-cell *matHeaderCellDef>ETA</th>
          <td mat-cell *matCellDef="let element">
            {{ element.eta }}
          </td>
        </ng-container>

        <ng-container matColumnDef="starting_price">
          <th mat-header-cell *matHeaderCellDef>Starting price</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startingPrice }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="text-align: right">
            <button
              mat-raised-button
              color="accent"
              (click)="goToReport(element.id)"
            >
              View report
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <ng-container *ngIf="exampleLink$ | async as exampleLink">
      <a [href]="exampleLink" target="_blank">
        <button
          mat-stroked-button
          color="accent"
          style="padding: 0.5em 2em; width: 100%; margin: 1em 0"
        >
          SEE EXAMPLE RESULTS
        </button>
      </a>
    </ng-container>
  </mat-card-content>
</ng-template>
