export enum PartType {
  NONE = 'none',
  PALLET = 'pallet',
  PALLET_LIP = 'pallet_lip',
  CONVEYOR = 'conveyor',
  CONVEYOR_GUIDE = 'conveyor_guide',
  FRAME = 'frame',
  LIFTKIT = 'liftkit',
  BASE_BRACKET = 'base_bracket',
  ROBOT = 'robot',
  OFFSET_BRACKET = 'offset_bracket',
  GRIPPER = 'gripper',
  SCENE = 'scene',
}
