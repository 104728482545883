import { IPallet } from 'src/app/models_new/types/pallet';
import { StackingMethod } from '../../enums/stacking-method';

export const defaultPallet: IPallet = {
  id: '1',
  name: 'No name',
  position: null,
  layers: [],
  overhangSides: 0,
  overhangEnds: 0,
  dimensions: {
    length: 1200,
    width: 800,
    palletHeight: 144,
    loadHeight: 1000,
    totalHeight: 144 + 1000,
    maxLoadHeight: 1000,
  },
  autoStack: {
    method: StackingMethod.ROTATE,
    baseIndex: 0,
  },
  preserveLayers: false,
};
