import { UpdateAction } from '../enums/update-action';

const blacklist = [
  UpdateAction.GENERATING_PALLET,
  UpdateAction.SET_STACKING_METHOD,
  UpdateAction.SET_BASE_INDEX,
];

export class UpdateActionsConfig {
  static ignoreAction(action: UpdateAction): boolean {
    return blacklist.includes(action);
  }
}
