export enum FieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  PW = 'password',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CAPTCHA = 'captcha',
  SELECT_SINGLE = 'select',
  MINI_SELECT_SINGLE = 'mini_select',
  SELECT_SINGLE_OBJECT = 'select_single_object',
  SELECT_MULTIPLE = 'select_multiple',
  SELECT_MULTIPLE_OBJECT = 'select_multiple_object',
  SLIDETOGGLE = 'slideToggle',
  ACCORDION = 'accordion',
  DRAG_AND_DROP_TARGET = 'drop_target',
  HR = 'hr',
  SELECT_SINGLE_OBJECT_COMPARE = 'select_single_object_compare',
  DATE = 'date',
  AUTOCOMPLETE = 'autocomplete',
  AUTOCOMPLETE_MULTIPLE = 'autocomplete_multiple',
  TIME = 'time',
}
