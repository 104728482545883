import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { set } from 'lodash-es';
import { Field } from 'src/app/models_new/classes/field';
import { FieldType } from 'src/app/models_new/types/field-type';

interface IEditField {
  [key: string]: {
    title: string;
    value: any;
    type: FieldType;
    options?: string[] | { [key: string]: string };
  };
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  matFields: Field[];
  fields: IEditField;
  changes: object;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      title: string;
      fields: IEditField;
    }
  ) {}

  ngOnInit(): void {
    this.fields = this.input.fields;
    this.title = this.input.title;

    const fieldKeys = Object.keys(this.fields);
    this.matFields = fieldKeys.map((fieldKey) => {
      const field = this.fields[fieldKey];

      let defaultVal = field.value;
      let options = this.options(fieldKey) as unknown;

      if (options && typeof options === 'object')
        defaultVal = options[defaultVal];

      if (options !== null && typeof options === 'object')
        options = Object.values(options);

      return this.createField(
        field.title,
        defaultVal,
        fieldKey,
        false,
        field.type,
        options as string[]
      );
    });
  }

  createField(
    label: string,
    defaultVal: string,
    id?: string,
    required: boolean = false,
    type: FieldType = FieldType.TEXT,
    options: string[] = []
  ): Field {
    return new Field(
      type,
      required,
      defaultVal,
      [],
      null,
      type === FieldType.SELECT_SINGLE ? options : null,
      null,
      { label: label, name: label.toLowerCase() },
      id ? id : label.toLowerCase()
    );
  }

  options(id) {
    return this.fields[id].options;
  }

  getOptionKeyFromValue(options: object, value: string): string {
    const index = Object.values(options).indexOf(value);
    if (index === -1) return undefined;
    return Object.keys(options)[index];
  }

  close() {
    this.dialogRef.close(null);
  }

  closeWithChanges() {
    const delta = {};
    for (const field of this.matFields) {
      let val = field.getValue();

      if (val === null || val.toString() === '') val = undefined;

      if (
        field.type === FieldType.SELECT_SINGLE &&
        typeof this.options(field.id) === 'object'
      ) {
        val = this.getOptionKeyFromValue(
          this.options(field.id),
          val.toString()
        );
      }

      set(delta, field.id, val);
    }
    this.dialogRef.close(delta);
  }
}
