import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';

export enum StorageMethod {
  /** SessionStorage's data is cleared when the page/tab session ends. Data is not transfered between sessions, except when tab is duplicated. */
  SESSION = 'sessionStorage',
  /** LocalStorage's data is kept along all same browser-profile's sessions. */
  LOCAL = 'localStorage',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  version: string = environment.version;

  constructor() {}

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   * Defaults data-storage method to SessionStorage if none is specified.
   * {@link https://javascript.info/localstorage Learn more}
   * @param key LocalStorageKey
   * @param value any (Json)
   * @param method Optional: enum StorageMethod
   */
  public setData(
    key: LocalStorageKey,
    value: any,
    method?: StorageMethod
  ): void {
    switch (method) {
      case StorageMethod.LOCAL:
        localStorage.setItem(key, JSON.stringify(value, this.replacer));
        break;
      case StorageMethod.SESSION:
      default:
        sessionStorage.setItem(key, JSON.stringify(value, this.replacer));
        break;
    }
  }

  /**
   * Returns the current object value associated with the given key, or null if the given key does not exist.
   * Will return SessionStorage's content first, and fallback to LocalStorage if none.
   * @param key LocalStorageKey
   * @returns String
   */
  public getData(key: string): any {
    const global = JSON.parse(localStorage.getItem(key), this.reviver);
    const session = JSON.parse(sessionStorage.getItem(key), this.reviver);
    return session ?? global;
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   * Defaults data-storage method to SessionStorage if none is specified.
   * {@link https://javascript.info/localstorage Learn more}
   * @param key LocalStorageKey
   * @param value
   * @param method Optional: enum StorageMethod
   */
  public setString(
    key: LocalStorageKey,
    value: any,
    method?: StorageMethod
  ): void {
    switch (method) {
      case StorageMethod.LOCAL:
        localStorage.setItem(key, JSON.stringify(value, this.replacer));
        break;
      case StorageMethod.SESSION:
      default:
        sessionStorage.setItem(key, JSON.stringify(value, this.replacer));
        break;
    }
  }

  /**
   * Returns the current string value associated with the given key, or null if the given key does not exist.
   * Will return SessionStorage's content first, and fallback to LocalStorage if none.
   * @param key LocalStorageKey
   * @returns String
   */
  public getString(key: LocalStorageKey): string | number {
    const value = localStorage.getItem(key);
    return value ?? null;
  }

  /**
   * Removes the key/value pair with the given key -if a key/value pair with the given key exists- on either of the storage methods.
   * @param key LocalStorageKey
   */
  public removeData(key: LocalStorageKey): void {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  /** Removes all key/value pairs -if there are any- for both storage methods. */
  public resetAllData(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * Alters the behavior of stringification, or an array of strings and numbers that specifies properties of value to be included in the output.
   * If replacer is anything other than a function or an array (e.g. null or not provided), all string-keyed properties of the object are included in the resulting JSON string.
   * @param key
   * @param value
   * @returns
   */
  private replacer(key: string, value: any): any {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    } else {
      return value;
    }
  }

  /**
   * Prescribes how each value originally produced by parsing is transformed before being returned.
   *  Non-callable values are ignored.
   * @param key
   * @param value
   * @returns
   */
  private reviver(key: string, value: any): any {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }
}
