<div style="padding: 2em">
  <h3>Edit {{ hardware.label }}</h3>
  <ng-container *appVar="orgShareField$ | async as orgField">
    <div class="field-wrapper">
      <!-- Element metadata & currency-->
      <span class="sub-section-title">General details:</span>
      <ng-container *ngFor="let field of metadataFields">
        <app-field
          class="field"
          [field]="field"
          [ngClass]="field.tabGroupId"
        ></app-field>
      </ng-container>

      <!-- Share to other organizations -->
      <ng-container *ngIf="orgField">
        <app-field
          class="field"
          [ngClass]="orgField.tabGroupId"
          [field]="orgField"
          [id]="orgField.id"
        ></app-field>
      </ng-container>

      <!-- Advanced settings -->
      <ng-container *ngIf="advancedFields.length">
        <mat-expansion-panel class="advanced-field-expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> Advanced Settings </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="advanced-field-wrapper">
            <app-field
              *ngFor="let field of advancedFields"
              class="advanced-field"
              [ngClass]="field.tabGroupId"
              [field]="field"
              [id]="field.id"
            >
            </app-field>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </div>

    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button
      style="float: right; margin-left: 1em"
      color="primary"
      mat-flat-button
      (click)="saveAndClose(orgField)"
    >
      <mat-icon>save</mat-icon>
      Save changes
    </button>
  </ng-container>
</div>
