import { BehaviorSubject } from 'rxjs';
import { AltLayout } from '../enums/alt-layout';
import { PalletPosition } from '../enums/pallet-position';
import { StackingMethod } from '../enums/stacking-method';
import { UpdateAction } from '../enums/update-action';
import { IPalletAdvancedSettings } from '../types/pallet-advanced-settings';
import { IProjectUpdate } from '../types/project-update';

export class PalletAdvancedSettings implements IPalletAdvancedSettings {
  altLayout: AltLayout;
  activeViewPallets: PalletPosition[];
  stackingMethod: StackingMethod;

  update$ = new BehaviorSubject<IProjectUpdate>(null);

  constructor(settings: IPalletAdvancedSettings, _origin: string) {
    for (const s in settings) {
      if (settings.hasOwnProperty(s)) {
        this[s] = settings[s];
      }
    }
  }

  update(updateAction: UpdateAction, extra: string) {
    this.update$.next({
      save: true,
      label: [extra],
      updateAction: updateAction,
    });
  }

  /**
   * @returns AltLayout
   */
  getAltLayout(): AltLayout {
    return this.altLayout;
  }

  altLayoutIsCustom(): boolean {
    return this.getAltLayout() === AltLayout.CUSTOM ? true : false;
  }

  /**
   * @returns AltLayout
   */
  getStackingMethod(): StackingMethod {
    return this.stackingMethod;
  }

  /**
   * @param active: PalletPosition[]
   */
  setActiveViewPallets(active: PalletPosition[]): void {
    this.activeViewPallets = active;
  }

  /**
   * @param altLayout: AltLayout
   */
  setAltLayout(altLayout: AltLayout, updateProject: boolean): void {
    this.altLayout = altLayout;
    if (updateProject) {
      this.update(UpdateAction.SET_ALT_LAYOUT, altLayout);
    }
  }
}
