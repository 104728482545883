import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataRequestState } from '../../../../../data-request/model';

@Component({
  selector: 'app-three-state',
  templateUrl: './three-state.component.html',
  styleUrls: ['./three-state.component.scss'],
})
export class ThreeStateComponent {
  @Input() state: DataRequestState<boolean>;
  @Input() contextLost: boolean;
  @Output() clickedRestore$ = new EventEmitter<boolean>();
}
