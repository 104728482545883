import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';

export const defaultApiOpenSimulation: IApiOpenSimulation = {
  country: null,
  email: null,
  organization_name: null,
  pattern_id: null,
  phase_of_research: null,
  firstName: null,
  lastName: null,
  fullName: null,
  continent: null,
};
