<ng-container *ngIf="organization$ | async as organization">
  <!-- Loading state -->
  <app-loading *ngIf="organization.isLoading || loading"></app-loading>

  <!-- Error state -->
  <app-error *ngIf="organization.error as error" [error]="error"></app-error>

  <!-- Empty state -->
  <app-empty
    *ngIf="organization.didSucceed && organization === undefined"
  ></app-empty>

  <ng-container *ngIf="organization.didSucceed && !loading">
    <app-customer-edit-card
      *appIfCommandAvailable="'update_organization'"
      [organization]="organization.value"
      [shouldOnlyShowGuiForChangingLogo]="shouldOnlyShowGuiForChangingLogo"
      (savedForm)="updateOrganization(organization.value, $event)"
    ></app-customer-edit-card>
  </ng-container>
</ng-container>
