<ng-container *appVar="appLayout.lowHeight$ | async as lowHeigth">
  <app-split-card [title]="title">
    <div split-card-content>
      <div class="card-fields-wrapper">
        <ng-container *ngFor="let conf of modeFieldConfigs">
          <app-field
            *ngIf="conf.field && conf.visible() && !conf.infoPopup"
            [ngClass]="lowHeigth ? 'low-height' : ''"
            [id]="conf.field.id"
            [field]="conf.field"
          ></app-field>
          <app-field
            *ngIf="conf.field && conf.visible() && conf.infoPopup"
            [id]="conf.field.id"
            [ngClass]="lowHeigth ? 'low-height' : ''"
            [field]="conf.field"
            (openInfoPopup)="openInfoPopup(conf.infoPopup)"
          ></app-field>
        </ng-container>
      </div>
      <div class="actions">
        <p *ngIf="missingContentText">{{ missingContentText }}</p>
        <!-- Options mode create button-->
        <app-button
          class="button"
          *ngIf="mode === 'options'"
          [disabled]="missingContentText ? true : false"
          [type]="'save'"
          [label]="createLabel"
          [icon]="'add'"
          (buttonClick)="option('create')"
        >
        </app-button>

        <!-- Options mode pick button-->
        <ng-container *appIfCommandAvailable="'pick_product_from_inventory'">
          <app-button
            class="button"
            *ngIf="mode === 'options'"
            [disabled]="missingContentText ? true : false"
            [type]="'clear'"
            [label]="pickLabel"
            [icon]="'search'"
            (buttonClick)="option('pick')"
          >
          </app-button>
        </ng-container>

        <!-- Create + Edit mode save button-->
        <ng-container *appIfCommandAvailable="'save_product'">
          <app-button
            class="button"
            *ngIf="
              mode === 'create' ||
              mode === 'pick' ||
              (mode === 'edit' && !isPublic)
            "
            [type]="'save'"
            [label]="saveLabel"
            [icon]="'edit'"
            (buttonClick)="save()"
          >
          </app-button>
        </ng-container>

        <!-- Create + Edit mode save button-->
        <!-- <ng-container *appIfCommandAvailable="'save_product'">
          <app-button
            class="button"
            *ngIf="
              mode === 'create' ||
              mode === 'pick' ||
              (mode === 'edit' && !isPublic)
            "
            [type]="'save'"
            [label]="saveAndCreatePatternLabel"
            [icon]="'add'"
            (buttonClick)="saveAndCreatePattern()"
          >
          </app-button>
        </ng-container> -->

        <!-- Cancel button-->
        <app-button
          class="button"
          *ngIf="
            mode === 'create' ||
            mode === 'pick' ||
            (mode === 'edit' && enableCancel)
          "
          [type]="'clear'"
          [label]="'CANCEL'"
          (buttonClick)="cancel$.emit()"
        >
        </app-button>
      </div>
    </div>
    <div split-card-artwork>
      <app-new-product-view
        class="product-view"
        [length]="product?.data?.length"
        [width]="product?.data?.width"
        [height]="product?.data?.height"
        [sticker]="(logoService.eitherLabelOrLogo$ | async)?.texture"
        [labelOrientations]="[product?.data?.label_orientation]"
        [showSide]="showSide$ | async"
        [hideNav]="false"
      ></app-new-product-view>
    </div>
  </app-split-card>
</ng-container>
