import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThreePerspective } from 'src/app/models_new/enums/three-perspective';
import { ThreePerspectiveService } from 'src/app/services/3dview/three-perspective.service';

@Component({
  selector: 'app-three-perspective',
  templateUrl: './three-perspective.component.html',
  styleUrls: ['./three-perspective.component.scss'],
})
export class ThreePerspectiveComponent {
  perspectives = ThreePerspective;

  @Input() perspective: ThreePerspective;
  @Output() selectedPerspective: EventEmitter<ThreePerspective> =
    new EventEmitter();

  constructor(private threePerspective: ThreePerspectiveService) {}

  selectPerspective(perspective: ThreePerspective) {
    this.perspective = perspective;
    this.selectedPerspective.emit(this.perspective);
    this.threePerspective.perspective$.next(perspective);
  }
}
