import { IApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { ApiOrganizationMember } from 'src/app/models_new/classes/api-models/ApiOrganizationMember';
import { ApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { ApiSimulation } from 'src/app/models_new/classes/api-models/ApiSimulation';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { ApiUser } from 'src/app/models_new/classes/api-models/ApiUser';
import { OrganizationSubscriptionType } from 'src/app/models_new/enums/organization-subscription-type';
import { OrganizationType } from 'src/app/models_new/enums/organization-type';
import { defaultApiOrganizationMember } from './default-api-organization-member';
import { defaultApiUser } from './default-api-user';

export const defaultApiOrganization: IApiOrganization = {
  id: '001',
  creator_id: new ApiUser(defaultApiUser).id,
  name: 'Default organization',
  type: OrganizationType.CUSTOMER_ORGANIZATION,
  location: 'Sandane, Norway',
  updated_at: new Date().getTime(),
  created_at: new Date().getTime(),
  subscription: OrganizationSubscriptionType.BASIC,
  subscription_details: null,
  organization_members: [
    new ApiOrganizationMember(defaultApiOrganizationMember),
  ],
  patterns: [],
  products: [],
  palletizing_projects: [],
  production_lines: [],
  scenes: [new ApiScene(null)],
  simulations: [new ApiSimulation(null)],
  strategies: [new ApiStrategy(null)],
};
