<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>{{ title$ | async }}</h1>
  </app-header>

  <app-hardware-configuration-card
    [hardwareID]="hardwareID"
    (hardwareIDChange)="hardwareIDChange($event)"
    *ngIf="hardwareID"
  ></app-hardware-configuration-card>
</app-page>
