<div
  class="company-logo-wrapper"
  (mouseenter)="isHovering = true"
  (mouseleave)="isHovering = false"
>
  <ng-container *ngIf="logo$ | async as logo">
    <!-- Loading state -->
    <ng-container *ngIf="logo.isLoading">
      <div [style.width]="'50px'" [style.background]="'white'"></div>
    </ng-container>

    <!-- Error state -->
    <ng-container *ngIf="logo.error as error">
      <img [src]="fallbackAsset | trust: 'resourceUrl'" />
    </ng-container>

    <!-- Normal state with fallback-asset -->
    <ng-container *ngIf="logo.didSucceed && !logo.value">
      <img [src]="fallbackAsset | trust: 'resourceUrl'" />
    </ng-container>

    <!-- Normal state with organization-asset -->
    <ng-container *ngIf="logo.didSucceed && logo.value">
      <img [src]="logo.value | trust: 'resourceUrl'" />

      <ng-container *appIfCommandAvailable="'update_organization'">
        <div
          *ngIf="canChooseNewLogo"
          [style.opacity]="isHovering ? '1' : '0'"
          class="choose-new-image-popover"
          (click)="didClickChangeLogo()"
        >
          <mat-icon>image</mat-icon>
          <p>Change<br />logo</p>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
