import { UnitSystemPipe } from '../pipes/unit-system.pipe';
import { LocalStorageService } from '../services/local-storage.service';

export type UnitSystemType = 'metric' | 'imperial';
export type ImperialUnit =
  | 'ft'
  | 'ft/min'
  | 'ft²'
  | 'in'
  | 'in/s'
  | 'in/s²'
  | 'oz'
  | 'lb';
export type MetricUnit =
  | 'm'
  | 'm/s'
  | 'm²'
  | 'cm'
  | 'mm'
  | 'mm/s'
  | 'mm/s²'
  | 'g'
  | 'kg';

export const completeUnitRegex = /^-?(0|[0-9]\d*|-*(0|[1-9]\d*)\.\d+)$/i;
export const validUnitRegex =
  /(\d+(\.\d+)?)\s*((\[)?(m²|m\/s|m|cm|mm|mm\/s|mm\/s²|g|kg|ft²|ft\/min|ft|in|in\/s|in\/s²|oz|lb)(\])?)(\b|\s.*|$)|\bmm\b/i;

const mappedUnits = {
  // Imperial => Metric
  ft: 'm',
  'ft/min': 'm/s',
  'ft²': 'm²',
  in: 'mm',
  'in/s': 'mm/s',
  'in/s²': 'mm/s²',
  oz: 'g',
  lb: 'kg',
  // Metric => Imperial
  m: 'ft',
  'm/s': 'ft/min',
  'm²': 'ft²',
  cm: 'in',
  mm: 'in',
  'mm/s': 'in/s',
  'mm/s²': 'in/s²',
  g: 'oz',
  kg: 'lb',
};

const mappedMultipliers = {
  // Imperial => Metric
  ft: 0.3048,
  'ft/min': 0.00508,
  'ft²': 0.092903,
  in: 25.4,
  'in/s': 25.4,
  'in/s²': 25.4,
  oz: 28.3495,
  lb: 0.453592,
  // Metric => Imperial
  m: 3.28084,
  'm/s': 196.85,
  'm²': 10.7639,
  mm: 0.0393701,
  cm: 0.393701,
  'mm/s': 0.0393701,
  'mm/s²': 0.0393701,
  g: 0.035274,
  kg: 2.20462,
};

export const metricUnits = [
  'm',
  'm/s',
  'm²',
  'cm',
  'mm',
  'mm/s',
  'mm/s²',
  'g',
  'kg',
];
export const imperialUnits = [
  'ft',
  'ft/min',
  'ft²',
  'in',
  'in/s',
  'in/s²',
  'oz',
  'lb',
];

/**
 * Expects a value and its original unit in an stringified form (ex: '69 kg' or '69 [kg]')
 * Returns the original input if none-convertible values are inputted, or the converted value and unit with the same stringified input format.
 * @param inputValue : string. If composed of number + ' ' + 'm' | 'm²' | 'mm' | 'g' | 'kg' | 'in' | 'oz' | 'lb' will be able to convert units.
 * @param unitSystem (Optional) : Will convert to this unit. Default is 'metric'.
 * @param fractional (Optional) : Will convert decimals into a single fractional character.
 * @returns {string} number + ' ' + 'm' | 'm²' | 'mm' | 'g' | 'kg' | 'in' | 'oz' | 'lb'
 */
export function getConvertedValue(
  inputValue: string,
  unitSystem: UnitSystemType = 'metric',
  fractional?: boolean
): string {
  let split = inputValue.split(' ');
  if (split.length === 2) {
    const suffix = split[1].replace('[', '').replace(']', '');
    if (
      (metricUnits.includes(suffix) && unitSystem === 'imperial') ||
      (imperialUnits.includes(suffix) && unitSystem === 'metric')
    ) {
      return getInvertedValue(inputValue, fractional);
    }
  }
  return inputValue;
}

/**
 * Expects a value and its unit as separate inputs.
 * Returns the original input if none-convertible values are inputted, or the converted value and unit separately.
 * @param value number.
 * @param unitType string || ImperialUnit | MetricUnit types.
 * @param fractional (Optional) : Will convert decimals into a single fractional character.
 * @returns { value: number; unit: string }
 */
export function getConvertedValueAndUnit(
  value: number,
  unitType: ImperialUnit | MetricUnit,
  unitSystem: UnitSystemType,
  fractional?: boolean
): { value: number; unit: string } {
  if (
    (metricUnits.includes(unitType) && unitSystem === 'imperial') ||
    (imperialUnits.includes(unitType) && unitSystem === 'metric')
  ) {
    const convertedValue = getInvertedValue(
      `${value} ${unitType}`,
      fractional
    ).split(' ');
    return {
      value: Number(convertedValue[0]),
      unit: convertedValue[1],
    };
  } else {
    return { value: Number(value), unit: unitType };
  }
}

/**
 * @desc Handles unit conversions where the original unit forms part of a larger string (if any), like a label.
 * @param {string} label: A label-like string where the measurement sits at the final part of the string. Like "Piab Palletizing Gripper 400 mm"
 * @returns {string} Label-like string with replaced original units to its conversion. Example: "Piab Palletizing Gripper 15¾ in"
 */
export function getConvertedLabelValue(
  label: string,
  unitSystem?: UnitSystemType,
  fractional: boolean = true
) {
  const matches = label?.match(validUnitRegex);
  if (matches && matches[0]) {
    return label.replace(
      matches[0],
      new UnitSystemPipe(new LocalStorageService()).transform(
        matches[0],
        null,
        unitSystem,
        fractional
      ) as string
    );
  }
  return label;
}

/**
 * Expecting a value and its original unit. Will convert to imperial units if metric, and vice versa.
 * If none-convertible values are inputted, return original.
 * @param {string} inputValue: If composed of number + ' ' + 'm' | 'm²' | 'mm' | 'g' | 'kg' | 'in' | 'oz' | 'lb' will be able to convert units.
 * @param fractional (Optional): Will convert decimals into a single fractional character.
 */
function getInvertedValue(inputValue: string, fractional?: boolean): string {
  const inputValueAndUnit: any[] = [
    Number(inputValue.split(' ')[0]),
    inputValue.split(' ')[1].replace('[', '').replace(']', ''),
  ];
  const bracketFormat = /.*\[.*].*/.test(inputValue);
  if (typeof inputValueAndUnit[0] === 'number') {
    let unit = mappedUnits[inputValueAndUnit[1]];
    unit = bracketFormat ? `[${unit}]` : unit;
    const convertedVal =
      inputValueAndUnit[0] * mappedMultipliers[inputValueAndUnit[1]];
    return isNaN(+convertedVal)
      ? 'N/A'
      : `${roundToEighth(convertedVal, fractional)} ${unit}`;
  }
  return inputValue;
}

/**
 * Rounding for eights, because of imperial...
 * @param value
 * @param useFractional Return decimal as fractional expression
 * @returns {number} rounded to eighths
 */
function roundToEighth(value: number, useFractional?: boolean): string {
  const inv = 1.0 / 0.125;
  return useFractional
    ? getFractionalDecimal(Math.round(value * inv) / inv)
    : `${Math.round((Math.round(value * inv) / inv) * 100) / 100}`;
}

/**
 * Fractional expression for eighths.
 * @param {number} value decimal value.
 * @returns {string} ascii fractional character for eighths.
 */
export function getFractionalDecimal(
  value: number,
  fractionalOnly?: boolean,
  allowZero?: boolean
): string {
  const fractionMap = {
    '0': '0',
    '12': '⅛',
    '125': '⅛',
    '13': '⅛',
    '25': '¼',
    '37': '⅜',
    '375': '⅜',
    '38': '⅜',
    '5': '½',
    '62': '⅝',
    '625': '⅝',
    '63': '⅝',
    '75': '¾',
    '87': '⅞',
    '875': '⅞',
    '88': '⅞',
  };
  if (value === 0 && !allowZero) return `${value}`;
  const inputValue = `${value}`.split('.');
  const fraction = fractionMap[value === 0 ? '0' : inputValue[1]];
  return fraction
    ? fractionalOnly
      ? fraction
      : inputValue[0] + fraction
    : `${value}`;
}

/**
 * Inversed fractional expression conversion from eighths to decimal equivalent.
 * @param {string} ascii fractional character for quarters and eighths.
 * @returns {number} decimal equivalent of fractional expression.
 */
export function getDecimalFromFraction(fraction: string): number {
  const fractionMap = {
    '0': 0,
    '⅟': 0, // Original 'whole-unit' fraction keept for retrocompatibility.
    '⅛': 0.125,
    '¼': 0.25,
    '⅜': 0.375,
    '½': 0.5,
    '⅝': 0.625,
    '¾': 0.75,
    '⅞': 0.875,
  };
  return fractionMap[fraction] || 0;
}

/**
 * Checks if the inputted unit type is handled by MRC.
 * @param {any} inputUnitType
 * @returns {boolean}
 */
export function isValidUnitType(
  inputUnitType: any
): inputUnitType is ImperialUnit | MetricUnit {
  return [...metricUnits, ...imperialUnits].includes(inputUnitType);
}
