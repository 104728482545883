<mat-menu #childMenu="matMenu">
  <div style="display: flex; flex-direction: column">
    <span *ngFor="let child of items">
      <span
        *ngIf="
          child.children &&
          child.children.length > 0 &&
          child.actionId !== 'favourite'
        "
      >
        <button
          mat-menu-item
          color="primary"
          [disabled]="child.disabled"
          [matMenuTriggerFor]="menu.childMenu"
          [style.color]="child.disabled ? undefined : child.txtColor"
          (click)="clickedAction(child)"
        >
          <ng-container *ngIf="child.customIcon">
            <mat-icon [svgIcon]="child.customIcon"></mat-icon>
          </ng-container>
          <ng-container *ngIf="!child.customIcon">
            <mat-icon
              [style.color]="child.disabled ? undefined : child.txtColor"
            >
              {{ child.icon }}
            </mat-icon>
          </ng-container>

          <span [style.color]="child.txtColor">{{ child.label }}</span>
        </button>
        <app-sub-menu
          #menu
          [items]="child.children"
          (actionEmit)="actionEmit.emit($event)"
        ></app-sub-menu>
      </span>

      <span
        *ngIf="
          (!child.children || child.children.length === 0) &&
          child.actionId !== 'favourite'
        "
      >
        <button
          mat-menu-item
          [style.color]="child.disabled ? undefined : child.txtColor"
          (click)="clickedAction(child)"
          [disabled]="child.disabled"
        >
          <mat-icon
            *ngIf="child.icon"
            [style.color]="child.disabled ? undefined : child.txtColor"
            >{{ child.icon }}</mat-icon
          >
          <span>{{ child.label }}</span>
        </button>
      </span>
    </span>
  </div>
</mat-menu>
