export enum ThreeHandlerMode {
  Default = 'defaultmode',

  PalletReport = 'palletreport',
  Project = 'project',
  SimConfig = 'simconfig',

  PalletView = 'palletview',
  RobotConfig = 'robotconfig',
  LogoView = 'logoview',

  ProductionLineView = 'productionlineview',
}
