import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { StandardPallet } from '../models_new/types/standard-pallet';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  palletType = new ReplaySubject<StandardPallet>(1);
  disableRCPicker$ = new BehaviorSubject<boolean>(true);

  constructor() {}
}
