<div class="container">
  <img [src]="publicApi.mrcLogoBlue" alt="MyRobot.cloud" />
  <div style="padding: 0em 2em">
    <h2 style="margin-bottom: 3em">{{ input.data.title }}</h2>
    <p *ngIf="input.data.message" class="message">
      {{ input.data.message }}
    </p>
  </div>

  <button
    mat-raised-button
    pally-button-l
    color="primary"
    class="back_button"
    (click)="navigateBack()"
  >
    Select organization <mat-icon>chevron_right</mat-icon>
  </button>
</div>
