import { Component } from '@angular/core';

/**
 * A wrapper component for pages.
 */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {}
