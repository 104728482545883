import { PartType } from '../models_new/enums/sim-config-part-type';

export class HwPartUtils {
  public static getPartAssetID(type: PartType, name: string): string {
    // Other scenes except pally are unsupported for now
    if (type === PartType.SCENE && name !== 'PALLY') {
      return undefined;
    }

    return type + '_' + name;
  }
}
