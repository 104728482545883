import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { RobotConfigApiService } from 'src/app/services/api/robot-config-api.service';
import { ExportImportService } from 'src/app/services/export-import.service';
import { ITableAction, ITableData } from '../../../gui/table/table.component';
import { FileUtils } from 'src/app/utils/file-utils';
import { NotificationService } from 'src/app/services/notification.service';
import { toRequestState } from 'src/app/data-request/operators';
import { RoleApiService } from 'src/app/services/api/role-api.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { DataRequestState } from 'src/app/data-request/model';
import { StateService } from '../../../../auth/state.service';
import { IApiOrganization } from '../../../../models_new/classes/api-models/ApiOrganization';
import { ExpandableTableConverter } from '../../../../models_new/classes/expandable-table-converter';
import { RobotActions } from '../../../gui/card-robot-config/card-robot-config.component';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { ObjectUtils } from 'src/app/utils/object';
import { HardwareApiService } from '../../../../services/api/hardware-api.service';
import { HardwareUtils } from '../../../../utils/hardware-utils';
import { getConvertedLabelValue } from 'src/app/utils/unit-utils';

@Component({
  selector: 'app-inventory-robot-configs',
  templateUrl: './inventory-robot-configs.component.html',
  styleUrls: ['./inventory-robot-configs.component.scss'],
})
export class InventoryRobotConfigsComponent implements OnInit {
  pagePath = pagesPATH;
  displayedColumns: string[] = [
    'select',
    'name',
    'owner',
    'Hardware configuration',
    'Software configuration',
    'updated_at',
    'buttons',
  ];
  robotConfigs$: Observable<DataRequestState<ITableData<RobotActions>[]>>;
  availableActions: string[];
  blockSelected: ApiRobotConfiguration[] = [];

  sortBy$: BehaviorSubject<string> = new BehaviorSubject<string>('name');

  sortingTypes: string[] = ['name', 'updated_at', 'created_at'];
  objUtil = ObjectUtils;
  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  cardActions: ITableAction<RobotActions>[] = [
    {
      label: 'Hardware configuration',
      actionId: 'hardware_configuration',
      roleAction: 'view_hardware',
    },
    {
      label: 'Software configuration',
      actionId: 'software_configuration',
      roleAction: 'view_software',
    },
    {
      label: 'Modify',
      actionId: 'modify',
      icon: 'edit',
      roleAction: 'modify_robot_configuration',
    },
    {
      label: 'Delete',
      actionId: 'delete',
      icon: 'delete',
      roleAction: 'delete_robot_configuration',
    },
  ];

  setupFilter: ISortingOption[] = [
    {
      id: 'owner_organization.name',
      label: 'Owner',
      children: [],
    },
    {
      id: 'scene.name',
      label: 'Hardware Configuration',
      children: [],
    },
    {
      id: 'strategy.name',
      label: 'Software Configuration',
      children: [],
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'owner',
      label: 'Owner',
    },
    {
      id: 'Hardware configuration',
      label: 'Hardware Configuration',
    },
    {
      id: 'Software configuration',
      label: 'Software Configuration',
    },
  ];

  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private router: Router,
    private robotConfigApi: RobotConfigApiService,
    private exportImportService: ExportImportService,
    private notificationService: NotificationService,
    private roleApi: RoleApiService,
    private stateService: StateService,
    private hardwareApi: HardwareApiService
  ) {}

  ngOnInit() {
    this.robotConfigs$ = this.stateService
      .getCustomerOrSalesOrganizationPreserveState()
      .pipe(
        take(1),
        switchMap((organization: IApiOrganization) =>
          combineLatest([
            this.robotConfigApi.fetchRobotconfigs(organization.id),
            this.robotConfigApi.fetchSalesOrgRobotConfigurations(
              organization.id
            ),
            this.roleApi.availableActions$,
            this.sortBy$,
            this.hardwareApi.fetchHwTypes(),
          ])
        ),
        map(([rcs, sales_rcs, availableActions, orderBy, hw_types]) => {
          return [
            rcs.concat(sales_rcs),
            availableActions,
            orderBy,
            hw_types,
          ] as const;
        }),
        tap((values) => {
          // Populate result filter
          for (let rc of values[0]) {
            // Loops over configs
            for (let option of this.setupFilter) {
              const id = ObjectUtils.getNested(rc, option.id);
              const child: ISortingOption = {
                id: id,
                label: id,
              };
              // Add option child if it doesn't exist in option children
              if (option.children.filter((o) => o.id === id).length === 0)
                option.children.push(child);
            }
          }
        }),
        takeUntil(this.destroy$),
        map(([configs, availableActions, orderBy, hw_types]) => {
          this.availableActions = availableActions;

          // Replace scene hw type with it's label.
          configs = configs.map((config) => {
            HardwareUtils.labelifyScene(config.scene.data, hw_types);
            return config;
          });

          try {
            return this.convertToExpandableContent(configs, orderBy);
          } catch (e) {
            console.error(e);
            return [];
          }
        }),
        toRequestState(),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  routeToEdit(rcId?: string) {
    this.router.navigate([
      pagesPATH.INVENTORY,
      pagesPATH.ROBOT_CONFIGURATIONS,
      rcId ? rcId : 'new',
    ]);
  }

  convertToExpandableContent(
    robots: ApiRobotConfiguration[],
    orderBy: string
  ): ITableData<RobotActions>[] {
    return new ExpandableTableConverter<RobotActions>()
      .data(robots)
      .orderBy(orderBy)
      .addColumn('name', '$.name')
      .addColumn('owner', '$.owner_organization.name')
      .addColumn('updated_at', '$.updated_at')
      .addCategory('Hardware configuration', 'hw', '$.scene.name')
      .addCategory('Software configuration', 'sw', '$.strategy.name')

      .addExpandableRow('Gripper', '$.scene.data.robot.gripper.label', 'hw')
      .addExpandableRow('Base', '$.scene.data.robot.frame.label', 'hw')
      .addExpandableRow('Conveyor', '$.scene.data.conveyors.0.label', 'hw')
      .addExpandableRow(
        'Lift column',
        '$.scene.data.robot.lift_kit.label}',
        'hw'
      )
      .addExpandableRow(
        'Height extender',
        (cfg) =>
          `${getConvertedLabelValue(
            cfg.scene.data.robot.lift_kit.max_stroke + ' m',
            null,
            false
          )}`,
        'hw'
      )
      .addExpandableRow('Robot', '$.scene.data.robot.label', 'hw')
      .addExpandableRow(
        'Pally version',
        '$.strategy.data.software.pally_version',
        'sw'
      )
      .addExpandableRow(
        'Polyscope version',
        '$.strategy.data.software.polyscope_version',
        'sw'
      )

      .rowActions([
        {
          label: 'Hardware configuration',
          actionId: 'hardware_configuration',
          roleAction: 'view_hardware',
          link_id: '$.scene.id',
          actionParam: 'a',
        },
        {
          label: 'Software configuration',
          actionId: 'software_configuration',
          roleAction: 'view_software',
          link_id: '$.strategy.id',
          actionParam: 'a',
        },
        {
          label: 'hr',
          actionId: 'divider',
        },
        {
          label: 'Modify',
          icon: 'edit',
          roleAction: 'modify_robot_configuration',
          actionId: 'modify',
        },
        {
          label: 'Delete',
          icon: 'delete',
          roleAction: 'delete_robot_configuration',
          actionId: 'delete',
          txtColor: '#AF2B2B',
        },
      ])
      .rowButtons([
        {
          enabled: this.availableActions.includes('export_robot_configuration'),
          actionId: 'export',
          icon: 'get_app',
        },
        {
          enabled: this.availableActions.includes('clone_robot_configuration'),
          actionId: 'clone',
          icon: 'content_copy',
        },
      ])
      .graphics('$.scene.image.url')
      .convert();
  }

  actionClicked(action: ITableAction<RobotActions>) {
    if (action.actionId === 'export') {
      this.robotConfigs$
        .pipe(
          map((rc: DataRequestState<ITableData<RobotActions>[]>) => {
            return rc.value;
          }),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((rcs: ITableData<RobotActions>[]) => {
          const item = rcs.find((f) => f.data.id === action.element);
          const rc = item.origin;
          FileUtils.downloadJson(rc, rc.name);
        });
    } else if (action.actionId === 'hardware_configuration') {
      this.router.navigate(
        [
          pagesPATH.INVENTORY,
          pagesPATH.HARDWARE_CONFIGURATIONS,
          typeof action.element === 'string'
            ? action.element
            : action.element.scene.id,
        ],
        {
          queryParams: { edit: true },
        }
      );
    } else if (action.actionId === 'software_configuration') {
      this.router.navigate(
        [
          pagesPATH.INVENTORY,
          pagesPATH.SOFTWARE_LIST,
          typeof action.element === 'string'
            ? action.element
            : action.element.strategy.id,
        ],
        {
          queryParams: { edit: true },
        }
      );
    } else if (action.actionId === 'modify') {
      this.routeToEdit(action.element.id ? action.element.id : action.element);
    } else if (action.actionId === 'delete') {
      this.robotConfigs$
        .pipe(
          skipWhile((rcs) => !rcs.value),
          take(1),
          map((m) => {
            const rc = m.value.find(
              (f) => f.data.id === (action.element?.id ?? action.element)
            );
            return rc.data.data;
          })
        )
        .subscribe((rcs) => {
          this.onDelete([rcs]);
        });
    } else if (action.actionId === 'clone') {
      this.robotConfigApi
        .fetchRobotconfigByID(
          typeof action.element === 'string'
            ? action.element
            : action.element.id
        )
        .pipe(
          take(1),
          switchMap((rc: ApiRobotConfiguration) => {
            return this.robotConfigApi.insertRobotConfig(
              rc.name + ' (copy)',
              rc.organization_id,
              rc.scene.id,
              rc.strategy.id
            );
          }),
          take(1)
        )
        .subscribe((result) => {
          if (result === null) {
            this.notificationService.showError(
              'Clone failed. Are you the owner of this item, and do you have the correct role in your organization?'
            );
          } else {
            this.notificationService.showSuccess(
              'The robot configuration was cloned successfully'
            );
          }
        });
    }
  }

  // The elements here are actually IExpandableTableItem[]...
  onDeleteBlock(elements: any[]) {
    this.onDelete(elements.map((m) => m.data));
  }

  onDelete(elements: ApiRobotConfiguration[]) {
    this.notificationService
      .deletePrompt(
        'Delete',
        'robot configuration',
        elements.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const operations = elements
            .map((m) => m.id)
            .map((id) => this.robotConfigApi.deleteRobotConfig(id));
          return forkJoin(operations);
        })
      )
      .subscribe((result) => {
        if (result === null) {
          this.notificationService.showError(
            'Delete failed. Are you the owner of this item, and do you have the correct role in your organization?'
          );
        } else {
          this.notificationService.showMessage(
            `The robot configuration was deleted successfully`
          );
        }
      });
  }

  public onFileChanged(event): void {
    var selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = () => {
      this.importRobotConfiguration(fileReader.result);
    };
  }

  private importRobotConfiguration(inputFile): void {
    const robotConfig = new ApiRobotConfiguration(JSON.parse(inputFile));
    this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(
        take(1),
        switchMap((org: IApiOrganization) =>
          this.exportImportService.importRobotConfigurationFile(
            robotConfig,
            org.id
          )
        )
      )
      .subscribe(() => {
        this.notificationService.showSuccess(
          'Configuration imported successfully'
        );
      });
  }
}
