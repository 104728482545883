import { Entry } from './entry';

export class Vector3 {
  x: number;
  y: number;
  z: number;

  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  set(index: number, value: number) {
    switch (index) {
      case 0:
        this.x = value;
        break;
      case 1:
        this.y = value;
        break;
      default:
        this.z = value;
    }
  }

  setVector3(v: Vector3) {
    this.x = v.x;
    this.y = v.y;
    this.z = v.z;
  }

  entries(): Entry[] {
    return [
      new Entry('x', this.x),
      new Entry('y', this.y),
      new Entry('z', this.z),
    ];
  }
}
