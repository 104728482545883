import * as THREE from 'three';
import { MathUtils } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export class TargetedCameraHelper {
  private controls: OrbitControls;
  private camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;

  targets: THREE.Object3D[] = [];
  bb = new THREE.Box3();

  padding = 1;

  center: THREE.Vector3;
  direction: THREE.Vector3;
  offset = 0;

  speed = 1;

  skipAnimation = false;

  maxOffset = 30;
  minOffset = 1;

  constructor(
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
    controls: OrbitControls,
    direction: THREE.Vector3 = new THREE.Vector3(0, 0, -1),
    center: THREE.Vector3 = new THREE.Vector3()
  ) {
    this.camera = camera;
    this.controls = controls;

    this.center = center;
    this.direction = direction.normalize();
  }

  update(dt: number): void {
    this.updateBB();

    if ('isPerspectiveCamera' in this.camera) {
      this.runPerspective(dt);
    } else if ('isOrthographicCamera' in this.camera) {
      this.runOrthograpic(dt);
    }
  }

  runPerspective(dt: number): void {
    const size = new THREE.Vector3();
    this.bb.getSize(size);
    // Current camera position before view check.
    const currentOffset = this.offset;

    // Calculate target offset to keep objects in view.
    this.offset = this.clampOffset(
      (Math.max(size.x, size.y, size.z) + this.padding) /
        2 /
        Math.tan((Math.PI * (this.camera as THREE.PerspectiveCamera).fov) / 360)
    );

    const pos = new THREE.Vector3()
      .copy(this.direction)
      .multiplyScalar(
        this.skipAnimation
          ? this.offset
          : MathUtils.lerp(currentOffset, this.offset, dt * this.speed)
      )
      .add(this.center);

    this.camera.position.copy(pos);
    this.controls.target.copy(this.center);
    this.controls.update();
  }

  /**
   * Using orthographic projections, there doesn't exsist depth.
   * So instead of moving the camera we zoom.
   * @param dt { number } - Nr of seconds since last frame.
   */
  runOrthograpic(dt: number): void {
    // Calculate the size of the bounding box
    let size = new THREE.Vector3();
    this.bb.getSize(size);

    // calculate the width and height of the camera frustum
    let frustumHeight =
      (this.camera as THREE.OrthographicCamera).top -
      (this.camera as THREE.OrthographicCamera).bottom;
    let frustumWidth =
      (this.camera as THREE.OrthographicCamera).right -
      (this.camera as THREE.OrthographicCamera).left;

    // Scale the frustum to fit the model
    let scaleY = (size.y + this.padding) / frustumHeight;
    let scaleX = (size.x + this.padding) / frustumWidth;
    let scaleZ = (size.z + this.padding) / frustumWidth;
    let scale = Math.max(scaleX, scaleY, scaleZ);

    // Current camera zoom before view check.
    const currentOffset = this.offset;

    // Calculate target offset to keep objects in view.
    this.offset = this.clampOffset(1 / scale);

    // Set the camera's zoom level to fit the model.
    this.camera.zoom = this.skipAnimation // Skip animation.
      ? this.offset // Resolve the final position immideatly.
      : // Smooths the updating to make a nice transision (linear interpolation).
        MathUtils.lerp(currentOffset, this.offset, dt * this.speed);
    this.camera.updateProjectionMatrix();
    this.controls.update();
  }

  private clampOffset(offset?: number): number {
    if (offset) {
      return MathUtils.clamp(offset, this.minOffset, this.maxOffset);
    } else {
      return MathUtils.clamp(this.offset, this.minOffset, this.maxOffset);
    }
  }

  addTarget(target: THREE.Object3D): void {
    // Skip if already added
    if (this.targets.find((t) => t.id === target.id)) {
      return;
    }

    this.targets.push(target);
    this.updateBB();
  }

  removeTarget(target: THREE.Object3D): void {
    this.targets.splice(
      this.targets.findIndex((obj) => obj === target),
      1
    );
    this.updateBB();
  }

  private updateBB(): void {
    if (this.targets.length === 0) {
      return;
    }

    this.bb.makeEmpty();

    for (const target of this.targets) {
      target.updateMatrixWorld(true);
      this.bb.expandByObject(target);
    }
  }

  setBoundingBox(bb: THREE.Box3): void {
    this.bb = bb;
  }
}
