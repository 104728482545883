export enum UpdateAction {
  SET_LAYERS = 'List of layers changed',
  ADD_NEW = 'New layer - Added new layer',
  DUPLICATED_PREV = 'New layer - Duplicated top layer',
  ROTATED_PREV = 'New layer - Rotated top layer',
  MIRRORED_PREV_HORIZ = 'New layer - Morriored horizontal top layer',
  MIRRORED_PREV_VERT = 'New layer - Morriored vertical top layer',
  DUPLICATE = 'New layer - Duplicated by id',
  REORDER = 'Reorder layer position',
  REMOVE = 'Removed layer',
  EDIT_BOXES = 'Edited boxes',
  ACTIVE_PALLETS = 'Changed active pallets',
  SET_ALT_LAYOUT = 'Changed alt layout',
  SET_VIEW_NO_OF_LAYERS = 'Changed no of layers to view',
  SET_VIEW_SETTINGS = 'Changed 3d view settings',
  DEFAULT_BOX_UPDATE = 'Default box update',
  DEFAULT_LAYER_UPDATE = 'Default layer update',
  DEFAULT_PALLET_UPDATE = 'Default pallet update',
  GENERATING_PALLET = 'Generating pallet - in progress',
  GENERATED_PALLET = 'Generating pallet - finish',
  SET_STACKING_METHOD = 'Changed stacking method - autostack',
  SET_BASE_INDEX = 'Changed selected base pattern index - autostack',
  SET_PROJECT_NAME = 'Changed project name',
  SET_PROJECT_DESC = 'Changed project description',
  SET_LABEL_ORIENTATION = 'Changed label orientation',
  SET_MAX_GRIP = 'Changed max grip',
  SET_SHIMPAPER_HEIGHT = 'Changed shim paper height',
  SET_LOGO = 'Set project logo',
}
