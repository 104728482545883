import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { catchError, map, Observable, of, switchMap, take } from 'rxjs';
import { pagesPATH } from '../models_new/config/pages';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import {
  IVendorAndSolution,
  PublicApiService,
} from '../services/api/public-api.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class FastTrackResolver {
  private access$: Observable<boolean>;

  constructor(
    private notify: NotificationService,
    private router: Router,
    private publicApi: PublicApiService,
    private localStorageService: LocalStorageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const queryParamKeys = Object.keys(route.queryParams);

    if (route.queryParams && queryParamKeys.length > 0) {
      this.access$ = of(route.queryParams).pipe(
        // Check query param value
        switchMap((_) => {
          let queryParamValid$: Observable<boolean> = of(true);

          if (
            route.queryParamMap.get('vn') ||
            route.queryParamMap.get('vi') ||
            route.queryParamMap.get('sp') ||
            route.queryParamMap.get('si')
          ) {
            queryParamValid$ = this.publicApi
              .fetchVendorAndSolutions(
                route.queryParamMap.get('vn'),
                route.queryParamMap.get('vi'),
                route.queryParamMap.get('sp'),
                route.queryParamMap.get('si')
              )
              .pipe(
                take(1),
                map((vs: IVendorAndSolution) => {
                  if (
                    vs?.default_sim_config?.length ||
                    vs?.vendor_organizations?.length
                  ) {
                    return true;
                  } else {
                    const queryParamValues = [
                      { param: 'vn', label: 'vendor name' },
                      { param: 'vi', label: 'vendor identifier' },
                      { param: 'sp', label: 'solution provider identifier' },
                      { param: 'si', label: 'solution identifier' },
                    ];
                    const queryParamValue = queryParamValues.find(
                      (value) => route.queryParamMap.get(value.param) !== null
                    );

                    if (!queryParamValue) {
                      throw new Error('Query value is not a valid value');
                    }

                    const queryParamInput =
                      route.queryParamMap.get(queryParamValue.param) ?? '--';
                    const queryParamTypeLabel = queryParamValue.label;

                    throw new Error(
                      `“${queryParamInput}” is not a valid ${queryParamTypeLabel}.`
                    );
                  }
                })
              );
          }
          if (
            route.queryParamMap.get('utm_source') ||
            route.queryParamMap.get('utm_campaign') ||
            route.queryParamMap.get('utm_medium') ||
            route.queryParamMap.get('utm_term') ||
            route.queryParamMap.get('utm_content') ||
            route.queryParamMap.get('utm_id')
          ) {
            const utm = {
              utm_source: route.queryParamMap.get('utm_source'),
              utm_campaign: route.queryParamMap.get('utm_campaign'),
              utm_medium: route.queryParamMap.get('utm_medium'),
              utm_term: route.queryParamMap.get('utm_term'),
              utm_content: route.queryParamMap.get('utm_content'),
              utm_id: route.queryParamMap.get('utm_id'),
            };
            this.localStorageService.setData(LocalStorageKey.UTM, utm);
            queryParamValid$ = of(true);
          }

          return queryParamValid$;
        })
      );
    } else {
      this.access$ = of(true);
    }
    return this.access$.pipe(
      catchError((err) => {
        this.notify.showError(err);
        this.router.navigate([pagesPATH.FAST_TRACK], {
          queryParams: {},
          queryParamsHandling: 'merge',
        });
        return of(false);
      })
    );
  }
}
