import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import {
  delay,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { List } from '../../../../utils/list';
import { OrbitControlsComponent } from '../content/core/controls/orbit-controls/orbit-controls.component';
import { ThreeViewContent } from '../../../../models_new/classes/3dview/three-content';
import { TrackingScene } from '../../../../models_new/classes/3dview/tracking-scene';
import { Type } from '../../../../utils/type';
import { ThreeUtils } from '../../../../utils/three-utils';
import { settings } from '../../../../models_new/config/application-settings';
import { DataRequestState } from '../../../../data-request/model';
import { toRequestState } from '../../../../data-request/operators';
import {
  IRenderingOptions,
  RenderingService,
} from '../../../../services/3dview/rendering.service';

@Component({
  selector: 'app-new-three-view',
  templateUrl: './three-view.component.html',
  styleUrls: ['./three-view.component.scss'],
})
export class New_ThreeViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
  @Input() controls: OrbitControls;

  @Input() interactive = true;
  @Input() debug = false;

  shadowsEnabled = true;

  // If either of these are false, rendering happens on control changes and calls throughout the app.
  @Input() enableXR: boolean;
  @Input() loop: boolean;
  // Keeps track of the interval used for this view.
  frameID: number | undefined = undefined;

  @Output() screenshot$ = new EventEmitter<string>();

  /**
   * NOTE: Only for internal controls changes, subscript to "change$"
   * for any controls you add though templating!
   */
  @Output() controlsChange$ = new EventEmitter<void>();

  scene = new TrackingScene();

  rendererReady$: Observable<boolean>;

  // Canvas stuff...
  @ViewChild('canvas', { static: true })
  canvasRef: ElementRef<HTMLCanvasElement>;

  // Quality of life getters.
  get canvas(): HTMLCanvasElement {
    return this.canvasRef?.nativeElement;
  }
  get canvasSize(): THREE.Vector2 | undefined {
    if (this.canvasRef) {
      return new THREE.Vector2(
        this.canvas.parentElement.clientWidth,
        this.canvas.parentElement.clientHeight
      );
    }
    return undefined;
  }
  get canvasAspect(): number | undefined {
    if (this.canvasRef) {
      return (
        this.canvas.parentElement.clientWidth /
        this.canvas.parentElement.clientHeight
      );
    }
    return undefined;
  }
  private context2D: CanvasRenderingContext2D;
  private resizeObserver: ResizeObserver;

  // 3DView contents
  private contents = new List<ThreeViewContent>();
  loading$: Observable<DataRequestState<boolean>>;

  // Time keeping
  private prevMS: number | undefined = undefined;
  private dtStabilizationCount = 20;

  // Helper subject for telling when ngInit has run, to hold back pipelines or
  // Ensure camera and controls are ready before manipulating them.
  ngOnInit$ = new ReplaySubject<boolean>(1);

  destroy$ = new ReplaySubject<boolean>(1);
  destroyed = false;

  renderingOptions: IRenderingOptions;
  renderInfo: any = {};

  constructor(
    private ngZone: NgZone,
    public renderingService: RenderingService,
    public cdRef: ChangeDetectorRef
  ) {
    this.rendererReady$ = this.renderingService.rendererReady$.pipe(
      takeUntil(this.destroy$)
    );

    // On change of content components, update the loading observable
    this.loading$ = this.rendererReady$.pipe(
      switchMap(() => this.contents.change$),
      takeUntil(this.destroy$),
      delay(0), // Push to next update cycle so content constructors can finish
      startWith(true),
      switchMap(() => {
        if (this.contents.size() > 0) {
          return combineLatest(
            this.contents
              // Find content that can tell if view loads or not
              .filter((c: ThreeViewContent) => Boolean(c.loading$))
              .map((c) => c.loading$) as Observable<boolean>[]
          );
        } else {
          return of([]);
        }
      }),
      map((values: boolean[]) => {
        let l = false;
        values.forEach((c: boolean) => {
          l = l || c;
        });
        return l;
      }),
      // TODO: Kent => debounceTime() prevents report-screenshots from being taken. To look for a work-around/fix as agreed.
      // If things change very fast, limit events to avoid flickering.
      // debounceTime(50),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    // Render if rendering context was restored.
    this.rendererReady$
      .pipe(
        switchMap(() => this.renderingService.renderingContextRestored$),
        takeUntil(this.destroy$),
        filter(Boolean)
      )
      .subscribe(() => this.render());

    // NOTE! Angular fails for some reason to detect that the
    // renderingService.contextLost is true. Just giving it a slight push.
    this.rendererReady$
      .pipe(
        switchMap(() => this.renderingService.renderingContextLost$),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.cdRef.detectChanges());

    /*
      NOTE! Hacky fix: Helps in protecting against recapturing of
      memory due to rendering after destruction, see comment in render().
    */
    this.destroy$.subscribe((v) => (this.destroyed = v));
  }

  ngOnInit(): void {
    this.context2D = this.canvas.getContext('2d');

    // Detect when the parent element resizes
    this.resizeObserver = new ResizeObserver(() => {
      this.resize();
    });
    this.resizeObserver.observe(this.canvas);

    // Make default camera if not given
    if (!this.camera) {
      this.camera = this.makeDefaultCamera();
      this.camera.position.copy(settings.view3d.defaultCameraPosition);
      this.camera.lookAt(0, 0, 0);
    }

    // Make some default controls if not given.
    if (
      !this.controls &&
      !this.hasContentComponentOfType(OrbitControlsComponent)
    ) {
      this.controls = this.makeControls(this.camera);
    }
    this.ngOnInit$.next(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loop || changes.enableXR) {
      this.enableRenderingLoop(
        changes.loop?.currentValue || changes.enableXR?.currentValue
      );
    }

    // Dispose previous controls
    if (changes.controls?.previousValue) {
      changes.controls.previousValue.dispose();
    }
    if (changes.controls?.currentValue) {
      this.subscribeToControlChanges(changes.controls.currentValue);
    }

    if (changes.interactive && this.controls) {
      if (!this.interactive) {
        // Turn off all interations
        this.setInteractive(false, false, false);
      } else {
        // Enabled rotate, pan + zoom interations, keys need to be set manually
        this.setInteractive();
      }
    }

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.ngOnInit$.complete();

    if (this.controls) {
      this.controls.dispose();
    }

    this.scene.dispose();

    for (const content of this.contents) {
      this.unregisterContent(content);
    }
  }

  hasContentComponentOfType(type: Type<ThreeViewContent>): boolean {
    return Boolean(this.contents.find((c) => c instanceof type));
  }

  makeDefaultCamera(): THREE.PerspectiveCamera {
    return new THREE.PerspectiveCamera(
      70,
      this.canvasSize.x / this.canvasSize.y,
      0.1,
      2000
    );
  }

  makeControls(camera: THREE.Camera): OrbitControls {
    const controls = new OrbitControls(camera, this.canvas);
    this.subscribeToControlChanges(controls);
    controls.enableRotate = this.interactive;
    controls.enablePan = this.interactive;
    controls.enableZoom = this.interactive;
    controls.update();
    return controls;
  }

  subscribeToControlChanges(controls: OrbitControls) {
    controls.addEventListener('change', () => {
      // Don't render if I'm already looping.
      // Mitigates excessive drawing, which causes the view to stagger.
      if (!this.loop) {
        this.render();
      }
    });
  }

  /**
   * Enables different interactions
   * @param rotate - **[Optional]** enables rotation of the view with left click + mouse move. **Default: true**
   * @param pan - **[Optional]** enables moving the orbit point with right click + mouse move. **Default: true**
   * @param zoom - **[Optional]** enables scroll wheel move camera closer or further away. **Default: true**
   * @param keys - **[Optional]** enables rotation and moving the orbit point with the keyboard. **Default: false**
   */
  setInteractive(
    rotate: boolean = true,
    pan: boolean = true,
    zoom: boolean = true,
    keys: boolean = false
  ): void {
    this.controls.enableRotate = rotate;
    this.controls.enablePan = pan;
    this.controls.enableZoom = zoom;
  }

  updateControls(): void {
    if (this.controls) {
      this.controls.update();
    }
  }

  protected getDeltaTime(): number {
    // No previous frame, no time has passed, default to 0.
    if (this.prevMS === undefined) {
      this.prevMS = new Date().getTime();
      return 0;
    }

    // Get milliseconds since last frame
    const curr = new Date().getTime();
    const delta = (curr - this.prevMS) / 1000;
    this.prevMS = curr;
    return delta;
  }

  /**
   * Overridable function for extending views to receive updates.
   * Can be ignored if not extended or a looping view.
   * @param dt {number} - Time since last frame in seconds.
   */
  update(dt?: number | undefined): void {
    // Update all content
    for (const content of this.contents.sort(
      (a, b) => b.updateOrder - a.updateOrder
    )) {
      if (content.update) {
        content.update(dt);
      }
    }
  }

  enableRenderingLoop(shouldLoop: boolean = true) {
    // Once renderer is ready, set up rendering loop if enabled
    this.rendererReady$
      .pipe(takeUntil(this.destroy$), filter(Boolean), take(1))
      .subscribe(() => {
        this.loop = shouldLoop;
        if (this.loop) {
          this.renderLoop();
        } else {
          cancelAnimationFrame(this.frameID);
          this.prevMS = undefined;
          this.dtStabilizationCount = 20;
        }
      });
  }

  private renderLoop(): void {
    // Get time since last frame
    const dt = this.getDeltaTime();

    // Don't update in the beginning, letting the timer stabilize enough.
    if (this.dtStabilizationCount === 0) {
      // Update
      this.update(dt);

      // Let's go!
      this.render();
    }

    this.dtStabilizationCount = Math.max(this.dtStabilizationCount - 1, 0);

    // Call the next frame.
    this.frameID = requestAnimationFrame(this.renderLoop.bind(this));
  }

  render(camera?: THREE.Camera): void {
    const cam = camera ? camera : this.camera;
    if (!this.scene || !cam) {
      console.debug(
        'Scene or camera not defined! Scene: ',
        this.scene,
        ' | Camera: ',
        cam
      );
      return;
    }

    /*
      TODO! Hacky fix! Look into later! 
      3dview re-renders the scene one last time after 
      being destroyed and recaptures the free'd memory. 
    */
    if (this.destroyed) {
      return;
    }

    // Run rendering outside angular to avoid change detection
    this.ngZone.runOutsideAngular(() => {
      const size = this.canvasSize;
      // Legal size
      if (size.x > 0 && size.y > 0) {
        this.canvas.width = size.x;
        this.canvas.height = size.y;
        ThreeUtils.resizeCameraToSize(this.camera, size);
        const output = this.renderingService.render(
          size,
          this.scene,
          this.camera,
          this.renderingOptions,
          this.renderInfo
        );
        if (output) {
          this.context2D.drawImage(output, 0, 0, size.x, size.y);
        }
      }
    });
  }

  @HostListener('resize')
  resize() {
    this.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    // Update all content
    for (const content of this.contents.sort(
      (a, b) => b.updateOrder - a.updateOrder
    )) {
      if (content.update) {
        content.resize(
          this.canvas.clientWidth,
          this.canvas.clientHeight,
          this.canvas.clientWidth / this.canvas.clientHeight
        );
      }
    }

    this.render();
  }

  setSize(width: number, height: number) {
    ThreeUtils.resizeCameraToSize(
      this.camera,
      new THREE.Vector2(width, height)
    );
  }

  registerContent(content: ThreeViewContent): void {
    this.contents.add(content);
  }

  unregisterContent(content: ThreeViewContent): void {
    this.contents.remove(content);
  }

  getBase64Screenshot(): string | undefined {
    // Render once more before grabbing the image.
    this.render();

    let base64: string;
    try {
      base64 = this.canvas.toDataURL();
    } catch (e) {
      console.debug(e);
      return undefined;
    } finally {
      this.screenshot$.emit(base64);
      return base64;
    }
  }
}
