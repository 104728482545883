<mat-tab-group
  [color]="'primary'"
  animationDuration="0ms"
  mat-stretch-tabs
  class="sorting-tab"
>
  <mat-tab *ngIf="setupFilter" [disabled]="!(setupFilter && resultFilter)">
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">star</mat-icon>
      SETUP
    </ng-template>

    <div class="navigation-container">
      <mat-accordion>
        <div class="expansion-pane-wrapper" *ngIf="showFilters">
          <ng-container *ngFor="let setup of setupFilter">
            <mat-expansion-panel [disabled]="!setup.children">
              <mat-expansion-panel-header
                [ngStyle]="!setup.children ? { 'padding-right': '0' } : {}"
              >
                <mat-icon>{{
                  setup.id === 'favourite' ? 'favorite' : 'star'
                }}</mat-icon>
                <span class="navigation-container-item-text">
                  {{ setup.label }}
                </span>
                <mat-checkbox
                  [color]="'primary'"
                  *ngIf="!setup.children"
                  (change)="setupSingleClick(setup.id, $event.checked)"
                ></mat-checkbox>
              </mat-expansion-panel-header>

              <ng-container>
                <mat-selection-list #selectionList>
                  <mat-list-option
                    (selectedChange)="
                      setupClick(
                        setup.id,
                        selectionList.selectedOptions.selected
                      )
                    "
                    color="primary"
                    [checkboxPosition]="'before'"
                    *ngFor="let child of setup.children"
                  >
                    {{ child.label }}
                  </mat-list-option>
                </mat-selection-list>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>
        </div>
      </mat-accordion>
    </div>
  </mat-tab>

  <mat-tab *ngIf="resultFilter" [disabled]="!(setupFilter && resultFilter)">
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">bar_chart</mat-icon>
      RESULTS
    </ng-template>

    <div class="filter-results-wrapper" *ngIf="showFilters">
      <ng-container *ngFor="let filter of resultFilter">
        <p class="filter-label">{{ filter.label }}</p>
        <ng-container *ngIf="filter.filterType === filterType.SLIDER">
          <div class="horizontal_wrapper">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{ filter.label }}</mat-label>
              <input
                matInput
                type="number"
                (input)="sliderDrag(filter.id, $event)"
                [value]="filter.value"
                min="{{ filter.min ?? 0 }}"
                max="{{ filter.max ?? 100 }}"
                [(ngModel)]="filter.value"
                subscriptSizing="dynamic"
              />
            </mat-form-field>

            <mat-slider
              [color]="'primary'"
              [min]="filter.min ?? 0"
              [max]="filter.max ?? 100"
              discrete
              aria-labelledby="example-name-label"
              #ngSlider
              ><input
                matSliderThumb
                [value]="filter.value"
                (valueChange)="
                  sliderDrag(filter.id, {
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  });
                  filter.value = $event
                "
                #ngSliderThumb="matSliderThumb"
              />
            </mat-slider>
          </div>
        </ng-container>

        <ng-container
          *ngIf="filter.filterType === filterType.SELECT && filter.options"
        >
          <mat-chip-listbox class="chips" multiple>
            <mat-chip-option
              *ngFor="let option of filter.options"
              (click)="onChipClick(filter.id, option.id)"
              >{{ option.label }}</mat-chip-option
            >
          </mat-chip-listbox>
        </ng-container>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-container *ngIf="getFilterAmount() > 0">
  <mat-divider></mat-divider>
  <div class="clear-filters-wrapper">
    <div class="filler-info-wrapper">
      <p class="filter-info">
        {{
          getFilterAmount() + (getFilterAmount() > 1 ? ' Filters' : ' Filter')
        }}
      </p>
      <p (click)="clearFilters()" class="clear-filter">CLEAR ALL</p>
    </div>
  </div>
</ng-container>
