import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';

export class FrameTask extends Task {
  static [taskNameSymbol] = 'Frame';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateFrame(this.data, resolve, reject);

    if (this.data.newVal.name === 'NONE') {
      resolve();
    }
  }

  public updateFrame(
    s: FieldUpdate,
    resolve: () => void,
    reject: (reason?: any) => void
  ): void {
    // eslint-disable-next-line eqeqeq
    if (s.newVal.name == 'NONE') {
      const world_frameJoint = this.robot.getJointByID(
        JointNames.WorldFrame
      ) as THREE.Object3D;
      world_frameJoint.position.set(0, 0, 0);
      world_frameJoint.rotation.set(0, 0, 0);
      world_frameJoint.scale.set(1, 1, 1);

      const frameLink = world_frameJoint.getObjectByName('frame_link');
      frameLink.position.set(0, 0, 0);
      frameLink.rotation.set(0, 0, 0);
      frameLink.scale.set(1, 1, 1);

      const visual = URDFUtils.findVisualFromJoint(world_frameJoint);
      ThreeUtils.disposeObject(visual.children);
      visual.children = []; // Remove any previous model
      visual.position.set(0, 0, 0);
      visual.rotation.set(0, 0, 0);
      visual.scale.set(1, 1, 1);

      const frame_liftkitJoint = this.robot.getJointByID(
        JointNames.FrameLiftkit
      ) as THREE.Object3D;
      frame_liftkitJoint.position.set(0, 0, 0);
      frame_liftkitJoint.rotation.set(0, 0, 0);
      frame_liftkitJoint.scale.set(1, 1, 1);

      this.visualize();
    } else {
      const world_frameJoint = this.robot.getJointByID(
        JointNames.WorldFrame
      ) as THREE.Object3D;
      const frameLink = world_frameJoint.getObjectByName('frame_link');
      const visual = URDFUtils.findVisualFromJoint(world_frameJoint);
      const frame_ColumnJoint = this.robot.getJointByID(
        JointNames.FrameLiftkit
      ) as THREE.Object3D;

      this.partStoreService
        .getPart<THREE.Object3D>(
          HwPartUtils.getPartAssetID(PartType.FRAME, s.newVal.name)
        )
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((part) => {
          const partJoint = part.getObjectByName('frame_joint');
          world_frameJoint.position.copy(partJoint.position);
          world_frameJoint.rotation.copy(partJoint.rotation);
          world_frameJoint.scale.copy(partJoint.scale);

          const partLink = partJoint.getObjectByName('frame');
          frameLink.position.copy(partLink.position);
          frameLink.rotation.copy(partLink.rotation);
          frameLink.scale.copy(partLink.scale);

          const partVisual = URDFUtils.findVisualFromJoint(partJoint);
          visual.position.copy(partVisual.position);
          visual.rotation.copy(partVisual.rotation);
          visual.scale.copy(partVisual.scale);
          visual.children = [];

          // Copy over children clones
          for (const child of partVisual.children) {
            const newChild = ThreeUtils.clone(child);
            visual.add(newChild);
          }

          const mountJoint = URDFUtils.findMountJoint(part);
          frame_ColumnJoint.position.copy(mountJoint.position);
          frame_ColumnJoint.rotation.copy(mountJoint.rotation);
          frame_ColumnJoint.scale.copy(mountJoint.scale);

          this.visualize();

          resolve();
        });
    }
  }

  visualize(): void {
    this.activateDimVis(
      'robot_mount_height_full',
      undefined,
      this.readShowVisualizers() ? -1 : undefined
    );
  }
}
