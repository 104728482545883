<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title style="margin-bottom: 0; margin-top: 12px">
      Robot configurations
    </h1>

    <app-header-actions>
      <ng-container *appIfCommandAvailable="'create_robot_configuration'">
        <button
          mat-flat-button
          pally-rounded-button-m
          color="primary"
          (click)="routeToEdit()"
        >
          <mat-icon>add</mat-icon>New
        </button>
      </ng-container>

      <ng-container *appIfCommandAvailable="'upload_robot_configuration'">
        <input
          style="display: none"
          type="file"
          (change)="onFileChanged($event)"
          #fileInput
        />
        <button
          mat-flat-button
          pally-rounded-button-m
          color="primary"
          (click)="fileInput.click()"
        >
          <mat-icon>upload_file</mat-icon>Upload file
        </button>
      </ng-container>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="robotConfigs$ | async as robotConfigs">
    <!-- Loading state -->
    <app-loading *ngIf="robotConfigs.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="robotConfigs.error as error" [error]="error"></app-error>

    <app-content-switcher
      *ngIf="robotConfigs.didSucceed"
      [enableToggle]="true"
      [selected]="blockSelected"
      [setupFilter]="setupFilter"
      [sortingColumns]="sortingColumns"
      objectType="robot_configuration"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
      (order)="orderBy = $event"
      (delete)="onDeleteBlock(blockSelected)"
    >
      <ng-template #table>
        <app-table-expandable
          [clickableRows]="true"
          [topRow]="false"
          [inputData]="robotConfigs.value"
          [displayedColumns]="displayedColumns"
          [filter]="tableFilter"
          [sortBy]="orderBy"
          (actionClicked)="actionClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-table-expandable>
      </ng-template>

      <ng-template #cardList>
        <app-card-list-wrapper
          [inputData]="robotConfigs.value"
          [filter]="tableFilter"
          [sortBy]="orderBy"
        >
          <ng-template #card let-data>
            <app-card-robot-config
              [robotConfig]="data.data.data"
              [doesAllowActions]="true"
              [actions]="cardActions"
              (actionClick)="actionClicked($event)"
            >
            </app-card-robot-config>
          </ng-template>
        </app-card-list-wrapper>
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
