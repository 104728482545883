import { Injector } from '@angular/core';
import * as THREE from 'three';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AssetIDs } from 'src/app/models_new/enums/asset-ids';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { taskNameSymbol, Task } from '../task';
import { milliToMeter } from '../../../../utils/div';
import { defaultApiProduct } from '../../../config/default/api-default/default-api-product';
import { DynamicBox } from '../../3dview/dynamic-box';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { ResourceTracker } from '../../../../utils/resource-tracker';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';

export class ConveyorBoxTask extends Task {
  static [taskNameSymbol] = 'Conveyor box';
  rt = new ResourceTracker();

  sticker$: Observable<THREE.Texture>;

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);

    this.destroy$.pipe(take(1)).subscribe(() => {
      this.rt.dispose();
    });

    // Get the company logo service and reuse the already loaded texture.
    this.sticker$ = injector
      .get(OrganizationLogoService)
      .eitherLabelOrLogo$.pipe(map((data) => data.texture));
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateConveyorBox();

    resolve();
  }

  public updateConveyorBox(): void {
    const conveyorBoxJoint = this.robot.getJointByID(
      JointNames.ConveyorBox
    ) as THREE.Object3D;
    const conveyorBoxVisual = URDFUtils.findVisualFromJoint(conveyorBoxJoint);

    const isGuideSideRIGHT = this.isGuideSideRIGHT();
    const guideSide = isGuideSideRIGHT ? 1 : -1;

    // Create box if not already exists
    if (!conveyorBoxVisual.getObjectByName('conveyor_box')) {
      combineLatest([
        AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(AssetIDs.Box),
        this.sticker$,
      ])
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe(([asset, texture]) => {
          // Extract the actual mesh.
          const boxMesh = asset.children[0] as THREE.Mesh;

          // Convert to dynamic box
          const box = new DynamicBox(boxMesh);
          box.name = 'conveyor_box';

          box.setSize({
            width: milliToMeter(defaultApiProduct.data.width),
            height: milliToMeter(defaultApiProduct.data.height),
            length: milliToMeter(defaultApiProduct.data.length),
          });

          // Rotate box 90 degrees around x to make the box oriented correctly
          // (Z-axis of conveyorBoxVisual points UP, not Y as expected)
          box.rotation.x = Math.PI / 2;

          box.setLabels(texture);
          box.setLabelOrientations([
            LabelOrientation.RIGHT,
            LabelOrientation.LEFT,
          ]);

          conveyorBoxVisual.add(box);
        });
    }

    conveyorBoxJoint.position.set(
      (milliToMeter(defaultApiProduct.data.height) / 2) * (guideSide * -1),
      milliToMeter(defaultApiProduct.data.length) / 2,
      milliToMeter(defaultApiProduct.data.width) / 2
    );
  }

  protected isGuideSideRIGHT(): boolean {
    return !(this.readGuideSide() > 0);
  }

  protected readGuideSide(): number {
    let side = this.readField<string>(SimConfigFieldIds.ConveyorRightGuideSide);
    return side.toLowerCase() === 'left' ? 1 : -1;
  }
}

/* OLD ALGORITHM, KEEP FOR REFERNCE!
const conveyorGuideJoint = this.robot.getJointByID(JointNames.ConveyorGuide) as THREE.Object3D;
const conveyorGuideOffsetXJoint = this.robot.getJointByID(JointNames.ConveyorGuideOffsetX) as THREE.Object3D;
const conveyorGuideOffsetXLink = URDFUtils.findLinkFromJoint(conveyorGuideOffsetXJoint);
const conveyorGuideLink = URDFUtils.findLinkFromJoint(conveyorGuideJoint);
const conveyorGuideVisual = URDFUtils.findVisualFromJoint(conveyorGuideJoint);

const type = this.readConveyorType();
const dimension = this.readConveyorDimention();
const isGuideSideRIGHT = this.isGuideSideRIGHT();
const guideSide = ((isGuideSideRIGHT) ? 1 : -1);
const guideWidth = this.readGuideWidth();

conveyorGuideOffsetXJoint.position.set(0, 0, 0);
conveyorGuideOffsetXJoint.rotation.set(0, 0, 0);
conveyorGuideOffsetXJoint.scale.set(1, 1, 1);

conveyorGuideOffsetXLink.position.set((dimension.x / 2) * guideSide, 0, 0);
conveyorGuideOffsetXLink.rotation.set(0, 0, 0);
conveyorGuideOffsetXLink.scale.set(1, 1, 1);

conveyorGuideJoint.position.set(0, 0, 0);
conveyorGuideJoint.rotation.set(0, 0, 0);
conveyorGuideJoint.scale.set(1, 1, 1);

                                // Counteracts dimension offset to the side
conveyorGuideLink.position.set(guideWidth * (guideSide * -1), 0, 0);
conveyorGuideLink.rotation.set(0, 0, 0);
conveyorGuideLink.scale.set(1, 1, 1);

conveyorGuideVisual.position.set(0.025 * guideSide, (length / 2), 0.05);
conveyorGuideVisual.rotation.set(0, 0, 0);
conveyorGuideVisual.scale.set(0.05, length, 0.1);
*/
