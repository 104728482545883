import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { IApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { ApiSimulation } from 'src/app/models_new/classes/api-models/ApiSimulation';
import { ApiUser } from 'src/app/models_new/classes/api-models/ApiUser';
import { defaultApiProduct } from './default-api-product';
import { defaultApiUser } from './default-api-user';
import { ObjectUtils } from '../../../../utils/object';
import { defaultPallyFileType } from '../default-pally-file-type';

export const defaultApiPattern: IApiPattern = {
  id: null,
  name: 'Default Pattern',
  data: ObjectUtils.cloneObject(defaultPallyFileType),
  owner_id: new ApiUser(defaultApiUser).id,
  organization_id: '001',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  product_id: new ApiProduct(defaultApiProduct).id,
  product: new ApiProduct(defaultApiProduct),
  simulations: [new ApiSimulation(null)],
};
