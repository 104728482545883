import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Field } from '../../../models_new/classes/field';
import { ISceneApiFileType } from '../../../models_new/types/simulation-api-file-type';
import { FieldType } from '../../../models_new/types/field-type';
import { defaultCustomGripper } from '../../../models_new/config/default/default-scene';
import { Validators } from '@angular/forms';
import { SimConfigFieldIds } from '../../../models_new/enums/simconfig-field-ids';
import * as THREE from 'three';
import { meterToMilli } from '../../../utils/div';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKey } from '../../../models_new/enums/local-storage-keys';
import { ICustomGripper } from '../../../models_new/types/custom-gripper';

@Component({
  selector: 'app-custom-gripper',
  templateUrl: './custom-gripper.component.html',
  styleUrls: ['./custom-gripper.component.scss'],
})
export class CustomGripperComponent {
  lengthField: Field = new Field(
    FieldType.NUMBER,
    true,
    meterToMilli(defaultCustomGripper.custom_collision_box.length),
    [Validators.required],
    null,
    null,
    null,
    { label: 'Length', suffix: 'mm' },
    SimConfigFieldIds.GripperCustomLength,
    null,
    null,
    null,
    null,
    { min: 0 }
  );
  widthField: Field = new Field(
    FieldType.NUMBER,
    true,
    meterToMilli(defaultCustomGripper.custom_collision_box.width),
    [Validators.required],
    null,
    null,
    null,
    { label: 'Width', suffix: 'mm' },
    SimConfigFieldIds.GripperCustomWidth,
    null,
    null,
    null,
    null,
    { min: 0 }
  );
  heightField: Field = new Field(
    FieldType.NUMBER,
    true,
    meterToMilli(defaultCustomGripper.custom_collision_box.height),
    [Validators.required],
    null,
    null,
    null,
    { label: 'Height', suffix: 'mm' },
    SimConfigFieldIds.GripperCustomHeight,

    null,
    null,
    null,
    null,
    { min: 0 }
  );
  offsetXField: Field = new Field(
    FieldType.NUMBER,
    true,
    meterToMilli(defaultCustomGripper.custom_offset_pose.position.x),
    [Validators.required],
    null,
    null,
    null,
    { label: 'Offset width', suffix: 'mm' },
    SimConfigFieldIds.GripperCustomPositionX
  );
  offsetYField: Field = new Field(
    FieldType.NUMBER,
    true,
    meterToMilli(-defaultCustomGripper.custom_offset_pose.position.y),
    [Validators.required],
    null,
    null,
    null,
    { label: 'Offset length', suffix: 'mm' },
    SimConfigFieldIds.GripperCustomPositionY
  );

  constructor(
    public dialogRef: MatDialogRef<CustomGripperComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        values?: ISceneApiFileType['robot']['gripper'];
      };
    },
    private localstoreService: LocalStorageService
  ) {
    if (this.input?.data?.values) {
      this.lengthField.formControl.setValue(
        this.input.data.values.custom_collision_box.length
      );
      this.widthField.formControl.setValue(
        this.input.data.values.custom_collision_box.width
      );
      this.heightField.formControl.setValue(
        this.input.data.values.custom_collision_box.height
      );
      this.offsetXField.formControl.setValue(
        this.input.data.values.custom_offset_pose.position.x
      );
      this.offsetYField.formControl.setValue(
        this.input.data.values.custom_offset_pose.position.y
      );
    }
  }

  makeCustomGripper(): ICustomGripper {
    return {
      custom_offset_pose: {
        position: new THREE.Vector3(
          this.offsetXField.formControl.value,
          this.offsetYField.formControl.value,
          0
        ),
        rotation: { r: 0, p: 0, y: 0 },
      },
      custom_collision_box: {
        length: this.lengthField.formControl.value,
        width: this.widthField.formControl.value,
        height: this.heightField.formControl.value,
        position: new THREE.Vector3(),
      },
    };
  }

  close(data?: ICustomGripper): void {
    if (data) {
      this.localstoreService.setData(LocalStorageKey.CUSTOM_GRIPPER, {
        custom_collision_box: data.custom_collision_box,
        custom_offset_pose: data.custom_offset_pose,
      } as ICustomGripper);
    }

    this.dialogRef.close(data);
  }
}
