import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { pagesPATH } from '../models_new/config/pages';
import { Layer } from '../models_new/classes/layer';
import { StackingMethod } from '../models_new/enums/stacking-method';
import { GripperOrientation } from '../models_new/enums/gripper-orientation';
import { gripperOrientations } from '../models_new/config/gripper-orientations';
import { IGripperOrientationOption } from '../models_new/types/gripper-orientation-option';
import { ObjectUtils } from '../utils/object';
import { settings } from '../models_new/config/application-settings';
import {
  IStepperStep,
  getProjectDataNavImg,
  projectDataNavs,
} from '../models_new/config/project-data-steps';
import { Box as PackingBox } from '@rocketfarm/packing-webcomponents';

@Injectable({
  providedIn: 'root',
})
export class SubnavViewService {
  // General
  public title$ = new BehaviorSubject<string>(null);
  public view$ = new BehaviorSubject<string>(null);

  // Project data
  public projectDataSection$ = new BehaviorSubject<IStepperStep>(null);
  public projectDataSectionIndex$ = new BehaviorSubject<number>(0);
  public projectDataSectionClick$ = new BehaviorSubject<number>(null);
  public sectionsWithInvalid$ = new BehaviorSubject<string[]>([]);
  public focusedBoxData$ = new BehaviorSubject<number>(0);

  // Pallet

  public selectedBasePattern$ = new BehaviorSubject<Layer>(null);
  public selectedBasePatternIndex$ = new BehaviorSubject<number>(0);
  public selectedStackingMethod$ = new BehaviorSubject<StackingMethod>(
    settings.defaultStackingMethod
  );
  public dirtyPallet$ = new BehaviorSubject<boolean>(false);
  public navOptionSelected$ = new BehaviorSubject<string>(null);
  public selectNavOption$ = new BehaviorSubject<string>('palletEdit');

  cleanPallet$ = new BehaviorSubject<boolean>(false);
  addLayer$ = new BehaviorSubject<boolean>(false);
  duplicatePrevLayer$ = new BehaviorSubject<boolean>(false);
  mirrorPrevLayerVert$ = new BehaviorSubject<boolean>(false);
  mirrorPrevLayerHoriz$ = new BehaviorSubject<boolean>(false);
  customBoxIndexLayer$ = new BehaviorSubject<boolean>(false);
  rotatePrevLayer$ = new BehaviorSubject<boolean>(false);
  addShimpaper$ = new BehaviorSubject<boolean>(false);

  public palletTools$ = merge(
    this.addShimpaper$.pipe(
      filter((m) => m),
      map(() => 'shim')
    ),
    this.addLayer$.pipe(
      filter((m) => m),
      map(() => 'add')
    ),
    this.duplicatePrevLayer$.pipe(
      filter((m) => m),
      map(() => 'dup')
    ),
    this.mirrorPrevLayerHoriz$.pipe(
      filter((m) => m),
      map(() => 'hor')
    ),
    this.mirrorPrevLayerVert$.pipe(
      filter((m) => m),
      map(() => 'ver')
    ),
    this.rotatePrevLayer$.pipe(
      filter((m) => m),
      map(() => 'rot')
    ),
    this.cleanPallet$.pipe(
      filter((m) => m),
      map(() => 'del')
    )
  );

  // Pattern edit buttons
  public autoStackPattern$ = new BehaviorSubject<boolean>(false);
  public addNewPatternBox$ = new BehaviorSubject<boolean>(false);
  public mirrorPatternVert$ = new BehaviorSubject<boolean>(false);
  public mirrorPatternHoriz$ = new BehaviorSubject<boolean>(false);
  public rotatePattern$ = new BehaviorSubject<boolean>(false);
  public alignPatternVert$ = new BehaviorSubject<boolean>(false);
  public alignPatternHoriz$ = new BehaviorSubject<boolean>(false);
  public inverseApproach$ = new BehaviorSubject<boolean>(false);
  public inverseApproachClicked$ = new BehaviorSubject<boolean>(false);
  public undoChange$ = new BehaviorSubject<boolean>(false);
  public redoChange$ = new BehaviorSubject<boolean>(false);

  // Pattern edit settings
  public patternCollision$ = new BehaviorSubject<boolean>(true);
  public restrictPatternToPallet$ = new BehaviorSubject<boolean>(true);
  public patternBoxPositions$ = new BehaviorSubject<boolean>(false);
  public currentLayer$ = new BehaviorSubject<Layer>(null);

  // Pattern edit > box(es) selected
  public patternSelectedBoxes$ = new BehaviorSubject<PackingBox[]>(null);
  public patternRotateBoxes$ = new BehaviorSubject<boolean>(false);
  public patternMirrorBoxes$ = new BehaviorSubject<boolean>(false);
  public patternDeleteBoxes$ = new BehaviorSubject<boolean>(false);
  public patternChangeBoxOrientation$ = new BehaviorSubject<boolean>(false);
  public patternEnforcedGripperOrientation$ = new BehaviorSubject<
    GripperOrientation[]
  >([]);
  public patternEnforcedGripperOrientationOptions$ = new BehaviorSubject<
    IGripperOrientationOption[]
  >(gripperOrientations);
  public patternSetEnforcedGripperOrientation$ = new BehaviorSubject<
    IGripperOrientationOption[]
  >(null);
  public patternChangeStopMultiplePick$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {
    this.router.events
      .pipe(
        map((ev) => {
          if (ev instanceof NavigationEnd) {
            const url = ev.url.replace('/', '');

            if (url.includes('patternEdit')) {
              this.view$.next('patternEdit');
            } else {
              this.view$.next(null);
            }

            if (url.includes(pagesPATH.PATTERNEDIT)) {
              this.resetAllPatternButtons();
              this.title$.next('Edit pattern');
            }
          }
        })
      )
      .subscribe();

    combineLatest([
      this.focusedBoxData$,
      this.projectDataSectionIndex$,
    ]).subscribe(([focusedField, stepSelected]) => {
      const nav = projectDataNavs[stepSelected];
      nav.img = getProjectDataNavImg(stepSelected, focusedField);
      this.projectDataSection$.next(nav);
    });

    /**
     * Watch for changes in sat enforced gripper orientations
     * If changes give possible options witch select updated
     */
    this.patternEnforcedGripperOrientation$
      .pipe(
        map((go: GripperOrientation[]) => {
          if (go) {
            const allOptions = ObjectUtils.cloneObject(gripperOrientations).map(
              (goo: IGripperOrientationOption) => {
                if (go.includes(goo.value)) {
                  goo.selected = true;
                } else {
                  goo.selected = false;
                }
                return goo;
              }
            );
            this.patternEnforcedGripperOrientationOptions$.next(allOptions);
          } else {
            this.patternEnforcedGripperOrientationOptions$.next(null);
          }
        })
      )
      .subscribe();
  }

  resetAllLayersButtons() {
    this.cleanPallet$.next(false);
    this.addLayer$.next(false);
    this.duplicatePrevLayer$.next(false);
    this.mirrorPrevLayerHoriz$.next(false);
    this.mirrorPrevLayerVert$.next(false);
    this.rotatePrevLayer$.next(false);
    this.customBoxIndexLayer$.next(false);
    this.addShimpaper$.next(false);
    this.currentLayer$.next(null);
  }

  resetAllPatternButtons() {
    // Pattern
    this.autoStackPattern$.next(false);
    this.addNewPatternBox$.next(false);
    this.mirrorPatternVert$.next(false);
    this.mirrorPatternHoriz$.next(false);
    this.rotatePattern$.next(false);
    this.alignPatternVert$.next(false);
    this.alignPatternHoriz$.next(false);
    this.undoChange$.next(false);
    this.redoChange$.next(false);

    // Boxes
    this.patternRotateBoxes$.next(false);
    this.patternMirrorBoxes$.next(false);
    this.patternDeleteBoxes$.next(false);
    this.patternChangeBoxOrientation$.next(false);
    this.patternSetEnforcedGripperOrientation$.next(null);
  }

  autoStackPattern() {
    this.autoStackPattern$.next(true);
  }

  addNewBox() {
    this.addNewPatternBox$.next(true);
  }

  mirrorPatternVert() {
    this.mirrorPatternVert$.next(true);
  }

  mirrorPatternHoriz() {
    this.mirrorPatternHoriz$.next(true);
  }

  rotatePattern() {
    this.rotatePattern$.next(true);
  }

  alignPatternVert() {
    this.alignPatternVert$.next(true);
  }

  alignPatternHoriz() {
    this.alignPatternHoriz$.next(true);
  }
}
