import { IStandardPallet } from './../../../models_new/types/standard-pallet';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/models_new/classes/field';
import { settings } from 'src/app/models_new/config/application-settings';
import { CardFieldIds } from 'src/app/models_new/config/form_fields';
import { FieldType } from 'src/app/models_new/types/field-type';

export function getCustomPalletFields(
  isPublic?: boolean,
  customPallet?: IStandardPallet
): Map<CardFieldIds, Field> {
  const customPalletLength = new Field(
    FieldType.NUMBER,
    true,
    customPallet
      ? +customPallet?.length
      : settings.customPalletSettings.length.default,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.PalletLength,
      label: 'Pallet length',
      suffix: 'mm',
    },
    CardFieldIds.PalletLength,
    null,
    null,
    null,
    'text-align: right',
    {
      min: settings.customPalletSettings.length.min,
      max: settings.customPalletSettings.length.max,
    }
  );

  const customPalletWidth = new Field(
    FieldType.NUMBER,
    true,
    customPallet
      ? +customPallet?.width
      : settings.customPalletSettings.width.default,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.PalletWidth,
      label: 'Pallet width',
      suffix: 'mm',
    },
    CardFieldIds.PalletWidth,
    null,
    null,
    null,
    'text-align: right',
    {
      min: settings.customPalletSettings.width.min,
      max: settings.customPalletSettings.width.max,
    }
  );

  const customPalletHeight = new Field(
    FieldType.NUMBER,
    true,
    customPallet
      ? +customPallet?.palletHeight
      : settings.customPalletSettings.palletHeight.default,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.PalletHeight,
      label: 'Pallet heigth',
      suffix: 'mm',
    },
    CardFieldIds.PalletHeight,
    null,
    null,
    null,
    'text-align: right',
    {
      min: settings.customPalletSettings.palletHeight.min,
      max: settings.customPalletSettings.palletHeight.max,
    }
  );

  return new Map<CardFieldIds, Field>([
    [CardFieldIds.PalletHeight, customPalletHeight],
    [CardFieldIds.PalletLength, customPalletLength],
    [CardFieldIds.PalletWidth, customPalletWidth],
  ]);
}

export const getCustomPalletFormGroup = (
  fields: Map<CardFieldIds, Field>
): UntypedFormGroup => {
  const formGroup = new UntypedFormGroup({});
  fields.forEach((f: Field) => {
    formGroup.addControl(f.text.name, f.formControl);
  });
  return formGroup;
};
