export class Export {
  stl: Blob;
  collada: Blob;
  step?: Blob;

  constructor(stl, dae, step?) {
    this.stl = stl;
    this.collada = dae;
    this.step = step;
  }
}
