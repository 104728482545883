<ng-container *ngIf="ready">
  <button
    *ngIf="buttonStyle.type === 'flat'"
    mat-flat-button
    [disabled]="disabled"
    color="{{ buttonStyle.color }}"
    class="rf-button {{ buttonStyle.effect }}"
    [ngClass]="{ disabled: disabled }"
    (click)="emitClick()"
  >
    <ng-container *ngIf="buttonStyle.icon">
      <mat-icon *ngIf="buttonStyle.icon.position === 'before'">{{
        buttonStyle.icon.type
      }}</mat-icon>
    </ng-container>
    <span *ngIf="label">&nbsp; {{ label }} &nbsp; </span>
    <ng-container *ngIf="buttonStyle.icon">
      <mat-icon *ngIf="buttonStyle.icon.position === 'after'">{{
        buttonStyle.icon.type
      }}</mat-icon>
    </ng-container>
  </button>

  <button
    *ngIf="buttonStyle.type === 'stroked'"
    mat-stroked-button
    [disabled]="disabled"
    color="{{ buttonStyle.color }}"
    class="rf-button {{ buttonStyle.effect }}"
    [ngClass]="{ disabled: disabled }"
    (click)="emitClick()"
  >
    <ng-container *ngIf="buttonStyle.icon">
      <mat-icon *ngIf="buttonStyle.icon.position === 'before'">{{
        buttonStyle.icon.type
      }}</mat-icon>
    </ng-container>
    <span *ngIf="label">&nbsp; {{ label }} &nbsp; </span>
    <ng-container *ngIf="buttonStyle.icon">
      <mat-icon *ngIf="buttonStyle.icon.position === 'after'">{{
        buttonStyle.icon.type
      }}</mat-icon>
    </ng-container>
  </button>
</ng-container>
