import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PickerSelectionAmount } from 'src/app/models_new/enums/picker-selection-amount';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { IPickerData } from 'src/app/models_new/types/picker-data';
import {
  IPickerDescription,
  pickerDescription,
} from 'src/app/models_new/types/picker-description';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PickerComponent {
  @Input() type: PickerType;
  @Input() selectionAmount: PickerSelectionAmount;

  selected: IPickerData[];

  pickerDescription: IPickerDescription;

  constructor(
    public dialogRef: MatDialogRef<PickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      type: PickerType;
      selectionAmount?: PickerSelectionAmount;
      selected?: IPickerData[];
    }
  ) {
    this.type = this.input.type;
    this.selected = [];
    this.selectionAmount =
      this.input.selectionAmount !== undefined
        ? this.input.selectionAmount
        : 'SELECT_MULTIPLE';
    this.pickerDescription = pickerDescription(this.type);
  }

  didChangeSelection(selection: IPickerData[]) {
    this.selected = selection;
  }

  didClickClose() {
    const initialAmountSelected = this.input.selected?.length || 0;
    if (
      (!this.input.selected || initialAmountSelected === 0) &&
      this.selected.length === 0
    ) {
      this.close();
    } else {
      this.closeAndPick();
    }
  }

  get closeButtonText(): string {
    const initialAmountSelected = this.input.selected?.length || 0;
    if (
      this.input.selected &&
      initialAmountSelected !== 0 &&
      this.selected.length === 0
    ) {
      return 'Pick none and close';
    } else if (
      (!this.input.selected || initialAmountSelected) &&
      this.selected.length === 0
    ) {
      return 'Close';
    } else {
      const isPlural = this.selected.length !== 1;
      return (
        'Pick ' +
        this.selected.length +
        ' ' +
        this.pickerDescription.name.toLowerCase() +
        (isPlural ? 's' : '') +
        ' and close'
      );
    }
  }

  closeAndPick() {
    this.dialogRef.close(this.selected ? this.selected : []);
  }

  close() {
    this.dialogRef.close(null);
  }
}
