import { GripperOrientation } from '../enums/gripper-orientation';
import { IGripperOrientationOption } from '../types/gripper-orientation-option';

export const gripperOrientations: IGripperOrientationOption[] = [
  {
    name: 'Left',
    value: GripperOrientation.LEFT,
    selected: false,
  },
  {
    name: 'Front',
    value: GripperOrientation.FRONT,
    selected: false,
  },
  {
    name: 'Right',
    value: GripperOrientation.RIGHT,
    selected: false,
  },
  {
    name: 'Back',
    value: GripperOrientation.BACK,
    selected: false,
  },
];
