import { IProjectData } from '../types/project-data';
import { LabelOrientation } from '../enums/label-orientation';
import { StackingMethod } from '../enums/stacking-method';
import { ILabelDirection } from '../types/labelDirection';

export class ProjectData implements IProjectData {
  id: string;
  name: string;
  description: string;
  dateModified: string;
  unit: string;
  pallet: {
    dimensions: {
      length: number;
      width: number;
      loadHeight: number;
      totalHeight: number;
      palletHeight: number;
    };
    overhang: {
      enabled: boolean;
      sides: number;
      ends: number;
    };
  };
  box: {
    dimensions: {
      width: number;
      height: number;
      length: number;
    };
    weight: number;
    padding: number;
    maxGrip: number;
    label: {
      enabled: boolean;
      orientation: LabelOrientation;
      direction: ILabelDirection;
    };
    logo?: {
      imageData: string;
    };
    indexOverride?: boolean;
  };
  shimPaper: {
    enabled: boolean;
    height: number;
  };
  stackingMethod?: StackingMethod;
  cpm?: number;

  constructor(data: IProjectData) {
    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        this[d] = data[d];
      }
    }

    // Set shimpaper to always activated!
    this.shimPaper.enabled = true;
  }

  getPalletTotalDimensions(): ProjectData['pallet']['dimensions'] {
    const pallet = this.getPalletData();

    pallet.dimensions.length = pallet.dimensions.length + pallet.overhang.ends;
    pallet.dimensions.width = pallet.dimensions.width + pallet.overhang.sides;

    return pallet.dimensions;
  }

  getShimPaperData(): ProjectData['shimPaper'] {
    return this.shimPaper;
  }

  getPalletData(): ProjectData['pallet'] {
    return this.pallet;
  }

  getBoxData(): ProjectData['box'] {
    return this.box;
  }
}
