import {
  Component,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import {
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  scan,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import { IApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { Field } from 'src/app/models_new/classes/field';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { HardwareApiService } from 'src/app/services/api/hardware-api.service';
import { RobotConfigApiService } from 'src/app/services/api/robot-config-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { findField, toScene } from 'src/app/services/robot-config-helper';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { replaceQuaternions } from 'src/app/utils/three-utils';

interface StepConfigStep {
  header: string;
  next: string;
  previous: string;
}
interface Config {
  hardwareTouched?: boolean;
  strategyTouched?: boolean;
  viewParent: string;
  viewChild: string;
  index: number;
  steps: StepConfigStep[];
}
@Component({
  selector: 'app-advanced-robot-config',
  templateUrl: './advanced-robot-config.component.html',
  styleUrls: ['./advanced-robot-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdvancedRobotConfigComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  ThreeHandlerMode = ThreeHandlerMode;
  @ViewChild('stepper') stepper: MatStepper;
  config$: Observable<Config>;
  robotConfiguration$: Observable<IApiRobotConfiguration>; //
  fields$: Observable<Field[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private simConfigService: SimConfigService,
    private hardwareApiService: HardwareApiService,
    private notificationService: NotificationService,
    private robotConfigService: RobotConfigApiService,
    private stateService: StateService
  ) {
    this.config$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      scan((acc, curr) => {
        acc.push(curr);
        return acc;
      }, new Array<ActivatedRoute>()),
      map((routes) => {
        const route = routes[routes.length - 1];
        const firstPath = route.snapshot.url[0].path;
        const secondPath = route.snapshot.url[1]?.path;
        const config = {
          hardwareTouched: !!routes.find(
            (route) =>
              route.snapshot.routeConfig.path.indexOf(
                pagesPATH.ADVANCED_CONFIG_HARDWARE
              ) > -1
          ),
          strategyTouched: !!routes.find(
            (route) =>
              route.snapshot.routeConfig.path.indexOf(
                pagesPATH.ADVANCED_CONFIG_STRATEGY
              ) > -1
          ),
          viewParent: firstPath,
          viewChild: secondPath,
          index: -1,
          steps: new Array<StepConfigStep>(),
        };

        if (
          firstPath === pagesPATH.ADVANCED_CONFIG_HARDWARE ||
          firstPath === pagesPATH.ADVANCED_CONFIG_STRATEGY
        ) {
          route.snapshot.parent.routeConfig.children.forEach(
            (child, index, array) => {
              if (
                child.path.indexOf(firstPath) !== -1 &&
                index !== array.length - 1
              ) {
                const isSecondLastChild = index === array.length - 2;
                const isFirstChild = index === 0;
                config.steps.push({
                  header: child.data.header,
                  next: isSecondLastChild
                    ? pagesPATH.ADVANCED_CONFIG_SUMMARY
                    : array[index + 1].path,
                  previous: isFirstChild ? '.' : array[index - 1].path,
                });
              }
            }
          );
          config.index = parseInt(secondPath) - 1;
        }
        return config;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );

    // TODO PALLY-2342 remove/simplify workaround after bugfix
    this.robotConfiguration$ = combineLatest([
      this.route.params,
      this.stateService.getCustomerOrSalesOrganization().pipe(take(1)),
    ]).pipe(
      switchMap(([params, organization]) =>
        this.robotConfigService
          .fetchRobotconfigs(organization.id)
          .pipe(
            map((robotConfigs) =>
              robotConfigs.find(
                (config) => config.id === params.robotConfigurationId
              )
            )
          )
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.fields$ = this.robotConfiguration$.pipe(
      switchMap((robotConfiguration) =>
        this.simConfigService.configureScene(robotConfiguration.scene.id)
      ),
      map(([fields]) => fields)
    );

    this.config$.subscribe();
  }

  async save(fields: Field[]) {
    const robotConfiguration = await firstValueFrom(this.robotConfiguration$);
    const name = findField(fields, SimConfigFieldIds.Name).getValue() as string;
    const data = replaceQuaternions(
      cloneDeep(toScene(fields, robotConfiguration.scene.id))
    );
    const insertResponse = await firstValueFrom(
      this.hardwareApiService.updateScene(
        robotConfiguration.scene.id,
        name,
        '',
        data
      )
    );
    this.notificationService.showMessage(`${name} saved`);
    return insertResponse;
  }

  async saveThenRoute(fields: Field[], relativeRoute: string) {
    await this.save(fields);
    this.router.navigate([relativeRoute], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
