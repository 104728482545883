import { Injector } from '@angular/core';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { ProjectRobotDescriptorService } from '../../../services/project-robot-descriptor.service';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  timeout,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  firstValueFrom,
  from,
  merge,
  Observable,
  ReplaySubject,
  Subject,
  TimeoutError,
} from 'rxjs';
import { FieldUpdate } from '../../types/field-update';
import { SimConfigFieldIds } from '../../enums/simconfig-field-ids';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { SimulationUpdateAction } from '../../enums/simulation-view-update';
import { ProcessingOverlayService } from 'src/app/services/processing-overlay.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { simConfigTasks } from '../../config/sim-config/sim-config-tasks';
import { Task, taskNameSymbol } from './task';
import { IListChange, List, ListChange } from 'src/app/utils/list';
import { TimedObject3DService } from '../../../services/timed-object3d.service';
import { simConfigDimVisConfig } from '../../config/timed-object-config';
import { DimensionVisualizer } from '../3dview/timed-objects/dimension-visualizer';
import { ITimedObjectConfig } from '../../types/simconf/timed-object-config';
import { ProjectService } from '../../../services/project.service';
import { AssetIDs } from '../../enums/asset-ids';
import {
  ISpeedTestResult,
  NetSpeedService,
} from '../../../services/net-speed.service';
import { NotificationService } from '../../../services/notification.service';

// NOTE! If the field to check should also be skipped, add it in the "fieldIds" list.
export const updateRelatedFields = new Map<
  SimConfigFieldIds,
  { predicate: (value: any) => boolean; fieldIds: SimConfigFieldIds[] }
>([
  [
    SimConfigFieldIds.AddOffsetBracket,
    {
      predicate: (value: boolean) => value === true,
      fieldIds: [
        SimConfigFieldIds.AddOffsetBracket,
        SimConfigFieldIds.OffsetBracketWidth,
        SimConfigFieldIds.OffsetBracketLength,
        SimConfigFieldIds.OffsetBracketHeight,
        SimConfigFieldIds.OffsetBracketOffsetX,
        SimConfigFieldIds.OffsetBracketOffsetY,
        SimConfigFieldIds.OffsetBracketOffsetZ,
        SimConfigFieldIds.OffsetBracketPositionX,
        SimConfigFieldIds.OffsetBracketPositionY,
        SimConfigFieldIds.OffsetBracketPositionZ,
      ],
    },
  ],
  [
    SimConfigFieldIds.GripperType,
    {
      predicate: (value: string) => value === 'CUSTOM',
      fieldIds: [
        SimConfigFieldIds.GripperCustomWidth,
        SimConfigFieldIds.GripperCustomLength,
        SimConfigFieldIds.GripperCustomHeight,
        SimConfigFieldIds.GripperCustomPositionX,
        SimConfigFieldIds.GripperCustomPositionY,
        SimConfigFieldIds.GripperCustomPositionZ,
        SimConfigFieldIds.GripperCustomRotationP,
        SimConfigFieldIds.GripperCustomRotationR,
        SimConfigFieldIds.GripperCustomRotationY,
      ],
    },
  ],
]);

export class ProjectRobotStructureHandler {
  tasks = new List<Task>();

  handlerID: any;
  onFinishedHandling$: Subject<void> = new Subject<void>();
  reportSize = true;

  fieldUpdates$: BehaviorSubject<FieldUpdate> =
    new BehaviorSubject<FieldUpdate>(null);

  robotUpdated = false;
  firstTotalNrSetupTasks = 0;
  firstRobotUpdate = true;
  progress$ = new BehaviorSubject<number>(0);
  hasTasks$: Observable<boolean>;
  taskTimeoutDuration$: Observable<number>;
  shouldReportTimeoutErrors = true;

  /**
   * true => Turns on reporting for all tasks regardless of duration.
   * false => Only tasks >= 1 second.
   */
  verboseTaskReporting = false;

  private robot: ProjectRobotDescriptorService;
  private poService: ProcessingOverlayService;
  private simConfigService: SimConfigService;
  private toService: TimedObject3DService;
  private projectService: ProjectService;
  private netSpeedService: NetSpeedService;
  private notification: NotificationService;

  constructor(
    private threeID: string,
    private injector: Injector,
    private destroy$: ReplaySubject<boolean>
  ) {
    this.robot = injector.get(ProjectRobotDescriptorService);
    this.poService = injector.get(ProcessingOverlayService);
    this.simConfigService = injector.get(SimConfigService);
    this.toService = injector.get(TimedObject3DService);
    this.projectService = injector.get(ProjectService);
    this.netSpeedService = injector.get(NetSpeedService);
    this.notification = injector.get(NotificationService);

    this.robot.mapped$
      .pipe(RXJSUtils.filterFalse(), take(1), takeUntil(this.destroy$))
      .subscribe((_: any) => {
        this.makeTimedObjectFromConfig(simConfigDimVisConfig);
      });

    this.taskTimeoutDuration$ = this.netSpeedService.speed$.pipe(
      map((result: ISpeedTestResult) => {
        /**
         * Average asset size is 20, size / speed = time spendt.
         * For super fast internet, give some time for processing and
         * possible fetching of other parts or sub-components (min 10 seconds).
         */
        const actualDuration: number = (20 / result.speed) * 1000;
        const duration: number = Math.max(actualDuration, 10000);
        console.debug(
          'TASK TIMEOUT IN SECONDS (used | actual): ',
          duration / 1000,
          ' | ',
          actualDuration / 1000
        );

        // Tell the user to be patient if slow internet speeds are detected.
        if (duration / 1000 > 60) {
          this.notification.showMessage(
            'Slow internet speeds detected. Please be patient.',
            10000
          );
        }
        return duration;
      }),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    this.tasks.change$
      .pipe(
        takeUntil(this.destroy$),
        filter((change: IListChange) => change.type === ListChange.Add)
      )
      .subscribe((_: any) => {
        this.startTaskHandler();
      });

    merge(this.simConfigService.fieldUpdate$, this.fieldUpdates$)
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterUndefinedAndNull())
      .subscribe((s: FieldUpdate) => {
        this.makeTask(s.fieldId, s);
      });

    AssetStoreService.onAssetLoadedWithID<any>(AssetIDs.SimconfigViewRobot)
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe(() => {
        this.updateRobotToSelectedConfiguration();
      });

    this.destroy$.pipe(take(1)).subscribe(() => {
      this.stopTaskHandler();
      this.tasks.clear();
    });

    this.hasTasks$ = this.tasks.change$.pipe(map(() => this.tasks.size() > 0));
  }

  makeTask(fieldID: string, data?: any, relatedDimVizID?: string): void {
    for (const info of simConfigTasks) {
      let hasID = false;
      for (const id of info.ids) {
        if (id === fieldID) {
          hasID = true;
        }
      }

      if (hasID) {
        const task = new info.config.type(
          this.threeID,
          this.injector,
          this.destroy$
        );
        task.init(
          data ? data : info.config.data,
          relatedDimVizID ? relatedDimVizID : info.config.relatedDimVizID
        );
        this.tasks.add(task);

        if (info.config.additionalTasks) {
          for (const config of info.config.additionalTasks) {
            const addTask = new config.type(
              this.threeID,
              this.injector,
              this.destroy$
            );
            addTask.init(config.data, config.relatedDimVizID);
            this.tasks.add(addTask);
          }
        }
      }
    }
  }

  startTaskHandler(): void {
    if (!this.handlerID) {
      this.handlerID = setTimeout(this.checkTaskQueue.bind(this), 20);
    }
    this.projectService.disableRCPicker$.next(true);
  }

  stopTaskHandler(): void {
    if (this.handlerID) {
      clearTimeout(this.handlerID);
      this.handlerID = undefined;
    }
    this.projectService.disableRCPicker$.next(false);
  }

  reportRobotUpdated(): void {
    this.reportSize = true;
    this.robotUpdated = true;
    this.render();
    this.onFinishedHandling$.next();
    this.firstRobotUpdate = false;
  }

  hideProcessingOverlay(): void {
    setTimeout(() => {
      if (this.poService.isLoading()) {
        this.poService.deactivateOverlay();
      }
    }, 100);
  }

  checkTaskQueue(): void {
    if (this.reportSize) {
      this.reportSize = false;
    }

    if (this.firstTotalNrSetupTasks === 0) {
      this.firstTotalNrSetupTasks = this.tasks.size();
    }

    if (this.tasks.size() > 0) {
      this.handleTask()
        .catch((reason) => {
          if (reason instanceof TimeoutError) {
            console.error('Task timed out');
          }
        })
        .finally(() => {
          // Update progress
          if (this.firstRobotUpdate) {
            this.progress$.next(
              ((this.firstTotalNrSetupTasks - this.tasks.size()) /
                this.firstTotalNrSetupTasks) *
                100
            );
          }
          if (this.tasks.size() > 0) {
            // More tasks, continue processing
            this.handlerID = setTimeout(this.checkTaskQueue.bind(this), 20);
          } else {
            // No more tasks to handle, stop handler
            this.stopTaskHandler();

            this.reportRobotUpdated();
            this.hideProcessingOverlay();
          }
        });
    }
  }

  handleTask(): Promise<void> {
    const task = this.tasks.shift();

    let startTime: number;
    return firstValueFrom(
      this.taskTimeoutDuration$.pipe(
        switchMap((duration) => {
          startTime = new Date().getTime();
          task.attempts++;
          return from(task.run()).pipe(
            timeout({
              first: !duration ? 10000 : duration,
            })
          );
        })
      )
    )
      .then(() => {
        const duration = (new Date().getTime() - startTime) / 1000;
        if (duration >= 1 || this.verboseTaskReporting) {
          const taskName = (task.constructor as any)[taskNameSymbol];
          console.debug(
            `Task "${
              taskName || task.constructor.name
            }" used ${duration} seconds.`
          );
        }
      })
      .catch((reason) => {
        if (reason instanceof TimeoutError) {
          const taskName = (task.constructor as any)[taskNameSymbol];

          // Add back the task to retry.
          if (task.attempts <= 2) {
            console.debug(
              `Rescheduling ${
                taskName || task.constructor.name
              } to retry. Attempts: ${task.attempts}`
            );
            this.tasks.add(task);
          } else {
            if (this.shouldReportTimeoutErrors || this.verboseTaskReporting) {
              this.shouldReportTimeoutErrors = false;
              this.notification.showError(
                `${
                  this.firstRobotUpdate ? 'Loading parts' : 'Updating parts'
                } timed out. Robot might be incomplete. Please try again later.`
              );
              // Can only report to the user every 30 seconds.
              setTimeout(
                () => (this.shouldReportTimeoutErrors = true),
                30 * 1000
              );
            }
            console.warn(
              `Giving up on task "${taskName}". Attempts: ${task.attempts}`
            );
            throw reason;
          }
        } else {
          console.error('Task failed: ', task);
        }
      });
  }

  updateRobotToSelectedConfiguration(): void {
    // Go through interesting fields and filter which to update or not.
    const idsToEmit = [];
    const idsToOmit = [];
    for (const fieldID of Object.values(SimConfigFieldIds)) {
      const field = this.simConfigService.getFieldForThreeView(
        fieldID,
        this.threeID
      );

      // Filter based on related fields
      for (const [key, obj] of updateRelatedFields) {
        if (fieldID === key && field) {
          const value = field.getValue();
          const ignore = !obj.predicate(value);
          if (ignore) {
            for (const f of obj.fieldIds) {
              idsToOmit.push(f);
            }
          }
        }
      }

      // Field ignored, skip it.
      if (idsToOmit.includes(fieldID)) {
        continue;
      }

      idsToEmit.push(fieldID);
    }

    // Emit events
    for (const fieldID of idsToEmit) {
      const field = this.simConfigService.getFieldForThreeView(
        fieldID,
        this.threeID
      );
      if (field) {
        this.fieldUpdates$.next({
          action: SimulationUpdateAction.FIELD_UPDATE,
          fieldName: field.text.name,
          fieldId: field.id,
          prevVal: field.getValue(),
          newVal: field.getValue(),
        } as FieldUpdate);
      }
    }
  }

  /* ---------------------------- Utility functions --------------------------- */

  private render() {
    RenderTrigger.render$.next(this.threeID);
  }

  private makeTimedObjectFromConfig(configs: ITimedObjectConfig[]) {
    for (const config of configs) {
      const points = this.toService.resolveConfigStartEnd(config);
      const dimVis = new DimensionVisualizer(
        config.id,
        this.threeID,
        config,
        points.start,
        points.end
      );
      this.toService.registerTimedObject(dimVis);
    }
  }
}
