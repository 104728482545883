import { LabelOrientation } from '../enums/label-orientation';
import { LabelsOutFreeDirection } from '../enums/labels-out-free-direction';

interface IFreeDirectionRotation {
  direction: string;
  rotate: number;
}

interface ILabelOrientationRotation {
  lo: number;
  freeDirections: IFreeDirectionRotation[];
}

interface IRotationConfig {
  rotation: boolean;
  labelOrientations: ILabelOrientationRotation[];
}

/**
 * Label orientations
 * Front 0 - 0
 * Right 1 - 90
 * Back 2 - 180
 * Left 3 - 270
 */

/**
 * @param rotated box is rotated or not
 * @param lo label orientation of box
 * @param directions free world directions of box
 * @returns rotation to rotate box. Undefined if it should not rotate
 */
export const getRotation = (
  rotated: boolean,
  lo: LabelOrientation,
  directions: string[]
) => {
  return rotationConfig
    .find((f1: IRotationConfig) => f1.rotation === rotated)
    ?.labelOrientations.find((f2: ILabelOrientationRotation) => f2.lo === lo)
    ?.freeDirections.find((f3: IFreeDirectionRotation) =>
      directions.includes(f3.direction)
    )?.rotate;
};

export const rotationConfig: IRotationConfig[] = [
  {
    rotation: false,
    labelOrientations: [
      {
        lo: 0, // Front
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[0], // 180
            rotate: 180,
          },
          {
            direction: LabelsOutFreeDirection[1], // 0
            rotate: 0,
          },
        ],
      },
      {
        lo: 180, // Back
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[0],
            rotate: 0,
          },
          {
            direction: LabelsOutFreeDirection[1],
            rotate: 180,
          },
        ],
      },
      {
        lo: 90, // Right
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 180,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 0,
          },
        ],
      },
      {
        lo: -90, // Left
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 0,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 180,
          },
        ],
      },
    ],
  },
  {
    rotation: true,
    labelOrientations: [
      {
        lo: 0, // Front
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[0],
            rotate: 270,
          },
          {
            direction: LabelsOutFreeDirection[1],
            rotate: 90,
          },
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 270,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 90,
          },
        ],
      },
      {
        lo: 180, // Back
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[0],
            rotate: 90,
          },
          {
            direction: LabelsOutFreeDirection[1],
            rotate: 270,
          },
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 90,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 270,
          },
        ],
      },
      {
        lo: 90, // Right
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 270,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 90,
          },
        ],
      },
      {
        lo: -90, // Left
        freeDirections: [
          {
            direction: LabelsOutFreeDirection[2],
            rotate: 90,
          },
          {
            direction: LabelsOutFreeDirection[3],
            rotate: 270,
          },
        ],
      },
    ],
  },
];
