import { Component, Input } from '@angular/core';
import { IGlobalNotification } from '../../services/notification.service';
import { marked } from 'marked';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent {
  @Input() notification: IGlobalNotification;

  protected readonly marked = marked;

  close() {
    this.notification = null;
  }
}
