import { IPallyFileType } from './pally-file-type';
import * as THREE from 'three';
import { ObjectUtils } from 'src/app/utils/object';
import { defaultScene } from '../config/default/default-scene';
import { defaultStrategy } from '../config/default/default-strategy';

enum status {
  RECEIVED,
  QUEUED,
  EXECUTING,
  FINISHED,
  ABORTED,
}

export interface IQuaternion {
  x: number;
  y: number;
  z: number;
  w: number;
}

interface Placetarget {
  name: string;
  lip: number;
  corner_base_pose: {
    position: THREE.Vector3;
    orientation: THREE.Quaternion | IQuaternion;
  };
}

interface Conveyor {
  type: string;
  label?: string; // If missing, grab from hw_types in the DB!
  custom_description: {
    name: string;
    sensors: {
      products: number[];
      product_priority: number;
    };
    dimension: THREE.Vector3;
    velocity: number;
    pub_rate: number;
    guide_width: number;
    guide_left: boolean;
    add_at_end: boolean;
  };
  box_corner_pose: {
    position: THREE.Vector3;
    orientation: THREE.Quaternion | IQuaternion;
  };
}

export interface RPY {
  r: number;
  p: number;
  y: number;
}

export function getDefaultScene() {
  return ObjectUtils.cloneObject(defaultScene) as ISceneApiFileType;
}

export function getDefaultStrategy() {
  return ObjectUtils.cloneObject(defaultStrategy) as IStrategyApiFileType;
}

export interface ISceneApiFileType {
  // Id must remain optional due to retrocompatibility.
  id?: string;
  robot_mounting_height: {
    position: THREE.Vector3;
    rotation: RPY;
  };
  favourite?: boolean;
  theme: string;
  conveyors: Conveyor[];
  robot: {
    // name: string;
    type: string;
    label?: string; // If missing, grab from hw_types in the DB!
    frame: {
      type: string;
      label?: string; // If missing, grab from hw_types in the DB!
    };
    lift_kit: {
      type: string;
      label?: string; // If missing, grab from hw_types in the DB!
      max_stroke: number;
    };
    gripper: {
      type: string;
      label?: string; // If missing, grab from hw_types in the DB!
      custom_collision_box: {
        width: number;
        length: number;
        height: number;
        position: THREE.Vector3;
      };
      custom_offset_pose: {
        position: THREE.Vector3;
        rotation: RPY;
      };
    };
    offset_bracket: {
      offset: THREE.Vector3;
      collision_object: {
        width: number;
        length: number;
        height: number;
        position: THREE.Vector3;
      };
    };
  };
  place_targets: Placetarget[];
  image?: any;
}

export interface IStrategyApiFileType {
  safety_preset: string;
  software: {
    polyscope_version: string;
    pally_version: string;
  };
  favourite?: boolean;
  auto_sim: boolean;
  smart_acceleration: string;
  gripper_optimization: string;
  above_pick_position_mm: number;
  smart_exits: {
    left_pallet: {
      x: {
        from: number;
        to: number;
      };
      y: {
        from: number;
        to: number;
      };
    };
    right_pallet: {
      x: {
        from: number;
        to: number;
      };
      y: {
        from: number;
        to: number;
      };
    };
  };
  smart_exits_override?: boolean;

  max_speed: number | string;
  max_acceleration: number | string;
  number_of_pallets: number;
  high_approach_boost_percentage: number;
  approach_distance: number | string;
  fixed_approach: boolean;
  box_free_height: number;
  waiting_position: {
    position: THREE.Vector3;
    orientation: THREE.Quaternion | IQuaternion;
  };
  lifting_column: {
    dynamic_positioning: number;
  };
}

export interface ISimulationApiFileType {
  default_sim_config_name?: string;
  readonly id?: string;
  status?: status;
  status_message?: string;
  pattern?: IPallyFileType;
  scene: ISceneApiFileType;
  strategy: IStrategyApiFileType;
  dependencies?: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
}

export interface ISimulationApiEditedData {
  name: string;
  editedScene: ISceneApiFileType;
  editedStrategy: IStrategyApiFileType;
  editedPattern: IPallyFileType;
}
