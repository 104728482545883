import { LabelOrientation } from '../enums/label-orientation';
import { IBox } from '../types/box';
import { defaultBox } from '../config/default/default-box';
import { BehaviorSubject } from 'rxjs';
import { IBoxUpdate } from '../types/box-update';
import { UpdateAction } from '../enums/update-action';
import { ObjectUtils } from 'src/app/utils/object';
import { GripperOrientation } from '../enums/gripper-orientation';
import { BoxRotation } from '../enums/box-rotation';

export class Box implements IBox {
  boxPadding: number;
  dimensions: {
    width: number;
    height: number;
    length: number;
  };
  weight: number;
  labelOrientations: LabelOrientation[];
  rotation: BoxRotation[];
  position: {
    x: number; // Box center
    y: number; // Box center
    z: number; // Box center
  };
  gripper: {
    enforcedOrientation?: GripperOrientation[];
    stopMultigrip?: boolean;
  };

  update$ = new BehaviorSubject<IBoxUpdate>(null);

  constructor(box?: IBox) {
    const b = box ? box : ObjectUtils.cloneObject(defaultBox); // Copy to make unique

    this.boxPadding = b.boxPadding;
    this.dimensions = b.dimensions;
    this.position = b.position;
    this.weight = b.weight;
    this.gripper = b.gripper;

    this.setStopMultigrip(b.gripper.stopMultigrip);
    this.setRotation(b.rotation);
    this.setLabelOrientations(b.labelOrientations);
  }

  update(updateAction: UpdateAction = UpdateAction.DEFAULT_BOX_UPDATE) {
    this.update$.next({
      updateAction: updateAction,
    });
  }

  /**
   * @param stop 0 = true, 1 = false
   */
  setStopMultigrip(stop: boolean) {
    this.gripper.stopMultigrip = stop;
  }

  /**
   * @param orientations: number[]
   */
  setEnforcedOrientations(orientations: number[]) {
    this.gripper.enforcedOrientation = orientations;
    this.gripper.enforcedOrientation.sort((a, b) => a - b);
  }

  /**
   * @param orientation: number
   */
  setEnforcedOrientation(orientation: number, select: boolean) {
    if (!this.getEnforcedOrientations().includes(orientation) && select) {
      this.gripper.enforcedOrientation.push(orientation);
    }

    if (!select) {
      this.gripper.enforcedOrientation =
        this.gripper.enforcedOrientation.filter(
          (go: GripperOrientation) => go !== orientation
        );
    }

    this.gripper.enforcedOrientation.sort((a, b) => a - b);
  }

  getEnforcedOrientations(): GripperOrientation[] {
    return this.gripper.enforcedOrientation;
  }

  /**
   * @param padding: number
   */
  setBoxPadding(padding: number) {
    this.boxPadding = padding;
  }

  getDimensions(): Box['dimensions'] {
    return this.dimensions;
  }

  /**
   * @param dimensions: width: number, length: number, height: number
   */
  setDimensions(dimensions: { width: number; length: number; height: number }) {
    if (!dimensions.height) {
      dimensions.height = this.dimensions.height;
    }
    this.dimensions = dimensions;
  }

  /**
   * @param labelOrientaion: LabelOrientaion[]
   */
  setLabelOrientations(labelOrientaions: LabelOrientation[]) {
    this.labelOrientations = labelOrientaions;
  }

  /**
   * @param rotations: number[]
   */
  setRotation(rotations: BoxRotation[]) {
    this.rotation = rotations.sort((a, b) => a - b);
  }

  /**
   * @param x: number
   */
  setX(x: number) {
    this.position.x = x;
  }

  /**
   * @param y: number
   */
  setY(y: number) {
    this.position.y = y;
  }

  /**
   * @param z: number
   */
  setZ(z: number) {
    this.position.z = z;
  }

  getPosition(): Box['position'] {
    return this.position;
  }

  /**
   * @param position: x: number, y: number, x: number
   */
  setPosition(position: { x: number; y: number; z: number }) {
    this.position = position;
  }

  /**
   * @param weight: number
   */
  setWeight(weight: number) {
    this.weight = weight;
  }
}
