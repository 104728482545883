import { AltLayout } from '../../enums/alt-layout';
import { LayerType } from '../../enums/layer-type';
import { Unit } from '../../enums/unit';
import { IPallyFileType, IPallyLayer } from '../../types/pally-file-type';
import { settings } from '../application-settings';

/**
 * Only used fix errors by validator. Not used by PPB models!!
 * */
export const defaultPallyFileLayer: IPallyLayer = {
  name: 'Default layer name',
  class: LayerType.LAYER,
};

/**
 * Only used fix errors by validator. Not used by PPB models!!
 * */
export const defaultPallyFileType: IPallyFileType = {
  id: '',
  name: '',
  description: '',
  dimensions: {
    length: 1200,
    width: 800,
    height: 1000,
    palletHeight: 144,
  },
  productDimensions: {
    width: 190,
    height: 200,
    length: 290,
    weight: 2500,
  },
  maxGrip: 8,
  maxGripAuto: true,
  labelOrientation: null,
  labelDirection: 'optimized',
  layerTypes: [],
  layers: [],
  guiSettings: {
    PPB_VERSION_NO: '',
    boxPadding: 0,
    units: Unit.METRIC,
    overhangSides: 0,
    overhangEnds: 0,
    altLayout: AltLayout.MIRROR,
    stackingMethod: settings.defaultStackingMethod,
  },
  dateModified: new Date().toISOString(),
};
