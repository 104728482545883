<ng-container *ngIf="infoData$ | async as infoData">
  <div class="info-container">
    <div class="content" [ngStyle]="infoData.image ? {} : { display: 'flex' }">
      <ng-container>
        <div class="left" *ngIf="infoData.image">
          <img [src]="infoData.image.url" [alt]="infoData.image.title" />
        </div>
        <div
          class="right"
          [ngStyle]="infoData.image ? {} : { 'padding-left': '16px' }"
        >
          <h3>Learn about</h3>
          <h2>{{ infoData.title }}</h2>
          <p [innerHTML]="infoData.text"></p>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="bottomBar"
    [ngStyle]="infoData.learnMoreLink ? {} : { 'grid-template-columns': '1fr' }"
  >
    <button
      *ngIf="infoData.learnMoreLink"
      (click)="didClickLearnMore(infoData.learnMoreLink)"
    >
      <mat-icon svgIcon="info"></mat-icon>
      Learn more
    </button>
    <button (click)="didClickClose()">
      {{ infoData?.actionButton1Content || 'Continue' }}
    </button>
  </div>
</ng-container>
