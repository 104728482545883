import { first, Observable, shareReplay } from 'rxjs';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { Type } from 'src/app/utils/type';
import { URDFTypes } from 'src/app/utils/urdf-utils';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { ITimedObjectConfig } from '../../../types/simconf/timed-object-config';
import { TimedObject3D } from '../timed-3d-object';
import { UnitSystemPipe } from 'src/app/pipes/unit-system.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';

/* eslint-disable max-len */

export class DimensionVisualizer extends TimedObject3D {
  duration: number;
  value: number | string;
  start: THREE.Vector3 | URDFTypes;
  end: THREE.Vector3 | URDFTypes;
  offset: {
    amount: number;
    axis: string;
  };
  mayorAxis: string;
  label: {
    offset: number;
    axis: 'x' | 'y' | 'z';
  };
  readonly color = '#E03131';
  readonly endsColor = '#555D71';

  remainingAxis: string;
  mainLine: THREE.Line;
  mainLinePoints: { start: THREE.Vector3; end: THREE.Vector3 };

  font$: Observable<Font>;

  config: ITimedObjectConfig;

  /**
   * DistanceVisualizer
   * @param id - string - Identifier for this visualizer.
   * @param start - THREE.Vector3 | URDFTypes - Start point.
   * @param end - THREE.Vector3 | URDFTypes - End point.
   * @param value - number | string - Value to be displayed.
   * @param mayorAxis - 'x' | 'y' | 'z' - Specifies which axis the dimension is in. **Optional!**. Without a mayor axis, the dimension goes point to point.
   * @param offset.amount - number - Offset in the direction specified by "offsetAxis".
   * @param offset.axis - 'x' | 'y' | 'z' - Specifies which axis to offset on.
   */
  constructor(
    id: string,
    threeID: string,
    config: ITimedObjectConfig,
    start: THREE.Vector3 | URDFTypes,
    end: THREE.Vector3 | URDFTypes
  ) {
    // ID, ThreeView id, trigger rendering, onTimeUse
    super(id, threeID, true, false);
    this.config = config;

    this.name = 'DimVis-' + this.ID;
    this.duration = config.data.duration;
    this.start = start;
    this.end = end;
    this.value = config.data.value;
    this.mayorAxis = config.data.mayorAxis;
    if (Type.isDefined(config.data.offset)) {
      this.offset = config.data.offset;
    } else if (Type.isDefined(this.mayorAxis)) {
      this.offset = {
        amount: 0,
        axis: this.calcBestOffsetAndRemainingAxis().offset,
      };
    }
    this.label = Type.isDefined(config.data.label)
      ? config.data.label
      : {
          offset: 0.1,
          axis: this.mayorAxis !== 'x' ? 'x' : 'y',
        };

    this.font$ = AssetStoreService.onAssetLoadedWithID<Font>(
      'helvetiker_font'
    ).pipe(first(), shareReplay({ bufferSize: 1, refCount: true }));
  }

  getStart(): THREE.Vector3 {
    const start = new THREE.Vector3();
    if (this.start instanceof THREE.Vector3) {
      start.copy(this.start);
    } else if (!this.config.notGlobal) {
      (this.start as THREE.Object3D).getWorldPosition(start);
    } else if (this.config.notGlobal) {
      start.copy(this.start.position);
    }
    return start;
  }

  getEnd(): THREE.Vector3 {
    const end = new THREE.Vector3();
    if (this.end instanceof THREE.Vector3) {
      end.copy(this.end);
    } else if (!this.config.notGlobal) {
      (this.end as THREE.Object3D).getWorldPosition(end);
    } else if (this.config.notGlobal) {
      end.copy(this.end.position);
    }
    return end;
  }

  getConfigValue(): unknown {
    return this.config.data.value;
  }

  isActive(): boolean {
    return Type.isDefined(this.timerID);
  }

  setStart(start: THREE.Vector3 | URDFTypes): void {
    this.start = start;
    this.update();
  }

  setEnd(end: THREE.Vector3 | URDFTypes): void {
    this.end = end;
    this.update();
  }

  setValue(value: number | string): void {
    this.value = value;
    this.update();
  }

  make(): void {
    if (
      Type.isDefined(this.mayorAxis, this.offset) &&
      this.mayorAxis === this.offset.axis
    ) {
      // TODO: HANDLE THIS! DIMENTION AXIS CAN'T BE THE SAME AS OFFSET AXIS!
    }

    if (Type.isDefined(this.mayorAxis, this.offset)) {
      this.remainingAxis = this.calcRemainingAxis(
        this.mayorAxis,
        this.offset.axis
      );
    }

    this.mainLinePoints = this.getMayorizedPoints(
      this.getStart().clone(),
      this.getEnd().clone(),
      this.remainingAxis,
      this.offset
    );

    this.mainLine = ThreeUtils.makeLine(
      this.mainLinePoints.start,
      this.mainLinePoints.end,
      this.color,
      { linewidth: 3, dashed: true }
    );
    this.add(this.mainLine);

    if (Type.isDefined(this.offset) && this.offset.amount !== 0) {
      const startLineStart = this.getStart().clone();
      const startLineEnd = this.mainLinePoints.start.clone();
      this.add(
        ThreeUtils.makeLine(startLineStart, startLineEnd, this.endsColor, {
          linewidth: 2,
        })
      );
    }

    const endLinesRemainingAxisStart = this.mainLinePoints.end.clone();
    const endLinesRemainingAxisEnd = this.mainLinePoints.end.clone();
    if (Type.isDefined(this.mayorAxis, this.remainingAxis)) {
      if (
        this.getStart()[this.remainingAxis] !==
        this.getEnd()[this.remainingAxis]
      ) {
        endLinesRemainingAxisEnd[this.remainingAxis] =
          this.getEnd()[this.remainingAxis];
        this.add(
          ThreeUtils.makeLine(
            endLinesRemainingAxisStart,
            endLinesRemainingAxisEnd,
            this.endsColor,
            { linewidth: 2 }
          )
        );
      }
    }

    if (Type.isDefined(this.mayorAxis, this.offset)) {
      if (
        endLinesRemainingAxisEnd[this.offset.axis] !==
        this.getEnd()[this.offset.axis]
      ) {
        const endLineOffsetStart = endLinesRemainingAxisEnd.clone();
        const endLinesOffsetEnd = this.getEnd().clone();
        this.add(
          ThreeUtils.makeLine(
            endLineOffsetStart,
            endLinesOffsetEnd,
            this.endsColor,
            { linewidth: 2 }
          )
        );
      }
    } else if (!Type.isDefined(this.mayorAxis) && Type.isDefined(this.offset)) {
      // No mayor axis (goes direct point to point), with offset axis!
      if (
        this.mainLinePoints.end[this.offset.axis] !==
        this.getEnd()[this.offset.axis]
      ) {
        const endLineOffsetStart = this.mainLinePoints.end.clone();
        const endLinesOffsetEnd = this.getEnd().clone();
        this.add(
          ThreeUtils.makeLine(
            endLineOffsetStart,
            endLinesOffsetEnd,
            this.endsColor,
            { linewidth: 2 }
          )
        );
      }
    }

    if (
      Type.isDefined(this.offset) &&
      endLinesRemainingAxisEnd[this.offset.axis] !==
        this.getEnd()[this.offset.axis]
    ) {
      const endLineOffsetStart = endLinesRemainingAxisEnd.clone();
      const endLinesOffsetEnd = this.getEnd().clone();
      this.add(
        ThreeUtils.makeLine(
          endLineOffsetStart,
          endLinesOffsetEnd,
          this.endsColor,
          { linewidth: 2 }
        )
      );
    }

    if (typeof this.value !== 'undefined') {
      this.makeLabel();
    }
  }

  makeLabel(): void {
    const possibleLabelRotations = [0, 90, 180, 270];
    const labelParent = new THREE.Object3D();
    // Set to middle of line
    labelParent.position.set(
      this.mainLinePoints.start.x +
        (this.mainLinePoints.end.x - this.mainLinePoints.start.x) / 2,
      this.mainLinePoints.start.y +
        (this.mainLinePoints.end.y - this.mainLinePoints.start.y) / 2,
      this.mainLinePoints.start.z +
        (this.mainLinePoints.end.z - this.mainLinePoints.start.z) / 2
    );
    this.add(labelParent);

    if (this.getConfigValue() === 'dist') {
      const diff = new THREE.Vector3().copy(this.getEnd()).sub(this.getStart());
      this.value = Math.abs(diff[this.mayorAxis]).toFixed(3);
    }

    if (typeof this.value === 'number') {
      this.value = Math.abs(this.value); // Always positive
    }

    this.font$.subscribe((font) => {
      const labelgeo = new TextGeometry(
        `${new UnitSystemPipe(new LocalStorageService()).transform(
          this.value + ' m'
        )}`,
        {
          font,
          size: 0.05,
          height: 0.01,
          curveSegments: 12,
          bevelEnabled: false,
        }
      );
      const labelmat = new THREE.MeshLambertMaterial({ color: this.color });
      const label = new THREE.Mesh(labelgeo, labelmat);
      if (this.config.notGlobal && this.config.data.label.xRot) {
        label.rotateOnAxis(
          new THREE.Vector3(1, 0, 0),
          MathUtils.degToRad(this.config.data.label.xRot)
        );
      }
      labelParent.add(label);

      // Rotate until free.
      for (const rot of possibleLabelRotations) {
        labelParent.rotation.y = MathUtils.degToRad(rot);

        label.position[this.label.axis] = this.label.offset; // Push away from the line
        labelgeo.computeBoundingBox();
        const size = new THREE.Vector3();
        labelgeo.boundingBox.getSize(size);
        label.position.y += -size.y / 2;

        if (this.label.axis === 'y') {
          label.position.x += -size.x / 2;
        }
        if (this.label.offset < 0) {
          label.position[this.label.axis] -= size[this.label.axis];
        }

        // This rotation works, keep it.
        if (
          !label.geometry.boundingBox.intersectsBox(
            this.mainLine.geometry.boundingBox
          )
        ) {
          break;
        }
      }
    });
  }

  private getMayorizedPoints(
    start: THREE.Vector3,
    end: THREE.Vector3,
    remainingAxis: string,
    offset: {
      amount: number;
      axis: string;
    }
  ): { start: THREE.Vector3; end: THREE.Vector3 } {
    // Mayor not offset  not defined, ignore mayorization
    if (!Type.isDefined(this.mayorAxis) && !Type.isDefined(offset)) {
      return { start: start, end: end };
    }

    if (Type.isDefined(remainingAxis)) {
      // Remaining axis needs to be frozen
      end[remainingAxis] = start[remainingAxis];
    }

    if (offset.amount !== 0) {
      // Add offset on start side
      start[offset.axis] += offset.amount;
    }

    if (
      offset.amount !== 0 &&
      !Type.isDefined(this.mayorAxis) &&
      Type.isDefined(offset)
    ) {
      // Add offset on end side
      end[offset.axis] += offset.amount;
    }

    if (Type.isDefined(this.mayorAxis)) {
      // Make dimension line follow mayor axis.
      end[offset.axis] = start[offset.axis];
    }

    return {
      start: start,
      end: end,
    };
  }

  private calcRemainingAxis(mayorAxis: string, offsetAxis: string): string {
    if (
      (mayorAxis === 'y' && offsetAxis === 'z') ||
      (mayorAxis === 'z' && offsetAxis === 'y')
    ) {
      return 'x';
    }
    if (
      (mayorAxis === 'x' && offsetAxis === 'z') ||
      (mayorAxis === 'z' && offsetAxis === 'x')
    ) {
      return 'y';
    }
    if (
      (mayorAxis === 'x' && offsetAxis === 'y') ||
      (mayorAxis === 'y' && offsetAxis === 'x')
    ) {
      return 'z';
    }

    // For safety, default to X-axis
    return 'x';
  }

  private calcBestOffsetAndRemainingAxis(): {
    offset: string;
    remaining: string;
  } {
    let axies = ['x', 'y', 'z'];
    axies = axies.filter((v: string) => v !== this.mayorAxis); // Ignore mayor axis.

    const axiesCross = new Map<string, number>();

    for (const axis of axies) {
      const offsetAxis = axis;
      const remainingAxis = this.calcRemainingAxis(this.mayorAxis, offsetAxis);

      const tPoints = this.getMayorizedPoints(
        this.getStart().clone(),
        this.getEnd().clone(),
        remainingAxis,
        {
          amount: 0,
          axis: offsetAxis,
        }
      );

      const tDiff = this.getEnd().clone().sub(tPoints.start.clone());

      const tMayor = new THREE.Vector3(); // Represents a vector along the mayor axis.
      tMayor[this.mayorAxis] = tDiff[this.mayorAxis];

      // Don't care if vectors are the same or mirrored, as long as it's one of them.
      axiesCross.set(
        axis,
        Math.abs(tMayor.cross(tPoints.end.sub(tPoints.start)).length())
      );
    }

    let diffAxis = axies[0];
    for (const [axis, cross] of axiesCross) {
      if (axis !== diffAxis && axiesCross[diffAxis] < cross) {
        diffAxis = axis;
      }
    }

    return {
      offset: diffAxis,
      remaining: this.calcRemainingAxis(this.mayorAxis, diffAxis),
    };
  }
}
