import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Field } from 'src/app/models_new/classes/field';
import { FieldType } from 'src/app/models_new/types/field-type';
import { BackofficeService } from 'src/app/services/api/backoffice.service';
import { Observable, map, shareReplay, skipWhile, take } from 'rxjs';
import { IHwSwType } from '../../../models_new/types/robot-config/hw-sw-type';

type AutocompleteOption = {
  label: string;
  id: string;
  value: string;
};
@Component({
  selector: 'app-edit-hardware',
  templateUrl: './edit-hardware.component.html',
  styleUrls: ['./edit-hardware.component.scss'],
})
export class EditHardwareComponent implements OnInit {
  hardware: IHwSwType;

  metadataFields: Field[];
  advancedFields: Field[] = [];
  orgShareField$: Observable<Field>;

  currency = 'EUR';
  currencyMap = {
    Euro: 'EUR',
    Dollar: 'USD',
    Pound: 'GBP',
    Nok: 'NOK',
  };

  constructor(
    public dialogRef: MatDialogRef<EditHardwareComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      hardware: IHwSwType;
      isBackoffice?: boolean;
    },
    private backOfficeApi: BackofficeService
  ) {}

  ngOnInit(): void {
    this.hardware = this.input.hardware;
    const currentOrgs = this.input.hardware.organization_hw_types.map(
      (entry) => {
        return {
          id: entry.id,
          label: entry.organization.name,
          value: entry.id,
        } as AutocompleteOption;
      }
    );
    this.orgShareField$ = this.backOfficeApi.getAllOrganizations().pipe(
      skipWhile((res) => !res),
      take(1),
      shareReplay({ bufferSize: 1, refCount: false }),
      map((orgs) => {
        if (!orgs) {
          return null;
        }
        const filteredOrgs = orgs.map((org) => {
          return {
            id: org.id,
            label: org.name,
            value: org.id,
          } as AutocompleteOption;
        });
        return new Field(
          FieldType.SELECT_MULTIPLE_OBJECT,
          false,
          currentOrgs,
          [],
          'organizations',
          filteredOrgs,
          null,
          { label: 'Accessible to', showProperty: 'label' },
          'organization-access'
        );
      })
    );

    this.metadataFields = [
      this.createField('Label', this.hardware.label, 'label', true),
      this.createField(
        'Description',
        this.optionalGetter(this.hardware.metadata.description, ''),
        'metadata.description'
      ),
      new Field(
        FieldType.SELECT_SINGLE,
        false,
        this.hardware.metadata.currency
          ? this.hardware.metadata.currency
          : 'Euro',
        [],
        'currency',
        ['Euro', 'Dollar', 'Pound', 'Nok'],
        null,
        { label: 'Currency', name: 'currency' },
        'metadata.currency'
      ),
      this.createField(
        'Price',
        this.optionalGetter(this.hardware.metadata.price, ''),
        'metadata.price',
        false,
        FieldType.NUMBER,
        'currency'
      ),
      this.createField(
        'Discount',
        this.optionalGetter(this.hardware.metadata.discount, ''),
        'metadata.discount',
        false,
        FieldType.NUMBER,
        'currency',
        '%'
      ),
      new Field(
        FieldType.SLIDETOGGLE,
        false,
        this.hardware.public,
        [],
        'public-toggle',
        null,
        null,
        { label: 'Publicly available' },
        'public'
      ),
    ];
    const metadata = this.optionalGetter(this.hardware.metadata, {});
    if (metadata.currency) {
      this.currency = this.currencyMap[metadata.currency];
    }
    if (this.hardware.metadata['pallet_offset_x']) {
      this.advancedFields.push(
        this.createField(
          'Pallet Orizontal Offset',
          this.hardware.metadata['pallet_offset_x'],
          'metadata.pallet_offset_x',
          false,
          FieldType.NUMBER,
          'mm'
        )
      );
      this.advancedFields.push(
        this.createField(
          'Pallet Vertical Offset',
          this.hardware.metadata['pallet_offset_z'] || 0,
          'metadata.pallet_offset_z',
          false,
          FieldType.NUMBER,
          'mm'
        )
      );
    }
  }

  public saveAndClose(orgsField?: Field) {
    const delta = {};
    let objRef = delta;
    const allFields: Field[] = [
      ...this.metadataFields,
      ...this.advancedFields,
      orgsField,
    ];
    for (const field of allFields) {
      const keys = field.id.split('.');
      const val = field.getValue();
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
          objRef[key] = val;
        } else {
          if (!delta[key]) {
            delta[key] = {};
          }
          objRef = delta[key];
        }
      }
      objRef = delta;
    }
    this.dialogRef.close(delta);
  }

  private createField(
    label: string,
    defaultVal: string,
    id?: string,
    required: boolean = false,
    type: FieldType = FieldType.TEXT,
    groupId?: string,
    suffix?: string
  ): Field {
    return new Field(
      type,
      required,
      defaultVal,
      [],
      groupId,
      null,
      null,
      { label: label, name: label.toLowerCase(), suffix: suffix },
      id ? id : label.toLowerCase()
    );
  }

  private optionalGetter(value: any, fallback: any): any {
    if (value) return value;
    else return fallback;
  }
}
