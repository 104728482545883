import { Injectable } from '@angular/core';
import { ApolloCache } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ISimulationApiFileType } from '../../models_new/types/simulation-api-file-type';
import { Type } from '../../utils/type';
import { IComponentUploadResult } from 'src/app/models_new/types/component-upload-result';
import { ClientApiService } from './client-api.service';
import { IApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private apollo: Apollo, private clientApi: ClientApiService) {}

  getCache(): ApolloCache<any> {
    return this.apollo.client.cache;
  }

  processParameters(properties: {
    [key: string]: { isString?: boolean; value: any };
  }): string {
    // eslint-disable-next-line prefer-const
    let str = '';
    const nrKeys = Object.keys(properties).length;
    let nr = 1;
    for (const key of Object.getOwnPropertyNames(properties)) {
      const isString =
        (!Type.isDefined(properties[key].isString) &&
          Type.isOfType(properties[key].value, 'string')) ||
        (Type.isDefined(properties[key].isString) && properties[key].isString);

      str = str.concat(
        `${key}: ${isString ? '"' : ''}${properties[key].value}${
          isString ? '"' : ''
        }${nr !== nrKeys ? ', ' : ''}`
      );
      nr++;
    }

    return str;
  }

  makeInsertMutationQuery(
    operation: string,
    properties: { [key: string]: { isString?: boolean; value: any } }
  ): any {
    const str = this.processParameters(properties);

    const m = gql`
    mutation insert {
      ${operation}(object: {${str}}) {
        id
      }
    }`;

    return m;
  }

  makeUpdateMutationQuery(
    operation: string,
    id: string,
    properties: { [key: string]: { isString?: boolean; value: any } },
    returnValues?: string
  ): any {
    const str = this.processParameters(properties);

    // Default return values to 'id' field if none are given.
    if (
      !Type.isDefined(returnValues) ||
      (Type.isDefined(returnValues) && returnValues === '')
    ) {
      returnValues = 'id';
    }

    const m = gql`
    mutation ${operation} {
      ${operation}(pk_columns: {id: "${id}"}, _set: {${str}}) {
        ${returnValues}
      }
    }`;

    return m;
  }

  getPublicPallydescriptionsSASToken(): Observable<string> {
    const q = gql`
      query getPublicModelsSas {
        getPublicModelsSas {
          SAS
        }
      }
    `;
    return this.clientApi
      .useClient('public')
      .query<{
        getPublicModelsSas: {
          SAS: string;
        };
      }>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data.data?.getPublicModelsSas.SAS;
        })
      );
  }

  getPallydescriptionsSASToken(): Observable<string> {
    const q = gql`
      query getSASToken {
        getModelsSas {
          SAS
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<{
        getModelsSas: {
          SAS: string;
        };
      }>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data.data?.getModelsSas.SAS;
        })
      );
  }

  sendComponentToGitHub(
    org_id: string,
    visual: string,
    collision: string,
    yaml: string,
    urdf: string,
    name: string,
    type: string
  ): Observable<IComponentUploadResult> {
    const q = gql`
      query UploadComponent(
        $dataCollection: ComponentUploadDataCollection!
        $name: String!
        $type: ComponentType!
        $organization_id: String!
      ) {
        componentUpload(
          dataCollection: $dataCollection
          type: $type
          name: $name
          organization_id: $organization_id
        ) {
          success
          reason
        }
      }
    `;

    const variables = {
      dataCollection: {
        collision: collision,
        urdf: urdf,
        visual: visual,
        yaml: yaml,
      },
      name: name,
      type: type.toUpperCase(),
      organization_id: org_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(
        map((data) => data.data.componentUpload as IComponentUploadResult)
      );
  }
}

export interface IStartSimulation {
  simulation_state: string;
}

export interface UpdateResponse {
  readonly id: string;
}

export interface IInsertSimulationResponse {
  readonly id: string;
}

export interface ISimulationResponse {
  simulation: IApiSimulation[];
}

export interface IPatternResponse {
  pattern: IPatternInfo[];
}

export interface IReportConfig {
  title: string;
  content: string;
  locked: boolean;
  order_index: number;
  contentful_title: string;
  contentful_content: string;
  contentful_children: {
    items: IReportConfig[];
  };
}

export interface IPatternInfo {
  readonly id: string;
  name: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  simulations?: {
    id: string;
    name: string;
    type: string;
  }[];
}

// Keep this interface!!
export interface IDefaultSimConfig {
  id: string;
  name: string;
  data: ISimulationApiFileType;
  vendor_ids?: {
    id: string;
    name: string;
  }[];
  solution_provider_id?: string;
  continents?: string[];
  regions?: string[];
  countries?: string[];
  liftkit_optimization?: boolean;
  description?: string;

  open_simulations_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

// Keep this interface!!
export interface IDefaultSimConfigResponse {
  default_sim_config: ISimulationApiFileType[];
}
