<div class="container">
  <app-generic-inventory-picker
    class="top"
    [pickerType]="type"
    [selectionAmount]="selectionAmount"
    [initialSelection]="input.selected"
    (didChangeSelection)="didChangeSelection($event)"
    (shouldClose)="close()"
  ></app-generic-inventory-picker>
  <div class="bottom">
    <button style="color: white" mat-button (click)="didClickClose()">
      {{ closeButtonText }}
    </button>
  </div>
</div>
