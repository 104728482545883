import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InMemoryCache, ApolloLink, ApolloClient } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { firstValueFrom } from 'rxjs';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Apollo, ApolloBase } from 'apollo-angular';
import { onError } from '@apollo/client/link/error';
import { Role } from 'src/app/models_new/types/role';
import { AuthService, User } from '@auth0/auth0-angular';

// Examples of use:
// NB: When using .subscribe, make sure to include param 'ws'.

//
// watchQuery:
// this.clientApi
// .getClient(Role.org_viewer)
// .watchQuery<any>({
//   query: q,
// })
// .valueChanges

// mutation:
// this.clientApi
// .getClient(Role.org_editor)
// .mutate<any>({
//   mutation: m,
//   variables: variables ? variables : null,
// })

// this.clientApi
// .useClient(Role.org_viewer, 'ws')
// .subscribe<any>({
// query: q,
// })

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        console.group('%cGraphQL error!', 'color: red; text-size: 14px;');
        console.error('Operation name: ' + operation.operationName);
        console.error(
          `[GraphQL error]: Message: "${message}", Location: ${locations}, Path: ${path}`
        );

        if (extensions && extensions.internal) {
          if (extensions.internal.request) {
            console.error(
              `[Request]:\n  - URL: "${extensions.internal.request.url}"\n  - Query: "${extensions.internal.request.body.request_query}"`
            );
          }
          if (extensions.internal.response) {
            console.error(
              `[Response]:\n  - Status: ${extensions.internal.response.status}\n  - Body: "${extensions.internal.response.body}"`
            );
          }
        }
        console.groupEnd();
      });
    }

    if (networkError) {
      console.group('%cNetwork error', 'color: red; text-size: 14px;');
      console.error('Operation name: ' + operation.operationName);
      console.error(networkError);
      console.groupEnd();
    }
  }
);

const clientNamer = (role: Role, type: 'http' | 'ws') => {
  return type + '_' + role;
};

@Injectable({
  providedIn: 'root',
})
export class ClientApiService {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private authService: AuthService
  ) {
    this.authService.user$.subscribe((u: User) => {
      if (u === null) {
        this.removeAllClients();
      }
    });
  }

  getClients(): string[] {
    const clients: string[] = Array.from(this.apollo['map'].keys());
    return clients;
  }

  /**
   * Gets the requested client by role and type.
   * Creates a new named client if not found.
   *
   * NB! When using .subscribe, make sure to include param 'ws' (webSocket)
   *
   * @param role Role
   * @param type 'http' | 'ws' Default: 'http'
   * @returns ApolloBase with the right x-hasura-role and auth headers
   */
  useClient(role: Role, type: 'http' | 'ws' = 'http'): ApolloBase<any> {
    // If not made yet. create a new client
    if (!this.getClients().includes(clientNamer(role, type))) {
      type === 'http' ? this.makeHttpClient(role) : this.makeWsClient(role);
    }

    return this.apollo.use(clientNamer(role, type));
  }
  /**
   * Gets the requested client by name.
   * Creates a new named client if not found.
   *
   * NB! When using .subscribe, make sure to include param 'ws' (webSocket)
   *
   * @param role Role
   * @param type 'http' | 'ws' Default: 'http'
   * @returns ApolloBase with the right x-hasura-role and auth headers
   */
  useClientWithName(
    name: string,
    role: Role,
    type: 'http' | 'ws' = 'http',
    extraHeaders?: any
  ): ApolloBase<any> {
    // If not made yet. create a new client
    if (!this.getClients().includes(name)) {
      type === 'http'
        ? this.makeHttpClient(role, name, extraHeaders)
        : this.makeWsClient(role, name, extraHeaders);
    }

    return this.apollo.use(name);
  }

  removeAllClients() {
    this.getClients().forEach((cl: string) => this.removeClient(cl));
  }

  removeClient(clientName: string): void {
    console.debug('Cleaning store and removing client: ' + clientName);

    // Reset client store and delete client
    this.apollo.use(clientName).client.stop();
    this.apollo.use(clientName).client.clearStore();
    this.apollo.use(clientName).client.resetStore();
    this.apollo.removeClient(clientName);
  }

  /**
   * @returns ApolloClient
   */
  private createNamedClient(
    link: ApolloLink,
    role: Role,
    type: 'http' | 'ws',
    name?: string
  ): ApolloClient<any> {
    if (!this.getClients().includes(name ? name : clientNamer(role, type))) {
      this.apollo.createNamed(name ? name : clientNamer(role, type), {
        link: link,
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                organization_by_pk: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
            Subscription: {
              fields: {
                scene: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                strategy: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
                robot_configuration: {
                  merge(existing, incoming) {
                    return incoming;
                  },
                },
              },
            },
          },
        }),
        connectToDevTools: true,
        defaultOptions: {
          watchQuery: {
            errorPolicy: 'all',
          },
          mutate: {
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
        },
      });
    }

    return this.apollo.use(name ? name : clientNamer(role, type)).client;
  }

  private async getHeaders(role: Role, extraHeaders?: Record<string, string>) {
    let context = {
      headers: {
        'x-hasura-role': role,
        Accept: 'charset=utf-8',
      } as Record<string, string>,
    };

    if (role !== 'public') {
      context.headers['Authorization'] =
        'Bearer ' +
        (await firstValueFrom(this.authService.getAccessTokenSilently()));
    }

    if (extraHeaders) {
      for (const [key, value] of Object.entries(extraHeaders)) {
        context.headers[key] = value;
      }
    }

    return context;
  }

  /**
   * @returns ApolloClient
   */
  makeHttpClient(
    role: Role,
    name?: string,
    extraHeaders?: Record<string, string>
  ): ApolloClient<any> {
    const basic = setContext(async (operation, context) => ({
      headers: (await this.getHeaders(role, extraHeaders)).headers,
    }));

    const http = ApolloLink.from([
      basic,
      errorLink,
      this.httpLink.create({ uri: environment.grapqlURL }),
    ]);

    return this.createNamedClient(http, role, 'http', name);
  }

  /**
   * @returns ApolloClient
   */
  makeWsClient(
    role: Role,
    name?: string,
    extraHeaders?: Record<string, string>
  ): ApolloClient<any> {
    const ws = new WebSocketLink({
      uri: environment.grapqlWebSocketURL,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: this.getHeaders(role, extraHeaders),
      },
    });

    const wsLink = ApolloLink.from([errorLink, ws]);

    return this.createNamedClient(wsLink, role, 'ws', name);
  }
}
