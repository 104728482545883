<ng-container *ngIf="ready">
  <div
    style="
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    "
  >
    <svg height="100%" width="100%" viewBox="0 0 120 120">
      <circle
        *ngFor="let segment of segments; let i = index"
        cx="60"
        cy="60"
        r="50"
        fill="transparent"
        stroke-width="10"
        [attr.stroke-dasharray]="getPerimeter(50)"
        [attr.stroke-dashoffset]="getOffset(50, i)"
        [attr.stroke]="getColor(i)"
      />
    </svg>

    <div class="donut-text" *ngIf="displayCounter">
      <svg viewBox="0 0 120 120">
        <text x="50%" y="50%" text-anchor="middle" class="upper">
          {{ itemsTotal }} items
        </text>
        <text
          x="50%"
          y="57%"
          text-anchor="middle"
          class="lower"
          font-family="Montserrat"
        >
          in total
        </text>
      </svg>
    </div>
  </div>
</ng-container>
