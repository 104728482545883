import { Box } from 'src/app/models_new/classes/box';
import { GripperOrientation } from 'src/app/models_new/enums/gripper-orientation';

/**
 * Created a new LayerType as defined in the Json-files
 */
export class LayerType {
  name: string;
  pattern: Pattern[];
  id: number;
}

/**
 * Creates a new Pattern that contain information about a box
 */
export class Pattern {
  w: number;
  l: number;
  x: number;
  y: number;
  r: number[];
  g: GripperOrientation[];
  stopMultigrip: boolean;
  direction: string;
  name?: string;
  height?: number;
  width?: number;
  index?: number;
  styleX?: number;
  styleY?: number;

  constructor(
    stopMultigrip: boolean,
    g: GripperOrientation[],
    width: number,
    length: number,
    x: number,
    y: number,
    rotation?: number,
    rotations?: number[]
  ) {
    this.w =
      rotations !== undefined
        ? rotations[0] === 0 || rotations[0] === 180
          ? width
          : length
        : width;
    this.l =
      rotations !== undefined
        ? rotations[0] === 0 || rotations[0] === 180
          ? length
          : width
        : length;
    this.x = x;
    this.y = y;
    this.r = rotations !== null ? rotations : [rotation];
    this.g = g;
    this.stopMultigrip = stopMultigrip;
  }
}

/**
 * Class used to contain information about a {@class Pattern}
 */
export class BoxPlacement {
  public finalId = -1;
  public dependentOn: number[] = Array<number>();
  public doubleGripCandidates: number[] = Array<number>();
  public patternWithOrigoInCorner: Pattern;

  constructor(public id: number, public box: Pattern) {
    this.patternWithOrigoInCorner = this.moveOriginToLowerLeftCorner(
      this.clonePattern(box)
    );
  }

  /**
   * Detects if a box is in the "shadow" of the current object.
   * Default behaviour is that the "shadow" is 1 box width to the right and 1 box height below
   * for the right pallet.
   * @param box Pattern to compare current object with
   * @param leftPallet Left or Right pallet to check for
   */
  public detectColision(box: Pattern, leftPallet?: boolean): boolean {
    let collision = false;
    const areaFactor = 1.0;
    let boxWithOrientation: Pattern;

    boxWithOrientation = this.moveOriginToLowerLeftCorner(
      this.clonePattern(box)
    );
    if (leftPallet) {
      if (
        this.patternWithOrigoInCorner.x -
          this.patternWithOrigoInCorner.w * areaFactor <
          boxWithOrientation.x + boxWithOrientation.w &&
        this.patternWithOrigoInCorner.x + this.patternWithOrigoInCorner.w >
          boxWithOrientation.x &&
        this.patternWithOrigoInCorner.y -
          this.patternWithOrigoInCorner.l * areaFactor <
          boxWithOrientation.y + boxWithOrientation.l &&
        this.patternWithOrigoInCorner.l + this.patternWithOrigoInCorner.y >
          boxWithOrientation.y
      ) {
        collision = true;
      }
    } else {
      if (
        this.patternWithOrigoInCorner.x <
          boxWithOrientation.x + boxWithOrientation.w &&
        this.patternWithOrigoInCorner.x +
          this.patternWithOrigoInCorner.w * (1 + areaFactor) >
          boxWithOrientation.x &&
        this.patternWithOrigoInCorner.y -
          this.patternWithOrigoInCorner.l * areaFactor <
          boxWithOrientation.y + boxWithOrientation.l &&
        this.patternWithOrigoInCorner.y + this.patternWithOrigoInCorner.l >
          boxWithOrientation.y
      ) {
        collision = true;
      }
    }

    return collision;
  }

  /**
   *  Converts `x` and `y` coordinates to be relative to the lower left corner, instead of center.
   * @param boxBefore Pattern to convert
   * @returns a `Pattern` with `x` and `y` cordinates relative to lower left corner of the `Pattern`
   */
  private moveOriginToLowerLeftCorner(boxBefore: Pattern): Pattern {
    boxBefore.x = boxBefore.x - boxBefore.w / 2;
    boxBefore.y = boxBefore.y - boxBefore.l / 2;
    return boxBefore;
  }

  /**
   * Clones a `Pattern` object
   * @param pattern Pattern to clone
   */
  private clonePattern(pattern: Pattern): Pattern {
    return new Pattern(
      pattern.stopMultigrip,
      pattern.g,
      pattern.w,
      pattern.l,
      pattern.x,
      pattern.y,
      null,
      pattern.r
    );
  }
}

/**
 * Class for optimizing the order to place the boxes on the pallet.
 */
export class BoxSorting {
  /**
   * Max allowed space between boxes for allowing double grip.
   */
  public alowedPadding = 4;

  private boxes: BoxPlacement[];
  private boxWidth = 0;
  private boxLength = 0;
  private leftPallet: boolean;

  /**
   * Creates an BoxSorting object based on a given box length and width. Optional parameter for selecting left pallet.
   * @param boxLength The length of the box
   * @param boxWidth The width of the box
   * @param leftPallet Selects which pallet to sort the placement for. Defaults to right Pallet.
   *
   */
  constructor(boxLength: number, boxWidth: number, leftPallet?: boolean) {
    this.boxLength = boxLength;
    this.boxWidth = boxWidth;
    this.leftPallet = leftPallet;
  }

  /**
   * Will start sorting the numbering of boxes.
   * @param layerType an Object with same structure as LayerType in the Json-files
   * @returns The same structure as input.
   */
  public sortLayerType(layerType: Pattern[]): Box[] {
    this.boxes = this.mapToBoxPlacement(layerType);
    this.detectDependencies();
    layerType = this.beginSorting();

    const newBoxes: Box[] = layerType.map((sortingBox: Pattern) => {
      const box = new Box();

      box.setDimensions({
        width: sortingBox.w,
        length: sortingBox.l,
        height: sortingBox.height || 0,
      });

      box.setRotation(sortingBox.r);
      box.setPosition({
        x: sortingBox.x,
        y: sortingBox.y,
        z: 0,
      });

      box.setEnforcedOrientations(sortingBox.g);
      box.setStopMultigrip(sortingBox.stopMultigrip);

      return box;
    });

    return newBoxes;
  }

  /**
   * Wraps a Pattern into a new class to manage all needed data for sorting
   * @param layerType as they are in the Json-file
   */
  private mapToBoxPlacement(layerType: Pattern[]): BoxPlacement[] {
    const boxes: BoxPlacement[] = [];
    for (let index = 0; index < layerType.length; index++) {
      const pattern = layerType[index];
      pattern.l = this.boxLength;
      pattern.w = this.boxWidth;
      boxes.push(new BoxPlacement(index + 1, layerType[index]));
    }
    return boxes;
  }

  /**
   * Updates `BoxPlacement.dependentOn` Array on all objects in `this.boxes`
   */
  private detectDependencies() {
    this.boxes.forEach((box) => {
      this.boxes.forEach((box2) => {
        if (box2 !== box && box.detectColision(box2.box, this.leftPallet)) {
          box.dependentOn.push(box2.id);
          this.findDoubleGripCandidates(box, box2);
        }
      });
    });
  }

  /**
   * Starts iterating through all items to sort them in the best order.
   * @returns A sorted list of Patterns
   */
  private beginSorting(): Pattern[] {
    let counter = 0;
    const sortedItems = Array<Pattern>();
    let item = this.getNextBox();
    while (item) {
      item.finalId = counter++;
      sortedItems.push(item.box);
      this.removeItemAsDependency(item);
      this.removeItemAsDoubleGripCandidate(item);

      item =
        item.doubleGripCandidates.length > 0
          ? this.getBoxById(item.doubleGripCandidates[0])
          : this.getNextBox();
    }
    return sortedItems;
  }

  /**
   * Finds the next box to place on the pallet
   */
  private getNextBox(): BoxPlacement {
    const candidates = new Array<BoxPlacement>();
    this.boxes.forEach((box) => {
      if (box.dependentOn.length === 0 && box.finalId < 0) {
        candidates.push(box);
      }
    });
    return this.prioritizeItems(candidates);
  }

  /**
   * Selects the best BoxPlacement to use next.
   * @param candidates Array of BoxPlacements to select from
   * @returns the best candidate
   */
  private prioritizeItems(candidates: BoxPlacement[]): BoxPlacement {
    let returnItem: BoxPlacement = null;

    candidates.forEach((box) => {
      const excludeList = new Array<number>();
      excludeList.push(box.id);
      const placementPossible = this.recursivePlacementCheck(box, excludeList);
      if (returnItem === null && placementPossible) {
        returnItem = box;
      } else if (placementPossible && returnItem.box.y > box.box.y) {
        returnItem = box;
      }
    });
    return returnItem;
  }

  /**
   * Recursivly identifies if a box has any double grippable neighbor boxes
   * in the same row or column (dependent on the orientation of the box). And if all boxes can be placed
   * without having any dependencies that they will block.
   * @param box - To check.
   * @param excludeList - Any item on this list will be ignored when checking for dependencies or double grip candidates.
   * @returns true if the box can be placed. false if the box row will block any other items.
   */
  private recursivePlacementCheck(
    box: BoxPlacement,
    excludeList: number[]
  ): boolean {
    let returnValue = box.doubleGripCandidates.length === 0;
    box.doubleGripCandidates.forEach((dgBoxId) => {
      if (!excludeList.includes(dgBoxId)) {
        const dgBox = this.getBoxById(dgBoxId);
        const dbGripResult = this.checkForDoubleGripPossible(
          dgBox,
          excludeList
        );
        if (dbGripResult > 0) {
          excludeList.push(dgBoxId);
          returnValue = this.recursivePlacementCheck(dgBox, excludeList);
        } else if (dbGripResult === 0) {
          if (
            dgBox.dependentOn.length === 1 &&
            dgBox.dependentOn[0] === box.id
          ) {
            returnValue = true;
          }
        }
      }
    });
    return returnValue;
  }

  /**
   * Identifies if two BoxPlacements are able to be double gripped.
   * It will update the `BoxPlacement.doubleGripCandidates` Array on the respective boxes
   * if double grip is possible.
   * The `allowedPadding` property in this class will determine the gap allowed between BoxPlacements
   * @param box - First BoxPlacement to check for orientation and distance/padding
   * @param box2 - Second BoxPlacement to compare
   */
  private findDoubleGripCandidates(box: BoxPlacement, box2: BoxPlacement) {
    let doubleGripPossible = false;
    box.box.r.forEach((rotation) => {
      if (box2.box.r.includes(rotation)) {
        if (
          (rotation === 0 || rotation === 180) &&
          Math.floor(box.box.x) === Math.floor(box2.box.x) &&
          Math.abs(box.box.y - box2.box.y) - box.box.l <= this.alowedPadding
        ) {
          doubleGripPossible = true;
        } else if (
          (rotation === 90 || rotation === 270) &&
          Math.floor(box.box.y) === Math.floor(box2.box.y) &&
          Math.abs(box2.box.x - box.box.x) - box.box.l <= this.alowedPadding
        ) {
          doubleGripPossible = true;
        }
      }
    });
    if (doubleGripPossible) {
      box.doubleGripCandidates.push(box2.id);
      box2.doubleGripCandidates.push(box.id);
    }
  }

  /**
   * Checks if a box has any double grip candidates, and if any of the candidates have any dependencies that
   * will be prevented to be placed at a later point.
   *
   * @param box - BoxPlacement to check
   * @param excludeList - Any item on this list will be ignored when checking for dependencies or double grip candidates.
   * @returns
   * - `-1 ` if a blocking dependency was discovered
   * - ` 0 ` if no double grip candidates was found
   * - ` BoxPlacement.Id ` if a non-blocking double grip candidate was found
   */
  private checkForDoubleGripPossible(
    box: BoxPlacement,
    excludeList: number[]
  ): number {
    let ret = 0;
    box.doubleGripCandidates.forEach((dgBoxId) => {
      if (!excludeList.includes(dgBoxId)) {
        const dgBox = this.getBoxById(dgBoxId);
        if (dgBox.dependentOn.length === 1 && dgBox.dependentOn[0] === box.id) {
          ret = dgBox.id;
        } else {
          ret = -1;
        }
      }
    });
    return ret;
  }

  /**
   * Removes a `BoxPlacement` as a dependency for all BoxPlacements in `this.boxes`
   * @param parentBox `BoxPlacement` to remove
   */
  private removeItemAsDependency(parentBox: BoxPlacement) {
    this.boxes.forEach((box) => {
      const index = box.dependentOn.indexOf(parentBox.id);
      if (index >= 0) {
        box.dependentOn.splice(index, 1);
      }
    });
  }

  /**
   * Removes a `BoxPlacement` as a double grip candidate for all BoxPlacements in `this.boxes`
   * @param parentBox `BoxPlacement` to remove
   */
  private removeItemAsDoubleGripCandidate(parentBox: BoxPlacement) {
    this.boxes.forEach((box) => {
      const index = box.doubleGripCandidates.indexOf(parentBox.id);
      if (index >= 0) {
        box.doubleGripCandidates.splice(index, 1);
      }
    });
  }

  /**
   * Gets a `BoxPlacement` by the `BoxPlacement.Id`
   * @param boxId Id of `BoxPlacement` to get
   */
  private getBoxById(boxId: number) {
    return this.boxes[boxId - 1];
  }
}
