interface IExternalSvgIcon {
  name: string;
  path: string;
}

export const externalSvgIcons: IExternalSvgIcon[] = [
  {
    name: 'pallet_height',
    path: 'palletHeight.svg',
  },
  {
    name: 'pallet_fill',
    path: 'palletFillRate.svg',
  },
  {
    name: 'pallet_weight',
    path: 'palletWeight.svg',
  },
  {
    name: '3d_view',
    path: '3d_icon.svg',
  },
  {
    name: '2d_view',
    path: '2D_icon.svg',
  },
  {
    name: 'pallet_overhang_sides',
    path: 'Pallet_overhang_sides.svg',
  },
  {
    name: 'pallet_overhang_ends',
    path: 'Pallet_overhang_ends.svg',
  },
  {
    name: 'box_padding',
    path: 'Box_padding.svg',
  },
  {
    name: 'label_orientaion_icon',
    path: 'Label_orientation_icon.svg',
  },
  {
    name: 'navbar_pkb',
    path: 'navbar/knowledge_base_icon.svg',
  },
  {
    name: 'pallet_cube',
    path: 'palletCubeRate.svg',
  },
  {
    name: 'boxes',
    path: 'boxes.svg',
  },
  {
    name: 'play-svgrepo-com',
    path: 'play-svgrepo-com.svg',
  },
  {
    name: 'pause-svgrepo-com',
    path: 'pause-svgrepo-com.svg',
  },
  {
    name: 'stop-svgrepo-com',
    path: 'stop-svgrepo-com.svg',
  },
  {
    name: 'player-svgrepo-com',
    path: 'player-svgrepo-com.svg',
  },
  {
    name: 'plus',
    path: 'plus.svg',
  },
  {
    name: 'info',
    path: 'icons8-info.svg',
  },
  {
    name: 'change-circle',
    path: 'change-circle.svg',
  },
  {
    name: 'info',
    path: 'info.svg',
  },
  {
    name: 'filter-alt',
    path: 'filterAlt.svg',
  },
  {
    name: 'product',
    path: 'product.svg',
  },
  {
    name: 'pallet',
    path: 'pallet.svg',
  },
  {
    name: 'pattern',
    path: 'pattern.svg',
  },
  {
    name: 'simulation',
    path: 'simulation.svg',
  },
  {
    name: 'robot_configuration',
    path: 'robot_configuration.svg',
  },
  {
    name: 'production_line',
    path: 'production_line.svg',
  },
  {
    name: 'hardware',
    path: 'hardware.svg',
  },
  {
    name: 'hardware_configuration',
    path: 'hardware_configuration.svg',
  },
  {
    name: 'software_configuration',
    path: 'software_configuration.svg',
  },
  {
    name: 'robot',
    path: 'robot.svg',
  },
];
