import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { URDFRobot } from '@rocketfarm/urdf-loader/src/URDFClasses';
import RF_URDFLoader from '@rocketfarm/urdf-loader/src/URDFLoader';
import { Asset, AssetType } from 'src/app/models_new/classes/asset/asset';
import { FontLoader, Font } from 'three/examples/jsm/loaders/FontLoader';
import {
  Collada,
  ColladaLoader,
} from 'three/examples/jsm/loaders/ColladaLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  throwError,
  combineLatest,
  merge,
} from 'rxjs';
import {
  filter,
  map,
  retryWhen,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Type } from 'src/app/utils/type';
import { ApiService } from 'src/app/services/api/api.service';
import { IAssetStoreLoadingConfig } from 'src/app/models_new/types/asset/asset-store-loading-config';
import { globalAssetConfig } from 'src/app/models_new/config/asset/global-assets-config';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { settings } from '../models_new/config/application-settings';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StateService } from '../auth/state.service';
import { IApiOrganization } from '../models_new/classes/api-models/ApiOrganization';
import { AssetIDs } from '../models_new/enums/asset-ids';
import { AuthService, User } from '@auth0/auth0-angular';
import { NotificationService } from './notification.service';

/**
 * TODO! AssetStoreService needs to be re-written when refactoring three-view.
 * Some of the functions swallows useful errors that should be handeled outside.
 */
@Injectable({
  providedIn: 'root',
})
export class AssetStoreService {
  public static assetStore: AssetStoreService;

  static readonly loadingCompleted$ = new ReplaySubject<boolean>(1);
  static readonly loadingProgress$ = new BehaviorSubject<number>(0);

  private assets: Map<string, Asset<any>> = new Map<string, Asset<any>>();
  private manager: THREE.LoadingManager;

  private urdfLoader: RF_URDFLoader;
  private daeLoader: ColladaLoader;
  private stlLoader: STLLoader;
  private fontLoader: FontLoader;
  private textureLoader: THREE.TextureLoader;

  private azureToken$: Observable<string>;

  private logoAsset: Asset<THREE.Texture>;

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private router: Router,
    private stateService: StateService,
    private notification: NotificationService
  ) {
    if (!Type.isDefined(AssetStoreService.assetStore)) {
      AssetStoreService.assetStore = this;
    }

    // Set up loaders
    this.setupLoaders();

    this.azureToken$ = merge(
      // This handles specific refreshes on without navigation
      this.router.events.pipe(
        filter((e) => e instanceof NavigationStart && !this.router.navigated),
        map(
          () => new NavigationEnd(0, window.location.href, window.location.href)
        )
      ),
      // This handles any navigation other than refreshes.
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
    ).pipe(
      take(1),
      switchMap((e: NavigationEnd) => this.activateUser(e)),
      switchMap((user) => this.activateAzureToken(user)),
      shareReplay({ bufferSize: 1, refCount: false }) // Allow reuse and sharing
    );

    // Make global assets
    // NOTE! As this depends on azureToken$ observable
    // it has to happen after it's defined.
    // NOTE! asset creating isn't happening fast enough if placed
    // in the azureToken$ pipe flow, moved it out as a fix.
    AssetStoreService.makeAssetsFromConfig(globalAssetConfig);
    AssetStoreService.loadAssetsFromConfig(globalAssetConfig);

    this.logoAsset = this.getAsset(AssetIDs.CompanyLogo);

    // Prep logo to a picture
    this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(
        tap((org) => {
          this.logoAsset.clear();
        }), // Organization changed, clear logo
        filter(Boolean),
        switchMap((org: IApiOrganization) => {
          return of(org.logo);
        })
      )
      .subscribe((data) => {
        const src = typeof data === 'string' ? data : data[0].url;

        // New asset incoming.
        this.logoAsset.setLoaded(false);

        const logo = new Image();
        logo.onload = () => {
          const texture = new THREE.Texture();
          texture.image = logo;
          texture.needsUpdate = true;
          this.logoAsset.set(texture, AssetIDs.CompanyLogo);
          this.logoAsset.setLoaded(true);
        };

        logo.src = src;
      });
  }

  activateUser(routerEvent: NavigationEnd): Observable<User> {
    if (
      !routerEvent.urlAfterRedirects.includes('user') &&
      !routerEvent.urlAfterRedirects.includes('public') &&
      !routerEvent.urlAfterRedirects.includes('o=1') &&
      window.location.pathname !== '/' + 'about' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/' + settings.fastTrackMainRoute &&
      !settings.fastTrackRoutes.some((v) =>
        window.location.pathname.includes(v)
      ) &&
      window.location === window.parent.location
    ) {
      return this.auth.user$.pipe(
        map((user: User) => {
          if (!user) {
            throw new Error('missing user');
          } else {
            return user;
          }
        }),
        retryWhen(RXJSUtils.genericRetryStrategy())
      );
    }

    return this.auth.user$;
  }

  activateAzureToken(user: User | null): Observable<string> {
    if (!user) {
      this.urdfLoader.packages = {
        pally_descriptions:
          settings.publicPallyDescriptionsURL + 'pally_descriptions',
        ur_description: settings.publicPallyDescriptionsURL + 'ur_description',
        dsr_description2:
          settings.publicPallyDescriptionsURL + 'dsr_description2',
        ur_e_description:
          settings.publicPallyDescriptionsURL + 'ur_e_description',
      };
    } else {
      this.urdfLoader.packages = {
        pally_descriptions:
          'https://pallydescriptions.azureedge.net/models/pally_descriptions',
        ur_description:
          'https://pallydescriptions.azureedge.net/models/ur_description',
        dsr_description2:
          'https://pallydescriptions.azureedge.net/models/dsr_description2',
        ur_e_description:
          'https://pallydescriptions.azureedge.net/models/ur_e_description',
      };
    }

    const tokensource = user
      ? this.apiService.getPallydescriptionsSASToken()
      : this.apiService.getPublicPallydescriptionsSASToken();

    return combineLatest([tokensource, of(user)]).pipe(
      map(([token, user]) => {
        token = decodeURIComponent(token);
        const sigDec = token.substring(token.indexOf('sig=') + 4);
        const sigEnc = encodeURIComponent(sigDec);
        token = token.replace(sigDec, sigEnc);

        // Sideeffect: Update the urdf loader options when we have a token
        this.urdfLoader.queryStringOptions = [
          [
            user
              ? settings.pallyDescriptionsURL
              : settings.publicPallyDescriptionsURL,
            '?' + token,
          ],
        ];
        return token;
      })
    );
  }

  private setupLoaders(): void {
    this.manager = new THREE.LoadingManager();
    this.urdfLoader = new RF_URDFLoader(this.manager);
    this.daeLoader = new ColladaLoader(this.manager);
    this.stlLoader = new STLLoader(this.manager);
    this.fontLoader = new FontLoader(this.manager);
    this.textureLoader = new THREE.TextureLoader(this.manager);

    this.manager.onLoad = function () {
      console.debug('Completed loading assets!');
      AssetStoreService.loadingCompleted$.next(true);
    };

    this.manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      AssetStoreService.loadingProgress$.next((itemsLoaded / itemsTotal) * 100);
    };

    const that = this;
    this.manager.onError = function (url) {
      const urlDotSplit = url.split('?');
      const urlSlashSplit = urlDotSplit[0].split('/');
      that.notification.showError(
        'Error loading ' + urlSlashSplit[urlSlashSplit.length - 1]
      );
    };
  }

  /* ----------------------------- Static members ----------------------------- */

  public static getAsset<Ttype = any>(id: string): Asset<Ttype> {
    return AssetStoreService.assetStore.getAsset<Ttype>(id);
  }

  public static addAsset(asset: Asset<any>): void {
    AssetStoreService.assetStore.addAsset(asset);
  }

  public static loadAsset(asset: Asset<any>): void {
    AssetStoreService.assetStore.loadAsset(asset);
  }

  public static makeAssetsFromConfig(
    configs: IAssetStoreLoadingConfig[]
  ): void {
    AssetStoreService.assetStore.makeAssetsFromConfig(configs);
  }

  public static loadAssetsFromConfig(
    configs: IAssetStoreLoadingConfig[]
  ): void {
    AssetStoreService.assetStore.loadAssetsFromConfig(configs);
  }

  public static destroyAssetsFromConfig(
    configs: IAssetStoreLoadingConfig[]
  ): void {
    AssetStoreService.assetStore.destroyAssetsFromConfig(configs);
  }

  public static whenAssetIsReady(id: string): Observable<Asset<any>> {
    return AssetStoreService.assetStore.whenAssetIsReady(id);
  }

  public static onAssetLoadedWithID<T>(id: string): Observable<T> {
    return AssetStoreService.assetStore.onAssetLoadedWithID(id);
  }

  public static onAssetUnloadedWithID(id: string): Observable<any> {
    return AssetStoreService.assetStore.onAssetUnloadedWithID(id);
  }

  /* ----------------------------- Instance members ---------------------------- */

  public makeAssetsFromConfig(configs: IAssetStoreLoadingConfig[]): void {
    for (const config of configs) {
      let asset = this.getAsset(config.id);

      // Asset didn't exsist, make it
      if (!asset) {
        asset = this.makeAsset(config);
      }
      asset.setConfig(config);
    }
  }

  public async loadAssetsFromConfig(
    configs: IAssetStoreLoadingConfig[]
  ): Promise<void> {
    for (const config of configs) {
      const asset = this.getAsset(config.id);
      if (Type.isDefined(asset)) {
        // Asset is not on demand, not loading or loaded, load it.
        if (!config.onDemand && !asset.isLoaded() && !asset.isLoading()) {
          this.loadAsset(asset, config);
        }
      }
    }
  }

  public destroyAssetsFromConfig(configs: IAssetStoreLoadingConfig[]) {
    for (const config of configs) {
      let asset = this.getAsset(config.id);
      if (asset) {
        asset.reset();
      }
    }
  }

  public getAsset<Ttype = any>(id: string): Asset<Ttype> {
    return this.assets.get(id) as Asset<Ttype>;
  }

  public whenAssetIsReady(id: string): Observable<Asset<any>> {
    return of(!this.assets.has(id) ? null : this.assets.get(id));
  }

  public onAssetLoadedWithID(id: string): Observable<any> {
    return this.whenAssetIsReady(id).pipe(
      switchMap((asset) => {
        return asset
          ? of(asset)
          : throwError(() => new Error(`Couldn't find asset! ${id}`));
      }),
      switchMap((asset) => {
        // Check if we need to trigger loading
        if (asset.config.onDemand && !asset.isLoaded() && !asset.isLoading()) {
          this.loadAsset(asset);
        }
        return asset.onModel$();
      })
    );
  }

  public onAssetUnloadedWithID(id: string): Observable<any> {
    return this.whenAssetIsReady(id).pipe(
      switchMap((asset) => {
        return asset
          ? of(asset)
          : throwError(() => new Error(`Couldn't find asset! ${id}`));
      }),
      switchMap((asset) => {
        return asset.onModelUnloaded$();
      })
    );
  }

  public addAsset(asset: Asset<any>): void {
    if (this.assets.has(asset.getID())) {
      this.reportDuplicateAssetID(asset.getID());
    }

    this.assets.set(asset.getID(), asset);
  }

  public makeAsset(config: IAssetStoreLoadingConfig): Asset<any> {
    if (this.assets.has(config.id)) {
      this.reportDuplicateAssetID(config.id);
      return undefined;
    }

    let asset: Asset<any>;
    if (config.type === AssetType.URDF) {
      asset = new Asset<URDFRobot>(config);
    } else if (config.type === AssetType.DAEModel) {
      asset = new Asset<THREE.Object3D>(config);
    } else if (config.type === AssetType.STLModel) {
      asset = new Asset<THREE.Object3D>(config);
    } else if (config.type === AssetType.Font) {
      asset = new Asset<Font>(config);
    } else if (config.type === AssetType.Texture) {
      asset = new Asset<THREE.Texture>(config);
    }

    this.addAsset(asset);
    return asset;
  }

  public loadAsset(asset: Asset<any>, config?: IAssetStoreLoadingConfig) {
    if (asset.getURLs().size === 0) {
      this.reportSkipLoading(asset.getID());
      return;
    }

    // Ignored if loading is already triggered
    if (asset.isLoading()) {
      return;
    }

    // Need to load more assets
    AssetStoreService.loadingCompleted$.next(false);

    asset.setLoading(true);

    this.azureToken$.pipe(take(1)).subscribe((token) => {
      this.handle(asset, config?.needsSASToken ? token : null);
    });

    // URDF structures wont display correctly unless assets are set loaded once all models are loaded
    AssetStoreService.loadingCompleted$
      .pipe(RXJSUtils.filterFalse(), take(1))
      .subscribe((value: boolean) => {
        asset.setLoaded(true);
      });
  }

  private resolveURL(url: string, token?: string): string {
    let location = url;
    if (token) {
      location = `${location}?${token}`;
    }
    return location;
  }

  private reportDuplicateAssetID(id: string): void {
    console.warn(
      'Duplicate asset ID: "' +
        id +
        '" Make sure asset ids are unique! Skipping!'
    );
  }

  private reportSkipLoading(id: string): void {
    console.warn('Skipping asset loading due to missing url! Asset id: ', id);
  }

  /* ------------------------- Asset loading functions ------------------------ */

  private handle(asset: Asset<any>, token: string) {
    switch (asset.getType()) {
      case AssetType.URDF: {
        this.handleURDF(asset);
        break;
      }
      case AssetType.DAEModel: {
        this.handleDAEModel(asset, token);
        break;
      }
      case AssetType.STLModel: {
        this.handleSTLModel(asset, token);
        break;
      }
      case AssetType.Font: {
        this.handleFont(asset, token);
        break;
      }
      case AssetType.Texture: {
        this.handleTexture(asset, token);
        break;
      }
    }
  }

  private handleURDF(asset: Asset<URDFRobot>): void {
    for (const [mode, url] of asset.getURLs()) {
      this.urdfLoader.load(url, (robot: URDFRobot) => {
        (robot as THREE.Object3D).name = asset.getID();
        asset.set(robot, mode);
      });
    }
  }

  private handleDAEModel(asset: Asset<THREE.Object3D>, token?: string): void {
    for (const [mode, url] of asset.getURLs()) {
      this.daeLoader.load(this.resolveURL(url, token), (model: Collada) => {
        (model.scene as THREE.Object3D).name = asset.getID();
        asset.set(model.scene, mode);
      });
    }
  }

  private handleSTLModel(asset: Asset<THREE.Object3D>, token?: string): void {
    for (const [mode, url] of asset.getURLs()) {
      this.stlLoader.load(
        this.resolveURL(url, token),
        (geo: THREE.BufferGeometry) => {
          const mesh = new THREE.Mesh(geo, new THREE.MeshPhongMaterial());
          mesh.name = asset.getID();
          asset.set(mesh, mode);
        }
      );
    }
  }

  private handleFont(asset: Asset<Font>, token?: string): void {
    for (const [mode, url] of asset.getURLs()) {
      this.fontLoader.load(this.resolveURL(url, token), (font: Font) => {
        asset.set(font, mode);
      });
    }
  }

  private handleTexture(asset: Asset<THREE.Texture>, token?: string): void {
    for (const [mode, url] of asset.getURLs()) {
      this.textureLoader.load(
        this.resolveURL(url, token),
        (texture: THREE.Texture) => {
          asset.set(texture, mode);
        }
      );
    }
  }
}
