<div
  [ngClass]="type === 'info' ? 'info-container' : 'error-container'"
  (click)="
    actionClicked.emit({
      actionId: data.onClickActionId
    })
  "
>
  <mat-icon *ngIf="data.materialIconTag">
    {{ data.materialIconTag }}
  </mat-icon>
  {{ data.actionText }}
</div>
