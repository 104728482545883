import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControlStatus,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  map,
  Observable,
  of,
  shareReplay,
  skipWhile,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  forkJoin,
  combineLatest,
  BehaviorSubject,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { Field } from 'src/app/models_new/classes/field';
import { defaultApiOpenSimulation } from 'src/app/models_new/config/default/api-default/default-api-open-simulation';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { SimulationStatus } from 'src/app/models_new/enums/simulation-status';
import { FieldType } from 'src/app/models_new/types/field-type';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import {
  InfoApiService,
  IOpenSimContent,
} from 'src/app/services/api/info-api.service';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import {
  LocalStorageService,
  StorageMethod,
} from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import {
  IFastTrackPipedriveInfo,
  PipedriveService,
} from '../../../services/pipedrive.service';
import { IReadyOpenSimulation, OpenSimService } from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { OrganizationApiService } from '../../../services/api/organization-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CaptchaDialogComponent } from '../../dialogs/captcha-dialog/captcha-dialog.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { NotificationService } from 'src/app/services/notification.service';
import confetti from 'canvas-confetti';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-service.service';

interface IResolvedParams {
  si?: string;
  solution?: any;
  sp?: string;
  solution_provider?: string;
  vi?: string;
  vendor_name?: string;
  vn?: string;
}

interface ProgressStep {
  key: string; // name of the step
  value: number; // amount of simulations on this step.
  isComplete: boolean;
  tooltip?: string;
}

@Component({
  selector: 'app-fast-track-user-info',
  templateUrl: './fast-track-user-info.component.html',
  styleUrls: ['./fast-track-user-info.component.scss'],
})
export class FastTrackUserInfoComponent implements OnInit, OnDestroy {
  dataReady$: Observable<DataRequestState<Field[]>>;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  destroy$: Subject<boolean> = new Subject<boolean>();
  simulations$: Observable<DataRequestState<IReadyOpenSimulation[]>>;
  tableDataSource: IReadyOpenSimulation[];
  cardState: 'fields' | 'loading' | 'explore' = 'fields';
  exploreContent$: Observable<IOpenSimContent>;
  showTableActions: boolean = false;
  displayedColumns = ['name', 'starting_price'];
  SimulationStatus = SimulationStatus;
  solutionsMetadata: [{ id: string; price?: string; externalUrl?: string }?] =
    [];
  overalProgress$: BehaviorSubject<ProgressStep[]> = new BehaviorSubject(null);
  generalError: boolean = false;

  exampleLink$: Observable<string>;

  simsForPipedrive: IReadyOpenSimulation[];
  sentSimulationLinks = false;
  currentStep = 4;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private infoApi: InfoApiService,
    public openSimService: OpenSimService,
    private publicApi: PublicApiService,
    public appLayout: AppLayoutService,
    private gas: GoogleAnalyticsService,
    private pipedriveService: PipedriveService,
    private route: ActivatedRoute,
    private orgApi: OrganizationApiService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.exampleLink$ = this.infoApi.getLandingPageInfo().pipe(
      take(1),
      map((value: IInfoCard) => value.learnMoreLink)
    );

    this.exploreContent$ = this.openSimService.contentFulContent$.pipe(
      map((m: IOpenSimContent[]) => {
        return m.find((c) => c.step === 5);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    if (this.openSimService.readySimulations$ === undefined) {
      this.openSimService.findAndNavigateToPreviousPage(this.currentStep);
    }

    this.simulations$ = this.openSimService.readySimulations$.pipe(
      takeUntil(this.destroy$),
      map((sims: IReadyOpenSimulation[]) =>
        this.updateSimulationTableData(sims, true)
      ),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.dataReady$ = this.openSimService.prevIsValid(this.currentStep).pipe(
      take(1),
      tap((isValid: boolean) => {
        if (!isValid) {
          this.openSimService.findAndNavigateToPreviousPage(this.currentStep);
        }
      }),

      switchMap((_) =>
        of(
          this.localStorage.getData(
            LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
          )
            ? new ApiPattern(
                this.localStorage.getData(
                  LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
                )
              )
            : null
        )
      ),
      switchMap((_) =>
        forkJoin([
          this.infoApi.getLandingPageInfo().pipe(
            take(1),
            map((info) => info.gdprLink)
          ),
          this.infoApi.getPhaseOfResearchConfig().pipe(take(1)),
          of(
            this.localStorage.getData(LocalStorageKey.OPEN_SIM) ||
              defaultApiOpenSimulation
          ),
        ])
      ),
      map(([gdprLink, phaseOfResearchConfig, openSim]) => {
        const fields = this.makeFields(
          gdprLink,
          openSim,
          phaseOfResearchConfig
        );
        fields.forEach((f: Field) => {
          this.formGroup.addControl(f.id, f.formControl);
        });
        return fields;
      }),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.dataReady$
      .pipe(
        skipWhile((r) => !r.value),
        take(1),
        switchMap((_) => this.formGroup.statusChanges),
        takeUntil(this.destroy$),
        tap((isValid: FormControlStatus) => {
          if (isValid === 'VALID') {
            this.openSimService.setStepIsValid(true);
          } else {
            this.openSimService.setStepIsValid(false);
          }
        }),
        switchMap((_) => this.formGroup.valueChanges),
        tap((value: any) => {
          const openSim: IApiOpenSimulation =
            this.localStorage.getData(LocalStorageKey.OPEN_SIM) || {};
          openSim.name = value.full_name;
          openSim.email = value.email;
          openSim.organization_name = value.organization_name;
          openSim.phase_of_research = value.phase_of_research;

          this.localStorage.setData(
            LocalStorageKey.OPEN_SIM,
            openSim,
            StorageMethod.LOCAL
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private startSimulations(): void {
    this.cardState = 'loading';
    let isConfettiThrown = false;

    const sims$ = this.openSimService.startSimulations().pipe(
      take(1),
      switchMap((pattern_id: string) =>
        this.publicApi.getOpenSimulationsByPatternId(pattern_id)
      ),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    // Main GUI pipe
    this.simulations$ = sims$.pipe(
      map((sims: IReadyOpenSimulation[]) => {
        return this.updateSimulationTableData(sims);
      }),
      tap((_) => {
        if (this.cardState === 'fields' || this.cardState === 'loading') {
          this.displayedColumns = this.displayedColumns.filter(
            (column) => column !== 'starting_price'
          );
        }
        this.cardState = 'explore';
        [
          'progress',
          'status',
          'cpm',
          'eta',
          'starting_price',
          'action',
        ].forEach((column) => {
          if (!this.displayedColumns.includes(column)) {
            this.displayedColumns.push(column);
          }
        });
        this.showTableActions = true;
        if (!isConfettiThrown) {
          this.trhowConfetti();
          isConfettiThrown = true;
        }
      }),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        } else {
          this.localStorage.removeData(
            LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
          );
          this.localStorage.removeData(LocalStorageKey.PROJECT);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );

    // Pipedrive info "plug-in"
    sims$
      .pipe(
        take(1),
        switchMap((sims: IReadyOpenSimulation[]) => {
          // Already sent, continue with your business...
          if (this.sentSimulationLinks) {
            return of(sims);
          }

          const opensimInfo: IApiOpenSimulation = this.localStorage.getData(
            LocalStorageKey.OPEN_SIM
          );

          const params = this.route?.snapshot?.queryParams;

          this.sentSimulationLinks = true;
          if (Object.keys(params).length > 0) {
            return this.resolveParams(params).pipe(
              take(1),
              switchMap((paramInfo: IResolvedParams) => {
                return this.sendPipedriveInfo(opensimInfo, sims, paramInfo);
              })
            );

            // No params, just simulations to send.
          } else {
            return this.sendPipedriveInfo(opensimInfo, sims);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => console.error(error),
      });
  }

  private trhowConfetti() {
    var end = Date.now() + 0.3 * 1000;
    var colors = ['#4d9391', '#395692', '#af2b2b', '#92b4dA', '#f4f4f4'];
    (function frame() {
      confetti({
        particleCount: 5,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 5,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }

  private updateSimulationTableData(
    inputData: IReadyOpenSimulation[],
    isInit?: boolean
  ): IReadyOpenSimulation[] {
    if (inputData?.length !== this.tableDataSource?.length) {
      let hasParams = false;
      const queryParams = this.route.snapshot.queryParams;
      if (
        queryParams.vn ||
        queryParams.si ||
        queryParams.sp ||
        queryParams.vi
      ) {
        hasParams = true;
      }
      if (isInit)
        inputData.forEach((sim, i) => {
          sim.progress = `${sim.progress || 0}%`;
          sim.name = hasParams ? sim.solution.name : `Solution No. ${i + 1}`;
          sim.cpm = `${sim?.cpm || '~'}`;
          sim.eta = sim?.eta ? `${sim?.eta} min` : '~';
          sim.startingPrice = sim.solution.metadata?.price
            ? `${sim.solution.metadata?.currency} ${this.transformToSpacedValue(
                sim.solution.metadata?.price + ''
              )}`
            : '~';
          sim.externalUrl = hasParams ? sim.solution.externalUrl : null;
          this.solutionsMetadata.push({
            id: sim.solution.name,
            price: sim.startingPrice,
            externalUrl: sim.solution.externalUrl || null,
          });
        });
      this.tableDataSource = ObjectUtils.cloneObject(inputData);
    } else if (inputData[0]['id'] && !inputData[0].solutionId) {
      if (this.tableDataSource[0].solutionId) {
        this.tableDataSource = ObjectUtils.cloneObject(inputData);
      }
      const applyToFixed = (value: any): any => {
        if (typeof value === 'number') {
          return value.toFixed(2);
        }
        return value;
      };

      this.tableDataSource.forEach((tableData) => {
        const inputDataEntry = inputData.find(
          (inputEntry) => tableData['id'] === inputEntry['id']
        );
        tableData.progress = `${inputDataEntry?.progress || 0}%`;
        tableData.status = inputDataEntry?.status;
        tableData.cpm = `${applyToFixed(inputDataEntry?.cpm) || '~'}`;
        // TODO: Fetch price from API instead.
        tableData.startingPrice = this.solutionsMetadata.find(
          (entry) => entry.id === tableData.name
        )?.price;
        tableData.externalUrl = this.solutionsMetadata.find(
          (entry) => entry.id === tableData.name
        )?.externalUrl;
        tableData.eta = inputDataEntry?.eta
          ? `${applyToFixed(inputDataEntry?.eta)} min`
          : '~';
      });
      this.checkStageCompletion();
    }
    return this.tableDataSource;
  }

  private transformToSpacedValue(input: string): string {
    const parts = input.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimalPart = parts[1] ? `.${parts[1]}` : '';
    return `${integerPart}${decimalPart}`;
  }

  /**
   * Checks the completion status of each stage in the progress and updates overlaProgress$
   */
  private checkStageCompletion(): void {
    const progresKeys = [
      'Queued',
      'Setting_up',
      'Starting_robot',
      'Palletizing',
      'Completed',
    ];
    const stages: ProgressStep[] = progresKeys.map((key) => ({
      key,
      value: 0,
      tooltip: '',
      isComplete: false,
    }));
    let validSimulationCount = 0;
    let errorSimulationConut = 0;
    for (const simulation of this.tableDataSource) {
      let simulationStage = simulation.status;
      if (simulationStage === 'Finished' || simulationStage === 'Successful') {
        simulationStage = 'Completed';
      }
      if (!progresKeys.includes(simulationStage)) {
        if (simulationStage === 'Error') {
          errorSimulationConut++;
        }
        continue;
      }
      if (errorSimulationConut === this.tableDataSource.length) {
        this.generalError = true;
      }
      validSimulationCount++;
      const stageIndex = stages.findIndex(
        (stage) => stage.key === simulationStage
      );
      for (let i = 0; i <= stageIndex; i++) {
        stages[i].value++;
      }
    }
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      if (i < stages.length - 1) {
        if (stages[i + 1].value === validSimulationCount) {
          stage.isComplete = true;
        }
      } else {
        if (stage.key === 'Completed' && stage.value === validSimulationCount) {
          stage.isComplete = true;
        }
      }
      if (stage.value !== 0 && stage.isComplete === false) {
        stage.tooltip = `${stage.value} simulation${
          stage.value > 1 ? 's' : ''
        } ${stage.key.toLowerCase().replaceAll('_', ' ')}`;
      }
    }
    this.overalProgress$.next(stages);
  }

  /**
   * Sorts and triggers simulations based on the selected page index.
   * It adds an event to the gas service and starts the simulations if the selected page is both done and valid.
   */
  private sortAndTriggerSimultions(): void {
    this.gas.addEvent(
      'User Info - Fast Track - MyRobot.cloud - Start Simulation - Click',
      null,
      this.gas.getLastParam()
    );
    this.openSimService.pagesSortedList$
      .pipe(take(1))
      .subscribe((pagesSortedList) => {
        if (
          pagesSortedList[this.openSimService.selectedPageIndex].done &&
          pagesSortedList[this.openSimService.selectedPageIndex].valid
        ) {
          this.startSimulations();
        }
      });
  }

  public checkCaptchaAndSubmit(): void {
    this.dialogService
      .showCustomDialog(
        CaptchaDialogComponent,
        DialogSize.SMALL,
        null,
        { isLoggable: false },
        true
      )
      .afterClosed()
      .subscribe((validated) => {
        if (!validated) {
          this.notificationService.showError(
            `Couldn't verify you are human. Try again`
          );
        } else {
          this.sortAndTriggerSimultions();
        }
      });
  }

  private resolveParams(params: any): Observable<IResolvedParams> {
    const ids: string[] = [];
    const operations: Observable<any>[] = [];
    if (params?.si) {
      ids.push('solution');
      operations.push(this.publicApi.getSolutionByID(params.si));
    }
    if (params?.sp) {
      ids.push('solution_provider');
      operations.push(this.orgApi.getPublicOrgNameByID(params.sp));
    }
    if (params?.vi || params?.vn) {
      ids.push('vendor_name');
      operations.push(this.orgApi.getPublicVendorName(params.vi, params.vn));
    }
    return combineLatest(operations).pipe(
      map((data: any) => {
        const solution_index = ids.indexOf('solution');
        const solution_provider_index = ids.indexOf('solution_provider');
        const vendor_name_index = ids.indexOf('vendor_name');
        const obj = {};
        if (solution_index > -1) {
          obj['solution'] = data[solution_index];
        }
        if (solution_provider_index > -1) {
          obj['solution_provider'] = data[solution_provider_index];
        }
        if (vendor_name_index > -1) {
          obj['vendor_name'] = data[vendor_name_index];
        }
        return obj;
      }),
      map(
        (data: {
          solution?: any;
          solution_provider?: string;
          vendor_name?: string;
        }) => {
          return {
            si: params?.si,
            solution: data?.solution,
            sp: params?.sp,
            solution_provider: data?.solution_provider
              ? data.solution_provider
              : data?.solution
              ? data.solution.solution_provider.name
              : 'UNKNOWN',
            vi: params?.vi,
            vendor_name: data?.vendor_name ? data.vendor_name : params?.vn,
            vn: params?.vn,
          } as IResolvedParams;
        }
      )
    );
  }

  private sendPipedriveInfo(
    opensimInfo: IApiOpenSimulation,
    sims: IReadyOpenSimulation[],
    paramInfo?: IResolvedParams
  ): Observable<any> {
    const info: IFastTrackPipedriveInfo = {
      fullName: opensimInfo.name,
      email: opensimInfo.email,
      region: opensimInfo.region,
      country: opensimInfo.country,
      organization: opensimInfo.organization_name,
      phase_of_research: opensimInfo.phase_of_research,
    };
    if (paramInfo?.solution_provider) {
      info['campaign'] = `${paramInfo.solution_provider}${
        paramInfo.vendor_name ? ' ' + paramInfo.vendor_name : ''
      } Fast Track Lead${
        paramInfo.solution ? ' - Solution: ' + paramInfo.solution.name : ''
      }`;
    }

    return this.pipedriveService.postPipedriveFastTrack(info, sims);
  }

  public goToReport(simId: string): void {
    this.gas.addEvent(
      'Confirmation - Fast Track - MyRobot.cloud - View report - Click',
      null,
      this.gas.getLastParam()
    );
    const url = this.router.serializeUrl(
      this.router.createUrlTree([pagesPATH.SIMULATIONS, simId], {
        queryParams: { o: 1 },
      })
    );
    window.open(url, '_blank');
  }

  public navigateTo(url: string): void {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }
    window.open(url, '_blank');
  }

  private makeFields(
    gdprLink: string,
    openSim: IApiOpenSimulation,
    phase_of_research: { title: string; options: string[] }
  ): Field[] {
    return [
      new Field(
        FieldType.TEXT,
        true,
        openSim.name,
        [Validators.required],
        null,
        null,
        null,
        { name: 'full-name', label: 'Full name' },
        'full_name'
      ),
      new Field(
        FieldType.TEXT,
        true,
        openSim.email,
        [Validators.required, Validators.email],
        null,
        [],
        null,
        { name: 'email', label: 'Email address' },
        'email'
      ),
      new Field(
        FieldType.TEXT,
        true,
        openSim.organization_name,
        [Validators.required],
        null,
        null,
        null,
        { name: 'organization', label: 'Organization name' },
        'organization_name'
      ),
      new Field(
        FieldType.SELECT_SINGLE,
        true,
        openSim.phase_of_research,
        [Validators.required],
        null,
        phase_of_research.options,
        null,
        { name: 'phase_of_research', label: phase_of_research.title },
        'phase_of_research'
      ),
      new Field(
        FieldType.CHECKBOX,
        true,
        null,
        [Validators.required, Validators.requiredTrue],
        null,
        [],
        null,
        {
          name: 'gdpr',
          label: `I agree to the <a href="${gdprLink}" target="_blank">terms and conditions</a>`,
        },
        'gdpr'
      ),
    ];
  }
}
