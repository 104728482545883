<div class="content-container">
  <div class="content">
    <div class="left">
      <h2>Dimensions:</h2>

      <app-field [field]="lengthField"></app-field>
      <app-field [field]="widthField"></app-field>
      <app-field [field]="heightField"></app-field>
    </div>
    <div class="right">
      <h2>Offset:</h2>

      <!-- y first so it's visually in line with gripper length field -->
      <app-field [field]="offsetYField"></app-field>
      <app-field [field]="offsetXField"></app-field>
    </div>
  </div>
  <div class="bottomBar">
    <button mat-stroked-button color="accent" (click)="close()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      class="create-button"
      (click)="close(makeCustomGripper())"
    >
      CONFIRM
    </button>
  </div>
</div>
