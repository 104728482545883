import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HardwareRoutingModule } from './hardware-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared.module';
import { ThreeModule } from '../three.module';
import { HardwareWrapperComponent } from '../../components/inventory/hardwares/hardware-wrapper/hardware-wrapper.component';
import { HardwareComponent } from '../../components/inventory/hardware/hardware.component';
import { EditHardwareComponent } from '../../components/dialogs/edit-hardware-dialog/edit-hardware.component';

@NgModule({
  declarations: [
    HardwareWrapperComponent,
    HardwareComponent,
    EditHardwareComponent,
  ],
  exports: [HardwareWrapperComponent, HardwareComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    CommonModule,
    HardwareRoutingModule,
  ],
})
export class HardwareModule {}
