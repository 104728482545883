import { OrganizationSubscriptionType } from '../../enums/organization-subscription-type';
import { OrganizationType } from '../../enums/organization-type';
import {
  IInventoryStatus,
  IInventoryStatusObject,
} from '../../types/company-inventory-numbers';
import { IInfoCardNewConfig } from '../../types/info-card-config';
import { ApiOrganizationMember } from './ApiOrganizationMember';
import { ApiPalletizingProject } from './ApiPalletizingProject';
import { ApiPattern } from './ApiPattern';
import { ApiProduct } from './ApiProduct';
import { ApiProductionLine } from './ApiProduction_line';
import { ApiRobotConfiguration } from './ApiRobotConfiguration';
import { ApiScene } from './ApiScene';
import { ApiSimulation } from './ApiSimulation';
import { ApiStrategy } from './ApiStrategy';
import { ApiUser } from './ApiUser';
import { ApiInstalledRobot } from './ApiRobotInstallation';
import {
  ApiOrganizationSubscription,
  IApiOrganizationSubscription,
} from './ApiOrganizationSubscription';

export interface IApiOrganization {
  id: string;
  creator_id: ApiUser['id'];
  name: string;
  type: OrganizationType;
  location?: string;
  updated_at: number;
  created_at: number;
  subscription: OrganizationSubscriptionType;
  subscription_details: IApiOrganizationSubscription;
  organization_members: ApiOrganizationMember[];
  patterns: ApiPattern[];
  products: ApiProduct[];
  palletizing_projects: ApiPalletizingProject[];
  production_lines: ApiProductionLine[];
  partner?: boolean;
  scenes: ApiScene[];
  simulations: ApiSimulation[];
  strategies: ApiStrategy[];
  robotConfigurations?: ApiRobotConfiguration[];
  installedRobots?: ApiInstalledRobot[];
  metadata?: {
    address: string;
    country: { name: string; value: string };
    state: { name: string; value: string };
  };
  parent_id?: string;
  logo?: string | { url: string }[];
  organization_relations?: {
    id: string;
    organization_a_id: string;
  }[];
  simulations_aggregate?: {
    aggregate: {
      count: number;
    };
  };
}

export interface IOrganizationUserData {
  id?: string;
  user_id?: string;
  name: string;
  email: string;
  metadata: any;
  type: string;
  organization_id: string;
}

export interface IOrganizationInvite {
  id: string;
  user_id?: string;
  organization_id: string;
  member_type: { types: string[] };

  organization: {
    name: string;
    type: OrganizationType;
  };
}

export interface IOrganizationInviteData extends IOrganizationInvite {
  organization: {
    name: string;
    type: OrganizationType;
  };
}

export interface IOrganizationInviteUserData {
  email: string;
  id: string;
  name: string;
  updated_at: string;
}

export interface IAggregateObj {
  aggregate: {
    count: number;
  };
}

export class ApiOrganization implements IApiOrganization {
  id: string;
  creator_id: ApiUser['id'];
  name: string;
  type: OrganizationType;
  location?: string;
  updated_at: number;
  created_at: number;
  subscription: OrganizationSubscriptionType;
  subscription_details: ApiOrganizationSubscription;
  organization_members: ApiOrganizationMember[];
  organization_members_aggregate?: IAggregateObj;
  customers?: ApiOrganization[];
  robotConfigurations: ApiRobotConfiguration[] = [];
  robot_configurations_aggregate?: IAggregateObj;
  scenes: ApiScene[];
  scenes_aggregate?: IAggregateObj;
  patterns: ApiPattern[];
  patterns_aggregate?: IAggregateObj;
  products: ApiProduct[];
  products_aggregate?: IAggregateObj;
  palletizing_projects: ApiPalletizingProject[];
  installed_robots: ApiInstalledRobot[];
  installed_robots_aggregate?: IAggregateObj;
  production_lines: ApiProductionLine[];
  simulations: ApiSimulation[];
  simulations_aggregate?: IAggregateObj;
  simulationsByStatus?: {
    Created: number;
    Initializing: number;
    Running: number;
    Finished: number;
    Error: number;
    Canceled: number;
    Queued: number;
    Validate: number;
    Successful: number;
    Setting_up: number;
    Starting_robot: number;
    Palletizing: number;
  };
  avg_simulation_time?: string;
  total_simulation_time?: string;
  strategies: ApiStrategy[];
  strategies_aggregate?: IAggregateObj;
  logo?: string;
  metadata?: {
    address: string;
    country: { name: string; value: string };
    state: { name: string; value: string };
  };
  vendor?: boolean;
  vendor_filter_name?: string;
  partner?: boolean;

  organization_relations?: {
    id: string;
    organization_a_id: string;
  }[];

  parent_id?: string;

  constructor(organization: IApiOrganization) {
    for (const d in organization) {
      if (organization.hasOwnProperty(d)) {
        this[d] = organization[d];
      }
    }
  }

  makeRobotConfig() {
    if (this.scenes?.length && this.strategies?.length) {
      // Make robot configuration from a scene and strategy with the same simulation id.
      this.scenes.forEach((scene: ApiScene) => {
        this.strategies.forEach((strat: ApiStrategy) => {
          if (scene.simulations?.length && strat.simulations?.length) {
            const alike = strat.simulations.find((sim: ApiSimulation) => {
              return scene.simulations.map((m) => m.id).includes(sim.id);
            });

            if (alike) {
              this.robotConfigurations.push(
                new ApiRobotConfiguration({
                  id: scene.id + '|' + strat.id + '_robot_config',
                  scene: scene,
                  strategy: strat,
                })
              );
            }
          }
        });
      });
    }
  }

  getOrganizationNumber(key: string): IInventoryStatus {
    const numbers: IInventoryStatusObject = {
      installed_robots: {
        name: 'Installed robots',
        number: this.installed_robots_aggregate.aggregate.count,
        status: 'Installed Robots',
        img: '../../../assets/illustrative/dashboard_card_robots.png',
        icon: 'smart_toy',
        disableCreateMenu: false,
        disableViewMenu: false,
        createAction: 'create_installed_robot',
        viewAllAction: 'view_all_installed_robot',
        relatedPages: [
          {
            label: 'From backup file',
            icon: 'add',
            roleAction: 'create_installed_robot',
            value: 'installed_robots_info_card_create_click',
            type: 'create',
            disable: false,
          },
          {
            label: 'Robots',
            roleAction: 'view_all_installed_robot',
            value: 'installed_robots_info_card_view_all_click',
            type: 'view',
            disable: false,
          },
        ],
      },
      projects: {
        name: 'Projects',
        viewAllAction: 'view_all_project',
        createAction: 'create_project',
        img: '../../../assets/illustrative/dashboard_card_projects.png',
        icon: 'engineering',
        disableCreateMenu: true,
        disableViewMenu: true,
        number: 0,
        status: this.palletizing_projects?.length > 1 ? 'projects' : 'project',
        relatedPages: [
          {
            label: 'Project',
            icon: 'add',
            roleAction: 'create_project',
            value: 'projects_info_card_create_click',
            type: 'create',
          },
          {
            label: 'Projects',
            roleAction: 'view_all_project',
            value: `projects_info_card_view_all_click`,
            type: 'view',
          },
        ],
      },
      simulations: {
        name: 'Simulations',
        viewAllAction: 'view_all_simulation',
        createAction: 'create_simulation',
        disableCreateMenu: false,
        disableViewMenu: false,
        number: this.simulations_aggregate.aggregate.count,
        status:
          this.simulations_aggregate.aggregate.count > 1
            ? 'simulations'
            : 'simulation',
        img: '../../../assets/illustrative/dashboard_card_simulations.png',
        icon: 'slideshow',
        relatedPages: [
          {
            label: 'Simulation',
            icon: 'add',
            roleAction: 'create_simulation',
            value: 'simulations_info_card_create_click',
            type: 'create',
            disable: false,
          },
          {
            label: 'Simulations',
            roleAction: 'view_all_simulation',
            value: `simulations_info_card_view_all_click`,
            disable: false,
            type: 'view',
          },
        ],
      },
      products: {
        name: 'Products',
        viewAllAction: 'view_all_product',
        createAction: 'create_product',
        disableCreateMenu: false,
        disableViewMenu: false,
        img: '../../../assets/illustrative/dashboard_card_products.png',
        icon: 'view_in_ar',
        number: this.products.length,
        status: this.products.length > 1 ? 'products' : 'product',
        relatedPages: [
          {
            label: 'Product',
            icon: 'add',
            roleAction: 'create_product',
            value: 'products_info_card_create_click',
            type: 'create',
            disable: false,
          },
          {
            label: 'Products',
            roleAction: 'view_all_product',
            value: `products_info_card_view_all_click`,
            disable: false,
            type: 'view',
          },
        ],
      },
      production_lines: {
        name: 'Production lines',
        viewAllAction: 'view_all_production_line',
        createAction: 'create_production_line',
        disableCreateMenu: false,
        disableViewMenu: false,
        img: '../../../assets/illustrative/dashboard_card_production_lines.png',
        icon: 'route',
        number: this.production_lines ? this.production_lines.length : 0,
        status:
          this.production_lines?.length > 1
            ? 'production lines'
            : 'production line',
        relatedPages: [
          {
            label: 'Production line',
            icon: 'add',
            roleAction: 'create_production_line',
            value: 'production_lines_info_card_create_click',
            type: 'create',
            disable: false,
          },
          {
            label: 'Production line',
            roleAction: 'view_all_production_line',
            value: `production_lines_info_card_view_all_click`,
            disable: false,
            type: 'view',
          },
        ],
      },
      patterns: {
        name: 'Patterns',
        viewAllAction: 'view_all_pattern',
        createAction: 'create_pattern',
        disableCreateMenu: false,
        disableViewMenu: false,
        number: this.patterns ? this.patterns.length : 0,
        status: this.patterns.length > 1 ? 'patterns' : 'pattern',
        img: '../../../assets/illustrative/dashboard_card_patterns.png',
        icon: 'view_comfy',
        relatedPages: [
          {
            label: 'Pattern',
            icon: 'add',
            roleAction: 'create_pattern',
            value: 'patterns_info_card_create_click',
            type: 'create',
            disable: false,
          },
          {
            label: 'Patterns',
            roleAction: 'view_all_pattern',
            value: `patterns_info_card_view_all_click`,
            disable: false,
            type: 'view',
          },
        ],
      },
      robot_configurations: {
        name: 'Robot configurations',
        viewAllAction: 'view_all_robot_configuration',
        createAction: 'create_robot_configuration',
        disableCreateMenu: false,
        disableViewMenu: false,
        number: this.robotConfigurations ? this.robotConfigurations.length : 0,
        status: this.patterns.length > 1 ? 'project' : 'projects',
        img: '../../../assets/illustrative/dashboard_card_robot_config.png',
      },
      inventory: {
        name: 'Hardware library',
        number: null,
        status: 'Hardware library',
        img: '../../../assets/illustrative/dashboard_card_hw_lib.png',
      },
      organization_members: {
        name: 'Organization members',
        number: this.organization_members_aggregate.aggregate.count,
        status:
          this.organization_members_aggregate.aggregate.count > 1
            ? 'members'
            : 'member',
        img: '../../../assets/illustrative/dashboard_card_members.png',
      },
    };

    return numbers[key];
  }

  getInfoCard(key: string): IInfoCardNewConfig {
    const numbers: IInventoryStatus = this.getOrganizationNumber(key);

    const card: IInfoCardNewConfig = {
      id: key,
      title: numbers.name,
      text: `<h3 style="font-size: 18px; margin-bottom: 0px; text-transform: capitalize;">
        ${numbers.number !== null ? numbers.number : ''} 
        ${numbers.status}
      </h3>`,
      img: numbers.img ? numbers.img : null,
      icon: numbers.icon,
      disableCreateMenu: numbers.disableCreateMenu,
      disableViewMenu: numbers.disableViewMenu,
      createActions: [],
      viewActions: [],
    };

    if (numbers.relatedPages) {
      numbers.relatedPages.forEach((page) => {
        if (page.type === 'view') {
          delete page['type'];
          card.viewActions.push(page);
        }

        if (page.type === 'create') {
          delete page['type'];
          card.createActions.push(page);
        }
      });
    }
    return card;
  }
}
