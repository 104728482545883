<app-landing-toolbar></app-landing-toolbar>

<ng-container *ngIf="contentfulAboutCollection$ | async as contentfulAbout">
  <div
    *appVar="getContentByTag(contentfulAbout) as pageContent"
    class="page-about-wrapper"
  >
    <div class="about-content">
      <div class="about-header">
        <button
          class="about-navigateBack-button"
          mat-button
          color="primary"
          (click)="navigateBack()"
        >
          <mat-icon>arrow_back</mat-icon> Go Back
        </button>
        <!-- About-Title: Displays a the article's title and subtitle. -->
        <h1 class="about-title">
          {{ pageContent.header.title }}
        </h1>
        <div
          *ngIf="pageContent?.header?.titleSubtext"
          class="about-title-description"
        >
          <ng-container
            *ngIf="
              !pageContent.header.titleSubtext?.nodeType;
              else richTextSubtitle
            "
          >
            {{ pageContent.header.titleSubtext }}
          </ng-container>

          <ng-template #richTextSubtitle>
            <p
              [innerHTML]="
                pageContent.header.titleSubtext | richtextDocumentToHtml
              "
            ></p>
          </ng-template>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="about-body-card">
        <!-- About-Text: Just displays plain text. -->
        <div *ngIf="pageContent?.content?.text" class="about-text-description">
          <ng-container *ngFor="let richTextEntry of pageContent.content.text">
            <ng-container *ngIf="!richTextEntry.data?.url; else richTextAsset">
              <p [innerHtml]="richTextEntry | richtextDocumentToHtml"></p>
            </ng-container>
            <ng-template #richTextAsset>
              <div
                *ngIf="richTextEntry.data.__typename === 'Asset'"
                class="about-text-description-assets"
              >
                <img
                  [src]="richTextEntry.data.url"
                  [alt]="richTextEntry.data?.title || ''"
                />
              </div>
            </ng-template>
          </ng-container>
        </div>

        <!-- About-List: Display a list of arguments by points. Can be accopmained of a text into by using the previous "content.text". -->
        <div *ngIf="pageContent?.content?.list" class="about-list-description">
          <ul>
            <li *ngFor="let listItem of pageContent.content.list">
              <p>{{ listItem }}</p>
            </li>
          </ul>
        </div>

        <!-- About-FAQ: Displays a list of expandables Questions whose answers become visible when being clicked. -->
        <div *ngIf="pageContent?.content?.faq" class="about-faq">
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel
              *ngFor="let listItem of pageContent.content.faq"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ listItem.question }}</mat-panel-title>
              </mat-expansion-panel-header>
              <p
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(listItem.answer)"
              ></p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <!-- About-Video: Displays an embeded video-Iframe. Currently supported for YouTube links. -->
        <div *ngIf="pageContent?.content?.youTubeEmbed" class="about-video">
          <iframe
            [src]="pageContent?.content?.youTubeEmbed | trust: 'resourceUrl'"
            title="YouTube video player"
            class="embedded-youtube"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>

      <!-- About-Conclusive-Footer: Text to be shown below the main about body, in a conclusive asecpect. -->
      <div
        *ngIf="
          pageContent?.conclusiveFooter?.titleSubtext ||
          pageContent?.conclusiveFooter?.title
        "
        class="about-conclusive-footer"
      >
        <h2 class="about-title">
          {{ pageContent.conclusiveFooter.title }}
        </h2>
        <div class="about-title-description">
          <ng-container
            *ngIf="
              !pageContent.conclusiveFooter.titleSubtext?.nodeType;
              else richTextSubtitle
            "
          >
            {{ pageContent.conclusiveFooter.titleSubtext }}
          </ng-container>

          <ng-template #richTextSubtitle>
            <p
              [innerHTML]="
                pageContent.conclusiveFooter.titleSubtext
                  | richtextDocumentToHtml
              "
            ></p>
          </ng-template>
        </div>
      </div>
      <button
        class="about-navigateBack-button"
        mat-button
        color="primary"
        (click)="navigateBack()"
      >
        <mat-icon>arrow_back</mat-icon> Go Back
      </button>
    </div>
  </div>
</ng-container>

<app-fast-track-footer></app-fast-track-footer>
