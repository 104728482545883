import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

export interface ResizeEvent {
  id: string;
  width: number;
  height: number;
}

// Helper class for easier trigger for rendering, resize and screenshot capture
export class RenderTrigger {
  public static readonly render$ = new ReplaySubject<string>(1);
  public static readonly resize$ = new ReplaySubject<ResizeEvent>(1);
  public static readonly screenshotTrigger$ = new Subject<string>();
  public static readonly screenshotOutput$ = new Subject<{
    id: string;
    img: HTMLImageElement;
  }>();
  public static readonly enableRenderingLoop$ = new BehaviorSubject<boolean>(
    false
  );
  public static readonly enableXR$ = new BehaviorSubject<boolean>(false);
}
