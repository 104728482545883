import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import {
  Observable,
  map,
  BehaviorSubject,
  Subject,
  takeUntil,
  of,
  mergeMap,
  switchMap,
  combineLatest,
  shareReplay,
} from 'rxjs';
import { StateService } from '../../auth/state.service';
import { ApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import {
  ApiStrategy,
  IApiStrategy,
} from '../../models_new/classes/api-models/ApiStrategy';
import { ObjectUtils } from '../../utils/object';
import { RXJSUtils } from '../../utils/rxjs-utils';
import { ErrorHandlerService } from '../error-handler.service';
import { IInsertSimulationResponse } from './api.service';
import { ClientApiService } from './client-api.service';
import { IHwSwType } from '../../models_new/types/robot-config/hw-sw-type';

@Injectable({
  providedIn: 'root',
})
export class SoftwareApiService implements OnDestroy {
  softwares$: Observable<IApiStrategy[]>;

  // Software types
  swTypes$: BehaviorSubject<IHwSwType[]> = new BehaviorSubject<IHwSwType[]>(
    undefined
  );

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private stateService: StateService,
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {
    // Organization products
    this.softwares$ = this.stateService.getCustomerOrSalesOrganization().pipe(
      takeUntil(this.destroy$),
      RXJSUtils.filterUndefinedAndNull(),
      switchMap((org) => {
        return combineLatest([
          this.fetchStrategies(org.id, true),
          this.fetchSalesOrgsStrategies(org.id),
        ]).pipe(
          map(([orgs_sw, sales_orgs_sw]) => orgs_sw.concat(sales_orgs_sw)),
          takeUntil(this.destroy$),
          shareReplay({ bufferSize: 1, refCount: true })
        );
      })
    );

    this.stateService.user$
      .pipe(switchMap(() => this.fetchSwTypes()))
      .subscribe((type: IHwSwType[]) => {
        this.swTypes$.next(type);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /* ------------------------ Database query functions ------------------------ */

  fetchStrategiesQuery(
    organizationId: string,
    selectDefaultStrategies: boolean = false
  ): Observable<ApiStrategy[]> {
    const qf = gql`
    query fetchStrategiesQuery {
      strategy(where: {organization_id: {_eq: "${organizationId}"}}) {
        id
        name
        data
        created_at
        updated_at
        simulations {
          id
          name
        }
      }
    }
  `;

    return this.clientApi
      .useClient('org_view')
      .subscribe<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchSalesOrgsStrategyQuery(
    customer_org_id: string
  ): Observable<ApiStrategy[]> {
    const q = gql`
      query fetchSalesOrgsStrategyQuery {
        strategy(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${customer_org_id}"}}}}) {
          id
          name
          data
          created_at
          updated_at
          organization_id
          simulations {
            id
            name
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchSimWizSalesOrgsStrategies(
    customer_org_id: string
  ): Observable<ApiStrategy[]> {
    const q = gql`
      subscription {
        strategy(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${customer_org_id}"}}}}) {
          id
          name
          data
          updated_at
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchSalesOrgsStrategies(customer_org_id: string): Observable<ApiStrategy[]> {
    const q = gql`
      subscription {
        strategy(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${customer_org_id}"}}}}) {
          id
          name
          data
          created_at
          updated_at
          organization_id
          simulations {
            id
            name
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchSimWizStrategies(organizationId: string): Observable<ApiStrategy[]> {
    const q = gql`
    subscription {
      strategy(where: {organization_id: {_eq: "${organizationId}"}}) {
        id
        name
        data
        updated_at
      }
    }
  `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchStrategies(
    organizationId: string = null,
    selectDefaultStrategies: boolean = false
  ): Observable<ApiStrategy[]> {
    const q = gql`
      subscription {
        strategy {
          id
          name
          data
          created_at
          updated_at
          organization_id
          simulations {
            id
            name
          }
        }
      }
    `;

    const qd = gql`
      subscription {
        strategy(where: { organization_id: { _is_null: false } }) {
          id
          name
          data
          created_at
          updated_at
          organization_id
          simulations {
            id
            name
          }
        }
      }
    `;

    const qf = gql`
    subscription {
      strategy(where: {organization_id: {_eq: "${organizationId}"}}) {
        id
        name
        data
        created_at
        updated_at
        organization_id
        simulations {
          id
          name
        }
      }
    }
  `;

    const qe = gql`
    subscription {
      strategy(where: {_or: [{organization_id: {_eq: "${organizationId}"}},{organization_id: {_is_null: true}}]}) {
        id
        name
        data
        created_at
        updated_at
        organization_id
        simulations {
          id
          name
        }
      }
    }
  `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId
          ? selectDefaultStrategies
            ? qe
            : qf
          : selectDefaultStrategies
          ? q
          : qd,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            const list: ApiStrategy[] = [];
            for (const s of data.data.strategy) {
              const strategy = new ApiStrategy(ObjectUtils.cloneObject(s));
              strategy.simulations = strategy.simulations.map(
                (sim) => new ApiSimulation(sim)
              );
              list.push(strategy);
            }
            return list;
          }
        })
      );
  }

  fetchDefaultStrategies(): Observable<ApiStrategy[]> {
    const q = gql`
      subscription {
        strategy(
          where: {
            _and: [
              { owner_id: { _is_null: true } }
              { organization_id: { _is_null: true } }
            ]
          }
        ) {
          id
          name
          data
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.strategy;
          }
        })
      );
  }

  fetchStrategy(id: string): Observable<IApiStrategy> {
    const q = gql`query getStrategy {
      strategy_by_pk(id: "${id}") {
        id
        name
        data
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((data) => data.data?.strategy_by_pk));
  }

  insertStrategy(
    name: string,
    description: string,
    data: any,
    organization_id: string
  ): Observable<IInsertSimulationResponse> {
    return of(organization_id).pipe(
      mergeMap((orgId) => {
        const m = gql`
          mutation InsertStrategy(
            $name: String = ""
            $description: String = ""
            $organization_id: uuid!
            $data: json = ""
          ) {
            insert_strategy_one(
              object: {
                name: $name
                description: $description
                organization_id: $organization_id
                data: $data
              }
            ) {
              id
            }
          }
        `;

        const variables = {
          name: name,
          description: description,
          organization_id: orgId,
          data: data,
        };

        return this.clientApi
          .useClient('org_edit')
          .mutate<any>({
            mutation: m,
            variables: variables,
          })
          .pipe(map((value) => value.data.insert_strategy_one));
      })
    );
  }

  updateStrategy(
    id: string,
    name: string,
    description: string,
    data: any
  ): Observable<IInsertSimulationResponse> {
    const m = gql`
    mutation updateStrategy($name: String!, $description: String!, $data: json!) {
      update_strategy_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, description: $description, data: $data}) {
        id
      }
    }
    `;

    const variables = {
      name: name,
      description: description,
      data: data,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((value) => value.data.update_strategy_by_pk));
  }

  deleteStrategyById(id: string): Observable<string> {
    const m = gql`
      mutation deleteStrategy($id: uuid!) {
        delete_strategy_by_pk(id: $id) {
          name
        }
      }
    `;

    const variables = {
      id: id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.delete_strategy_by_pk.name));
  }

  fetchSwTypes(): Observable<IHwSwType[]> {
    const q = gql`
      query getSwTypes {
        sw_types {
          id
          label
          name
          metadata
          hw_sw_type {
            name
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.sw_types as IHwSwType[]));
  }

  fetchSwTypeByName(name: string): Observable<IHwSwType[]> {
    const q = gql`
    query getSwType {
      hw_sw_type(where: {name: {_eq: "${name}"}}) {
        sw_types {
          label
          id
          name
          metadata
        }
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => data.data.hw_sw_type[0].sw_types as IHwSwType[])
      );
  }
}
