<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Software configurations</h1>
    <app-header-actions>
      <button
        mat-raised-button
        pally-rounded-button-m
        color="primary"
        (click)="actionClicked({ actionId: 'create' })"
        *appIfCommandAvailable="'create_software'"
      >
        <mat-icon>add</mat-icon>New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="data$ | async as inputData">
    <!-- Loading state -->
    <app-loading *ngIf="inputData.isLoading"></app-loading>

    <app-content-switcher
      *ngIf="inputData.didSucceed"
      [enableToggle]="true"
      [selected]="blockSelected"
      [setupFilter]="setupFilter"
      [sortingColumns]="sortingColumns"
      objectType="software"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
      (order)="orderBy = $event"
      (delete)="onDelete()"
    >
      <ng-template #table>
        <app-table
          [overrideColumnTitles]="overrideColumns"
          [clickableRows]="true"
          [topRow]="false"
          [inputData]="inputData.value"
          [displayedColumns]="listColumns"
          [actions]="true"
          [sortBy]="orderBy"
          [filter]="tableFilter"
          (actionClicked)="actionClicked($event)"
          (rowClicked)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        ></app-table>
      </ng-template>

      <ng-template #cardList>
        <app-card-list-wrapper
          [inputData]="inputData.value"
          [filter]="tableFilter"
          [sortBy]="orderBy"
        >
          <ng-template #card let-data>
            <app-card-picker-list
              [type]="pickerType"
              [columnColors]="[]"
              [listColumnUnits]="[]"
              [doesAllowActions]="true"
              [listColumns]="listColumns"
              [listData]="data"
              [data]="data.data"
              (actionHandler)="actionClicked($event)"
            >
            </app-card-picker-list>
          </ng-template>
        </app-card-list-wrapper>
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
