<app-page>
  <div class="error-card-wrapper">
    <div class="icon-containers">
      <mat-icon class="rotate">settings</mat-icon>
      <mat-icon class="top-icon">error</mat-icon>
    </div>
    <p>
      Your simulation report access has expired for security purposes.
      <br />If you believe this is an error,
      <a href="mailto:contact@myrobot.cloud">please email us</a> to request
      renewed access. <br />We aim to respond promptly.
    </p>
  </div>
</app-page>
