import { ApiOrganization } from './ApiOrganization';
import { ApiPattern } from './ApiPattern';
import { ApiProduct } from './ApiProduct';
import { ApiProductionLine } from './ApiProduction_line';
import { ApiScene } from './ApiScene';
import { ApiSimulation } from './ApiSimulation';
import { ApiStrategy } from './ApiStrategy';

export interface IApiPalletizingProject {
  id: string;
  name: string;
  updated_at: string | number;
  owner_id: string;
  organization: ApiOrganization;
  products: ApiProduct[];
  patterns: ApiPattern[];
  production_lines: ApiProductionLine[];
  scenes: ApiScene[];
  strategies: ApiStrategy[];
  simulations: ApiSimulation[];
}

export class ApiPalletizingProject implements IApiPalletizingProject {
  id: string;
  name: string;
  updated_at: string | number;
  created_at: string | number;
  owner_id: string;
  organization: ApiOrganization;
  products: ApiProduct[];
  patterns: ApiPattern[];
  production_lines: ApiProductionLine[];
  scenes: ApiScene[];
  strategies: ApiStrategy[];
  simulations: ApiSimulation[];

  constructor(project: IApiPalletizingProject) {
    for (const d in project) {
      if (project.hasOwnProperty(d)) {
        this[d] = project[d];
      }
    }
  }
}
