import {
  ExpandableTableContentType,
  ExpandableTableItem,
  IExpandableTableAction,
  IExpandableTableData,
  IExpandableTableRowButton,
} from '../types/expandable-table-data';
import {
  ITableAction,
  ITableData,
} from '../../components/gui/table/table.component';
import { ObjectUtils } from 'src/app/utils/object';

/** Builder class to convert raw data into ITableData
 * used for table-expandable. */
export class ExpandableTableConverter<ActionType> {
  private _data: any[];

  private _columns: {
    label: string;
    value: string | number | Function;
    unit?: string;
    link_id?: string;
  }[] = [];

  private _rowActions: IExpandableTableAction<ActionType>[] | Function = [];
  private _rowButtons: IExpandableTableRowButton<ActionType>[] | Function;
  private _leftContentWidth:
    | 'col-3'
    | 'col-4'
    | 'col-5'
    | 'col-6'
    | 'col-7'
    | 'col-8' = 'col-6';

  private _graphics: string = 'Unknown content';
  private _error: Function;

  private _orderBy: string = 'name';
  private _searchTerm: string = '';
  private _searchColumn: string = 'name';

  private _categories: Map<
    string,
    {
      label: string;
      value: string;
      items: ExpandableTableItem[];
    }
  > = new Map();

  constructor() {
    this._categories.set('__default', {
      label: '',
      value: '',
      items: [],
    });
  }

  /** Sets the data used for the expandable table.
   * @param data Array of data to create rows from. */
  public data(data: any[]): ExpandableTableConverter<ActionType> {
    this._data = data;
    return this;
  }

  /** Sets optional error message for rows.
   * @param error Function that should return error message. Takes row data. */
  public error(error: Function): ExpandableTableConverter<ActionType> {
    this._error = error;
    return this;
  }

  /** Adds new column to table data.
   * @param label Name of column used in top row.
   * @param value Value of column. Use '$' to insert data value from path.
   * @param unit Column unit.
   * @param link_id Link to navigate to when column is clicked. Value is returned as
   * action.element when #actionClicked is called. Use '$' to insert data value from path. */
  public addColumn(
    label: string,
    value: string | number | Function,
    unit?: string,
    link_id?: string
  ): ExpandableTableConverter<ActionType> {
    this._columns.push({
      label: label,
      value: value ? value : null,
      unit: unit,
      link_id: link_id,
    });
    return this;
  }

  /** Sets width of left content.
   * @param width Width of left content. */
  leftContentWidth(
    width: 'col-3' | 'col-4' | 'col-5' | 'col-6' | 'col-7' | 'col-8'
  ): ExpandableTableConverter<ActionType> {
    this._leftContentWidth = width;
    return this;
  }

  /** Adds new category for expanded mode.
   * Also adds column with same properties as category.
   * @param label Name of category.
   * @param id Unique id of category.
   * @param value Value of category row and column. Use '$' to insert data value from path.*/
  public addCategory(
    label: string,
    id: string,
    value?: string
  ): ExpandableTableConverter<ActionType> {
    this._categories.set(id, {
      label: label,
      value: value,
      items: [],
    });
    return this.addColumn(label, value);
  }

  /** Adds new row to expanded mode.
   * @param label Name of row.
   * @param value Value of row. Use '$' to insert data value from path.
   * @param category Will insert this row underneath category with provided id.
   * @param type Row type */
  addExpandableRow(
    label: string,
    value: string | number | Function,
    category?: string,
    type?: ExpandableTableContentType
  ): ExpandableTableConverter<ActionType> {
    const cat = category ? category : '__default';
    const item: ExpandableTableItem = new ExpandableTableItem({
      label: label,
      value: value ? value : null,
      type: type ? type : ExpandableTableContentType.TYPE_TWO_COLUMN_TEXT,
    });

    this._categories.get(cat).items.push(item);
    return this;
  }

  /** Sets row actions. */
  rowActions(
    actions: IExpandableTableAction<ActionType>[] | Function
  ): ExpandableTableConverter<ActionType> {
    this._rowActions = actions;
    return this;
  }

  /** Sets row buttons.
   * @param buttons Row buttons or function that returns array of row buttons. */
  rowButtons(
    buttons: IExpandableTableRowButton<ActionType>[] | Function
  ): ExpandableTableConverter<ActionType> {
    this._rowButtons = buttons;
    return this;
  }

  /** Sets graphics.
   * @param graphics URL to graphics. */
  graphics(graphics: string): ExpandableTableConverter<ActionType> {
    this._graphics = graphics;
    return this;
  }

  /** Sets column to order table by.
   * @param orderBy Column to order table by. */
  orderBy(orderBy: string): ExpandableTableConverter<ActionType> {
    this._orderBy = orderBy;
    return this;
  }

  /** Sets search term to filter data by.
   * @param searchTerm Filter input .
   * @param column Column to filter. Defaults to 'name'. */
  searchTerm(
    searchTerm: string,
    column: string = 'name'
  ): ExpandableTableConverter<ActionType> {
    this._searchTerm = searchTerm;

    this._searchColumn = column;
    return this;
  }

  /** Converts builder to array of ITableData. */
  public convert(): ITableData<ActionType>[] {
    const tableData: ITableData<ActionType>[] = [];
    let filteredData = this._data
      .filter(
        (d) =>
          this._searchTerm === '' ||
          ObjectUtils.getNested(d, this._searchColumn)
            .toLowerCase()
            .includes(this._searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (this._orderBy === 'name') {
          return ObjectUtils.getNested(a, this._orderBy) <
            ObjectUtils.getNested(b, this._orderBy)
            ? -1
            : 1;
        } else {
          return ObjectUtils.getNested(a, this._orderBy) <
            ObjectUtils.getNested(b, this._orderBy)
            ? 1
            : -1;
        }
      });

    filteredData.forEach((d) => {
      tableData.push({
        origin: d,
        data: this.getExpandableData(d),
        actions: this._rowActions as ITableAction<ActionType>[],
      });
    });
    return tableData;
  }

  private getExpandableData(data): IExpandableTableData<ActionType> {
    const expandableContent: ExpandableTableItem[] = [];
    for (const [key, value] of this._categories.entries()) {
      if (key !== '__default') {
        expandableContent.push({
          label: String(ExpandableTableConverter.mapValue(data, value.value)),
          title: value.label,
          value: ExpandableTableConverter.mapValue(data, value.value),
        });
      }
      for (const item of value.items) {
        expandableContent.push(
          new ExpandableTableItem({
            label: item.label,
            unit: item.unit,
            value: ExpandableTableConverter.mapValue(data, item.value),
          })
        );
      }
    }
    const columns: ExpandableTableItem[] = [];
    for (const item of this._columns) {
      let copy = new ExpandableTableItem(item);
      copy.value = ExpandableTableConverter.mapValue(data, item.value);
      if (item.link_id) {
        copy.link_id = String(
          ExpandableTableConverter.mapValue(data, item.link_id)
        );
      }
      columns.push(copy);
    }

    const rowActions: IExpandableTableAction<ActionType>[] = [];

    if (typeof this._rowActions === 'function') {
      rowActions.push(...this._rowActions(data));
    } else {
      for (const action of this._rowActions) {
        let copy = { ...action };
        copy.link_id = String(
          ExpandableTableConverter.mapValue(data, action.link_id)
        );
        rowActions.push(copy);
      }
    }

    let rowButtons: IExpandableTableRowButton<ActionType>[] = [];
    if (typeof this._rowButtons === 'function') {
      rowButtons.push(...this._rowButtons(data));
    } else {
      rowButtons.push(...this._rowButtons);
    }

    return {
      id: data.id,
      data: data,
      error: this._error ? this._error(data) : undefined,
      items: columns,
      rowActions: rowActions,
      rowButtons: rowButtons,
      expandableContent: {
        graphicalResources: ExpandableTableConverter.mapValue(
          data,
          this._graphics
        ),
        leftContentWidth: this._leftContentWidth,
        items: expandableContent,
      },
    };
  }

  private static mapValue(
    data: any,
    value: string | number | Function | any[]
  ): string | any[] | number {
    if (!value) {
      return '~';
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'function') {
      return value(data);
    }
    if (value instanceof Array) {
      return value;
    }
    if (value.startsWith('$')) {
      return ObjectUtils.getNested(data, value.replace('$.', ''));
    }
    if (value.includes('$')) {
      const split = value.split('$');
      return (
        split[0] +
        ObjectUtils.getNested(
          data,
          split[1].startsWith('.')
            ? split[1].substring(1, split[1].length)
            : split[1]
        )
      );
    }
    return value;
  }
}
