import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { delay, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as THREE from 'three';
import { ThreeHandler } from 'src/app/models_new/classes/3dview/three-handler';
import { ThreeRendererService } from 'src/app/services/3dview/three-renderer.service';
import { TimedObject3DService } from 'src/app/services/timed-object3d.service';
import { ProjectRobotDescriptorService } from 'src/app/services/project-robot-descriptor.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import { SceneStore } from 'src/app/public/scene-store';
import { CanvasStore } from 'src/app/public/canvas-store';
import { RenderTrigger } from 'src/app/public/render-trigger';
import {
  CameraType,
  InputHandlerService,
} from 'src/app/services/3dview/input-handler.service';
import { threeHandlerModeConfig } from 'src/app/models_new/config/3dview/threemode-handler-config';
import { View3DEventsService } from 'src/app/services/3dview/view3d-events.service';
import { ThreePerspective } from 'src/app/models_new/enums/three-perspective';
import { ThreePerspectiveService } from 'src/app/services/3dview/three-perspective.service';
import { Project } from 'src/app/models_new/classes/project';
import { DataService } from 'src/app/services/data.service';
import { v4 as uuidv4 } from 'uuid';
import { PalletViewHandler } from 'src/app/models_new/classes/3dview/handlers/pallet-view-handler';

@Component({
  selector: 'app-three-view',
  templateUrl: './three-view.component.html',
  styleUrls: ['./three-view.component.scss'],
  providers: [
    View3DEventsService,
    InputHandlerService,
    ThreeRendererService,
    TimedObject3DService,
    ProjectRobotDescriptorService,
  ],
})
export class ThreeViewComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  ThreeHandlerMode = ThreeHandlerMode;

  // Unique instance id
  @Input() ID = uuidv4();
  @Input() mode: ThreeHandlerMode;

  @Input() project?: Project;
  @Input() interactive?: boolean;
  @Input() choosePerspective?: boolean = false;
  @Input() perspective?: CameraType;

  @Input() set cameraPosition(pos: THREE.Vector3) {
    if (pos) {
      this.inputHandler.setCameraPostion(pos);
      this.inputHandler.updateControls();
      this.cameraRepositioned.emit(true);
    }
  }

  @ViewChild('canvas', { static: true })
  private canvasRef: ElementRef<HTMLCanvasElement>;

  threeHandler$: BehaviorSubject<ThreeHandler> =
    new BehaviorSubject<ThreeHandler>(undefined);
  get threeHandler(): any {
    return this.threeHandler$.getValue();
  }

  loading$: Observable<boolean>;

  private timerID: any;
  private timerDuration = 0;

  @Output() cameraRepositioned: EventEmitter<boolean> = new EventEmitter();
  @Output() emitImage?: EventEmitter<string> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  resizeObserver: ResizeObserver;

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private threeRenderer: ThreeRendererService,
    public inputHandler: InputHandlerService,
    private threeEvents: View3DEventsService,
    private threePerspective: ThreePerspectiveService,
    private dataService: DataService,
    private elementRef: ElementRef
  ) {
    // Has to be done after init!
    this.threeHandler$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1),
        switchMap((_: ThreeHandler) => RenderTrigger.screenshotOutput$),
        takeUntil(this.destroy$),
        filter((data) => {
          return data.id === this.ID;
        }) // Accept only my screenshots
      )
      .subscribe((data: { id: string; img: HTMLImageElement }) => {
        if (data.img) {
          setTimeout(() => {
            this.emitImage.emit(data.img.src);
          });
        }
      });
  }

  ngOnInit() {
    this.threeRenderer.threeID$.next(this.ID);
    this.inputHandler.threeID$.next(this.ID);

    this.resizeObserver = new ResizeObserver((entries) => {
      this.onResize();
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngAfterViewInit() {
    // No specific project given, fetch default.
    if (!this.project) {
      this.project = this.dataService.getProject();
    }

    CanvasStore.addCanvas(
      this.ID,
      this.canvasRef.nativeElement,
      this.elementRef.nativeElement
    );

    this.threeEvents.canvasFetched$.next(true);

    this.threeEvents.beforeCameraSetup$.next(true);
    this.inputHandler.setCamera(
      this.perspective ? this.perspective : CameraType.Perspective
    );
    this.threeEvents.cameraMade$.next(true);

    SceneStore.addScene(this.ID, new THREE.Scene());
    this.threeEvents.sceneBuilt$.next(true);

    this.onResize();

    const objType = threeHandlerModeConfig.get(this.mode).type;
    this.threeHandler$.next(new objType(this.ID, this.injector, this.project));
    this.threeEvents.threeHandlerConstructed$.next(true);

    const handler$ = this.threeHandler$.pipe(
      takeUntil(this.destroy$),
      RXJSUtils.filterUndefinedAndNull(),
      take(1)
    );

    handler$.subscribe((handler: ThreeHandler) => {
      handler.init();
      handler.addLighting();
      this.threeEvents.threeHandlerInitialized$.next(true);

      handler.afterViewInit();
      this.threeEvents.threeHandlerAfterViewInitialized$.next(true);
    });

    // Since the handler is created after the view has initialize (ngAfterViewInit) we will
    // get a warning if we update the value of loading. To mitigate this we add a delay(0)
    // to push the update to the next event loop update. Could be mitigated if we allow three view
    // handler to update together with other angular data, instead of after the view has been initialized.
    this.loading$ = handler$.pipe(
      switchMap((handler) => {
        return handler.loading$;
      }),
      delay(0),
      tap((loading) => this.loading.emit(loading))
    );

    // Run rendering outside angular to avoid change detection (i.e. updating UI on change — performance optimization)
    this.render();

    this.threeEvents.setup3DViewFinished$.next(true);

    this.threeHandler$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1),
        switchMap((handler: ThreeHandler) =>
          combineLatest([
            this.threePerspective.perspective$.pipe(
              takeUntil(this.destroy$),
              RXJSUtils.filterUndefinedAndNull()
            ),
            of(handler),
          ])
        )
      )
      .subscribe({
        next: ([perspective, handler]) => {
          handler.handlePerspective(perspective);
        },
        error: (err) => console.error(err),
      });

    if (typeof this.interactive !== 'undefined' && !this.interactive) {
      this.inputHandler.forbidAll();
    }

    this.timedResizeLoop(3000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.threeHandler$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((handler: ThreeHandler) => {
        handler.onChanges(changes);
      });
  }

  ngOnDestroy() {
    this.threeHandler$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((handler: ThreeHandler) => {
        handler.destroy$.next(true);
        handler.destroy$.complete();
      });
    this.destroy$.next(true);
    this.destroy$.complete();

    CanvasStore.removeCanvas(this.ID);
    SceneStore.removeScene(this.ID);
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
    this.threeRenderer.kill();
  }

  render() {
    this.ngZone.runOutsideAngular(() => {
      RenderTrigger.render$.next(this.ID);
    });
  }

  @HostListener('resize')
  onResize() {
    this.ngZone.runOutsideAngular(() => {
      RenderTrigger.resize$.next({
        id: this.ID,
        width: this.elementRef.nativeElement.clientWidth,
        height: this.elementRef.nativeElement.clientHeight,
      });
    });
  }

  getManualScreenShot() {
    // Has to be done after init!
    this.threeHandler$
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((handler: ThreeHandler) => {
        RenderTrigger.screenshotTrigger$.next(this.ID);
      });
  }

  @HostListener('mouseover')
  activateControls(): void {
    this.inputHandler.enablePan();
  }

  @HostListener('mouseleave')
  deactivateControls(): void {
    this.inputHandler.disablePan();
  }

  setPerspective(p: ThreePerspective): void {
    //
  }

  setProject(p: Project): void {
    this.project = p;
    if (this.threeHandler$) {
      const threeHandler = this.threeHandler$.getValue();
      if (threeHandler instanceof PalletViewHandler) {
        threeHandler.setProject(p);
      }
    }
  }

  timedResizeLoop(duration?: number): void {
    this.timerID = setTimeout(this.timedResizeLoop.bind(this, duration), 50);

    this.onResize();

    this.timerDuration += 100;

    if (this.timerDuration >= (duration ? duration : 1000)) {
      clearTimeout(this.timerID);
      this.timerID = undefined;
      this.timerDuration = 0;
    }
  }
}
