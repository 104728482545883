import { radians } from 'src/app/utils/geometry-utils';
import * as THREE from 'three';
import { InstancedMesh } from 'three';
import { ThreeUtils } from '../../../utils/three-utils';
import { LabelOrientation } from '../../enums/label-orientation';
import { ArrowGeometry, FrontArrow } from './arrow';
import { InstancedSticker, Sticker } from './sticker';

interface IBoxSize {
  width: number;
  height: number;
  length: number;
}

/**
 * Coordinate system
 *
 *                         +------------------------+                          ^ Y-axis (UP)
 *                        /                        /|                          |
 *                       /                        / |                          |
 *                      /                        /  |                          |
 *                     /          TOP           /   |                          |
 *                    /                        /    |                          |
 *                   /                        /     |                          +---------> X-axis
 *                  +------------------------+      |                         /
 *                  |                        |      |                        /
 *         LEFT     |                        |      |  RIGHT                / Z-axis
 *                  |                        |      |
 *                  |                        |      |
 *                  |                        |      +
 *                  |         FRONT          |     /
 *                  |                        |    /
 *                  |                        |   /
 *                  |                        |  /
 *                  |                        | /
 *                  |                        |/
 *                  +------------------------+
 *
 */
export class DynamicBox extends THREE.Object3D {
  texture: THREE.Texture;
  labels: Sticker[];
  labelOrientations: LabelOrientation[];

  readonly bb = new THREE.Box3();
  frontIndicator: THREE.Mesh | undefined;

  /**
   * Create a box 3D object that can be dynamically resized. Supports logo on the box
   *
   * @param boxMesh the mesh model to use for the box. It is assumed to be 1x1x1 meters.
   */
  constructor(private boxMesh: THREE.Mesh) {
    super();

    this.add(boxMesh);
  }

  setLabels(texture: THREE.Texture) {
    this.texture = texture;

    if (this.labelOrientations) {
      this.setLabelOrientations(this.labelOrientations);
    } else {
      // "setLabelOrientations" didnt update our boxes so update them.
      this.updateLabels();
    }
  }

  setLabelOrientations(orientations: LabelOrientation[]) {
    this.labelOrientations = orientations;

    // Dispose previous labels!
    if (this.labels?.length > 0) {
      for (const label of this.labels) {
        label.removeFromParent();
        ThreeUtils.disposeObject(label);
      }
    }

    if (this.labelOrientations && this.texture) {
      // Filter out null values, meaning no labels
      this.labelOrientations = this.labelOrientations.filter((v) => v !== null);

      this.labels = this.labelOrientations.map(() => new Sticker(this.texture));

      if (this.labels.length > 0) {
        this.add(...this.labels);
      }
    }
    this.updateLabels();
  }

  /**
   * Set the size of the box, given in meters
   */
  setSize({ width, height, length }: IBoxSize) {
    // The size of the box is only applied to the inner box mesh
    // This ensures that we can control the helper objects without rescaling them

    this.boxMesh.scale.set(width, height, length);
    this.updateBB();
    this.updateLabels();
    this.updateFrontIndicator();
  }

  updateBB(): void {
    this.updateMatrixWorld();
    this.bb.makeEmpty();
    this.bb.setFromObject(this.boxMesh);
  }

  get width() {
    return this.boxMesh.scale.x;
  }

  get height() {
    return this.boxMesh.scale.y;
  }

  get length() {
    return this.boxMesh.scale.z;
  }

  get size() {
    this.updateBB();
    return this.bb.getSize(new THREE.Vector3());
  }
  get min() {
    this.updateBB();
    return this.bb.min.clone();
  }
  get max() {
    this.updateBB();
    return this.bb.max.clone();
  }

  /**
   * Update the geometry and material of the box
   *
   * @param geometry
   * @param material
   */
  setBox(
    geometry: THREE.BufferGeometry,
    material: THREE.Material | THREE.Material[]
  ) {
    this.boxMesh.geometry = geometry;
    this.boxMesh.material = material;
  }

  showFrontIndicator() {
    if (!this.frontIndicator) {
      this.frontIndicator = new FrontArrow();
      this.frontIndicator.rotation.x = -Math.PI / 2;
      this.add(this.frontIndicator);
      this.updateFrontIndicator();
    } else {
      this.frontIndicator.visible = true;
    }
  }

  hideFrontIndicator() {
    if (this.frontIndicator) {
      this.frontIndicator.visible = false;
    }
  }

  private updateFrontIndicator() {
    if (this.frontIndicator) {
      // The arrow is 1 unit long and 0.8 units wide.
      // Since the box length is along the arrow direction, it is defined as the face width
      const scale = computeMaxStickerSize(
        this.length,
        this.width,
        1 / 0.8
      ).multiplyScalar(0.5); // We only want it to be half the size of the top face

      this.frontIndicator.scale.copy(scale);

      this.frontIndicator.position.y = this.height / 2; // Move it up to the box face
      this.frontIndicator.position.z = scale.z / 2; // Move the arrow forward, centering it on the box
    }
  }

  private updateLabels() {
    if (this.labels) {
      // Iterate over each of the orientations
      for (const [j, orientation] of this.labelOrientations.entries()) {
        const label = this.labels[j];
        const boxSize = {
          width: this.width + 0.01,
          height: this.height + 0.01,
          length: this.length + 0.01,
        };

        label.position.copy(computeStickerPosition(orientation, boxSize));
        label.quaternion.copy(computeStickerOrientation(orientation));
        label.scale.copy(
          computeStickerScale(orientation, boxSize, this.labels[j].aspect)
        );
      }
    }
  }
}

export class InstancedDynamicBox extends THREE.Object3D {
  boxMesh: THREE.InstancedMesh;

  labels: InstancedSticker | undefined;
  labelOrientations: LabelOrientation[];

  locked = new Map<number, boolean>();

  frontIndicators: InstancedMesh | undefined;
  // TODO: Should be made to use InstancedMesh.
  boxLines: THREE.Object3D[] = [];

  boundingBox = new THREE.Box3();
  helper = new THREE.Box3Helper(this.boundingBox);
  showDebug: boolean;
  min: THREE.Mesh;
  max: THREE.Mesh;
  showOutLinesValue: boolean;

  /**
   * Create a instanced box 3D object that can be dynamically resized. Supports logo on the boxes.
   *
   * @param mesh the mesh model to use for the box. It is assumed to be 1x1x1 meters.
   */
  constructor(mesh: THREE.Mesh, count: number) {
    super();

    this.boxMesh = new THREE.InstancedMesh(mesh.geometry, mesh.material, count);

    this.add(this.boxMesh);
    this.helper.visible = false;
    this.add(this.helper);
  }

  setLabels(texture: THREE.Texture, orientations: LabelOrientation[]) {
    // Remove the old labels if present
    const oldLabels = this.getObjectByName('labels') as InstancedSticker;
    if (oldLabels) {
      oldLabels.removeFromParent();
      ThreeUtils.disposeObject(oldLabels);
    }

    this.labelOrientations = orientations;

    if (!this.labelOrientations) {
      return;
    }

    this.labels = new InstancedSticker(
      texture,
      this.boxMesh.count * this.labelOrientations.length
    );
    this.labels.name = 'labels';
    this.add(this.labels);

    this.labels.instanceMatrix.setUsage(this.boxMesh.instanceMatrix.usage);
    this.labels.instanceMatrix.needsUpdate = true;

    this.updateLabelsForBoxes();
  }

  setLabelsTexture(texture: THREE.Texture) {
    // Remove the old labels if present
    this.labels.setTexture(texture);
    this.labels.instanceMatrix.needsUpdate = true;

    this.updateLabelsForBoxes();
  }

  setLabelOrientations(orientations: LabelOrientation[]): void {
    if (this.labels) {
      this.setLabels(this.labels.getTexture(), orientations);
    }
  }

  showLabels(visible: boolean) {
    if (this.labels) {
      this.labels.visible = visible;
      this.updateLabelsForBoxes();
    }
  }

  showFrontIndicator() {
    if (!this.frontIndicators) {
      this.frontIndicators = new InstancedMesh(
        new ArrowGeometry(0.05),
        new THREE.MeshPhongMaterial({
          color: 0x156289,
          emissive: 0x072534,
          flatShading: true,
        }),
        this.boxMesh.count
      );
      this.add(this.frontIndicators);
      this.updateFrontIndicator();
    } else {
      // Show the previously hidden one
      this.frontIndicators.visible = true;
    }
  }

  hideFrontIndicator() {
    if (this.frontIndicators) {
      this.frontIndicators.visible = false;
    }
  }

  setCount(count: number) {
    this.boxMesh.count = count;
    if (this.labels) {
      this.labels.setCount(this.boxMesh.count * this.labelOrientations.length);
    }

    if (this.frontIndicators) {
      this.frontIndicators.count = this.boxMesh.count;
    }

    this.doUpdate();
  }

  getCount(): number {
    return this.boxMesh.count;
  }

  setMatrixAt(i, matrix: THREE.Matrix4, isLocked: boolean = false) {
    this.boxMesh.setMatrixAt(i, matrix);
    this.locked.set(i, isLocked);
    this.boxMesh.instanceMatrix.needsUpdate = true;

    if (this.labels) {
      this.updateLabelsForBox(i);
    }

    if (this.frontIndicators) {
      this.updateFrontIndicator(i);
    }
    if (this.boxLines) {
      this.updateOutlines(i);
    }
  }

  updateBB() {
    // Clear the box.
    this.boundingBox.makeEmpty();

    // Go through all boxes and find the extreme positions.
    for (let i = 0; i < this.boxMesh.count; i++) {
      const transform = this.decomposeMatrixAt(i);

      // Create a stupid dummy object using the matrix data.
      const dummy = new THREE.Mesh(
        this.boxMesh.geometry,
        this.boxMesh.material
      );
      dummy.position.copy(transform.position);
      dummy.quaternion.copy(transform.rotation);
      dummy.scale.copy(transform.scale);
      this.add(dummy);

      // Expand the bounding box by this box.
      this.boundingBox.expandByObject(dummy);

      // Clean up and remove the unecessary dummy object.
      this.remove(dummy);
      // Geometry is used to display the boxes, don't dispose here.
      dummy.geometry = undefined;
      dummy.material = undefined;
    }

    if (this.min && this.max) {
      this.min.position.copy(this.boundingBox.min);
      this.max.position.copy(this.boundingBox.max);
    }
  }

  decomposeMatrixAt(i: number): {
    position: THREE.Vector3;
    rotation: THREE.Quaternion;
    scale: THREE.Vector3;
  } {
    let matrix = new THREE.Matrix4();
    this.boxMesh.getMatrixAt(i, matrix);

    const transform = {
      position: new THREE.Vector3(),
      rotation: new THREE.Quaternion(),
      scale: new THREE.Vector3(),
    };
    matrix.decompose(transform.position, transform.rotation, transform.scale);
    return transform;
  }

  /**
   * Quality of life function to visualize the bounding box of
   * the box/boxes as well as it's minimum and maximum bounds,
   * visualized by a red (min) and green (max) sphere.
   *
   * Other possible things to add is the total physical dimensions of
   * the box/boxes, position in text and so on.
   * @param showDebugInfo { boolean } - Enabled debug info or not.
   */
  debug(showDebugInfo?: boolean): void {
    this.showDebug = showDebugInfo;
    this.helper.visible = showDebugInfo ? true : false;

    // Make and show debug info.
    if (this.showDebug) {
      const geo = new THREE.SphereGeometry(0.05);

      // Visualizes the minimum corner of the pallet.
      this.min = new THREE.Mesh(
        geo,
        new THREE.MeshLambertMaterial({ color: '#ff0000' })
      );
      this.add(this.min);

      // Visualizes the maximum corner of the pallet.
      this.max = new THREE.Mesh(
        geo,
        new THREE.MeshLambertMaterial({ color: '#00ff00' })
      );
      this.add(this.max);

      // Hide and destroy debug info.
    } else if (this.min && this.max) {
      this.remove(this.min);
      this.remove(this.max);
      ThreeUtils.disposeObject(this.min);
      ThreeUtils.disposeObject(this.max);
    }
  }

  doUpdate() {
    this.boxMesh.instanceMatrix.needsUpdate = true;

    if (this.labels) {
      this.labels.instanceMatrix.needsUpdate = true;
    }

    if (this.frontIndicators) {
      this.frontIndicators.instanceMatrix.needsUpdate = true;
    }
    this.updateOutlines();
  }

  setUsage(usage: THREE.Usage) {
    this.boxMesh.instanceMatrix.setUsage(usage);
    if (this.labels) {
      this.labels.instanceMatrix.setUsage(usage);
    }
    if (this.frontIndicators) {
      this.frontIndicators.instanceMatrix.setUsage(usage);
    }
  }

  private updateLabelsForBox(index: number) {
    this.updateLabelsForBoxes(index, index + 1);
  }

  public updateLabelsForBoxes(start = 0, end = this.boxMesh.count) {
    this.labels.count = this.boxMesh.count * this.labelOrientations.length;

    const boxMatrix = new THREE.Matrix4();
    const boxScale = new THREE.Vector3();

    // We can use the box matrix as the initial starting point, and then compute all the sticker
    // transforms in the box coordinate system.

    for (let i = start; i < end; i++) {
      // Extract the box matrix. We use this to get the box size and we multiply
      // our calculations with it at the end. This allow us to use the box coordinate system
      // when computing the sticker position.
      this.boxMesh.getMatrixAt(i, boxMatrix);

      // Extract scale and position
      boxScale.setFromMatrixScale(boxMatrix);

      if (boxScale.x === 0 || boxScale.y === 0 || boxScale.z === 0) {
        return;
      }

      const boxSize = {
        width: boxScale.x,
        height: boxScale.y,
        length: boxScale.z,
      };

      const isLocked = this.locked.get(i);

      // Iterate over each of the orientations
      for (const [j, orientation] of this.labelOrientations.entries()) {
        const scale = computeStickerScale(
          orientation,
          boxSize,
          this.labels.aspect
        );

        // Convert to scale in box coordinates system
        const relativeScale = scale.divide(boxScale);

        const scaleMatrix = new THREE.Matrix4().makeScale(
          /*
          NOTE!
          Labelorientations are always only 2 long. 
          If the lable we're trying to position is the secondary (at index 1)
          label position and the box is locked, set it's scale to 0, hiding it.
          */
          isLocked && j == 1 ? 0 : relativeScale.x,
          isLocked && j == 1 ? 0 : relativeScale.y,
          isLocked && j == 1 ? 0 : relativeScale.z
        );

        const orientationMatrix = new THREE.Matrix4().makeRotationY(
          radians(orientation || 0)
        );

        // Positioning is also done in the box coordinate system,
        // So basically we are just moving in 0.5 in the correct direction
        let position = new THREE.Vector3();
        if (orientation === LabelOrientation.FRONT) {
          position.z = 0.5;
        } else if (orientation === LabelOrientation.BACK) {
          position.z = -0.5;
        } else if (orientation === LabelOrientation.LEFT) {
          position.x = -0.5;
        } else if (orientation === LabelOrientation.RIGHT) {
          position.x = 0.5;
        }

        const transitionMatrix = new THREE.Matrix4().setPosition(position);

        const matrix = boxMatrix
          .clone()
          .multiply(transitionMatrix)
          .multiply(scaleMatrix)
          .multiply(orientationMatrix);

        this.labels.setMatrixAt(i + j * this.boxMesh.count, matrix);
      }
    }
    this.labels.instanceMatrix.needsUpdate = true;
  }

  private updateFrontIndicator(start = 0, end = this.boxMesh.count) {
    const boxMatrix = new THREE.Matrix4();
    const boxScale = new THREE.Vector3();

    // We can use the box matrix as the initial starting point, and then compute all the sticker
    // transforms in the box coordinate system.

    for (let i = start; i < end; i++) {
      // Extract the box matrix. We use this to get the box size and we multiply
      // our calculations with it at the end. This allow us to use the box coordinate system
      // when computing the sticker position.
      this.boxMesh.getMatrixAt(i, boxMatrix);

      // Extract scale and position
      boxScale.setFromMatrixScale(boxMatrix);

      // Iterate over each of the orientations
      const scale = computeMaxStickerSize(
        boxScale.z,
        boxScale.x,
        1 / 0.8
      ).multiplyScalar(0.5);

      // Convert to scale in box coordinates system
      const relativeScale = scale.divide(boxScale);

      const scaleMatrix = new THREE.Matrix4().makeScale(
        relativeScale.x,
        relativeScale.y,
        relativeScale.z
      );

      const orientationMatrix = new THREE.Matrix4().makeRotationFromEuler(
        new THREE.Euler(-Math.PI / 2)
      );

      // Positioning is also done in the box coordinate system,
      const position = new THREE.Vector3(0, 0.5, relativeScale.z / 2);
      const transitionMatrix = new THREE.Matrix4().setPosition(position);

      const matrix = boxMatrix
        .clone()
        .multiply(transitionMatrix)
        .multiply(scaleMatrix)
        .multiply(orientationMatrix);

      this.frontIndicators.setMatrixAt(i, matrix);
    }
    this.frontIndicators.instanceMatrix.needsUpdate = true;
  }

  showOutlines(showOutLines: boolean) {
    this.showOutLinesValue = showOutLines;
    this.updateOutlines();
  }

  private updateOutlines(start = 0, end = this.boxMesh.count) {
    // Remove and dispose of them anyway.
    this.boxLines.forEach((boxLine) => {
      this.remove(boxLine);
      ThreeUtils.disposeObject(boxLine);
    });
    this.boxLines = [];

    // If they're not gonna be visible, there's no need to update them.
    if (!this.showOutLinesValue) {
      return;
    }

    for (let i = start; i < end; i++) {
      const boxMatrix = new THREE.Matrix4();
      this.boxMesh.getMatrixAt(i, boxMatrix);

      const matrix = boxMatrix.clone();

      //Create the outline
      const boxLineGeometry = new THREE.EdgesGeometry(this.boxMesh.geometry);
      const boxLineMaterial = new THREE.LineBasicMaterial({
        color: 0x000000,
        linewidth: 1,
      });

      const boxLine = new THREE.LineSegments(boxLineGeometry, boxLineMaterial);
      boxLine.applyMatrix4(matrix);

      this.boxLines.push(boxLine);

      this.add(boxLine);
    }
  }
}

/**
 *
 * Currently only supports the sides of the box (not TOP / BOTTOM)
 */
function computeStickerScale(
  boxFace: LabelOrientation,
  boxSize: IBoxSize,
  stickerAspectRatio: number
): THREE.Vector3 {
  // We start by determining the box face aspect ratio
  const faceHeight = boxSize.height;
  let faceWidth;
  if (boxFace === LabelOrientation.FRONT || boxFace === LabelOrientation.BACK) {
    faceWidth = boxSize.width;
  } else {
    // Default to LEFT / RIGHT
    faceWidth = boxSize.length;
  }

  return computeMaxStickerSize(
    faceWidth,
    faceHeight,
    stickerAspectRatio
  ).multiplyScalar(0.5); // We divide it by 0.5 to make the sticker only take up half the space on the limiting axis.
}

/**
 * Compute the maxium sticker scale on a given face.
 * The resulting size makes the sticker fit inside the given face
 */
function computeMaxStickerSize(
  faceWidth: number,
  faceHeight: number,
  stickerAspectRatio: number
) {
  /*
   * Logo scale
   *
   * The goal is to have the logo sticker take up at max half the width or hight of the box. And we
   * need to scale the sticker equally across all axes to prevent skewing the image. Since the box
   * and the logo might have different aspect ratios we need to figure out if the width or height
   * axis is the limiting axis.
   *
   * The aspect ratio is defined as the width / height.
   *
   * If the aspect ratio of the box face is larger than the aspect ratio of the logo, it means
   * that the box is _more wide_ than the logo, thus the heigth axis is the limiting axis. In the opposite
   * case, the width is the limiting axis.
   *
   * Once we have determined the limiting axis we can scale the logo according to that box axis.
   * Again we have two diffent cases, depending on the aspect ratio of the logo. Lets draw the 4 cases to make it
   * clearer. The outer rectangle is the box face, the inner rectangle is the logo.
   *
   *
   *
   *    Box aspect > 1: Height is the restricting axis
   *    ==============================================
   *
   *    Logo aspect > 1                                      Logo aspect < 1
   *    ┌────┬─────────┬────┐                                ┌───────┬───┬───────┐
   *    │    │         │    │                                │       │   │       │
   *    │    │         │    │                                │       │   │       │
   *    │    │         │    │                                │       │   │       │
   *    └────┴─────────┴────┘                                └───────┴───┴───────┘
   *
   *    Logo texture has the size                            Logo texture has the size
   *    width = 1, height = 1 / logoAspect.                  width = logoAspect, height = 1
   *
   *    To scale it correctly, such that the                 To scale it correctly, such that the
   *    logo height equals the face height we                logo height equals the face height we
   *    compute it as:                                       simply us it directly:
   *
   *    logoHeight = faceHeight * (1 / logoAspect)           logoHeight = faceHeight
   *
   *
   *
   *
   *    Box aspect < 1: Width is the restricting axis
   *    -------------------------------------------------------------
   *
   *
   *    Logo aspect > 1                                      Logo aspect < 1
   *    ┌───────┐                                            ┌───────┐
   *    │       │                                            │       │
   *    │       │                                            ├───────┤
   *    │       │                                            │       │
   *    ├───────┤                                            │       │
   *    │       │                                            │       │
   *    ├───────┤                                            │       │
   *    │       │                                            │       │
   *    │       │                                            ├───────┤
   *    │       │                                            │       │
   *    └───────┘                                            └───────┘
   *
   *    Logo texture has the size                            Logo texture has the size
   *    width = 1, height = 1 / logoAspect                   width = logoAspect, heigth = 1
   *
   *    To scale it correctly, such that the                 To scale it correctly, such that the
   *    logo height equals the face height we                logo height equals the face height we
   *    simply use it directly:                              compute it as:
   *
   *    logoWidth = faceWidth                                logoWidth = faceWidth * (1 / logoAspect)
   *
   *
   * Now we just set the scale matching the logoHeight or logoWidth to get a logo that fits inside
   * the box face.
   */
  const boxAspectRatio = faceWidth / faceHeight;

  if (boxAspectRatio > stickerAspectRatio) {
    // Box more streched in width. Height should be the restricting axis

    if (stickerAspectRatio > 1) {
      // Works for a stickerAspect > 1
      const logoHeight = stickerAspectRatio * faceHeight;
      return new THREE.Vector3(logoHeight, logoHeight, logoHeight);
    } else {
      // stickerAspect < 1
      return new THREE.Vector3(faceHeight, faceHeight, faceHeight);
    }
  } else {
    // Width is the restricting axis

    // Works for stickerAspect > 1 : OKOK
    if (stickerAspectRatio > 1) {
      return new THREE.Vector3(faceWidth, faceWidth, faceWidth);
    } else {
      // stickerAspect < 1
      const logoWidth = faceWidth / stickerAspectRatio;
      return new THREE.Vector3(logoWidth, logoWidth, logoWidth);
    }
  }
}

/**
 * Computes the sticker position
 */
function computeStickerPosition(
  boxFace: LabelOrientation,
  boxSize: IBoxSize
): THREE.Vector3 {
  let position = new THREE.Vector3();
  if (boxFace === LabelOrientation.FRONT) {
    position.z = boxSize.length / 2;
  } else if (boxFace === LabelOrientation.BACK) {
    position.z = -boxSize.length / 2;
  } else if (boxFace === LabelOrientation.LEFT) {
    position.x = -boxSize.width / 2;
  } else if (boxFace === LabelOrientation.RIGHT) {
    position.x = boxSize.width / 2;
  }

  return position;
}

function computeStickerOrientation(
  boxFace: LabelOrientation
): THREE.Quaternion {
  const UP = new THREE.Vector3(0, 1, 0);
  return new THREE.Quaternion().setFromAxisAngle(UP, radians(boxFace || 0));
}
