import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ThreePallet } from 'src/app/models_new/classes/3dview/three-pallet';
import { Pallet } from 'src/app/models_new/classes/pallet';
import { Project } from 'src/app/models_new/classes/project';
import { PalletPosition } from 'src/app/models_new/enums/pallet-position';
import { AssetIDs } from '../../models_new/enums/asset-ids';
import { RXJSUtils } from '../../utils/rxjs-utils';
import { AssetStoreService } from '../asset-store.service';
import { LabelOrientation } from '../../models_new/enums/label-orientation';

export class ProjectThreeFactory {
  projectUpdateSub: Subscription;

  pallets$ = new ReplaySubject<Map<PalletPosition, ThreePallet>>(1);

  private readonly leftPalletXOffset = 0.333;

  private project: Project;
  private alwaysShowLabels: boolean;

  $ready: Observable<boolean>;

  constructor(project?: Project, alwaysShowLabels: boolean = false) {
    this.alwaysShowLabels = alwaysShowLabels;

    // We considered our self ready once we have loaded a project and
    // the box + pallet asset. The build() process it self could be included
    // but it becomes harder to follow the data flow. And it has been measured
    // to take about 5ms (1/3 of a frame), meaning it can be neglected.
    this.$ready = combineLatest([
      AssetStoreService.onAssetLoadedWithID(AssetIDs.Box),
      AssetStoreService.onAssetLoadedWithID(AssetIDs.EURPallet),
    ]).pipe(
      map(() => true),
      startWith(false) // Initially we are not ready
    );

    if (project) {
      // Update on project updates
      this.setProject(project);
    }
  }

  setProject(project: Project) {
    this.project = project;

    // Remove previous subscription
    if (this.projectUpdateSub) {
      this.projectUpdateSub.unsubscribe();
    }

    // Subscribe to future updates
    this.projectUpdateSub = this.project.update$
      .pipe(
        debounceTime(200),
        RXJSUtils.bufferUntilObsTrue(this.$ready, { bufferSize: 1 })
      )
      .subscribe(this.build.bind(this));
  }

  build(): void {
    const pallets = new Map<PalletPosition, ThreePallet>();
    for (let i = 0; i < this.project.pallets.length; i++) {
      const p = this.project.pallets[i];

      let oppositeLabelOrientation =
        (this.project.data.box.label.orientation + 180) % 360;
      if (oppositeLabelOrientation === 270) {
        oppositeLabelOrientation = LabelOrientation.LEFT;
      }

      // Make pallet
      const pallet = this.buildPallet(p, [
        this.project.data.box.label.orientation,
        // Add opposite direction for unlocked boxes
        oppositeLabelOrientation,
      ]);
      pallet.name = `${
        p.position === PalletPosition.RIGHT ? 'RIGHT' : 'LEFT'
      } pallet`;

      // Move pallet position if its the left pallet position and show all pallets enabled
      switch (p.position) {
        case PalletPosition.RIGHT: {
          pallet.setXPosition(pallet.edgeOffset.right + this.leftPalletXOffset);
          break;
        }
        case PalletPosition.LEFT: {
          pallet.setXPosition(pallet.edgeOffset.left - this.leftPalletXOffset);
          break;
        }
      }

      pallets.set(p.position, pallet);
    }
    this.pallets$.next(pallets);
  }

  private buildPallet(
    pallet: Pallet,
    labelOrientations: LabelOrientation[]
  ): ThreePallet {
    const isActive = pallet.position === this.project.getActivePalletPosition();
    const threePallet = new ThreePallet(pallet, labelOrientations, isActive);

    const palletView = this.project.getPalletView();
    const showFront = palletView.palletViewSettings.showFront;
    const labelEnabled =
      this.project.getProjectData().box.label.enabled &&
      (palletView.palletViewSettings.showLabelOrientation ||
        this.alwaysShowLabels);
    threePallet.updateBoxes(labelEnabled, showFront);

    return threePallet;
  }
}
