import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import {
  Observable,
  map,
  take,
  BehaviorSubject,
  switchMap,
  combineLatest,
  of,
  startWith,
} from 'rxjs';
import {
  IAboutContent,
  IAboutRawContentful,
} from 'src/app/models_new/types/about-card-content';
import { ObjectUtils } from 'src/app/utils/object';
import {
  IInfoCard,
  IButtonContent,
  IInfoCardConfig,
} from '../../models_new/types/info-card-config';
import {
  Document as richtextDocument,
  BLOCKS,
} from '@contentful/rich-text-types';
import { ClientApiService } from './client-api.service';
import { fast_track_phases } from 'src/app/models_new/config/fast_track_phase';
import { ErrorHandlerService } from '../error-handler.service';
export interface IOpenSimContent {
  mainTitle: string;
  cardTitle: string;
  cardSubtitle: string;
  cardText: string;
  cardLongText: {
    json: richtextDocument;
  };
  cardLongerText: string;
  callToActionButton1?: string;
  callToActionButton2?: string;
  step: number;
}

export interface ISimulationFooterElement {
  name?: string;
  header: string;
  description: {
    json: richtextDocument;
  };
  image: {
    url: string;
  };
  actionButtons?: {
    buttons: {
      name: string;
      url: string;
      title: string;
    }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class InfoApiService {
  infoCards$: BehaviorSubject<IInfoCardConfig[]> = new BehaviorSubject<
    IInfoCardConfig[]
  >(null);

  constructor(
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {
    // Fetch project list info into infoCards$
    this.getProjectListInfoCards()
      .pipe(take(1))
      .subscribe((infoCards: IInfoCardConfig[]) => {
        this.infoCards$.next(infoCards);
      });
  }

  public getOpenSimInfo(): Observable<IOpenSimContent[]> {
    const q = gql`
      query getOpenSimInfo {
        openSimulationContentCollection {
          items {
            cardText
            cardTitle
            cardSubtitle
            callToActionButton1
            callToActionButton2
            mainTitle
            step
            cardLongText {
              json
            }
            cardLongerText
            callToActionButton1
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data.data.openSimulationContentCollection.items;
        })
      );
  }

  getPhaseOfResearchConfig(): Observable<{ title: string; options: string[] }> {
    const q = gql`
      query getPhaseOfResearchField {
        phaseOfResearchField(id: "PGakYvtlMmD8Eal8vhRXR") {
          options
          title
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ phaseOfResearchField: { title: string; options: string[] } }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.warn('Missing options from contentful, using fallback');
            return {
              options: fast_track_phases,
              title: 'Level of interest',
            };
          } else {
            return data.data.phaseOfResearchField;
          }
        })
      );
  }

  getLandingPageInfo(): Observable<IInfoCard> {
    const q = gql`
      query getLandingPageInfo {
        landingPageContent(id: "52YidOaXuvnFxsaFJtWvN1") {
          image {
            url
          }
          text
          title
          learnMoreLink
          gdprLink
          ctaButtonText
          youTubeEmbed
          extraImagesCollection {
            items {
              url
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data.data.landingPageContent;
        })
      );
  }

  getLandingPageFooterInfo(): Observable<IInfoCard[]> {
    const q = gql`
      query getLandingPageFooterInfo {
        landingPageFooterContentCollection(order: sortOrder_ASC) {
          items {
            text
            title
            sortOrder
            content {
              json
            }
            linkList
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data.data.landingPageFooterContentCollection.items;
        })
      );
  }

  getProjectListInfoCards(): Observable<IInfoCardConfig[]> {
    const q = gql`
      query getProjectListInfoCards {
        projectListInfoCollection(order: sys_id_DESC) {
          items {
            title
            actions
            text
            imagesCollection {
              items {
                url
              }
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          const items: IInfoCardConfig[] = ObjectUtils.cloneObject(
            data.data.projectListInfoCollection.items
          );
          items.map(
            (i) =>
              (i.imgs = i.imagesCollection.items.map((img: any) => img.url))
          );
          return items;
        })
      );
  }

  getSimulationDetailsInfoCards(): Observable<IInfoCard[]> {
    const q = gql`
      query getSimDetailsInfoCollection {
        simDetailsInfoCollection {
          items {
            identifier
            title
            text
            learnMoreLink
            actionButton1Content
            image {
              url
              title
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data.simDetailsInfoCollection.items));
  }

  getPatternInfoCards(): Observable<IInfoCard[]> {
    const q = gql`
      query getPatternInfoCollection {
        patternInfoCollection {
          items {
            identifier
            title
            text
            image {
              url
              title
            }
            learnMoreLink
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data.patternInfoCollection.items));
  }

  getSolutionInfoCards(): Observable<IInfoCard[]> {
    const q = gql`
      query getSolutionInfoCollection {
        solutionInfoCollection {
          items {
            identifier
            title
            text
            image {
              url
              title
            }
            learnMoreLink
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data.solutionInfoCollection.items));
  }

  getSoftwareInfoCards(cardId: string): Observable<IInfoCard[]> {
    const q = gql`
      query getSoftwareConfigInfoCollection {
        softwareConfigInfoCollection(where: { identifier: "${cardId}" }) {
          items {
            identifier
            title
            text
            image {
              url
              title
            }
            learnMoreLink
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data.softwareConfigInfoCollection.items));
  }

  public getAboutContentCards(): Observable<IAboutContent[]> {
    const q = gql`
      query getAboutFaqContentCollection {
        aboutFaqContentCollection {
          items {
            title
            titleLegend {
              json
            }
            text {
              json
            }
            faq
            textList
            tag
            sys {
              id
            }
            youTubeEmbed
            conclusiveTitle
            conclusiveTitleLegend {
              json
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(
        switchMap((data) => {
          var itemList: IAboutRawContentful[] = ObjectUtils.cloneObject(
            data?.data?.aboutFaqContentCollection?.items
          );
          if (itemList)
            return combineLatest(
              itemList
                .filter((item) =>
                  item.text?.json?.content.find(
                    (content) => content.nodeType === 'embedded-asset-block'
                  )
                )
                .map((item) => this.getAboutContentCardsAssets(item['tag']))
            ).pipe(
              startWith<IAboutRawContentful[]>([]),
              map((assetResponse) => {
                assetResponse?.forEach((asset) => {
                  itemList.find((item) => item.tag === asset.tag).text.links =
                    asset?.text?.links;
                });
                itemList;
                var aboutContent: IAboutContent[] = [];
                itemList.forEach((item) => {
                  aboutContent.push({
                    tag: item.tag,
                    header: {
                      title: item.title,
                      titleSubtext: item.titleLegend?.json,
                    },
                    content: {
                      text: this.extractRichTextAssets(item),
                      list: item.textList,
                      faq: item.faq,
                      youTubeEmbed: item.youTubeEmbed,
                    },
                    conclusiveFooter: {
                      title: item.conclusiveTitle,
                      titleSubtext: item.conclusiveTitleLegend?.json,
                    },
                  });
                });
                return aboutContent;
              })
            );
          else return of(null);
        })
      );
  }

  public getPermissionsInfoCards(): Observable<IInfoCard[]> {
    const q = gql`
      query getPermissionsInfoCollection {
        permissionsInfoCollection {
          items {
            id
            text
            title
            identifier
            learnMoreLink
            actionButton1Content
            image {
              url
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return null;
          } else {
            return data.data.permissionsInfoCollection.items;
          }
        })
      );
  }

  /**
   * @method getAboutContentCards
   * @description Contentful limits queries by complexity. When fetching content linked to assets, quering the assets makes
   * the query too complex, so those have to be fetched apart. This function will only request assets for designated contents.
   * @param {string} tag
   * @returns {Observable<IAboutRawContentful>}
   */
  private getAboutContentCardsAssets(
    tag: IAboutContent['tag']
  ): Observable<IAboutRawContentful> {
    const q = gql`
      query getAboutFaqContentCollectionAssets {
        aboutFaqContentCollection(
          where: { tag_contains: "${tag}" }
          limit: 1
        ) {
          items {
            tag
            text {
              links {
                assets {
                  block {
                    title
                    url
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data?.data?.aboutFaqContentCollection?.items[0]));
  }

  /**
   * @method extractRichTextAssets
   * Fetches Assets from missing rich-text content.
   * @description This is meant to be a temporary work-around as looks like Contentful's richtextDocumentToHtml is omitting
   * inserted images from the conversion. Here images will be extracted so can be displayed separately, but yet, along the text.
   * @param {richTextAndAssets[]} richTextItem
   */
  public extractRichTextAssets(
    richTextItem: IAboutRawContentful
  ): richtextDocument[] {
    var richTextAndAssets: richtextDocument[] = [];
    const richTextInput = richTextItem.text?.json;
    const itemAssets = richTextItem.text?.links?.assets?.block;
    if (itemAssets?.length > 0 && richTextInput) {
      let object: { content: any[]; data: any; nodeType: any } = {
        content: [],
        data: {},
        nodeType: BLOCKS.DOCUMENT,
      };
      for (let i = 0; i < richTextInput.content.length; i++) {
        insertAndPush(
          richTextInput.content[i].nodeType === 'embedded-asset-block'
        );
        function insertAndPush(resetObject?: boolean) {
          if (resetObject) {
            richTextAndAssets.push(object);
            object = { content: [], data: {}, nodeType: BLOCKS.DOCUMENT };
            richTextInput.content[i];
            richTextAndAssets.push({
              content: [],
              data: itemAssets.find(
                (asset: any) =>
                  asset.sys.id === richTextInput.content[i].data.target.sys.id
              ),
              nodeType: BLOCKS.DOCUMENT,
            });
          } else {
            object.content.push(richTextInput.content[i]);
            richTextInput.content[i];
            if (i == richTextInput.content.length - 1)
              richTextAndAssets.push(object);
          }
        }
      }
    } else {
      return [richTextInput];
    }
    return richTextAndAssets;
  }

  getSimulationReportFooterElements(): Observable<ISimulationFooterElement[]> {
    const q = gql`
      query getSimulationFooterElements {
        simulationReportFooterElementCollection {
          items {
            name
            header
            actionButtons
            description {
              json
            }
            image {
              url
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            console.warn(
              'No active simulation footer elements published in Contentful'
            );
            return [];
          } else {
            return data.data.simulationReportFooterElementCollection?.items;
          }
        })
      );
  }

  getButtonContentByTag(contentfulTag: string): Observable<IButtonContent[]> {
    const q = gql`
      query getButtonContentByTag {
        mrcButtonContentCollection(where: {contentfulMetadata: {tags: {id_contains_all: ["${contentfulTag}"]}}}) {
          items {
            content
            target
            nameTag
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data.data.mrcButtonContentCollection?.items;
        })
      );
  }
}
