<ng-container *appVar="appLayout.isVertical$ | async as verticalLayout">
  <div class="pallet-report-layout">
    <app-header>
      <h1
        app-overline
        style="display: grid; grid-template-columns: 1fr auto; gap: 1em"
        [ngStyle]="verticalLayout ? { 'line-height': '16px;' } : {}"
      >
        Pallet Report
      </h1>

      <h2
        *ngIf="pdfData"
        app-title
        style="display: flex; margin: 0; align-items: center; width: 100%"
      >
        {{ pdfData.details.project_name }}
      </h2>

      <app-company-logo
        #orgLogo
        [orgId]="orgId"
        [assetUrl]="assetUrl"
      ></app-company-logo>

      <app-header-actions>
        <img
          height="50px"
          src="/assets/dummy/pally_logo_blue.png"
          alt="Pally"
          style="margin-right: 2em"
        />
        <img
          height="50px"
          src="/assets/mrc-logo/MRC-logo-blue.png"
          alt="MyRobot.Cloud"
        />
      </app-header-actions>
    </app-header>

    <h2>Summary</h2>

    <div class="report-summary three-pallet-img" *ngIf="pdfData">
      <div class="report-summary-info">
        <mat-card appearance="outlined">
          <mat-card-subtitle class="subtitle"
            >CUBE EFFICIENCY</mat-card-subtitle
          >
          <mat-card-title class="title">
            {{ pdfData.pallets[1].cubic_efficiency }}%
          </mat-card-title>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-subtitle class="subtitle"
            >NUMBER OF CASES</mat-card-subtitle
          >
          <mat-card-title class="title">
            {{ pdfData.pallets[1].boxesNo }}
          </mat-card-title>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-subtitle class="subtitle"
            >TOTAL LOADING HEIGHT</mat-card-subtitle
          >
          <mat-card-title class="title">
            {{ pdfData.pallets[1].grs_height + ' mm' | unitSystem }}
          </mat-card-title>
        </mat-card>
      </div>
      <div class="report-image-container">
        <img
          class="report-image"
          src="{{ pdfData.pallets[1].image }}"
          alt="Pallet"
        />
        <img
          *ngIf="pdfData.pallets[1].imageTop"
          class="report-image top-view"
          src="{{ pdfData.pallets[1].imageTop }}"
          alt="Pallet"
        />
      </div>
    </div>

    <div class="report-summary" style="margin-top: 1em" *ngIf="pdfData">
      <mat-card appearance="outlined">
        <mat-card-subtitle>
          <div class="subtitle">
            <mat-icon svgIcon="product"></mat-icon>PRODUCT
          </div>
        </mat-card-subtitle>
        <mat-card-content>
          <div class="key-val">
            <span>Width</span>
            <span>{{ pdfData.box.width + ' mm' | unitSystem }}</span>
          </div>
          <div class="key-val">
            <span>Length</span>
            <span>{{ pdfData.box.length + ' mm' | unitSystem }}</span>
          </div>
          <div class="key-val">
            <span>Height</span>
            <span>{{ pdfData.box.height + ' mm' | unitSystem }}</span>
          </div>
          <div class="key-val">
            <span>Weight</span>
            <span>{{ pdfData.box.weight + ' kg' | unitSystem }}</span>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-subtitle class="subtitle">
          <mat-icon svgIcon="pallet"></mat-icon>PALLET</mat-card-subtitle
        >
        <mat-card-content>
          <div class="key-val">
            <span>Width</span>
            <span>{{ pdfData.pallets[1].width + ' mm' | unitSystem }}</span>
          </div>
          <div class="key-val">
            <span>Length</span>
            <span>{{ pdfData.pallets[1].length + ' mm' | unitSystem }}</span>
          </div>
          <div class="key-val">
            <span>Height</span>
            <span>{{
              pdfData.pallets[1].net_height + ' mm' | unitSystem
            }}</span>
          </div>
          <div style="padding-top: 1em">
            <span>Center of Mass:</span>
            <div class="key-val">
              <span>X (From bottom left corner)</span>
              <span>{{
                pdfData.pallets[1].center_of_mass.x + ' mm' | unitSystem
              }}</span>
            </div>
            <div class="key-val">
              <span>Y (From bottom left corner)</span>
              <span>{{
                pdfData.pallets[1].center_of_mass.y + ' mm' | unitSystem
              }}</span>
            </div>
            <div class="key-val">
              <span>Height (Including pallet)</span>
              <span
                >{{ pdfData.pallets[1].center_of_mass.z + ' mm' | unitSystem }}
              </span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <h2>Pallet Details</h2>
    <app-table
      *ngIf="reportTableData"
      [strictFirstColWidth]="true"
      [showPaginator]="false"
      [actions]="false"
      [clickableRows]="false"
      [topRow]="false"
      [inputData]="reportTableData"
      [displayedColumns]="reportColumns"
    ></app-table>

    <div class="footer">
      <h3>Powered by Rocketfarm</h3>
      <img
        alt="Rocketfarm logo"
        width="250px"
        src="/assets/icons/rf_logo_color.png"
      />
    </div>
  </div>
</ng-container>

<app-pallet-report-view
  *ngIf="project && !(palletScreenShot$ | async)"
  class="view3d"
  [project]="project"
  (screenshot$)="palletScreenShot$.emit($event)"
></app-pallet-report-view>

<app-pallet-report-view
  *ngIf="project && !(palletTopScreenShot$ | async)"
  class="view3d"
  [project]="project"
  [cameraPos]="topCameraPos"
  [showOutLines]="true"
  [firstLayerOnly]="true"
  (screenshot$)="palletTopScreenShot$.emit($event)"
></app-pallet-report-view>
