export enum OrganizationType {
  CUSTOMER_ORGANIZATION = 'customer_organization',
  SALES_ORGANIZATION = 'sales_organization',
  BACKOFFICE_ORGANIZATION = 'backoffice_organization',
}

export const OrganizationTypeTitles: { [key in OrganizationType]: string } = {
  customer_organization: 'Customer',
  sales_organization: 'Sales',
  backoffice_organization: 'Backoffice',
};
