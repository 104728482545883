<app-page class="page-container" [ngClass]="{ dialog: isDialog }">
  <div *ngIf="robotConfig$ | async as robot">
    <app-breadcrumb-nav *ngIf="!isDialog"></app-breadcrumb-nav>

    <!-- Loading state -->
    <app-loading *ngIf="robot.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="robot.error as error" [error]="error"></app-error>

    <ng-container *ngIf="robot.didSucceed">
      <form [formGroup]="formGroup">
        <section>
          <div class="left">
            <app-header>
              <h1 app-overline *ngIf="!isDialog">
                {{ !robot.value ? 'Create' : 'Edit' }}
              </h1>
              <h2 app-title>Robot Configuration</h2>
            </app-header>
          </div>
        </section>
        <mat-divider></mat-divider>

        <app-edit-page>
          <h3 app-heading>General information</h3>
          <mat-form-field app-content appearance="fill">
            <mat-label>Robot configuration name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </app-edit-page>

        <app-edit-page>
          <h3 app-heading>Hardware configuration</h3>
          <app-config-preset-picker
            app-content
            [presets]="hardwarePresets$ | async"
            [presetType]="'hardware'"
            [disableInventoryPick]="true"
            [disableAdd]="robot.value !== null"
            [isDialog]="isDialog"
            (pickerSelect)="updateValue($event, 'hw')"
          ></app-config-preset-picker>
        </app-edit-page>

        <app-edit-page>
          <h3 app-heading>Software configuration</h3>
          <app-config-preset-picker
            app-content
            [presets]="softwarePresets$ | async"
            [presetType]="'software'"
            [disableInventoryPick]="true"
            [disableAdd]="robot.value !== null"
            [isDialog]="isDialog"
            (pickerSelect)="updateValue($event, 'sw')"
          ></app-config-preset-picker>
        </app-edit-page>
      </form>
    </ng-container>
  </div>
</app-page>

<ng-container *ngIf="isDialog; else pageActions">
  <div class="dialog-actions-container">
    <app-dialog-bottom-navbar
      title="Robot configuration"
      primaryButton="confirm"
      [secondaryButton]="'cancel'"
      (nextClick)="saveRobotConfig()"
      (backClick)="closeDialog()"
    ></app-dialog-bottom-navbar>
  </div>
</ng-container>
<ng-template #pageActions>
  <app-wizard-bottom-navbar
    title="Robot configuration"
    primaryButton="confirm"
    [secondaryButton]="'cancel'"
    (nextClick)="saveRobotConfig()"
    (backClick)="cancelRobotConfig()"
  ></app-wizard-bottom-navbar>
</ng-template>
