import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  FormControlStatus,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Observable,
  map,
  of,
  startWith,
  take,
  takeUntil,
  ReplaySubject,
  switchMap,
  tap,
  Subject,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { Field } from 'src/app/models_new/classes/field';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import {
  OrganizationType,
  OrganizationTypeTitles,
} from 'src/app/models_new/enums/organization-type';
import { FieldType } from 'src/app/models_new/types/field-type';
import {
  ICountryNState,
  PublicApiService,
} from 'src/app/services/api/public-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FileUtils } from 'src/app/utils/file-utils';
import { ObjectUtils } from 'src/app/utils/object';
import { RoleApiService } from '../../../services/api/role-api.service';
import { NotificationService } from '../../../services/notification.service';
import { customerEditCardFields } from './customer-edit-card-fields';
import { CustomerEditUrlPromptComponent } from './customer-edit-url-prompt/customer-edit-url-prompt.component';

type logoType = 'dummy' | 'api' | 'upload';

@Component({
  selector: 'app-customer-edit-card',
  templateUrl: './customer-edit-card.component.html',
  styleUrls: ['./customer-edit-card.component.scss'],
})
export class CustomerEditCardComponent implements OnInit, OnDestroy {
  fields: Field[] = ObjectUtils.cloneObject(customerEditCardFields);
  formGroup: UntypedFormGroup;

  logo$: Observable<DataRequestState<string>>;
  logoFormControl = new UntypedFormControl('');
  logoType: logoType;
  formIsUnvalid$: Observable<boolean>;

  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('file') fileClick: ElementRef;
  @Input() organization?: ApiOrganization;
  @Input() type?: OrganizationType;
  @Input() shouldOnlyShowGuiForChangingLogo: boolean;
  @Output() savedForm: EventEmitter<{
    address: ApiOrganization['metadata']['address'];
    country: ApiOrganization['metadata']['country'];
    state: ApiOrganization['metadata']['state'];
    logo: string;
    name: string;
  }> = new EventEmitter();

  canEdit$: Observable<boolean>;
  formChange$ = new Subject<void>();

  constructor(
    private errorHandler: ErrorHandlerService,
    private dialogService: DialogService,
    private publicApiService: PublicApiService,
    private roleApiService: RoleApiService,
    private notification: NotificationService
  ) {
    // If the user doesn't have necessary permissions, disable the fields.
    this.canEdit$ = this.roleApiService.availableActions$.pipe(
      takeUntil(this.destroy$),
      map((actions) => actions.includes('update_organization'))
    );
  }

  ngOnInit(): void {
    const fcs: UntypedFormControl[] = this.fields.map((m) => m.formControl);
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl('name', fcs[0]);
    this.formGroup.addControl('address', fcs[1]);
    this.formGroup.addControl('logo', this.logoFormControl);

    this.fetchCountryField()
      .pipe(
        tap((countryField: Field) => {
          this.fields.push(countryField);
          this.formGroup.addControl('country', countryField.formControl);
          // Fields in the form changed, handle it.
          this.formChange$.next();

          const fcs: UntypedFormControl[] = this.fields.map(
            (m) => m.formControl
          );
          if (this.organization) {
            this.patchFields(fcs);
          }
        }),
        switchMap(
          () =>
            this.fields.find((f) => f.id === 'country').formControl.valueChanges
        ),
        takeUntil(this.destroy$),
        switchMap((country: ICountryNState) =>
          this.publicApiService.getStates(country?.value)
        )
      )
      .subscribe({
        next: (states: ICountryNState[]) => {
          if (states.length) {
            this.addStateField(states);
          } else {
            this.removeStateField();
          }
        },
        error: (err) => this.errorHandler.handleError(err),
      });

    this.formChange$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.canEdit$),
        map((allowed: boolean) => {
          this.fields.map((f) => (allowed ? f.enable() : f.disable()));
        })
      )
      .subscribe();

    this.canEdit$
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map((allowed: boolean) => {
          if (!allowed) {
            this.notification.showError(
              'Can not edit organization, contact your representatives in your organization'
            );
          }
        })
      )
      .subscribe();

    this.formIsUnvalid$ = this.formGroup.statusChanges.pipe(
      map((status: FormControlStatus) => {
        if (status === 'VALID') {
          return false;
        } else {
          return true;
        }
      }),
      startWith(true)
    );

    if (this.organization?.logo) {
      this.setLogo(this.organization.logo, 'upload');
    } else {
      FileUtils.urlToBase64(
        '../../../../assets/dummy/dummy-image.png'
      ).subscribe({
        next: (base64: string) => this.setLogo(base64, 'dummy'),
        error: (err) => this.errorHandler.handleError(err),
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get title(): string {
    if (this.shouldOnlyShowGuiForChangingLogo) {
      return 'Change logo';
    }

    const type = this.type ?? this.organization?.type;
    return (
      OrganizationTypeTitles[type] ??
      OrganizationTypeTitles[OrganizationType.CUSTOMER_ORGANIZATION]
    );
  }

  patchFields(fcs: UntypedFormControl[]) {
    fcs[0].setValue(this.organization.name);
    fcs[1].setValue(this.organization.metadata?.address || '');
    fcs[2].setValue(this.organization.metadata?.country || '', {
      emitEvent: true,
    });
  }

  fetchCountryField(): Observable<Field> {
    return this.publicApiService
      .getCountries()
      .pipe(
        map(
          (countries: ICountryNState[]) =>
            new Field(
              FieldType.SELECT_SINGLE_OBJECT_COMPARE,
              true,
              '',
              [Validators.required],
              null,
              countries,
              null,
              { label: 'Country', name: 'country' },
              'country'
            )
        )
      );
  }

  addStateField(states: ICountryNState[]) {
    this.removeStateField();

    const stateField = new Field(
      FieldType.SELECT_SINGLE_OBJECT_COMPARE,
      true,
      '',
      [Validators.required],
      null,
      states,
      null,
      { label: 'State', name: 'state', showProperty: 'name' },
      'state'
    );

    this.fields.push(stateField);
    this.formGroup.addControl('state', stateField.formControl);
    this.formChange$.next();
    // Fields in the form changed, handle it.
  }

  removeStateField() {
    const hasState = this.fields.find((f) => f.id === 'state');

    if (hasState) {
      this.fields.pop();
      this.formGroup.removeControl('state');
      this.formChange$.next();
      // Fields in the form changed, handle it.
    }
  }

  setLogo(logo: string, logoType: logoType) {
    logoType !== 'dummy' ? this.logoFormControl.setValue(logo) : null;
    this.logoType = logoType;
    this.logo$ = of(logo).pipe(toRequestState());
  }

  findLogo() {
    const dialogRef = this.dialogService.showCustomDialog(
      CustomerEditUrlPromptComponent,
      DialogSize.MEDIUM
    );

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (base64: string) => {
          if (base64 && base64.length) {
            this.setLogo(base64, 'api');
          }
        },
        error: (err) => this.errorHandler.handleError(err),
      });
  }

  uploadFileEvent(event: any) {
    FileUtils.uploadFile(event)
      .pipe(
        take(1),
        map((file: string) => {
          this.setLogo(file, 'upload');
        })
      )
      .subscribe();
  }

  uploadFile() {
    this.fileClick.nativeElement.value = '';
    this.fileClick.nativeElement.click();
  }
}
