import { IInfoCardConfig } from '../../types/info-card-config';
import { IPallyFileType } from '../../types/pally-file-type';
import { ApiOrganization } from './ApiOrganization';
import { ApiProduct } from './ApiProduct';
import { ApiSimulation } from './ApiSimulation';
import { ApiUser } from './ApiUser';

export interface IApiPattern {
  id?: string;
  name: string;
  description?: string;
  data: IPallyFileType;
  owner_id: ApiUser['id'];
  organization_id: ApiOrganization['id'];
  created_at: string;
  updated_at: string;
  product_id: ApiProduct['id'];
  product: ApiProduct;
  simulations: ApiSimulation[];
  pattern_palletizing_projects?: IPatternPalletizingProject[];
  cpm?: number;
}

export class ApiPattern implements IApiPattern {
  id: string;
  name: string;
  description?: string;
  data: IPallyFileType;
  owner_id: ApiUser['id'];
  organization_id: ApiOrganization['id'];
  created_at: string;
  updated_at: string;
  product_id: ApiProduct['id'];
  product: ApiProduct;
  simulations: ApiSimulation[];
  pattern_palletizing_projects?: IPatternPalletizingProject[];
  cpm?: number;
  constructor(pattern: IApiPattern) {
    for (const d in pattern) {
      if (pattern.hasOwnProperty(d)) {
        this[d] = pattern[d];
      }
    }
    if (this.id === null) {
      this.id = Math.floor(Math.random() * 100).toString(); // TODO: this is mock data
    }
  }

  getListCard(): IInfoCardConfig {
    return {
      id: this.id,
      title: this.name,
      img: '../../../../assets/dummy/dummy-logo.jpg',
      text: `The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs.`,
      actions: [
        {
          label: 'CONTINUE TO PATTERN',
          value: 'continue_to_pattern_click',
        },
      ],
    };
  }
}

export interface IPatternPalletizingProject {
  id: string;
  palletizing_project_id: string;
}
