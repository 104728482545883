import { Observable, switchMap, tap, of, map, take } from 'rxjs';
import {
  ApiOrganization,
  IApiOrganization,
} from 'src/app/models_new/classes/api-models/ApiOrganization';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import {
  OrganizationApiService,
  UpdateOrganizationResult,
} from 'src/app/services/api/organization-api.service';
import { environment } from 'src/environments/environment';

export interface IUpdateCustomerFields {
  address?: ApiOrganization['metadata']['address'];
  country: ApiOrganization['metadata']['country'];
  state: ApiOrganization['metadata']['state'];
  logo?: string;
  name: string;
}

export class EditCustomer {
  constructor(
    private organizationApi: OrganizationApiService,
    private assetApi: AssetApiService
  ) {}

  makeFetchOrganizationObservable(orgId: string): Observable<ApiOrganization> {
    return this.organizationApi.getOrganizationByIdLight(orgId).pipe(
      tap({
        error: (error) => {
          if (environment.production) {
            console.warn(
              `Failed to get logo for organization ${orgId}, using dummy logo.`,
              error
            );
          }
        },
      }),
      map((o: IApiOrganization) => {
        const org = new ApiOrganization(o);
        return org;
      })
    );
  }

  updateOrganization(
    orgId: string,
    fields: IUpdateCustomerFields
  ): Observable<any> {
    return this.organizationApi
      .updateOrganization(orgId, fields.name, {
        address: fields.address,
        country: { name: fields.country.name, value: fields.country.value },
        state: fields.state
          ? { name: fields.state.name, value: fields.state.value }
          : null,
      })
      .pipe(
        take(1),
        switchMap((result: UpdateOrganizationResult) => {
          if (
            fields.logo &&
            !fields.logo.includes('assets/dummy/dummy-logo.jpg')
          ) {
            if (result.update_organization_by_pk.assets?.length) {
              return this.assetApi.updateAsset({
                id: result.update_organization_by_pk.assets[0].id,
                asset_type: 'logo',
                data: fields.logo.substring(
                  fields.logo.indexOf('base64,') + 7,
                  fields.logo.length
                ),
                file_type:
                  '.' +
                  fields.logo.substring(
                    fields.logo.indexOf('/') + 1,
                    fields.logo.indexOf('base64,') - 1
                  ),
                name: fields.name + '_logo',
                organization_id: orgId,
              });
            } else {
              return this.assetApi.uploadAsset({
                asset_type: 'logo',
                data: fields.logo.substring(
                  fields.logo.indexOf('base64,') + 7,
                  fields.logo.length
                ),
                file_type:
                  '.' +
                  fields.logo.substring(
                    fields.logo.indexOf('/') + 1,
                    fields.logo.indexOf('base64,') - 1
                  ),
                name: fields.name + '_logo',
                organization_id: orgId,
              });
            }
          } else {
            return of('');
          }
        })
      );
  }
}
