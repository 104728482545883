import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { IUser } from 'src/app/models_new/types/user';
import { IHasuraUser } from '../../models_new/types/hasura-user';
import { ClientApiService } from './client-api.service';
import { ICountryNState } from './public-api.service';
import { UserType } from 'src/app/models_new/types/signup-user-type';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private clientApi: ClientApiService) {}

  getUserData(userId: string): Observable<IHasuraUser> {
    const q = gql`
    query getUser {
      user_by_pk(id: "${userId}") {
        name
        email
        metadata
        organization_members {
          type
        }
      }
    }`;

    return this.clientApi
      .useClient('user')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data?.user_by_pk;
        })
      );
  }

  updateUserMetadata(
    metadata: IUser['https://login.myrobot.cloud/user_metadata']
  ): Observable<IHasuraUser> {
    const q = gql`
      query UpdateUserMetadata($metadata: json!) {
        UpdateUserMetadata(metadata: $metadata) {
          success
        }
      }
    `;

    const variables = {
      metadata: metadata,
    };

    return this.clientApi
      .useClient('user')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          if (data.data?.UpdateUserMetadata.success) {
            return data.data?.UpdateUserMetadata.success;
          } else {
            throw new Error(
              'Something went wrong when updating user metadata' + data
            );
          }
        })
      );
  }

  updateSendLeadEmailFlag(
    userId: string,
    orgId: string,
    sendLeadEmail: boolean
  ): Observable<IHasuraUser> {
    const q = gql`
      query UpdateUserLeadEmailFlag(
        $send_lead_email: Boolean!
        $organization_id: uuid!
        $user_id: String!
      ) {
        UpdateUserLeadEmailFlag(
          send_lead_email: $send_lead_email
          organization_id: $organization_id
          user_id: $user_id
        ) {
          success
        }
      }
    `;
    const variables = {
      send_lead_email: sendLeadEmail,
      organization_id: orgId,
      user_id: userId,
    };

    return this.clientApi
      .useClient('org_admin')
      .mutate<any>({
        mutation: q,
        variables: variables,
      })
      .pipe(map((data) => data.data?.UpdateUserLeadEmailFlag.success));
  }

  createSalesOrganization(org: {
    name: string;
    metadata: {
      country: ICountryNState;
      address?: string;
      state?: ICountryNState;
      sub_type?: UserType;
    };
  }): Observable<string> {
    const m = gql`
    mutation createSalesOrg($metadata: json) {
      insert_organization_one(object: {type: sales_organization, metadata: $metadata, name: "${org.name}"}) {
        id
      }
    }`;

    return this.clientApi
      .useClient('user')
      .mutate<any>({
        mutation: m,
        variables: {
          metadata: org.metadata,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_organization_one.id;
          }
        })
      );
  }

  getMemberships(orgId?: string): Observable<string[]> {
    const s = gql`
    subscription getMemberInOrganization {
      organization_member(where: {organization_id: {_eq: "${orgId}"}}) {
        organization_id
      }
    }`;

    const sNoOrg = gql`
      subscription getMemberInOrganization {
        organization_member {
          organization_id
        }
      }
    `;
    return this.clientApi
      .useClient('user', 'ws')
      .subscribe<any>({
        query: orgId ? s : sNoOrg,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.organization_member;
          }
        })
      );
  }
}
