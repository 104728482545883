import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-status-messages',
  templateUrl: './status-messages.component.html',
  styleUrls: ['./status-messages.component.scss'],
})
export class StatusMessagesComponent {
  constructor(public notify: NotificationService) {}
}
