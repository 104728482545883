import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, iif, mergeMap, Observable, of, tap, timeout } from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { IOrganizationInviteData } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { pagesPATH } from 'src/app/models_new/config/pages';

@Component({
  selector: 'app-org-invite',
  templateUrl: './org-invite.component.html',
  styleUrls: ['./org-invite.component.scss'],
})
export class OrgInviteComponent implements OnInit {
  invites$: Observable<DataRequestState<IOrganizationInviteData[]>>;
  isSignUpInvite: boolean = false;

  constructor(
    private stateService: StateService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.invites$ = this.auth.isAuthenticated$.pipe(
      mergeMap((isAuth) =>
        iif(() => isAuth, this.stateService.organizationInvites$, of(null))
      ), // Check if user is logged in.
      tap((isAuthenticated) => {
        if (isAuthenticated === null && this.route.snapshot.queryParams.email) {
          // If not and there are queryparams email. This is a invite to a user with no account.
          this.isSignUpInvite = true;
          this.auth.loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
            },
          });
        }
        if (
          isAuthenticated === null &&
          !this.route.snapshot.queryParams.email
        ) {
          // The invited user has an account.
          this.auth.loginWithRedirect();
        }
      }),
      toRequestState()
    );
  }

  didAccept(invite: IOrganizationInviteData) {
    this.stateService.organizations$
      .pipe(
        timeout(3000),
        catchError(() => of(null))
      )
      .subscribe((organizations) => {
        if (organizations) {
          this.stateService.setOrganizationById(invite.organization_id);
        } else {
          // New users won't yet resolve organizations$. Redirect to dashboard after timeout.
          this.router.navigate([pagesPATH.CUSTOMERS]);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  }
}
