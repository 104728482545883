<app-toolbar-component *ngIf="showToolbar$ | async"></app-toolbar-component>
<div class="toolbar-spacer" *ngIf="showToolbar$ | async"></div>
<ng-container
  *ngIf="!isLandingPage && globalNotification$ | async as globalNotification"
>
  <app-notification-banner
    [notification]="globalNotification"
  ></app-notification-banner>
</ng-container>
<router-outlet></router-outlet>
<app-status-messages></app-status-messages>

<div *ngIf="developmentWatermark" class="developmentWatermark">
  <span>Development</span>
</div>
