import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  Inject,
} from '@angular/core';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { Box } from '@rocketfarm/packing-webcomponents';
import { AxisDirection, Location } from '@rocketfarm/cartesian-rectangle';
import { EditorBox } from 'src/app/models_new/classes/editor-box';
import { UnitConversionPipe } from 'src/app/pipes/unit-conversion.pipe';
import { DataService } from 'src/app/services/data.service';
import { PatternlogicService } from 'src/app/services/patternlogic.service';
import { SubnavViewService } from 'src/app/services/subnav-view.service';
import { of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { Project } from 'src/app/models_new/classes/project';
import { PalletPosition } from 'src/app/models_new/enums/pallet-position';
import { ProjectData } from 'src/app/models_new/classes/project-data';
import { LayerApproach } from 'src/app/models_new/enums/layer-approach';
import { Layer } from 'src/app/models_new/classes/layer';
import { NotificationService } from 'src/app/services/notification.service';
import { ObjectUtils } from 'src/app/utils/object';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AlternativeLayoutService } from 'src/app/services/alternative-layout.service';
import { Box as newBox } from '../../../../models_new/classes/box';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { IGripperOrientationOption } from 'src/app/models_new/types/gripper-orientation-option';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pattern-edit',
  templateUrl: './pattern-edit.component.html',
  styleUrls: ['./pattern-edit.component.scss'],
})
export class PatternEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('boxPacking') boxPacking: ElementRef<HTMLBoxPackingElement>;

  project: Project;
  projectData: ProjectData;
  layer: Layer;
  layerId: string;
  palletPosition: PalletPosition;
  boxesInLayer: EditorBox[];

  viewReady = false;

  autoalignDistance = 15;
  frameWidth: number;
  frameLength: number;
  boxPadding: number;
  boxOverflowX: number;
  boxOverflowY: number;
  aspectRatio: string;

  // The debugging property hides the robot. Useful when debugging, and the robot is just occupying space in the view.
  debugging: boolean;

  metaData: Project;
  name: string;
  patternId: number;
  saved: boolean;
  dirty = false;
  inverseApproach = false;
  inverseApproachInitialized = false;

  selectedBoxes: Box[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  saveHistoryIndex = 0;
  editHistory: Array<EditorBox[]> = [];
  editHistoryIndex = 0;
  preservedLayer: boolean;

  pagesPATH = pagesPATH;

  constructor(
    private workspaceService: WorkspaceService,
    private patternLogicService: PatternlogicService,
    public unitsPipe: UnitConversionPipe,
    private notifier: NotificationService,
    private dataService: DataService,
    public subNavView: SubnavViewService,
    private errorHandler: ErrorHandlerService,
    private altLayoutService: AlternativeLayoutService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<PatternEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project;
      layerId: string;
      palletPosition: PalletPosition;
    }
  ) {
    this.keyboardHandler = this.keyboardHandler.bind(this);
    document.addEventListener('keydown', this.keyboardHandler);
  }

  ngOnInit() {
    this.subNavView.view$.next(pagesPATH.PATTERNEDIT);

    of(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.project = data.project;
        this.projectData = this.project.getProjectData();

        this.layerId = data.layerId;
        this.palletPosition = +data.palletPosition;

        if (
          this.layerId &&
          this.palletPosition !== null &&
          this.palletPosition !== undefined
        ) {
          let layer;
          try {
            layer = this.project
              .getPalletByPosition(this.palletPosition)
              .getLayerById(this.layerId);
          } catch (err) {
            this.errorHandler.handleError(err);
          } finally {
            this.layer = layer;
          }

          if (this.layer && this.layer.boxes && this.projectData) {
            if (this.layer.preserved) {
              this.preservedLayer = true;
            }

            this.inverseApproach =
              this.layer.approach === LayerApproach.INVERSE ? true : false;
            setTimeout(() => {
              this.subNavView.inverseApproach$.next(this.inverseApproach);
              this.inverseApproachInitialized = true;
              this.subNavView.title$.next('Edit: ' + this.layer.name);
              this.subNavView.customBoxIndexLayer$.next(
                this.projectData.box?.indexOverride || false
              );
            });
            this.frameWidth = this.projectData.getPalletData().dimensions.width;
            this.frameLength =
              this.projectData.getPalletData().dimensions.length;
            this.boxPadding = this.projectData.getBoxData().padding;
            this.boxOverflowX = this.projectData.getPalletData().overhang.sides;
            this.boxOverflowY = this.projectData.getPalletData().overhang.ends;
            this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
              this.layer.boxes,
              this.projectData
            );

            this.aspectRatio = `${this.frameWidth + this.boxOverflowX} / ${
              this.frameLength + this.boxOverflowY
            }`;

            this.addToEditHistory(this.boxesInLayer);
          } else {
            const palletname = this.project.getPalletNameByPosition(
              this.palletPosition
            );
            this.notifier.showError(
              `No layer with id ${this.layerId} on ${palletname}`
            );
          }

          this.saved = false;
          this.dataService.setImportDirty(true);

          this.viewReady = true;
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subNavView.alignPatternHoriz$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.alignHoriz();
          }
        });

      this.subNavView.alignPatternVert$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.alignVert();
          }
        });

      this.subNavView.mirrorPatternVert$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.mirrorPatternVert();
          }
        });

      this.subNavView.mirrorPatternHoriz$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.mirrorPatternHoriz();
          }
        });

      this.subNavView.rotatePattern$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.rotatePattern();
          }
        });

      this.subNavView.addNewPatternBox$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.addBox();
          }
        });

      this.subNavView.patternRotateBoxes$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.boxPacking.nativeElement.rotate();
          }
        });

      this.subNavView.patternMirrorBoxes$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.boxPacking.nativeElement.rotate().then(() => {
              this.boxPacking.nativeElement.rotate();
            });
          }
        });

      this.subNavView.patternDeleteBoxes$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.boxPacking.nativeElement.delete();
          }
        });

      this.subNavView.patternChangeBoxOrientation$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v && this.selectedBoxes) {
            this.selectedBoxes.forEach((box: Box) => {
              this.lockDirection(box);
            });
          }
        });

      this.subNavView.patternSetEnforcedGripperOrientation$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v: IGripperOrientationOption[]) => {
          if (v !== null && this.selectedBoxes) {
            this.selectedBoxes.forEach((box: Box) => {
              this.setGripperOrientation(box, v);
            });
          }
        });

      this.subNavView.patternChangeStopMultiplePick$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v && this.selectedBoxes) {
            this.selectedBoxes.forEach((box: Box) => {
              this.stopMultipleGrip(box);
            });
          }
        });

      this.subNavView.undoChange$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.undoLast();
          }
        });

      this.subNavView.redoChange$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          if (v) {
            this.redoLast();
          }
        });

      this.subNavView.view$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
        if (v && this.selectedBoxes) {
          if (v === pagesPATH.PATTERNEDIT && this.selectedBoxes.length > 0) {
            this.boxPacking.nativeElement.unselectAll();
          }
        }
      });
      this.subNavView.inverseApproach$
        .pipe(takeUntil(this.destroy$))
        .subscribe((v) => {
          this.invertedPlacement(v);
        });

      this.subNavView.customBoxIndexLayer$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isCustom) => {
          this.layer.overrideBoxIds = isCustom;
          this.projectData.box.indexOverride = isCustom;
          this.update();
        });

      /**
       * Workaround for the issue where the svg element is not rendered correctly when the component is first rendered.
       * The styling needed to be applied can't be done in a cleaner manner due to the shadow dom and the SVG coming
       * from an external dependency. Ideally, this would have to be arranged on the external dependency.
       * @todo Look at @rocketfarm/packing-webcomponents to see if this can be fixed there.
       * {@link https://rocketfarm.atlassian.net/browse/PALLY-3903 Learn more} about this issue.
       */
      const shadowRootObserver = new MutationObserver((mutationsList) => {
        const shadowRootMutations = mutationsList.filter(
          (mutation) =>
            mutation.target === this.boxPacking.nativeElement.shadowRoot
        );
        if (shadowRootMutations.length > 0) {
          const svgElement =
            this.boxPacking.nativeElement.shadowRoot.querySelector('svg');
          if (svgElement) {
            svgElement.style.maxHeight = 'inherit';
            shadowRootObserver.disconnect();
          }
        }
      });
      shadowRootObserver.observe(this.boxPacking.nativeElement.shadowRoot, {
        childList: true,
      });
    });
  }

  ngOnDestroy() {
    const newLayersInEdit = this.workspaceService.layerEditing$.getValue();
    if (newLayersInEdit) {
      this.workspaceService.layerEditingComplete$.next(true);
    }

    document.removeEventListener('keydown', this.keyboardHandler);
    this.subNavView.resetAllPatternButtons();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onChange(changes: SimpleChanges) {
    if (changes) {
      this.dirty = true;
      this.saved = false;
    }
  }

  onSelection($event: Event): void {
    const selectedBoxes = ($event as CustomEvent<Box[]>).detail;
    const newSelection = [];
    selectedBoxes.forEach((box) => {
      const toBeSelected = this.boxesInLayer.find(
        (item) => item.getId() === box.getId()
      );
      newSelection.push(toBeSelected);
    });
    this.selectedBoxes = newSelection.sort((a, b) => a.getId() - b.getId());
    this.subNavView.patternSelectedBoxes$.next(this.selectedBoxes);
    if (selectedBoxes.length > 0) {
      if (this.selectedBoxes.length === 1) {
        const gripperOrientations = (this.selectedBoxes[0] as EditorBox)
          .gripperOrientations;
        this.subNavView.patternEnforcedGripperOrientation$.next(
          gripperOrientations
        );
      } else {
        const gripperOrientations = this.selectedBoxes.map(
          (m) => (m as EditorBox).gripperOrientations
        );

        let equal = true;
        gripperOrientations.forEach((f1) => {
          gripperOrientations.forEach((f2) => {
            const isEqual = ObjectUtils.isEqual(f1, f2);
            if (!isEqual) {
              equal = false;
            }
          });
        });

        if (equal) {
          this.subNavView.patternEnforcedGripperOrientation$.next(
            gripperOrientations[0]
          );
        } else {
          this.subNavView.patternEnforcedGripperOrientation$.next([]);
        }
      }

      this.subNavView.view$.next(pagesPATH.BOXEDIT);
      this.subNavView.title$.next('Edit box');
    } else {
      this.subNavView.view$.next(pagesPATH.PATTERNEDIT);
      this.subNavView.title$.next('Edit: ' + this.layer.name);
    }
  }

  /**
   * Updates the index box in the pattern editor.
   * @param {{prevId:number; nextId:number}} event - An object containing the previous and next box IDs.
   */
  public updateIndexBox(event: { prevId: number; nextId: number }) {
    const tempBoxes = this.workspaceService.editorBoxesToBoxes(
      this.boxesInLayer,
      this.layer.height
    );
    const prevBox = tempBoxes[event.prevId];
    const nextBox = tempBoxes[event.nextId];
    tempBoxes[event.prevId] = nextBox;
    tempBoxes[event.nextId] = prevBox;
    this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
      tempBoxes,
      this.projectData
    );
    this.checkBoxesAndSort();
  }

  edit($event: Event) {
    // Angular is missing proper custom event typings so we need to cast it
    const boxes: Box[] = ($event as CustomEvent<Box[]>).detail;
    this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
      boxes,
      this.projectData,
      this.boxesInLayer
    );
    this.checkBoxesAndSort();
  }

  private checkBoxesAndSort() {
    this.selectedBoxes.forEach((selBox) => {
      const selected = this.boxesInLayer.find(
        (box) => box.getId() === selBox.getId()
      );
      if (this.boxesInLayer.find((box) => box.getId() === selBox.getId())) {
        selBox.setLocation(selected.getXMin(), selected.getYMin());
        selBox.setRotated(selected.isRotated());
      }
    });
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  update() {
    this.preservedLayer = false;
    this.addToEditHistory(this.boxesInLayer);
    this.dirty = true;
    this.saved = false;
  }

  addBox(): void {
    const nextId = this.workspaceService.getNextId(this.boxesInLayer);
    const box = this.workspaceService.addBox(nextId, this.projectData);
    this.boxesInLayer.push(box);
    this.boxesInLayer = this.boxesInLayer.slice();
    this.update();
  }

  deleteBox(boxId: number) {
    const indexSelected = this.selectedBoxes.findIndex(
      (box) => box.getId() === boxId
    );
    const index = this.boxesInLayer.findIndex((box) => box.getId() === boxId);
    if (index !== -1 && indexSelected !== -1) {
      // Remove from pallet
      this.boxesInLayer.splice(index, 1);

      // Remove from selected boxes
      this.selectedBoxes.splice(indexSelected, 1);

      // Reload drawings
      this.boxesInLayer = this.boxesInLayer.slice();
    }
    this.update();
  }

  lockDirection(box: Box) {
    const foundBox = this.boxesInLayer.find(
      (existingBox) => existingBox.getId() === box.getId()
    );
    if (foundBox) {
      const directions = foundBox.getBoxFrontDirections();
      if (directions.length > 1) {
        foundBox.setBoxFrontDirections(directions.slice(0, 1));
      } else {
        const newDirection = (directions[0] + 2) % 4;
        directions.push(newDirection);
        foundBox.setBoxFrontDirections(directions);
      }
      this.boxesInLayer = this.boxesInLayer.slice();
    }

    this.update();
  }

  stopMultipleGrip(box: Box) {
    const tempBoxes = this.workspaceService.editorBoxesToBoxes(
      this.boxesInLayer,
      this.layer.height
    );
    tempBoxes[box.getId()].gripper.stopMultigrip =
      !tempBoxes[box.getId()].gripper.stopMultigrip;

    this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
      tempBoxes,
      this.projectData
    );
    this.update();
  }

  setGripperOrientation(box: Box, orientations: IGripperOrientationOption[]) {
    const tempBoxes = this.workspaceService.editorBoxesToBoxes(
      this.boxesInLayer,
      this.layer.height
    );

    orientations.forEach((o: IGripperOrientationOption) => {
      tempBoxes[box.getId()].setEnforcedOrientation(o.value, o.selected);
    });

    this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
      tempBoxes,
      this.projectData
    );
    this.update();
  }

  alignVert(): void {
    this.patternLogicService.alignBoxVert(
      this.projectData.getPalletData(),
      this.boxesInLayer
    );
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  alignHoriz(): void {
    this.patternLogicService.alignBoxHoriz(
      this.projectData.getPalletData(),
      this.boxesInLayer
    );
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  mirrorPatternVert(): void {
    this.patternLogicService.mirrorPatternVert(
      this.projectData.getPalletData(),
      this.boxesInLayer
    );
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  mirrorPatternHoriz(): void {
    this.patternLogicService.mirrorPatternHoriz(
      this.projectData.getPalletData(),
      this.boxesInLayer
    );
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  rotatePattern(): void {
    this.patternLogicService.rotatePattern(
      this.projectData.getPalletData(),
      this.boxesInLayer
    );
    this.boxesInLayer = this.sort(this.boxesInLayer);
    this.update();
  }

  invertedPlacement(value: boolean) {
    const clicked = this.subNavView.inverseApproachClicked$.getValue();
    if (clicked) {
      this.preservedLayer = false;
    }

    if (
      !this.preservedLayer &&
      this.inverseApproachInitialized &&
      this.inverseApproach !== value
    ) {
      this.inverseApproach = value;

      let tempBoxes = this.workspaceService.editorBoxesToBoxes(
        this.boxesInLayer,
        this.layer.height
      );

      if (this.palletPosition === PalletPosition.LEFT) {
        value = !value;
      }

      tempBoxes = this.workspaceService.automaticSortBoxes(
        this.projectData,
        tempBoxes,
        value,
        this.layer.overrideBoxIds
      );
      this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
        tempBoxes,
        this.projectData
      );
      this.update();
    }
  }

  private keyboardHandler(event: KeyboardEvent): void {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (event.code !== undefined || !event.repeat) {
      if (event.code === 'KeyZ' && event.ctrlKey) {
        this.undoLast();
        handled = true;
      } else if (event.code === 'KeyY' && event.ctrlKey) {
        this.redoLast();
        handled = true;
      } else {
        this.patternLogicService.onKeydown(event, this);
      }
    }

    if (handled) {
      // Suppress 'double action' if event handled
      event.preventDefault();
    }
  }

  private isEqual(first: Array<EditorBox>, second: Array<EditorBox>) {
    let result = true;
    if (first.length === second.length) {
      for (let i = 0; i < first.length; i++) {
        if (!first[i].equalsDirectedRectangleLocation(second[i])) {
          result = false;
          break;
        }
        if (
          !ObjectUtils.isEqual(
            first[i].getBoxFrontDirections(),
            second[i].getBoxFrontDirections()
          )
        ) {
          result = false;
          break;
        }
        if (
          !ObjectUtils.isEqual(
            first[i].stopMultigripFlag,
            second[i].stopMultigripFlag
          )
        ) {
          result = false;
          break;
        }
        if (
          !ObjectUtils.isEqual(
            first[i].gripperOrientations,
            second[i].gripperOrientations
          )
        ) {
          result = false;
          break;
        }
      }
    } else {
      result = false;
    }
    return result;
  }

  private addToEditHistory(boxes: Array<EditorBox>, setDirty = true): void {
    const boxesInPattern = ObjectUtils.cloneObject(boxes);

    // Check if anything has changed
    if (this.editHistory.length > 0) {
      const oldBoxes = ObjectUtils.cloneObject(
        this.editHistory[this.editHistoryIndex]
      );
      const equal = this.isEqual(
        ObjectUtils.sortyBy(boxesInPattern),
        ObjectUtils.sortyBy(oldBoxes)
      );
      if (equal) {
        return;
      }
    }

    // Remove any future events
    this.editHistory.splice(
      this.editHistoryIndex + 1,
      this.editHistory.length - this.editHistoryIndex - 1
    );
    this.editHistory.push(boxesInPattern);

    // Delete first if history too long
    if (this.editHistory.length > 100) {
      this.editHistory.splice(0, 1);
    }
    this.editHistoryIndex = this.editHistory.length - 1;

    // If saveHistoryIndex is greater than editHistoryIndex, we should
    // set the dirty flag. And reset the saveHistoryIndex.
    if (setDirty || this.saveHistoryIndex > this.editHistoryIndex) {
      this.dirty = true;
      this.saved = false;
    }
  }

  private undoLast(): void {
    if (this.editHistoryIndex > 0) {
      // Unselect all boxes to update their values
      this.boxPacking.nativeElement.unselectAll();

      this.boxesInLayer = ObjectUtils.cloneObject(
        this.editHistory[--this.editHistoryIndex]
      );

      if (this.editHistoryIndex === this.saveHistoryIndex) {
        this.dirty = false;
        this.saved = true;
      } else {
        this.dirty = true;
        this.saved = false;
      }
    }
  }

  private redoLast(): void {
    if (this.editHistory.length - this.editHistoryIndex > 1) {
      // Unselect all boxes to update their values
      this.boxPacking.nativeElement.unselectAll();

      this.boxesInLayer = ObjectUtils.cloneObject(
        this.editHistory[++this.editHistoryIndex]
      );
      if (this.editHistoryIndex === this.saveHistoryIndex) {
        this.dirty = false;
        this.saved = true;
      } else {
        this.dirty = true;
        this.saved = false;
      }
    }
  }

  private sort(boxes: Array<EditorBox>) {
    if (!this.layer.overrideBoxIds) {
      const selectedBoxesLocation: Location[] = [];
      const updatedSelectedBoxes: EditorBox[] = [];
      this.selectedBoxes.forEach((box) =>
        selectedBoxesLocation.push(box.location)
      );
      let tempBoxes = this.workspaceService.editorBoxesToBoxes(
        ObjectUtils.cloneObject(boxes),
        this.layer.height
      );
      tempBoxes = this.workspaceService.automaticSortBoxes(
        this.projectData,
        tempBoxes,
        this.inverseApproach || this.palletPosition === PalletPosition.LEFT,
        this.layer.overrideBoxIds
      );

      const result = this.workspaceService.boxesToEditBoxes(
        tempBoxes,
        this.projectData
      );

      result.forEach((box) => {
        selectedBoxesLocation.some((loc) => {
          if (box.location.equalsLocation(loc)) {
            updatedSelectedBoxes.push(box);
            return true;
          }
          return false;
        });
        return this.selectedBoxes.length === updatedSelectedBoxes.length;
      });
      this.boxPacking.nativeElement.select(updatedSelectedBoxes);
      return result;
    } else {
      return boxes;
    }
  }

  /**
   * @returns is saved
   */
  save(): boolean {
    const tempBoxes: newBox[] = this.workspaceService.editorBoxesToBoxes(
      ObjectUtils.cloneObject(this.boxesInLayer),
      this.layer.height
    );
    if (
      this.workspaceService.hasCollision(
        this.projectData,
        tempBoxes,
        this.palletPosition === PalletPosition.LEFT
      )
    ) {
      const dialogRef = this.dialogService.showStandardDialog({
        content: {
          title: 'Layer has collision',
          contentAsString: 'Edit the layer or discard changes',
        },
        button: { text: 'Discard changes' },
        gui: { size: DialogSize.MEDIUM },
      });

      dialogRef
        .afterClosed()
        .pipe(
          take(1),
          switchMap((disc) => {
            if (disc) {
              return this.dialogService
                .showStandardDialog({
                  content: {
                    title: 'Discard changes',
                    contentAsString:
                      'This will discard your changes to this pattern',
                  },
                  button: { text: 'Discard' },
                  gui: { size: DialogSize.MEDIUM },
                })
                .afterClosed();
            } else {
              return of(null);
            }
          }),
          take(1)
        )
        .subscribe((conf) => {
          if (conf) {
            this.dirty = false;
            this.dialogRef.close();
          } else {
            console.debug('Continued edit');
          }
        });

      return false;
    } else {
      this.savePattern();
      this.saveHistoryIndex = this.editHistoryIndex;
      return true;
    }
  }

  private savePattern(): void {
    this.saved = true;

    const newLayersInEdit = this.workspaceService.layerEditing$.getValue();
    if (newLayersInEdit) {
      newLayersInEdit.saved = true;
      this.workspaceService.layerEditing$.next(newLayersInEdit);
    }
    // Temporary workaround for now is to use old classes in autosort. We should use the new model when it's ready.
    let tempBoxes: newBox[] = this.workspaceService.editorBoxesToBoxes(
      ObjectUtils.cloneObject(this.boxesInLayer),
      this.layer.height
    );
    if (!this.layer.overrideBoxIds) {
      let leftPalletSort = this.inverseApproach;
      if (this.palletPosition === PalletPosition.LEFT) {
        leftPalletSort = !leftPalletSort;
      }
      tempBoxes = this.workspaceService.automaticSortBoxes(
        this.projectData,
        tempBoxes,
        leftPalletSort,
        this.layer.overrideBoxIds
      );
    }
    this.boxesInLayer = this.workspaceService.boxesToEditBoxes(
      tempBoxes,
      this.projectData
    );

    // Save to all of typeId
    const typeId = this.layer.typeId;
    let layersOfTypeId;

    try {
      layersOfTypeId = this.project
        .getPalletByPosition(this.palletPosition)
        .getLayersByTypeId(typeId);
    } catch (error) {
      this.errorHandler.handleError(error);
    } finally {
      if (layersOfTypeId.length) {
        const currentApproach = this.inverseApproach;
        this.project
          .getPalletByPosition(this.palletPosition)
          .getLayersByTypeId(typeId)
          .forEach((l: Layer) => {
            if (currentApproach) {
              l.approach = LayerApproach.INVERSE;
            } else {
              l.approach = LayerApproach.NULL;
            }
            // Clone to make unique objects.
            const boxes = ObjectUtils.cloneObject(tempBoxes);
            if (!this.preservedLayer) {
              l.unpreserve();
            }
            l.setBoxes(boxes);
            l.updateBoxesData(this.projectData);
            l.overrideBoxIds = this.layer.overrideBoxIds;
          });
        // If right pallet changes. Update alt layout when save to generate left pallet after changes
        if (this.palletPosition === PalletPosition.RIGHT) {
          const altLayout = this.altLayoutService.selectedAltLayout$.getValue();
          this.altLayoutService.selectedAltLayout$.next(altLayout);
        }
        this.dataService.setProject(this.project);
        this.dialogRef.close(this.project);
      }
    }
  }

  /**
   * @param newValue: number
   * @param box: EditorBox
   */
  updateX(newValue: number, box: Box) {
    const diff = box.getXCenter() - newValue;
    if (diff !== 0) {
      const direction =
        diff > 0 ? AxisDirection.X_NEGATIVE : AxisDirection.X_POSITIVE;
      this.boxPacking.nativeElement.moveById(
        box.getId(),
        direction,
        Math.abs(diff)
      );
    }
  }

  /**
   * @param newValue: number
   * @param box: EditorBox
   */
  updateY(newValue: number, box: Box) {
    const diff = box.getYCenter() - newValue;
    if (diff !== 0) {
      const direction =
        diff > 0 ? AxisDirection.Y_NEGATIVE : AxisDirection.Y_POSITIVE;
      this.boxPacking.nativeElement.moveById(
        box.getId(),
        direction,
        Math.abs(diff)
      );
    }
  }
}
