import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SimulationStatus } from 'src/app/models_new/enums/simulation-status';

const today = new Date(Date.now());
const now = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 1
);
const oneYearBack = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 365
);

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements AfterViewInit, OnDestroy {
  statuses = Object.values(SimulationStatus);
  status = new UntypedFormControl(this.statuses);
  range = new UntypedFormGroup({
    start: new UntypedFormControl(oneYearBack),
    end: new UntypedFormControl(now),
  });

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() textFilter: EventEmitter<string> = new EventEmitter();
  @Output() statusFilter: EventEmitter<string[]> = new EventEmitter();
  @Output() dateFilter: EventEmitter<{ start: string; end: string }> =
    new EventEmitter();

  ngAfterViewInit(): void {
    this.status.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.statusFilter.emit(val);
    });

    this.range.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val.start && val.end) {
        val.end.setTime(Date.now());
        val.start = val.start.toLocaleString();
        val.end = val.end.toLocaleString();
        this.dateFilter.emit(val);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.textFilter.emit(filterValue);
  }
}
