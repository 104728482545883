/**
 * Find the mime type of a file extension
 *
 * @param extension file extension without '.'
 */
export function getMimeType(extension: string) {
  switch (extension) {
    case 'jpe':
    case 'jpg':
      return 'image/jpeg';
    case 'svg':
      return 'image/svg+xml';
    case 'tif':
      return 'image/tiff';
  }

  // Handles jpeg, png, bmp as well as other obscure formats where the extesions and mimetype matches
  return 'image/' + extension;
}

export function toDataUrl(base64Input: string, mimeType: string) {
  const prefix = `data:${mimeType};base64,`;
  return prefix + base64Input;
}

// Handles image processing of HTMLImageElement
export class ImageProcessing {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  constructor() {
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
  }

  scale(img: HTMLImageElement, scale: number = 1) {
    this.canvas.height = img.height * scale;
    this.canvas.width = img.width * scale;
    this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);

    let resizedImg = new Image();
    resizedImg.src = this.ctx.canvas.toDataURL();

    return resizedImg;
  }

  // Crop image to specified resolution
  crop(img: HTMLImageElement, x_resolution: number, y_resolution: number) {
    if (img.width >= x_resolution && img.height >= y_resolution) {
      const sx = (img.width - x_resolution) / 2;
      const sy = (img.height - y_resolution) / 2;

      this.canvas.height = y_resolution;
      this.canvas.width = x_resolution;
      this.ctx.drawImage(
        img,
        sx,
        sy,
        x_resolution,
        y_resolution,
        0,
        0,
        x_resolution,
        y_resolution
      );
      let croppedImg = new Image();
      croppedImg.src = this.ctx.canvas.toDataURL();
      return croppedImg;
    } else {
      console.error(
        'Source image resolution is lower than requested crop resolution'
      );
      return img;
    }
  }
}
