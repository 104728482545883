import { IBox } from 'src/app/models_new/types/box';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { BoxRotation } from 'src/app/models_new/enums/box-rotation';

export const defaultBox: IBox = {
  boxPadding: 0,
  dimensions: {
    width: 190,
    height: 200,
    length: 290,
  },
  weight: 2500,
  labelOrientations: [LabelOrientation.NULL],
  rotation: [BoxRotation.ZERO],
  position: {
    x: 0,
    y: 0,
    z: 0,
  },
  gripper: {
    enforcedOrientation: [],
    stopMultigrip: false,
  },
};
