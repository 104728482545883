import { Injectable } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppLayoutService {
  isVertical$: Observable<boolean>;
  lowHeight$: Observable<boolean>;

  constructor(public responsive: BreakpointObserver) {
    this.isVertical$ = this.responsive
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map((res) => (res.matches ? true : false)),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.lowHeight$ = this.responsive.observe('(max-height: 850px)').pipe(
      map((res) => (res.matches ? true : false)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
