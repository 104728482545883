import { IDefaultSimConfig } from 'src/app/services/api/api.service';
import { ObjectUtils } from 'src/app/utils/object';

const config = {
  boxLengthUnder300: ['double_colab_ur10', 'double_max_ur10'],
  boxLengthOver300: [
    'single_colab_ur10',
    'single_max_ur10',
    'single_colab_ur16',
    'single_max_ur16',
  ],
  boxWeightUnder6: ['double_colab_ur10', 'double_max_ur10'],
  boxWeightUnder12: [
    'single_colab_ur10',
    'single_max_ur10',
    'single_colab_ur10',
    'single_max_ur10',
  ],
  boxWeightOver6: ['single_colab_ur10', 'single_max_ur10'],
  boxWeightOver12: ['single_colab_ur16', 'single_max_ur16'],
  boxWeightUnder6cpmOver12: ['double_max_ur10'],
  boxWeightUnder12cpmOver7: ['single_max_ur10'],
  boxWeightUnder12cpmUnder7: ['single_colab_ur10'],
  boxWeightOver12cpmover7: ['single_max_ur16'],
};

export class DefaultSimConfigGetter {
  allOptions: IDefaultSimConfig[];
  remainingOptions: IDefaultSimConfig[];

  constructor(
    defaults: IDefaultSimConfig[],
    boxLength: number,
    boxWeight: number,
    cpm: number
  ) {
    this.allOptions = defaults;
    this.allOptions.forEach((def: IDefaultSimConfig) =>
      ObjectUtils.cloneObject(def)
    );
    this.remainingOptions = this.allOptions;

    this.setMaxGrip(boxLength);
    this.basedOnBoxLength(boxLength);
    this.basedOnBoxWeight(boxWeight, boxLength);
    this.basedOnCpm(cpm, boxWeight);
  }

  setMaxGrip(boxLength: number): void {
    this.remainingOptions.forEach((def: IDefaultSimConfig) => {
      if (boxLength > 300) {
        def.data.pattern.maxGrip = 1;
      } else {
        def.data.pattern.maxGrip = 2;
      }
    });
  }

  basedOnBoxLength(boxLength: number): void {
    if (boxLength > 300) {
      this.remainingOptions = this.remainingOptions.filter((f) =>
        config.boxLengthOver300.includes(f.name)
      );
    } else {
      this.remainingOptions = this.remainingOptions.filter((f) =>
        config.boxLengthUnder300.includes(f.name)
      );
    }
  }

  basedOnBoxWeight(boxWeight: number, boxLength: number): void {
    if (boxWeight > 6000 && boxWeight <= 12000) {
      // Reset reminders
      this.remainingOptions = this.allOptions;

      this.remainingOptions = this.remainingOptions.filter(
        (f) =>
          config.boxWeightOver12.includes(f.name) ||
          config.boxWeightOver6.includes(f.name)
      );

      // Set max grip 1
      this.setMaxGrip(301);
    } else if (boxWeight > 12000) {
      // Reset reminders
      this.remainingOptions = this.allOptions;

      this.remainingOptions = this.remainingOptions.filter((f) =>
        config.boxWeightOver12.includes(f.name)
      );

      // Set max grip 1
      this.setMaxGrip(301);
    } else {
      if (boxLength > 300) {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightUnder12.includes(f.name)
        );
      } else {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightUnder6.includes(f.name)
        );
      }
    }
  }

  basedOnCpm(cpm: number, boxWeight: number) {
    if (boxWeight < 6000) {
      if (cpm > 12) {
        this.remainingOptions = this.remainingOptions.filter(
          (f) =>
            config.boxWeightUnder6cpmOver12.includes(f.name) ||
            config.boxWeightUnder12cpmOver7.includes(f.name)
        );
      } else if (cpm > 7) {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightUnder12cpmOver7.includes(f.name)
        );
      } else {
        this.remainingOptions = this.remainingOptions.filter(
          (f) =>
            !config.boxWeightUnder6cpmOver12.includes(f.name) &&
            !config.boxWeightUnder12cpmOver7.includes(f.name)
        );
      }
    } else if (boxWeight > 12000) {
      if (cpm > 7) {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightOver12cpmover7.includes(f.name)
        );
      } else {
        this.remainingOptions = this.remainingOptions.filter(
          (f) => !config.boxWeightOver12cpmover7.includes(f.name)
        );
      }
    } else {
      if (cpm > 7) {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightUnder12cpmOver7.includes(f.name)
        );
      } else {
        this.remainingOptions = this.remainingOptions.filter((f) =>
          config.boxWeightUnder12cpmUnder7.includes(f.name)
        );
      }
    }
  }

  getDefault(): IDefaultSimConfig {
    return this.remainingOptions.length
      ? ObjectUtils.cloneObject(this.remainingOptions[0])
      : null;
  }
}
