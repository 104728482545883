import { UntypedFormGroup, Validators } from '@angular/forms';
import { Field } from 'src/app/models_new/classes/field';
import { defaultApiProduct } from 'src/app/models_new/config/default/api-default/default-api-product';
import {
  CardFieldIds,
  labelOrientations,
} from 'src/app/models_new/config/form_fields';
import { nameRegexp } from 'src/app/models_new/config/validation/pally-file-values';
import { FieldType } from 'src/app/models_new/types/field-type';
import { UnitSystemType } from 'src/app/utils/unit-utils';

export function newProductCardFields(
  restricted?: boolean,
  unitSystem?: UnitSystemType
): Field[] {
  const useImperial = unitSystem === 'imperial';
  const lengthField = new Field(
    FieldType.NUMBER,
    true,
    defaultApiProduct.data.length,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.ProductLength,
      label: 'Product length',
      suffix: 'mm',
    },
    CardFieldIds.ProductLength,
    null,
    new Map<string, string>(),
    null,
    null,
    restricted ? { min: 50, max: 1300 } : { min: 20 }
  );
  const widthField = new Field(
    FieldType.NUMBER,
    true,
    defaultApiProduct.data.width,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.ProductWidth,
      label: 'Product width',
      suffix: 'mm',
    },
    CardFieldIds.ProductWidth,
    null,
    new Map<string, string>(),
    null,
    null,
    restricted ? { min: 50, max: 1300 } : { min: 20 }
  );
  const heightField = new Field(
    FieldType.NUMBER,
    true,
    defaultApiProduct.data.height,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.ProductHeight,
      label: 'Product height',
      suffix: 'mm',
    },
    CardFieldIds.ProductHeight,
    null,
    new Map<string, string>(),
    null,
    null,
    restricted ? { min: 20, max: 1300 } : { min: 20 }
  );
  const weightField = new Field(
    FieldType.NUMBER,
    true,
    useImperial
      ? defaultApiProduct.data.weight * 0.001
      : defaultApiProduct.data.weight,
    [],
    null,
    null,
    null,
    {
      name: CardFieldIds.ProductWeight,
      label: 'Product weight',
      suffix: useImperial ? 'kg' : 'g',
    },
    CardFieldIds.ProductWeight,
    null,
    new Map<string, string>(),
    null,
    null,
    restricted
      ? useImperial
        ? { min: 50 * 0.001, max: 12000 * 0.001 }
        : { min: 50, max: 12000 }
      : { min: 0 }
  );
  return [
    new Field(
      FieldType.SELECT_SINGLE_OBJECT,
      true,
      null,
      [Validators.required],
      null,
      [],
      null,
      { name: CardFieldIds.ProductSelect, label: 'Product' },
      CardFieldIds.ProductSelect
    ),
    new Field(
      FieldType.TEXT,
      true,
      '',
      [Validators.pattern(nameRegexp), Validators.required],
      null,
      null,
      null,
      { name: CardFieldIds.ProductName, label: 'Product name' },
      CardFieldIds.ProductName,
      null,
      new Map([
        [
          'product',
          'Restrictions by Pally - Allowed characters: a-z A-Z 0-9 . _ - ()',
        ],
      ])
    ),
    lengthField,
    widthField,
    heightField,
    weightField,
    new Field(
      FieldType.SELECT_SINGLE_OBJECT,
      false,
      undefined,
      [],
      null,
      [],
      null,
      { name: CardFieldIds.ProductionLineSelect, label: 'Production line' },
      CardFieldIds.ProductionLineSelect
    ),
    new Field(
      FieldType.SELECT_SINGLE_OBJECT,
      true,
      labelOrientations[0],
      [],
      null,
      labelOrientations,
      null,
      {
        name: CardFieldIds.ProductLabelOrientation,
        label: 'Label Orientation',
      },
      CardFieldIds.ProductLabelOrientation
    ),
  ];
}

export const getProductCardsFormGroup = (fields: Field[]): UntypedFormGroup => {
  const formGroup = new UntypedFormGroup({});
  fields.forEach((f: Field) => {
    formGroup.addControl(f.text.name, f.formControl);
  });

  return formGroup;
};
