import { ObjectUtils } from 'src/app/utils/object';
import { LabelOrientation } from '../enums/label-orientation';

export class LabelOrientator {
  boxRotations: number[];
  labelOrientation: LabelOrientation;

  constructor(boxRotations: number[], labelOrientation: LabelOrientation) {
    const boxRot = ObjectUtils.cloneObject(boxRotations);
    const labelOr = ObjectUtils.cloneObject(labelOrientation);

    this.boxRotations = boxRot.map((n: number) => this.mapToPos(n));
    this.labelOrientation = this.mapToPos(labelOr);
  }

  /**
   * Mapping to negative rotations. 270 => -90
   * @param n: rotation
   */
  mapToNeg(n: number) {
    if (n === 270) {
      return LabelOrientation.LEFT;
    } else {
      return n;
    }
  }

  /**
   * Mapping to positive rotations. -90 => 270
   * @param n: rotation
   */
  mapToPos(n: number) {
    if (n === LabelOrientation.LEFT) {
      return 270;
    } else {
      return n;
    }
  }

  getLabelOrientations(): LabelOrientation[] {
    const labelOrientations = [];

    let lo1 = this.labelOrientation + this.boxRotations[0];

    // Unlocked (0, 180) or (90, 270)
    if (this.boxRotations[1] !== null && this.boxRotations[1] !== undefined) {
      let lo2 = this.labelOrientation + this.boxRotations[1];

      lo2 = lo2 % 360;
      labelOrientations.push(this.mapToNeg(lo2));
    }

    lo1 = lo1 % 360;
    labelOrientations.unshift(this.mapToNeg(lo1));

    return labelOrientations;
  }
}
