import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { IReadyOpenSimulation } from '../components/fast-track/open-sim.service';
import { gql } from 'apollo-angular';
import { ClientApiService } from './api/client-api.service';

export interface IFastTrackPipedriveInfo {
  /** @deprecated Use fullName instead */
  firstName?: string;
  /** @deprecated Use fullName instead */
  lastName?: string;
  fullName: string;
  email: string;
  region: string;
  country: string;
  organization: string;
  phase_of_research: string;
  campaign?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PipedriveService {
  constructor(
    private clientApi: ClientApiService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Function to serialize objects where output is without the " surrounding the object keys.
   *
   * @param {object} obj
   * @returns {String}
   */
  serializeObject(obj: Record<string, any>): string {
    let serialized = '{';
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        serialized += key + ':' + JSON.stringify(obj[key]) + ',';
      }
    }
    serialized = serialized.slice(0, -1); // Remove the trailing comma
    serialized += '}';
    return serialized;
  }

  postPipedriveFastTrack(
    data: IFastTrackPipedriveInfo,
    simulations?: IReadyOpenSimulation[]
  ): Observable<any> {
    const variables: Record<string, string | number> = {};

    // Assemble Pipedrive data
    for (const key of Object.getOwnPropertyNames(data)) {
      variables[key] = data[key as keyof typeof data] ?? '';
    }

    variables['name'] = variables['fullName'];
    variables['form_submitted'] = 'MRC Fast Track';
    variables['source'] = 'Simulation';
    variables['company'] = variables['organization'];
    variables['date_time'] = new Date().toLocaleString();
    variables['visible_to'] = 1;

    /**
     *  There are two values which are missing in the backend for this integration:
     *
     *  region
     *  phase_of_research
     *
     */

    // Delete fields not expected in mutation input
    delete variables['firstName'];
    delete variables['lastName'];
    delete variables['fullName'];
    delete variables['phase_of_research'];
    delete variables['region'];
    delete variables['formSubmitter'];
    delete variables['organization'];

    // Create array of notes
    let notes;
    if (simulations) {
      const list = [];
      for (const sim of simulations) {
        list.push(
          `- ${sim.solution.name} - ${
            sim.solution.solution_provider?.name || 'N/A'
          } - Link to simulation: ${
            this.document.location.origin
          }/simulations/${sim.id}?o=1\n`
        );
      }

      notes = [
        {
          name: 'Simulations note',
          content: `
          Fast Track Simulation(s) verified:
          ${list.join('\n')}
      
          Phase of research: ${data.phase_of_research + ''}`,
        },
      ];
    }

    // NOTE! It is an issue with sending in notes parameter as an array.
    // Therefor the mutation is created with hardcoded values,
    // and the notes values is "manually" serialized through a
    // own function in this service.
    const mutation = gql`
    mutation UpdatePipedrivePerson {
      UpdatePipedrivePerson(
        email: "${variables['email']}",
        name: "${variables['name']}",
        country: "${variables['country']}",
        company: "${variables['company']}",
        ${
          variables['campaign']
            ? 'campaign: "' + variables['campaign'] + '",'
            : ''
        }
        date_time: "${variables['date_time']}",
        form_submitted:"${variables['form_submitted']}",
        source: "${variables['source']}",
        visible_to: "${variables['visible_to']}",
        notes: ${this.serializeObject(notes[0])},
      ) {
        pipedrive_field_check
        error
        actions {
          action
          info
          pipedrive_response
        }
      }
    }`;

    return this.clientApi
      .useClient('public')
      .mutate<any>({
        mutation,
      })
      .pipe(
        take(1),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      );
  }

  public postPipedriveContactMe(email: string): Observable<any> {
    // Assemble Pipedrive data
    const variables = {
      name: email.split('@')[0],
      email,
      form_submitted: 'Contact Form MyRobot.cloud',
      visible_to: 1,
      date_time: new Date().toLocaleString(),
    };

    const notes = [
      {
        name: 'Contact note',
        content: `
    Contact request sent from Landing page.
    Please do contact to this e-mail address: ${email}

    Phase of research: Contact Me`,
      },
    ];

    // NOTE! It is an issue with sending in notes parameter as an array.
    // Therefor the mutation is created with hardcoded values,
    // and the notes values is "manually" serialized through a
    // own function in this service.
    const mutation = gql`
    mutation UpdatePipedrivePerson {
      UpdatePipedrivePerson(
        email: "${variables['email']}",
        name: "${variables['name']}",
        date_time: "${variables['date_time']}",
        form_submitted:"${variables['form_submitted']}",
        visible_to: "${variables['visible_to']}",
        notes: ${this.serializeObject(notes[0])},
      ) {
        pipedrive_field_check
        error
        actions {
          action
          info
          pipedrive_response
        }
      }
    }`;

    return this.clientApi
      .useClient('public')
      .mutate<any>({
        mutation,
      })
      .pipe(
        take(1),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      );
  }
}
