import { LabelOrientation } from './label-orientation';

export enum AssetIDs {
  Box = 'box',
  Box_Disabled = 'box_disabled',
  Box_Label_Front_Locked = 'box_label_front_locked',
  Box_Label_Back_Locked = 'box_label_back_locked',
  Box_Label_Front = 'box_label_front',
  Box_Label_Right_Locked = 'box_label_right_locked',
  Box_Label_Left_Locked = 'box_label_left_locked',
  Box_Label_Right = 'box_label_right',
  Box_Front = 'box_front',
  Box_Front_Label_Front_Locked = 'box_front_label_front_locked',
  Box_Front_Label_Back_Locked = 'box_front_label_back_locked',
  Box_Front_Label_Front = 'box_front_label_front',
  Box_Front_Label_Right_Locked = 'box_front_label_right_locked',
  Box_Front_Label_Left_Locked = 'box_front_label_left_locked',
  Box_Front_Label_Right = 'box_front_label_right',

  CompanyLogo = 'company_logo',
  CompanyLogoPreppedBox = 'companylogo_prepped_box',
  LabelTexture = 'label_texture',
  LabelCompactTexture = 'label_compact_texture',
  HelvetikerFont = 'helvetiker_font',

  ProjectViewRobot = 'project_view_robot',
  ProjectViewConveyor = 'project_view_conveyor',
  EURPallet = 'eur_pallet',

  SimconfigViewRobot = 'simconfig_view_robot',

  SimvizViewRobot = 'simviz_view_robot',
}

export function composeBoxAssetID(settings: {
  front: boolean;
  label: boolean;
  orientations?: LabelOrientation[];
  lockedOverride?: boolean;
  disabled?: boolean;
}): AssetIDs {
  const locked =
    typeof settings.lockedOverride === 'boolean'
      ? settings.lockedOverride
      : settings.orientations.length === 1;
  let boxID = 'box';
  // Disabled box is plain
  if (settings.disabled) {
    return (boxID + '_disabled') as AssetIDs;
  }
  if (settings.front) {
    boxID += '_front';
  }
  if (settings.orientations) {
    const orientation = resolveBoxLabelOrientation(
      locked,
      settings.orientations
    );
    if (settings.label && orientation) {
      boxID += '_label_' + orientation;
      if (locked) {
        boxID += '_locked';
      }
    }
  }
  return boxID as AssetIDs;
}

export function resolveBoxLabelOrientation(
  locked: boolean,
  orientations: LabelOrientation[]
): string {
  if (orientations.length > 0 && orientations[0] !== LabelOrientation.NULL) {
    if (!locked && orientations[0] === LabelOrientation.BACK) {
      return 'front'; // unlocked + back = unlocked + front
    }
    if (!locked && orientations[0] === LabelOrientation.LEFT) {
      return 'right'; // unlocked + left = unlocked + right
    }

    for (const [key, value] of Object.entries(LabelOrientation)) {
      if (value !== null && orientations[0] === value) {
        return key.toLowerCase();
      }
    }
  }
  return undefined; // Non-exsistent or invalid orientation
}
