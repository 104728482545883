import { Pipe, PipeTransform } from '@angular/core';
import {
  getConvertedValue,
  getConvertedValueAndUnit,
  ImperialUnit,
  MetricUnit,
  validUnitRegex,
  UnitSystemType,
  isValidUnitType,
} from '../utils/unit-utils';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { LocalStorageService } from '../services/local-storage.service';

@Pipe({
  name: 'unitSystem',
})
export class UnitSystemPipe implements PipeTransform {
  constructor(private localStorageService: LocalStorageService) {}
  /**
   * @desc Returns the inputed value converted to the requested or preferred unit system.
   * @param {string | number} value number | string. If inputed as number, needs of Unit to be specified.
   * @param {ImperialUnit | MetricUnit} unit imperialUnit and metricUnit types ('ft' | 'ft²' | 'in' | 'in/s' | 'in/s²' | 'oz' | 'lb' | 'm' | 'm²' | 'mm' | 'mm/s' | 'mm/s²' | 'g' | 'kg').
   * @param {'metric' | 'imperial'} outputSystemFormat (Optional) Will use the preferred unit system unless its specified here otherwise.
   * @param {boolean} fractional (Optiuonal) Will use fraction-based none-integer values.
   * @returns {string | number | object} or {value: number, unit: string}
   */
  transform(
    value: string | number,
    unit?: string | ImperialUnit | MetricUnit,
    outputSystemFormat?: UnitSystemType,
    fractional?: boolean
  ):
    | string
    | number
    | { value: number; unit: string | ImperialUnit | MetricUnit } {
    const preferredUnitSystem = this.localStorageService.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
    if (typeof value === 'string' && !unit) {
      if (validUnitRegex.test(value)) {
        return getConvertedValue(
          value,
          outputSystemFormat ?? (preferredUnitSystem as UnitSystemType),
          fractional
        );
      } else {
        return value;
      }
    } else if (!isNaN(+value) && unit) {
      return isValidUnitType(unit)
        ? getConvertedValueAndUnit(
            +value,
            unit,
            outputSystemFormat ?? (preferredUnitSystem as UnitSystemType),
            fractional
          )
        : value;
    } else {
      return value;
    }
  }
}
