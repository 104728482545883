import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { IApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { ObjectUtils } from '../../../../utils/object';
import { defaultScene } from '../default-scene';
import { defaultApiOrganization } from './default-api-organization';

export const defaultApiScene: IApiScene = {
  id: '001',
  name: 'Default Hardware Configuration',
  data: ObjectUtils.cloneObject(defaultScene),
  description: 'Default dummy data',
  organization_id: new ApiOrganization(defaultApiOrganization).id,
  created_at: new Date().getTime() + '',
  updated_at: new Date().getTime() + '',
  simulations: [],
  image: null,
};
