import { RoleAction } from '../config/role-gui/available-actions';

export enum ExpandableTableContentType {
  TYPE_TWO_COLUMN_TEXT = 'two_column_text',
  TYPE_CHIP = 'chip',
  TYPE_DONUT_CHART = 'donut',
}

export interface IExpandableTableItem<ActionType = never> {
  label: string;
  title?: string;
  value: string | number | Function | any[];
  unit?: string;
  link_id?: string;
  type?: ExpandableTableContentType;
  color?: string;
  action?: IExpandableTableAction<ActionType>;
}

export class ExpandableTableItem<ActionType = never>
  implements IExpandableTableItem<ActionType>
{
  label: string = undefined;
  title?: string = undefined;
  value: string | number | any[] | Function = undefined;
  unit?: string = undefined;
  link_id?: string = undefined;
  type?: ExpandableTableContentType =
    ExpandableTableContentType.TYPE_TWO_COLUMN_TEXT;
  color?: string = undefined;
  action?: IExpandableTableAction<ActionType>;

  constructor(options: IExpandableTableItem<ActionType>) {
    for (const d in options) {
      if (options.hasOwnProperty(d)) {
        this[d] = options[d];
      }
    }
  }
}

export interface IExpandableTableRowButton<ActionType = never> {
  label?: string;
  enabled: boolean;
  roleAction?: RoleAction;
  hrefLink?: string;
  actionId?: ActionType | 'divider';
  icon?: string;
  link_id?: string;
}

export interface IExpandableTableAction<ActionType = never> {
  label?: string;
  actionId: ActionType | 'divider';
  roleAction?: RoleAction;
  actionParam?: string;
  link_id?: string;
  icon?: string;
  txtColor?: string;
  disabled?: boolean;
}
export interface IExpandableTableData<ActionType = never> {
  id: string;
  error?: string;
  data?: any;
  items: IExpandableTableItem<ActionType>[];
  rowActions?: IExpandableTableAction<ActionType>[];
  rowButtons?: IExpandableTableRowButton<ActionType>[];
  expandableContent: {
    graphicalResources: string | any[] | number;
    items: ExpandableTableItem<ActionType>[];
    headerLeft?: ExpandableTableItem;
    leftContentWidth: 'col-3' | 'col-4' | 'col-5' | 'col-6' | 'col-7' | 'col-8';
  };
}

export interface ISceneImageAsset {
  id: string;
  url?: string;
}
