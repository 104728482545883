import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HardwareComponent } from '../../components/inventory/hardware/hardware.component';
import { HardwareWrapperComponent } from '../../components/inventory/hardwares/hardware-wrapper/hardware-wrapper.component';
import { pagesPATH } from '../../models_new/config/pages';
import { InventoryHardwaresComponent } from '../../components/inventory/hardwares/inventory-hardware/inventory-hardwares.component';
import { OrganizationContextResolver } from 'src/app/resolvers/organization-context-resolver.resolver';

const routes: Routes = [
  {
    path: '',
    component: HardwareWrapperComponent,
    resolve: { organization_id: OrganizationContextResolver },
    data: {
      breadcrumb: 'Hardwares',
      bcRouteTo: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
    },
    children: [
      {
        path: '',
        component: InventoryHardwaresComponent,
        data: {
          breadcrumb: undefined, // We use the breadcrumb from the parent
        },
      },
      {
        component: HardwareComponent,
        path: ':id',
        data: {
          breadcrumb: 'Hardware',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HardwareRoutingModule {}
