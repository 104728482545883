export class LoadStatus {
  status = false;
  title = '';
  msg = '';
  failed = false;

  /**
   * @param status is TRUE while loading
   * @param title title of loading card
   * @param msg message on loading card
   * @param failed is TRUE when failed
   */
  constructor(
    status: boolean = false,
    failed: boolean = false,
    title: string = '',
    msg: string = ''
  ) {
    this.status = status;
    this.failed = failed;
    this.title = title;
    this.msg = msg;
  }
}
