export interface IApiUser {
  readonly id: string;
  email: string;
  user_metadata: {
    company: string;
    firstName: string;
    job_title: string;
    lastName: string;
    picture: string;
  };
}

export class ApiUser implements IApiUser {
  readonly id: string;
  email: string;
  user_metadata: {
    company: string;
    firstName: string;
    job_title: string;
    lastName: string;
    picture: string;
  };
  org_id: string;
  type: string;

  constructor(user: IApiUser) {
    for (const d in user) {
      if (user.hasOwnProperty(d)) {
        this[d] = user[d];
      }
    }
  }
}
