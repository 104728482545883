<ng-container *appVar="openSimService.embedHandler$ | async as isEmbedValid">
  <!-- While loading, show loading cmoponent -->
  <app-loading *ngIf="isEmbedValid.isLoading"></app-loading>

  <!-- If error, or done loading & embeded apiKey not valid, show error -->
  <ng-container
    *ngIf="
      (!isEmbedValid.isLoading && !isEmbedValid.value) || isEmbedValid.error
    "
  >
    <div class="denied-embed-content">
      <h1>Access denied</h1>
      <p>The API key you provided is invalid. Please check and try again.</p>
    </div>
  </ng-container>

  <!-- If done loading & embeded apiKey is valid, show the fast track outlet -->
  <ng-container *ngIf="isEmbedValid.value">
    <div
      class="landing-outlet-wrapper"
      id="landing-outlet-wrapper"
      [ngClass]="{ embedded: openSimService.embed }"
    >
      <ng-container
        *ngIf="
          openSimService.showNavTopBottom() ||
          (openSimService.embed && openSimService.started)
        "
      >
        <app-fast-track-navbar
          *ngIf="openSimService.contentFulContent$ | async"
        ></app-fast-track-navbar>
      </ng-container>

      <div
        class="landing-outlet"
        [ngClass]="{ topNavBar: openSimService.showNavTopBottom() }"
      >
        <ng-container *ngIf="!openSimService.embed">
          <router-outlet></router-outlet>
        </ng-container>

        <ng-container *ngIf="openSimService.embed">
          <ng-container *ngIf="!openSimService.started">
            <app-fast-track-landing></app-fast-track-landing>
          </ng-container>
          <ng-container *ngIf="openSimService.started">
            <ng-container [ngSwitch]="openSimService.selectedStepIndex">
              <ng-container *ngSwitchCase="0">
                <app-fast-track-product></app-fast-track-product>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <app-fast-track-pattern></app-fast-track-pattern>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <app-fast-track-region></app-fast-track-region>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <app-fast-track-user-info></app-fast-track-user-info>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!deviceDialogDismissed">
        <div class="overlay">
          <div class="iconContainer card">
            <div class="phone"><mat-icon class="icon">refresh</mat-icon></div>
            <div class="card-text">
              <span>
                For a better experience,<br />
                rotate your device
              </span>
            </div>
          </div>
          <button
            mat-flat-button
            pally-button-l
            pally-gradient
            color="accent"
            class="dismiss-button"
            (click)="deviceDialogDismissed = true"
          >
            Dismiss
          </button>
        </div>
      </ng-container>
    </div>
    <app-fast-track-footer
      *ngIf="!openSimService.embed"
    ></app-fast-track-footer>
  </ng-container>
</ng-container>
