import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { ObjectUtils } from 'src/app/utils/object';
import { Field } from './field';
import { SimulationApiGroup } from '../classes/simulation-api-group';
import { simulationFieldConfig } from '../config/simulation-fields-config';
import {
  IEnabledByField,
  ISimApiFormConfig,
} from '../types/sim-api-form-config';
import { ISimulationApiFileType } from '../types/simulation-api-file-type';
import { FieldType } from '../types/field-type';
import { Box } from './box';
import { defaultScene } from '../config/default/default-scene';
import { defaultStrategy } from '../config/default/default-strategy';

export interface ReadonlyFieldMaker {
  getFieldWithEnabledById(enablerId: string): Field[];
  getEnablingFields(): string[];
  getAllFields(): Field[];
  getFieldsByAllParentId(parentId: string): Field[];
  getFieldsByFirstParentId(parentId: string): Field[];
  getFieldByName(name: string): Field;
  getFieldById(id: string): Field;
  getConfigByName(
    id: string,
    fieldConfig: ISimApiFormConfig[]
  ): ISimApiFormConfig | undefined;
  getFieldConfig(id: string): ISimApiFormConfig;
}

interface PatchField {
  id: string;
  parentId: string;
  value?: any;
}
/**@deprecated
 * This class will soon be deprecated. Construct {@link Field} classes without this class
 */
export class FieldMaker implements ReadonlyFieldMaker {
  addedFields: Field[] = [];
  addedGroups: SimulationApiGroup[] = [];
  conditionalParentFields: Field[] = [];
  addedFieldChilds: Field[] = [];
  conditionalFieldsSub: Subscription;
  childrenFieldsSub: Subscription;

  storedData: ISimulationApiFileType;
  patchDataFields: PatchField[] = [];

  defaultSceneAndStrategy: ISimulationApiFileType = {
    scene: defaultScene,
    strategy: defaultStrategy,
  };
  mainFormGroup: SimulationApiGroup = new SimulationApiGroup('mainFormGroup');
  childrenDepth = 1;
  maxGrip: number;
  boxDimensions: Box['dimensions'];

  constructor(
    maxGrip: number,
    boxDimensions: Box['dimensions'],
    storedData?: ISimulationApiFileType,
    storedGuiSettings?: { id: string; value: any }[]
  ) {
    this.maxGrip = maxGrip;
    this.boxDimensions = boxDimensions;

    if (storedData) {
      this.storedData = ObjectUtils.cloneObject(storedData);
      delete this.storedData.pattern;
      this.makePatchData(this.storedData, [], 'mainFormGroup');
    }

    if (storedGuiSettings) {
      const guiSettings: { id: string; value: any }[] =
        ObjectUtils.cloneObject(storedGuiSettings);
      guiSettings.forEach((f) => {
        const split = f.id.split('.');
        const parent = split.slice(0, split.length - 1).join('.');
        const newField: PatchField = {
          id: f.id,
          parentId: parent,
          value: f.value,
        };

        this.patchDataFields.push(newField);
      });
    }

    this.makeChildren(
      this.defaultSceneAndStrategy,
      this.mainFormGroup,
      'mainFormGroup',
      storedData ? true : false
    );

    this.addConditionalFields();
    this.subToChildFields();
  }

  subToChildFields() {
    // Special case when a field (not formGroup) has children. Should listen for children updates and update parent field value.

    const sources: Observable<any>[] = [];
    if (this.childrenFieldsSub) {
      this.childrenFieldsSub.unsubscribe();
    }

    this.addedFieldChilds.forEach((f: Field) => {
      sources.push(f.formControl.valueChanges.pipe(startWith(f.getValue())));
    });

    // eslint-disable-next-line
    this.childrenFieldsSub = combineLatest(sources).subscribe((values) => {
      values.forEach((value: any, i) => {
        const f = this.addedFieldChilds[i];

        const parentId = f.getParentId();
        const childIndex = +f.getId().substr(f.getId().length - 1);

        // new parent value
        let parentValue = this.getFieldById(parentId).formControl.value;
        if (!Array.isArray(parentValue)) {
          parentValue = [parentValue];
        }
        parentValue[childIndex] = isNaN(value) ? value : +value;
        const parent = this.getFieldById(parentId);

        let correct;
        // Check if order of values are valid
        if (parent.valueOrder === 'asc' || !parent.valueOrder) {
          correct = parentValue.slice().sort((a: any, b: any) => a - b);
        }
        if (parent.valueOrder === 'desc') {
          correct = parentValue.slice().sort((a: any, b: any) => b - a);
        }

        if (JSON.stringify(correct) !== JSON.stringify(parentValue)) {
          f.formControl.setErrors({ incorrect: true });
          f.formControl.markAsTouched();
        } else {
          f.formControl.setErrors(null);
          f.formControl.markAsTouched();
        }

        parent.formControl.setValue(parentValue);
      });
    });
  }

  addConditionalFields() {
    // Field might be a new field, or a generated field from params const.
    const fields = this.getEnablingFields();

    fields.forEach((f: string) => {
      let added = this.getFieldById(f);
      if (!added) {
        added = this.getFieldByName(f);
      }
      let newField;

      if (!added) {
        const fieldConfig = this.getFieldConfig(f);
        newField = this.makeField(f, fieldConfig, f, null);
        this.addedFields.push(newField);
      } else {
        newField = added;
      }
      this.conditionalParentFields.push(newField);
    });

    const sources: Observable<any>[] = [];
    if (this.conditionalFieldsSub) {
      this.conditionalFieldsSub.unsubscribe();
    }

    this.conditionalParentFields.forEach((f: Field) => {
      // eslint-disable-next-line
      sources.push(f.formControl.valueChanges.pipe(startWith(f.getValue())));
    });

    // Corresponds to fields list
    this.conditionalFieldsSub = combineLatest(sources).subscribe(
      (enablingFields) => {
        enablingFields.forEach((s: IEnabledByField, idx: number) => {
          this.enableFieldsWithEnabler({
            id: fields[idx],
            value: s as any,
          });
        });
      }
    );
  }

  enableFieldsWithEnabler(enabler: IEnabledByField) {
    const fieldsEnabledByEnabler = this.getFieldWithEnabledById(enabler.id);

    if (fieldsEnabledByEnabler.length) {
      fieldsEnabledByEnabler.forEach((f: Field) => {
        f.reactive.enabled_by_field.forEach((e: IEnabledByField) => {
          if (e.id === enabler.id) {
            if (e.value === enabler.value) {
              e.validCondition = true;
            } else {
              e.validCondition = false;
            }
          }
        });

        const allConditionsValid = f.reactive.enabled_by_field
          .map((m) => m.validCondition)
          .every((v) => v === true);

        allConditionsValid ? f.show() : f.hide();
        if (this.getFieldConfig(f.id).disabled) {
          f.disable();
        }
      });
    }
  }

  getFieldWithEnabledById(enablerId: string): Field[] {
    return this.addedFields
      .filter((f: Field) => f.reactive.enabled_by_field.length)
      .filter((f: Field) =>
        f.reactive.enabled_by_field.map((m) => m.id).includes(enablerId)
      );
  }

  getEnablingFields(): string[] {
    const enablingFields = [];
    this.addedFields
      .filter((f: Field) => f.reactive.enabled_by_field.length)
      .map((f: Field) => f.reactive.enabled_by_field)
      .forEach((e: IEnabledByField[]) => {
        const fields = e.map((m) => m.id);

        fields.forEach((ef) => {
          if (!enablingFields.includes(ef)) {
            enablingFields.push(ef);
          }
        });
      });

    return [].concat.apply([], enablingFields);
  }

  getPatchDataById(id: string): PatchField['value'] {
    const patchFields = this.patchDataFields.filter((f) => f.id === id);
    if (patchFields.length) {
      return patchFields[0].value !== null && patchFields[0].value !== undefined
        ? patchFields[0].value
        : null;
    } else {
      return null;
    }
  }

  getFieldByTabGroupId(tabGroupId: string): Field[] {
    return this.addedFields
      .filter((f: Field) => f.tabGroupId === tabGroupId)
      .map((m, i) => {
        if (m.guiOrderIdx === null || m.guiOrderIdx === undefined) {
          m.guiOrderIdx = 100 + i;
        }
        return m;
      })
      .sort((a, b) =>
        a.guiOrderIdx > b.guiOrderIdx
          ? 1
          : a.guiOrderIdx === b.guiOrderIdx
          ? 0
          : -1
      );
  }

  getChildrenDepth() {
    return this.childrenDepth;
  }

  getMainFormGroup(): SimulationApiGroup {
    return this.mainFormGroup;
  }

  getAllFields(): Field[] {
    return this.addedFields;
  }

  getFieldsByAllParentId(parentId: string): Field[] {
    const hasParent: Field[] = [];

    this.addedFields.forEach((field: Field) => {
      if (field.parentId.includes(parentId)) {
        hasParent.push(field);
      }
    });

    return hasParent;
  }

  getFieldsByFirstParentId(parentId: string): Field[] {
    return this.addedFields.filter((f) => f.parentId === parentId);
  }

  getFieldByName(name: string): Field {
    const splittedName = name.split('.');
    let found;

    this.addedFields.forEach((f: Field) => {
      const splittedId = f.id.split('.');

      if (splittedName.every((r) => splittedId.includes(r))) {
        found = f;
      }
    });

    return found;
  }

  getFieldById(id: string): Field {
    let found = this.addedFields.filter((f) => f.id === id);

    if (!found.length) {
      found = this.addedFieldChilds.filter((f) => f.id === id);
    }

    return found.length ? found[0] : null;
  }

  makePatchData(obj: ISimulationApiFileType, group: any, parentId: string) {
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      if (
        typeof obj[key as keyof ISimulationApiFileType] === 'object' &&
        key !== 'products'
      ) {
        const newGroup = {
          id: parentId + '.' + key,
          parentId: parentId,
        };

        group[key] = newGroup;

        this.makePatchData(obj[key], newGroup, newGroup.id);
      } else {
        const newField: PatchField = {
          id: parentId + '.' + key,
          parentId: parentId,
          value: obj[key as keyof ISimulationApiFileType],
        };

        group[key] = newField;

        this.patchDataFields.push(newField);
      }
    }
  }

  addChildToField(
    fieldId: string,
    label: string,
    hint: string,
    defaultValue: string | number
  ) {
    const field = this.getFieldById(fieldId);
    const fieldConfig = this.getFieldConfig(fieldId);
    const childIndex = field.children.length;

    const childConfig = ObjectUtils.cloneObject(
      this.getFieldConfig(fieldConfig.childrenFieldName)
    );
    childConfig.label = `${label} ${childIndex + 1}`;
    childConfig.hint = `${hint} ${childIndex + 1}`;
    childConfig.defaultValue = defaultValue;
    const childField = this.makeField(
      fieldConfig.childrenFieldName + '.' + childIndex,
      childConfig,
      fieldConfig.childrenFieldName + childIndex,
      fieldId
    );

    field.addChild(childField);
    this.addedFieldChilds.push(childField);
    this.subToChildFields();
  }

  removeChildFromField(fieldId: string) {
    const field = this.getFieldById(fieldId);
    const parent = this.getFieldById(field.parentId);

    parent.children.forEach((f) => {
      this.addedFieldChilds = this.addedFieldChilds.filter(
        (af) => af.id !== f.id
      );
    });
    parent.removeChild(fieldId);
    parent.children.forEach((f) => {
      this.addedFieldChilds.push(f);
    });
    this.subToChildFields();
  }

  makeField(
    fieldId: string,
    fieldConfig: ISimApiFormConfig,
    key: string,
    parentId: string
  ) {
    const patchData = this.getPatchDataById(fieldId);
    const newField = new Field(
      fieldConfig.type,
      fieldConfig.required,
      patchData !== null && patchData !== undefined
        ? patchData
        : fieldConfig.defaultValue,
      fieldConfig.validators,
      fieldConfig.tabGroupId,
      fieldConfig.options,
      fieldConfig.guiOrderIdx,
      {
        label: fieldConfig.label,
        name: fieldConfig.id,
        suffix: fieldConfig.suffix,
        hint: fieldConfig.hint,
      },
      fieldId
    );
    newField.id = fieldId;
    newField.parentId = parentId;

    if (fieldConfig.type === FieldType.NUMBER && fieldConfig.step) {
      newField.step = fieldConfig.step;
    } else {
      newField.step = 1;
    }

    if (fieldConfig.valueOrder) {
      newField.valueOrder = fieldConfig.valueOrder;
    }

    if (fieldConfig.maxChildren) {
      newField.maxChildren = fieldConfig.maxChildren;

      if (
        fieldConfig.id ===
          'mainFormGroup.scene.conveyors.custom_description.sensors.products' &&
        this.maxGrip !== null &&
        this.maxGrip !== undefined
      ) {
        newField.maxChildren = this.maxGrip;
      }
    }

    if (fieldConfig.childrenFieldName && fieldConfig.childrenFieldName.length) {
      newField.children = [];
      const childConfig = ObjectUtils.cloneObject(
        this.getFieldConfig(fieldConfig.childrenFieldName)
      );
      let label = 'Empty label';
      let hint = 'Empty hint';

      if (
        fieldConfig.id ===
        'mainFormGroup.scene.conveyors.custom_description.sensors.products'
      ) {
        label = 'Product no.';
        hint = 'Location of sensor no.';

        const increment = (this.boxDimensions.length + 10) / 1000;

        if (!this.storedData) {
          // If default sim config

          for (let i = 0; i < newField.maxChildren; i++) {
            const prevValue = fieldConfig.defaultValue[i - 1] || 0;
            const childDefault = i > 0 ? prevValue + increment : 0;
            (fieldConfig.defaultValue as number[]).push(childDefault);
          }
        } else {
          // If stored data. Refresh/import etc...
          const patchArray =
            this.getPatchDataById(fieldId) ||
            (fieldConfig.defaultValue as number[]);
          (fieldConfig.defaultValue as number[]) = patchArray;
        }
      }

      (fieldConfig.defaultValue as any[]).forEach((value, index) => {
        const childPatchData =
          this.getPatchDataById(fieldId) !== null &&
          this.getPatchDataById(fieldId) !== undefined
            ? this.getPatchDataById(fieldId)[index]
            : null;

        childConfig.defaultValue = childPatchData
          ? childPatchData
          : fieldConfig.defaultValue[index];
        childConfig.label = `${label} ${index + 1}`;
        childConfig.hint = `${hint} ${index + 1}`;
        const childField = this.makeField(
          fieldConfig.childrenFieldName + '.' + index,
          childConfig,
          fieldConfig.childrenFieldName + index,
          fieldId
        );
        newField.children.push(childField);
        this.addedFieldChilds.push(childField);
      });
    }

    if (fieldConfig.enabledByFields && fieldConfig.enabledByFields.length) {
      newField.setEnabledBy(fieldConfig.enabledByFields);
    }

    newField.reactive.updatesFieldFn = fieldConfig.updatesFieldFn
      ? fieldConfig.updatesFieldFn
      : undefined;

    if (
      fieldConfig.updatesFieldsOnChange &&
      fieldConfig.updatesFieldsOnChange.length
    ) {
      newField.reactive.updates_fields_onChange =
        fieldConfig.updatesFieldsOnChange;
    }
    if (
      fieldConfig.postUpdatesFieldsOnChange &&
      fieldConfig.postUpdatesFieldsOnChange.length
    ) {
      newField.reactive.post_updates_fields_onChange =
        fieldConfig.postUpdatesFieldsOnChange;
    }

    if (fieldConfig.disabled) {
      newField.disable();
    }

    return newField;
  }

  makeChildren(
    obj: any,
    group: SimulationApiGroup,
    parentId: string,
    storedData: boolean
  ) {
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      if (typeof obj[key] === 'object' && key !== 'products') {
        const newGroup = new SimulationApiGroup(key);
        newGroup.id = parentId + '.' + key;
        newGroup.parentId = parentId;

        group.addChild(newGroup);

        this.addedGroups.push(newGroup);

        this.makeChildren(obj[key], newGroup, newGroup.id, storedData);
      } else {
        const fieldId = parentId + '.' + key;
        const fieldConfig = this.getFieldConfig(fieldId);
        const newField = this.makeField(fieldId, fieldConfig, key, parentId);

        group.addChild(newField);
        this.addedFields.push(newField);

        const childDepth = this.getDepthById(newField.id);
        if (childDepth > this.childrenDepth) {
          this.childrenDepth = childDepth;
        }
      }
    }
  }

  makeExportValues(obj: any | ISimulationApiFileType): ISimulationApiFileType {
    for (const key in obj) {
      if (
        typeof obj[key] === 'object' &&
        key !==
          'mainFormGroup.scene.conveyors.custom_description.sensors.products'
      ) {
        if (obj[key] && Object.keys(obj[key]).includes('0')) {
          const newArray = [];

          for (const arrObj in obj[key]) {
            if (obj[key][arrObj]) {
              newArray.push(obj[key][arrObj]);
            }
          }

          obj[key] = newArray;
        }

        this.makeExportValues(obj[key]);
      } else {
        // Take last part of id as new name to match interface
        // Current id was used to difference fields
        const splittedId = key.split('.');
        const newKey = splittedId[splittedId.length - 1];
        let newObj = ObjectUtils.cloneObject(obj[key]);

        if (
          !isNaN(newObj) &&
          !Array.isArray(newObj) &&
          typeof newObj !== 'boolean' &&
          newKey !== 'polyscope_version' // Polyscope is special case, should be a string
        ) {
          newObj = parseFloat(newObj);
        }

        obj[newKey] = newObj;
        delete obj[key];
      }
    }

    return obj;
  }

  getDepthById(id: string): number {
    // Count number of dots. Add last depth after last dot since its not counted
    return id.match(/\./g).length + 1;
  }

  getConfigByName(
    id: string,
    fieldConfig: ISimApiFormConfig[]
  ): ISimApiFormConfig | undefined {
    const splittedId = id.split('.');
    let found;

    fieldConfig.forEach((f: ISimApiFormConfig) => {
      const splittedName = f.id.split('.');

      if (splittedName.every((r) => splittedId.includes(r))) {
        found = f;
      }
    });

    return found;
  }

  getFieldConfig(id: string): ISimApiFormConfig {
    const fieldConfig: ISimApiFormConfig[] = ObjectUtils.cloneObject(
      simulationFieldConfig
    );
    const directIdFound = fieldConfig.filter((f) => f.id === id);

    if (directIdFound.length) {
      return directIdFound[0];
    } else {
      // Ids might be added from an array and containing index in id
      // Look for matches.
      const foundConfig = this.getConfigByName(id, fieldConfig);

      if (foundConfig) {
        return foundConfig;
      } else {
        return fieldConfig.filter((f) => f.id === 'mainFormGroup.default')[0];
      }
    }
  }
}
