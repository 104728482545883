<div class="error-component-wrapper">
  <ng-container *appVar="errorLogEntry$ | async as errorEntry">
    <div class="error-header">
      <div class="error-icon-glitchy">
        <mat-icon>error_outline</mat-icon>
      </div>
      <div class="error-message">
        <p class="message">
          {{ errorEntry?.guiText?.message || processedMessage }}
        </p>
        <p class="suggestion">
          {{ errorEntry?.guiText?.suggestion || processedSuggestion }}
        </p>
      </div>
    </div>

    <ng-container *ngIf="!hideActions">
      <div class="error-datails-expand" *ngIf="error?.stack">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> <b>See error details </b></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="error-details-code">
            <h4>Error:</h4>
            <pre
              class="code-block"
            ><code>{{  error.stack || 'Undefined' }}</code></pre>
            <button
              mat-icon-button
              (click)="doAction('copyError', errorEntry)"
              matTooltip="Copy error"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <div
            class="error-details-context"
            *ngIf="errorEntry?.context?.length"
          >
            <h4>Url/Request context:</h4>
            <pre
              class="code-block"
            ><code><ng-container *ngFor="let item of errorEntry.context">{{ item }}<br /></ng-container></code></pre>
          </div>
        </mat-expansion-panel>
      </div>

      <div class="error-actions">
        <button
          mat-stroked-button
          color="primary"
          (click)="doAction('navigateBack')"
        >
          <mat-icon>navigate_before</mat-icon>
          Go back
        </button>
        <!-- @deprecated : DataDog no longer available. -->
        <!-- <button
          mat-flat-button
          color="primary"
          (click)="doAction('report', errorEntry)"
          [disabled]="isErrorReported"
        >
          Report <mat-icon>flag</mat-icon>
        </button> -->
        <button
          mat-stroked-button
          color="primary"
          (click)="doAction('refresh')"
        >
          Refresh <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
