import { PalletPosition } from '../enums/pallet-position';
import { Unit } from '../enums/unit';
import { Unit as UnitType } from '../types/unit';
import { IPdfGeneratorData } from '../types/project-report-data';
import { Project } from './project';
import { reportMeasurement } from '../config/form_fields';
import { ObjectUtils } from 'src/app/utils/object';
import { UnitConversionPipe } from 'src/app/pipes/unit-conversion.pipe';
import { ITableData } from '../../components/gui/table/table.component';
import { UnitSystemPipe } from 'src/app/pipes/unit-system.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export class PdfGeneratorData {
  details: IPdfGeneratorData['details'];
  box: IPdfGeneratorData['box'];
  pallets: IPdfGeneratorData['pallets'];
  unitFormulas: UnitType[] = reportMeasurement;
  unit: Unit;
  unitsPipe: UnitConversionPipe;

  constructor(
    project: Project,
    unit: Unit,
    unitsPipe: UnitConversionPipe,
    palletImage: string,
    palletTopImage?: string
  ) {
    this.unit = unit;
    this.unitsPipe = unitsPipe;

    const now = new Date();
    const dateAsFormat = `${now.getDate()}/${
      now.getMonth() + 1
    }/${now.getFullYear()}`;
    const dateFormat = 'd/m/y';

    this.details = {
      project_name: project.data.name,
      project_description: project.data.description,
      project_date: dateAsFormat,
      date_format: dateFormat,
    };
    this.box = {
      width: this.getValueAsUnit(project.data.box.dimensions.width),
      height: this.getValueAsUnit(project.data.box.dimensions.height),
      length: this.getValueAsUnit(project.data.box.dimensions.length),
      weight: this.getValueAsUnit(project.data.box.weight, true),
    };

    const rightPallet = project.getPalletByPosition(PalletPosition.RIGHT);
    const leftPallet = project.getPalletByPosition(PalletPosition.LEFT);

    const rightCOG = rightPallet.getPalletSummary().palletCenterOfMass;
    const leftCOG = leftPallet.getPalletSummary().palletCenterOfMass;

    this.pallets = [
      {
        position: 'LEFT',
        length: this.getValueAsUnit(leftPallet.dimensions.length),
        width: this.getValueAsUnit(leftPallet.dimensions.width),
        net_height: this.getValueAsUnit(leftPallet.getPalletLoadHeight()),
        grs_height: this.getValueAsUnit(leftPallet.getPalletTotalHeight(true)),
        net_weight: this.getValueAsUnit(
          leftPallet.getPalletSummary().palletWeight,
          true
        ),
        grs_weight: this.getValueAsUnit(0, true),
        cube: this.getValueAsUnit(0),
        shipper: this.getValueAsUnit(0),
        area_efficiency: +leftPallet
          .getPalletSummary()
          .palletAreaEfficiency.toFixed(2),
        cubic_efficiency: +leftPallet
          .getPalletSummary()
          .palletCubeEfficiency.toFixed(2),
        layersNo: leftPallet.getPalletSummary().numberOfLayers,
        boxesNo: leftPallet.getPalletSummary().boxCount,
        image: palletImage,
        imageTop: palletTopImage,
        center_of_mass: {
          x: this.getValueAsUnit(leftCOG.x),
          y: this.getValueAsUnit(leftCOG.y),
          z: this.getValueAsUnit(leftCOG.z),
        },
      },
      {
        position: 'RIGHT',
        length: this.getValueAsUnit(rightPallet.dimensions.length),
        width: this.getValueAsUnit(rightPallet.dimensions.width),
        net_height: this.getValueAsUnit(rightPallet.getPalletLoadHeight()),
        grs_height: this.getValueAsUnit(rightPallet.getPalletTotalHeight(true)),
        net_weight: this.getValueAsUnit(
          rightPallet.getPalletSummary().palletWeight,
          true
        ),
        grs_weight: this.getValueAsUnit(0, true),
        cube: this.getValueAsUnit(0),
        shipper: this.getValueAsUnit(0),
        area_efficiency: +rightPallet
          .getPalletSummary()
          .palletAreaEfficiency.toFixed(2),
        cubic_efficiency: +rightPallet
          .getPalletSummary()
          .palletCubeEfficiency.toFixed(2),
        layersNo: rightPallet.getPalletSummary().numberOfLayers,
        boxesNo: rightPallet.getPalletSummary().boxCount,
        image: palletImage,
        imageTop: palletTopImage,
        center_of_mass: {
          x: this.getValueAsUnit(rightCOG.x),
          y: this.getValueAsUnit(rightCOG.y),
          z: this.getValueAsUnit(rightCOG.z),
        },
      },
    ];
  }

  getValueAsUnit(value: number, isWeight: boolean = false): number {
    let converted = value;

    if (this.unit !== Unit.METRIC) {
      converted = ObjectUtils.cloneObject(value);
      const unitFormula = this.getSelectedUnitFormula();
      converted = this.unitsPipe.format(
        converted,
        isWeight ? 'weight' : null,
        unitFormula
      );
    }

    if (isWeight && this.unit === Unit.METRIC) {
      // Convert to kg
      converted = converted / 1000;
    }

    return +converted.toFixed(2);
  }

  getSelectedUnitFormula(): UnitType {
    const selectedUnitFormula = this.unitFormulas.filter(
      (f) => f.value === this.unit
    );
    if (selectedUnitFormula.length) {
      return selectedUnitFormula[0];
    } else {
      return this.unitFormulas[0];
    }
  }

  getData(): IPdfGeneratorData {
    return {
      details: this.details,
      box: this.box,
      pallets: this.pallets,
      lengthSuffix: this.getSelectedUnitFormula().suffixLW,
      massSuffix: this.getSelectedUnitFormula().suffixW,
    };
  }

  getPalletTableData(): ITableData<any>[] {
    let data = [];

    const pallet = this.pallets[1];
    const box = this.box;

    data.push([
      'Total Length',
      this.unitSysCheck(box.length + ' mm'),
      this.unitSysCheck(pallet.length + ' mm'),
    ]);
    data.push([
      'Total Width',
      this.unitSysCheck(box.width + ' mm'),
      this.unitSysCheck(pallet.width + ' mm'),
    ]);
    data.push([
      'Net Height',
      this.unitSysCheck(box.height + ' mm'),
      this.unitSysCheck(pallet.net_height + ' mm'),
    ]);
    data.push([
      'Gross Height',
      this.unitSysCheck(box.height + ' mm'),
      this.unitSysCheck(pallet.grs_height + ' mm'),
    ]);
    data.push([
      'Net Weight',
      this.unitSysCheck(box.weight + ' kg'),
      this.unitSysCheck(pallet.net_weight + ' kg'),
    ]);
    data.push(['Area Efficiency', '', pallet.cubic_efficiency + '%']);
    data.push(['Cubic Efficiency', '', pallet.area_efficiency + '%']);
    data.push(['Cases', '', pallet.boxesNo]);
    data.push(['Number of layers', '', pallet.layersNo]);
    let tableData: ITableData<any>[] = [];
    for (let i = 0; i < data.length; i++) {
      let row = {};
      row['Detail'] = data[i][0];
      row['Case'] = data[i][1];
      row['Unit Load'] = data[i][2];
      tableData.push({
        data: row,
      });
    }
    return tableData;
  }

  private unitSysCheck(value: string): string {
    return `${new UnitSystemPipe(new LocalStorageService()).transform(value)}`;
  }
}
