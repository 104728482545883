import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { RobotConfigApiService } from '../../../services/api/robot-config-api.service';
import { PresetPickerData } from '../../../models_new/types/preset-picker-data';
import { HardwareApiService } from '../../../services/api/hardware-api.service';
import { DataRequestState } from '../../../data-request/model';
import { toRequestState } from '../../../data-request/operators';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { NotificationService } from 'src/app/services/notification.service';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
@Component({
  selector: 'app-robot-config',
  templateUrl: './robot-config.component.html',
  styleUrls: ['./robot-config.component.scss'],
})
export class RobotConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  organizationId$: Observable<string>;
  robotConfig$: Observable<
    DataRequestState<{ name: string; scene_id: string; strategy_id: string }>
  >;
  hardwarePresets$: Observable<PresetPickerData[]>;
  softwarePresets$: Observable<PresetPickerData[]>;
  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() configId?: string;
  @Input() orgId?: string;
  @Input() isDialog?: boolean = false;

  @Output() savedConfig = new EventEmitter<{ id: string }>();

  savingState: boolean = false;

  formGroup = new UntypedFormGroup({
    name: new FormControl<string>('', [Validators.required]),
    hw: new FormControl<string>('', [Validators.required]),
    sw: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private robotconfigApi: RobotConfigApiService,
    private softwareApi: SoftwareApiService,
    private hardwareApi: HardwareApiService,
    private notify: NotificationService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.robotConfig$ = this.route.params.pipe(
      take(1),
      switchMap((params) => {
        return this.configId === 'new' || params.id === 'new'
          ? of(null)
          : this.robotconfigApi.fetchRobotConfigBasic(params.id);
      }),
      tap((robotConfig) => {
        if (robotConfig) {
          this.updateValue(robotConfig.name, 'name');
          this.updateValue(robotConfig.scene_id, 'hw');
          this.updateValue(robotConfig.strategy_id, 'sw');
        } else {
          if (!this.isDialog) {
            // If query params includes software=true || hardware=true then set the value from localStorage
            const queryParams = this.route.snapshot.queryParams;
            const localStorage = this.localStorageService.getData(
              LocalStorageKey.ROBOT_CONFIG
            );
            if (
              localStorage &&
              (queryParams.software || queryParams.hardware)
            ) {
              this.updateValue(localStorage.name, 'name');
              this.updateValue(localStorage.hw, 'hw');
              this.updateValue(localStorage.sw, 'sw');
            }
          }
        }
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    this.organizationId$ =
      this.isDialog && this.orgId
        ? of(this.orgId)
        : this.route.data.pipe(
            take(1),
            map(
              (data: Data) =>
                (data as IOrganizationContextResolverData).organization_id
            ),
            shareReplay({ bufferSize: 1, refCount: true })
          );

    this.hardwarePresets$ = this.organizationId$.pipe(
      switchMap((orgId: string) =>
        combineLatest([
          this.hardwareApi.fetchSimWizSalesOrgsHardwares(orgId),
          this.hardwareApi.fetchSimWizHardwares(orgId),
          this.robotConfig$,
        ])
      ),
      take(1),
      map(([hardwaresSo, hardwaresCo, robot]) => {
        const hws = hardwaresSo.concat(hardwaresCo);
        const formValue = this.formGroup.value;
        return hws
          .sort((a, b) => b.updated_at.localeCompare(a.updated_at))
          .map((hardware) => {
            const isSelectedHw =
              (robot?.value && hardware.id === robot.value.scene_id) ||
              formValue.hw === hardware.id;
            if (isSelectedHw) {
              this.updateValue(hardware.id, 'hw');
            }
            return {
              id: hardware.id,
              favourite: hardware.data.favourite,
              image:
                hardware.image?.url ||
                '../../../../assets/dummy/simulation-teaser.jpg',
              label: hardware.name,
              selected: isSelectedHw,
            };
          });
      })
    );

    this.softwarePresets$ = this.organizationId$.pipe(
      switchMap((orgId: string) =>
        combineLatest([
          this.softwareApi.fetchSimWizSalesOrgsStrategies(orgId),
          this.softwareApi.fetchSimWizStrategies(orgId),
          this.robotConfig$,
        ])
      ),
      take(1),
      map(([softwaresSo, softwaresCo, robot]) => {
        const sws = softwaresSo.concat(softwaresCo);
        const formValue = this.formGroup.value;
        return sws
          .sort((a, b) => b.updated_at.localeCompare(a.updated_at))
          .map((software, index) => {
            let isSelected =
              (robot?.value && software.id === robot.value.strategy_id) ||
              formValue.sw === software.id;

            // If query params includes software=true and no robot.value, the first in array should be selected
            if (
              this.route.snapshot.queryParams.software &&
              !robot?.value &&
              index === 0
            ) {
              isSelected = true;
            }

            if (isSelected) {
              this.updateValue(software.id, 'sw');
            }
            return {
              id: software.id,
              label: software.name,
              favourite: software.data.favourite,
              pallyVersion: software.data.software.pally_version,
              optimization: software.data.gripper_optimization,
              selected: isSelected,
            };
          });
      }),
      map((softwares) => {
        // We can only have one selected software, so if more than one is selected, we deselect all but the first
        if (softwares.filter((f) => f.selected).length > 1) {
          softwares.forEach((software, index) => {
            if (index !== 0) {
              software.selected = false;
            }
          });
        }
        return softwares;
      })
    );
  }

  ngAfterViewInit(): void {
    // Store name in localStorage if changed
    this.getFormControl('name')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        // Get data from local store
        const localStorage = this.localStorageService.getData(
          LocalStorageKey.ROBOT_CONFIG
        );
        // Update local store
        this.localStorageService.setData(LocalStorageKey.ROBOT_CONFIG, {
          ...localStorage,
          name: this.getFormControl('name').value,
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getFormControl(controlName: 'name' | 'hw' | 'sw'): AbstractControl<string> {
    return this.formGroup.get(controlName);
  }

  updateValue(value: string, controlName: 'name' | 'hw' | 'sw') {
    this.getFormControl(controlName).setValue(value);

    // Save to local storage based on control name
    const localStorage = this.localStorageService.getData(
      LocalStorageKey.ROBOT_CONFIG
    );
    this.localStorageService.setData(LocalStorageKey.ROBOT_CONFIG, {
      ...localStorage,
      [controlName]: value,
    });
  }

  validateRobotConfig(): string {
    if (this.getFormControl('name').invalid) {
      return 'Name is a required field';
    }
    if (this.getFormControl('hw').invalid) {
      return 'Select one Hardware configuration';
    }
    if (this.getFormControl('sw').invalid) {
      return 'Salect one Software configuration';
    } else {
      return null;
    }
  }

  cancelRobotConfig() {
    this.router.navigate([pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS]);
  }

  closeDialog() {
    this.savedConfig.emit(null);
  }

  async saveRobotConfig() {
    this.savingState = true;
    const unvalid = this.validateRobotConfig();
    if (unvalid) {
      this.notify.showMessage(unvalid);
      this.savingState = false;
      return null;
    } else {
      const source$ =
        this.configId === 'new' || this.route.snapshot.params.id === 'new'
          ? this.robotconfigApi.insertRobotConfig(
              this.getFormControl('name').value,
              await firstValueFrom(this.organizationId$),
              this.getFormControl('hw').value,
              this.getFormControl('sw').value
            )
          : this.robotconfigApi.updateRobotConfig(
              this.route.snapshot.params.id,
              this.getFormControl('name').value,
              this.getFormControl('hw').value,
              this.getFormControl('sw').value
            );

      return source$.pipe(take(1)).subscribe({
        next: (_) => {
          // Delete local store
          this.localStorageService.removeData(LocalStorageKey.ROBOT_CONFIG);

          // Notify
          this.notify.showSuccess(`Robot configuration saved!`);

          // Navigate to robot configurations
          if (!this.isDialog) {
            this.router.navigate([
              pagesPATH.INVENTORY,
              pagesPATH.ROBOT_CONFIGURATIONS,
            ]);
          }
          this.savingState = false;
        },
        error: (error) => {
          this.notify.showError(`Failed to save robot configuration: ${error}`);
        },
        complete: () => {
          if (this.isDialog) {
            // Return here id of the new robot config
            this.savedConfig.emit({
              id: this.route.snapshot.params.id,
            });
          }
          this.savingState = false;
        },
      });
    }
  }
}
