import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { ThreeModule } from 'src/app/modules/three.module';
import { SoftwareListComponent } from '../../components/inventory/software/software-list/software-list.component';
import { SoftwareRoutingModule } from './software-routing.module';
import { SoftwareWrapperComponent } from '../../components/inventory/software/software-wrapper/software-wrapper.component';
import { EditSoftwareDialogComponent } from 'src/app/components/dialogs/edit-software-dialog/edit-software-dialog.component';

@NgModule({
  declarations: [
    SoftwareWrapperComponent,
    SoftwareListComponent,
    EditSoftwareDialogComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ThreeModule,
    SharedModule,
    SoftwareRoutingModule,
    MaterialModule,
    CommonModule,
  ],
})
export class SoftwareModule {}
