import { Injector } from '@angular/core';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { ThreeHandler } from '../three-handler';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { takeUntil, take } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Project } from '../../project';
import { AssetSelectionHelper } from '../asset-selection-helper';
import { HwPartUtils } from '../../../../utils/hw-part-utils';
import { PartType } from '../../../enums/sim-config-part-type';
import { RenderTrigger } from '../../../../public/render-trigger';
import { newPalletViewAssetConfig } from '../../../config/asset/new-pallet-view-assets-config';
import { ProjectService } from '../../../../services/project.service';
import { URDFUtils } from '../../../../utils/urdf-utils';

const assets = new Map<string, string>([
  ['US 1', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['US 2', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['EU 1', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['EU 2', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['EU 6', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['AU 1', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['ASIA 1', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
  ['CHEP', HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1')],
]);

export class NEWPalletViewHandler extends ThreeHandler {
  assetSelectionHelper: AssetSelectionHelper;

  radius = 5;

  focusPoint: THREE.Vector3;

  projectList: ProjectService;

  constructor(ID: string, injector: Injector, project?: Project) {
    super(ID, injector, project);

    this.projectList = injector.get(ProjectService);

    this.focusPoint = new THREE.Vector3(this.radius, 0, 0);

    AssetStoreService.makeAssetsFromConfig(newPalletViewAssetConfig);
    AssetStoreService.loadAssetsFromConfig(newPalletViewAssetConfig);

    this.assetSelectionHelper = new AssetSelectionHelper({
      radius: this.radius,
    });
    this.scene.add(this.assetSelectionHelper);
    for (const [id, asset] of assets) {
      AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(asset)
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((model) => {
          const pallet = URDFUtils.findPartVisual(model);
          pallet.setRotationFromEuler(
            new THREE.Euler(MathUtils.degToRad(-90), 0, 0)
          );
          this.assetSelectionHelper.addModel(id, pallet);
        });
    }

    this.threeEvents.update$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dt: number) => {
        this.assetSelectionHelper.update(dt);
      });
  }

  public init(): void {
    this.inputHandler.setCameraPostion(
      new THREE.Vector3(this.radius + 1, 1, 0)
    );
    this.inputHandler.setCameraTarget(new THREE.Vector3(this.radius, -0.4, 0));
    this.inputHandler.forbidAll();
    this.inputHandler.setMaxCameraDistance(5);
    this.inputHandler.updateControls();
  }

  public afterViewInit(): void {
    this.projectList.palletType
      .pipe(takeUntil(this.destroy$))
      .subscribe((pallet) => this.assetSelectionHelper.select(pallet.name));

    RenderTrigger.enableRenderingLoop$.next(true);
  }

  addLighting(): void {
    const ambientIntensity = 0.2;
    const spotLightIntensity = 0.8;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity);
    this.scene.add(ambient);

    const spotLight = new THREE.SpotLight('#ffffff');
    spotLight.position.set(this.radius + 2, 5, -2);
    spotLight.angle = Math.PI / 16;
    spotLight.intensity = spotLightIntensity;
    spotLight.penumbra = 0.5;
    spotLight.target.position.copy(this.focusPoint);
    this.scene.add(spotLight.target);
    this.scene.add(spotLight);
  }
}
