<div class="stepper_radio_wrapper">
  <p class="radio_stepper_info_text" *ngIf="stepperText">{{ stepperText }}</p>

  <div class="stepper_radio_buttons_wrapper">
    <div *ngIf="!disableButtons" class="nav_button">
      <button [hidden]="initStep === 0" mat-icon-button (click)="prevStep()">
        <mat-icon>arrow_back_ios_new</mat-icon>Back
      </button>
    </div>
    <div
      class="radio_step"
      *ngFor="let step of radioSteps"
      (click)="selectStep(step)"
    >
      <div
        [style.cursor]="clickableButtons ? 'pointer' : 'default'"
        [class]="initStep === step ? 'radio_circle_selected' : 'radio_circle'"
      ></div>
      <div *ngIf="showStepNo && initStep === step" class="stepNo">
        {{ initStep + 1 }}
      </div>
    </div>
    <div *ngIf="!disableButtons" class="nav_button" style="text-align: right">
      <button
        [disabled]="disabledNext"
        [hidden]="initStep + 1 > radioSteps.length - 1"
        mat-icon-button
        (click)="nextStep()"
      >
        Next <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</div>
