<div class="info-container">
  <ng-container>
    <div class="content">
      <div class="left" width="50%">
        <img
          src="../../../assets/illustrative/Pallet_dimensions.png"
          width="350px"
        />
      </div>
      <div class="right">
        <h3>Create a custom pallet</h3>
        <h2>Set pallet dimensions</h2>

        <app-field [field]="getField(CardFieldIds.PalletLength)"></app-field>

        <app-field [field]="getField(CardFieldIds.PalletWidth)"></app-field>

        <app-field [field]="getField(CardFieldIds.PalletHeight)"></app-field>
      </div>
    </div>
    <div class="bottomBar">
      <button (click)="didClickClose()">Cancel</button>
      <ng-container
        *ngIf="getCustomPalletFormGroup.valid; else createButtonDisabled"
      >
        <button class="create-button" (click)="didClickCreate()">
          Create pallet
        </button>
      </ng-container>
      <ng-template #createButtonDisabled>
        <button class="create-button-disabeld">Create pallet</button>
      </ng-template>
    </div>
  </ng-container>
</div>
