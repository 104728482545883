import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { map, switchMap } from 'rxjs/operators';
import { pagesPATH } from '../../../models_new/config/pages';
import { HardwareApiService } from 'src/app/services/api/hardware-api.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.scss'],
})
export class HardwareComponent implements OnDestroy, AfterViewInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  updateTitle$: Subject<string> = new Subject<string>();
  title$: Observable<string>;
  hardwareID: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private hardwareApiService: HardwareApiService,
    private notify: NotificationService
  ) {
    const params = this.route.snapshot.params;
    if (!params.id) {
      this.errorHandler.handleError(new Error('Missing id in url!'));
      return;
    }

    this.hardwareID = params.id;
    this.title$ = this.updateTitle$.pipe(
      switchMap((id) => {
        return id === 'new'
          ? of('Create hardware configuration')
          : this.hardwareApiService.fetchHardwareByID(params.id).pipe(
              map((val) => {
                return val.name;
              })
            );
      })
    );
  }

  ngAfterViewInit() {
    this.updateTitle$.next(this.hardwareID);
  }

  hardwareIDChange({ id }): void {
    this.updateTitle$.next(id);
    // Navigate to new scene
    if (this.hardwareID !== id) {
      this.notify.showSuccess(
        `The hardware configuration was saved successfully!`
      );
      this.router.navigate(
        [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
        {
          queryParamsHandling: 'preserve',
        }
      );
    } else {
      this.notify.showSuccess(
        `The hardware configuration was saved successfully!`
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
