import { Validators } from '@angular/forms';
import { FieldType } from '../types/field-type';
import { ConveyorDirection } from '../enums/sim-config-conveyor-dir';
import { ISimApiFormConfig } from '../types/sim-api-form-config';
import { settings } from './application-settings';
import {
  getSmartExitFieldById,
  smart_exit_fields,
} from './smart-exit-field-config';
import { IQuaternion } from '../types/simulation-api-file-type';

class FieldConfigFn {
  static conveyorTypesDefaults(conType: string): {
    height: number;
    width: number;
    length: number;
  } {
    const typesMap = {
      CUSTOM: {
        height: 0.6,
        width: 0.6,
        length: 2,
      },
      MINIPAL: {
        height: 0.82,
        width: 0.45,
        length: 1.2,
      },
      KAMELEON: {
        height: 0.7,
        width: 0.55,
        length: 2.5,
      },
      STANDARD: {
        height: 0.7,
        width: 0.55,
        length: 2.5,
      },
      VENTION: {
        height: 0.83,
        width: 0.6,
        length: 1.6,
      },
    };

    if (!typesMap[conType]) {
      console.warn('No config found for', conType);
    }
    return typesMap[conType] ? typesMap[conType] : typesMap['CUSTOM'];
  }

  static changeConveyorType(
    conType: string,
    fieldId: string
  ): number | undefined {
    if (fieldId === 'con_height') {
      return FieldConfigFn.conveyorTypesDefaults(conType).height;
    }

    if (fieldId === 'con_width') {
      return FieldConfigFn.conveyorTypesDefaults(conType).width;
    }

    if (fieldId === 'con_length') {
      return FieldConfigFn.conveyorTypesDefaults(conType).length;
    }

    return undefined;
  }

  static changeConveyorHeight(value: number) {
    return value;
  }

  static conveyor_direction_to_smart_exit(
    dir: ConveyorDirection,
    fieldId: string
  ): number {
    let range = 0;
    const fieldConf = smart_exit_fields.filter((f) => f.id === fieldId)[0];

    if (dir === ConveyorDirection.FRONT) {
      range = fieldConf.Front;
    }
    if (dir === ConveyorDirection.LEFT) {
      range = fieldConf.Left;
    }
    if (dir === ConveyorDirection.RIGHT) {
      range = fieldConf.Right;
    }
    return range;
  }

  static conveyor_direction_to_boost_percantage(
    dir: ConveyorDirection
  ): number {
    let boost = 0;
    if (dir === ConveyorDirection.FRONT) {
      boost = 50;
    }
    if (dir === ConveyorDirection.LEFT) {
      boost = 90;
    }
    if (dir === ConveyorDirection.RIGHT) {
      boost = 90;
    }
    return boost;
  }

  /**
   * Hard coded from string to radian
   * @param dir as string
   */
  static conveyor_direction_changed(
    dir: ConveyorDirection,
    fieldId: string = 'x'
  ): number {
    if (
      fieldId === 'high_approach_boost_percentage' ||
      smart_exit_fields.map((m) => m.id).includes(fieldId)
    ) {
      if (fieldId === 'high_approach_boost_percentage') {
        return FieldConfigFn.conveyor_direction_to_boost_percantage(dir);
      } else {
        return FieldConfigFn.conveyor_direction_to_smart_exit(dir, fieldId);
      }
    } else {
      let rad;
      if (dir === ConveyorDirection.FRONT) {
        rad = 0;
      }
      if (dir === ConveyorDirection.LEFT) {
        rad = -1.570796;
      }
      if (dir === ConveyorDirection.RIGHT) {
        rad = 1.570796;
      }

      const toQuaternion = FieldConfigFn.euler_to_quaternion(rad);
      const value = toQuaternion[fieldId];

      return value % 1 !== 0 ? value.toFixed(6) : value;
    }
  }

  static euler_to_quaternion(
    yaw: number,
    pitch: number = 0,
    roll: number = 0
  ): IQuaternion {
    const np = Math;
    const qx =
      np.sin(roll / 2) * np.cos(pitch / 2) * np.cos(yaw / 2) -
      np.cos(roll / 2) * np.sin(pitch / 2) * np.sin(yaw / 2);
    const qy =
      np.cos(roll / 2) * np.sin(pitch / 2) * np.cos(yaw / 2) +
      np.sin(roll / 2) * np.cos(pitch / 2) * np.sin(yaw / 2);
    const qz =
      np.cos(roll / 2) * np.cos(pitch / 2) * np.sin(yaw / 2) -
      np.sin(roll / 2) * np.sin(pitch / 2) * np.cos(yaw / 2);
    const qw =
      np.cos(roll / 2) * np.cos(pitch / 2) * np.cos(yaw / 2) +
      np.sin(roll / 2) * np.sin(pitch / 2) * np.sin(yaw / 2);

    return { x: qx, y: qy, z: qz, w: qw };
  }

  /**
   * @param changeField which field to change
   */
  static bracket_length_changed(
    bracketLength: number,
    changeField: string
  ): number {
    let value;
    if (changeField === 'offsetY' || changeField === 'posY') {
      value = -bracketLength;
    }
    return value;
  }

  /**
   * @param changeField which field to change
   */
  static bracket_height_changed(
    bracketHeight: number,
    changeField: string
  ): number {
    let value;
    if (changeField === 'posZ') {
      value = bracketHeight / 2;
    } else if (changeField === 'offsetZ') {
      value = bracketHeight;
    }
    return value;
  }

  /**
   * @param changeField which field to change
   */
  static gripper_length_changed(
    gripperLength: number,
    changeField: string
  ): number {
    let value;
    if (changeField === 'posY') {
      value = gripperLength / 2;
    }
    return value;
  }

  /**
   * @param changeField which field to change
   */
  static gripper_height_changed(
    gripperHeight: number,
    changeField: string
  ): number {
    let value;
    if (changeField === 'posZ') {
      value = gripperHeight / 2;
    }
    return value;
  }
}

export const simulationFieldConfig: ISimApiFormConfig[] = [
  {
    label: 'missing config',
    id: 'mainFormGroup.default',
    validators: [],
    type: FieldType.TEXT,
    required: false,
    defaultValue: null,
    tabGroupId: null,
    suffix: '',
    hint: '',
  },
  {
    label: 'Polyscope version',
    id: 'mainFormGroup.scene.software.polyscope_version',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: false,
    options: ['5.9', '5.9.1', '5.9.2', '5.9.3', '5.9.4', '5.11'],
    defaultValue: '5.9',
    tabGroupId: 'overview.software',
  },
  {
    label: 'Pally version',
    id: 'mainFormGroup.scene.software.pally_version',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: false,
    options: ['2.6.1', '2.7.0', '2.7.1', '2.8.0'],
    defaultValue: '2.8.0',
    tabGroupId: 'overview.software',
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.robot_mounting_height.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'overview.robot',
    guiOrderIdx: 3,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
    ],
  },
  {
    label: 'X position',
    id: 'mainFormGroup.scene.robot_mounting_height.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    suffix: 'm',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot_mounting_height.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Y position',
    id: 'mainFormGroup.scene.robot_mounting_height.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    suffix: 'm',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot_mounting_height.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Z position',
    id: 'mainFormGroup.scene.robot_mounting_height.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    guiOrderIdx: 2,
    suffix: 'm',
    hint: 'Mounting height',
  },
  {
    label: 'R rotation',
    id: 'mainFormGroup.scene.robot_mounting_height.rotation.r',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    suffix: 'na',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot_mounting_height.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'P rotation',
    id: 'mainFormGroup.scene.robot_mounting_height.rotation.p',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    suffix: 'na',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot_mounting_height.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Y rotation',
    id: 'mainFormGroup.scene.robot_mounting_height.rotation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'overview.robot',
    suffix: 'na',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.frame.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot.lift_kit.type',
        value: 'NONE',
      },
      {
        id: 'mainFormGroup.scene.robot_mounting_height.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Theme',
    id: 'mainFormGroup.scene.theme',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    options: ['PALLY', 'MINIPAL', 'NONE'],
    required: false,
    defaultValue: 'PALLY',
    tabGroupId: 'overview.scene',
    suffix: '',
    hint: '',
  },
  // Conveyor
  {
    label: 'Type',
    id: 'mainFormGroup.scene.conveyors.type',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: ['CUSTOM', 'MINIPAL', 'STANDARD', 'VENTION'],
    required: true,
    defaultValue: 'MINIPAL',
    tabGroupId: 'pickup.primary',
    suffix: '',
    hint: '',
    guiOrderIdx: 0,
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.conveyors.custom_description.dimension.z',
        fnOutput: 'con_height',
      },
      {
        id: 'mainFormGroup.scene.conveyors.custom_description.dimension.x',
        fnOutput: 'con_width',
      },
      {
        id: 'mainFormGroup.scene.conveyors.custom_description.dimension.y',
        fnOutput: 'con_length',
      },
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.position.y',
        ignoreUpdateFunction: true,
      },
      // {
      //   name: 'mainFormGroup.scene.conveyors.custom_description.guide_width',
      //   fnOutput: 'con_guide_width'
      // },
      // {
      //   name: 'mainFormGroup.scene.conveyors.custom_description.velocity',
      //   fnOutput: 'con_speed'
      // },
      // {
      //   name: 'mainFormGroup.scene.conveyors.custom_description.pub_rate',
      //   fnOutput: 'con_bpm'
      // },
    ],
    updatesFieldFn: FieldConfigFn.changeConveyorType,
  },
  {
    label: 'Conveyor height',
    id: 'mainFormGroup.scene.conveyors.custom_description.dimension.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.6,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: '(Z dimension)',
    guiOrderIdx: 1,
    postUpdatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.position.z',
        fnOutput: 'conveyor_height_changes',
      },
    ],
    updatesFieldFn: FieldConfigFn.changeConveyorHeight,
  },
  {
    label: 'Conveyor width',
    id: 'mainFormGroup.scene.conveyors.custom_description.dimension.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.6,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: '(X dimension)',
    guiOrderIdx: 2,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Conveyor length',
    id: 'mainFormGroup.scene.conveyors.custom_description.dimension.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 2,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: '(Y dimension)',
    guiOrderIdx: 3,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Guide width',
    id: 'mainFormGroup.scene.conveyors.custom_description.guide_width',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0.3,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: 'Conveyor guide',
    guiOrderIdx: 5,
  },
  {
    label: 'Guide position',
    id: 'mainFormGroup.scene.conveyors.custom_description.guide_left',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: true,
    defaultValue: 'LEFT',
    options: ['LEFT', 'RIGHT'],
    tabGroupId: 'pickup.primary',
    suffix: '',
    hint: '',
    guiOrderIdx: 6,
    enabledByFields: [
      // {
      //   id: 'mainFormGroup.scene.conveyors.type',
      //   value: 'CUSTOM',
      // },
    ],
  },
  {
    label: 'Conveyor coming from',
    id: 'mainFormGroup.scene.conveyors.conveyor_direction',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: ['Front', 'Left', 'Right'],
    required: true,
    defaultValue: settings.defaultSimConfigConveyorDir,
    tabGroupId: 'pickup.primary',
    suffix: '',
    hint: '',
    postUpdatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.x',
        fnOutput: 'x',
      },
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.y',
        fnOutput: 'y',
      },
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.z',
        fnOutput: 'z',
      },
      {
        id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.w',
        fnOutput: 'w',
      },
      {
        id: 'mainFormGroup.strategy.high_approach_boost_percentage',
        fnOutput: 'high_approach_boost_percentage',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.from',
        fnOutput: 'mainFormGroup.strategy.smart_exits.left_pallet.x.from',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.to',
        fnOutput: 'mainFormGroup.strategy.smart_exits.left_pallet.x.to',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.from',
        fnOutput: 'mainFormGroup.strategy.smart_exits.left_pallet.y.from',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.to',
        fnOutput: 'mainFormGroup.strategy.smart_exits.left_pallet.y.to',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.from',
        fnOutput: 'mainFormGroup.strategy.smart_exits.right_pallet.x.from',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.to',
        fnOutput: 'mainFormGroup.strategy.smart_exits.right_pallet.x.to',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.from',
        fnOutput: 'mainFormGroup.strategy.smart_exits.right_pallet.y.from',
      },
      {
        id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.to',
        fnOutput: 'mainFormGroup.strategy.smart_exits.right_pallet.y.to',
      },
    ],
    updatesFieldFn: FieldConfigFn.conveyor_direction_changed,
    guiOrderIdx: 7,
  },
  {
    label: 'Conveyor speed',
    id: 'mainFormGroup.scene.conveyors.custom_description.velocity',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0.3,
    tabGroupId: 'pickup.primary',
    suffix: 'm/s',
    hint: '(Velocity)',
    guiOrderIdx: 8,
  },
  {
    label: 'Boxes per minute',
    id: 'mainFormGroup.scene.conveyors.custom_description.pub_rate',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 8,
    tabGroupId: 'pickup.primary',
    suffix: 'box/min',
    hint: '(Pub rate)',
    guiOrderIdx: 9,
  },
  {
    label: 'Add at end',
    id: 'mainFormGroup.scene.conveyors.custom_description.add_at_end',
    validators: [],
    type: FieldType.CHECKBOX,
    required: false,
    defaultValue: false,
    tabGroupId: 'pickup.primary',
    suffix: '',
    hint: 'The width of the fixed conveyor (if the conveyor doesn´t have guide this should be the width of the conveyor divided by 2)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.type',
        value: 'CUSTOM',
      },
    ],
    guiOrderIdx: 10,
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.conveyors.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'pickup.primary',
    guiOrderIdx: 11,
  },
  {
    label: 'Name',
    id: 'mainFormGroup.scene.conveyors.custom_description.name',
    validators: [Validators.required],
    type: FieldType.TEXT,
    required: true,
    defaultValue: 'conveyor_mock',
    tabGroupId: 'pickup.primary',
    suffix: '',
    hint: '',
    disabled: true,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.conveyors.type',
        value: 'CUSTOM',
      },
      {
        // Will never show, but will activate conveyor_direction
        id: 'mainFormGroup.scene.conveyors.conveyor_direction',
        value: !null,
      },
    ],
  },
  {
    label: 'Products',
    id: 'mainFormGroup.scene.conveyors.custom_description.sensors.products',
    validators: [],
    type: FieldType.ACCORDION,
    required: false,
    defaultValue: [],
    childrenFieldName:
      'mainFormGroup.scene.conveyors.custom_description.sensors.product',
    maxChildren: 8,
    valueOrder: 'asc',
    tabGroupId: 'pickup.primary',
    hint: 'Add sencors',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Product',
    id: 'mainFormGroup.scene.conveyors.custom_description.sensors.product',
    validators: [Validators.min(0)],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: '',
    step: 0.1,
  },
  {
    label: 'Product priority',
    id: 'mainFormGroup.scene.conveyors.custom_description.sensors.product_priority',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'n/a',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.conveyors.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Box corner pose position X',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: 'Location of conveyor',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Distance to center of robot',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.9,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: 'Location of conveyor',
    guiOrderIdx: 4,
  },
  {
    label: 'Box corner pose position Z',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.6,
    tabGroupId: 'pickup.primary',
    suffix: 'm',
    hint: '(Box corner pose position Z)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Box corner pose orientation X',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'na',
    hint: 'Rotation of conveyor',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Box corner pose orientation Y',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'na',
    hint: 'Rotation of conveyor',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Box corner pose orientation Z',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pickup.primary',
    suffix: 'na',
    hint: 'Rotation of conveyor',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Box corner pose orientation W',
    id: 'mainFormGroup.scene.conveyors.box_corner_pose.orientation.w',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 1,
    tabGroupId: 'pickup.primary',
    suffix: 'na',
    hint: 'Rotation of conveyor',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.conveyors.advanced',
        value: true,
      },
    ],
  },
  // {
  //   label: 'Robot name',
  //   name: 'mainFormGroup.scene.robot.name',
  //   validators: [],
  //   type: FieldType.TEXT,
  //   required: false,
  //   defaultValue: 'UR10',
  //   tabGroupId: 'overview.robot',
  //   suffix: '',
  //   hint: '',
  //   guiOrderIdx: 0,
  // },
  {
    label: 'Robot type',
    id: 'mainFormGroup.scene.robot.type',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: ['UR10E', 'UR10-CB', 'UR16E'],
    required: true,
    defaultValue: 'UR10E',
    tabGroupId: 'overview.robot',
    suffix: '',
    hint: 'Which kind of robot',
    guiOrderIdx: 0,
  },
  {
    label: 'Frame type',
    id: 'mainFormGroup.scene.robot.frame.type',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: ['EASY_PALLETIZER', 'VENTION', 'MINIPAL', 'KAMELEON', 'NONE'],
    required: true,
    defaultValue: 'EASY_PALLETIZER',
    tabGroupId: 'overview.frame',
    suffix: '',
    hint: 'Robot mounting frame used (if any)',
  },
  {
    label: 'Lifting column type',
    id: 'mainFormGroup.scene.robot.lift_kit.type',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: ['EWELLIX', 'NONE'],
    required: true,
    defaultValue: 'EWELLIX',
    tabGroupId: 'overview.lift_kit',
    suffix: '',
    hint: 'Lift kit used (if any)',
    updatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot_mounting_height.position.z',
      },
    ],
  },
  {
    label: 'Lift kit max stroke',
    id: 'mainFormGroup.scene.robot.lift_kit.max_stroke',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '0.9',
    tabGroupId: 'overview.lift_kit',
    suffix: 'm',
    hint: 'Lift kit max stroke',
  },
  {
    label: 'Gripper type',
    id: 'mainFormGroup.scene.robot.gripper.type',
    validators: [Validators.required],
    type: FieldType.SELECT_SINGLE,
    options: [
      'UNIGRIPPER_CO_LIGHT',
      'SCHMALZ_FXCB_FOAM',
      'SCHMALZ_FXCB_SUCTION',
      'PIAB-400',
      'PIAB',
      'PIAB_TRIPLE',
      'CUSTOM',
    ],
    required: true,
    defaultValue: 'SCHMALZ_FXCB_FOAM',
    tabGroupId: 'gripper.type',
    suffix: '',
    hint: '',
    updatesFieldsOnChange: [],
    postUpdatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.width',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.length',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.height',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.x',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.y',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.z',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.r',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.p',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.y',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
    ],
  },
  {
    label: 'Width',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.width',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'If custom (width of gripper)',
    guiOrderIdx: 1,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Length',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.length',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'if custom (length of gripper)',
    guiOrderIdx: 2,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Height',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.height',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: '',
    guiOrderIdx: 3,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.type',
        value: 'CUSTOM',
      },
    ],
    updatesFieldsOnChange: [],
    postUpdatesFieldsOnChange: [],
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'gripper.dimensions',
    guiOrderIdx: 5,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Custom collision box position X',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    disabled: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'na',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom collision box position Y',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    disabled: true,
    hint: 'na',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom collision box position Z',
    id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    disabled: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'na',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom offset pose position X',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'Gripper offset mount (left or right of robot arm)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom offset pose position Y',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'How far ahead / behind of robot arm',
    guiOrderIdx: 4,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.type',
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Custom offset pose position Z',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'm',
    hint: 'Up / Down ',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom offset pose rotation R',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.r',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'na',
    hint: 'Rotated gripper',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom offset pose rotation P',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.p',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'na',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Custom offset pose rotation Y',
    id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.rotation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.dimensions',
    suffix: 'na',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_collision_box.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Add offset bracket',
    id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'gripper.offset',
    guiOrderIdx: 1,
    updatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.x',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.y',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.z',
        skipIf: [
          {
            id: 'mainFormGroup.scene.robot.gripper.type',
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value != 'CUSTOM',
          },
        ],
      },
    ],
  },
  {
    label: 'Bracket width',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.width',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.05,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Dimension of offset bracket box ',
    guiOrderIdx: 2,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
    ],
  },
  {
    label: 'Bracket length',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.length',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.2,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Dimension of offset bracket box',
    guiOrderIdx: 3,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
    ],
    updatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.offset.y',
        fnOutput: 'offsetY',
      },
    ],
    postUpdatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.x',
        /* eslint-disable-next-line , , , , eqeqeq, , , , , , , , ,  */
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.y',
        /* eslint-disable-next-line , , , , eqeqeq, , , , , , , , ,  */
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.z',
        /* eslint-disable-next-line eqeqeq */
      },
    ],
    updatesFieldFn: FieldConfigFn.bracket_length_changed,
  },
  {
    label: 'Bracket height',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.height',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.01,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Dimension of offset bracket box',
    guiOrderIdx: 4,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
    ],
    updatesFieldsOnChange: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.offset.z',
        fnOutput: 'offsetZ',
      },
      {
        id: 'mainFormGroup.scene.robot.gripper.custom_offset_pose.position.z',
      },
    ],
    updatesFieldFn: FieldConfigFn.bracket_height_changed,
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'gripper.offset',
    guiOrderIdx: 5,
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
    ],
  },
  {
    label: 'Offset X',
    id: 'mainFormGroup.scene.robot.offset_bracket.offset.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
    ],
    hint: 'If gripper is offset mounted (should correspond to custom_offset_pose)',
  },
  {
    label: 'Offset Y',
    id: 'mainFormGroup.scene.robot.offset_bracket.offset.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: -0.2,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
    ],
    hint: 'If gripper is offset mounted (should correspond to custom_offset_pose)',
  },
  {
    label: 'Offset Z',
    id: 'mainFormGroup.scene.robot.offset_bracket.offset.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.01,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.add_offset',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Collision object position X',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Placement of offset bracket relative to tip of robot arm (wrist_3)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: 'I will hide this field for ever',
      },
    ],
  },
  {
    label: 'Collision object position Y',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Placement of offset bracket relative to tip of robot arm (wrist_3)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: 'I will hide this field for ever',
      },
    ],
  },
  {
    label: 'Collision object position Z',
    id: 'mainFormGroup.scene.robot.offset_bracket.collision_object.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'gripper.offset',
    suffix: 'm',
    hint: 'Placement of offset bracket relative to tip of robot arm (wrist_3)',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: true,
      },
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: 'I will hide this field for ever',
      },
    ],
  },
  {
    label: 'Pallet name',
    id: 'mainFormGroup.scene.place_targets.0.name',
    validators: [],
    type: FieldType.TEXT,
    required: false,
    defaultValue: 'p2',
    tabGroupId: 'pallet.left_pallet',
    suffix: '',
    hint: '',
    disabled: true,
    guiOrderIdx: 1,
  },
  {
    label: 'Pallet name',
    id: 'mainFormGroup.scene.place_targets.1.name',
    validators: [],
    type: FieldType.TEXT,
    required: false,
    defaultValue: 'p1',
    tabGroupId: 'pallet.right_pallet',
    suffix: '',
    hint: '',
    disabled: true,
    guiOrderIdx: 1,
  },
  {
    label: 'Lip',
    id: 'mainFormGroup.scene.place_targets.0.lip',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'mm',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Lip',
    id: 'mainFormGroup.scene.place_targets.1.lip',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'mm',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Distance from robot center to pallet edge',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: -0.33,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'm',
    guiOrderIdx: 2,
    hint: 'Must fit robot base',
  },
  {
    label: 'Distance from robot center to pallet edge',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0.33,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'm',
    guiOrderIdx: 2,
    hint: 'Must fit robot base',
  },
  {
    label: 'Pallet offset',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: -0.6,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'm',
    guiOrderIdx: 3,
    hint: 'Usually "pallet length / 2',
  },
  {
    label: 'Pallet offset',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: -0.6,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'm',
    guiOrderIdx: 3,
    hint: 'Usually "pallet length / 2',
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.place_targets.1.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'pallet.right_pallet',
    guiOrderIdx: 4,
  },
  {
    label: 'Advanced',
    id: 'mainFormGroup.scene.place_targets.0.advanced',
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    tabGroupId: 'pallet.left_pallet',
    guiOrderIdx: 4,
  },
  {
    label: 'Corner base pose position Z',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'm',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
    hint: 'Position of pallets relative to robot (given that robot is not offset mounted in x/y direction)',
  },
  {
    label: 'Corner base pose position Z',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'm',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
    hint: 'Position of pallets relative to robot (given that robot is not offset mounted in x/y direction)',
  },
  {
    label: 'Corner base pose orientation X',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.orientation.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation X',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.orientation.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation Y',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.orientation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation Y',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.orientation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation Z',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.orientation.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation Z',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.orientation.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation W',
    id: 'mainFormGroup.scene.place_targets.0.corner_base_pose.orientation.w',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 1,
    tabGroupId: 'pallet.left_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.0.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Corner base pose orientation W',
    id: 'mainFormGroup.scene.place_targets.1.corner_base_pose.orientation.w',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 1,
    tabGroupId: 'pallet.right_pallet',
    suffix: 'na',
    hint: 'Rotation of pallets',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.place_targets.1.advanced',
        value: true,
      },
    ],
  },
  {
    label: 'Gripper optimization',
    id: 'mainFormGroup.strategy.gripper_optimization',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    options: ['NONE', '2_WAY', '4_WAY'],
    required: false,
    defaultValue: '4_WAY',
    tabGroupId: 'advanced.gripper',
    suffix: '',
    hint: '',
  },
  {
    label: 'Above pick position',
    id: 'mainFormGroup.strategy.above_pick_position_mm',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 100,
    tabGroupId: 'advanced.path_planning',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Left pallet X from',
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.from',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.left_pallet.x.from'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Left pallet X to',
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.to',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.left_pallet.x.to'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Left pallet Y from',
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.from',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.left_pallet.y.from'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Left pallet Y to',
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.to',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.left_pallet.y.to'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Right pallet X from',
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.from',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.right_pallet.x.from'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Right pallet X to',
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.to',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.right_pallet.x.to'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Right pallet Y from',
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.from',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.right_pallet.y.from'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Right pallet Y to',
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.to',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: getSmartExitFieldById(
      'mainFormGroup.strategy.smart_exits.right_pallet.y.to'
    )[settings.defaultSimConfigConveyorDir],
    tabGroupId: 'advanced.smart_exits',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Multi grip',
    id: 'mainFormGroup.strategy.max_grip',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 2,
    tabGroupId: 'advanced.gripper',
    suffix: '',
    hint: '',
  },
  {
    label: 'Max speed',
    id: 'mainFormGroup.strategy.max_speed',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 1300,
    tabGroupId: 'advanced.robot',
    suffix: 'mm/s',
    hint: '',
  },
  {
    label: 'Max acceleration',
    id: 'mainFormGroup.strategy.max_acceleration',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 1200,
    tabGroupId: 'advanced.robot',
    suffix: 'mm/s2',
    hint: '',
  },
  {
    label: 'Smart acceleration',
    id: 'mainFormGroup.strategy.smart_acceleration',
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: false,
    defaultValue: 'Medium',
    tabGroupId: 'advanced.robot',
    suffix: '',
    hint: '',
    options: ['Slowest', 'Slower', 'Medium', 'Faster', 'Fastest'],
  },
  {
    label: 'Auto start simulation',
    id: 'mainFormGroup.strategy.auto_sim',
    validators: [],
    type: FieldType.CHECKBOX,
    required: false,
    defaultValue: true,
    tabGroupId: 'advanced.robot',
    suffix: '',
    hint: '',
    enabledByFields: [
      {
        id: 'mainFormGroup.scene.robot.offset_bracket.advanced',
        value: 'I will hide this field for ever',
      },
    ],
  },
  {
    label: 'High approach boost percentage',
    id: 'mainFormGroup.strategy.high_approach_boost_percentage',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 50,
    tabGroupId: 'advanced.path_planning',
    suffix: '%s',
    hint: '',
  },
  {
    label: 'Approach distance',
    id: 'mainFormGroup.strategy.approach_distance',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 30,
    tabGroupId: 'advanced.path_planning',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Box free height',
    id: 'mainFormGroup.strategy.box_free_height',
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 100,
    tabGroupId: 'advanced.path_planning',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Waiting position X',
    id: 'mainFormGroup.strategy.waiting_position.position.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Waiting position Y',
    id: 'mainFormGroup.strategy.waiting_position.position.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Waiting position Z',
    id: 'mainFormGroup.strategy.waiting_position.position.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'mm',
    hint: '',
  },
  {
    label: 'Waiting orientation X',
    id: 'mainFormGroup.strategy.waiting_position.orientation.x',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'na',
    hint: '',
  },
  {
    label: 'Waiting position Y',
    id: 'mainFormGroup.strategy.waiting_position.orientation.y',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'na',
    hint: '',
  },
  {
    label: 'Waiting position Z',
    id: 'mainFormGroup.strategy.waiting_position.orientation.z',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'na',
    hint: '',
  },
  {
    label: 'Waiting position W',
    id: 'mainFormGroup.strategy.waiting_position.orientation.w',
    validators: [Validators.required],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    tabGroupId: 'advanced.waiting_position',
    suffix: 'na',
    hint: '',
  },
  {
    label: 'Lifting column dynamic positioning',
    id: 'mainFormGroup.strategy.lifting_column.dynamic_positioning',
    validators: [],
    type: FieldType.CHECKBOX,
    required: false,
    defaultValue: 1,
    tabGroupId: 'overview.lift_kit',
    suffix: '',
    hint: '',
  },
];
