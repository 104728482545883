import { IApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { ObjectUtils } from 'src/app/utils/object';
import { ApiOrganization } from '../../../classes/api-models/ApiOrganization';
import { defaultStrategy } from '../default-strategy';
import { defaultApiOrganization } from './default-api-organization';

export const defaultApiStrategy: IApiStrategy = {
  id: '001',
  name: 'Default Software Configuration',
  data: ObjectUtils.cloneObject(defaultStrategy),
  description: 'Default dummy data',
  organization_id: new ApiOrganization(defaultApiOrganization).id,
  simulations: [],
};
