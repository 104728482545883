<ng-container *ngIf="project$ | async as project">
  <ng-container *appVar="appLayout.lowHeight$ | async as lowHeigth">
    <!-- Loading state -->
    <app-loading *ngIf="project.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="project.error as error" [error]="error"></app-error>

    <ng-container *ngIf="project.didSucceed">
      <div class="create-sections-wrapper mat-mdc-card-outlined">
        <section
          class="pattern-create-left-section"
          [ngClass]="{ 'low-height': lowHeigth }"
          [ngStyle]="
            lowHeigth ? { 'padding-top': '1em', 'padding-bottom': '1em' } : {}
          "
        >
          <!-- Fields-->
          <ng-container>
            <form [formGroup]="formGroup" style="position: relative">
              <ng-container *ngIf="!isFastTrack; else fastTrackForm">
                <mat-list>
                  <mat-list-item>
                    <section class="two-split">
                      <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="name"
                          [errorStateMatcher]="eagerMatcher"
                        />
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="description"
                        />
                      </mat-form-field>
                    </section>

                    <section class="two-split">
                      <mat-form-field appearance="outline">
                        <mat-label>Stacking method</mat-label>
                        <mat-select formControlName="stacking_method">
                          <mat-option value="duplicate"
                            >Column stack</mat-option
                          >
                          <mat-option value="rotate">Rotate stack</mat-option>
                          <mat-option value="mirrorVert"
                            >Mirror vertical</mat-option
                          >
                          <mat-option value="mirrorHoriz"
                            >Mirror horizontal</mat-option
                          >
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Pallet</mat-label>
                        <mat-select formControlName="pallet">
                          <mat-option
                            class="special-option"
                            (click)="handleCustomPalletClick()"
                            >+ Add custom pallet</mat-option
                          >
                          <mat-option
                            *ngFor="let p of ListOfAllPallets"
                            [value]="p"
                          >
                            {{ p.name }} -
                            {{
                              PPBUnitUtils.getConvertedValue(
                                p.length + ' mm',
                                unit
                              )
                            }}
                            x
                            {{
                              PPBUnitUtils.getConvertedValue(
                                p.width + ' mm',
                                unit
                              )
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </section>

                    <section class="two-split">
                      <mat-form-field appearance="outline">
                        <mat-label>Number of layers</mat-label>
                        <mat-select formControlName="nr_layers">
                          <ng-container *ngFor="let l of ZeroToThirty">
                            <mat-option *ngIf="l !== 0" [value]="l">
                              {{ l }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Label orientation</mat-label>
                        <mat-select formControlName="label_orientation">
                          <mat-option
                            *ngFor="let lo of LabelOrientations"
                            [value]="lo"
                          >
                            {{ lo }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </section>
                  </mat-list-item>

                  <mat-divider style="margin-bottom: 1em"></mat-divider>

                  <mat-list-item>
                    <section class="two-split">
                      <mat-form-field appearance="outline">
                        <mat-label>Overhang sides</mat-label>
                        <mat-select formControlName="overhang_sides">
                          <mat-option
                            *ngFor="let os of ZeroToHoundred"
                            [value]="os"
                          >
                            {{
                              PPBUnitUtils.getConvertedValue(
                                os + ' mm',
                                unit,
                                true
                              )
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Overhang ends</mat-label>
                        <mat-select formControlName="overhang_ends">
                          <mat-option
                            *ngFor="let oe of ZeroToHoundred"
                            [value]="oe"
                          >
                            {{
                              PPBUnitUtils.getConvertedValue(
                                oe + ' mm',
                                unit,
                                true
                              )
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Product padding</mat-label>
                        <mat-select formControlName="padding">
                          <mat-option
                            *ngFor="let p of ZeroToHoundred"
                            [value]="p"
                          >
                            {{
                              PPBUnitUtils.getConvertedValue(p + ' mm', unit)
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </section>
                  </mat-list-item>

                  <mat-divider style="margin-bottom: 1em"></mat-divider>

                  <mat-list-item>
                    <section class="two-split">
                      <mat-form-field appearance="outline">
                        <mat-label>Speed</mat-label>
                        <mat-hint>Cases per minute</mat-hint>
                        <mat-select formControlName="cpm">
                          <ng-container *ngFor="let cpm of ZeroToThirty">
                            <mat-option *ngIf="cpm !== 0" [value]="cpm">
                              {{ cpm }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Max grip</mat-label>
                        <mat-select formControlName="max_grip">
                          <mat-option value="Auto">Auto</mat-option>
                          <mat-option
                            *ngFor="let max of MaxGrips"
                            [value]="max"
                          >
                            {{ max }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </section>
                  </mat-list-item>
                </mat-list>
              </ng-container>

              <ng-template #fastTrackForm>
                <mat-form-field appearance="outline" class="width-full">
                  <mat-label>Stacking method</mat-label>
                  <mat-select formControlName="stacking_method">
                    <mat-option value="duplicate">Column stack</mat-option>
                    <mat-option value="rotate">Rotate stack</mat-option>
                    <mat-option value="mirrorVert">Mirror vertical</mat-option>
                    <mat-option value="mirrorHoriz"
                      >Mirror horizontal</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="width-full">
                  <mat-label>Pallet</mat-label>
                  <mat-select formControlName="pallet">
                    <mat-option
                      class="special-option"
                      (click)="handleCustomPalletClick()"
                      >+ Add custom pallet</mat-option
                    >
                    <mat-option *ngFor="let p of ListOfAllPallets" [value]="p">
                      {{ p.name }} -
                      {{
                        PPBUnitUtils.getConvertedValue(p.length + ' mm', unit)
                      }}
                      x
                      {{
                        PPBUnitUtils.getConvertedValue(p.width + ' mm', unit)
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <section class="two-split">
                  <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Number of layers</mat-label>
                    <mat-select formControlName="nr_layers">
                      <ng-container *ngFor="let l of ZeroToThirty">
                        <mat-option *ngIf="l !== 0" [value]="l">
                          {{ l }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Speed</mat-label>
                    <mat-hint>Cases per minute</mat-hint>
                    <mat-select formControlName="cpm">
                      <mat-option
                        *ngFor="let cpm of ZeroToThirty"
                        [value]="cpm"
                      >
                        {{ cpm }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </section>
                <section class="width-full">
                  <mat-form-field appearance="outline" class="width-full">
                    <mat-label>Label outwards?</mat-label>
                    <mat-select formControlName="label_orientation">
                      <mat-option
                        *ngFor="let lo of LabelOrientationsFastTrack"
                        [value]="lo.id"
                      >
                        {{ lo.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </section>
              </ng-template>
            </form>
          </ng-container>

          <!--Actions-->
        </section>
        <section
          class="pattern-create-right-section"
          [ngClass]="{ 'fast-track': isFastTrack }"
        >
          <!--Pallet info-->
          <div class="info-wrapper">
            <app-pattern-info
              *ngIf="PatternInfoInput"
              [PatternInfoInput]="PatternInfoInput"
            ></app-pattern-info>
          </div>
          <div class="view3d-wrapper">
            <app-lite-pattern-view
              style="width: 100%; height: 100%"
              [project]="project.value"
              [sticker]="sticker$ | async"
            ></app-lite-pattern-view>

            <ng-container>
              <button
                mat-icon-button
                class="pattern_select_button"
                (click)="prevPatternSelect()"
                matTooltip="Previous base pattern"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <button
                mat-icon-button
                class="pattern_select_button"
                (click)="nextPatternSelect()"
                matTooltip="Next base pattern"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
              <div class="select-stepper-wrapper">
                <app-stepper-radio
                  style="margin: 0px"
                  [disableButtons]="true"
                  [radioSteps]="patternGenerator.basePatternIndexes$ | async"
                  [initStep]="patternGenerator.selectedBasePatternIndex"
                  (selectedStep)="onPatternSelect($event)"
                  [showStepNo]="false"
                >
                </app-stepper-radio>
              </div>
            </ng-container>
          </div>
        </section>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
