import { IThreeBoxGuiSettings } from '../types/three-box-settings';
import { IPalletView } from '../types/pallet-view';
import { BehaviorSubject } from 'rxjs';
import { IProjectUpdate } from '../types/project-update';
import { UpdateAction } from '../enums/update-action';

export class PalletView implements IPalletView {
  palletViewSettings: IThreeBoxGuiSettings;
  palletViewLayersNo: number;

  update$ = new BehaviorSubject<IProjectUpdate>(null);

  constructor(viewSettings?: IThreeBoxGuiSettings, viewLayersNo?: number) {
    this.palletViewSettings = viewSettings;
    this.palletViewLayersNo = viewLayersNo;
  }

  saveViewSettings() {
    this.update$.next({ updateAction: UpdateAction.SET_VIEW_SETTINGS });
  }

  /**
   * @param multiplePallets: boolean
   */
  setMultiplePalletsView(multiplePallets: boolean): void {
    this.palletViewSettings.showLeftPallet = multiplePallets;
  }

  getViewSettingsAsArray() {
    return Object.entries(this.palletViewSettings).map((e) => ({
      name: e[0],
      value: e[1],
    }));
  }

  getLayersView(): number {
    return this.palletViewLayersNo;
  }

  /**
   * @param noOfLayers: number
   */
  setLayersView(noOfLayers: number): void {
    this.palletViewLayersNo = noOfLayers;
    this.update$.next({ updateAction: UpdateAction.SET_VIEW_NO_OF_LAYERS });
  }
}
