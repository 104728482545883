import { Component, HostListener } from '@angular/core';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { OpenSimService } from '../open-sim.service';

@Component({
  selector: 'app-fast-track-navbar',
  templateUrl: './fast-track-navbar.component.html',
  styleUrls: ['./fast-track-navbar.component.scss'],
})
export class FastTrackNavbarComponent {
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(_: any) {
    this.addScrollableNavbar();
  }

  constructor(
    public openSimService: OpenSimService,
    public appLayout: AppLayoutService
  ) {}

  addScrollableNavbar() {
    if (this.openSimService.embed) {
      return;
    } else {
      if (window.scrollY > 2) {
        document
          .getElementById('landing-outlet-nav-top')
          ?.classList.add('scrolled');
      } else {
        document
          .getElementById('landing-outlet-nav-top')
          ?.classList.remove('scrolled');
      }
    }
  }
}
