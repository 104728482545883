export interface ISmartExitFieldConf {
  id: string;
  Front: number;
  Left: number;
  Right: number;
}

export const getSmartExitFieldById = (id: string): ISmartExitFieldConf => {
  return smart_exit_fields.filter((f) => f.id === id)[0];
};

export const smart_exit_fields: ISmartExitFieldConf[] = [
  {
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.from',
    Front: 100,
    Left: 100,
    Right: 100,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.x.to',
    Front: 700,
    Left: 350,
    Right: 350,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.from',
    Front: 50,
    Left: -400,
    Right: 100,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.left_pallet.y.to',
    Front: 250,
    Left: 0,
    Right: 800,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.from',
    Front: 100,
    Left: 100,
    Right: 100,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.x.to',
    Front: 700,
    Left: 350,
    Right: 350,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.from',
    Front: 50,
    Left: 100,
    Right: -400,
  },
  {
    id: 'mainFormGroup.strategy.smart_exits.right_pallet.y.to',
    Front: 250,
    Left: 800,
    Right: 0,
  },
];
