import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import {
  Observable,
  map,
  Subject,
  take,
  of,
  mergeMap,
  combineLatest,
  switchMap,
} from 'rxjs';
import { StateService } from '../../auth/state.service';
import {
  IApiScene,
  ApiScene,
} from '../../models_new/classes/api-models/ApiScene';
import { ApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import { ObjectUtils } from '../../utils/object';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { ClientApiService } from './client-api.service';
import {
  IMutationResult,
  ISingleMutationResult,
} from 'src/app/models_new/types/mutation-result';
import { ErrorHandlerService } from '../error-handler.service';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { v4 as uuidv4 } from 'uuid';
import { FileUtils } from '../../utils/file-utils';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import {
  HwSwTypeMap,
  IHwSwType,
} from '../../models_new/types/robot-config/hw-sw-type';

interface IInsertHWTypeResponse {
  readonly id: string;
  readonly hw_type_id: string;
  readonly organization_id: string;
}

export interface ISceneResponse {
  readonly id: string;
  readonly name: string;
  readonly image: { id: string };
}

interface IHwSwResponse {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class HardwareApiService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private clientApi: ClientApiService,
    private stateService: StateService,
    private errorHandler: ErrorHandlerService,
    private assetApi: AssetApiService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getHardwareByID$(id: string): Observable<ApiScene> {
    return this.stateService.getCustomerOrSalesOrganization().pipe(
      switchMap((org: ApiOrganization) =>
        combineLatest([
          this.fetchHardwaresQuery(org.id),
          this.fetchSalesOrgsHardwares(org.id),
        ])
      ),
      take(1),
      map(([orgs_hw, sales_orgs_hw]) => orgs_hw.concat(sales_orgs_hw)),
      map((hardwares) => {
        return hardwares.find((hw) => hw.id === id);
      })
    );
  }

  public getHwTypes$(isBackoffice?: boolean): Observable<IHwSwType[]> {
    return this.fetchHwTypes(isBackoffice);
  }

  public getHwSwMap$() {
    return this.getHwTypes$().pipe(map((types) => new HwSwTypeMap(types)));
  }

  public getValidSceneImageUrl(
    robotConfigs: ApiScene[]
  ): Observable<ApiScene[]> {
    const valid = robotConfigs.filter(
      (scene) =>
        scene.image?.url &&
        FileUtils.validAzureBlobStoreUrl(scene.image.url).valid
    );
    const invalid = robotConfigs.filter(
      (scene) =>
        !scene.image?.url ||
        !FileUtils.validAzureBlobStoreUrl(scene.image?.url).valid
    );

    const sources$: Observable<ApiScene>[] = invalid.map((scene: ApiScene) => {
      if (scene.image) {
        return this.assetApi.getAsset(scene.image?.id).pipe(
          take(1),
          map((result) => {
            scene.image.url = result
              ? result
              : '../../../../assets/illustrative/simulation.png';
            return scene;
          })
        );
      } else {
        scene.image = {
          id: '00000000-0000-0000-0000-000000000000',
          url: '../../../../assets/illustrative/simulation.png',
        };
        return of(scene);
      }
    });

    if (sources$.length < 1) {
      return of([...valid]);
    } else if (valid.length < 1) {
      return combineLatest([...sources$]);
    } else {
      return combineLatest([...sources$, ...valid.map((m) => of(m))]);
    }
  }

  /* ------------------------ Database query functions ------------------------ */

  private fetchHardwaresQuery(organizationId: string): Observable<ApiScene[]> {
    const qf = gql`
      query fetchHardwaresQuery {
        scene(where: {organization_id: {_eq: "${organizationId}"}}) {
          description
          id
          name
          data
          description
          owner_organization: organization {
            name
          }
          created_at
          updated_at
          simulations {
            id
            name
          }
          image {
            id
            url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .subscribe<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.scene;
          }
        }),
        switchMap((hwScenes: ApiScene[]) => {
          return this.getValidSceneImageUrl(hwScenes);
        })
      );
  }

  public fetchSimWizSalesOrgsHardwares(orgId: string): Observable<ApiScene[]> {
    const q = gql`
      subscription {
        scene(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${orgId}"}}}}) {
          id
          name
          data
          updated_at
          image {
            id
            url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data?.data?.scene;
        })
      );
  }

  private fetchSalesOrgsHardwares(
    customer_org_id: string
  ): Observable<ApiScene[]> {
    const q = gql`
      subscription {
        scene(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${customer_org_id}"}}}}) {
          description
          id
          name
          data
          description
          owner_organization: organization {
            name
          }
          created_at
          updated_at
          simulations {
            id
            name
          }
          image {
            id
            url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          const list: ApiScene[] = [];
          for (const s of data.data.scene) {
            const scene: ApiScene = new ApiScene(ObjectUtils.cloneObject(s));
            scene.simulations = scene.simulations.map(
              (sim) => new ApiSimulation(sim)
            );
            list.push(scene);
          }
          return list;
        }),
        switchMap((hwScenes: ApiScene[]) => {
          return this.getValidSceneImageUrl(hwScenes);
        })
      );
  }

  public fetchSimWizHardwares(organizationId: string): Observable<ApiScene[]> {
    const q = gql`
      subscription {
        scene(where: {organization_id: {_eq: "${organizationId}"}}, order_by: {created_at: desc}) {
          id
          name
          data
          updated_at
          image {
            id
            url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data?.data?.scene;
        })
      );
  }

  public fetchHardwares(organizationId: string = null): Observable<ApiScene[]> {
    const q = gql`
      subscription fetchHardwares {
        scene {
          description
          id
          name
          data
          description
          owner_organization: organization {
            name
          }
          created_at
          updated_at
          simulations {
            id
            name
          }
          image {
            id
            url
          }
        }
      }
    `;

    const qf = gql`
      subscription fetchHardwares {
        scene(where: {organization_id: {_eq: "${organizationId}"}}) {
          description
          id
          name
          data
          description
          owner_organization: organization {
            name
          }
          created_at
          updated_at
          simulations {
            id
            name
          }
          image {
            id
            url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId ? qf : q,
      })
      .pipe(
        map((data) => {
          const list: ApiScene[] = [];
          for (const s of data.data.scene) {
            const scene: ApiScene = new ApiScene(ObjectUtils.cloneObject(s));
            scene.simulations = scene.simulations.map(
              (sim) => new ApiSimulation(sim)
            );
            list.push(scene);
          }
          return list;
        }),
        switchMap((hwScenes: ApiScene[]) => {
          return this.getValidSceneImageUrl(hwScenes);
        })
      );
  }

  public fetchHardwareByID(id: string): Observable<ApiScene> {
    const q = gql`query getScene {
      scene_by_pk(id: "${id}") {
        created_at
        data
        description
        id
        name
        organization_id
        owner_id
        simulations {
          id
          name
          owner_id
          pattern_id
          scene_id
          strategy_id
          created_at
          updated_at
        }
      }
    }`;
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          const scene: IApiScene = ObjectUtils.cloneObject(
            data.data.scene_by_pk
          );
          scene.simulations = scene.simulations.map(
            (m) => new ApiSimulation(m)
          );
          return scene as IApiScene;
        })
      );
  }

  public insertScene(
    name: string,
    description: string,
    data: any,
    organization_id: string
  ): Observable<ISceneResponse> {
    return of(organization_id).pipe(
      mergeMap(() => {
        const id = (data.id = uuidv4());
        const m = gql`
          mutation insertScene(
            $id: uuid!
            $name: String!
            $description: String!
            $organization_id: uuid
            $data: json!
          ) {
            insert_scene_one(
              object: {
                id: $id
                name: $name
                description: $description
                organization_id: $organization_id
                data: $data
              }
            ) {
              id
              name
              image {
                id
                url
              }
            }
          }
        `;

        const variables = {
          id: id,
          name: name,
          description: description,
          data: data,
          organization_id: organization_id,
        };

        return this.clientApi
          .useClient('org_edit')
          .mutate<any>({
            mutation: m,
            variables: variables,
          })
          .pipe(
            map((v) => {
              if (v.errors) {
                this.errorHandler.handleError(v.errors[0]);
              } else {
                return v.data.insert_scene_one;
              }
            })
          );
      })
    );
  }

  public updateScene(
    id: string,
    name: string,
    description: string,
    data: any,
    image_id?: string
  ): Observable<ISceneResponse> {
    const m = image_id
      ? gql`
    mutation UpdateScene($name: String!, $description: String, $data: json!, $image_asset_id: uuid) {
      update_scene_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, description: $description, data: $data, image_asset_id: $image_asset_id}) {
        id
        name
        image {
          id
        }
      }
    }
    `
      : gql`
    mutation UpdateScene($name: String!, $description: String, $data: json!) {
      update_scene_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, description: $description, data: $data}) {
        id
        name
        image {
          id
        }
      }
    }
    `;

    const variables = image_id
      ? {
          name: name,
          description: description,
          data: data,
          image_asset_id: image_id ? image_id : null,
        }
      : {
          name: name,
          description: description,
          data: data,
        };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((value) => value.data.update_scene_by_pk));
  }

  public deleteSceneById(
    id: string
  ): Observable<{ id: string; image: { id: string } }> {
    const m = gql`
      mutation deleteScene($id: uuid!) {
        delete_scene_by_pk(id: $id) {
          name
          image {
            id
          }
        }
      }
    `;

    const variables = {
      id: id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.delete_scene_by_pk));
  }

  /**
   * Fetches any hardware types that's public or accisible through organization
   * @param {boolean} isBackoffice
   * @returns
   */
  public fetchHwTypes(isBackoffice?: boolean): Observable<IHwSwType[]> {
    const q = gql`
      subscription {
        hw_types {
          id
          label
          name
          metadata
          type
          public
          created_at
          updated_at
          organization_id
          hw_sw_type {
            name
          }
          organization_hw_types {
            id
            organization_id
            organization {
              name
            }
          }
        }
      }
    `;
    return this.clientApi
      .useClient(isBackoffice ? 'rf_backoffice' : 'org_view', 'ws')
      .subscribe<{ hw_types: IHwSwType[] }>({
        query: q,
      })
      .pipe(map((data) => data.data.hw_types));
  }

  public insertGlobalHardwareTypeToOrganization(
    hwTypeId: string,
    organization: string,
    isBackoffice?: boolean
  ): Observable<IInsertHWTypeResponse> {
    const m = gql`
      mutation insertGlobalHWToOrg(
        $organization_id: uuid!
        $hw_type_id: uuid!
      ) {
        insert_organization_hw_types_one(
          object: { organization_id: $organization_id, hw_type_id: $hw_type_id }
        ) {
          id
          hw_type_id
          organization_id
        }
      }
    `;

    const variables = {
      hw_type_id: hwTypeId,
      organization_id: organization,
    };

    return this.clientApi
      .useClient(isBackoffice ? 'rf_backoffice_edit' : 'org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data?.insert_organization_hw_types_one));
  }

  public removeOrganizationHwTypeByOrgIdAndHwTypeId(
    organization_id: string,
    hardwareTypeId: string,
    isBackoffice?: boolean
  ): Observable<IMutationResult> {
    const q = gql`
      mutation rmHwType($organization_id: uuid!, $hardwareType_id: uuid) {
        delete_organization_hw_types(
          where: {
            _and: {
              organization_id: { _eq: $organization_id }
              hw_type_id: { _eq: $hardwareType_id }
            }
          }
        ) {
          affected_rows
        }
      }
    `;

    const variables = {
      organization_id: organization_id,
      hardwareType_id: hardwareTypeId,
    };

    return this.clientApi
      .useClient(isBackoffice ? 'rf_backoffice_edit' : 'org_edit')
      .mutate<any>({
        mutation: q,
        variables: variables,
      })
      .pipe(
        map((data) =>
          data?.errors
            ? this.errorHandler.handleError(data.errors[0])
            : data.data?.delete_organization_hw_types
        )
      );
  }

  public insertHardWareType(
    name: string,
    label: string,
    type: string,
    organization: string,
    metadata: any,
    isPublic: boolean
  ): Observable<IHwSwType> {
    return this.getHwIdByName(type).pipe(
      RXJSUtils.filterUndefinedAndNull(),
      take(1),
      switchMap((data) => {
        const typeID = data[0].id;

        const m = gql`
          mutation insertHW(
            $name: String!
            $label: String!
            $type: uuid!
            $organization_id: uuid!
            $metadata: jsonb
            $public: Boolean! = false
          ) {
            insert_hw_types_one(
              object: {
                name: $name
                label: $label
                type: $type
                organization_id: $organization_id
                metadata: $metadata
                public: $public
              }
            ) {
              id
              organization_id
              name
              label
              public
              metadata
            }
          }
        `;

        const variables = {
          name: name,
          label: label,
          type: typeID,
          organization_id: organization,
          metadata: metadata,
          public: isPublic,
        };

        return this.clientApi
          .useClient('org_edit')
          .mutate<any>({
            mutation: m,
            variables: variables,
          })
          .pipe(
            map((value) => {
              let hw: IHwSwType = value.data.insert_hw_types_one;
              hw.hw_sw_type = {
                name: type,
              };
              return hw;
            })
          );
      })
    );
  }

  private getHwIdByName(name: string): Observable<IHwSwResponse[]> {
    const q = gql`
    query getHwType {
      hw_sw_type(where: {name: {_eq: "${name}"}}) {
        id
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => data.data.hw_sw_type as IHwSwResponse[])
      );
  }

  /* ------------------------ Database mutation functions ------------------------ */

  public removeHwTypeById(obj: IHwSwType): Observable<IMutationResult> {
    const id = obj.id;
    const q = gql`
      mutation rmHwType($id: uuid!) {
        delete_organization_hw_types(where: { hw_type_id: { _eq: $id } }) {
          affected_rows
        }
      }
    `;

    const variables = {
      id: id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: q,
        variables: variables,
      })
      .pipe(
        map(
          (data) => data.data?.delete_organization_hw_types as IMutationResult
        )
      );
  }

  public editHwTypeById(
    id: string,
    changes: IHwSwType,
    isBackoffice?: boolean
  ): Observable<ISingleMutationResult> {
    const q = gql`
      mutation updateHwType($id: uuid!, $label: String, $metadata: jsonb) {
        update_hw_types_by_pk(
          pk_columns: { id: $id }
          _set: { label: $label, metadata: $metadata }
        ) {
          id
        }
      }
    `;
    const variables = {
      id: id,
      label: changes.label,
      metadata: changes.metadata,
    };
    return this.clientApi
      .useClient(isBackoffice ? 'rf_backoffice_edit' : 'org_edit')
      .mutate<any>({
        mutation: q,
        variables: variables,
      })
      .pipe(
        map((data) => data.data?.update_hw_types_by_pk as ISingleMutationResult)
      );
  }
}
