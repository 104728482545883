import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { HardwareApiService } from 'src/app/services/api/hardware-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DeletePromptComponent } from '../../dialogs/delete-prompt/delete-prompt.component';
import { EditHardwareComponent } from '../../dialogs/edit-hardware-dialog/edit-hardware.component';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { RXJSUtils } from '../../../utils/rxjs-utils';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { settings } from 'src/app/models_new/config/application-settings';
import { StateService } from '../../../auth/state.service';
import { IHwSwType } from '../../../models_new/types/robot-config/hw-sw-type';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-hardware-card',
  templateUrl: './hardware-card.component.html',
  styleUrls: ['./hardware-card.component.scss'],
})
export class HardwareCardComponent implements OnInit {
  @Input() hardwareType: IHwSwType;
  @Input() shouldDisplayPrice: boolean = true;
  @Input() shouldDisplayActionButton: boolean = true;
  @Input() isBackoffice: boolean = false;
  @Input() isClickable: boolean = false;
  @Input() selected: boolean = false;

  isComponentOwner$: Observable<boolean>;
  hardwareProviderCompanyLogo$: Observable<string>;
  currencyMap = {
    Euro: '€',
    Dollar: '$',
    Pound: '£',
    Nok: 'kr',
  };

  constructor(
    private dialog: MatDialog,
    private hardwareService: HardwareApiService,
    private apiService: ApiService,
    private notification: NotificationService,
    private stateService: StateService,
    private errorHandler: ErrorHandlerService
  ) {
    this.isComponentOwner$ = this.stateService
      .getCustomerOrSalesOrganizationPreserveState()
      .pipe(map((org) => org.id === this.hardwareType.organization_id));
  }

  ngOnInit(): void {
    if (this.isHardwareProvider()) {
      this.hardwareProviderCompanyLogo$ = this.apiService
        .getPallydescriptionsSASToken()
        .pipe(
          take(1),
          startWith(null),
          map((token) =>
            token
              ? `${
                  settings.pallyDescriptionsURL +
                  this.hardwareType.metadata?.logo_path
                }?${token}`
              : '../../../../assets/dummy/dummy-logo.jpg'
          )
        );
    } else {
      this.hardwareProviderCompanyLogo$ = of(null);
    }
  }

  public get getDiscountPercent(): number {
    return Math.round((this.hardwareType.metadata.discount * 100) / 100);
  }

  public get priceDiscount(): string {
    let res =
      Math.ceil(
        this.hardwareType.metadata.price -
          Number(this.hardwareType.metadata.price) *
            (this.hardwareType.metadata.discount / 100)
      ) +
      ' ' +
      (this.currencyMap[this.hardwareType.metadata.currency] || 'EUR');
    return res;
  }

  public isHardwareProvider(): boolean {
    const metadata = this.hardwareType.metadata;
    if (metadata && metadata.organization_id === '?') {
      return false;
    }
    return metadata && metadata.logo_path;
  }

  public doAction(actionId: string, value?: any): void {
    if (actionId == 'delete_component') {
      this.deleteComponent();
    } else if (actionId === 'edit_component') {
      this.editComponent();
    } else {
      console.warn('Unkown actionId: ' + actionId);
    }
  }

  private deleteComponent(): void {
    this.dialog
      .open(DeletePromptComponent, {
        data: {
          data: {
            type: this.hardwareType.hw_sw_type.name,
            element: this.hardwareType.label,
            id: this.hardwareType.id,
          },
        },
      })
      .afterClosed()
      .pipe(
        RXJSUtils.filterUndefinedAndNull(),
        switchMap(() => {
          return this.hardwareService.removeHwTypeById(this.hardwareType);
        }),
        map((result) => {
          if (!result || result.affected_rows === 0) {
            throw `${this.hardwareType.label} could not be deleted.`;
          }
        })
      )
      .subscribe({
        next: () => {
          this.notification.showSuccess(
            `${this.hardwareType.label} was deleted!`
          );
        },
        error: (error) => {
          this.errorHandler.logError(error);
        },
      });
  }

  private editComponent(): void {
    this.dialog
      .open(EditHardwareComponent, {
        data: {
          hardware: this.hardwareType,
          isBackoffice: this.isBackoffice,
        },
      })
      .afterClosed()
      .pipe(
        RXJSUtils.filterUndefinedAndNull(),
        switchMap((delta) => {
          this.mergeInMissingValues(delta, this.hardwareType);
          if (delta.metadata.price === '') {
            delete delta.metadata.price;
          }
          if (delta.metadata.discount === '' || delta.metadata.discount <= 0) {
            delete delta.metadata.discount;
          } else {
            delta.metadata.discount;
          }
          delete this.hardwareType['updated_at'];
          return combineLatest([
            this.hardwareService.editHwTypeById(
              this.hardwareType.id,
              delta,
              this.isBackoffice
            ),
            of(
              this.sortModifiedOrgAccess(
                this.hardwareType['organization_hw_types'],
                delta['organization-access']
              ) as { added?: string[]; removed?: string[] }
            ),
          ]);
        }),
        switchMap(([editResult, orgChanges]) => {
          if (!editResult?.id) {
            throw `${this.hardwareType.label} could not be changed.`;
          } else {
            const addOrgs = orgChanges.added?.map(
              (ordId) =>
                this.hardwareService.insertGlobalHardwareTypeToOrganization(
                  editResult.id,
                  ordId,
                  this.isBackoffice
                ) ?? []
            );
            const removeOrgs = orgChanges.removed?.map((orgId) => {
              return (
                this.hardwareService.removeOrganizationHwTypeByOrgIdAndHwTypeId(
                  orgId,
                  editResult.id,
                  this.isBackoffice
                ) ?? []
              );
            });
            return orgChanges?.added || orgChanges?.removed
              ? combineLatest([...(addOrgs || []), ...(removeOrgs || [])])
              : of(EMPTY);
          }
        })
      )
      .subscribe({
        next: () => {
          this.notification.showSuccess(
            `${this.hardwareType.label} was changed!`
          );
        },
        error: (error) => {
          this.errorHandler.logError(error);
        },
      });
  }

  private sortModifiedOrgAccess(
    oldOrgs,
    newOrgs
  ): { added: string[]; removed: string[] } {
    const oldIds = new Set(oldOrgs?.map((org) => org.organization_id));
    const newIds = new Set(newOrgs?.map((org) => org.id));
    const commonIds = Array.from(
      new Set([...oldIds].filter((id) => newIds.has(id)))
    );
    return {
      added: newOrgs
        ?.filter((org) => !commonIds.includes(org.id))
        ?.map((org) => org.id),
      removed: oldOrgs
        ?.filter((org) => !commonIds.includes(org.organization_id))
        ?.map((org) => org.id),
    };
  }

  private mergeInMissingValues(obj: object, reference: object) {
    for (const key of Object.keys(reference)) {
      if (typeof obj[key] == 'object' && typeof reference[key] == 'object') {
        this.mergeInMissingValues(obj[key], reference[key]);
        continue;
      }
      if (obj[key] !== undefined) {
        continue;
      }
      obj[key] = reference[key];
    }
  }
}
