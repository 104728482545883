import { PalletSummary } from './pallet-summary';
import { PalletPosition } from '../enums/pallet-position';
import { Layer } from './layer';
import { IPallet } from '../types/pallet';
import { defaultPallet } from '../config/default/default-pallet';
import { LayerType } from '../enums/layer-type';
import { IPalletSummary } from '../types/pallet-summary';
import { BehaviorSubject, merge } from 'rxjs';
import { ILayerUpdate } from '../types/layer-update';
import { UpdateAction } from '../enums/update-action';
import { IPalletUpdate } from '../types/pallet-update';
import { StackingMethod } from '../enums/stacking-method';
import { ObjectUtils } from 'src/app/utils/object';
import { BoxOrientor } from './box-orientor';

export class Pallet implements IPallet {
  id: string;
  name: string;
  palletSummary?: PalletSummary;
  position: PalletPosition;
  layers: Layer[];

  overhangSides?: number;
  overhangEnds?: number;
  dimensions: {
    length: number;
    width: number;
    loadHeight: number; // Height of layers
    palletHeight: number; // Height of pallet it self
    totalHeight: number; // Total of load and pallet height
    maxLoadHeight: number;
  };
  autoStack: {
    method: StackingMethod;
    baseIndex: number;
  };
  preserveLayers: boolean;

  update$ = new BehaviorSubject<IPalletUpdate>(null);

  constructor(position: PalletPosition, pallet?: IPallet) {
    this.position = position;

    const p = pallet ? pallet : ObjectUtils.cloneObject(defaultPallet); // Copy to make unique object

    this.id = p.id;
    this.name = p.name;
    this.palletSummary = p.palletSummary
      ? new PalletSummary(p.palletSummary)
      : null;
    this.layers = p.layers ? p.layers.map((m) => new Layer(m as Layer)) : [];
    this.overhangSides = p.overhangSides;
    this.overhangEnds = p.overhangEnds;
    this.dimensions = p.dimensions;
    this.autoStack = p.autoStack ? p.autoStack : null;
    this.preserveLayers = p.preserveLayers ? p.preserveLayers : false;

    if (this.layers && this.layers.length) {
      this.subscribeToLayerUpdates();
    }
  }

  subscribeToLayerUpdates() {
    const sources: BehaviorSubject<ILayerUpdate>[] = [];
    this.layers.forEach((l: Layer) => {
      sources.push(l.update$);
    });

    if (sources.length) {
      merge(...sources).subscribe((u: ILayerUpdate) => {
        if (u) {
          this.update(u.label, u.updateAction);
        }
      });
    }
  }

  update(label: (string | number)[] = [''], updateAction: UpdateAction) {
    this.update$.next({
      palletPosition: this.position,
      updateAction: updateAction,
      label: label,
    });
  }

  /**
   * @param uniqueNo: number
   */
  setId(uniqueNo: number): void {
    this.id = `${uniqueNo}`;
  }

  /**
   * @param overhang: number
   */
  setOverhangSides(overhang: number): void {
    this.overhangSides = overhang;
  }

  /**
   * @param overhang: number
   */
  setOverhangEnds(overhang: number): void {
    this.overhangEnds = overhang;
  }

  /**
   * @param length: number
   */
  setPalletLength(length: number): void {
    this.dimensions.length = length;
  }

  /**
   * @param width: number
   */
  setPalletWidth(width: number): void {
    this.dimensions.width = width;
  }

  /**
   * @param palletHeight // Height of the pallet it self
   */
  setPalletHeight(palletHeight: number) {
    this.dimensions.palletHeight = palletHeight;
  }

  /**
   * @param maxHeight: number
   */
  setPalletMaxHeight(maxHeight: number) {
    this.dimensions.maxLoadHeight = maxHeight;
  }

  /**
   * @returns height of pallet
   */
  getPalletHeight(): number {
    return this.dimensions.palletHeight;
  }

  getPalletSummary(): PalletSummary {
    this.updatePalletDynamic();
    return this.palletSummary;
  }

  setLayerPositions(): void {
    if (this.layers.length) {
      this.layers.forEach((layer: Layer, index: number) => {
        layer.setLayerPosition(this.layers.length - index);
      });

      // Sort layers by layerPosition
      const sortedByPosition = [...this.layers].sort((a: Layer, b: Layer) => {
        return a.layerPosition - b.layerPosition;
      });

      // Set z position for each layer
      let startZ = this.getPalletHeight();
      sortedByPosition.forEach((layer: Layer) => {
        layer.setZPosition(startZ + layer.getHeight() / 2);
        startZ = layer.getTopZ();
      });
    }
  }

  /**
   * Updates dynamic pallet settings
   */
  updatePalletDynamic() {
    this.setPalletLoadHeight();
    this.setPalletTotalHeight();
    this.setPalletSummary();
    this.setLayerPositions();
  }

  setPalletSummary() {
    const summary: IPalletSummary = {
      boxCount: 0,
      palletHeight: this.getPalletTotalHeight(true),
      palletLoadHeight: this.getPalletLoadHeight(),
      palletWeight: 0,
      numberOfLayers: 0,
      palletAreaEfficiency: 0,
      palletCubeEfficiency: 0,
      palletCenterOfMass: {
        x: 0,
        y: 0,
        z: 0,
      },
    };

    const layers: Layer[] = this.getLayersByType(LayerType.LAYER);
    const palletArea = this.dimensions.length * this.dimensions.width;

    if (layers.length) {
      let centerMassX = 0;
      let centerMassY = 0;
      let centerMassZ = 0;

      layers.forEach((layer: Layer) => {
        summary.boxCount += layer.boxes.length;
        summary.numberOfLayers++;
        summary.palletWeight += layer.weight;

        //Calculate center of mass
        const center = layer.centerOfMass;
        centerMassX += center.x * layer.weight;
        centerMassY += center.y * layer.weight;
        centerMassZ += center.z * layer.weight;
      });

      summary.palletCenterOfMass.x = centerMassX / summary.palletWeight;
      summary.palletCenterOfMass.y = centerMassY / summary.palletWeight;
      summary.palletCenterOfMass.z = centerMassZ / summary.palletWeight;

      if (layers[0].boxes.length) {
        const boxDimensions = layers[0].boxes[0].dimensions;
        const baseLayer = layers[0];
        const productArea = boxDimensions.length * boxDimensions.width;

        summary.palletAreaEfficiency =
          ((productArea * baseLayer.boxes.length) / palletArea) * 100;
        summary.palletCubeEfficiency =
          ((productArea * summary.boxCount) /
            (palletArea * summary.numberOfLayers)) *
          100;
      }
    }

    this.palletSummary = summary;
  }

  /**
   * Makes a layer with unique id from a layer.
   *
   * @param layer: Layer
   * @param updateAction: UpdateAction
   */
  addLayer(
    layer: Layer,
    updateAction: UpdateAction,
    last: boolean = false
  ): void {
    layer.setId(this.getNewLayerId());

    if (last) {
      this.layers.push(layer);
    } else {
      this.layers.unshift(layer);
    }
    this.update([layer.id], updateAction);
  }

  getNewLayerId(): number {
    let unique;
    let newId;

    const makeNewId = () => {
      unique = Math.floor(Math.random() * 10000);
      newId = this.position + '-' + unique;
      return newId;
    };

    while (this.getLayerById(makeNewId())) {} // Create new ids until an unused one is found

    return unique;
  }

  /**
   * @param stackingMethod: StackingMethod
   */
  setStackingMethod(stackingMethod: StackingMethod) {
    this.autoStack.method = stackingMethod;
    this.update([stackingMethod], UpdateAction.SET_STACKING_METHOD);
  }

  /**
   * @param baseIndex: number
   */
  setBasePatternByIndex(baseIndex: number) {
    this.autoStack.baseIndex = baseIndex;
    this.update([baseIndex], UpdateAction.SET_BASE_INDEX);
  }

  getDuplicateLayersByTypeId(): Layer[] {
    const duplicates: Layer[] = [];

    this.layers.forEach((l: Layer) => {
      const alikeLayers = this.getLayersByTypeId(l.typeId).filter(
        (f) => f.id !== l.id
      );

      if (alikeLayers.length) {
        alikeLayers.forEach((dup: Layer) => {
          const currentIds = duplicates.map((m) => m.id);
          if (!currentIds.includes(dup.id)) {
            duplicates.push(dup);
          }
        });
      }
    });

    return duplicates;
  }

  getDuplicatesByBoxes(): Layer[] {
    const duplicates: Layer[] = [];

    this.layers.forEach((l1: Layer) => {
      this.layers.forEach((l2: Layer) => {
        if (l2.id !== l1.id) {
          const isEqualBoxPositions = ObjectUtils.isEqual(
            l1.getBoxPositions().map((m) => ({ x: m.x, y: m.y })),
            l2.getBoxPositions().map((m) => ({ x: m.x, y: m.y }))
          );
          const isEqualBoxRotations = ObjectUtils.isEqual(
            l1.getBoxRotations(),
            l1.getBoxRotations()
          );

          if (isEqualBoxPositions && isEqualBoxRotations) {
            duplicates.push(l2);
          }
        }
      });
    });

    return duplicates;
  }

  getUniquesByBoxes(): Layer[] {
    const duplicates = this.getDuplicatesByBoxes().map((m) => m.id);
    const uniqueLayers: Layer[] = [];

    this.layers.forEach((l: Layer) => {
      if (!duplicates.includes(l.id)) {
        uniqueLayers.push(l);
      }
    });

    return uniqueLayers;
  }

  /**
   * If some of the layers in a pallet has same typeId. This will return one of each.
   * @param startBottom: true = start at bottom, false = start at top
   * @returns one of each layer.typeIds
   */
  getOneOfEachLayersTypeId(startBottom: boolean = true): Layer[] {
    const getUnique = (layers: Layer[]) =>
      Array.from(new Set(layers.map((l: Layer) => l.typeId))).map((typeId) => {
        return layers.find((l) => l.typeId === typeId);
      });

    if (startBottom) {
      // Reverse arrays to find first unique from bottom
      return [
        ...getUnique(this.getLayersByType(LayerType.SHIMPAPER).reverse()),
        ...getUnique(this.getLayersByType(LayerType.LAYER).reverse()),
      ];
    } else {
      return [
        ...getUnique(this.getLayersByType(LayerType.SHIMPAPER)),
        ...getUnique(this.getLayersByType(LayerType.LAYER)),
      ];
    }
  }

  /**
   * @param layers: Layer[]
   */
  setLayers(layers: Layer[]) {
    this.layers = layers;
    const layerIds = this.layers.map((m: Layer) => m.id);
    this.update(layerIds, UpdateAction.SET_LAYERS);
  }

  /**
   * @param index: number
   * @param layer: Layer
   * @param updateAction: UpdateAction
   */
  addLayerAtIndex(
    index: number,
    layer: Layer,
    updateAction: UpdateAction
  ): void {
    layer.setId(this.getNewLayerId());
    this.layers.splice(index, 0, layer);
    this.update([layer.id], updateAction);
  }

  moveLayerToIndex(newIndex: number, oldIndex: number, layer: Layer) {
    this.removeLayerAtIndex(oldIndex, true);
    this.addLayerAtIndex(newIndex, layer, UpdateAction.ADD_NEW);
  }

  /**
   * @param id // Layer id
   */
  removeLayerById(id: string): void | Error {
    const layer = this.layers.filter((l: Layer) => l.id === id);
    if (layer.length) {
      this.layers = this.layers.filter((l: Layer) => l.id !== id);
      this.update([id], UpdateAction.REMOVE);
    } else {
      throw new Error('Cannot remove layer. No layer with id: ' + id);
    }
  }

  /**
   * @param index: number
   */
  removeLayerAtIndex(index: number, manualAction: boolean = true): void {
    const layer = this.getLayerByIndex(index);

    if (layer) {
      const layerId = layer.id;
      this.layers.splice(index, 1);
      if (manualAction) {
        this.update([layerId], UpdateAction.REMOVE);
      }
    } else {
      throw new Error('Cannot remove layer at index ' + index);
    }
  }

  whipePallet() {
    this.layers = [];
    this.update([], UpdateAction.REMOVE);
  }

  /**
   * @param index: number
   * @param layer: Layer
   */
  replaceLayerAtIndex(
    index: number,
    layer: Layer,
    updateAction?: UpdateAction
  ): void {
    if (!layer.id) {
      layer.setId(this.getNewLayerId());
    }
    const replacedId = this.getLayerByIndex(index)
      ? this.getLayerByIndex(index).id
      : null;
    this.layers.splice(index, 1, layer);

    this.update([layer.id], updateAction ? updateAction : UpdateAction.REMOVE);
    this.update(
      [replacedId],
      updateAction ? updateAction : UpdateAction.ADD_NEW
    );
  }

  /**
   * @param generating optional if auto stack
   */
  deleteAllLayers(generating?: boolean) {
    const allLayerIds = this.layers.map((m: Layer) => m.id);
    this.layers = [];
    const updateAction = generating
      ? UpdateAction.GENERATING_PALLET
      : UpdateAction.REMOVE;
    this.update(allLayerIds, updateAction);
  }

  setPalletLoadHeight(): void {
    this.dimensions.loadHeight = this.getPalletLoadHeight();
  }

  setPalletTotalHeight(): void {
    this.dimensions.totalHeight = this.getPalletTotalHeight(true);
  }

  getLayersLength(): number {
    return this.layers.length;
  }

  getTopLayer(): Layer {
    return this.getLayerByIndex(0);
  }

  getBottomLayer(): Layer {
    return this.getLayerByIndex(this.layers.length - 1);
  }

  /**
   * @param layerPosition: number
   */
  getLayerByPosition(layerPosition: number): Layer {
    const layer = this.layers.filter(
      (l: Layer) => l.layerPosition === layerPosition
    );
    return layer.length ? layer[0] : null;
  }

  getLayerByIndex(index: number): Layer {
    return this.layers[index];
  }

  getLayerById(id: string): Layer {
    const layer = this.layers.filter((l: Layer) => l.id === id);
    return layer.length ? layer[0] : null;
  }

  getLayersByTypeId(typeId: string): Layer[] {
    const layers = this.layers.filter((l: Layer) => l.typeId === typeId);
    return layers;
  }

  getLayersByType(type: LayerType): Layer[] {
    return this.layers.filter((l: Layer) => l.type === type);
  }

  getHighestTypeId(type: LayerType): number {
    const layers = this.getLayersByType(type);
    if (!layers.length) {
      return 0;
    }
    const typeIds = layers.map((m: Layer) => m.getTypeId('layer-no'));
    const highestTypeId = Math.max(...typeIds);

    if (highestTypeId === -Infinity) {
      return 0;
    }
    return highestTypeId;
  }

  getLowestUnusedTypeId(type: LayerType): number {
    const layers = this.getLayersByType(type);
    if (!layers.length) {
      return 0;
    }

    const typeIds = layers.map((m: Layer) => m.getTypeId('layer-no'));
    let lowestUnused;
    let i = 1;
    while (i <= layers.length + 1) {
      if (!typeIds.includes(i)) {
        lowestUnused = i;
        break;
      }
      i++;
    }

    if (lowestUnused === Infinity) {
      return 0;
    }

    return lowestUnused;
  }

  getPalletMaxTotalHeight(includePallet: boolean): number {
    return includePallet
      ? this.dimensions.totalHeight
      : this.dimensions.loadHeight;
  }

  getPalletTotalHeight(includePallet: boolean): number {
    let palletHeight = includePallet ? this.dimensions.palletHeight : 0;
    this.layers.forEach((layer: Layer) => {
      palletHeight += layer.height;
    });
    return palletHeight;
  }

  getPalletLoadHeight(): number {
    let palletHeight = 0;
    this.layers.forEach((layer: Layer) => {
      palletHeight += layer.height;
    });
    return palletHeight;
  }

  setLabelsFacingOut(): void {
    this.layers.forEach((l) => {
      const boxOrientor = new BoxOrientor(l.boxes, this.dimensions);
      l.boxes = boxOrientor.getBoxes();
    });

    this.update(['Labels facing out'], UpdateAction.EDIT_BOXES);
  }

  setLabelsLocked(): void {
    this.layers.forEach((l) => {
      l.boxes.forEach((b) => {
        b.rotation.pop();
        b.labelOrientations.pop();
      });
    });

    this.update(['Labels locked'], UpdateAction.EDIT_BOXES);
  }
}
