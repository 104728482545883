import { TransformVectorType } from '../enums/transform-vector-type';
import { Vector3 } from './vector3';
import { TransformVector } from './transform-vector';

export class Transform {
  vectors = [
    new TransformVector(TransformVectorType.TRANSLATION, 0, 0, 0),
    new TransformVector(TransformVectorType.ROTATION, 0, 0, 0),
    new TransformVector(TransformVectorType.SCALE, 1, 1, 1),
  ];

  constructor() {}

  translation(): Vector3 {
    return this.vectors[0].vector;
  }

  rotation(): Vector3 {
    return this.vectors[1].vector;
  }

  scale(): Vector3 {
    return this.vectors[2].vector;
  }
}
