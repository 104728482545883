import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ThreeViewContent } from '../../../../../../../models_new/classes/3dview/three-content';
import { settings } from '../../../../../../../models_new/config/application-settings';
import { New_ThreeViewComponent } from '../../../../three-view/three-view.component';

@Component({
  selector: 'app-orthographic-camera',
  templateUrl: './orthographic-camera.component.html',
  styleUrls: ['./orthographic-camera.component.scss'],
})
export class OrthographicCameraComponent
  extends ThreeViewContent
  implements OnChanges
{
  camera: THREE.OrthographicCamera;

  @Input() near = 0.01;
  @Input() far = 1000;
  @Input() width = settings.view3d.defaultOrthographicCamera.width;
  @Input() height = settings.view3d.defaultOrthographicCamera.height;
  @Input() up = new THREE.Vector3(0, 1, 0);

  @Input() position = settings.view3d.defaultCameraPosition.clone();
  @Input() rotation: THREE.Quaternion;

  @Input() target: THREE.Object3D | THREE.Vector3 | undefined;

  @Input() controls: OrbitControls;

  // Adds a light helper for the light to the scene.
  @Input() debug: boolean;
  helper: THREE.CameraHelper;

  constructor(threeView: New_ThreeViewComponent) {
    super(threeView);
    this.updateOrder = 50; // Update this late

    const dim = calcOrthographicCameraDimensions(
      this.width,
      this.height,
      this.threeView.canvasAspect
    );
    this.camera = new THREE.OrthographicCamera(
      dim.left,
      dim.right,
      dim.top,
      dim.bottom,
      this.near,
      this.far
    );
    this.camera.position.copy(this.position);
    this.camera.updateProjectionMatrix();
    this.scene.add(this.camera);

    this.helper = new THREE.CameraHelper(this.camera);
    this.helper.visible = false;
    this.scene.add(this.helper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.width?.currentValue || changes.height?.currentValue) &&
      this.width &&
      this.height
    ) {
      this.updateCameraDimensions(
        this.width,
        this.height,
        this.threeView.canvasAspect
      );
    }

    if (changes.near?.currentValue) {
      this.camera.near = changes.near.currentValue;
      this.camera.updateProjectionMatrix();
    }
    if (changes.far?.currentValue) {
      this.camera.far = changes.far.currentValue;
      this.camera.updateProjectionMatrix();
    }
    if (changes.position?.currentValue) {
      this.camera.position.copy(changes.position?.currentValue);
      this.camera.updateProjectionMatrix();

      this.updateControls();
    }
    if (changes.up?.currentValue) {
      this.camera.up.copy(changes.up?.currentValue);
      this.camera.updateProjectionMatrix();
    }
    if (changes.debug?.currentValue) {
      this.helper.visible = changes.debug.currentValue;
    }
    if (changes.target?.currentValue) {
      this.updateTrackingOfTarget(changes.target.currentValue);

      this.updateControls();
    }
  }

  update(dt: number): void {
    if (this.target) {
      this.updateTrackingOfTarget(this.target);
    }
  }

  updateControls(): void {
    if (this.controls) {
      this.controls.update();
    } else if (this.threeView.controls) {
      this.threeView.updateControls();
    }
  }

  resize(width: number, height: number, aspect: number): void {
    this.updateCameraDimensions(this.width, this.height, aspect);
  }

  updateCameraDimensions(width: number, height: number, aspect: number): void {
    const dim = calcOrthographicCameraDimensions(width, height, aspect);
    this.camera.left = dim.left;
    this.camera.right = dim.right;
    this.camera.top = dim.top;
    this.camera.bottom = dim.bottom;
    this.camera.updateProjectionMatrix();
  }

  private updateTrackingOfTarget(
    target: THREE.Object3D | THREE.Vector3 | undefined
  ): void {
    if (!this.target) {
      return;
    }

    let lookAtPos = new THREE.Vector3();
    if ('isObject3D' in target) {
      lookAtPos.copy(target.position);
    } else {
      lookAtPos = target;
    }
    this.camera.lookAt(lookAtPos);
    this.camera.updateProjectionMatrix();
    // Make sure I'm updated to my internal camera
    this.rotation = this.camera.quaternion;
  }
}

/**
 * Calculates the edges for a orthograpic camera with a given size in
 * the world (NOT screen pixes) and corrects it with the viewport aspect.
 * @param width number - the width you want IN THE 3D WORLD (meters)
 * @param height number - the height you want IN THE 3D WORLD (meters)
 * @param aspect number - aspect of the viewport/surface you're rendering on. **If omitted** it defaults to the aspect of tht given width and height.
 * @returns correct left, right, top and bottom properties for orthograpic cameras.
 */
export function calcOrthographicCameraDimensions(
  width: number,
  height: number,
  aspect: number = width / height
): { left: number; right: number; top: number; bottom: number } {
  return {
    left: (-width / 2) * aspect,
    right: (width / 2) * aspect,
    top: height / 2,
    bottom: -height / 2,
  };
}
