import { IStandardPallet } from './../../../models_new/types/standard-pallet';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { CardFieldIds } from 'src/app/models_new/config/form_fields';
import { Field } from 'src/app/models_new/classes/field';
import {
  getCustomPalletFormGroup,
  getCustomPalletFields,
} from './custom-pallet-fields';

export interface ICustomPalletDimensions {
  pallet_height: number;
  pallet_length: number;
  pallet_width: number;
}

@Component({
  selector: 'app-custom-pallet-dialog',
  templateUrl: './custom-pallet-dialog.component.html',
  styleUrls: ['./custom-pallet-dialog.component.scss'],
})
export class CustomPalletDialogComponent implements OnInit {
  createHandler: Function;

  CardFieldIds = CardFieldIds;
  getCustomPalletFields: Map<CardFieldIds, Field>;
  getCustomPalletFormGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CustomPalletDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        createHandler: Function;
        customPallet?: IStandardPallet;
      };
    }
  ) {}

  ngOnInit(): void {
    this.createHandler = this.input.data.createHandler;
    this.getCustomPalletFields = getCustomPalletFields(
      null,
      this.input.data?.customPallet
    );
    this.getCustomPalletFormGroup = getCustomPalletFormGroup(
      this.getCustomPalletFields
    );
  }

  getField(id: CardFieldIds): Field {
    return this.getCustomPalletFields.get(id);
  }

  didClickCreate() {
    if (this.getCustomPalletFormGroup.valid) {
      const newDimensions = <IStandardPallet>{
        palletHeight: this.getCustomPalletFormGroup.getRawValue().pallet_height,
        width: this.getCustomPalletFormGroup.getRawValue().pallet_width,
        length: this.getCustomPalletFormGroup.getRawValue().pallet_length,
      };
      this.createHandler(newDimensions);
      this.didClickClose();
    }
  }

  didClickClose() {
    this.dialogRef.close();
  }
}
