<ng-container *appVar="appLayout.lowHeight$ | async as lowHeigth">
  <ng-container
    *appVar="openSimService.pagesSortedList$ | async as pagesSortedList"
  >
    <div
      class="landing-outlet-nav-top"
      id="landing-outlet-nav-top"
      [ngStyle]="
        lowHeigth
          ? {
              'padding-top': '0.8em',
              'padding-bottom': '0.5em',
              height: '5rem'
            }
          : {}
      "
    >
      <button
        mat-raised-button
        color="accent"
        id="fastTrackBackBtn"
        (click)="openSimService.back()"
        *ngIf="openSimService.selectedPageIndex < pagesSortedList.length - 1"
      >
        BACK
      </button>
      <div class="landing-outlet-stepper">
        <div
          *ngFor="let page of pagesSortedList; let i = index; let l = last"
          class="stepper-wrapper"
        >
          <ng-container *ngIf="!openSimService.embed">
            <div
              (click)="openSimService.setStep(page.step)"
              [routerLink]="page.path"
              queryParamsHandling="preserve"
              [class.done-step]="page.done"
              [class.active-step]="
                openSimService.selectedStepIndex === page.step - 1
              "
              class="step"
            >
              <span
                *ngIf="
                  page.done &&
                  openSimService.selectedStepIndex !== page.step - 1
                "
                ><mat-icon>done</mat-icon></span
              >
              <span
                *ngIf="
                  !page.done ||
                  openSimService.selectedStepIndex === page.step - 1
                "
                >{{ i + 1 }}</span
              >
              <span
                class="step-text"
                [ngStyle]="lowHeigth ? { 'margin-bottom': '0.5em' } : {}"
              >
                {{ page.content.cardText }}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="openSimService.embed">
            <div
              (click)="openSimService.setStep(page.step)"
              [class.done-step]="page.done"
              [class.active-step]="
                openSimService.selectedStepIndex === page.step - 1
              "
              class="step"
            >
              <span
                *ngIf="
                  page.done &&
                  openSimService.selectedStepIndex !== page.step - 1
                "
                ><mat-icon>done</mat-icon></span
              >
              <span
                *ngIf="
                  !page.done ||
                  openSimService.selectedStepIndex === page.step - 1
                "
                >{{ i + 1 }}</span
              >
              <span
                class="step-text"
                [ngStyle]="lowHeigth ? { 'margin-bottom': '0.5em' } : {}"
              >
                {{ page.content.cardText }}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!l">
            <div
              class="line"
              [ngClass]="page.done ? 'done-line' : 'not-done-line'"
            ></div>
          </ng-container>
        </div>
      </div>

      <button
        *ngIf="openSimService.selectedPageIndex < pagesSortedList.length - 1"
        mat-raised-button
        id="fastTrackNextBtn"
        color="accent"
        (click)="openSimService.next()"
        [disabled]="!pagesSortedList[openSimService.selectedPageIndex].valid"
      >
        <span>NEXT</span>
      </button>
    </div>
  </ng-container>
</ng-container>
