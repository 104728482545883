import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { analyticsPagesPATH, pagesPATH } from './models_new/config/pages';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  LocalStorageService,
  StorageMethod,
} from './services/local-storage.service';
import { LocalStorageKey } from './models_new/enums/local-storage-keys';
import { ProcessingOverlayService } from './services/processing-overlay.service';
import { externalSvgIcons } from './models_new/config/svgIcons';
import {
  IGlobalNotification,
  NotificationService,
} from './services/notification.service';
import { combineLatest, Observable, of } from 'rxjs';
import { StateService } from './auth/state.service';
import { GoogleAnalyticsService } from './services/google-analytics-service.service';
import { SupportWidgetService } from 'src/app/services/support-widget.service';
import { AuthService } from '@auth0/auth0-angular';
import { RXJSUtils } from './utils/rxjs-utils';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  pagesPATH = pagesPATH;
  title = environment.title;
  version: string = environment.version;
  developmentWatermark = environment.development;
  showToolbar$: Observable<boolean>;
  private defaultShowToolbar = true;

  isLandingPage: boolean = false;

  globalNotification$: Observable<IGlobalNotification>;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    public router: Router,
    private route: ActivatedRoute,
    private notifier: NotificationService,
    private localStorageService: LocalStorageService,
    public processingOverlayService: ProcessingOverlayService,
    private updateService: UpdateService,
    private stateService: StateService,
    private gas: GoogleAnalyticsService,
    private supportWidgetService: SupportWidgetService,
    private auth: AuthService
  ) {
    this.isLandingPage = this.router.url === '/';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLandingPage = event.url === '/';
      }
    });

    if (environment.development) {
      document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'L') {
          const prompt = window.prompt('Logout?', 'yes');
          if (prompt) {
            this.auth.logout();
          }
        }
      });
    }

    this.globalNotification$ = this.notifier
      .getLatestGlobalNotifications()
      .pipe(
        RXJSUtils.filterUndefinedAndNull(),
        map((notifications) => {
          if (notifications) {
            const latestViewed: string[] = this.localStorageService.getData(
              LocalStorageKey.LATEST_VIEWED_GLOBAL_NOTIFICATION
            )
              ? this.localStorageService.getData(
                  LocalStorageKey.LATEST_VIEWED_GLOBAL_NOTIFICATION
                )
              : [];
            for (const nonExpired of notifications) {
              if (latestViewed?.includes(nonExpired.id)) {
                continue;
              }
              latestViewed.push(nonExpired.id);
              this.localStorageService.setData(
                LocalStorageKey.LATEST_VIEWED_GLOBAL_NOTIFICATION,
                latestViewed,
                StorageMethod.LOCAL
              );
              return nonExpired;
            }
          }
          return null;
        }),
        filter((notification) => !!notification)
      );

    this.subscribeToRouterEvents();

    this.titleService.setTitle(this.title);

    this.metaService.addTags([
      {
        name: 'keywords',
        content:
          'Pallet builder, Pattern generator, Universal Robots, palletizing',
      },
      // eslint-disable-next-line max-len
      {
        name: 'description',
        content:
          'Easy to use Pallet Builder software. Design a 3D view of your pallet pattern. Online Pattern Generator for Pally software. For Universal Robots palletizing projects.',
      },
      {
        name: 'robots',
        content: 'index, follow',
      },
    ]);

    externalSvgIcons.forEach((externalIcon) => {
      this.matIconRegistry.addSvgIcon(
        externalIcon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/icons/' + externalIcon.path
        )
      );
    });

    this.showToolbar$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap((route) =>
        combineLatest([of(route), this.stateService.isUserLogged$])
      ),
      mergeMap(([route, isUserLogged]) => {
        if (
          (route.snapshot.queryParams.hasOwnProperty('public') &&
            !isUserLogged) ||
          route.snapshot.queryParams.hasOwnProperty('o')
        ) {
          return of({
            showToolbar: false,
          });
        }
        return route.data;
      }),
      map((data) =>
        data.hasOwnProperty('showToolbar')
          ? data.showToolbar
          : this.defaultShowToolbar
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // This enables Atlassian service widget when users are logged in.
    this.stateService.isUserLogged$.subscribe((isLoggedIn) => {
      if (isLoggedIn && environment?.atlassianServiceWidgetKey) {
        this.supportWidgetService.loadScriptDynamically().then(() => {
          window.document.dispatchEvent(
            new Event('DOMContentLoaded', {
              bubbles: true,
              cancelable: true,
            })
          );
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.updateService.isCheckForUpdateEnabled()) {
      this.updateService.checkForUpdates();
    }
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        // Absorb all events except last within a hopefully long enough timespan.
        debounceTime(500),
        tap((event: NavigationEnd) => {
          if (!environment.googleAnalyticsGtag) {
            console.warn('No gtag found!');
            return;
          } else {
            this.setAnalyticsPagePath(event.urlAfterRedirects);
          }
        }),
        switchMap((_) => this.route.queryParams),
        tap((params) => {
          if (
            params['success'] &&
            params['success'] === 'true' &&
            params['message']
          ) {
            this.notifier.showSuccess(params['message']);
          }
          if (
            params['utm_campaign'] ||
            params['utm_content'] ||
            params['utm_medium'] ||
            params['utm_source']
          ) {
            this.localStorageService.setData(LocalStorageKey.UTM, params);
          }
        })
      )
      .subscribe();
  }

  setAnalyticsPagePath(event) {
    let toCheck = JSON.parse(JSON.stringify(event));

    if (toCheck.includes('?')) {
      toCheck = toCheck.substr(1, toCheck.indexOf('?') - 1);
    } else {
      toCheck = event.substr(1);
    }

    const analyticsPath = analyticsPagesPATH.filter(
      toCheck == ''
        ? (f) => f.path === toCheck
        : (f) => f.path.includes(toCheck)
    );

    let page_path;
    let page_title;
    if (analyticsPath.length) {
      page_path = analyticsPath[0].pagePath
        ? analyticsPath[0].pagePath
        : analyticsPath[0].path;
      page_title = analyticsPath[0].pageTitle;
    } else {
      page_path = page_title = event;
    }

    this.gas.addPageview(page_path, page_title);
  }
}
