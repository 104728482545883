<app-landing-toolbar *ngIf="!openSimService.embed"></app-landing-toolbar>

<ng-container *ngIf="contentfulContent$ | async as contentfulContent">
  <!-- Loading state -->
  <app-loading *ngIf="contentfulContent.isLoading"></app-loading>

  <!-- Error state -->
  <app-error
    *ngIf="contentfulContent.error as error"
    [error]="error"
  ></app-error>

  <ng-container *ngIf="contentfulContent.didSucceed && contentfulContent.value">
    <div
      class="landing-page-content"
      [ngClass]="{ embedded: openSimService.embed }"
    >
      <div class="landing-page-left" [ngStyle]="{}">
        <div class="landing-page-left-div">
          <img
            *ngIf="!contentfulContent.value.youTubeEmbed"
            [src]="contentfulContent.value.image.url"
            class="big-img"
          />
          <iframe
            *ngIf="contentfulContent.value.youTubeEmbed"
            [src]="contentfulContent.value.youTubeEmbed | trust: 'resourceUrl'"
            title="YouTube video player"
            class="embedded-youtube"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
      <div class="landing-page-right">
        <div class="landing-page-right-text">
          <h1>{{ contentfulContent.value.title }}</h1>
          <p [innerHTML]="contentfulContent.value.text"></p>
        </div>
        <button
          *ngIf="!openSimService.embed"
          class="landing-cta"
          mat-raised-button
          color="accent"
          (click)="analyticsTrySimulations()"
          [routerLink]="pagesPath.FAST_TRACK_PRODUCT"
          queryParamsHandling="preserve"
          style="height: 56px"
        >
          {{ contentfulContent.value?.ctaButtonText || 'TRY OUT SIMULATIONS' }}
        </button>
        <button
          *ngIf="openSimService.embed"
          class="landing-cta"
          mat-raised-button
          color="accent"
          (click)="openSimService.start()"
          style="height: 56px"
        >
          {{ contentfulContent.value?.ctaButtonText || 'TRY OUT SIMULATIONS' }}
        </button>
        <a
          [href]="contentfulContent.value.learnMoreLink"
          target="_blank"
          [appAnalytics]="
            'Home - MyRobot.cloud	- See Example Result CTA - Click'
          "
        >
          <button
            mat-stroked-button
            color="accent"
            style="border: 2px solid; height: 56px"
          >
            SEE EXAMPLE RESULTS
          </button>
        </a>
      </div>
    </div>
  </ng-container>
</ng-container>
