<div
  class="card"
  [ngClass]="{
    info: type === 'info',
    warn: type === 'warn',
    error: type === 'error',
    'white-background': solidBackground,
  }"
>
  <div class="icon">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  </div>
  <div>
    <div class="message">
      <h3 *ngIf="title">{{ title }}</h3>
      <p style="margin: 0px">{{ message }}</p>
    </div>
  </div>
</div>
