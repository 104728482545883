<div class="state-chips-wrapper">
  <ng-container *ngIf="sales_org">
    <button
      mat-icon-button
      [matMenuTriggerFor]="salesOrgMenu"
      class="state-chip-button"
      (menuOpened)="salesOrgMenuClosed = false"
      (menuClosed)="salesOrgMenuClosed = true"
    >
      <!-- Sales Organization chip -->
      <app-company-logo
        [activeOrganizationType]="sales_org.type"
        [orgId]="sales_org.id"
        [assetUrl]="sales_org.logo"
      ></app-company-logo>

      <div class="state-chip-text">
        <span>{{ sales_org.name }}</span>
      </div>
      <mat-icon *ngIf="salesOrgMenuClosed">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="!salesOrgMenuClosed">arrow_drop_up</mat-icon>
    </button>
    <mat-menu #salesOrgMenu="matMenu" backdropClass="orgMainMenu">
      <div mat-menu-item class="extended-menu-item" disabled="true">
        <div mat-card-avatar class="extended-menu-card-avatar">
          <app-company-logo
            [activeOrganizationType]="sales_org.type"
            [orgId]="sales_org.id"
            [assetUrl]="sales_org.logo"
          ></app-company-logo>
        </div>
        <div class="extended-menu-text">
          <span class="secondary-span">SALES ORGANIZATION</span>
          <span class="main-span">{{ sales_org.name }}</span>
        </div>
      </div>
      <ng-template #editSales>
        <div
          *appIfCommandAvailable="'update_organization'"
          class="extended-menu-card-edit"
          (click)="doAction('navigateTo', routeId.EDIT_SALES_ORG)"
        >
          <mat-icon> edit </mat-icon>
        </div>
      </ng-template>

      <button mat-menu-item (click)="selectOrganization(sales_org)">
        <mat-icon>dashboard</mat-icon>Dashboard
      </button>
      <button
        *ngIf="sales_organizations && sales_organizations.length > 1"
        mat-menu-item
        [matMenuTriggerFor]="salesOrgSwitch"
      >
        <mat-icon>cached</mat-icon>Switch organization
      </button>
    </mat-menu>

    <!-- Active SalesOrg switch -->
    <mat-menu #salesOrgSwitch="matMenu" yPosition="below">
      <div class="organization-card-wrapper">
        <ng-container
          [ngTemplateOutlet]="organizationItemList"
          [ngTemplateOutletContext]="{
            orgs: sales_organizations,
            type: 'sales_org'
          }"
        ></ng-container>
      </div>
    </mat-menu>
  </ng-container>

  <!-- Customer Organization chip -->
  <ng-container *ngIf="customer_org">
    <button
      mat-icon-button
      [matMenuTriggerFor]="customerOrgMenu"
      class="state-chip-button"
      (menuOpened)="customerOrgMenuClosed = false"
      (menuClosed)="customerOrgMenuClosed = true"
    >
      <app-company-logo
        [activeOrganizationType]="customer_org.type"
        [orgId]="customer_org.id"
        [assetUrl]="customer_org.logo"
      ></app-company-logo>
      <div class="state-chip-text">
        <span>{{ customer_org.name }}</span>
      </div>
      <mat-icon *ngIf="customerOrgMenuClosed">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="!customerOrgMenuClosed">arrow_drop_up</mat-icon>
    </button>
    <mat-menu #customerOrgMenu="matMenu" backdropClass="orgMainMenu">
      <div mat-menu-item class="extended-menu-item" disabled="true">
        <div mat-card-avatar class="extended-menu-card-avatar">
          <app-company-logo
            [activeOrganizationType]="customer_org.type"
            [orgId]="customer_org.id"
            [assetUrl]="customer_org.logo"
          ></app-company-logo>
        </div>
        <div class="extended-menu-text">
          <span class="secondary-span">CUSTOMER ORGANIZATION</span>
          <span class="main-span">{{ customer_org.name }}</span>
        </div>
      </div>

      <button mat-menu-item (click)="selectOrganization(customer_org)">
        <mat-icon>dashboard</mat-icon>Dashboard
      </button>
      <button
        *ngIf="customer_organizations && customer_organizations.length > 1"
        mat-menu-item
        [matMenuTriggerFor]="customerOrgSwitch"
      >
        <mat-icon>cached</mat-icon>Switch organization
      </button>
      <button
        mat-menu-item
        class="extended-menu-card-item-leave"
        (click)="selectOrganization(sales_org)"
        *ngIf="sales_org"
      >
        <mat-icon>logout</mat-icon>Leave active customer
      </button>
    </mat-menu>

    <!-- Active CustomerOrg switch -->
    <mat-menu #customerOrgSwitch="matMenu" yPosition="below">
      <div class="organization-card-wrapper">
        <ng-container
          [ngTemplateOutlet]="organizationItemList"
          [ngTemplateOutletContext]="{
            orgs: customer_organizations,
            type: 'customer_org'
          }"
        ></ng-container>
      </div>
    </mat-menu>
  </ng-container>
  <div class="state-chip-divider"></div>

  <!-- User Organization chip -->
  <ng-container *ngIf="user">
    <div *ngIf="inviteCount$ | async as inviteCount" class="badge-notification">
      <p>{{ inviteCount }}</p>
    </div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
      class="state-chip-button"
      (menuOpened)="userMenuClosed = false"
      (menuClosed)="userMenuClosed = true"
    >
      <div class="imageBadgeContainer">
        <img [src]="user.metadata.picture" />
      </div>
      <div class="state-chip-text">
        <span>{{ user.name }}</span>
      </div>
      <mat-icon *ngIf="userMenuClosed">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="!userMenuClosed">arrow_drop_up</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu" backdropClass="userMainMenu">
      <div mat-menu-item class="extended-menu-item" disabled="true">
        <div
          mat-card-avatar
          class="extended-menu-logo"
          [style.background-image]="'url(' + user.metadata.picture + ')'"
          [style.background-size]="'cover'"
        ></div>
        <div class="extended-menu-text">
          <span class="main-span">{{ user.name }}</span>
          <span class="secondary-span">{{ user.email }}</span>
        </div>
      </div>
      <button
        mat-menu-item
        class="menu-option"
        (click)="doAction('navigateTo', routeId.INVITE_PAGE)"
      >
        <div>
          Invites
          <div
            *ngIf="inviteCount$ | async as inviteCount"
            class="badge-notification badge-notification-menu-item"
          >
            <p>{{ inviteCount }}</p>
          </div>
        </div>
        <div class="imageBadgeContainer">
          <mat-icon>group</mat-icon>
        </div>
      </button>
      <ng-container
        *ngIf="contentfulUserMenuButtons$ | async as contentfulButtons"
      >
        <button
          *ngIf="
            getElementByNameTag(
              contentfulButtons.value,
              'userMenuSupportBtn'
            ) as supportBtn
          "
          mat-menu-item
          class="menu-option"
          (click)="doAction('navigateTo', supportBtn?.target)"
        >
          {{ supportBtn?.content }} <mat-icon>open_in_new</mat-icon>
        </button>
      </ng-container>
      <button
        mat-menu-item
        class="menu-option"
        (click)="doAction('navigateTo', eulaUrl)"
      >
        EULA <mat-icon>open_in_new</mat-icon>
      </button>
      <button
        mat-menu-item
        class="menu-option"
        (click)="$event.stopPropagation(); selectUnit.open()"
      >
        Unit System
        <mat-select [(value)]="preferredUnitSystem" #selectUnit>
          <mat-option
            value="metric"
            (click)="doAction('setUnitSystem', 'metric')"
          >
            Metric
          </mat-option>
          <mat-option
            value="imperial"
            (click)="doAction('setUnitSystem', 'imperial')"
            >Imperial</mat-option
          >
        </mat-select>
      </button>
      <button
        mat-menu-item
        class="menu-option"
        (click)="
          auth.logout({
            logoutParams: {
              returnTo: logoutPath
            }
          })
        "
      >
        Log out <mat-icon>logout</mat-icon>
      </button>
    </mat-menu>
  </ng-container>
</div>

<ng-template #organizationItemList let-orgs="orgs" let-type="type">
  <div
    class="organization-card"
    *ngFor="let org of orgs"
    (click)="selectOrganization(org)"
  >
    <div class="row">
      <div class="col-2 extended-menu-avatar" mat-card-avatar disabled="true">
        <app-company-logo
          [orgId]="org.id"
          [assetUrl]="org.logo"
        ></app-company-logo>
      </div>
      <div class="col-9 row extended-menu-text" disabled="true">
        <span *ngIf="org.type; else dashboard" class="col-12 secondary-span">{{
          org.type.toUpperCase().replaceAll('_', ' ')
        }}</span>
        <ng-template #dashboard>
          <span>ORGANIZATION</span>
        </ng-template>
        <span class="col-12 main-span">{{ org.name }}</span>
      </div>
      <div class="col-1 extended-menu-radio_button">
        <mat-icon>
          {{
            (type === 'customer_org' ? customer_org : sales_org)?.id === org.id
              ? 'radio_button_checked'
              : 'radio_button_unchecked'
          }}
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>
