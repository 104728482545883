import { IGuiButtonInput } from '../types/button-gui-input';

export const loginDialogButtons: IGuiButtonInput[] = [
  {
    text: 'LOG IN',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
    background:
      'linear-gradient(75deg, rgba(57,86,146,1) 0%, rgba(33,49,83,1) 100%)',
  },
  {
    text: 'SIGN UP',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
  },
];

export const dialogButtons: IGuiButtonInput[] = [
  {
    text: 'Cancel',
    background: 'transparent',
    textColor: 'black',
  },
  {
    text: 'Continue',
  },
];

export const projectDataOuterButtons: IGuiButtonInput[] = [
  {
    text: 'BACK',
    textColor: 'white',
    background: '#212529',
    icon: 'keyboard_arrow_left',
    iconPosition: 'inside',
    iconAlignment: 'left',
  },
  {
    text: 'NEXT',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
  },
  {
    text: 'Pallet Report',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
    background:
      'linear-gradient(75deg, rgba(57,86,146,1) 0%, rgba(33,49,83,1) 100%)',
  },
  {
    text: 'Download UR file',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
    background:
      'linear-gradient(75deg, rgba(57,86,146,1) 0%, rgba(33,49,83,1) 100%)',
  },
  {
    text: 'Request Simulation',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
  },
  // Logged in
  {
    text: 'NEXT',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
  },
  // Not logged in
  {
    text: 'NEXT',
    textColor: 'white',
    icon: 'keyboard_arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'right',
    background: '#212529',
  },
];

export const projectDataInnerButtons: IGuiButtonInput[] = [
  {
    text: 'Column stack',
    textColor: 'black',
    icon: 'view_module',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: '#D0D0D0',
  },
  {
    text: 'Rotate stack',
    textColor: 'black',
    icon: 'rotate_90_degrees_ccw',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: '#D0D0D0',
  },
  {
    text: 'Mirror vertical',
    textColor: 'black',
    icon: 'vertical_align_center',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: '#D0D0D0',
  },
  {
    text: 'Mirror horizontal',
    textColor: 'black',
    icon: 'vertical_align_center',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: '#D0D0D0',
  },
  // Box padding
  {
    text: '2mm',
    textColor: 'black',
    icon: 'box_padding',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: 'white',
  },
  // Pallet overhang ends
  {
    text: '2mm',
    textColor: 'black',
    icon: 'launch',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: 'white',
  },
  // Pallet overhang sides
  {
    text: '2mm',
    textColor: 'black',
    icon: 'launch',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: 'white',
  },
  {
    icon: 'arrow_left',
    iconPosition: 'inside',
    iconAlignment: 'center',
    background: '#E03131',
  },
  {
    icon: 'arrow_right',
    iconPosition: 'inside',
    iconAlignment: 'center',
    background: '#E03131',
  },
  {
    text: '2mm',
    textColor: 'black',
    icon: 'launch',
    iconPosition: 'inside',
    iconAlignment: 'left',
    background: 'white',
  },
];
