import { ModelType } from './model-type';
import { Transform } from './transform';
import { Vector3 } from './vector3';

export class GeneratorParams {
  name: string;
  file: File;
  type: ModelType;
  transforms: Transform[];
  boundingBox: Vector3;
}
