import { reduce } from 'lodash-es';

export interface IHwSwType {
  id: string;
  label: string;
  name: string;
  metadata?: any;
  type?: string;
  public?: boolean;
  created_at?: string;
  updated_at?: string;
  hw_sw_type?: {
    name: string;
  };
  organization_id: string;
  organization_hw_types?: {
    id: string;
    organization_id: string;
    organization?: {
      name: string;
    };
  }[];
}
export class HwSwTypeMap {
  [id: string]: IHwSwType;
  constructor(types: IHwSwType[]) {
    reduce(
      types,
      (result, type) => {
        result[type.id] = type;
        return result;
      },
      this
    );
  }
}
