import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import {
  EditCustomer,
  IUpdateCustomerFields,
} from '../edit-customer/edit-customer';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.scss'],
})
export class EditCustomerDialogComponent implements OnInit {
  organization$: Observable<DataRequestState<ApiOrganization>>;
  loading: boolean;

  shouldOnlyShowGuiForChangingLogo: boolean = false;

  editCustomer = new EditCustomer(this.organizationApi, this.assetApi);

  constructor(
    private organizationApi: OrganizationApiService,
    private assetApi: AssetApiService,
    private notify: NotificationService,
    private errorHandler: ErrorHandlerService,
    public dialogRef: MatDialogRef<EditCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        orgId: string;
        isChangingLogo: boolean;
        successHandler?: () => void;
        isBackoffice?: boolean;
      };
    }
  ) {}

  ngOnInit(): void {
    this.shouldOnlyShowGuiForChangingLogo = this.input.data.isChangingLogo;

    this.organization$ = this.editCustomer
      .makeFetchOrganizationObservable(this.input.data.orgId)
      .pipe(toRequestState());
  }

  public updateOrganization(
    org: ApiOrganization,
    fields: IUpdateCustomerFields
  ): void {
    this.loading = true;

    if (this.input.data.isBackoffice) {
      this.dialogRef.close(fields.logo);
      this.loading = false;
    } else {
      this.editCustomer.updateOrganization(org.id, fields).subscribe({
        next: () => {
          this.loading = false;
          this.saved(fields.name);
        },
        error: (err) => {
          this.loading = false;
          this.errorHandler.handleError(err);
        },
      });
    }
  }

  didClickClose() {
    this.dialogRef.close();
  }

  private saved(name: string): void {
    this.notify.showSuccess(`The organization ${name} was updated!`);
    if (this.input.data.successHandler) {
      this.input.data.successHandler();
    }
    this.dialogRef.close();
  }
}
