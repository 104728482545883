import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject, concat, finalize, takeUntil, tap } from 'rxjs';
import { Project } from 'src/app/models_new/classes/project';
import { IExportImportResult } from 'src/app/models_new/types/export-import-result';
import { IPallyFileType } from 'src/app/models_new/types/pally-file-type';
import { ExportImportService } from 'src/app/services/export-import.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'app-prompt-upload',
  templateUrl: './prompt-upload.component.html',
  styleUrls: ['./prompt-upload.component.scss'],
})
export class PromptUploadComponent implements OnDestroy {
  @ViewChild('file') fileClick: ElementRef;
  fileName: string;
  file: File;
  fileContent: any;
  valid: boolean;
  importedJSON: IPallyFileType;

  result: {
    breaking: IExportImportResult[];
    major: IExportImportResult[];
    minor: IExportImportResult[];
  } = {
    breaking: [],
    major: [],
    minor: [],
  };

  suggestedAction: string;

  destroy$: Subject<boolean> = new Subject<boolean>();
  stopValidation$: Subject<boolean> = new Subject<boolean>();

  project: Project;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PromptUploadComponent>,
    private validator: ValidatorService,
    private exportImportService: ExportImportService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  uploadFile(): void {
    if (!this.project) {
      this.notificationService.showError('Please import a pattern first!');
      return;
    }
    this._bottomSheetRef.dismiss(this.project);
  }

  cancelUpload() {
    this._bottomSheetRef.dismiss();
  }

  onFileChange(event) {
    this.suggestedAction = null;
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.fileName = this.file.name;
      this.fileContent = fileReader.result;
      this.valid = false;
      this.validateImport(this.fileContent);
    };
    fileReader.readAsText(this.file);
  }

  uploadSectionClick() {
    this.fileClick.nativeElement.value = '';
    this.fileClick.nativeElement.click();
  }

  private validateImport(input: string) {
    let importedJSON;
    try {
      importedJSON = JSON.parse(input) as IPallyFileType;
    } catch (err) {
      this.result.breaking = [err];
      this.notificationService.showError(
        'Please import a Pally Pallet Builder export file!'
      );
      return;
    }

    if (importedJSON) {
      this.result.breaking = [];
      this.result.major = [];
      this.result.minor = [];

      this.validator.noOfValidators = 3;

      concat(
        this.validator.validateImportKeys(importedJSON as IPallyFileType),
        this.validator.validateImportTypes(importedJSON as IPallyFileType),
        this.validator.validateImportValues(importedJSON as IPallyFileType)
      )
        .pipe(
          takeUntil(this.destroy$ || this.stopValidation$),
          tap((res) => {
            this.validator.proceed(); // Adds progress

            res
              .filter((f) => f.type === 0)
              .forEach((err) => this.result.breaking.push(err));
            res
              .filter((f) => f.type === 1)
              .forEach((err) => this.result.major.push(err));
            res
              .filter((f) => f.type === 2)
              .forEach((err) => this.result.minor.push(err));
          }),
          finalize(() => {
            this.validator.validationProgress$.next(0);
            this.importedJSON = importedJSON;

            if (this.result.breaking.length) {
              this.suggestedAction =
                'Please fix shown errors before re-importing project!';
              this.valid = false;
            } else {
              this.valid = true;
              this.initiateProject(this.importedJSON as IPallyFileType);
            }
          })
        )
        .subscribe({
          next: () => {
            if (this.result.breaking.length || this.result.major.length) {
              // Stop the validation
              this.stopValidation$.next(true);
            }
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }

  initiateProject(importedJSON: IPallyFileType) {
    this.project = this.exportImportService.mapToProjectFormat(
      importedJSON,
      'import-component'
    );
    console.debug('Imported project: ', this.project);
  }
}
