import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../../../services/google-analytics-service.service';
import { OpenSimService } from '../open-sim.service';
import { SupportWidgetService } from 'src/app/services/support-widget.service';

@Component({
  selector: 'app-fast-track-landing',
  templateUrl: './fast-track-landing.component.html',
  styleUrls: ['./fast-track-landing.component.scss'],
})
export class FastTrackLandingComponent implements OnInit, OnDestroy {
  contentfulContent$: Observable<DataRequestState<IInfoCard>>;
  pagesPath = pagesPATH;
  logoutPath = window.location.href;
  environment = environment;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(_: any) {
    if (window.scrollY > 2) {
      document.getElementById('main-toolbar')?.classList.add('scrolled');
    } else {
      document.getElementById('main-toolbar')?.classList.remove('scrolled');
    }
  }

  constructor(
    private infoApi: InfoApiService,
    public auth: AuthService,
    private gas: GoogleAnalyticsService,
    public openSimService: OpenSimService,
    private supportWidgetService: SupportWidgetService
  ) {}

  ngOnInit(): void {
    this.contentfulContent$ = this.infoApi
      .getLandingPageInfo()
      .pipe(take(1), toRequestState());

    this.supportWidgetService.hideSupportButton();
  }

  ngOnDestroy(): void {
    this.supportWidgetService.resetSupportButtonStyling();
  }

  analyticsTrySimulations(): void {
    this.gas.addEvent(
      'Home - MyRobot.cloud - Try Out Simulation CTA - Click',
      null,
      this.gas.getLastParam()
    );
  }
}
