export interface IApiOrganizationSubscription {
  id: string;
  subscription_type: string;
  subscription_data: any;
  trial: boolean;
  converted_from_trial: boolean;
  valid_to: Date;
  created_at: Date;
  updated_at: Date;
}

export class ApiOrganizationSubscription
  implements IApiOrganizationSubscription
{
  id: string;
  subscription_type: string;
  subscription_data: any;
  trial: boolean;
  converted_from_trial: boolean;
  valid_to: Date;
  created_at: Date;
  updated_at: Date;

  constructor(organization: IApiOrganizationSubscription) {
    for (const d in organization) {
      if (organization.hasOwnProperty(d)) {
        this[d] = organization[d];
      }
    }
  }
}
