<div class="software-config-wrapper" *ngIf="config$ | async as config">
  <div [ngSwitch]="config.viewParent">
    <h1 *ngSwitchCase="'hardware'">Hardware configuration</h1>
    <h1 *ngSwitchCase="'strategy'">Strategy configuration</h1>
    <h1 *ngSwitchCase="'summary'">Summary and Exports</h1>
    <h1 *ngSwitchDefault>Robot configurator</h1>
  </div>
  <div class="stepper-slot">
    <mat-stepper
      *ngIf="config.index > -1"
      linear
      #stepper
      [selectedIndex]="config.index"
    >
      <!-- Define steps-->
      <mat-step
        [optional]="true"
        [label]="step.header"
        [editable]="true"
        [completed]="i < config.index"
        *ngFor="let step of config.steps; index as i"
      >
      </mat-step>

      <!-- mat-step icon overrides. -->
      <ng-template matStepperIcon="edit">
        <svg viewBox="0 0 25 25" class="mat-step-svg-icon-state-edit">
          <circle
            class="ring"
            cx="12.5"
            cy="12.5"
            r="10"
            fill="transparent"
            stroke-width="5"
          ></circle>
        </svg>
      </ng-template>
      <ng-template matStepperIcon="number">
        <svg viewBox="0 0 25 25" class="mat-step-svg-icon-state-number">
          <circle
            class="ring"
            cx="12.5"
            cy="12.5"
            r="6"
            fill="transparent"
            stroke-width="1"
          ></circle>
        </svg>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="error">
        <mat-icon>warning</mat-icon>
      </ng-template>
    </mat-stepper>
  </div>
  <div class="main" *ngIf="fields$ | async as fields">
    <div class="left">
      <ng-template #overview>
        <app-config-overview
          [hardwareTouched]="config.hardwareTouched"
          [strategyTouched]="config.strategyTouched"
        ></app-config-overview>
      </ng-template>
      <router-outlet
        *ngIf="config.viewChild !== 'advanced'; else overview"
      ></router-outlet>
    </div>
    <div class="right" *ngIf="config.viewParent !== 'summary'">
      <app-three-view [mode]="ThreeHandlerMode.RobotConfig"></app-three-view>
      <ng-template #showOverview>
        <ng-template #strategyButton>
          <button
            *ngIf="!config.strategyTouched"
            mat-flat-button
            (click)="saveThenRoute(fields, 'strategy/1')"
            mat-button
          >
            Strategy configuration<mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </ng-template>
        <button
          *ngIf="!config.hardwareTouched; else strategyButton"
          mat-flat-button
          (click)="saveThenRoute(fields, 'hardware/1')"
          mat-button
        >
          Hardware configuration<mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </ng-template>
      <ng-container *ngIf="config.index > -1; else showOverview">
        <button
          mat-flat-button
          *ngIf="config.steps[config.index]?.previous as previous"
          (click)="saveThenRoute(fields, previous)"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>Previous
        </button>
        <button
          mat-flat-button
          *ngIf="config.steps[config.index]?.next as next"
          (click)="saveThenRoute(fields, next)"
          mat-button
        >
          Next<mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </ng-container>
      <button
        mat-flat-button
        (click)="saveThenRoute(fields, 'summary')"
        mat-button
      >
        Summary and exports<mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
