import { UntypedFormGroup } from '@angular/forms';
import { Field } from './field';

type groupOrField = Field | SimulationApiGroup;

export class SimulationApiGroup {
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  children: Field[] | SimulationApiGroup[] | any = [];
  guiFields: Field[] = [];
  text: {
    name: string;
  } = {
    name: 'unset',
  };
  id: string;
  parentId: string;

  constructor(name: string, controls?: groupOrField[]) {
    this.text.name = name;
  }

  addChild(f: Field | SimulationApiGroup) {
    if (f instanceof Field) {
      this.formGroup.addControl(f.text.name, f.formControl);
      this.guiFields.push(f);
    }
    if (f instanceof SimulationApiGroup) {
      this.formGroup.addControl(f.text.name, f.formGroup);
    }

    this.children.push(f);
  }
}
