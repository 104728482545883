<div (window:resize)="onResize()" class="mainEditor">
  <div class="left">
    <h4 class="tagline">UPLOAD COMPONENT</h4>
    <h2 class="title">Edit Component</h2>

    <div style="margin-top: 16px">
      <div class="horizontal_container">
        <p>Radians</p>
        <mat-slide-toggle
          class="toggle"
          [checked]="shouldUseRad"
          (toggleChange)="updateShouldUseRad()"
          color="primary"
          #toggleRad
        ></mat-slide-toggle>
      </div>
      <div class="horizontal_container">
        <p>Bounding box</p>
        <mat-slide-toggle
          class="toggle"
          [checked]="showBoundingBox"
          (toggleChange)="updateShowBoundingBox()"
          color="primary"
          #toggleBounding
        ></mat-slide-toggle>
      </div>
      <div class="horizontal_container">
        <p>Grid</p>
        <mat-slide-toggle
          class="toggle"
          [checked]="showGrid"
          (toggleChange)="updateShowGrid()"
          color="primary"
          #toggleGrid
        ></mat-slide-toggle>
      </div>
    </div>

    <div
      style="margin-top: 8px"
      *ngFor="
        let transform of modelUpload.generatorParams.transforms;
        let transformIndex = index
      "
    >
      <h2 *ngIf="modelUpload.generatorParams.transforms.length > 1">
        Point {{ transformIndex + 1 }}
      </h2>
      <div *ngFor="let type of transform.vectors; let vectorPosition = index">
        <div style="display: inline-flex">
          <p style="margin-bottom: 12px">{{ type.type }}</p>
        </div>
        <div class="grid">
          <mat-form-field
            class="form-field"
            appearance="outline"
            *ngFor="
              let vector of entries[transformIndex][vectorPosition];
              let transformTypeIndex = index
            "
          >
            <mat-label>{{
              transformValueNames[vectorPosition][transformTypeIndex]
            }}</mat-label>
            <input
              class="{{ type.type }}"
              (input)="
                inputChange(vectorPosition, vector.name, $event, transformIndex)
              "
              matInput
              step="{{ type.type !== 'Rotation' ? '.01' : rotationStep }}"
              type="number"
              value="{{
                type.type === 'Rotation' && !shouldUseRad
                  ? convertToDeg(vector.value)
                  : vector.value
              }}"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="horizontal_container">
      <button
        class="page_button stroked_button"
        (click)="modelUpload.backClick()"
        mat-stroked-button
      >
        <mat-icon class="icon mat-icon material-icons mat-icon-no-color">
          arrow_back
        </mat-icon>
        BACK
      </button>
      <button
        class="page_button filled_button"
        (click)="modelUpload.nextClick()"
        mat-flat-button
      >
        NEXT
        <mat-icon class="icon mat-icon material-icons mat-icon-no-color">
          arrow_forward
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="right" id="container"></div>
</div>
