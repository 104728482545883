import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { DataRequestState } from './model';
import { loadingState, loadedState, errorState } from './builders';

export function toRequestState<T>(): (
  source: Observable<T>
) => Observable<DataRequestState<T>> {
  return (source: Observable<T>) =>
    source.pipe(
      map((result) => loadedState<T>(result)),
      startWith(loadingState<T>()),
      catchError((err) => of(errorState<T>(err)))
    );
}
