export const pallyFileShouldHaveKeys = {
  projectData: [
    'name',
    'description',
    'dimensions',
    'productDimensions',
    'maxGrip',
    'labelOrientation',
    'labelDirection',
    'layerTypes',
    'layers',
    'guiSettings',
    'dateModified',
  ],
  dimensions: ['length', 'width', 'height'],
  productDimensions: ['width', 'height', 'length', 'weight'],
  guiSettings: [
    'boxPadding',
    'units',
    'overhangSides',
    'overhangEnds',
    'altLayout',
    'stackingMethod',
    'baseIndex',
  ],
  allLayersTypes: ['name', 'class'],
  layer: ['name', 'class', 'pattern'],
  shimPaper: ['name', 'class', 'height'],
  pattern: ['x', 'y', 'r'],
};

export const pallyFileCouldHaveKeys = {
  projectData: ['maxGripAuto'],
  dimensions: ['palletHeight'],
  productDimensions: [],
  guiSettings: ['PPB_VERSION_NO'],
  allLayersTypes: ['pattern', 'height'],
  layer: ['altApproach', 'approach', 'altPattern'],
  shimPaper: [],
  pattern: ['g', 'f'],
};
