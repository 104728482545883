import {
  Directive,
  Input,
  OnDestroy,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MarketingTagID } from '../models_new/config/marketing-tags';
import { GoogleAnalyticsService } from '../services/google-analytics-service.service';

@Directive({
  selector: '[appAnalytics]',
})
export class GoogleAnalyticsDirective implements OnChanges, OnDestroy {
  @Input() appAnalytics: MarketingTagID;

  event = (() => {
    this.gas.addEvent(this.appAnalytics, null, this.gas.getLastParam());
  }).bind(this);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private gas: GoogleAnalyticsService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appAnalytics?.currentValue) {
      this.elementRef.nativeElement.addEventListener('click', this.event);
    }
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('click', this.event);
  }
}
