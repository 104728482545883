import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Rectangle, RectangleLocation } from '@rocketfarm/cartesian-rectangle';
import { PalletLayerCanvas } from './pallet-layer-canvas';

export interface Dimensions {
  palletLength: number;
  palletWidth: number;
  productHeight: number;
  productWidth: number;
  productLength: number;
}

@Component({
  selector: 'app-pallet-canvas',
  templateUrl: './pallet-canvas.component.html',
  styleUrls: ['./pallet-canvas.component.scss'],
})
export class PalletCanvasComponent implements AfterViewInit, OnDestroy {
  private palletLayerCanvas: PalletLayerCanvas;

  @Input()
  boxes: RectangleLocation[];

  @Input()
  frame: Rectangle;

  @ViewChild('palletLayerCanvas')
  private palletLayerCanvasElement: ElementRef<HTMLCanvasElement>;

  constructor() {}

  ngAfterViewInit() {
    this.palletLayerCanvas = new PalletLayerCanvas(
      this.frame,
      this.palletLayerCanvasElement.nativeElement,
      PalletLayerCanvas.defaultBoxColor,
      '#A8A5A5'
    );
    this.updateBoxes(this.boxes);
  }

  updateBoxes(boxes: RectangleLocation[]) {
    boxes.map((box) => this.palletLayerCanvas.addRectangleLocation(box, 10));
  }

  ngOnDestroy() {
    this.palletLayerCanvas.clear();
  }
}
