import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { IBreadcrumb } from 'src/app/models_new/types/breadcrumb';
import { BreadcrumbService } from 'src/app/services/breadcrumb-service.service';
import { combineLatest } from 'rxjs';

type BreadcrumbStyle = 'REGULAR' | 'COMPACT';

@Component({
  selector: 'app-breadcrumb-nav',
  templateUrl: './breadcrumb-nav.component.html',
  styleUrls: ['./breadcrumb-nav.component.scss'],
})
export class BreadcrumbNavComponent {
  @Input() breadcrumbs: IBreadcrumb[];

  /**
   * When set to true, the customer organization is ommited from the breadcrumb.
   * Defaults to false.
   */
  @Input() onlyForSalesOrganization: boolean = false;

  /**
   * When set to true, include the last item in the breadcrumb array. Being this component.
   * Defaults to false.
   */
  @Input() excludeSelf: boolean = false;

  /**
   * The visual type of breadcrumb.
   * Defaults to COMPACT.
   */
  @Input() style: BreadcrumbStyle = 'COMPACT';

  /**
   * When set to true, ommits /customers from the breadcrumb.
   * This is prefered if the home item redirects to /customers.
   */
  @Input() excludeCustomersBreadcrumb: boolean = false;

  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    this.breadcrumbs$ = combineLatest([
      this.breadcrumbService.organizationBreadcrumbs$,
      this.breadcrumbService.breadcrumbs$,
    ]).pipe(
      map(([orgBreadcrumbs, navBreadcrumbs]) => {
        let breadcrumbs: IBreadcrumb[] = this.excludeSelf
          ? navBreadcrumbs.slice(0, -1)
          : navBreadcrumbs; // Remove last item if excludeSelf equals true

        // Exclude breadcrumb for customer_org if page is onlyForSalesOrganization
        if (this.onlyForSalesOrganization && orgBreadcrumbs.length === 2) {
          breadcrumbs = [orgBreadcrumbs[0]].concat(breadcrumbs);
        } else {
          breadcrumbs = orgBreadcrumbs.concat(breadcrumbs);
        }

        if (this.excludeCustomersBreadcrumb) {
          breadcrumbs = breadcrumbs.filter(
            (breadcrumb) =>
              breadcrumb.label != 'Customer' ||
              breadcrumb.path != '/' + pagesPATH.CUSTOMERS
          );
        }

        return breadcrumbs;
      })
    );
  }

  goHome() {
    this.router.navigate([pagesPATH.CUSTOMERS]);
  }

  crumbClicked(crumb: IBreadcrumb) {
    const path = crumb.routeTo || crumb.path;

    if (path) {
      if (Array.isArray(path)) {
        this.router.navigate(path);
      } else {
        this.router.navigate([path]);
      }
    }
  }
}
