<ng-container *ngIf="filteredData$ | async as filtered_data">
  <ng-container *ngIf="filtered_data.length > 0; else empty">
    <ng-container
      *ngFor="
        let data of filtered_data
          | slice: page * numItemsInPage:(page + 1) * numItemsInPage
      "
    >
      <ng-container
        [ngTemplateOutlet]="cardRef"
        [ngTemplateOutletContext]="{ $implicit: data }"
      >
      </ng-container>
    </ng-container>

    <mat-paginator
      [length]="filtered_data.length"
      [pageSize]="8"
      [pageSizeOptions]="[3, 5, 8, 13]"
      (page)="didChangePage($event)"
    ></mat-paginator>
  </ng-container>

  <ng-template #empty>
    <app-empty></app-empty>
  </ng-template>
</ng-container>
