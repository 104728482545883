import { Component, Input, Output } from '@angular/core';
import { IOrganizationInviteData } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { mapPrivileges } from 'src/app/utils/roles';
import { EventEmitter } from '@angular/core';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-card-invitation',
  templateUrl: './card-invitation.component.html',
  styleUrls: ['./card-invitation.component.scss'],
})
export class CardInvitationComponent {
  @Input() invite: IOrganizationInviteData;
  @Input() showButtons: boolean = true;
  @Output() didAccept = new EventEmitter<IOrganizationInviteData>();
  @Output() didReject = new EventEmitter<IOrganizationInviteData>();

  constructor(
    private organizationApi: OrganizationApiService,
    private notification: NotificationService
  ) {}

  getRolesForType(types: string[]): string {
    let result = '';
    for (let i = 0; i < types.length; i++) {
      result += mapPrivileges(types[i]);
      if (i < types.length - 1) {
        result += ', ';
      }
    }
    return result;
  }

  acceptInvite(invite: IOrganizationInviteData) {
    this.organizationApi
      .acceptOrganizationInviteForUser(invite.id)
      .subscribe((result) => {
        if (result && result.id) {
          this.notification.showSuccess(
            `You are now a member of the ${invite.organization.name} organization!`
          );
          this.didAccept.emit(invite);
        } else {
          this.notification.showError(
            'An error occured while accepting the invite'
          );
        }
      });
  }

  rejectInvite(invite: IOrganizationInviteData) {
    this.notification
      .deletePrompt('Delete', 'invitation', [
        'Invitation from: ' + invite.organization.name,
      ])
      .afterDismissed()
      .pipe(
        filter(Boolean),
        switchMap((_) =>
          this.organizationApi.rejectOrganizationInviteForUser(invite.id)
        )
      )
      .subscribe((result) => {
        if (result && result.id) {
          this.notification.showMessage(
            `The invitation from ${invite.organization.name} has been rejected.`
          );
          this.didReject.emit(invite);
        } else {
          this.notification.showError(
            'An error occured while rejecting the invite'
          );
        }
      });
  }
}
