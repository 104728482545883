import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, skipWhile } from 'rxjs/operators';
import { PalletEditorService } from '../../services/pallet-editor.service';
import { ApiPattern } from './api-models/ApiPattern';
import { IApiProduct } from './api-models/ApiProduct';
import { ExportImportService } from 'src/app/services/export-import.service';
import { defaultApiPattern } from '../config/default/api-default/default-api-pattern';
import { RectangleLocation } from '@rocketfarm/packing';
import { MagicStackService } from 'src/app/services/magic-stack.service';
import { BoxSorting } from 'src/app/components/patterns/pattern/pattern-edit/boxsorting';
import { Project } from './project';
import { StackingMethod } from '../enums/stacking-method';
import { BasePatternConfig } from '../types/base-pattern-config';
import { ObjectUtils } from 'src/app/utils/object';
import { IProjectData } from '../types/project-data';
import { ProjectData } from './project-data';
import { LabelOrientation } from '../enums/label-orientation';
import { settings } from '../config/application-settings';
import { AlternativeLayoutService } from 'src/app/services/alternative-layout.service';

export class PatternGenerator {
  private basePatternConfig: BasePatternConfig;
  private stackingMethod: StackingMethod;
  private noOfLayers: number = settings.defaultNoOfLayers;
  private projectData: IProjectData;
  private product: IApiProduct;
  private regenPallet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public project$: Observable<Project>;
  public basePatternIndexes$ = new BehaviorSubject<number[]>([]);
  public selectedBasePatternIndex: number = 0;

  constructor(
    private palletEditor: PalletEditorService,
    private exportImport: ExportImportService,
    private magicStackService: MagicStackService,
    private altLayoutService: AlternativeLayoutService
  ) {
    this.project$ = this.regenPallet$.pipe(
      skipWhile((regen) => !regen),
      map((_regen: boolean) => {
        console.debug('Regenerating pallet');
        this.palletEditor.generateLayers(
          this.basePatternConfig.layer,
          this.stackingMethod,
          this.noOfLayers,
          this.basePatternConfig.project
        );
        this.basePatternConfig.project.palletAdvancedSettings.stackingMethod =
          this.stackingMethod;
        this.basePatternConfig.project.data.dateModified =
          new Date().toISOString();

        this.altLayoutService.selectedAltLayout$.next({
          selected: settings.defaultAltLayout,
          origin: 'pattern-generator-ts',
          project: this.basePatternConfig.project,
        });
        console.debug(this.basePatternConfig.project.pallets);
        return this.basePatternConfig.project;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  destroy(): void {
    this.regenPallet$.complete();
    this.basePatternIndexes$.complete();
  }

  public setNoOfLayers(noOfLayers: number): PatternGenerator {
    this.noOfLayers = noOfLayers;
    return this;
  }

  public setStackingMethod(stackingMethod: StackingMethod): PatternGenerator {
    this.stackingMethod = stackingMethod;
    return this;
  }

  public setSelectedBasePatternIndex(
    selectedBasePatternIndex: number
  ): PatternGenerator {
    this.selectedBasePatternIndex = selectedBasePatternIndex;
    return this;
  }

  public setProjectData(projectData: IProjectData): PatternGenerator {
    this.projectData = projectData;
    return this;
  }

  public setProduct(product: IApiProduct): PatternGenerator {
    this.product = product;
    return this;
  }

  public generateNewPattern(): void {
    const pattern = new ApiPattern(ObjectUtils.cloneObject(defaultApiPattern));

    if (this.product) {
      pattern.data.productDimensions.length = this.product.data.length;
      pattern.data.productDimensions.width = this.product.data.width;
      pattern.data.productDimensions.height = this.product.data.height;
      pattern.data.productDimensions.weight = this.product.data.weight;
    }

    const project = this.exportImport.mapToProjectFormat(
      pattern.data,
      'pattern-generator',
      /*
      Layers and pallets should not be protected on this import/re-mapping.
      If not, left pallet is cleared and not re-built.
      */
      false
    );

    project.data = new ProjectData(this.projectData);
    project.data.box.dimensions.length = this.product.data.length;
    project.data.box.dimensions.width = this.product.data.width;
    project.data.box.dimensions.height = this.product.data.height;
    project.data.box.weight = this.product.data.weight;

    const isLabelEnabled =
      this.projectData.box.label.orientation !== LabelOrientation.NULL;
    this.projectData.box.label.enabled = isLabelEnabled;

    project.palletView.palletViewSettings.showLabelOrientation = isLabelEnabled;
    project.palletView.palletViewSettings.labelOrientation =
      this.projectData.box.label.orientation;
    project.palletView.saveViewSettings();

    const bc = this.getNewBaseLayer(project);
    this.basePatternIndexes$.next(bc.basePatternsIndexes);

    // Select the first available pattern
    this.selectBasePattern(bc, this.selectedBasePatternIndex || 0);
    this.regenPallet$.next(true);
  }

  private getNewBaseLayer(project: Project): BasePatternConfig {
    if (
      project.getProjectData().getPalletData().overhang.ends ||
      project.getProjectData().getPalletData().overhang.sides
    ) {
      project.data.pallet.overhang.enabled = true;
    } else {
      project.data.pallet.overhang.enabled = true;
    }

    return this.palletEditor.initiateBaseLayer(project);
  }

  private selectBasePattern(bc: BasePatternConfig, basePatternIndex: any) {
    const selectedPatternCard: RectangleLocation[] =
      bc.basePatterns[basePatternIndex];

    const boxesToBoxSortingPattern = selectedPatternCard.map(
      this.magicStackService.rotatedRectangleLocationToPattern
    );

    const sorter = new BoxSorting(
      bc.options.box.getLength(),
      bc.options.box.getWidth(),
      false
    );

    bc.layer.boxes = sorter.sortLayerType(boxesToBoxSortingPattern);

    this.basePatternConfig = bc;
  }
}
