export const palletViewColors = {
  box: {
    default: '#dbbb95',
    front: 'blue',
    back: 'red',
    right: 'green',
    left: 'yellow',
  },
  selectedPallet: '#b33329',
  unselectedPallet: '#c5c5c5',
};
