<div class="ingress">
  You’ll find all of your configurations below. When you’re satisfied, you can
  export the combined simulation configuration file, the software/hardware
  configurations individually or download a simulation report.
</div>
<div class="simulations-buttons">
  <button
    (click)="exportSimulationConfig()"
    class="config-button"
    color="primary"
    mat-raised-button
  >
    <mat-icon>save_alt</mat-icon>&nbsp;Simulation configuration
  </button>
  <!-- TODO enable this button only for admin-->
  <!--button class="report-button" color="primary" mat-raised-button><mat-icon>save_alt</mat-icon>&nbsp;Simulation report</button-->
</div>
<div *ngIf="summary$ | async as summary" class="config-columns">
  <div class="hardware-config column">
    <div class="head">
      Hardware configuration
      <button class="column-button" (click)="exportScene()" mat-flat-button>
        <mat-icon>save_alt</mat-icon>&nbsp;EXPORT
      </button>
      <button routerLink="../hardware/1" class="column-button" mat-flat-button>
        EDIT
      </button>
    </div>
    <ng-container
      [ngTemplateOutlet]="summarySteps"
      [ngTemplateOutletContext]="{ summaries: summary.scene }"
    ></ng-container>
  </div>
  <div class="strategy-config column">
    <div class="head">
      Strategy configuration
      <button (click)="exportStrategy()" class="column-button" mat-flat-button>
        <mat-icon>save_alt</mat-icon>&nbsp;EXPORT
      </button>
      <button routerLink="../strategy/1" class="column-button" mat-flat-button>
        EDIT
      </button>
    </div>
    {{ summary.strategy[0].header }}
    <ng-container
      [ngTemplateOutlet]="summarySteps"
      [ngTemplateOutletContext]="{ summaries: summary.strategy }"
    ></ng-container>
  </div>
</div>

<ng-template #summarySteps let-summaries="summaries">
  <mat-accordion *ngFor="let stepSummary of summaries">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ stepSummary.header }}
        </mat-panel-title>
        <mat-panel-description>
          <!--helper text-->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item *ngFor="let field of stepSummary.fields">
          <span>{{ field.text.label }}:</span
          ><span
            ><b
              >&#160;{{
                field.options ? field.getValue()['label'] : field.getValue()
              }}</b
            ></span
          >
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
