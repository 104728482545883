import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { AssetType } from '../../classes/asset/asset';
import { PartType } from '../../enums/sim-config-part-type';
import { IAssetStoreLoadingConfig } from '../../types/asset/asset-store-loading-config';
import { settings } from '../application-settings';

const pallet_eur1_id = HwPartUtils.getPartAssetID(PartType.PALLET, 'EUR1');

export const newPalletViewAssetConfig: IAssetStoreLoadingConfig[] = [
  {
    id: pallet_eur1_id,
    urls: new Map<string, string>([
      [
        pallet_eur1_id,
        settings.pallyDescriptionsURL +
          'pally_descriptions/pallet/EUR/EUR1/urdf/EUR1.urdf',
      ],
    ]),
    type: AssetType.URDF,
  },
];
