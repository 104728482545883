import { Box } from '../models_new/classes/box';
import { Layer } from '../models_new/classes/layer';
import { GripperOrientation } from '../models_new/enums/gripper-orientation';
import { LayerApproach } from '../models_new/enums/layer-approach';
import { LayerType } from '../models_new/enums/layer-type';
import { IPallyBox, IPallyLayer } from '../models_new/types/pally-file-type';

export class PallyFileFormatter {
  static toProjectEnforcedOrientation(
    orientations: GripperOrientation[]
  ): GripperOrientation[] {
    return orientations.map((o: GripperOrientation) => {
      if (o === GripperOrientation.BACK_ALT) {
        o = GripperOrientation.BACK;
      }
      if (o === GripperOrientation.LEFT_ALT) {
        o = GripperOrientation.LEFT;
      }
      if (o === GripperOrientation.RIGHT_ALT) {
        o = GripperOrientation.RIGHT;
      }

      return o;
    });
  }

  static toProjectType(type: string): LayerType {
    if (type === LayerType.LAYER || type === LayerType.SHIMPAPER) {
      return type;
    } else {
      return LayerType.LAYER;
    }
  }

  static toPallyLayer(l: Layer): IPallyLayer {
    switch (l.type) {
      case LayerType.LAYER:
        return {
          name: l.name,
          class: l.type,
          pattern: l.boxes.map((b: Box) => this.toPallyBox(b)),
          altPattern: [],
          approach: l.approach !== undefined ? l.approach : LayerApproach.NULL,
          altApproach: LayerApproach.NULL,
        };
      case LayerType.SHIMPAPER:
        return {
          name: l.name,
          class: l.type,
          height: l.height,
        };
    }
  }

  static toPallyBox(b: Box): IPallyBox {
    return {
      x: b.position.x,
      y: b.position.y,
      r: b.rotation,
      g: b.gripper.enforcedOrientation,
      f: b.gripper.stopMultigrip ? 0 : 1,
    };
  }
}
