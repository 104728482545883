<!-- Regular style -->
<div *ngIf="style === 'REGULAR'" class="breadcrumb-wrapper">
  <button mat-icon-button (click)="goHome()">
    <mat-icon>home</mat-icon>
  </button>
  <div
    class="breadcrumbs"
    *ngFor="let c of breadcrumbs ? breadcrumbs : (breadcrumbs$ | async)"
  >
    <button mat-icon-button disabled>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button mat-button (click)="crumbClicked(c)">{{ c.label }}</button>
  </div>
</div>

<!-- Compact style -->
<div *ngIf="style === 'COMPACT'" class="breadcrumb-compact-wrapper">
  <div
    class="crum"
    *ngFor="
      let c of breadcrumbs ? breadcrumbs : (breadcrumbs$ | async);
      last as isLast
    "
  >
    <div *ngIf="!isLast" (click)="crumbClicked(c)">
      <span>{{ c.label }}</span>
    </div>
    <div *ngIf="isLast">
      <span>{{ c.label }}</span>
    </div>
    <span class="slashSpan" style="margin: 0 8px">/</span>
  </div>
</div>
