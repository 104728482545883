export interface CanvasStoreInfo {
  canvas: HTMLCanvasElement;
  parent: HTMLElement;
}

export class CanvasStore {
  private static cVMap: Map<string, CanvasStoreInfo> = new Map<
    string,
    CanvasStoreInfo
  >();

  public static getCanvas(id: string): HTMLCanvasElement {
    if (!CanvasStore.cVMap.has(id)) {
      return undefined;
    }

    return CanvasStore.cVMap.get(id).canvas;
  }

  public static getCanvasParent(id: string): HTMLElement {
    if (!CanvasStore.cVMap.has(id)) {
      return undefined;
    }

    return CanvasStore.cVMap.get(id).parent;
  }

  public static addCanvas(
    id: string,
    canvas: HTMLCanvasElement,
    parent: HTMLElement
  ): CanvasStoreInfo {
    const canvasInfo = { canvas: canvas, parent: parent } as CanvasStoreInfo;
    CanvasStore.cVMap.set(id, canvasInfo);
    return canvasInfo;
  }

  public static removeCanvas(id: string): CanvasStoreInfo {
    if (!CanvasStore.cVMap.has(id)) {
      return undefined;
    }

    const info = CanvasStore.cVMap.get(id);
    CanvasStore.cVMap.delete(id);
    return info;
  }
}
