import { Component } from '@angular/core';

@Component({
  selector: 'app-exit-button',
  templateUrl: './exit-button.component.html',
  styleUrls: ['./exit-button.component.scss'],
})
export class ExitButtonComponent {
  constructor() {}
}
