export class ModelType {
  name: string;
  pointCount: number;
  id: string;
  description: string;
  shouldOutputBoundingBox: boolean;

  constructor(
    name: string,
    id: string,
    pointCount: number,
    description: string,
    shouldOutputBoundingBox: boolean = false
  ) {
    this.name = name;
    this.description = description;
    this.pointCount = pointCount;
    this.id = id;
    this.shouldOutputBoundingBox = shouldOutputBoundingBox;
  }
}
