import { Box } from './box';
import { LayerType } from '../enums/layer-type';
import { ILayer } from '../types/layer';
import { ProjectData } from './project-data';
import { LayerApproach } from '../enums/layer-approach';
import { PalletPosition } from '../enums/pallet-position';
import { BehaviorSubject } from 'rxjs';
import { ILayerUpdate } from '../types/layer-update';
import { UpdateAction } from '../enums/update-action';
import { IBoxUpdate } from '../types/box-update';
import { merge } from 'rxjs';
import { LabelOrientator } from './label-orientator';

export class Layer implements ILayer {
  id: string;
  typeId: string;
  name: string;
  type: LayerType;
  boxes: Box[];
  height: number;
  weight: number;
  approach: LayerApproach;
  palletPosition: PalletPosition;
  layerPosition: number;
  zPosition: number;
  preserved: boolean;
  overrideBoxIds: boolean;

  update$ = new BehaviorSubject<ILayerUpdate>(null);

  constructor(l: {
    palletPosition: PalletPosition;
    type: LayerType;
    typeId: string;
    name?: string;
    boxes?: Box[];
    approach?: LayerApproach;
    height?: number;
    overrideBoxIds?: boolean;
  }) {
    this.type = l.type || null;
    this.boxes = l.type === LayerType.SHIMPAPER ? [] : l.boxes || [];
    this.weight = this.getWeight();

    this.approach = l.approach !== undefined ? l.approach : LayerApproach.NULL;
    this.overrideBoxIds = l.overrideBoxIds || false;

    this.setPalletPosition(l.palletPosition);
    this.setHeight(l.height);
    this.setTypeId(l.typeId);
    this.setName(l.name);

    if (this.boxes && this.boxes.length) {
      this.subscribeToBoxUpdates();
    }
  }

  subscribeToBoxUpdates() {
    const sources: BehaviorSubject<IBoxUpdate>[] = [];
    this.boxes.forEach((b: Box) => {
      sources.push(b.update$);
    });

    if (sources.length) {
      merge(...sources).subscribe((u: IBoxUpdate) => {
        if (u) {
          this.update(u.updateAction);
        }
      });
    }
  }

  update(updateAction: UpdateAction = UpdateAction.DEFAULT_LAYER_UPDATE) {
    this.update$.next({
      palletPosition: this.palletPosition,
      layerId: [this.id],
      updateAction: updateAction,
    });
  }

  preserve() {
    this.preserved = true;
  }

  unpreserve() {
    this.preserved = false;
  }

  /**
   * @param position: PalletPosition
   */
  setPalletPosition(position: PalletPosition) {
    this.palletPosition = position;
  }

  /**
   * @param uniqueNo: number
   */
  setId(uniqueNo: number): void {
    this.id = `${this.palletPosition}-${uniqueNo}`;
  }

  getTypeId(part: 'layer-no' | 'pallet-no'): number {
    let typeidasno = null;
    if (part === 'layer-no') {
      typeidasno = Number(this.typeId.split('-')[1]);
    }
    if (part === 'pallet-no') {
      typeidasno = Number(this.typeId.split('-')[3]);
    }

    return typeidasno;
  }

  /**
   * @param typeId: string
   */
  setTypeId(newTypeId: string): void {
    this.typeId = `${this.type}-${newTypeId}-pallet-${this.palletPosition}`;
  }

  /**
   * @param boxes: Box[]
   */
  setBoxes(boxes: Box[]): void {
    this.boxes = boxes;
    this.setAllBoxesZPosition();
  }

  /**
   * @param layerPosition: number
   */
  setLayerPosition(layerPosition: number): void {
    this.layerPosition = layerPosition;
  }

  getBottomZ(): number {
    return this.zPosition - this.height / 2;
  }

  getTopZ(): number {
    return this.zPosition + this.height / 2;
  }

  setZPosition(zPosition: number) {
    this.zPosition = zPosition;

    this.setAllBoxesZPosition();
  }

  setAllBoxesZPosition(): void {
    // set Z positions for all boxes
    if (this.boxes && this.boxes.length) {
      this.boxes.forEach((b: Box) => {
        b.setZ(this.zPosition);
      });
    }
  }

  /**
   * @param name: string
   * // Sets layer name
   */
  setName(name?: string): void {
    if (name) {
      this.name = name;
      return;
    }

    if (this.type === LayerType.LAYER) {
      this.name = `Layer type: ${this.getTypeId('layer-no')}`;
    } else {
      this.name = `Shimpaper type: ${this.getTypeId('layer-no')}`;
    }
  }

  getHeight(): number {
    return this.height;
  }

  setHeight(height?: number): void {
    if (this.type === LayerType.LAYER && this.boxes && this.boxes.length) {
      this.height = this.boxes[0].dimensions.height;
    } else {
      if (height !== undefined && height !== null) {
        this.height = height;
      } else {
        this.height = 0;
      }
    }
  }

  getWeight(): number {
    this.setWeight();
    return this.weight;
  }

  /**
   * Sets layer weight of boxes
   */
  setWeight(): void {
    if (this.boxes && this.boxes.length) {
      let weight = 0;
      this.boxes.forEach((box: Box) => {
        if (box.weight) {
          weight += box.weight;
        }
      });
      this.weight = weight;
    } else {
      this.weight = 0;
    }
  }

  getBoxPositions(): Box['position'][] {
    return this.boxes.map((m) => m.getPosition());
  }

  getBoxRotations(): Box['rotation'][] {
    return this.boxes.map((m) => m.rotation);
  }

  get centerOfMass(): {
    x: number;
    y: number;
    z: number;
  } {
    const positions = this.getBoxPositions();

    const centerOfMass = {
      x: 0,
      y: 0,
      z: 0,
    };
    let totalWeight = 0;
    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      const weight = this.boxes[i].weight;
      centerOfMass.x += position.x * weight;
      centerOfMass.y += position.y * weight;
      centerOfMass.z += position.z * weight;
      totalWeight += weight;
    }

    centerOfMass.x /= totalWeight;
    centerOfMass.y /= totalWeight;
    centerOfMass.z /= totalWeight;
    return centerOfMass;
  }

  updateBoxesData(projectData: ProjectData) {
    this.boxes.forEach((box: Box) => {
      box.setBoxPadding(projectData.box.padding);
      box.setDimensions(projectData.box.dimensions);
      box.setWeight(projectData.box.weight);

      if (projectData.box.label.enabled) {
        const labelOrientor = new LabelOrientator(
          box.rotation,
          projectData.box.label.orientation
        );
        box.setLabelOrientations(labelOrientor.getLabelOrientations());
      }
    });

    this.setWeight();
  }
}
