import { Injectable, OnDestroy } from '@angular/core';
import { ReadonlyFieldMaker } from '../models_new/classes/simualation-api-field-maker';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  of,
  Subject,
  Subscription,
  zip,
} from 'rxjs';
import {
  map,
  pairwise,
  startWith,
  take,
  takeUntil,
  filter,
  first,
  tap,
  switchMap,
} from 'rxjs/operators';
import {
  ISceneApiFileType,
  ISimulationApiFileType,
} from '../models_new/types/simulation-api-file-type';
import { FieldUpdate } from '../models_new/types/field-update';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Field } from '../models_new/classes/field';
import { SimulationUpdateAction } from '../models_new/enums/simulation-view-update';
import { ObjectUtils } from '../utils/object';
import { ExportImportService } from './export-import.service';
import { INavbarItem } from '../models_new/types/navbar-item';
import { FieldMaker } from '../models_new/classes/simualation-api-field-maker';
import { DataService } from './data.service';
import { LoadStatus } from '../models_new/classes/load-status';
import { Observable } from 'rxjs/internal/Observable';
import { DefaultSimConfigGetter } from '../models_new/classes/default-sim-config-getter';
import { IDefaultSimConfig } from './api/api.service';
import { SimConfigFieldIds } from '../models_new/enums/simconfig-field-ids';
import { updateFields } from './robot-config-helper';
import { RXJSUtils } from '../utils/rxjs-utils';
import { HardwareApiService } from './api/hardware-api.service';
import { defaultScene } from '../models_new/config/default/default-scene';
import { createSimConfigSceneFields } from './robot-config-helper';
import { PublicApiService } from './api/public-api.service';
import { FileUtils } from '../utils/file-utils';
import { NotificationService } from './notification.service';
import { AuthService } from '@auth0/auth0-angular';
import { AssetStoreService } from './asset-store.service';
import { IAssetStoreLoadingConfig } from '../models_new/types/asset/asset-store-loading-config';
import { HwPartUtils } from '../utils/hw-part-utils';
import { PartType } from '../models_new/enums/sim-config-part-type';
import { AssetType } from '../models_new/classes/asset/asset';
import { settings } from '../models_new/config/application-settings';
import { LocalStorageService } from './local-storage.service';
import {
  HwSwTypeMap,
  IHwSwType,
} from '../models_new/types/robot-config/hw-sw-type';

const defaultRobotURDFParts: Map<SimConfigFieldIds, any> = new Map<
  SimConfigFieldIds,
  any
>([
  [SimConfigFieldIds.ConveyorRightType, 'MINIPAL'],
  [SimConfigFieldIds.FrameType, 'MINIPAL'],
  [SimConfigFieldIds.LiftkitType, 'EWELLIX'],
  [SimConfigFieldIds.BasebracketHeight, 0],
  [SimConfigFieldIds.RobotType, 'UR10E'],
  [SimConfigFieldIds.GripperType, 'SCHMALZ_FXCB_FOAM'],
]);

@Injectable({
  providedIn: 'root',
})
export class SimConfigService implements OnDestroy {
  navItemSelected$ = new BehaviorSubject<INavbarItem>(null);
  fieldsChanges$ = new BehaviorSubject<ISimulationApiFileType>(null);
  lastFieldsChange$ = new BehaviorSubject<ISimulationApiFileType>(null);
  currentFieldsChange$ = new BehaviorSubject<ISimulationApiFileType>(null);
  fieldUpdate$ = new BehaviorSubject<FieldUpdate>(null);
  threeViewFields$ = new BehaviorSubject<Map<string, Field[]>>(null);

  importFile$ = new BehaviorSubject<{ clicked: boolean; fullFile: boolean }>({
    clicked: false,
    fullFile: true,
  });
  exportFile$ = new BehaviorSubject<boolean>(false);
  downloadSimReport$ = new BehaviorSubject<boolean>(false);
  resetFields$ = new BehaviorSubject<boolean>(false);

  update$ = new BehaviorSubject<FieldUpdate>(null);

  roFieldMaker$ = new BehaviorSubject<ReadonlyFieldMaker>(null);

  activateLoadOverlay$ = new BehaviorSubject<LoadStatus>(new LoadStatus(false));

  navItemClosedStart$: Subject<void> = new Subject<void>();
  navItemOpenedChange$: Subject<boolean> = new Subject<boolean>();
  navItemOpenedStart$: Subject<void> = new Subject<void>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  threeFieldsSubs: Map<string, Subscription[]> = new Map<
    string,
    Subscription[]
  >();

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private exportImportService: ExportImportService,
    private dataService: DataService,
    private publicApi: PublicApiService,
    private hardwareApiService: HardwareApiService,
    private notification: NotificationService,
    private auth: AuthService,
    private localstoreService: LocalStorageService
  ) {
    this.fieldsChanges$
      .pipe(
        map((values: ISimulationApiFileType) => {
          const lastChanges = this.currentFieldsChange$.getValue();
          this.lastFieldsChange$.next(lastChanges);
          this.currentFieldsChange$.next(values);
        })
      )
      .subscribe();

    // Init allFields$ property with default fields
    // TODO change allFields$ to ReplaySubject, or Observable with replay(1).
    // This will require refactoring of external usage of allFields$ propertyr directly. calls like .getValue() and .next()
    firstValueFrom(
      this.hardwareApiService.getHwSwMap$().pipe(
        tap((hwSwMap) => {
          const assetConfigs = this.makeAssetConfigs(hwSwMap);
          AssetStoreService.makeAssetsFromConfig(assetConfigs);
          AssetStoreService.loadAssetsFromConfig(assetConfigs);
        }),
        map((hwSwIdMap) =>
          createSimConfigSceneFields(
            this.notification,
            this.localstoreService,
            hwSwIdMap,
            defaultScene
          )
        )
      )
    ).then((fields) => {
      let dict = this.threeViewFields$.getValue() || new Map<string, Field[]>();
      dict.set('__default', fields);
      this.threeViewFields$.next(dict);
    });

    this.threeViewFields$
      .pipe(RXJSUtils.filterUndefinedAndNull(), takeUntil(this.destroy$))
      .subscribe((threeFields) => {
        for (const [threeID, fields] of threeFields.entries()) {
          this.subFieldChanges(threeID, fields);
        }
      });

    // Handle updating of other fields
    this.fieldUpdate$
      .pipe(RXJSUtils.filterUndefinedAndNull(), takeUntil(this.destroy$))
      .subscribe((update: FieldUpdate) => {
        this.emitUpdateAction(update);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private subFieldChanges(threeID: string, fields: Field[]) {
    // New fields are incoming, check if we need to unsubscribe first.
    let subs = this.threeFieldsSubs.get(threeID);
    if (subs) {
      for (const sub of subs) {
        sub.unsubscribe();
      }
    }
    if (fields) {
      subs = [];
      fields.forEach((field: Field) => {
        subs.push(
          field.formControl.valueChanges
            .pipe(takeUntil(this.destroy$), startWith(''), pairwise())
            .subscribe(([oldValue, newValue]) => {
              if (newValue !== undefined) {
                this.fieldUpdate$.next({
                  action: SimulationUpdateAction.FIELD_UPDATE,
                  fieldId: field.id,
                  fieldName: field.text.label,
                  threeId: threeID,
                  prevVal: !isNaN(oldValue) ? +oldValue : oldValue,
                  newVal: !isNaN(newValue) ? +newValue : newValue,
                });
              }
            })
        );

        if (field.formControl.value === '') {
          for (const [key, value] of defaultRobotURDFParts) {
            if (key === field.id) {
              if (field.options) {
                for (const option of field.options as IHwSwType[]) {
                  if (option.name === value) {
                    field.formControl.setValue(option, {
                      emitEvent: true,
                    });
                  }
                }
              } else if (!field.options) {
                field.formControl.setValue(value, { emitEvent: true });
              }
            }
          }
        }
      });
      // Take note of the subscriptions for this threeview until next field updates.
      this.threeFieldsSubs.set(threeID, subs);
    }
  }

  private createSceneFields(id: string) {
    return combineLatest([
      this.hardwareApiService.getHwSwMap$(),
      this.hardwareApiService.getHardwareByID$(id),
    ]).pipe(
      take(1),
      map(([hwSwIdMap, scene]) => {
        return createSimConfigSceneFields(
          this.notification,
          this.localstoreService,
          hwSwIdMap,
          scene.data,
          scene.name
        );
      })
    );
  }

  setScene(scene: ISceneApiFileType, threeId: string = '__default') {
    return combineLatest([
      this.hardwareApiService.getHwSwMap$(),
      this.threeViewFields$.pipe(
        first((val) => val !== null), //wait for threeViewFields$ default initialization
        map((m) => m.get(threeId)),
        filter((fields) => fields !== null)
      ),
    ]).pipe(
      take(1),
      tap(([hwSwMap, fields]) => {
        updateFields(
          this.notification,
          this.localstoreService,
          fields,
          scene,
          hwSwMap
        );
      }),
      switchMap((_) => {
        return this.getFields();
      })
    );
  }

  configureScene(
    sceneId?: string,
    filter?: SimConfigFieldIds[],
    threeFilter?: SimConfigFieldIds[],
    keepFields: boolean = false,
    threeID: string = '__default'
  ): Observable<[Field[], Field[]]> {
    // configurs default scene if no sceneId is given
    let fields: Observable<Field[]>;

    if (keepFields) {
      fields = this.threeViewFields$.pipe(
        map((m) => m.get(threeID)),
        take(1)
      );
    } else {
      if (sceneId) {
        fields = this.createSceneFields(sceneId).pipe(take(1));
      } else {
        fields = this.hardwareApiService.getHwSwMap$().pipe(
          take(1),
          map((hwSwMap) =>
            createSimConfigSceneFields(
              this.notification,
              this.localstoreService,
              hwSwMap
            )
          )
        );
      }
    }

    return fields.pipe(
      take(1),
      tap((fields) => {
        let dict =
          this.threeViewFields$.getValue() || new Map<string, Field[]>();
        dict.set(threeID, fields);
        this.threeViewFields$.next(dict);
      }),
      switchMap((_) => {
        return zip([
          this.getFields(threeID, filter),
          this.getFields(threeID, threeFilter),
        ]);
      })
    );
  }

  getThreeViewFields(ids: SimConfigFieldIds[], threeID: string) {
    return this.threeViewFields$.asObservable().pipe(
      map((fields) => {
        return fields[threeID].filter((field) =>
          ids ? (ids as string[]).includes(field.id) : true
        );
      })
    );
  }

  getFields(
    threeId: string = '__default',
    ids?: SimConfigFieldIds[]
  ): Observable<Field[]> {
    return this.threeViewFields$.asObservable().pipe(
      map((m) => m.get(threeId)),
      filter((fields) => fields?.length > 0),
      map((fields) => {
        if (!ids) {
          return fields;
        }

        return ids.map((id) => fields.find((field) => field.id === id));
      })
    );
  }

  getField(id: string): any {
    return this.getFieldForThreeView(id, '__default');
  }

  getAllFields(threeId: string): Field[] {
    return this.threeViewFields$.getValue().get(threeId);
  }

  getFieldForThreeView(id: string, threeID: string = '__default'): any {
    const list = this.threeViewFields$.getValue().get(threeID);
    if (list) {
      return list.find((field) => field.id === id);
    }
    return undefined;
  }

  makeDefaultSimConfig(
    useDefaultConfig: boolean,
    cpm?: number
  ): Observable<ISimulationApiFileType> {
    const project = this.dataService.getProject();
    let exportFile: ISimulationApiFileType;

    if (useDefaultConfig) {
      // Get default config

      return this.publicApi.fetchDefaultSimConfigs().pipe(
        take(1),
        map((configs: IDefaultSimConfig[]) => {
          configs = configs.map((def: IDefaultSimConfig) => {
            const defClone = ObjectUtils.cloneObject(def);
            (defClone.data as any).project =
              this.exportImportService.mapToExportFormat(project);
            return defClone;
          });

          const configGetter = new DefaultSimConfigGetter(
            configs,
            project.data.getBoxData().dimensions.length,
            project.data.getBoxData().weight,
            +cpm
          );
          exportFile = configGetter.getDefault()
            ? configGetter.getDefault().data
            : null;
          exportFile.default_sim_config_name = configGetter.getDefault()
            ? configGetter.getDefault().name
            : 'No default file found';
          return exportFile;
        })
      );
    } else {
      // No default config
      const maker = new FieldMaker(
        project.data.getBoxData().maxGrip,
        project.data.getBoxData().dimensions
      );

      const simulationFormGroup = maker.getMainFormGroup();
      exportFile = maker.makeExportValues(
        simulationFormGroup.formGroup.getRawValue()
      );

      exportFile.pattern = this.exportImportService.mapToExportFormat(project);

      // Set offset bracket to 0 if not toggled
      const offsetEnabled = maker.getFieldById(
        'mainFormGroup.scene.robot.offset_bracket.add_offset'
      ).formControl.value;
      if (!offsetEnabled) {
        exportFile.scene.robot.offset_bracket.collision_object.height = 0;
        exportFile.scene.robot.offset_bracket.collision_object.width = 0;
        exportFile.scene.robot.offset_bracket.collision_object.length = 0;
        exportFile.scene.robot.offset_bracket.collision_object.position.x = 0;
        exportFile.scene.robot.offset_bracket.collision_object.position.y = 0;
        exportFile.scene.robot.offset_bracket.collision_object.position.z = 0;
        exportFile.scene.robot.offset_bracket.offset.x = 0;
        exportFile.scene.robot.offset_bracket.offset.y = 0;
        exportFile.scene.robot.offset_bracket.offset.z = 0;
      }

      return of(exportFile);
    }
  }

  resetButtons() {
    this.importFile$.next({ clicked: false, fullFile: true });
    this.exportFile$.next(false);
    this.downloadSimReport$.next(false);
    this.resetFields$.next(false);
  }

  downloadSimulationReport(data: ISimulationApiFileType | any) {
    this.auth
      .getAccessTokenSilently()
      .pipe(
        switchMap((token: string) => {
          const headers = {
            Authorization: `Bearer ${token}`,
            pdfParams: environment.pdfReportParams,
            pdfTemplate: environment.pdfReportTemplate,
          };
          return this.http.post(environment.pdfReportURL, data, { headers });
        })
      )
      .subscribe({
        next: (response: Object) => {
          FileUtils.downloadBase64Pdf(
            response['message'],
            `${data.project.data.name}_simulation_performance_report.pdf`
          );
        },
        error: (err) => this.errorHandler.handleError(err),
      });
  }

  emitUpdateAction(updateAction: FieldUpdate) {
    // Normal updates
    this.handleUpdateOnChange(updateAction, false);

    this.update$.next(updateAction);

    // Post updates
    this.handleUpdateOnChange(updateAction, true);
  }

  handleUpdateOnChange(updateAction: FieldUpdate, post: boolean) {
    if (updateAction.fieldId) {
      const field = this.getFieldForThreeView(
        updateAction.fieldId,
        updateAction.threeId
      );

      // Missing field or no fields to update, skip.
      if (
        !field ||
        !(
          field.reactive.updates_fields_onChange ||
          field.reactive.post_updates_fields_onChange
        )
      ) {
        return;
      }

      const updatesList = post
        ? field.reactive.post_updates_fields_onChange
        : field.reactive.updates_fields_onChange;

      if (updatesList?.length) {
        // "updatesList.forEach()" won't run even though it has elements to loop over.
        // Swapped to for-of loop instead, since it works.
        for (const update of updatesList) {
          let { id, fnOutput, skipIf, ignoreUpdateFunction } = update;

          // Check if update should be skipped
          if (skipIf?.length > 0) {
            for (const obj of skipIf) {
              const value = this.getFieldForThreeView(
                obj.id,
                updateAction.threeId
              ).getValue();
              const shouldSkip = obj.conditionFn(value, field);
              if (shouldSkip) {
                return; // Skip this update
              }
            }
          }

          const changeField = this.getFieldForThreeView(
            id,
            updateAction.threeId
          );
          let setValue;

          // If update function is not to be used,
          // just cause an update with the same value.
          if (
            ignoreUpdateFunction ||
            typeof field.reactive.updatesFieldFn === 'undefined' ||
            typeof fnOutput === 'undefined'
          ) {
            setValue = changeField.getValue();

            // "updatesFieldFn" is expected to be used, use it and update.
          } else if (field.reactive.updatesFieldFn) {
            setValue = field.reactive.updatesFieldFn(
              updateAction.newVal,
              fnOutput
            );
          }
          changeField.formControl.setValue(setValue);
        }
      }

      // NOTE! Kent: As far as I've understood, product sensors are handled automatically for now.
      // TODO! ADD BACK THIS? (Was the old way using parented fields, which no longer is the case)
      // Special case for product sensors. Update parent when changes
      /*
      if (field.parentId === 'mainFormGroup.scene.conveyors.0.custom_description.sensors.products.0') {
        const childIndex = field.id.substr(field.id.length - 1);
        const parentField = this.getField(field.parentId);
        const parentValue = parentField.formControl.value;
        parentValue[childIndex] = field.formControl.value;
        parentField.formControl.setValue(parentValue);
      }
      */
    }
  }

  private makeAssetConfigs(hwSwMap: HwSwTypeMap): IAssetStoreLoadingConfig[] {
    const configs = [];
    for (const [_, value] of Object.entries(hwSwMap)) {
      configs.push(this.makeAssetConfig(value));
    }
    return configs;
  }

  public makeAssetConfig(hwSw: IHwSwType): IAssetStoreLoadingConfig {
    const id = HwPartUtils.getPartAssetID(
      hwSw.hw_sw_type.name as unknown as PartType,
      hwSw.name
    );

    const urls = new Map<string, string>();
    if (hwSw.metadata.urdf_path) {
      urls.set(id, settings.pallyDescriptionsURL + hwSw.metadata.urdf_path);
    }

    return {
      id: id,
      urls: urls,
      type: AssetType.URDF,
      onDemand: true,
    };
  }
}
