import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AltLayout } from '../models_new/enums/alt-layout';
import { Layer } from '../models_new/classes/layer';
import { settings } from '../models_new/config/application-settings';
import { Pallet } from '../models_new/classes/pallet';
import { UpdateAction } from '../models_new/enums/update-action';
import { PalletEditorService } from './pallet-editor.service';
import { WorkspaceService } from './workspace.service';
import { ProjectData } from '../models_new/classes/project-data';
import { LayerApproach } from '../models_new/enums/layer-approach';
import { Project } from '../models_new/classes/project';

@Injectable({
  providedIn: 'root',
})

/**
 * Handles alternative pallet
 */
export class AlternativeLayoutService {
  altLayouts: string[] = [
    AltLayout.NULL,
    AltLayout.COPY,
    AltLayout.ROTATE,
    AltLayout.MIRROR,
    AltLayout.CUSTOM,
  ];
  selectedAltLayout$ = new BehaviorSubject<{
    selected: AltLayout;
    origin: string;
    project: Project;
  }>({
    selected: settings.defaultAltLayout,
    origin: 'init',
    project: null,
  });

  project: Project;

  constructor(
    public palletEditor: PalletEditorService, // TODO: FIX TO PRIVATE
    private workspaceService: WorkspaceService
  ) {
    this.selectedAltLayout$
      .pipe()
      .subscribe(
        (altLayout: {
          selected: AltLayout;
          origin: string;
          project: Project;
        }) => {
          if (
            altLayout.selected === AltLayout.CUSTOM ||
            altLayout.origin === 'init'
          ) {
            return;
          }

          if (!altLayout.project) {
            throw new Error('Project is not defined');
          }

          this.project = altLayout.project;
          /**
           * Makes sure that alternative pallet layers always has same array length(max) as primary pallet.
           * If alternative pallet has less layers it will be generated by this.computeSetFunction()
           * */
          const maxI = 100; // Fallback if infinite loop
          let i = 0;
          while (
            this._alternativePallet.getLayersLength() >
              this._primaryPallet.getLayersLength() ||
            i === maxI
          ) {
            this._alternativePallet.removeLayerAtIndex(
              this._alternativePallet.getLayersLength() - 1
            );
            i++;
          }

          this._alternativePallet.preserveLayers = false;
          this.saveAltLayout(altLayout.selected, true);
          this.computeSetFunction(altLayout.selected);
        }
      );
  }

  /**
   * @returns Pallet
   */
  get _primaryPallet(): Pallet {
    return this.project.getPalletByPosition(settings.primaryPallet);
  }

  /**
   * @returns Pallet
   */
  get _alternativePallet(): Pallet {
    return this.project.getPalletByPosition(settings.secondaryPallet);
  }

  saveAltLayout(altLayout: AltLayout, updateProject: boolean) {
    this.project
      .getPalletAdvancedSettings()
      .setAltLayout(altLayout, updateProject);
  }

  computeSetFunction(altLayout: AltLayout): void {
    const projectData: ProjectData = this.project.getProjectData();

    this._alternativePallet.whipePallet();

    if (altLayout === AltLayout.NULL) {
      this.setNull();
    } else {
      this._primaryPallet.layers.forEach((layer: Layer, index: number) => {
        const newLayer = this.palletEditor.makeClone(
          layer,
          this._alternativePallet,
          false
        );

        if (altLayout === AltLayout.COPY) {
          this.setCopy(newLayer, index, projectData);
        }
        if (altLayout === AltLayout.ROTATE) {
          this.setRotate(newLayer, index, projectData);
        }
        if (altLayout === AltLayout.MIRROR) {
          this.setMirror(newLayer, index, projectData);
        }
      });
    }
  }

  /**
   * Sets alternative pallet layers to null (auto)
   */
  setNull(): void {
    this._alternativePallet.deleteAllLayers(true);
  }

  /**
   * Sets copied alternative pallet layer
   * @param layer: Layer
   * @param layerIndex: number
   * @param projectData: ProjectData
   */
  setCopy(layer: Layer, layerIndex: number, projectData: ProjectData): void {
    if (layer.boxes) {
      layer.setBoxes(
        this.workspaceService.automaticSortBoxes(
          projectData,
          layer.boxes,
          layer.approach === LayerApproach.INVERSE ? false : true,
          layer.overrideBoxIds
        )
      );
      this._alternativePallet.replaceLayerAtIndex(
        layerIndex,
        layer,
        UpdateAction.GENERATING_PALLET
      );
    }
  }

  /**
   * Sets rotated alternative pallet layer
   * @param layer: Layer
   * @param layerIndex: number
   * @param projectData: ProjectData
   */
  setRotate(layer: Layer, layerIndex: number, projectData: ProjectData): void {
    if (layer.boxes) {
      this.palletEditor.rotateLayer(layer, this.project);
      layer.setBoxes(
        this.workspaceService.automaticSortBoxes(
          projectData,
          layer.boxes,
          layer.approach === LayerApproach.INVERSE ? false : true,
          layer.overrideBoxIds
        )
      );
    }
    this._alternativePallet.replaceLayerAtIndex(
      layerIndex,
      layer,
      UpdateAction.GENERATING_PALLET
    );
  }

  /**
   * Sets mirrored alternative pallet layer
   * @param layer: Layer
   * @param layerIndex: number
   * @param projectData: ProjectData
   */
  setMirror(layer: Layer, layerIndex: number, projectData: ProjectData) {
    if (layer.boxes) {
      this.palletEditor.mirrorLayerHoriz(layer, this.project);
      layer.setBoxes(
        this.workspaceService.automaticSortBoxes(
          projectData,
          layer.boxes,
          layer.approach === LayerApproach.INVERSE ? false : true,
          layer.overrideBoxIds
        )
      );
    }
    this._alternativePallet.replaceLayerAtIndex(
      layerIndex,
      layer,
      UpdateAction.GENERATING_PALLET
    );
  }
}
