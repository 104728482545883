<mat-card appearance="outlined" class="invitationCard">
  <div class="hstack" style="justify-content: space-between">
    <div class="hstack" style="gap: 16px; align-items: center">
      <div class="vstack">
        <h1>{{ invite.organization.name }}</h1>
        <p>{{ getRolesForType(invite.member_type.types) }}</p>
      </div>
    </div>
    <div
      class="equalVstack buttonContainer"
      style="gap: 8px"
      *ngIf="showButtons"
    >
      <button (click)="acceptInvite(invite)">Become member</button>
      <button (click)="rejectInvite(invite)">Reject</button>
    </div>
  </div>
</mat-card>
