<mat-card
  [class.selected]="selected"
  mat-ripple
  class="selectable-card"
  [ngClass]="{ 'card-disabled': disable }"
  (click)="cardSelected.emit()"
>
  <div class="illustration">
    <ng-container *ngIf="!renderVersionNo">
      <img draggable="false" class="default-img" [src]="image" />
    </ng-container>
    <ng-container *ngIf="renderVersionNo">
      <div
        class="version-no-img no-select"
        [ngStyle]="pallyVersionBackground(renderVersionNo)"
      >
        <img
          draggable="false"
          src="../../../../assets/icons/Pally_logo.png"
          alt="pally_logo"
        />
        <div class="version-text" *ngIf="renderVersionNo !== 'latest'">
          {{ renderVersionNo }}
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="preset-info-wrapper overflow-scroll"
    #wrapper
    (mouseenter)="startMarqueeOverflow(wrapper, scrollingLabel)"
    (mouseleave)="stopMarqueeOverflow(scrollingLabel)"
  >
    <p class="tagline">{{ tagline | uppercase }}</p>
    <h3 #scrollingLabel class="preset-label">{{ label }}</h3>
    <mat-checkbox
      style="position: absolute; top: 1em; right: 1em"
      [checked]="selected"
    ></mat-checkbox>
  </div>
</mat-card>
