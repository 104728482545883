<app-landing-toolbar></app-landing-toolbar>

<ng-container *ngIf="contentfulContent$ | async as content; else loading">
  <div class="page-about-wrapper">
    <div class="release-notes-content">
      <div class="release-header">
        <button
          class="release-navigateBack-button"
          mat-button
          color="primary"
          (click)="navigateBack()"
        >
          <mat-icon>arrow_back</mat-icon> Go Back
        </button>
        <!-- Release-Title: Displays a the article's title and subtitle. -->
        <h1 class="about-title">
          {{ content.title }}
        </h1>
        <div *ngIf="content.subtitle" class="release-title-description">
          <p
            *ngIf="release$ | async as release"
            [innerHTML]="
              content.subtitle.replace(
                '{published_at}',
                timeUtil.getFormattedDate(release.publishedAt, '2-digit')
              )
            "
          ></p>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="release-body-card">
        <!-- Release-Text: Just displays plain text. -->
        <div *ngIf="content.text" class="release-text-description">
          <ng-container *ngFor="let richTextEntry of content.text">
            <ng-container *ngIf="!richTextEntry.data?.url; else richTextAsset">
              <p [innerHtml]="richTextEntry | richtextDocumentToHtml"></p>
            </ng-container>
            <ng-template #richTextAsset>
              <div
                *ngIf="richTextEntry.data.__typename === 'Asset'"
                class="release-text-description-assets"
              >
                <img
                  [src]="richTextEntry.data.url"
                  [alt]="richTextEntry.data?.title || ''"
                />
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="page-about-wrapper">
    <app-loading style="margin-top: 4em"></app-loading>
  </div>
</ng-template>

<app-fast-track-footer></app-fast-track-footer>
