<div class="picker-wrapper">
  <ng-container *ngIf="!disableAdd">
    <div class="horizontal-wrapper">
      <button
        pally-rounded-button-m
        [color]="'primary'"
        class="preset-button"
        mat-raised-button
        (click)="createNew()"
      >
        <mat-icon style="margin-bottom: 3px">add</mat-icon>
        New
      </button>
      <button
        *ngIf="!disableInventoryPick"
        class="preset-button"
        (click)="showInventoryPicker()"
      >
        <mat-icon style="margin-bottom: 3px">search</mat-icon>
        Pick from inventory
      </button>
    </div>
    <mat-divider style="margin-top: 12px"></mat-divider>
  </ng-container>
  <div
    id="horizontal-wrapper"
    class="horizontal-wrapper"
    style="margin-top: 18px; margin-bottom: 12px"
  >
    <p class="no-found" *ngIf="presets?.length === 0">
      {{ 'No ' + presetType + ' found.' }}
    </p>

    <app-selectable-card
      *ngFor="let preset of presets"
      id="preset-{{ preset.id }}-{{ presetType }}"
      [tagline]="presetType !== 'product' ? presetType + ' preset' : presetType"
      [label]="preset.label"
      [image]="preset.image"
      [renderVersionNo]="preset.pallyVersion"
      [selected]="preset.selected || selectedId === preset.id"
      [disable]="disableCards"
      (cardSelected)="pickerClick(preset.id)"
    >
    </app-selectable-card>
  </div>
</div>
