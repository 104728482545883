<mat-toolbar class="dialog-bottom-navbar">
  <div class="dialog-bottom-navbar-content">
    <div class="dialog-bottom-navbar-title">{{ title }}</div>
    <div class="spacer"></div>
    <div class="dialog-bottom-navbar-actions">
      <button
        *ngIf="secondaryButton && secondaryButton !== 'hidden'"
        mat-stroked-button
        (click)="
          secondaryButton === 'skip'
            ? skipClick.emit(true)
            : backClick.emit(true)
        "
      >
        {{ secondaryButton }}
      </button>
      <button
        *ngIf="tertiaryButton"
        style="margin-right: 2em"
        mat-raised-button
        color="accent"
        (click)="tertiaryButtonClick.emit(true)"
      >
        {{ tertiaryButton }}
        <mat-icon style="margin-bottom: 3px">edit</mat-icon>
      </button>
      <button mat-raised-button color="accent" (click)="nextClick.emit(true)">
        {{ primaryButton }}
        <mat-icon style="margin-bottom: 3px">arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
