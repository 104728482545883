import { Injector } from '@angular/core';
import * as THREE from 'three';
import { URDFRobot } from '@rocketfarm/urdf-loader';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { FieldUpdate } from '../../../types/field-update';
import { milliToMeter } from '../../../../utils/div';

export class ConveyorTask extends Task {
  static [taskNameSymbol] = 'Conveyor';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    if (this.data.fieldId === SimConfigFieldIds.ConveyorRightType) {
      this.updateConveyorType(this.data, resolve, reject);
    } else {
      // Assume some property got updated
      this.updateConveyorProperties();
    }

    const offset = this.readConveyorOffset();
    if (this.data.fieldId === SimConfigFieldIds.ConveyorRightPositionY) {
      this.activateDimVis(
        SimConfigFieldIds.ConveyorRightPositionY,
        {
          value: offset.y,
        },
        this.readShowVisualizers() ? -1 : undefined
      );
    }
    if (this.data.fieldId === SimConfigFieldIds.ConveyorRightPositionZ) {
      this.activateDimVis(
        SimConfigFieldIds.ConveyorRightPositionZ,
        {
          value: offset.z,
        },
        this.readShowVisualizers() ? -1 : undefined
      );
    }

    if (this.data.fieldId === SimConfigFieldIds.ConveyorRightPositionX) {
      this.activateDimVis(
        SimConfigFieldIds.ConveyorRightPositionX,
        {
          value: offset.x,
        },
        this.readShowVisualizers() ? -1 : undefined
      );
    }

    if (
      this.data.fieldId !== SimConfigFieldIds.ConveyorRightType ||
      this.isCustomConveyor()
    ) {
      resolve();
    }
  }

  public updateConveyorType(s: FieldUpdate, resolve, reject): void {
    const conveyorJoint = this.robot.getJointByID(
      JointNames.WorldConveyor
    ) as THREE.Object3D;
    const conveyorLink = URDFUtils.findLinkFromJoint(conveyorJoint);
    const conveyorVisual = URDFUtils.findVisualFromJoint(conveyorJoint);

    const type = this.readConveyorType();
    const dimension = this.readConveyorDimension();
    const offset = this.readConveyorOffset();
    const rotation = this.readConveyorRotation();

    if (type === 'CUSTOM') {
      conveyorJoint.position.copy(offset);
      conveyorJoint.rotation.set(0, 0, 0);
      conveyorJoint.scale.set(1, 1, 1);

      conveyorLink.position.set(0, 0, 0);
      conveyorLink.quaternion.copy(rotation);
      conveyorLink.scale.set(1, 1, 1);

      conveyorVisual.position.set(0, dimension.y / 2, -(dimension.z / 2));
      conveyorVisual.rotation.set(0, 0, 0);
      conveyorVisual.scale.copy(dimension);

      conveyorVisual.children = [];
      const conveyorGeo = new THREE.BoxGeometry(1, 1, 1);
      const conveyorMat = new THREE.MeshLambertMaterial({ color: '#B6B6B6' });
      const conveyorMesh = new THREE.Mesh(conveyorGeo, conveyorMat);
      conveyorMesh.name = 'CUSTOM';
      conveyorVisual.add(conveyorMesh);

      resolve();
    } else {
      this.partStoreService
        .getPart<URDFRobot>(HwPartUtils.getPartAssetID(PartType.CONVEYOR, type))
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((part) => {
          const partJoint = (part as THREE.Object3D).getObjectByName(
            'conveyor_joint'
          );
          const partLink = URDFUtils.findLinkFromJoint(partJoint);
          const partVisual = URDFUtils.findVisualFromJoint(partJoint);

          conveyorJoint.position.copy(offset);
          conveyorJoint.rotation.copy(partJoint.rotation);
          conveyorJoint.scale.copy(partJoint.scale);

          conveyorLink.position.copy(partLink.position);
          conveyorLink.quaternion.copy(rotation); // Maintain user's rotation
          conveyorLink.scale.copy(partLink.scale);

          conveyorVisual.position.copy(partVisual.position);
          conveyorVisual.rotation.copy(partVisual.rotation);
          conveyorVisual.scale.copy(partVisual.scale);
          conveyorVisual.children = [];

          for (const child of partVisual.children) {
            conveyorVisual.add(ThreeUtils.clone(child));
          }

          resolve();
        });
    }
  }

  protected updateConveyorProperties(): void {
    const conveyorJoint = this.robot.getJointByID(
      JointNames.WorldConveyor
    ) as THREE.Object3D;
    const conveyorLink = URDFUtils.findLinkFromJoint(conveyorJoint);
    const conveyorVisual = URDFUtils.findVisualFromJoint(conveyorJoint);

    const dimension = this.readConveyorDimension();
    const offset = this.readConveyorOffset();
    const rotation = this.readConveyorRotation();

    conveyorJoint.position.copy(offset);
    conveyorJoint.rotation.set(0, 0, 0);
    conveyorJoint.scale.set(1, 1, 1);

    conveyorLink.position.set(0, 0, 0);
    conveyorLink.quaternion.copy(rotation);
    conveyorLink.scale.set(1, 1, 1);

    if (this.isCustomConveyor()) {
      conveyorVisual.position.set(0, dimension.y / 2, -(dimension.z / 2));
      conveyorVisual.rotation.set(0, 0, 0);
      conveyorVisual.scale.copy(dimension);
    }
  }

  protected isCustomConveyor(): boolean {
    return this.readConveyorType() === 'CUSTOM';
  }

  protected readConveyorType(): string {
    return this.readField<any>(SimConfigFieldIds.ConveyorRightType).name;
  }

  protected readConveyorDimension(dimension?: THREE.Vector3): THREE.Vector3 {
    const tempDim: Array<any> = this.readFields([
      SimConfigFieldIds.ConveyorRightWidth,
      SimConfigFieldIds.ConveyorRightLength,
      SimConfigFieldIds.ConveyorRightHeight,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempDim.length; i++) {
      if (tempDim[i] === -0 || tempDim[i] === '-0') {
        tempDim[i] = 0;
      } else if (Type.isOfType(tempDim[i], 'string')) {
        tempDim[i] = +tempDim[i];
      }
    }

    const dim = new THREE.Vector3(
      milliToMeter(tempDim[0]),
      milliToMeter(tempDim[1]),
      milliToMeter(tempDim[2])
    );

    if (Type.isDefined(dimension)) {
      dimension.copy(dim);
    }

    return dim;
  }

  protected readConveyorOffset(offset?: THREE.Vector3): THREE.Vector3 {
    const tempOffset: Array<any> = this.readFields([
      SimConfigFieldIds.ConveyorRightPositionX,
      SimConfigFieldIds.ConveyorRightPositionY, // Conveyor distance from robot
      SimConfigFieldIds.ConveyorRightPositionZ,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempOffset.length; i++) {
      if (tempOffset[i] === -0 || tempOffset[i] === '-0') {
        tempOffset[i] = 0;
      } else if (Type.isOfType(tempOffset[i], 'string')) {
        tempOffset[i] = +tempOffset[i];
      }
    }

    const o = new THREE.Vector3(
      milliToMeter(tempOffset[0]),
      milliToMeter(tempOffset[1]),
      milliToMeter(tempOffset[2])
    );

    if (Type.isDefined(offset)) {
      offset.copy(o);
    }

    return o;
  }

  protected readConveyorRotation(
    rotation?: THREE.Quaternion
  ): THREE.Quaternion {
    const tempRot: Array<any> = this.readFields([
      SimConfigFieldIds.ConveyorRightRotationX,
      SimConfigFieldIds.ConveyorRightRotationY,
      SimConfigFieldIds.ConveyorRightRotationZ,
      SimConfigFieldIds.ConveyorRightRotationW,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempRot.length; i++) {
      if (tempRot[i] === -0 || tempRot[i] === '-0') {
        tempRot[i] = 0;
      } else if (Type.isOfType(tempRot[i], 'string')) {
        tempRot[i] = +tempRot[i];
      }
    }

    const rot = new THREE.Quaternion(
      tempRot[0],
      tempRot[1],
      tempRot[2],
      tempRot[3]
    );

    if (Type.isDefined(rotation)) {
      rotation.copy(rot);
    }

    return rot;
  }
}
