import * as THREE from 'three';
import { ShaderMaterial } from 'three';

/**
 * Original source: https://threejsfundamentals.org/threejs/threejs-cleanup-loaded-files.html
 * Modified by Kent Wincent Holt @ Rocketfarm AS
 * Date: 29.10.2021
 */

export class ResourceTracker {
  private resources = new Set<any>();

  disposeCB = ((event: any) => {
    this.untrack(event.target);
    event.target.removeEventListener('dispose', this.disposeCB);
  }).bind(this);

  track(resource: any): any {
    if (!resource) {
      return resource;
    }

    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach((resource) => this.track(resource));
      return resource;
    }

    if (
      resource.dispose ||
      resource instanceof THREE.BufferGeometry ||
      resource instanceof THREE.Material ||
      resource instanceof ShaderMaterial
    ) {
      this.resources.add(resource);

      // If dispose is called on this resource before I do, remove reference to it.
      resource.addEventListener('dispose', this.disposeCB);
    }

    if (resource instanceof THREE.Object3D) {
      this.track(resource.children);

      if (resource instanceof THREE.Mesh) {
        this.track(resource.geometry);
        this.track(resource.material);
      }
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value);
        }
      }

      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource instanceof ShaderMaterial) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value;
            if (
              uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)
            ) {
              this.track(uniformValue);
            }
          }
        }
      }
    }

    return resource;
  }

  untrack(resource: any): void {
    this.resources.delete(resource);
  }

  disposeResource(resource: any): any {
    if (!resource) {
      return resource;
    }

    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach((resource) => this.disposeResource(resource));
      return resource;
    }

    if (resource.dispose) {
      resource.dispose();
      this.untrack(resource);
    }

    if (resource instanceof THREE.Object3D) {
      resource.children.forEach((child) => this.untrack(child));

      if (resource instanceof THREE.Mesh) {
        this.disposeResource(resource.geometry);
        this.disposeResource(resource.material);
      }
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.disposeResource(value);
        }
      }

      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource instanceof ShaderMaterial) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value;
            if (
              uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)
            ) {
              this.disposeResource(uniformValue);
            }
          }
        }
      }
    }

    return resource;
  }
  dispose() {
    for (const resource of this.resources) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }

      if (resource.dispose) {
        resource.dispose();
      }
    }

    this.resources.clear();
  }
}

const globalResources = new ResourceTracker();

export function track(resource: any): any {
  return globalResources.track(resource);
}

export function untrack(resource: any): void {
  globalResources.untrack(resource);
}

export function dispose(): void {
  globalResources.dispose();
}
