<div class="main" class="config-overview left-content">
  <div class="head">
    <ng-template #spacer><span>&nbsp;</span></ng-template>
    <ng-container *appIfCommandAvailable="'update_robot_configuration'">
      <input
        *ngIf="robotConfiguration$ | async as robotConfig; else spacer"
        (keyup)="nameInputKeyup$.next(nameInput.value)"
        matInput
        #nameInput
        [value]="robotConfig.name"
        placeholder="Unnamed"
        type="text"
      />
    </ng-container>
  </div>

  <div class="overview-text">
    <p>We’ve taken the liberty of making a default configuration for you.</p>
    <p>
      You’re free to make as many or as few changes as you desire, or import
      your existing configuration files
    </p>
    <p>
      At any time, head over to “Summary and exports” to export the
      configurations.
    </p>
  </div>
  <div class="config-steps-card hardware">
    <div class="left">
      <div
        [ngClass]="{ touched: hardwareTouched === true }"
        class="icon-wrapper"
      >
        <mat-icon class="icon check">check</mat-icon>
      </div>
      <div class="left-text-wrapper">
        <span class="left-text">Hardware configuration</span>
      </div>
    </div>
    <div class="right">
      <div
        *ngIf="hardwareTouched"
        class="icon-wrapper replay"
        routerLink="hardware/1"
      >
        <mat-icon class="icon replay">replay</mat-icon>
      </div>

      <div class="icon-wrapper edit" routerLink="hardware/1">
        <mat-icon class="icon edit">edit</mat-icon>
      </div>
    </div>
  </div>
  <div class="config-steps-card strategy">
    <div class="left">
      <div
        [ngClass]="{ touched: strategyTouched === true }"
        class="icon-wrapper"
      >
        <mat-icon class="icon check">check</mat-icon>
      </div>
      <div class="left-text-wrapper">
        <span class="left-text">Strategy configuration</span>
      </div>
    </div>
    <div class="right">
      <div
        *ngIf="strategyTouched"
        class="icon-wrapper replay"
        routerLink="strategy/1"
      >
        <mat-icon class="icon replay">replay</mat-icon>
      </div>

      <div class="icon-wrapper upload">
        <mat-icon class="icon upload">cloud_upload</mat-icon>
      </div>
      <div class="icon-wrapper edit" routerLink="strategy/1">
        <mat-icon class="icon edit">edit</mat-icon>
      </div>
    </div>
  </div>
</div>
