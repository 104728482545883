import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  combineLatest,
  map,
  of,
  skipWhile,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import {
  mainMenuCustomer,
  mainMenuSales,
} from 'src/app/models_new/config/main-menu-nav-items';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { OrganizationType } from 'src/app/models_new/enums/organization-type';
import { INavbarItem } from 'src/app/models_new/types/navbar-item';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import { OrganizationLogoService } from 'src/app/services/organization-logo.service';
import { ObjectUtils } from 'src/app/utils/object';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';

@Component({
  selector: 'app-toolbar-component',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('less', style({ transform: 'rotate(0)' })),
      state('more', style({ transform: 'rotate(-180deg)' })),
      transition('more => less', animate('200ms ease-out')),
      transition('less => more', animate('200ms ease-in')),
    ]),
  ],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  ready = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  navbarItems: INavbarItem[];
  pagesPATH = pagesPATH;
  activeOrganization: ApiOrganization;
  organizations: ApiOrganization[];
  salesOrganizations: ApiOrganization[];
  allUsersOrganizations: ApiOrganization[];
  unrelatedCustomerOrgs: ApiOrganization[];
  filteredOrgs: ApiOrganization[];
  filteredUnrelatedOrgs: ApiOrganization[];
  customer_org: ApiOrganization;
  sales_org: ApiOrganization;
  expandedElement;
  notifications: number = 0;
  unhandledLeads: number = 0;

  constructor(
    public stateService: StateService,
    private router: Router,
    private organizationApi: OrganizationApiService,
    public organizationLogoService: OrganizationLogoService,
    public publicApi: PublicApiService
  ) {}

  ngOnInit(): void {
    this.refreshMenuInputData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  refreshMenuInputData() {
    this.ready = false;
    this.stateService.isUserLogged$
      .pipe(
        skipWhile(
          (isUserLogged) =>
            !isUserLogged && window.location.href.includes('o=1')
        ),
        switchMap((_) => this.stateService.sales_organization$),
        switchMap((salesOrg: ApiOrganization) =>
          combineLatest([
            of(salesOrg),
            salesOrg?.id
              ? this.organizationApi.getNoOfUnhandledLeads(salesOrg.id)
              : of(0),
          ])
        ),
        map(([salesOrg, unhandledLeads]) => {
          this.unhandledLeads = unhandledLeads;
          return salesOrg;
        }),
        switchMap((salesOrg: ApiOrganization) => {
          this.sales_org = salesOrg;
          return combineLatest([
            this.stateService.organizations$,
            salesOrg
              ? this.organizationApi
                  .getRelatedCustomerOrganizationsById(salesOrg?.id)
                  .pipe(
                    takeUntil(this.destroy$),
                    map((orgs) =>
                      orgs.map(
                        (customer_org) => new ApiOrganization(customer_org)
                      )
                    )
                  )
              : of(null),
            this.stateService
              .getCustomerOrSalesOrganization()
              .pipe(RXJSUtils.filterUndefinedAndNull()),
          ]);
        })
      )
      .subscribe({
        next: ([organizations, child_organizations, activeOrg]) => {
          if (organizations && activeOrg) {
            /** Gets the current active and unrealted organization & backs them up: */
            this.activeOrganization = activeOrg;
            this.allUsersOrganizations = ObjectUtils.cloneObject(organizations);
            this.unrelatedCustomerOrgs = ObjectUtils.cloneObject(
              this.getUnrelatedCustomerOrganizations()
            );
            if (
              !this.sales_org &&
              activeOrg.type === OrganizationType.CUSTOMER_ORGANIZATION
            ) {
              /** Customer-organization-only user: */
              this.organizations = organizations;
            } else {
              /** Sales-organization user */
              (this.organizations = child_organizations),
                (this.salesOrganizations = organizations.filter(
                  (o) => o.type === OrganizationType.SALES_ORGANIZATION
                ));
              this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
              this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
                this.unrelatedCustomerOrgs
              );
            }
          }
          if (this.activeOrganization) {
            this.buildNavbarMenu(this.activeOrganization.type);
            this.ready = true;
          } else {
            this.ready = false;
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  navItemClick(item: INavbarItem) {
    this.expandedElement = null;
    if (item.path.includes('{organization_id}')) {
      this.stateService
        .getCustomerOrSalesOrganization()
        .pipe(take(1))
        .subscribe((org) => {
          const path = [...item.path];
          path[path.indexOf('{organization_id}')] = org.id;
          this.router.navigate(
            path,
            item.queryParams ? { queryParams: item.queryParams } : {}
          );
        });
      return;
    }
    this.router.navigate(
      item.path,
      item.queryParams ? { queryParams: item.queryParams } : {}
    );
  }

  selectCustomerOrganization(org): void {
    this.stateService.setCustomerOrganization(org);
    this.buildNavbarMenu(org.type);
    this.activeOrganization = org;
  }

  applyTextFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.filteredOrgs = [];
      this.filteredOrgs = this.organizations.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      this.filteredUnrelatedOrgs = [];
      this.filteredUnrelatedOrgs = this.unrelatedCustomerOrgs.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    } else {
      this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
      this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
        this.unrelatedCustomerOrgs
      );
    }
  }

  private buildNavbarMenu(orgType): void {
    this.navbarItems =
      orgType === OrganizationType.CUSTOMER_ORGANIZATION
        ? mainMenuCustomer
        : mainMenuSales;

    if (orgType === OrganizationType.SALES_ORGANIZATION) {
      // Leads item
      mainMenuSales[0].badge = this.unhandledLeads;
      this.updateNotifications(this.unhandledLeads);
    } else {
      this.updateNotifications(0, true);
    }
  }

  updateNotifications(notifications: number, clearAll: boolean = false) {
    if (!clearAll) {
      this.notifications = notifications;
    } else {
      this.notifications = 0;
    }
  }

  selectSalesOrganization(org: ApiOrganization) {
    this.stateService.setSalesOrganization(org);
    this.activeOrganization = this.sales_org;
  }

  toggleExpandedElement(selected) {
    this.expandedElement = selected === this.expandedElement ? null : selected;
  }

  /**
   * Gets unrelated (to salesOrgs) customerOrgs which the user have access to. Example scenarions;
   * - A SalesOrg/Backoffice user gets permission into another SalesOrg's customer-organization.
   * - A user gets permission into a customer with no sales organization associated.
   * @returns: ApiOrganizations[]
   */
  private getUnrelatedCustomerOrganizations(): ApiOrganization[] {
    return this.allUsersOrganizations.filter((o) => {
      return o.type == OrganizationType.CUSTOMER_ORGANIZATION &&
        (!o['organization_relations'].length ||
          o['organization_relations'].filter((relation) => {
            return (
              this.allUsersOrganizations.find(
                (org) => org.id === relation.organization_a_id
              ) == undefined
            );
          }).length)
        ? o
        : null;
    });
  }
}
