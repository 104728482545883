<app-header>
  <h2 style="margin-right: 24px" app-title *ngIf="title">{{ title }}</h2>
  <app-header-actions>
    <ng-container *appIfCommandAvailable="'create_organization_hardware_type'">
      <button
        *ngFor="let button of actionButtons"
        mat-raised-button
        pally-rounded-button-
        color="primary"
        (click)="
          actionClicked.emit({ actionId: button.id, actionValue: title })
        "
      >
        <mat-icon>{{ button.icon }}</mat-icon> {{ button.label }}
      </button>
    </ng-container>
  </app-header-actions>
</app-header>
<mat-divider></mat-divider>

<div class="sort-wrapper" *ngIf="sortOptions.length > 0">
  <div class="sort-chips">
    <span style="color: rgba(7, 10, 23, 0.6); margin-right: 1em">SORT BY</span>
    <button
      mat-stroked-button
      (click)="sorterSelected(sort)"
      *ngFor="let sort of sortOptions"
      [ngStyle]="{
        'background-color': sort.selected ? '#395692' : '#E0E0E0',
        color: sort.selected ? '#FFF' : '#232631'
      }"
    >
      {{ sort.label }}
    </button>
  </div>
</div>

<div class="cards-wrapper">
  <!-- Loading state -->
  <app-loading [message]="' '" *ngIf="isLoading"></app-loading>

  <ng-container *ngIf="sortedContent">
    <!-- No matching state -->
    <p
      *ngIf="!isLoading && !sortedContent?.length"
      style="color: rgba(0, 0, 0, 0.6)"
    >
      There are no {{ title.toLowerCase() }} matching filters.
      <ng-container
        *appIfCommandAvailable="'create_organization_hardware_type'"
      >
        <p
          style="display: inline"
          *ngFor="let button of actionButtons; let i = index"
        >
          <button
            class="linkButton"
            (click)="
              actionClicked.emit({ actionId: button.id, actionValue: title })
            "
          >
            {{ button.label }}
          </button>
          <span *ngIf="i !== actionButtons.length - 1"> or </span>
        </p>
      </ng-container>
    </p>

    <!-- List state -->
    <app-hardware-card
      *ngFor="
        let hardware of pagination > 0
          ? getPaginatedContent(page)
          : sortedContent
      "
      class="hardware-card"
      [hardwareType]="hardware"
      [shouldDisplayActionButton]="!isGlobal"
      [isBackoffice]="isBackoffice"
      [isClickable]="isGlobal"
      [selected]="isSelected(hardware)"
      (click)="clickCard(hardware)"
    >
    </app-hardware-card>
    <mat-paginator
      *ngIf="pagination > 0"
      class="paginator"
      [length]="sortedContent.length"
      [pageSize]="pagination"
      [pageIndex]="page"
      (page)="page = $event.pageIndex"
    >
    </mat-paginator>
  </ng-container>
</div>
