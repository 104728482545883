import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductionLineHandlerDataMultiple {
  casesPerMinute = new BehaviorSubject(0);
  threeViewCpm$ = new BehaviorSubject<{ [key: string]: number }>(null);

  configure(cpm: number, threeID?: string) {
    if (threeID) {
      const dict = this.threeViewCpm$.getValue() || {};
      dict[threeID] = cpm;
      this.threeViewCpm$.next(dict);
    } else {
      this.casesPerMinute.next(cpm);
    }
  }

  getCasesPerMinute(threeID?: string) {
    if (threeID) {
      return this.threeViewCpm$.pipe(map((dict) => dict[threeID] || 0));
    } else {
      return this.casesPerMinute;
    }
  }
}
