import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable, Subject } from 'rxjs';
import { IPatternTableData } from 'src/app/models_new/types/patternTableData';
import {
  ApiPattern,
  IApiPattern,
} from '../../models_new/classes/api-models/ApiPattern';
import {
  ApiProduct,
  IApiProduct,
} from '../../models_new/classes/api-models/ApiProduct';
import { ApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import { Project } from '../../models_new/classes/project';
import { ISimulationApiFileType } from '../../models_new/types/simulation-api-file-type';
import { ObjectUtils } from '../../utils/object';
import { ErrorHandlerService } from '../error-handler.service';
import { IPatternInfo } from './api.service';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class PatternApiService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchPatternById(id: string): Observable<ApiPattern> {
    const q = gql`
      query fetchPatternById {
        pattern_by_pk(id: "${id}") {
          data
          id
          name
          organization_id
          owner_id
          created_at
          description
          product_id
          updated_at
          product {
            name
            data
            id
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((data) => {
          if (data.error) {
            this.errorHandler.handleError(data.error[0].message);
            return null;
          } else {
            return ObjectUtils.cloneObject(data.data.pattern_by_pk);
          }
        })
      );
  }

  fetchPatternIDs(): Observable<IPatternInfo[]> {
    const q = gql`
      {
        pattern {
          name
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.pattern as IPatternInfo[]));
  }

  fetchPatterns(): Observable<ApiPattern[]> {
    const q = gql`
      subscription {
        pattern {
          id
          name
          data

          updated_at
          created_at

          description
          data
          name
          product {
            id
            name
          }
          simulations {
            id
            name
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          const list = [];
          for (const p of data.data.pattern) {
            const pattern = new ApiPattern(ObjectUtils.cloneObject(p));
            pattern.simulations = pattern.simulations.map(
              (m) => new ApiSimulation(m)
            );
            list.push(pattern);
          }
          return list;
        })
      );
  }

  fetchInventoryPatternsQuery(
    organizationId: string
  ): Observable<ApiPattern[]> {
    const qf = gql`
      query { 
        pattern(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          data
          description

          updated_at
          created_at

          product_id
          product {
            id
            name
            owner_id
            organization_id
            data
            created_at
            product_production_lines {
              id
            }
          }
          simulations {
            id
            name
          }
          pattern_palletizing_projects {
            palletizing_project_id
            id
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .subscribe<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            return [];
          } else {
            return data.data.pattern;
          }
        })
      );
  }

  fetchSimWizPatterns(organizationId: string) {
    const q = gql`
      subscription { 
        pattern(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          data
          product {
            name
          }
          updated_at
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data.data.pattern;
        })
      );
  }

  fetchInventoryPatternNames(
    organizationId: string = null
  ): Observable<string[]> {
    const qf = gql`
      query fetchInventoryPatternNames { 
        pattern(where: {organization_id: {_eq: "${organizationId}"}}) {
          name
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.pattern.map((m: any) => m.name);
          }
        })
      );
  }

  fetchInventoryPatterns(
    organizationId: string = null
  ): Observable<IPatternTableData[]> {
    const q = gql`
      subscription {
        pattern(order_by: { updated_at: desc }) {
          palletDimensions: data(path: "dimensions")
          productDimensions: data(path: "productDimensions")
          layers: data(path: "layers")
          layerTypes: data(path: "layerTypes")
          id
          name
          product {
            name
            id
          }
          updated_at
        }
      }
    `;
    const qf = gql`
      subscription { 
        pattern(where: {organization_id: {_eq: "${organizationId}"}}, order_by: { updated_at: desc }) {
          palletDimensions: data(path: "dimensions")
          productDimensions: data(path: "productDimensions")
          layers: data(path: "layers")
          layerTypes: data(path: "layerTypes")
          id
          name
          product { 
            name 
            id 
          }
          updated_at
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId ? qf : q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            const patterns = ObjectUtils.cloneObject(data.data.pattern);

            const setExtraProperties = (pattern: IPatternTableData): void => {
              const noOfLayers = pattern.layers.length;

              let totalLayerHeight = 0;
              let totalShimpaperHeight = 0;

              pattern.product_amount = 0;
              pattern.layers.forEach((layerType) => {
                pattern.layerTypes.forEach((layerLayout) => {
                  if (layerType === layerLayout.name) {
                    if (layerLayout.class === 'layer') {
                      totalLayerHeight += layerLayout.height;
                    }
                    if (layerLayout.class === 'separator') {
                      totalShimpaperHeight += layerLayout.height;
                    }

                    pattern.product_amount += layerLayout.pattern?.length
                      ? layerLayout.pattern.length
                      : 0;
                  }
                });
              });

              pattern.total_height =
                totalShimpaperHeight +
                totalLayerHeight +
                pattern.palletDimensions.palletHeight;
              const productArea =
                pattern.productDimensions.length *
                pattern.productDimensions.width;
              const palletArea =
                pattern.palletDimensions.length *
                pattern.palletDimensions.width;
              const cubeEfficiency =
                ((productArea * pattern.product_amount) /
                  (palletArea * noOfLayers)) *
                100;
              // Round to at most two decimals. (toFixed always gives two decimals, and the + converts to number; removing any trailing zeroes)
              pattern.cube_efficiency = +cubeEfficiency.toFixed(2);
            };

            patterns.forEach((p: any) => setExtraProperties(p));

            return patterns;
          }
        })
      );
  }

  fetchInventoryPattern(id: string): Observable<ApiPattern> {
    const q = gql`query getInventoryPattern {
      pattern_by_pk(id: "${id}") {
        id
        name
        data
        description

        updated_at
        created_at

        product_id
        product {
          id
          name
          owner_id
          organization_id
          data
          created_at
          product_production_lines {
            id
          }
        }
        simulations {
          id
          name
        }
        pattern_palletizing_projects {
          palletizing_project_id
          id
        }
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((data) => {
          const p = data.data.pattern_by_pk;
          const pattern = new ApiPattern(ObjectUtils.cloneObject(p));
          pattern.product = new ApiProduct(pattern.product);
          pattern.simulations = pattern.simulations.map(
            (m) => new ApiSimulation(m)
          );
          return pattern;
        })
      );
  }

  fetchPatternDataByPK(
    patternId: string
  ): Observable<ISimulationApiFileType['pattern']> {
    const q = gql`query getPatternDataByPK($id: uuid!) {
      pattern_by_pk(id: $id) {
        data
      }
    `;

    const variables = {
      id: patternId,
    };

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(map((data) => data.data?.pattern_by_pk?.data));
  }

  fetchPatternByName(name: string): Observable<IPatternInfo[]> {
    const q = gql`
    {
      pattern(where: {name: {_eq: "${name}"}}) {
        id
        name
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.pattern));
  }

  insertPattern(
    pattern: IApiPattern | ApiPattern,
    product: {
      id: string;
      data?: IApiProduct;
    },
    org_id: string
  ): Observable<{ id: string; name: string }> {
    const m = gql`
      mutation insertPattern(
        $name: String = ""
        $description: String = ""
        $data: json = ""
        $product_id: uuid
        $org_id: uuid!
      ) {
        insert_pattern_one(
          object: {
            name: $name
            description: $description
            data: $data
            product_id: $product_id
            organization_id: $org_id
          }
        ) {
          id
          name
        }
      }
    `;

    if (product.data) {
      // Update pattern productDimensions
      pattern.data.productDimensions = product.data.data;
    }

    const variables = {
      name: pattern.data.name || (pattern.data.name = pattern.name),
      description:
        pattern.data.description ||
        (pattern.data.description = pattern.description),
      data: pattern.data,
      product_id: product.id,
      org_id: org_id,
    };
    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(
        map((value) => {
          if (value.errors) {
            this.errorHandler.handleError(value.errors[0]);
            throw new Error(value.errors[0].message);
          } else {
            return value.data.insert_pattern_one;
          }
        })
      );
  }

  updatePattern(
    id: string,
    pattern: ApiPattern | IApiPattern,
    product_id: string
  ): Observable<{ id: string }> {
    const m = gql`
    mutation UpdatePattern($name: String!, $description: String, $product_id: uuid!, $data: json!) {
      update_pattern_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, description: $description, product_id: $product_id, data: $data}) {
        id
        name
      }
    }
    `;

    const variables = {
      name: pattern.data.name,
      description: pattern.data.description,
      data: pattern.data,
      product_id: product_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.update_pattern_by_pk));
  }

  deletePatternById(id: string): Observable<string> {
    const m = gql`
      mutation deletePattern($id: uuid!) {
        delete_pattern_by_pk(id: $id) {
          name
        }
      }
    `;

    const variables = {
      id: id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.delete_pattern_by_pk));
  }

  deletePattern(pattern: Project): Observable<any> {
    return this.deletePatternByName(pattern.data.name);
  }

  deletePatternByName(name: string): Observable<any> {
    const m = gql`
      mutation deletePattern($name: String = "") {
        delete_pattern(where: { name: { _eq: $name } }) {
          affected_rows
          returning {
            id
            name
          }
        }
      }
    `;

    const variables = {
      name: name,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data));
  }

  fetchProductInPattern(id: string): Observable<IApiProduct> {
    const q = gql`
      query getProductInPattern($id: uuid!) {
        pattern_by_pk(id: $id) {
          product {
            id
            name
          }
        }
      }
    `;
    const variables = {
      id: id,
    };
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(map((data) => data.data?.pattern_by_pk.product));
  }
}
