import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FastTrackPatternComponent } from 'src/app/components/fast-track/fast-track-pattern/fast-track-pattern.component';
import { FastTrackProductComponent } from 'src/app/components/fast-track/fast-track-product/fast-track-product.component';
import { FastTrackRegionComponent } from 'src/app/components/fast-track/fast-track-region/fast-track-region.component';
import { FastTrackUserInfoComponent } from 'src/app/components/fast-track/fast-track-user-info/fast-track-user-info.component';
import { FastTrackLandingComponent } from 'src/app/components/fast-track/fast-track-landing-page/fast-track-landing.component';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { FastTrackOutletComponent } from 'src/app/components/fast-track/fast-track-outlet/fast-track-outlet.component';
import { FastTrackResolver } from 'src/app/resolvers/fast-track-resolver.resolver';

const routes: Routes = [
  {
    path: '',
    component: FastTrackOutletComponent,
    resolve: { resolved: FastTrackResolver },
    data: {
      showToolbar: false,
    },
    children: [
      {
        path: '',
        component: FastTrackLandingComponent,
        pathMatch: 'full',
        data: {
          showToolbar: false,
        },
      },
      {
        path: pagesPATH.FAST_TRACK_PRODUCT,
        component: FastTrackProductComponent,
        data: {
          showToolbar: false,
        },
      },
      {
        path: pagesPATH.FAST_TRACK_PATTERN,
        component: FastTrackPatternComponent,
        data: {
          showToolbar: false,
        },
      },
      {
        path: pagesPATH.FAST_TRACK_USER_INFO,
        component: FastTrackUserInfoComponent,
        data: {
          showToolbar: false,
        },
      },
      {
        path: pagesPATH.FAST_TRACK_REGION,
        component: FastTrackRegionComponent,
        data: {
          showToolbar: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
