<mat-card appearance="outlined" class="customer-edit-card">
  <mat-card-header>
    <mat-card-title style="margin: 1em 0; font-size: 20px">{{
      title
    }}</mat-card-title>
  </mat-card-header>

  <div class="mat-card-content-wrapper">
    <div
      [ngClass]="
        shouldOnlyShowGuiForChangingLogo
          ? 'customer-edit-card-full'
          : 'customer-edit-card-left'
      "
    >
      <ng-container *ngIf="!shouldOnlyShowGuiForChangingLogo">
        <app-field *ngFor="let field of fields" [field]="field"></app-field>
      </ng-container>

      <ng-container *ngIf="shouldOnlyShowGuiForChangingLogo">
        <ng-container *ngTemplateOutlet="chooseLogo"></ng-container>
      </ng-container>

      <mat-card-actions>
        <ng-container *ngIf="shouldOnlyShowGuiForChangingLogo">
          <app-button
            *appIfCommandAvailable="'update_organization'"
            style="width: 100%"
            [type]="'cta'"
            [label]="'SAVE'"
            (buttonClick)="savedForm.emit(this.formGroup.value)"
          >
          </app-button>
        </ng-container>
        <ng-container *ngIf="!shouldOnlyShowGuiForChangingLogo">
          <app-button
            *appIfCommandAvailable="'update_organization'"
            style="width: 100%"
            [type]="'cta'"
            [label]="'SAVE'"
            [disabled]="formIsUnvalid$ | async"
            (buttonClick)="savedForm.emit(this.formGroup.value)"
          >
          </app-button>
        </ng-container>
      </mat-card-actions>
    </div>

    <div
      class="customer-edit-card-right"
      *ngIf="!shouldOnlyShowGuiForChangingLogo"
    >
      <ng-container *ngTemplateOutlet="chooseLogo"></ng-container>
    </div>
  </div>
</mat-card>

<ng-template #chooseLogo>
  <input
    type="file"
    accept=".png, .jpg"
    #file
    (change)="uploadFileEvent($event)"
    style="display: none"
  />

  <div class="customer-edit-logo-section">
    <ng-container *ngIf="logo$ | async as logo">
      <!-- Loading state -->
      <app-loading *ngIf="logo.isLoading"></app-loading>

      <!-- Error state -->
      <app-error
        *ngIf="logo.error as error"
        [error]="error"
        [hideActions]="true"
      ></app-error>

      <!-- Empty state -->
      <app-empty *ngIf="logo.didSucceed && logo === undefined"></app-empty>

      <ng-container *ngIf="logo.didSucceed">
        <img
          [src]="logo.value | trust: 'resourceUrl'"
          style="width: 200px; height: 200px"
        />
        <p *ngIf="logoType === 'dummy'">
          Find logo automatically via brandfetch,<br />
          or upload your own.
        </p>
        <p style="font-size: 10px" *ngIf="logoType === 'api'">
          Logo provided by Clearbit
        </p>
      </ng-container>
    </ng-container>
  </div>

  <div class="customer-edit-logo-buttons">
    <app-button
      *appIfCommandAvailable="'update_organization'"
      [type]="'edit'"
      [label]="'FIND'"
      [icon]="'search'"
      [iconPosition]="'before'"
      (buttonClick)="findLogo()"
    ></app-button>

    <app-button
      *appIfCommandAvailable="'update_organization'"
      [type]="'edit'"
      [label]="'UPLOAD YOUR LOGO'"
      [icon]="'insert_drive_file'"
      [iconPosition]="'before'"
      (buttonClick)="uploadFile()"
    ></app-button>
  </div>
</ng-template>
