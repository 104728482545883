import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { pallyVersionBackground } from 'src/app/models_new/enums/pally-background';
import { polyscopeVersionBackground } from 'src/app/models_new/enums/polyscope-background';
import { ITableAction } from '../table/table.component';
import * as PPBTimeUtil from '../../../utils/time-utils';
import { ThreeHandlerMode } from '../../../models_new/classes/3dview/three-handler-mode';
import {
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { HardwareApiService } from '../../../services/api/hardware-api.service';
import { HardwareUtils } from '../../../utils/hardware-utils';
import { IHwSwType } from '../../../models_new/types/robot-config/hw-sw-type';
import { ObjectUtils } from 'src/app/utils/object';

export type RobotActions =
  | 'report'
  | 'clone'
  | 'export'
  | 'hardware_configuration'
  | 'software_configuration'
  | 'details'
  | 'share'
  | 'modify'
  | 'delete';

interface IRowObject {
  name: string;
  key: string;
  suffix?: string;
}
interface DisplayedColumns {
  summary: IRowObject[];
  hardware: IRowObject[];
  software: IRowObject[];
}

@Component({
  selector: 'app-card-robot-config',
  templateUrl: './card-robot-config.component.html',
  styleUrls: ['./card-robot-config.component.scss'],
})
export class CardRobotConfigComponent implements OnInit, OnChanges, OnDestroy {
  get = ObjectUtils.getNested;
  @ViewChild('URVersion') urVersionView: ElementRef<HTMLDivElement>;
  @ViewChild('PallyVersion') pallyVersionView: ElementRef<HTMLDivElement>;

  @Input() robotConfig: IApiRobotConfiguration;

  @Input() selected: boolean;

  @Input() doesAllowActions: boolean;
  @Input() actions: ITableAction<RobotActions>[];
  @Output() actionClick: EventEmitter<ITableAction<RobotActions>> =
    new EventEmitter();

  timeUtils = PPBTimeUtil;
  threeViewMode: ThreeHandlerMode = ThreeHandlerMode.RobotConfig;

  fadeType = 0;
  swConfigFadeInterval: any;
  fadeDelay = 8000;

  pallyVersionBackground = pallyVersionBackground;
  polyscopeVersionBackground = polyscopeVersionBackground;

  viewMode: 'summary' | 'hardware' | 'software' = 'summary';

  displayedColumns: DisplayedColumns = {
    summary: [
      { name: 'Hardware Configuration', key: 'scene.name' },
      { name: 'Software Configuration', key: 'strategy.name' },
    ],
    hardware: [
      { name: 'Name', key: 'scene.name' },
      {
        name: 'Gripper',
        key: 'scene.data.robot.gripper.label',
      },
      { name: 'Frame', key: 'scene.data.robot.frame.label' },
      { name: 'Conveyor', key: 'scene.data.conveyors.0.label' },
      {
        name: 'Lift column',
        key: 'scene.data.robot.lift_kit.label',
      },
      { name: 'Robot', key: 'scene.data.robot.label' },
    ],
    software: [
      { name: 'Name', key: 'strategy.name' },
      { name: 'Robot OS', key: 'strategy.data.software.polyscope_version' },
      {
        name: 'Pally software version',
        key: 'strategy.data.software.pally_version',
      },
      {
        name: 'Gipper optimization',
        key: 'strategy.data.gripper_optimization',
      },
      {
        name: 'Maximum speed',
        key: 'strategy.data.max_speed',
        suffix: ' mm/s',
      },
      {
        name: 'Maximum acceleration',
        key: 'strategy.data.max_acceleration',
        suffix: ' mm/s²',
      },
      {
        name: 'Approach distance',
        key: 'strategy.data.approach_distance',
        suffix: ' mm',
      },
    ],
  };

  rcChanges$ = new ReplaySubject<IApiRobotConfiguration>(1);

  rcConfig$: Observable<IApiRobotConfiguration>;

  destroy$ = new Subject<boolean>();

  constructor(private hardwareApi: HardwareApiService) {
    this.rcConfig$ = combineLatest([
      this.rcChanges$,
      this.hardwareApi.fetchHwTypes(),
    ]).pipe(
      map(([rc, hw_types]: [IApiRobotConfiguration, IHwSwType[]]) => {
        HardwareUtils.labelifyScene(rc.scene.data, hw_types);
        return rc;
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnInit(): void {
    this.rcConfig$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(this.startSoftwareFade.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('robotConfig' in changes && changes.robotConfig.currentValue) {
      this.rcChanges$.next(changes.robotConfig.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.endSoftwareFade();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  cardStyleClass() {
    return this.selected ? 'config-card selected' : 'config-card';
  }

  setViewMode(mode: 'summary' | 'hardware' | 'software') {
    this.viewMode = mode;
  }

  startSoftwareFade() {
    this.endSoftwareFade();
    this.swConfigFadeInterval = setInterval(() => {
      if (this.fadeType === 0) {
        this.fadeType += 1;
        this.urVersionView
          ? (this.urVersionView.nativeElement.style.opacity = '0')
          : null;
        this.pallyVersionView.nativeElement.style.opacity = '1';
      } else {
        this.fadeType = 0;

        this.urVersionView
          ? (this.urVersionView.nativeElement.style.opacity = '1')
          : null;
        this.pallyVersionView.nativeElement.style.opacity = '0';
      }
    }, this.fadeDelay);
  }

  endSoftwareFade() {
    if (this.swConfigFadeInterval) clearInterval(this.swConfigFadeInterval);
  }
}
