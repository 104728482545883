import { ThreeHandlerMode } from '../classes/3dview/three-handler-mode';
import { PickerDisplayMode } from '../enums/picker-display-mode';
import { PickerType } from '../enums/picker-type';
import { FieldType } from './field-type';

export interface IPickerField {
  name: string;
  key: string;
  type?: FieldType; // Defaulting to FieldType.TEXT if no value is present
  isEditable?: boolean; // Defaulting to true if no value is present
  color?: string; // Defaulting to label-color
  visibleIn?: PickerDisplayMode[]; // Defaulting to all display modes (i.e. both listView and tableView)
  unit?: string;
  hwSwType?: string;
  enumKeys?: string[];
}

export interface IPickerDescription {
  name: string;
  fields: IPickerField[];
  threeViewMode: ThreeHandlerMode;
  sortingKeys: {
    name: string;
    lastModified: string;
    createdAt: string;
  };
  searchKey: string;
}

export function pickerDescription(type: PickerType): IPickerDescription {
  switch (type) {
    case PickerType.PRODUCTION_LINE:
      return {
        name: 'Production line',
        fields: [
          { name: 'Name', key: 'name' },
          {
            name: 'Hours of production per day',
            key: 'data.hop',
            type: FieldType.NUMBER,
          },
          {
            name: 'Products per minute',
            key: 'data.cpm',
            type: FieldType.NUMBER,
          },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: ThreeHandlerMode.ProductionLineView,
      };

    case PickerType.PRODUCT:
      return {
        name: 'Product',
        fields: [
          { name: 'Name', key: 'name' },
          { name: 'Height', key: 'data.height', unit: 'mm' },
          { name: 'Length', key: 'data.length', unit: 'mm' },
          { name: 'Weight', key: 'data.weight', unit: 'g' },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: 'productview' as ThreeHandlerMode,
      };

    case PickerType.HARDWARE_CONFIGURATION:
      return {
        name: 'Hardware configuration',
        fields: [
          { name: 'Name', key: 'name' },
          {
            name: 'Gripper',
            key: 'data.robot.gripper.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'gripper',
          },
          {
            name: 'Base',
            key: 'data.robot.frame.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'frame',
          },
          {
            name: 'Conveyor',
            key: 'data.conveyors[0].type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'conveyor',
          },
          {
            name: 'Lift column',
            key: 'data.robot.lift_kit.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'liftkit',
          },
          {
            name: 'Height extender',
            key: 'data.robot_mounting_height.position.z',
            type: FieldType.NUMBER,
          },
          {
            name: 'Robot',
            key: 'data.robot.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'robot',
          },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: ThreeHandlerMode.RobotConfig,
      };

    case PickerType.PATTERN:
      return {
        name: 'Pattern',
        fields: [
          { name: 'Name', key: 'name' },
          { name: 'Cube efficiency', key: 'cube_efficiency', unit: '%' },
          { name: 'Product', key: 'product.name' },
          { name: 'Total height', key: 'total_height', unit: 'mm' },
          { name: 'Product amount', key: 'product_amount' },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: ThreeHandlerMode.Project,
      };

    case PickerType.SOFTWARE_CONFIGURATION:
      return {
        name: 'Software configuration',
        fields: [
          { name: 'Name', key: 'name' },
          {
            name: 'Robot OS',
            key: 'data.software.polyscope_version',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'polyscope version',
          },
          {
            name: 'Pally software version',
            key: 'data.software.pally_version',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'pally version',
          },
          {
            name: 'Gipper optimization',
            key: 'data.gripper_optimization',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'gripper optimization',
          },
          {
            name: 'Maximum speed',
            key: 'data.max_speed',
            unit: 'mm/s',
            type: FieldType.NUMBER,
          },
          {
            name: 'Maximum acceleration',
            key: 'data.max_acceleration',
            unit: 'mm/s2',
            type: FieldType.NUMBER,
          },
          {
            name: 'Approach distance',
            key: 'data.approach_distance',
            unit: 'mm',
            type: FieldType.NUMBER,
          },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: ThreeHandlerMode.LogoView,
      };

    case PickerType.ROBOT_CONFIGURATION:
      return {
        name: 'Robot configuration',
        fields: [
          { name: 'Name', key: 'name' },
          { name: 'Robot', key: 'scene.data.robot.type' },
          {
            name: 'Gripper',
            key: 'scene.data.robot.gripper.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'gripper',
          },
          {
            name: 'Base',
            key: 'scene.data.robot.frame.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'frame',
          },
          {
            name: 'Conveyor',
            key: 'scene.data.conveyors[0].type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'conveyor',
          },
          {
            name: 'Lift column',
            key: 'scene.data.robot.lift_kit.type',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'liftkit',
          },
          {
            name: 'Robot OS',
            key: 'strategy.data.software.polyscope_version',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'polyscope version',
          },
          {
            name: 'Pally software version',
            key: 'strategy.data.software.pally_version',
            type: FieldType.SELECT_SINGLE,
            hwSwType: 'pally version',
          },
        ],
        sortingKeys: {
          name: 'name',
          lastModified: 'updated_at',
          createdAt: 'created_at',
        },
        searchKey: 'name',
        threeViewMode: ThreeHandlerMode.RobotConfig,
      };

    case PickerType.ROBOT_LICENSE:
      return {
        name: 'Robots',
        fields: [],
        sortingKeys: undefined,
        searchKey: 'name',
        threeViewMode: undefined,
      };
  }
}
