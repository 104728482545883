import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { Role } from 'src/app/models_new/types/role';
import { ErrorHandlerService } from '../error-handler.service';
import { ClientApiService } from './client-api.service';

export type assetType =
  | 'logo'
  | 'documentation'
  | 'scene_image'
  | 'zip'
  | 'license';

export interface assetData {
  asset_type?: assetType;
  data?: string;
  file_type: string;
  name: string;
  organization_id?: string;
  id?: string;
}

export interface IUploadAsset {
  id: string;
  url: string;
  backup_data?: {
    robot_serial_number: string;
    mac_address: string;
    backup_zip_id: string;
    robot_configuration_id: string;
  };
}

export interface IGetAssetResult {
  assets_by_pk: {
    url: string;
  };
}

export interface IGetAssetByOrg {
  id: string;
  name: string;
  type: assetType;
  file_type: string;
  url: string;
}

const GET_ASSET_BY_ID_QUERY = gql`
  query GetAssetById($assetId: uuid!) {
    assets_by_pk(id: $assetId) {
      url
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AssetApiService {
  constructor(
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {}

  getAsset(assetId: string): Observable<string> {
    const q = gql`
      query getAsset($assetId: String!) {
        getAsset(id: $assetId) {
          data
        }
      }
    `;

    const variables = {
      assetId: assetId,
    };

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        variables: variables,
      })
      .pipe(
        map((result) => {
          if (result.errors) {
            console.error(result.errors[0]);
            return null;
          } else {
            return result.data?.getAsset?.data;
          }
        })
      );
  }

  updateAsset(
    assetData: assetData,
    role: Role = 'org_edit'
  ): Observable<IUploadAsset> {
    const mutation = gql`
      mutation updateAsset(
        $data: String
        $file_type: String!
        $name: String!
        $id: String!
      ) {
        updateAsset(file_type: $file_type, name: $name, data: $data, id: $id) {
          id
          url
        }
      }
    `;

    const variables = {
      data: assetData.data,
      file_type: assetData.file_type,
      name: assetData.name,
      id: assetData.id,
    };

    // Specifying the result type, gives you a typed result
    interface Result {
      updateAsset: {
        id: string;
        url: string;
      };
    }

    const options = {
      mutation,
      variables,
    };

    return this.clientApi
      .useClient(role)
      .mutate<Result>(options)
      .pipe(map((result) => result?.data?.updateAsset));
  }

  uploadAsset(
    assetData: assetData,
    role: Role = 'org_edit',
    isBackups: boolean = false
  ): Observable<IUploadAsset> {
    const mutation = gql`
      mutation uploadAsset(
        $asset_type: String!
        $data: String!
        $file_type: String!
        $name: String!
        $organization_id: String!
        $isRobotBackup: Boolean!
      ) {
        uploadAsset(
          asset_type: $asset_type
          file_type: $file_type
          name: $name
          organization_id: $organization_id
          data: $data
          isRobotBackup: $isRobotBackup
        ) {
          id
          url
          backup_data
        }
      }
    `;

    const variables = {
      asset_type: assetData.asset_type,
      data: assetData.data,
      file_type: assetData.file_type,
      name: assetData.name,
      organization_id: assetData.organization_id,
      isRobotBackup: isBackups,
    };

    // Specifying the result type, gives you a typed result
    interface Result {
      uploadAsset: {
        id: string;
        url: string;
        backup_data?: any;
      };
    }

    const options = {
      mutation,
      variables,
    };

    return this.clientApi
      .useClient(role)
      .mutate<Result>(options)
      .pipe(
        map((result) => {
          if (result.errors) {
            this.errorHandler.handleError(result.errors[0]);
            return { id: null, url: null };
          } else {
            const asset = result?.data?.uploadAsset;

            if (asset?.backup_data) {
              asset.backup_data = JSON.parse(asset.backup_data);
            }

            return asset;
          }
        })
      );
  }

  getAssetData(
    id: string,
    role: Role
  ): Observable<ApolloQueryResult<IGetAssetResult>> {
    return this.clientApi
      .useClient(role)
      .query<IGetAssetResult>({
        query: GET_ASSET_BY_ID_QUERY,
        variables: {
          assetId: id,
        },
      })
      .pipe(
        map((result) => {
          if (result.errors) {
            this.errorHandler.handleError(result.errors[0]);
            return null;
          } else {
            return result;
          }
        })
      );
  }

  getAssetByOrganizationId(
    organizationId: string
  ): Observable<IGetAssetByOrg[]> {
    const q = gql`
      query getAssetByOrganizationId($organizationId: uuid!) {
        assets(where: { organization_id: { _eq: $organizationId } }) {
          file_type
          id
          name
          type
          url
        }
      }
    `;

    const variables = {
      organizationId: organizationId,
    };

    return this.clientApi
      .useClient('org_edit')
      .query<any>({
        query: q,
        variables: variables,
      })
      .pipe(map((result) => (result.errors ? null : result.data?.assets)));
  }

  deleteAsset(id: string) {
    const mutation = gql`
      mutation deleteAsset($image_asset_id: String!) {
        deleteAsset(id: $image_asset_id) {
          success
        }
      }
    `;
    const variables = {
      image_asset_id: id,
    };

    const options = {
      mutation,
      variables,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<{ deleteAsset: { success: boolean } }>(options)
      .pipe(
        map((result) => {
          if (result.errors) {
            this.errorHandler.handleError(new Error('Delete asset failed'));
            this.errorHandler.handleError(result.errors[0]);
            return true;
          } else {
            return result.data.deleteAsset.success;
          }
        })
      );
  }
}
