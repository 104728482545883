import { pallyFileValuesValidators } from '../../config/validation/pally-file-values';
import { ErrorType } from '../../enums/error-type';
import { IExportImportResult } from '../../types/export-import-result';
import { IPallyFileType, IPallyLayer } from '../../types/pally-file-type';

export class ImportValuesValidator {
  errors: IExportImportResult[] = [];
  errorType: ErrorType = ErrorType.MAJOR;
  rules = pallyFileValuesValidators;

  constructor(
    json: IPallyFileType,
    checkEmptyID?: boolean,
    checkEmptyName?: boolean
  ) {
    const addError = (
      key: string,
      value: string,
      errors: (boolean | string)[]
    ) => {
      if (errors.length) {
        errors.forEach((err: boolean | string) => {
          if (
            (checkEmptyID && key === 'id' && !value) ||
            (checkEmptyName && key === 'name' && !value)
          ) {
            return;
          }

          /** Ex: 'name is '$Yolo$' - Allowed characters are: a-z' */
          this.errors.push({
            message: `${key} is '${value}' - ${err}`,
            type: this.errorType,
          });
        });
      }
    };

    for (const data in json) {
      if (
        data === 'dimensions' ||
        data === 'productDimensions' ||
        data === 'guiSettings'
      ) {
        const dimname =
          data === 'dimensions'
            ? 'Pallet'
            : data === 'productDimensions'
            ? 'Box/Product'
            : 'Gui settings';

        for (const dim in json[data]) {
          if (this.rules[data][dim]) {
            const errors = this.rules[data][dim](json[data][dim]).filter(
              (f) => f !== true
            );
            addError(dimname + ' ' + dim, json[data][dim], errors);
          }
        }
      } else if (data === 'layers') {
        // Validate that layers have matching layerTypes
        for (const layerName in json[data]) {
          if (json[data].hasOwnProperty(layerName)) {
            if (json.layerTypes && json.layerTypes.length) {
              const allLayerTypes = json.layerTypes.map(
                (m: IPallyLayer) => m.name
              );
              const layerType = allLayerTypes.filter(
                (f: string) => f === json[data][layerName]
              );

              if (!layerType.length) {
                addError(`'${json[data][layerName]}'`, 'not found', [
                  'layerTypes are: ' + allLayerTypes.join(', '),
                ]);
              }
            } else {
              addError('layerTypes', 'not in json', []);
            }
          }
        }
      } else if (data === 'layerTypes') {
        // Validate layerTypes
        for (const layer in json[data]) {
          if (json[data].hasOwnProperty(layer) && json.layerTypes.length) {
            // Validate that layerTypes dont have duplicated naming
            const allLayerTypes = json.layerTypes.map(
              (m: IPallyLayer) => m.name
            );
            const findLayerName = allLayerTypes.filter(
              (f: string) => f === json[data][layer].name
            );
            if (findLayerName.length > 1) {
              addError(json[data][layer].name, 'duplicated', [
                `${json[data][layer].name} found ${findLayerName.length} times`,
              ]);
            }

            for (const layerKey in json[data][layer]) {
              // Validate pattern and altPattern
              if (layerKey === 'pattern' || layerKey === 'altPattern') {
                for (const box in json[data][layer][layerKey]) {
                  if (json[data][layer][layerKey].hasOwnProperty(box)) {
                    // Validate boxes
                    for (const boxKey in json[data][layer][layerKey][box]) {
                      if (this.rules[data][layerKey][boxKey]) {
                        const errors = this.rules[data][layerKey][boxKey](
                          json[data][layer][layerKey][box][boxKey]
                        ).filter((f) => f !== true);
                        addError(
                          boxKey +
                            ' in box no ' +
                            box +
                            ' in ' +
                            json[data][layer].name,
                          json[data][layer][layerKey][box][boxKey],
                          errors
                        );
                      }
                    }
                  }
                }

                // Validate layer
              } else {
                if (this.rules[data][layerKey]) {
                  const errors = this.rules[data][layerKey](
                    json[data][layer][layerKey]
                  ).filter((f) => f !== true);
                  const layerName = json[data][layer].name
                    ? 'named ' + json[data][layer].name
                    : '';
                  addError(
                    'Layer ' + layerName + ' ' + layerKey,
                    json[data][layer][layerKey],
                    errors
                  );
                }
              }
            }
          }
        }
      } else {
        if (this.rules[data]) {
          const errors = this.rules[data](json[data]).filter((f) => f !== true);
          addError(data, json[data], errors);
        }
      }
    }
  }
}
