import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import {
  JointNames,
  LinkNames,
} from 'src/app/services/project-robot-descriptor.service';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { milliToMeter } from '../../../../utils/div';

export class ConveyorGuideTask extends Task {
  static [taskNameSymbol] = 'Conveyor guide';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateConveyorGuide();

    const dimvis = this.activateDimVis(
      SimConfigFieldIds.ConveyorRightGuideWidth,
      {
        value: this.readGuideWidth(),
      },
      this.readShowVisualizers() ? -1 : undefined,
      false
    );
    const conveyorGuideOffsetXJoint = this.robot.getJointByID(
      JointNames.ConveyorGuideOffsetX
    );
    conveyorGuideOffsetXJoint.add(dimvis);

    // Probably an additional task (data = undefined) OR Box free height changed
    if (this.data?.fieldId === SimConfigFieldIds.ConveyorBoxFreeHeight) {
      const conveyorBoxFreeLink = this.robot.getLinkByID(
        LinkNames.ConveyorBoxFreeHeight
      );
      const end = conveyorBoxFreeLink.getWorldPosition(new THREE.Vector3());
      const boxFreeHeight = this.readBoxFreeHeight();
      end.y += boxFreeHeight / 1000;
      this.activateDimVis(
        SimConfigFieldIds.ConveyorBoxFreeHeight,
        {
          value: `${boxFreeHeight} mm`,
          end: end,
        },
        this.readShowVisualizers() ? -1 : undefined
      );
    }

    resolve();
  }

  public updateConveyorGuide(): void {
    const conveyorGuideJoint = this.robot.getJointByID(
      JointNames.ConveyorGuide
    );
    const conveyorBoxFreeJoint = this.robot.getJointByID(
      JointNames.ConveyorBoxFreeHeight
    );
    const conveyorBoxFreeLink =
      URDFUtils.findLinkFromJoint(conveyorBoxFreeJoint);
    const conveyorGuideOffsetXJoint = this.robot.getJointByID(
      JointNames.ConveyorGuideOffsetX
    );
    const conveyorGuideOffsetXLink = URDFUtils.findLinkFromJoint(
      conveyorGuideOffsetXJoint
    );
    const conveyorGuideLink = URDFUtils.findLinkFromJoint(conveyorGuideJoint);
    const conveyorGuideVisual =
      URDFUtils.findVisualFromJoint(conveyorGuideJoint);

    const type = this.readConveyorType();
    const dimension = this.readConveyorDimension();
    const isGuideSideRIGHT = this.isGuideSideRIGHT();
    const guideSide = isGuideSideRIGHT ? 1 : -1;
    const guideWidth = this.readGuideWidth();

    conveyorBoxFreeJoint.position.set(0, 0, 0);
    conveyorBoxFreeJoint.rotation.set(0, 0, 0);
    conveyorBoxFreeJoint.scale.set(1, 1, 1);

    conveyorBoxFreeLink.position.set(dimension.x / 2, 0, 0);
    conveyorBoxFreeLink.rotation.set(0, 0, 0);
    conveyorBoxFreeLink.scale.set(1, 1, 1);

    conveyorGuideOffsetXJoint.position.set(0, 0, 0);
    conveyorGuideOffsetXJoint.rotation.set(0, 0, 0);
    conveyorGuideOffsetXJoint.scale.set(1, 1, 1);

    conveyorGuideOffsetXLink.position.set((dimension.x / 2) * guideSide, 0, 0);
    conveyorGuideOffsetXLink.rotation.set(0, 0, 0);
    conveyorGuideOffsetXLink.scale.set(1, 1, 1);

    conveyorGuideJoint.position.set(0, 0, 0);
    conveyorGuideJoint.rotation.set(0, 0, 0);
    conveyorGuideJoint.scale.set(1, 1, 1);

    // Counteracts dimension offset to the side
    conveyorGuideLink.position.set(guideWidth * (guideSide * -1), 0, 0);
    conveyorGuideLink.rotation.set(0, 0, 0);
    conveyorGuideLink.scale.set(1, 1, 1);

    conveyorGuideVisual.position.set(0.025 * guideSide, length / 2, 0.05);
    conveyorGuideVisual.rotation.set(0, 0, 0);
    conveyorGuideVisual.scale.set(0.05, length, 0.1);

    if (type === 'CUSTOM') {
      ThreeUtils.disposeObject(conveyorGuideVisual.children);
      conveyorGuideVisual.children = [];
      const guideGeo = new THREE.BoxGeometry(1, 1, 1);
      const guideMat = new THREE.MeshLambertMaterial({ color: '#B6B6B6' });
      const guideMesh = new THREE.Mesh(guideGeo, guideMat);
      conveyorGuideVisual.add(guideMesh);
    } else {
      ThreeUtils.disposeObject(conveyorGuideVisual.children);
      conveyorGuideVisual.children = [];
    }
  }

  protected readConveyorType(): string {
    return this.readField(SimConfigFieldIds.ConveyorRightType);
  }

  protected readConveyorDimension(dimension?: THREE.Vector3): THREE.Vector3 {
    const tempDim: Array<any> = this.readFields([
      SimConfigFieldIds.ConveyorRightWidth,
      SimConfigFieldIds.ConveyorRightLength,
      SimConfigFieldIds.ConveyorRightHeight,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempDim.length; i++) {
      if (tempDim[i] === -0 || tempDim[i] === '-0') {
        tempDim[i] = 0;
      } else if (Type.isOfType(tempDim[i], 'string')) {
        tempDim[i] = +tempDim[i];
      }
    }

    const dim = new THREE.Vector3(
      milliToMeter(tempDim[0]),
      milliToMeter(tempDim[1]),
      milliToMeter(tempDim[2])
    );

    if (Type.isDefined(dimension)) {
      dimension.copy(dim);
    }

    return dim;
  }

  protected isGuideSideRIGHT(): boolean {
    return !(this.readGuideSide() > 0);
  }

  protected readGuideSide(): number {
    let side = this.readField<string>(SimConfigFieldIds.ConveyorRightGuideSide);
    return side.toLowerCase() === 'left' ? 1 : -1;
  }

  protected readGuideWidth(): number {
    let height = this.readField<number>(
      SimConfigFieldIds.ConveyorRightGuideWidth
    );
    // To mitigate "-0" which messes with later calculations
    if (height === -0) {
      height = 0;
    } else if (Type.isOfType(height, 'string')) {
      height = +height;
    }
    return milliToMeter(height) as number;
  }

  protected readBoxFreeHeight(): number {
    let height = this.readField<number>(
      SimConfigFieldIds.ConveyorBoxFreeHeight
    );
    // To mitigate "-0" which messes with later calculations
    if (height === -0) {
      height = 0;
    } else if (Type.isOfType(height, 'string')) {
      height = +height;
    }
    return milliToMeter(height) as number;
  }
}
