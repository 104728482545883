import {
  EventEmitter,
  Component,
  Input,
  Output,
  ContentChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { IFilterData } from 'src/app/models_new/types/filter-data';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';

/**
 * Wrapper around tables and card-list.
 * Provides switching between the two
 * as well as common input such as
 * filter/sort buttons and search field
 *
 * ******* USAGE ********
 * <app-content-switcher>
 *  <ng-template #table      <---- mark as table
 *  >
 *    <app-someTable></app-someTable>
 *  </ng-template>
 *
 *  <ng-template #cardList   <---- mark as card-list
 *  >
 *    <app-someCardList></app-someCardList>
 *  </ng-template>
 *
 * </app-content-switcher>
 */

@Component({
  selector: 'app-content-switcher',
  templateUrl: './content-switcher.component.html',
  styleUrls: ['./content-switcher.component.scss'],
})
export class ContentSwitcherComponent implements OnInit {
  @ContentChild('table') tableRef: TemplateRef<any>;
  @ContentChild('cardList') cardRef: TemplateRef<any>;

  /** Input params */
  @Input() selected: any[] = [];
  @Input() enableToggle: boolean = false;
  @Input() enableSearch: boolean = true;
  @Input() enableDelete: boolean = true;
  @Input() enableFiltering?: boolean = true;
  @Input() enableSorting?: boolean = true;
  @Input() objectType?:
    | 'hardware'
    | 'software'
    | 'robot_configuration'
    | 'production_line'
    | 'product'
    | 'pattern'
    | 'project'
    | 'simulation'
    | 'installed_robot';
  // Filtering and sorting
  @Input() setupFilter: ISortingOption[] = undefined;
  @Input() resultFilter: IFilterData[] = undefined;
  @Input() sortingColumns: ISortingOption[] = undefined;
  @Input() initSort?: {
    column: string;
    order: SortDirection;
  };
  @Input() viewCompare?: boolean = false;
  @Input() defaultMode?: 'table' | 'card-list' = 'table';

  /** Output params */
  @Output() selectedFiltering: EventEmitter<FilterTableData> =
    new EventEmitter<FilterTableData>();
  @Output() order: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() modeChange: EventEmitter<'table' | 'card-list'> = new EventEmitter<
    'table' | 'card-list'
  >();
  @Output() compare: EventEmitter<boolean> = new EventEmitter<boolean>();

  mode: 'table' | 'card-list';

  filterSortObj: FilterTableData = new FilterTableData();

  ngOnInit(): void {
    this.mode = this.defaultMode;
  }

  switchContent() {
    this.mode = this.mode === 'table' ? 'card-list' : 'table';
    this.modeChange.emit(this.mode);
  }

  searchEvent(event) {
    this.filterSortObj.searchText = event.target.value;
    this.search.emit(event.target.value);
    this.selectedFiltering.emit(this.filterSortObj);
  }

  updateFiltering(filtering: FilterTableData) {
    this.filterSortObj.resultFilter = filtering.resultFilter;
    this.filterSortObj.resultSlider = filtering.resultSlider;
    this.filterSortObj.setupFilter = filtering.setupFilter;
    this.filterSortObj.setupFilterSingle = filtering.setupFilterSingle;
    this.selectedFiltering.emit(this.filterSortObj);
  }

  updateSorting(orderBy) {
    this.order.emit(orderBy);
  }
}
