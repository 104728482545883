import { ProjectData } from './project-data';
import { PalletView } from './pallet-view';
import { PalletPosition } from '../enums/pallet-position';
import { Pallet } from './pallet';
import { PalletAdvancedSettings } from './pallet-advanced-settings';
import { defaultProject } from '../config/default/default-project';
import { IProject } from '../types/project';
import { Layer } from './layer';
import { LayerType } from '../enums/layer-type';
import { BehaviorSubject, merge } from 'rxjs';
import { IProjectUpdate } from '../types/project-update';
import { UpdateAction } from '../enums/update-action';
import { IPalletUpdate } from '../types/pallet-update';
import { UpdateActionsConfig } from '../config/update-actions';
import { map } from 'rxjs/operators';
import { LabelOrientation } from '../enums/label-orientation';
import { ObjectUtils } from 'src/app/utils/object';
import { IPallyZone } from '../types/pally-file-type';

export class Project implements IProject {
  data: ProjectData;
  activePallet: PalletPosition;
  pallets: Pallet[];
  archive: Pallet[];
  palletView: PalletView;
  palletAdvancedSettings: PalletAdvancedSettings;

  zones?: IPallyZone[];
  altZones?: IPallyZone[];

  update$ = new BehaviorSubject<IProjectUpdate>(null);

  constructor(origin: string, project?: IProject) {
    const p = project ? project : ObjectUtils.cloneObject(defaultProject);

    // Set pallet view label orientation
    p.palletView.palletViewSettings.labelOrientation =
      p.data.box.label.orientation;

    this.data = new ProjectData(p.data);
    this.activePallet = p.activePallet;
    this.pallets = p.pallets.map((m) => new Pallet(m.position, m));
    this.archive = p.archive.map((m) => new Pallet(m.position, m));
    this.palletView = new PalletView(
      p.palletView.palletViewSettings,
      p.palletView.palletViewLayersNo
    );
    this.palletAdvancedSettings = new PalletAdvancedSettings(
      p.palletAdvancedSettings,
      'project'
    );

    if (this.archive && this.archive.length) {
      this.archive.forEach((pa: Pallet) => pa.setId(this.getNewPalletId()));
    }

    if (this.pallets && this.pallets.length) {
      this.pallets.forEach((pallet: Pallet) =>
        pallet.setId(this.getNewPalletId())
      );
      this.subscribeToPalletUpdates();
    }
    if (this.palletView && this.palletAdvancedSettings) {
      this.subscribeToSettingsUpdates();
    }

    /**
     * Adds update.save if not in ignore list
     * Data service subscribes to project.update$
     */
    this.update$
      .pipe(
        map((u: IProjectUpdate) => {
          if (u && UpdateActionsConfig.ignoreAction(u.updateAction)) {
            u.save = false;
          }
          if (u && !UpdateActionsConfig.ignoreAction(u.updateAction)) {
            u.save = true;
          }

          if (u) {
            this.updateAllPalletsData();
            this.updateAllBoxesData();
          }
        })
      )
      .subscribe();
  }

  subscribeToSettingsUpdates() {
    merge(
      this.palletView.update$,
      this.palletAdvancedSettings.update$
    ).subscribe((u: IProjectUpdate) => {
      if (u) {
        this.update(null, u.label as string[], u.updateAction);
      }
    });
  }

  subscribeToPalletUpdates() {
    const sources: BehaviorSubject<IPalletUpdate>[] = [];
    this.pallets.forEach((p: Pallet) => {
      sources.push(p.update$);
    });

    if (sources.length) {
      merge(...sources).subscribe((u: IPalletUpdate) => {
        if (u) {
          if (
            u.updateAction === UpdateAction.ADD_NEW ||
            u.updateAction === UpdateAction.DUPLICATED_PREV ||
            u.updateAction === UpdateAction.ROTATED_PREV ||
            u.updateAction === UpdateAction.MIRRORED_PREV_HORIZ ||
            u.updateAction === UpdateAction.MIRRORED_PREV_VERT ||
            u.updateAction === UpdateAction.DUPLICATE
          ) {
            this.setViewAllLayers();
          }

          this.update(u.palletPosition, u.label as string[], u.updateAction);
        }
      });
    }
  }

  /**
   * @param palletPosition: PalletPosition
   * @param label: string[]
   * @param updateAction: UpdateAction
   */
  update(
    palletPosition: PalletPosition,
    label: string[] = [''],
    updateAction: UpdateAction
  ) {
    this.update$.next({
      updateAction: updateAction,
      palletPosition: palletPosition,
      label: label,
    });
  }

  addPallet(pallet: Pallet) {
    pallet.setId(this.getNewPalletId());
    this.archive.unshift(pallet);
  }

  removePalletById(id: string): void | Error {
    const pallet = this.getPalletById(id);
    if (pallet) {
      this.archive = this.archive.filter((p: Pallet) => p.id !== id);
    } else {
      throw new Error('Cannot remove pallet. No pallet with id: ' + id);
    }
  }

  getNewPalletId(): number {
    let unique;
    let newId;
    const archiveLength = this.archive ? this.archive.length : '0';

    const makeNewId = () => {
      unique = Math.floor(Math.random() * 10000);
      newId = archiveLength + '-' + unique;
      return newId;
    };

    while (this.getPalletById(makeNewId())) {} // Generate new ones until a unique one is found

    return unique;
  }

  getPalletById(id: string): Pallet {
    const pallets = this.pallets ? this.pallets : [];
    const archive = this.archive ? this.archive : [];

    const concat = [...pallets, ...archive];
    const pallet = concat.filter((p: Pallet) => p.id === id);
    return pallet.length ? pallet[0] : null;
  }

  /**
   * @param palletPosition: PalletPosition
   */
  getPalletNameByPosition(palletPosition: PalletPosition): string {
    return palletPosition === 0 ? 'left pallet' : 'right pallet';
  }

  /**
   * @param palletPosition: PalletPosition
   */
  getPalletByPosition(palletPosition: PalletPosition): Pallet {
    const pallet = this.pallets.filter(
      (p: Pallet) => p.position === palletPosition
    );
    return pallet.length ? pallet[0] : null;
  }

  getProjectData(): ProjectData {
    return this.data;
  }

  getPalletData(): ProjectData['pallet'] {
    return this.data.getPalletData();
  }

  getBoxData(): ProjectData['box'] {
    return this.data.getBoxData();
  }

  getActivePalletPosition(): PalletPosition {
    return this.activePallet;
  }

  getActivePallet(): Pallet {
    const pallets: Pallet[] = this.pallets.filter(
      (pallet: Pallet) => pallet.position === this.activePallet
    );
    if (pallets.length) {
      return pallets[0];
    } else {
      throw new Error(
        `Cannot find any pallets at active pallet position: ${this.activePallet}`
      );
    }
  }

  /**
   * @param palletPosition: PalletPosition
   * @param index: number
   */
  getLayerByIndex(palletPosition: PalletPosition, index: number): Layer {
    const pallet = this.getPalletByPosition(palletPosition);
    return pallet.getLayerByIndex(index);
  }

  /**
   * @param palletPosition: PalletPosition
   * @param id: string
   */
  getLayerById(palletPosition: PalletPosition, id: string): Layer {
    const pallet = this.getPalletByPosition(palletPosition);
    return pallet.getLayerById(id);
  }

  getPallets(): Pallet[] {
    return this.pallets;
  }

  getPalletView(): PalletView {
    return this.palletView;
  }

  getPalletAdvancedSettings(): PalletAdvancedSettings {
    return this.palletAdvancedSettings;
  }

  setMaxGrip(maxGrip: number) {
    this.data.box.maxGrip = maxGrip;
    this.update$.next({
      updateAction: UpdateAction.SET_MAX_GRIP,
    });
  }

  setShimpaperHeight(height: number) {
    this.data.shimPaper.height = height;
    this.update$.next({
      updateAction: UpdateAction.SET_SHIMPAPER_HEIGHT,
    });
  }

  setLabelOrientation(orientation: LabelOrientation) {
    if (orientation !== null && orientation !== undefined) {
      this.data.box.label.enabled = true;
    }
    if (orientation === null) {
      this.data.box.label.enabled = false;
    }

    this.data.box.label.orientation = orientation;

    this.update$.next({
      updateAction: UpdateAction.SET_LABEL_ORIENTATION,
    });
  }

  /**
   * @param name: string
   */
  setProjectName(name: string): void {
    this.data.name = name;

    this.update$.next({
      updateAction: UpdateAction.SET_PROJECT_NAME,
    });
  }

  /**
   * @param desc: string
   */
  setProjectDescription(desc: string): void {
    this.data.description = desc;

    this.update$.next({
      updateAction: UpdateAction.SET_PROJECT_DESC,
    });
  }

  /**
   * @param active: PalletPosition
   */
  setActivePallet(active: PalletPosition): void {
    this.activePallet = active;

    this.update$.next({
      updateAction: UpdateAction.ACTIVE_PALLETS,
    });
  }

  setViewAllLayers(): void {
    const currentLayersLength = this.getActivePallet().layers.length;
    this.palletView.setLayersView(currentLayersLength);
  }

  /**
   * Updates all pallet data from projectData
   */
  updateAllPalletsData(): void {
    const projectData = this.getProjectData();

    this.getPallets().forEach((p: Pallet) => {
      p.setOverhangSides(projectData.pallet.overhang.sides);
      p.setOverhangEnds(projectData.pallet.overhang.ends);
      p.setPalletHeight(projectData.pallet.dimensions.palletHeight);
      p.setPalletMaxHeight(projectData.pallet.dimensions.loadHeight);
      p.setPalletLength(projectData.pallet.dimensions.length);
      p.setPalletWidth(projectData.pallet.dimensions.width);
      p.updatePalletDynamic();
    });
  }

  /**
   * Updates all box data from projectData
   */
  updateAllBoxesData(): void {
    const projectData = this.getProjectData();

    this.getPallets().forEach((p: Pallet) => {
      const layers = p.getLayersByType(LayerType.LAYER);
      if (layers.length) {
        layers.forEach((l: Layer) => {
          l.updateBoxesData(this.getProjectData());
          l.setHeight();
        });
      }

      const shimpapers = p.getLayersByType(LayerType.SHIMPAPER);
      if (shimpapers.length) {
        shimpapers.forEach((l: Layer) => {
          l.height = !l.preserved ? projectData.shimPaper.height : l.height;
        });
      }
    });
  }

  setBoxLogo(b64: string): void {
    this.getProjectData().box.logo = {
      imageData: b64,
    };
  }
}
