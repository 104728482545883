import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  debounceTime,
  filter,
  shareReplay,
  skipWhile,
  switchMap,
  map,
  take,
  tap,
  startWith,
} from 'rxjs/operators';
import { IllegalOrganizationSubscriptionComponent } from '../components/dialogs/illegal-organization-subscription/illegal-organization-subscription.component';
import {
  ApiOrganization,
  IApiOrganization,
  IOrganizationInviteData,
} from '../models_new/classes/api-models/ApiOrganization';
import { pagesPATH } from '../models_new/config/pages';
import { DialogSize } from '../models_new/enums/dialogSize';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { OrganizationType } from '../models_new/enums/organization-type';
import { IHasuraUser } from '../models_new/types/hasura-user';
import { OrganizationApiService } from '../services/api/organization-api.service';
import { UserApiService } from '../services/api/user-api.service';
import { DialogService } from '../services/dialog.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import {
  LocalStorageService,
  StorageMethod,
} from '../services/local-storage.service';
import { ObjectUtils } from '../utils/object';
import { RXJSUtils } from '../utils/rxjs-utils';

interface IStoredState {
  salesOrg: string;
  customerOrg: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  user$: Observable<IHasuraUser>;
  sales_organization$: BehaviorSubject<ApiOrganization> =
    new BehaviorSubject<ApiOrganization>(null);
  customer_organization$: BehaviorSubject<ApiOrganization> =
    new BehaviorSubject<ApiOrganization>(null);
  organizations$: BehaviorSubject<ApiOrganization[]> = new BehaviorSubject<
    ApiOrganization[]
  >(null);
  organizationInvites$: Observable<IOrganizationInviteData[]>;
  isUserLogged$: Observable<boolean>;

  constructor(
    private auth: AuthService,
    private organizationApi: OrganizationApiService,
    private userApi: UserApiService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private errorHandler: ErrorHandlerService,
    private dialogService: DialogService
  ) {
    this.user$ = this.auth.user$.pipe(
      skipWhile((user: User) => !user),
      switchMap((u: User) =>
        this.userApi.getUserData(u.sub).pipe(
          map((hasuraUser: IHasuraUser) => {
            if (!hasuraUser) {
              this.errorHandler.navigateToUserErrors([
                new Error('No user with id: ' + u.sub),
              ]);
              return null;
            } else if (
              !hasuraUser.metadata ||
              !hasuraUser.metadata.firstName ||
              !hasuraUser.metadata.lastName ||
              !hasuraUser.metadata.country
            ) {
              this.router.navigate([pagesPATH.USER, pagesPATH.METADATA_EDIT]);
              return null;
            } else {
              const hUser: IHasuraUser = ObjectUtils.cloneObject(hasuraUser);
              hUser.metadata.picture = u.picture ? u.picture : '';
              hUser.id = u.sub;
              return hUser;
            }
          })
        )
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.organizationInvites$ = this.user$.pipe(
      skipWhile((user: IHasuraUser) => !user),
      map((user) => user.id),
      filter(Boolean),
      switchMap((userId) =>
        this.organizationApi.fetchOrganizationInvitesForUser(userId)
      )
    );

    this.user$
      .pipe(
        skipWhile((user: IHasuraUser) => !user),
        switchMap((user: IHasuraUser) => {
          if (user.organization_members.length) {
            return this.organizationApi.getOrganizationsLight();
          } else {
            return of(null);
          }
        }),
        switchMap((_) => this.organizationApi.getOrganizationsLight()),
        tap((orgs: IApiOrganization[]) => {
          if (window.location.pathname === '/user/sign-up/setup') {
            return;
          }
          if (!orgs || !orgs.length) {
            this.router.navigate([pagesPATH.USER, pagesPATH.USER_SIGN_UP]);
          } else {
            let orgz = this.setOrganizations(orgs);
            const sales_orgs = orgz.filter(
              (f) => f.type === OrganizationType.SALES_ORGANIZATION
            );
            const customer_orgs = orgz.filter(
              (f) => f.type === OrganizationType.CUSTOMER_ORGANIZATION
            );

            // If any sales orgs, select a sales org.
            if (sales_orgs.length) {
              if (this.getStoredState?.salesOrg) {
                if (orgz.find((f) => f.id === this.getStoredState.salesOrg)) {
                  this.setSalesOrganization(
                    orgz.find((f) => f.id === this.getStoredState.salesOrg),
                    true
                  );
                } else {
                  sales_orgs.length > 1
                    ? this.router.navigate([
                        pagesPATH.USER,
                        pagesPATH.LANDING_PAGE,
                      ])
                    : this.setSalesOrganization(sales_orgs[0], true);
                }
              } else {
                sales_orgs.length > 1
                  ? this.router.navigate([
                      pagesPATH.USER,
                      pagesPATH.LANDING_PAGE,
                    ])
                  : this.setSalesOrganization(sales_orgs[0], true);
              }
              if (this.getStoredState?.customerOrg) {
                this.setCustomerOrganization(
                  orgz.find((f) => f.id === this.getStoredState.customerOrg),
                  true
                );
              }
            } else if (customer_orgs.length) {
              if (this.getStoredState?.customerOrg) {
                if (
                  orgz.find((f) => f.id === this.getStoredState.customerOrg)
                ) {
                  this.setCustomerOrganization(
                    orgz.find((f) => f.id === this.getStoredState.customerOrg),
                    true
                  );
                } else {
                  this.setCustomerOrganization(customer_orgs[0]);
                }
              } else {
                this.setCustomerOrganization(customer_orgs[0]);
              }
            } else {
              this.errorHandler.handleError(
                new Error('This user dont have any organizations yet...')
              );
            }
          }
        })
      )
      .subscribe({
        error: (err) => {
          this.errorHandler.handleError(err);
        },
      });

    /**
     * When navigate back or forward catch which organization is active.
     */
    this.router.events
      .pipe(
        filter(
          (route_event): route_event is NavigationEnd =>
            route_event instanceof NavigationEnd
        ),
        startWith({ url: this.router.url }),
        map((navigation) => {
          // Event states that you are now in a Sales org context
          if (navigation['url'] === '/customers') {
            return this.getStoredState?.salesOrg;
          }
          // Event states that you are now in a Customer org context
          else if (
            navigation['url'] !== '/customers' &&
            navigation['url'].includes('/customers')
          ) {
            const questionMarkIndex = window.location.href.lastIndexOf('?');
            const lastParamIndex: number =
              window.location.href.lastIndexOf('/') + 1;
            const id = window.location.href.substring(
              lastParamIndex,
              questionMarkIndex !== -1
                ? questionMarkIndex
                : window.location.href.length
            );
            return this.getStoredState?.customerOrg || id;
          } else {
            return null;
          }
        }),
        RXJSUtils.filterUndefinedAndNull(),
        switchMap((orgId: string) =>
          this.organizations$.pipe(
            RXJSUtils.filterUndefinedAndNull(),
            map((orgs: ApiOrganization[]) => orgs.find((f) => f.id == orgId))
          )
        ),
        tap((org: ApiOrganization) => {
          if (org) {
            org.type === OrganizationType.SALES_ORGANIZATION
              ? this.setSalesOrganization(org, null, null)
              : this.setCustomerOrganization(org, null, null);
          }
        })
      )
      .subscribe();

    /**
     * Tells whether or not there is a user logged in.
     */
    this.isUserLogged$ = this.auth.user$.pipe(
      map((u) => Boolean(u)),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // Validates and blocks the user with a popup dialog if org subscription
    // doens't exist or is a trial and has expired.
    this.getCustomerOrSalesOrganization()
      .pipe(
        tap((org: ApiOrganization) => {
          if (window.location.pathname === '/user/sign-up/setup') {
            return;
          } else {
            this.validateOrgSubscription(org);
          }
        })
      )
      .subscribe();
  }

  get getStoredState(): IStoredState {
    return this.localStorageService.getData(LocalStorageKey.STATE);
  }

  set setStoredState(state: IStoredState) {
    this.localStorageService.setData(
      LocalStorageKey.STATE,
      state,
      StorageMethod.LOCAL
    );
  }

  customerOrganizationAsPromise(): Promise<ApiOrganization> {
    return firstValueFrom(this.customer_organization$);
  }

  salesOrganizationAsPromise(): Promise<ApiOrganization> {
    return firstValueFrom(this.sales_organization$);
  }

  organizationsAsPromise(): Promise<ApiOrganization[]> {
    return firstValueFrom(this.organizations$);
  }

  setOrganizations(orgs: IApiOrganization[]): ApiOrganization[] {
    const orgz = ObjectUtils.cloneObject(orgs);

    // Convert to ApiOrganization
    const result = orgz.map((m) => {
      const o = new ApiOrganization(m);
      return o;
    });

    this.organizations$.next(result);
    return result;
  }

  async setSalesOrganization(
    org: ApiOrganization,
    init: boolean = false,
    navigate: boolean = true
  ) {
    const salesOrg = ObjectUtils.cloneObject(org);
    this.sales_organization$.next(salesOrg);
    this.customer_organization$.next(null);

    if (!init) {
      const customer = await this.customerOrganizationAsPromise();
      this.setStoredState = {
        // Enforce null state if falsy, due to org === null checks
        customerOrg: customer?.id ? customer.id : null,
        salesOrg: salesOrg.id,
      };

      navigate ? this.router.navigate([pagesPATH.CUSTOMERS]) : null;
    }
  }

  async setOrganizationById(orgId: string) {
    let org = (await this.organizationsAsPromise())?.find(
      (o) => o.id === orgId
    );
    if (!org) {
      this.organizationApi
        .getOrganizationsLight()
        .pipe(
          tap((orgs: IApiOrganization[]) => {
            if (orgs) this.setOrganizations(orgs);
          }),
          switchMap(() => this.organizations$),
          take(1)
        )
        .subscribe({
          next: (orgs: ApiOrganization[]) => {
            org = orgs.find((o) => o.id === orgId);
            if (!org) {
              this.router.navigate([pagesPATH.CUSTOMERS]);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              org.type === OrganizationType.SALES_ORGANIZATION
                ? this.setSalesOrganization(org)
                : this.setCustomerOrganization(org);
            }
            return orgs;
          },
          error: (err) => this.errorHandler.handleError(err),
        });
    } else {
      org.type === OrganizationType.SALES_ORGANIZATION
        ? this.setSalesOrganization(org)
        : this.setCustomerOrganization(org);
    }
  }

  async setCustomerOrganization(
    org: ApiOrganization,
    init: boolean = false,
    navigate: boolean = true
  ) {
    this.customer_organization$.next(org);

    if (!init) {
      let sales = (await this.salesOrganizationAsPromise())?.id;

      // No sales organization set, do we have one in stored state?
      if (!sales) {
        sales = this.getStoredState?.salesOrg;
      }
      this.setStoredState = {
        customerOrg: org.id,
        // Enforce null state if falsy, due to org === null checks
        salesOrg: sales ? sales : null,
      };

      navigate ? this.router.navigate([pagesPATH.CUSTOMERS, org.id]) : null;
    }
  }

  getCustomerOrSalesOrganizationPreserveState(): Observable<ApiOrganization> {
    const state = this.getStoredState;
    if (state) {
      return state.customerOrg
        ? this.customer_organization$.pipe(RXJSUtils.filterUndefinedAndNull())
        : this.sales_organization$.pipe(RXJSUtils.filterUndefinedAndNull());
    } else {
      return this.getCustomerOrSalesOrganization();
    }
  }

  // Returns an Observable ApiOrganization that emits whenever customer_organization$ or sales_organization$ emits a non null value
  getCustomerOrSalesOrganization(): Observable<ApiOrganization> {
    return combineLatest([
      this.customer_organization$,
      this.sales_organization$,
    ]).pipe(
      skipWhile(([c, s]) => !c && !s),
      map(([customer, sales]) => {
        return !customer ? sales : customer;
      }),
      debounceTime(100), // Switching customers causes excess messages, drop them.
      shareReplay({ bufferSize: 1, refCount: false }) // Subscriptions
    );
  }

  validateParentOrgSubscription(parentOrgId: string) {
    this.organizations$
      .pipe(
        take(1),
        map((orgs: ApiOrganization[]) => orgs.find((f) => f.id === parentOrgId))
      )
      .subscribe((parentOrg: ApiOrganization) => {
        this.validateOrgSubscription(parentOrg);
      });
  }

  validateOrgSubscription(org: ApiOrganization) {
    // If any parent organization, validate that.
    if (org.organization_relations?.length) {
      this.validateParentOrgSubscription(
        org.organization_relations[0].organization_a_id
      );
      return;
    }

    let title: string;
    let message: string;

    // No subscription.
    if (!org.subscription_details) {
      title = `Your organization "${org.name}" needs a subscription to continue.`;
      message = `Contact support@myrobot.cloud to purchase a subscription.`;

      // Is a trial subscription that has expired.
    } else if (
      // TODO: Remove trial check to validate all subscriptions later.
      org.subscription_details?.trial &&
      new Date(org.subscription_details?.valid_to) <= new Date()
    ) {
      title = `Your ${
        // Ready-ed for when we check all subscriptions, not just trials.
        org.subscription_details?.trial ? '(trial) ' : ''
      } subscription for organization "${org.name}" has expired.`;
      message = `Contact support@myrobot.cloud to renew your subscription.`;
    }

    if (
      // No subscription.
      !org.subscription_details ||
      // Trial subscription that has expired.
      (org.subscription_details?.trial &&
        new Date(org.subscription_details?.valid_to) <= new Date())
    ) {
      this.dialogService.showCustomDialog(
        IllegalOrganizationSubscriptionComponent,
        DialogSize.MEDIUM,
        null,
        {
          title: title,
          message: message,
        },
        false
      );
    }
  }
}
