import { TransformVectorType } from '../enums/transform-vector-type';
import { Vector3 } from './vector3';

export class TransformVector {
  type: TransformVectorType;
  vector: Vector3;

  constructor(type: TransformVectorType, x: number, y: number, z: number) {
    this.type = type;
    this.vector = new Vector3(x, y, z);
  }

  x(): number {
    return this.vector.x;
  }
  y(): number {
    return this.vector.y;
  }
  z(): number {
    return this.vector.z;
  }
}
