import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { gql } from 'apollo-angular';
import {
  ApiProduct,
  IApiProduct,
} from '../../models_new/classes/api-models/ApiProduct';
import { ObjectUtils } from '../../utils/object';
import { ClientApiService } from './client-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  constructor(private clientApi: ClientApiService) {}

  fetchProductsLight(
    organizationId: string,
    getPatterns: boolean = false
  ): Observable<ApiProduct[]> {
    const qf = gql`
      subscription getProducts {
        product(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          data
          created_at
          updated_at
          ${
            getPatterns
              ? `patterns {
            id
            simulations {
              id
            }
          }`
              : ''
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: qf,
      })
      .pipe(
        map((data) => {
          const list = [];
          for (const p of data.data.product) {
            list.push(new ApiProduct(ObjectUtils.cloneObject(p)));
          }
          return list;
        })
      );
  }

  fetchProductById(id: string): Observable<ApiProduct> {
    const q = gql`query getProduct {
      product_by_pk(id: "${id}") {
        id
        name
        data
        patterns {
          id
          name
          simulations {
            id
            name
          }
        }
        updated_at
        product_production_lines {
          production_line {
            id
            name
            data
          }
        }
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((data) => {
          return new ApiProduct(
            ObjectUtils.cloneObject(data.data.product_by_pk)
          );
        })
      );
  }

  createProduct(
    productName: string,
    productData: any,
    org_id: string
  ): Observable<IApiProduct> {
    const m = gql`
      mutation createProduct($name: String!, $data: json!, $org_id: uuid!) {
        insert_product_one(
          object: { name: $name, data: $data, organization_id: $org_id }
        ) {
          id
        }
      }
    `;

    const variables = {
      name: productName,
      data: productData,
      org_id: org_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.insert_product_one));
  }

  updateProduct(
    id: string,
    productName: string,
    productData: any
  ): Observable<IApiProduct> {
    const m = gql`
    mutation updateProduct($name: String!, $data: json!) {
      update_product_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name, data: $data}) {
        id
      }
    }`;

    const variables = {
      name: productName,
      data: productData,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((data) => data.data.update_product_by_pk));
  }

  deleteProduct(id: string): Observable<IApiProduct> {
    const m = gql`
    mutation deleteProduct {
      delete_product_by_pk(id: "${id}") {
        name
        id
      }
    }`;

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data.delete_product_by_pk));
  }
}
