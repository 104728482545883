export enum pagesPATH {
  // Landing
  FAST_TRACK = 'fast-track',
  FAST_TRACK_PATTERN = 'l-pattern',
  FAST_TRACK_PRODUCT = 'l-product',
  FAST_TRACK_REGION = 'l-region',
  FAST_TRACK_USER_INFO = 'l-user-info',
  FAST_TRACK_EXPLORE = 'l-explore',

  ABOUT = 'about',
  EXPORT_IMPORT = 'export_import',
  CALLBACK = 'callback',
  RELEASE_NOTES = 'release-notes',

  // User
  USER = 'user',
  USER_DASHBOARD = 'user-dashboard', // NOTE! Not used
  USER_SIGN_IN = 'sign-in',
  USER_SIGN_UP = 'sign-up',
  LANDING_PAGE = 'landing-page',
  NEW_ORGANIZATION = 'new-organization',
  RESET = 'reset',
  VERIFY = 'verify',
  SHARE = 'share', // NOTE! Not used
  ORG_INVITE = 'org-invite',
  ERROR_PAGE = 'error-page',
  METADATA_EDIT = 'metadata-edit',

  // Customer
  CUSTOMERS = 'customers',
  JOIN_ORG = 'join-org',
  VIEW_ORG = 'view-org',
  NEW_ORG = 'new-org',
  ORG_MEMBERS = 'members',
  ORGANIZATIONS = 'organizations',
  LEADS = 'leads',
  LEAD = 'lead',
  SOLUTION = 'solution',

  // Inventory
  INVENTORY = 'inventory',
  INVENTORY_CUSTOMERS = 'customers',
  COMPARE_ITEMS = 'compare-items',

  // Prodline
  PRODUCTION_LINES = 'production-lines',

  // Pattern
  NEW_PATTERN = 'new-pattern', // NOTE! Not used
  PATTERNS = 'patterns',
  PATTERNEDIT = 'patternEdit',
  PALLET = 'pallet',
  BOXEDIT = 'boxEdit',

  // Product
  NEW_PRODUCT = 'new-product', // NOTE! Not used
  PRODUCTS = 'products',

  // Hardware
  HARDWARE_CONFIGURATIONS = 'hardware-configurations',
  UPLOAD = 'model-upload',
  HARDWARE_LIBRARY = 'hardware-library',

  // Software
  SOFTWARE_LIST = 'software-configurations',

  // Installed Robots configs
  INSTALLED_ROBOTS = 'installed-robots',
  NEW_INSTALLED_ROBOT = 'new',
  INSTALLED_ROBOT_SIMULATION_WIZARD = 'installed-robot-simulation-wizard',
  MY_UR = 'my-ur',

  /**
   * @deprecated My-Robots naming is to be phased out, will be removed.
   * For temporary redirect only! Use INSTALLED_ROBOTS instead.
   **/
  MY_ROBOTS_DEPRECATED = 'my-robots',

  // Robot configs
  ROBOT_CONFIGURATIONS = 'robot-configurations',
  ROBOT_CONFIG_SELECT = 'robot-config-select', // NOTE! Not used
  ROBOT_CONFIG_EDIT = 'robot-config-edit', // NOTE! Not used

  // Advanced robot config
  ADVANCED_CONFIG = 'advanced',
  // Advanced sub pages
  ADVANCED_CONFIG_OVERVIEW = 'overview', // NOTE! Not used
  ADVANCED_CONFIG_HARDWARE = 'hardware',
  ADVANCED_CONFIG_STRATEGY = 'strategy',
  ADVANCED_CONFIG_SUMMARY = 'summary',

  // Simulation
  SIMULATIONS = 'simulations',
  SIMULATIONCONFIG = 'simulation-config',
  SIMULATIONVISUALIZATION = 'simulation-visualization',
  SIMPLE_SIMULATION = 'simple-simulation', // TODO: Might not need wizard
  SIMULATION_RETRY = 'simulation-retry',
  SIMULATION_SELECT_HARDWARE = 'hardware',
  SIMULATION_SELECT_SOFTWARE = 'software',
  SIMULATION_WIZARD = 'simulation-wizard',

  // Report
  REPORT = 'report',
  BACKOFFICE = 'backoffice',
  NEW_LICENSE = 'new-license', // NOTE! Not used

  SHARED_LINK_EXPIRED = 'link-expired',
}

export interface IAnalyticsPagesPATH {
  path: string;
  /* 
  Custom path displayed in the analytics entry.
  If omitted, path is used as page path.
  */
  pagePath?: string;
  pageTitle: string;
}

export const analyticsPagesPATH: IAnalyticsPagesPATH[] = [
  {
    path: '',
    pagePath: '/Home | Fast Track | MyRobot.cloud',
    pageTitle: 'Home | MyRobot.cloud',
  },
  {
    path: pagesPATH.FAST_TRACK_PRODUCT,
    pagePath: '/l-product | Fast Track | MyRobot.cloud',
    pageTitle: 'Product | Fast Track | MyRobot.cloud',
  },
  {
    path: pagesPATH.FAST_TRACK_PATTERN,
    pagePath: '/l-pattern | Fast Track | MyRobot.cloud',
    pageTitle: 'Pattern | Fast Track | MyRobot.cloud',
  },
  {
    path: pagesPATH.FAST_TRACK_REGION,
    pagePath: '/l-region | Fast Track | MyRobot.cloud',
    pageTitle: 'Region | Fast Track | MyRobot.cloud',
  },
  {
    path: pagesPATH.FAST_TRACK_USER_INFO,
    pagePath: '/l-user-info | Fast Track | MyRobot.cloud',
    pageTitle: 'User Info | Fast Track | MyRobot.cloud',
  },
  {
    path: pagesPATH.SIMULATIONS,
    pageTitle: 'SIMULATIONS | MRC',
  },
];
