import { Injectable } from '@angular/core';
import {
  URDFJoint,
  URDFLink,
  URDFRobot,
  URDFVisual,
} from '@rocketfarm/urdf-loader/src/URDFClasses';
import { AssetStoreService } from './asset-store.service';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RXJSUtils } from '../utils/rxjs-utils';

@Injectable({
  providedIn: 'root',
})
export class PartStoreService {
  getPart<T>(id: string): Observable<T> {
    const obs = new ReplaySubject<T>(1);
    AssetStoreService.loadingCompleted$
      .pipe(RXJSUtils.filterFalse(), take(1))
      .subscribe((value: boolean) => {
        AssetStoreService.onAssetLoadedWithID<T>(id).subscribe(obs);
      });
    return obs as Observable<T>;
  }

  getPartJoint(part: URDFRobot, name: string): URDFJoint {
    return part.joints[name];
  }

  getPartLink(part: URDFRobot, name: string): URDFLink {
    return part.links[name];
  }

  getPartVisual(part: URDFRobot, parentLinkName: string): URDFVisual {
    const links = part.links;
    for (const threePart of Object.values(links)) {
      if (threePart.name === parentLinkName) {
        return threePart.getObjectByProperty(
          'type',
          'URDFVisual'
        ) as URDFVisual;
      }
    }

    return null;
  }
}
