import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Type } from '../utils/type';
import * as THREE from 'three';
import {
  JointNames,
  ProjectRobotDescriptorService,
} from './project-robot-descriptor.service';
import { TimedObject3D } from '../models_new/classes/3dview/timed-3d-object';
import { ITimedObjectConfig } from '../models_new/types/simconf/timed-object-config';
import { URDFTypes } from '../utils/urdf-utils';

@Injectable({
  providedIn: 'root',
})
export class TimedObject3DService implements OnDestroy {
  private items: Map<string, TimedObject3D> = new Map<string, TimedObject3D>();

  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(protected robot: ProjectRobotDescriptorService) {}

  public ngOnDestroy(): void {
    this.items.clear();
    this.destroy$.next(true);
  }

  public getTimedObject(id: string) {
    return this.items.get(id);
  }

  public getAllTimedObjects(): Iterable<TimedObject3D> {
    return this.items.values();
  }

  public registerTimedObject(obj: TimedObject3D) {
    if (Type.isDefined(this.items.get(obj.ID))) {
      console.warn('Overriding TimedObject3D with id: "' + obj.ID + '"!');
    }

    this.items.set(obj.ID, obj);
  }

  public activateFromID(id: string, durationMS?: number) {
    const dimvis = this.items.get(id);

    if (!Type.isDefined(dimvis)) {
      console.warn('Can\'t find dimension visualizer with id: "' + id + '"!');
      return;
    }

    dimvis.activate(durationMS);
  }

  public activateTimedObject(obj: TimedObject3D, durationMS?: number) {
    obj.activate(durationMS);
  }

  public resolveConfigStartEnd(config: ITimedObjectConfig): {
    start: THREE.Vector3 | URDFTypes;
    end: THREE.Vector3 | URDFTypes;
  } {
    let start;
    if (typeof config.data.start === 'string') {
      if (Object.values(JointNames).indexOf(config.data.start) >= 0) {
        start = this.robot.getJointByID(config.data.start);
      } else {
        start = this.robot.getLinkByID(config.data.start);
      }
    } else {
      start = config.data.start;
    }
    let end;
    if (typeof config.data.end === 'string') {
      if (Object.values(JointNames).indexOf(config.data.end) >= 0) {
        end = this.robot.getJointByID(config.data.end);
      } else {
        end = this.robot.getLinkByID(config.data.end);
      }
    } else {
      end = config.data.end;
    }
    return {
      start: start,
      end: end,
    };
  }
}
