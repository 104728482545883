import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { SoftwareDetailsComponent } from '../../components/inventory/software/software-details/software-details.component';
import { SoftwareListComponent } from '../../components/inventory/software/software-list/software-list.component';
import { SoftwareWrapperComponent } from '../../components/inventory/software/software-wrapper/software-wrapper.component';
import { OrganizationContextResolver } from '../../resolvers/organization-context-resolver.resolver';

const routes: Routes = [
  {
    path: '',
    component: SoftwareWrapperComponent,
    resolve: { organization_id: OrganizationContextResolver },
    data: {
      breadcrumb: 'Software configurations',
      bcRouteTo: [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST],
    },
    children: [
      {
        path: '',
        component: SoftwareListComponent,
        data: {
          breadcrumb: undefined, // We use the breadcrumb from the parent
        },
      },
      {
        path: ':id',
        component: SoftwareDetailsComponent,
        data: {
          breadcrumb: 'Software configuration',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SoftwareRoutingModule {}
