import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import { ObjectUtils } from 'src/app/utils/object';
import { marked } from 'marked';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent implements OnInit {
  id: string;
  substitutions?: { [key: string]: string };
  infoData$: Observable<IInfoCard>;

  constructor(
    public dialogRef: MatDialogRef<InfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        dataSource: Observable<IInfoCard[]>;
        id: string;
        substitutions: { [key: string]: string };
      };
    }
  ) {}

  ngOnInit(): void {
    this.id = this.input.data.id;
    this.substitutions = this.input.data.substitutions;
    this.infoData$ = this.input.data.dataSource.pipe(
      map((cards: IInfoCard[]) =>
        cards.find((card) => card.identifier === this.id)
      ),
      map((card: IInfoCard) => {
        const cardClone = ObjectUtils.cloneObject(card);
        cardClone.text = cardClone.text.split('\n').join('<br>');
        cardClone.text = this.formatString(
          cardClone.text,
          this.substitutions || {}
        );
        return cardClone;
      })
    );
  }

  formatString(str: string, substitutions: { [key: string]: string }): string {
    const substitutedText = str.replace(/{(\w+)}/g, (match) => {
      const key = match.substring(1, match.length - 1);
      return substitutions[key] ? substitutions[key] : match;
    });
    return marked.parse(substitutedText);
  }

  didClickLearnMore(link) {
    window.open(link);
    this.didClickClose();
  }

  didClickClose() {
    this.dialogRef.close();
  }
}
