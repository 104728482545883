import { ApiOrganization } from './ApiOrganization';
import { ApiProduct } from './ApiProduct';
import { ApiUser } from './ApiUser';

export interface IProductionLineData {
  /** Cases per minute */
  cpm: number;
  /** Hours of production */
  hop: number;
  /** Minutes of production */
  mop: number;
  /** Period of production */
  per: 'day' | 'week' | 'month';
}

export interface IApiProductionLine {
  id: string;
  owner_id: ApiUser['id'];
  name: string;
  data: IProductionLineData;
  product_ids: ApiProduct['id'][];
  organization_id: ApiOrganization['id'];
  updated_at: string;
  production_line_palletizing_projects: {
    palletizing_project: {
      name?: string;
    };
  }[];
}

export class ApiProductionLine implements IApiProductionLine {
  id: string;
  owner_id: ApiUser['id'];
  name: string;
  data: IProductionLineData;
  product_ids: ApiProduct['id'][];
  organization_id: ApiOrganization['id'];
  updated_at: string;
  production_line_palletizing_projects: {
    palletizing_project: {
      name?: string;
    };
  }[];

  constructor(productionLine: IApiProductionLine) {
    for (const d in productionLine) {
      if (productionLine.hasOwnProperty(d)) {
        this[d] = productionLine[d];
      }
    }
  }

  copy(): ApiProductionLine {
    return new ApiProductionLine(this);
  }

  static createEmpty(): ApiProductionLine {
    return new ApiProductionLine({
      id: undefined,
      owner_id: undefined,
      name: '',
      data: {
        cpm: 0,
        hop: 0,
        mop: 0,
        per: 'day',
      },
      product_ids: [],
      organization_id: undefined,
      updated_at: undefined,
      production_line_palletizing_projects: undefined,
    });
  }
}
