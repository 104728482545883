import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-box',
  templateUrl: './loader-box.component.html',
  styleUrls: ['./loader-box.component.scss'],
})
export class LoaderBoxComponent {
  /**
   * Sets the color of the loading animation icon to white
   */
  @Input()
  whiteColor?: boolean;
}
