import { NavbarButtonItemType } from '../enums/advanced-navbar-button-item-type';
import { INavbarItem } from '../types/navbar-item';
import { pagesPATH } from './pages';

export const mainMenuCustomer: INavbarItem[] = [
  {
    label: 'Production',
    type: NavbarButtonItemType.BUTTON,
    children: [
      {
        label: 'Products',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.PRODUCTS],
        icon: 'product',
      },
      // {
      //   label: 'Production lines',
      //   type: NavbarButtonItemType.BUTTON,
      //   path: [pagesPATH.PRODUCTION_LINES],
      //   icon: 'production_line',
      // },
      {
        label: 'Patterns',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.PATTERNS],
        icon: 'pattern',
      },
      {
        label: 'Simulations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.SIMULATIONS],
        icon: 'simulation',
      },
      {
        label: 'Installed Robots',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INSTALLED_ROBOTS],
        icon: 'robot',
      },
    ],
  },
  {
    label: 'Organization',
    type: NavbarButtonItemType.BUTTON,
    children: [
      {
        label: 'Members',
        type: NavbarButtonItemType.BUTTON,
        path: [
          pagesPATH.ORGANIZATIONS,
          '{organization_id}',
          pagesPATH.ORG_MEMBERS,
        ],
      },
      {
        label: 'Edit organization',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.CUSTOMERS, pagesPATH.VIEW_ORG, '{organization_id}'],
        roleAction: 'update_organization',
        queryParams: { edit: true },
      },
    ],
  },
  {
    label: 'Inventory',
    type: NavbarButtonItemType.BUTTON,
    path: [pagesPATH.INVENTORY],
    children: [
      {
        label: 'Robot configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS],
        icon: 'robot_configuration',
      },
      {
        label: 'Software configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST],
        needQuery: true,
        icon: 'software_configuration',
      },
      {
        label: 'Hardware configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
        needQuery: true,
        icon: 'hardware_configuration',
      },
      /**
       * @todo To be added back when HardwareLibrary is fixed/stable.
       * @see {@link https://rocketfarm.atlassian.net/browse/PALLY-3187 | PALLY-3187} for further information.
       *
       *{
       *  label: 'Hardware library',
       *  type: NavbarButtonItemType.BUTTON,
       *  path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_LIBRARY],
       *  needQuery: true,
       * },
       */
    ],
  },
];

export const mainMenuSales: INavbarItem[] = [
  {
    label: 'Leads',
    type: NavbarButtonItemType.BUTTON,
    path: [pagesPATH.ORGANIZATIONS, pagesPATH.LEADS],
    needQuery: true,
    disabled: true,
  },
  {
    label: 'Organization',
    type: NavbarButtonItemType.BUTTON,
    children: [
      {
        label: 'Members',
        type: NavbarButtonItemType.BUTTON,
        path: [
          pagesPATH.ORGANIZATIONS,
          '{organization_id}',
          pagesPATH.ORG_MEMBERS,
        ],
      },
      {
        label: 'Edit organization',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.CUSTOMERS, pagesPATH.VIEW_ORG, '{organization_id}'],
        roleAction: 'update_organization',
        queryParams: { edit: true },
      },
    ],
  },
  {
    label: 'Inventory',
    type: NavbarButtonItemType.BUTTON,
    path: [pagesPATH.INVENTORY],
    children: [
      {
        label: 'Robot configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS],
        icon: 'robot_configuration',
      },
      {
        label: 'Software configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST],
        needQuery: true,
        icon: 'software_configuration',
      },
      {
        label: 'Hardware configurations',
        type: NavbarButtonItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
        needQuery: true,
        icon: 'hardware_configuration',
      },
      /**
       * @todo To be added back when HardwareLibrary is fixed/stable.
       * @see {@link https://rocketfarm.atlassian.net/browse/PALLY-3187 | PALLY-3187} for further information.
       *
       *{
       *  label: 'Hardware library',
       *  type: NavbarButtonItemType.BUTTON,
       *  path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_LIBRARY],
       *  needQuery: true,
       * },
       */
    ],
  },
  {
    label: 'Customers',
    type: NavbarButtonItemType.BUTTON,
    path: [pagesPATH.INVENTORY, pagesPATH.CUSTOMERS],
  },
];
