const polyscopeBackgroundMap = new Map([
  [
    'N/A',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #969696 0%, #696969 100%)',
    },
  ],
  [
    '5.9',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #3E62AE 0%, #2B4478 100%)',
    },
  ],
  [
    '5.9.4',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #db3d99 0%, #971c64 100%)',
    },
  ],
  [
    '5.10',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #eab17b 0%, #CA793F 100%)',
    },
  ],
  [
    '5.11',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #34D592 0%, #1F9C68 100%)',
    },
  ],
]);

export function polyscopeVersionBackground(version: string) {
  let backgroundGradient = polyscopeBackgroundMap.get(version);
  if (!backgroundGradient) {
    backgroundGradient = {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #ae453e 0%, #78372b 100%)',
    };
  }
  return backgroundGradient;
}
