<ng-container *ngIf="!rfb">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchDefault>
      <button mat-raised-button color="accent" (click)="state = 'new'">
        New URSim container
      </button>
      <button mat-stroked-button color="accent" (click)="state = 'existing'">
        Connect to existing container
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'new'">
      <form [formGroup]="formGroupNewClient">
        <mat-form-field>
          <mat-label>Polyscope version</mat-label>
          <input type="text" matInput formControlName="polyscope" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Robot model</mat-label>
          <input type="text" matInput formControlName="robot" />
        </mat-form-field>
        <button
          mat-raised-button
          color="accent"
          type="submit"
          (click)="startUrSimContainer()"
        >
          Start URSim Container
        </button>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="'existing'">
      <form [formGroup]="formGroupClient">
        <mat-form-field>
          <mat-label>Host</mat-label>
          <input type="text" matInput formControlName="host" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Port</mat-label>
          <input type="number" matInput formControlName="port" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input type="text" matInput formControlName="password" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Path</mat-label>
          <input type="text" matInput formControlName="path" />
        </mat-form-field>
        <button
          mat-raised-button
          color="accent"
          type="submit"
          (click)="startClient()"
        >
          Start Vnc Client
        </button>
      </form>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="rfb">
  <div class="actions-wrapper">
    <button mat-icon-button (click)="screenshot()" matTooltip="Take screenshot">
      <mat-icon>screenshot_monitor</mat-icon>
    </button>
    <div class="upDownLoad">
      <button mat-icon-button (click)="uploadFile()" matTooltip="Upload file">
        <mat-icon>upload_file</mat-icon>
      </button>
      <button
        mat-icon-button
        [matMenuTriggerFor]="downLoadMenu"
        matTooltip="Download files"
      >
        <mat-icon>download</mat-icon>
      </button>
      <mat-menu #downLoadMenu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="Installations">
          Installations
        </button>
        <button mat-menu-item [matMenuTriggerFor]="Others">Others</button>
        <button mat-menu-item [matMenuTriggerFor]="Programs">Programs</button>
      </mat-menu>

      <mat-menu #Installations="matMenu">
        <button
          mat-menu-item
          *ngFor="let i of assets.installations"
          (click)="downloadFile(i)"
        >
          {{ i.name }}
        </button>
      </mat-menu>

      <mat-menu #Others="matMenu">
        <button
          mat-menu-item
          *ngFor="let o of assets.others"
          (click)="downloadFile(o)"
        >
          {{ o.name }}
        </button>
      </mat-menu>

      <mat-menu #Programs="matMenu">
        <button
          mat-menu-item
          *ngFor="let p of assets.programs"
          (click)="downloadFile(p)"
        >
          {{ p.name }}
        </button>
      </mat-menu>

      <input
        type="file"
        accept=".urp, .installation, .json"
        #file
        (change)="uploadFileEvent($event)"
        style="display: none"
      />
    </div>
    <span class="spacer"></span>
    <button mat-raised-button color="warn" (click)="disconnect()">
      Disconnect
    </button>
  </div>
</ng-container>
<div>
  <div id="screen">
    <!-- This is where the remote screen will appear -->
  </div>
</div>
