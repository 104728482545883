export enum SimConfigFieldIds {
  PolyscopeVersion = 'mainFormGroup.scene.software.polyscope_version',
  PallyVersion = 'mainFormGroup.scene.software.pally_version',
  /* ------------------------------- Info fields ------------------------------ */
  Name = 'robot_config.name',
  ShowDimensions = 'show_dimensions',

  /* ------------------------------ Frame fields ------------------------------ */
  FrameId = 'robot.frame.id',
  FrameType = 'robot.frame.type',
  FrameName = 'robot.frame.name',
  FrameTypeLabel = 'robot.frame.label', // NOTE! Field is not defined.

  /* ----------------------------- Liftkit fields ----------------------------- */
  LiftkitId = 'robot.lift_kit.id',
  LiftkitName = 'robot.lift_kit.name',
  LiftkitType = 'robot.lift_kit.type',
  LiftkitTypeLabel = 'robot.lift_kit.label', // NOTE! Field is not defined.
  LiftkitMaxStroke = 'robot.lift_kit.max_stroke',

  /* --------------------------- Basebracket fields --------------------------- */
  BasebracketHeight = 'robot_mounting_height.position.z',
  BasebracketRotation = 'robot_mounting_height.rotation.y',

  /* ------------------------------ Robot fields ------------------------------ */
  RobotId = 'robot.id',
  RobotType = 'robot.type',
  RobotName = 'robot.name',
  RobotTypeLabel = 'robot.label', // NOTE! Field is not defined.

  /* -------------------------- Offsetbracket fields -------------------------- */
  OffsetBracketType = 'offset_bracket.type',
  AddOffsetBracket = 'offset_bracket.add',
  OffsetBracketWidth = 'robot.offset_bracket.collision_object.width',
  OffsetBracketLength = 'robot.offset_bracket.collision_object.length',
  OffsetBracketHeight = 'robot.offset_bracket.collision_object.height',
  OffsetBracketAdvanced = 'offset_bracket.advanced',
  OffsetBracketPositionX = 'robot.offset_bracket.collision_object.position.x',
  OffsetBracketPositionY = 'robot.offset_bracket.collision_object.position.y',
  OffsetBracketPositionZ = 'robot.offset_bracket.collision_object.position.z',
  OffsetBracketOffsetX = 'robot.offset_bracket.offset.x',
  OffsetBracketOffsetY = 'robot.offset_bracket.offset.y',
  OffsetBracketOffsetZ = 'robot.offset_bracket.offset.z',

  /* ----------------------------- Gripper fields ----------------------------- */
  GripperId = 'robot.gripper.id',
  GripperType = 'robot.gripper.type',
  GripperTypeLabel = 'robot.gripper.label', // NOTE! Field is not defined.
  GripperName = 'robot.gripper.name',
  GripperCustomWidth = 'robot.gripper.custom_collision_box.width',
  GripperCustomLength = 'robot.gripper.custom_collision_box.length',
  GripperCustomHeight = 'robot.gripper.custom_collision_box.height',
  GripperAdvanced = 'robot.gripper.advanced',
  GripperCustomPositionX = 'robot.gripper.custom_offset_pose.position.x',
  GripperCustomPositionY = 'robot.gripper.custom_offset_pose.position.y',
  GripperCustomPositionZ = 'robot.gripper.custom_offset_pose.position.z',
  GripperCustomRotationR = 'robot.gripper.custom_offset_pose.rotation.r',
  GripperCustomRotationP = 'robot.gripper.custom_offset_pose.rotation.p',
  GripperCustomRotationY = 'robot.gripper.custom_offset_pose.rotation.y',

  /* -------------------------- Custom pallet fields -------------------------- */
  PalletSideOffset = 'pallet_side_offset',
  PalletEndOffset = 'pallet_end_offset',
  PalletVerticalOffset = 'pallet_vert_offset',

  /* ------------------------------ Pallet fields ----------------------------- */
  PalletRightName = 'place_targets.[name:p1].name',
  PalletRightAdvanced = 'place_targets.[name:p1].advanced',
  PalletRightPositionX = 'place_targets.[name:p1].corner_base_pose.position.x',
  PalletRightPositionY = 'place_targets.[name:p1].corner_base_pose.position.y',
  PalletRightPositionZ = 'place_targets.[name:p1].corner_base_pose.position.z',
  PalletRightRotationX = 'place_targets.[name:p1].corner_base_pose.orientation.x',
  PalletRightRotationY = 'place_targets.[name:p1].corner_base_pose.orientation.y',
  PalletRightRotationZ = 'place_targets.[name:p1].corner_base_pose.orientation.z',
  PalletRightRotationW = 'place_targets.[name:p1].corner_base_pose.orientation.w',
  PalletRightLipEnabled = 'place_targets.[name:p1].lip.enabled',
  PalletRightLipHeight = 'place_targets.[name:p1].lip',

  PalletLeftName = 'place_targets.[name:p2].name',
  PalletLeftAdvanced = 'place_targets.[name:p2].advanced',
  PalletLeftPositionX = 'place_targets.[name:p2].corner_base_pose.position.x',
  PalletLeftPositionY = 'place_targets.[name:p2].corner_base_pose.position.y',
  PalletLeftPositionZ = 'place_targets.[name:p2].corner_base_pose.position.z',
  PalletLeftRotationX = 'place_targets.[name:p2].corner_base_pose.orientation.x',
  PalletLeftRotationY = 'place_targets.[name:p2].corner_base_pose.orientation.y',
  PalletLeftRotationZ = 'place_targets.[name:p2].corner_base_pose.orientation.z',
  PalletLeftRotationW = 'place_targets.[name:p2].corner_base_pose.orientation.w',
  PalletLeftLipEnabled = 'place_targets.[name:p2].lip.enabled',
  PalletLeftLipHeight = 'place_targets.[name:p2].lip',

  /* ----------------------------- Conveyor fields ---------------------------- */
  ConveyorRightId = 'conveyors.0.id',
  ConveyorRightType = 'conveyors.0.type',
  ConveyorRightTypeLabel = 'conveyors.0.label', // NOTE! Field is not defined.
  ConveyorRightTypeName = 'conveyors.0.name',
  ConveyorRightName = 'conveyors.0.custom_description.name',
  ConveyorRightAdvanced = 'conveyors.0.advanced',
  ConveyorRightDirection = 'conveyors.0.direction',
  ConveyorRightWidth = 'conveyors.0.custom_description.dimension.x',
  ConveyorRightLength = 'conveyors.0.custom_description.dimension.y',

  //  As we currently only support single product mode only ConveyorRightHeight
  //  is used to represent the conveyor height.
  ConveyorRightHeight = 'conveyors.0.custom_description.dimension.z',
  ConveyorRightPositionX = 'conveyors.0.box_corner_pose.position.x',
  ConveyorRightPositionY = 'conveyors.0.box_corner_pose.position.y',
  ConveyorRightPositionZ = 'conveyors.0.box_corner_pose.position.z',
  ConveyorRightRotationX = 'conveyors.0.box_corner_pose.orientation.x',
  ConveyorRightRotationY = 'conveyors.0.box_corner_pose.orientation.y',
  ConveyorRightRotationZ = 'conveyors.0.box_corner_pose.orientation.z',
  ConveyorRightRotationW = 'conveyors.0.box_corner_pose.orientation.w',
  ConveyorRightGuideSide = 'conveyors.0.custom_description.guide_left',
  ConveyorRightGuideWidth = 'conveyors.0.custom_description.guide_width',
  ConveyorRightVelocity = 'conveyors.0.custom_description.velocity',
  ConveyorRightPubRate = 'conveyors.0.custom_description.pub_rate',
  ConveyorRightAddAtEnd = 'conveyors.0.custom_description.add_at_end',

  ConveyorLeftId = 'conveyors.1.id',
  ConveyorLeftType = 'conveyors.1.type',
  ConveyorLeftTypeName = 'conveyors.1.name',
  ConveyorLeftTypeLabel = 'conveyors.1.label', // NOTE! Field is not defined.
  ConveyorLeftName = 'conveyors.1.custom_description.name',
  ConveyorLeftAdvanced = 'conveyors.1.advanced',
  ConveyorLeftDirection = 'conveyors.1.direction',
  ConveyorLeftWidth = 'conveyors.1.custom_description.dimension.x',
  ConveyorLeftLength = 'conveyors.1.custom_description.dimension.y',

  // ConveyorLeftHeight is currently not in use. See ConveyorRightHeight for details.
  ConveyorLeftHeight = 'conveyors.1.custom_description.dimension.z',
  ConveyorLeftPositionX = 'conveyors.1.box_corner_pose.position.x',
  ConveyorLeftPositionY = 'conveyors.1.box_corner_pose.position.y',
  ConveyorLeftPositionZ = 'conveyors.1.box_corner_pose.position.z',
  ConveyorLeftRotationX = 'conveyors.1.box_corner_pose.orientation.x',
  ConveyorLeftRotationY = 'conveyors.1.box_corner_pose.orientation.y',
  ConveyorLeftRotationZ = 'conveyors.1.box_corner_pose.orientation.z',
  ConveyorLeftRotationW = 'conveyors.1.box_corner_pose.orientation.w',
  ConveyorLeftGuideWidth = 'conveyors.1.custom_description.guide_width',
  ConveyorLeftVelocity = 'conveyors.1.custom_description.velocity',
  ConveyorLeftPubRate = 'conveyors.1.custom_description.pub_rate',
  ConveyorLeftAddAtEnd = 'conveyors.1.custom_description.add_at_end',

  /* ------------------------------ Scene fields ------------------------------ */
  ThemeType = 'theme',

  /* ----------------------------- Strategy fields ---------------------------- */
  ConveyorBoxFreeHeight = 'box_free_height',
}
