import { OrganizationMemberType } from '../../enums/organization-member-type';
import { ApiOrganization } from './ApiOrganization';
import { ApiUser } from './ApiUser';

export interface IApiOrganizationMember {
  user_id: ApiUser['id'];
  organization_id: ApiOrganization['id'];
  created_at: number;
  updated_at: number;
  type: OrganizationMemberType;
  invitation_id: string;
}

export class ApiOrganizationMember implements IApiOrganizationMember {
  user_id: ApiUser['id'];
  organization_id: ApiOrganization['id'];
  created_at: number;
  updated_at: number;
  type: OrganizationMemberType;
  invitation_id: string;
  name: string;

  constructor(member: IApiOrganizationMember) {
    for (const d in member) {
      if (member.hasOwnProperty(d)) {
        this[d] = member[d];
      }
    }
  }
}
