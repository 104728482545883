import { ApiScene } from './ApiScene';
import { ApiStrategy } from './ApiStrategy';

export interface IApiRobotConfiguration {
  id: string;
  scene: ApiScene;
  strategy: ApiStrategy;
  name?: string;

  created_at?: string;
  owner_id?: string;
  updated_at?: number;
  organization_id?: string;
  owner_organization?: {
    name: string;
  };
}

export class ApiRobotConfiguration implements IApiRobotConfiguration {
  id: string;
  scene: ApiScene;
  strategy: ApiStrategy;
  name?: string;

  created_at?: string;
  owner_id?: string;
  updated_at?: number;
  organization_id?: string;
  owner_organization?: {
    name: string;
  };

  constructor(robotConfig: IApiRobotConfiguration) {
    for (const d in robotConfig) {
      if (robotConfig.hasOwnProperty(d)) {
        this[d] = robotConfig[d];
      }
    }
  }
}
