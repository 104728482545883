import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import {
  IEnabledByField,
  OnChangeFieldUpdate,
} from '../types/sim-api-form-config';
import { FieldType } from '../types/field-type';
import { Type } from 'src/app/utils/type';
import { assetType } from '../../services/api/asset-api.service';
import { EventEmitter } from '@angular/core';

export class Field {
  id = 'unset';
  parentId = 'unset';
  type = 'unset';
  required = false;
  defaultValue: any = null;
  formControl: UntypedFormControl;
  tabGroupId: string;
  options?: string[] | number[] | object[];
  guiOrderIdx?: number;
  validators: ValidatorFn[];
  errorMsgs: Map<string, string>;
  children: Field[];
  maxChildren: number;
  step: number;
  min: number;
  max: number;
  valueOrder: string;
  prefixIcon: {
    material: boolean;
    icon: string;
  };
  text: {
    label: string;
    name: string;
    suffix: string;
    hint: string;
    showProperty?: string;
  } = {
    label: '',
    name: '',
    suffix: '',
    hint: '',
    showProperty: 'name',
  };
  reactive: {
    enabled: boolean;
    enabled_by_field: IEnabledByField[];
    updates_fields_onChange: OnChangeFieldUpdate[];
    post_updates_fields_onChange?: OnChangeFieldUpdate[];
    updatesFieldFn?: Function;
    hidden: boolean;
  } = {
    enabled: true,
    enabled_by_field: [],
    updates_fields_onChange: [],
    updatesFieldFn: undefined,
    hidden: false,
  };
  inputStyling?: string;
  infoPopupClick?: EventEmitter<any> = new EventEmitter<any>();

  /* ------------------------- Drag'n'drop properties ------------------------- */

  /**
   * Not really needed, just to fill out the field
   * for you if you're going to upload an asset.
   */
  fileCategory?: assetType = 'logo';

  /**  Allows picking multiple files at once. Defaulted to false */
  multiple?: boolean = false;

  /** Supported formats. Limits the file formats supported if given */
  accept?: string[];

  /** Size limit. Filters the input files by file-size. Value must be given in Bytes */
  sizeLimit?: number;

  /* Feedback to show loading icon */
  loading?: boolean = false;

  /* Wether or not the org id should be added to the file_name to make it more identifiable */
  includeOrgIdInFileName?: boolean = false;

  constructor(
    type: FieldType,
    required: boolean,
    defaultValue: any,
    validators: ValidatorFn[] = [],
    tabGroupId: string,
    options: string[] | number[] | object[],
    guiOrderIdx: number,
    text: {
      label?: string;
      name?: string;
      suffix?: string;
      hint?: string;
      showProperty?: string;
    },
    id: string,
    prefixIcon?: {
      material: boolean;
      icon: string;
    },
    errorMsgs?: Map<string, string>,
    updateOn: 'blur' | 'submit' | 'change' = 'change',
    inputStyling?: string,
    range?: {
      min?: number;
      max?: number;
    }
  ) {
    this.type = type;
    if (Type.isDefined_NotNull(text)) {
      this.text.name = text.name;
      this.text.label = text.label;
      this.text.hint = text.hint;
      this.text.suffix = text.suffix;
    }

    this.inputStyling = inputStyling ? inputStyling : '';

    // Use property if given, defaults to "name"
    if (text?.showProperty) {
      this.text.showProperty = text.showProperty;
    }

    this.defaultValue = defaultValue;
    this.tabGroupId = tabGroupId;

    if (prefixIcon) {
      this.prefixIcon = prefixIcon;
    }

    if (id) {
      this.id = id;
    }

    if (options) {
      this.options = options;
    }

    if (guiOrderIdx !== null && guiOrderIdx !== undefined) {
      this.guiOrderIdx = guiOrderIdx;
    }

    this.validators = validators;

    if (range) {
      this.min = range.min;
      this.max = range.max;
    }

    this.formControl = new UntypedFormControl(this.defaultValue, {
      validators: validators,
      updateOn: updateOn,
    });

    this.setRequired(required);

    this.errorMsgs = errorMsgs ? errorMsgs : new Map<string, string>();

    this.formControl.valueChanges.subscribe((s) => {
      if (!isNaN(s) && !Array.isArray(s)) {
        const decimals: number = s.toString().split('.')[1]?.length || 0;
        if (decimals > 3) {
          s = parseFloat(s).toFixed(3);
        }

        this.formControl.setValue(s, { emitEvent: false });
      }

      if (type === FieldType.CHECKBOX || type === FieldType.SLIDETOGGLE) {
        if (s) {
          this.formControl.setValue(true, { emitEvent: false });
        } else {
          this.formControl.setValue(false, { emitEvent: false });
        }
      }

      // Special case for this field. Checkbox true => 1, false => 0;
      if (
        this.id === 'mainFormGroup.strategy.lifting_column.dynamic_positioning'
      ) {
        if (s) {
          this.formControl.setValue(1, { emitEvent: false });
        } else {
          this.formControl.setValue(0, { emitEvent: false });
        }
      }
    });
  }

  setValue(value: string | number | any) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  addChild(child?: Field) {
    this.children.push(child);
  }

  removeChild(childId: string) {
    this.children = this.children.filter((f) => f.id !== childId);
    // Update children ids, names and labels according to list of children.
    this.defaultValue = [];
    this.children.forEach((child, index) => {
      child.id = child.text.name + '.' + index;
      child.text.label = `Product no. ${index + 1}`;
      child.text.hint = `Location of sensor no. ${index + 1}`;
      this.defaultValue[index] = child.formControl.value;
    });

    this.formControl.setValue(this.defaultValue);
  }

  /**
   * @param enabledBy fields
   */
  setEnabledBy(enabledByField: IEnabledByField[]) {
    enabledByField.forEach((field: IEnabledByField) => {
      this.reactive.enabled_by_field.push({ validCondition: false, ...field });
    });

    this.disable();
  }

  isRequired(): boolean {
    return this.required;
  }

  setRequired(required: boolean) {
    this.required = required;
    if (this.required && !this.formControl.hasValidator(Validators.required)) {
      this.formControl.addValidators(Validators.required);
    } else if (
      !this.required &&
      this.formControl.hasValidator(Validators.required)
    ) {
      this.formControl.removeValidators(Validators.required);
    }
  }

  getId() {
    return this.id;
  }

  getParentId() {
    return this.parentId;
  }

  getHint(): string {
    return this.text.hint;
  }

  setHint(hint: string) {
    this.text.hint = hint;
  }

  getValue(): string | number | object | boolean {
    return this.formControl.value;
  }

  makeClean() {
    this.formControl.markAsPristine();
  }

  makeDirty() {
    this.formControl.markAsDirty();
  }

  hide() {
    this.reactive.hidden = true;
    this.disable();
  }

  show() {
    this.reactive.hidden = false;
    this.enable();
  }

  enable() {
    this.reactive.enabled = true;
    this.formControl.enable({ onlySelf: true, emitEvent: false }); // Has to not emit event. Emitting event will result in eternal loop
  }

  disable() {
    this.reactive.enabled = false;
    this.formControl.disable({ onlySelf: true, emitEvent: false }); // Has to not emit event. Emitting event will result in eternal loop
  }
}
