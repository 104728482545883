import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, take, tap } from 'rxjs/operators';
import { PatternEditComponent } from '../components/patterns/pattern/pattern-edit/pattern-edit.component';
import { Layer } from '../models_new/classes/layer';
import { Pallet } from '../models_new/classes/pallet';
import { Project } from '../models_new/classes/project';
import { ProjectData } from '../models_new/classes/project-data';
import { settings } from '../models_new/config/application-settings';
import { AltLayout } from '../models_new/enums/alt-layout';
import { LayerApproach } from '../models_new/enums/layer-approach';
import { LayerType } from '../models_new/enums/layer-type';
import { PalletPosition } from '../models_new/enums/pallet-position';
import { StackingMethod } from '../models_new/enums/stacking-method';
import { UpdateAction } from '../models_new/enums/update-action';
import { BasePatternConfig } from '../models_new/types/base-pattern-config';
import { IEditing } from '../models_new/types/editing-pattern';
import { ObjectUtils } from '../utils/object';
import { ErrorHandlerService } from './error-handler.service';
import { MagicStackService } from './magic-stack.service';
import { SubnavViewService } from './subnav-view.service';
import { WorkspaceService } from './workspace.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PalletEditorService {
  constructor(
    private subNavView: SubnavViewService,
    private workspaceService: WorkspaceService,
    private errorHandler: ErrorHandlerService,
    private magicStackService: MagicStackService,
    private dialog: MatDialog
  ) {}

  /**
   * @param setPredefined: boolean
   */
  _makePalletDirty(setPredefined: boolean) {
    if (setPredefined) {
      this.subNavView.dirtyPallet$.next(true);
      this.subNavView.selectedStackingMethod$.next(StackingMethod.CUSTOMIZED);
    }
  }

  initiateBaseLayer(p: Project): BasePatternConfig {
    const newLayer = new Layer({
      palletPosition: p.getActivePallet().position,
      type: LayerType.LAYER,
      typeId: '1',
    });

    const options = this.magicStackService.getConfig(p.getProjectData());

    const basePatternConfig: BasePatternConfig = {
      layer: newLayer,
      options: options,
      basePatterns: null,
      basePatternsIndexes: null,
      project: p,
    };

    basePatternConfig.basePatterns = this.magicStackService
      .toOptionPages(basePatternConfig.options, 50)[0]
      .slice(0, 10);
    basePatternConfig.basePatternsIndexes = new Array(
      basePatternConfig.basePatterns.length
    )
      .fill(null)
      .map((m, index) => index)
      .slice(0, 10);

    return basePatternConfig;
  }

  /**
   * @param baseLayer: Layer
   * @param stackingMethod: StackingMethod
   */
  generateLayers(
    baseLayer: Layer,
    stackingMethod: StackingMethod,
    noOfLayers: number,
    p: Project
  ): void {
    const pallet = p.getActivePallet();

    // Clean pallet
    pallet.deleteAllLayers(true);

    // Set typeId to first
    if (baseLayer.getTypeId('layer-no') > 0) {
      baseLayer.setTypeId('1');
    }

    // Add base pattern
    pallet.addLayer(baseLayer, UpdateAction.GENERATING_PALLET);

    // Generate pallet
    if (noOfLayers > 1) {
      this.autoStackPallet(stackingMethod, noOfLayers, p);
    }

    p.setViewAllLayers();
    p.update(
      p.getActivePallet().position,
      [`Generated pallet ${stackingMethod}`],
      UpdateAction.GENERATED_PALLET
    );
  }

  /**
   * @param stackingMethod: StackingMethod
   */
  autoStackPallet(
    stackingMethod: StackingMethod,
    noOfLayers: number,
    p: Project
  ) {
    const pallet = p.getActivePallet();

    const maxHeight = p.data.box.dimensions.height * noOfLayers;

    const isOdd = (number: number) => (number % 2 ? true : false);

    let iterator = 0;
    while (pallet.dimensions.loadHeight < maxHeight && iterator < 100) {
      const layerPositionToCopy: number =
        iterator === 0 || isOdd(iterator) ? 1 : 2;
      const clone: Layer = ObjectUtils.cloneObject(
        pallet.getLayerByPosition(layerPositionToCopy)
      );

      // Set new layer typeId and name. Layer.id is added by pallet.addLayer()

      if (iterator > 0) {
        // Clone from first or second layer on pallet.
        pallet.addLayer(clone, UpdateAction.GENERATING_PALLET);
      } else {
        // Do stacking method on first iteration(Layer no. 2 on pallet).

        if (stackingMethod !== StackingMethod.DUPLICATE) {
          clone.setTypeId(
            (pallet.getHighestTypeId(LayerType.LAYER) + 1).toString()
          );
          clone.setName();
        }

        if (stackingMethod === StackingMethod.ROTATE) {
          this.rotateLayer(clone, p);
        }

        if (stackingMethod === StackingMethod.MIRROR_HORIZ) {
          this.mirrorLayerHoriz(clone, p);
        }

        if (stackingMethod === StackingMethod.MIRROR_VERT) {
          this.mirrorLayerVert(clone, false, p);
        }

        if (stackingMethod === StackingMethod.DUPLICATE) {
          pallet.addLayer(clone, UpdateAction.GENERATING_PALLET);
        }

        if (stackingMethod !== StackingMethod.DUPLICATE) {
          pallet.addLayer(clone, UpdateAction.GENERATING_PALLET);
        }
      }

      // Updates pallet and layers info.
      pallet.updatePalletDynamic();

      iterator++;

      // Stop if next layer will exeed pallet max height
      if (pallet.dimensions.loadHeight + clone.height > maxHeight) {
        break;
      }
    }
  }

  addShimPaper(p: Project): void {
    const pallet = p.getActivePallet();

    const shimPaperLayer = new Layer({
      palletPosition: p.getActivePalletPosition(),
      type: LayerType.SHIMPAPER,
      typeId: '1',
      height: p.getProjectData().getShimPaperData().height,
    });

    pallet.addLayer(shimPaperLayer, UpdateAction.ADD_NEW, true);
    let shimIndex = [...pallet.layers]
      .reverse()
      .findIndex((l: Layer) => l.id === shimPaperLayer.id);

    // If alt pallet is custom add shimpaper to that pallet
    const altPalletLayout = p.getPalletAdvancedSettings().getAltLayout();

    if (altPalletLayout === AltLayout.CUSTOM) {
      const secondaryPallet = p.getPalletByPosition(settings.secondaryPallet);

      secondaryPallet.addLayerAtIndex(
        secondaryPallet.layers.length - shimIndex,
        shimPaperLayer,
        UpdateAction.ADD_NEW
      );
    }
  }

  addNewLayer(p: Project): Observable<Project> {
    const pallet = p.getActivePallet();

    const newLayer: Layer = new Layer({
      palletPosition: p.getActivePalletPosition(),
      type: LayerType.LAYER,
      typeId: (pallet.getHighestTypeId(LayerType.LAYER) + 1).toString(),
    });

    return this.workspaceService.openPatternAutostack(newLayer).pipe(
      take(1),
      tap((layer: Layer) => {
        if (layer) {
          layer.setHeight();
          pallet.addLayer(layer, UpdateAction.ADD_NEW);
        }
      }),
      map((_) => p)
    );
  }

  /**
   * @param layerId as string
   */
  ivertLayerApproachById(
    params: { layerId: string; inverted: boolean },
    p: Project
  ): void {
    const pallet = p.getActivePallet();

    const layer = pallet.getLayerById(params.layerId);
    const projectData: ProjectData = p.data;
    const newAppraoch: LayerApproach = params.inverted
      ? LayerApproach.INVERSE
      : LayerApproach.NULL;

    try {
      // Add inverse and replace boxes in all layers with same typeId
      const sameLayers = pallet
        .getLayersByTypeId(layer.typeId)
        .filter((f) => f.type === LayerType.LAYER);

      sameLayers.forEach((l: Layer) => {
        const tempBoxes = this.workspaceService.automaticSortBoxes(
          projectData,
          l.boxes,
          newAppraoch === LayerApproach.INVERSE,
          l.overrideBoxIds
        );

        l.approach = newAppraoch;
        l.setBoxes(tempBoxes);
      });

      // Update project
      pallet.setLayers(pallet.layers);
    } catch (error) {
      this.errorHandler.handleError(error);
    } finally {
      this._makePalletDirty(true);
    }
  }

  /**
   * @param layerId shimpaper layers id
   */
  editShimpaperHeightById(
    params: { layerId: string; height: number },
    p: Project
  ): void {
    const pallet = p.getActivePallet();
    const shimpaper = pallet.getLayerById(params.layerId);
    shimpaper.preserve();
    shimpaper.setHeight(params.height);
    shimpaper.setTypeId(
      (pallet.getHighestTypeId(LayerType.SHIMPAPER) + 1).toString()
    );
    shimpaper.setName();

    const otherPallet = p.getPalletByPosition(
      pallet.position === PalletPosition.LEFT
        ? PalletPosition.RIGHT
        : PalletPosition.LEFT
    );

    const secondaryLayer = otherPallet.getLayerByPosition(
      shimpaper.layerPosition
    );

    if (secondaryLayer && secondaryLayer.type === LayerType.SHIMPAPER) {
      secondaryLayer.preserve();
      secondaryLayer.setHeight(params.height);
      shimpaper.setTypeId(
        (otherPallet.getHighestTypeId(LayerType.SHIMPAPER) + 1).toString()
      );
      shimpaper.setName();
    }

    pallet.update([shimpaper.id], UpdateAction.DEFAULT_LAYER_UPDATE);
    otherPallet.update([shimpaper.id], UpdateAction.DEFAULT_LAYER_UPDATE);
  }

  /**
   * @param index: number
   */
  removeLayerByIndex(index: number, p: Pallet) {
    try {
      p.removeLayerAtIndex(index);
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  /**
   * @param layerId: string
   */
  removeLayerById(layerId: string, p: Project): void {
    const pallet = p.getActivePallet();

    try {
      const layerPositionToRemove = pallet.getLayerById(layerId).layerPosition;

      // If alt pallet is custom and layer is shimpaper, remove this on alt pallet
      const altPalletLayout = p.getPalletAdvancedSettings().getAltLayout();

      if (
        altPalletLayout === AltLayout.CUSTOM &&
        pallet.position !== settings.secondaryPallet
      ) {
        const secondaryPallet = p.getPalletByPosition(settings.secondaryPallet);
        const secondaryLayer = secondaryPallet.getLayerByPosition(
          layerPositionToRemove
        );

        if (secondaryLayer && secondaryLayer.type === LayerType.SHIMPAPER) {
          secondaryPallet.removeLayerById(secondaryLayer.id);
        }
      }

      // Remove primary pallet layer
      pallet.removeLayerById(layerId);
    } catch (err) {
      this.errorHandler.handleError(err);
    } finally {
      this._makePalletDirty(true);
    }
  }

  /**
   * @param layerId: string
   */
  editLayer(layerId: string, p: Project): Observable<Project | Error> {
    this._makePalletDirty(true);
    const pallet = p.getActivePallet();
    const layer = p.getLayerById(pallet.position, layerId);
    let newLayer: Layer;

    const alikeLayers = pallet.getLayersByTypeId(layer.typeId);

    if (alikeLayers.length > 1) {
      const clone = this.cloneLayer(layer);
      newLayer = new Layer({
        palletPosition: pallet.position,
        type: clone.type,
        typeId: (pallet.getHighestTypeId(clone.type) + 1).toString(),
        height: clone.height,
      });

      newLayer.setBoxes(clone.boxes);
      newLayer.approach = clone.approach;

      const layerIndex = pallet.layers.findIndex(
        (l: Layer) => l.id === clone.id
      );

      pallet.addLayerAtIndex(layerIndex, newLayer, UpdateAction.DUPLICATE);

      const layerEditing: IEditing = {
        oldLayer: layer,
        newLayer: newLayer,
        saved: false,
      };

      this.workspaceService.startLayerSavedTracking(layerEditing, pallet);
    }

    if (newLayer && newLayer.id) {
      return this.editAll(newLayer.id, p);
    } else {
      return this.editAll(layer.id, p);
    }
  }

  /**
   * @param layerId: string
   */
  editAll(layerId: string, p: Project): Observable<Project | Error> {
    const pallet = p.getActivePallet();

    let layer;
    try {
      layer = p.getLayerById(pallet.position, layerId);
    } catch (err) {
      layer = null;
      this.errorHandler.handleError(err);
    }

    if (!layer) {
      throw new Error('Layer not found');
    } else {
      this._makePalletDirty(true);

      const patternDialog$ = this.dialog.open(PatternEditComponent, {
        data: {
          project: p,
          layerId: layerId,
          palletPosition: pallet.position,
        },
        width: '100%',
        height: '90%',
        disableClose: true,
        panelClass: 'pallet-dialog-container',
      });

      return patternDialog$.afterClosed().pipe(
        take(1),
        tap((_project: Project) => {
          pallet.update([layerId], UpdateAction.DEFAULT_LAYER_UPDATE);
          p.update(
            p.getActivePallet().position,
            [`Updated pallet`],
            UpdateAction.DEFAULT_LAYER_UPDATE
          );
        })
      );
    }
  }

  /**
   * @param layer: Layer
   */
  rotateLayer(layer: Layer, p: Project): void {
    this.mirrorLayerVert(layer, true, p);
    this.mirrorLayerHoriz(layer, p);
  }

  /**
   * @param layer: Layer
   */
  mirrorLayerHoriz(layer: Layer, p: Project): void {
    try {
      this.workspaceService.swapHorizOnPallet(
        p.getProjectData().getPalletData().dimensions,
        layer.boxes
      );
      layer.setBoxes(
        this.workspaceService.automaticSortBoxes(
          p.getProjectData(),
          layer.boxes,
          layer.palletPosition === PalletPosition.LEFT
        )
      );
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * @param layer: Layer
   */
  mirrorLayerVert(layer: Layer, skipSorting = false, p: Project): void {
    try {
      this.workspaceService.swapWertOnPallet(
        p.getProjectData().getPalletData().dimensions,
        layer.boxes
      );

      layer.setBoxes(
        skipSorting
          ? layer.boxes
          : this.workspaceService.automaticSortBoxes(
              p.getProjectData(),
              layer.boxes,
              layer.palletPosition === PalletPosition.LEFT
            )
      );
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * @param layerId: string
   */
  duplicateOboveLayerById(
    layerId: string,
    p: Pallet,
    newTypeId: boolean
  ): void {
    let clone: Layer;
    try {
      clone = this.cloneLayer(p.getLayerById(layerId));
    } catch (error) {
      this.errorHandler.handleError(error);
    } finally {
      if (clone) {
        this._makePalletDirty(true);

        const layerIndex = p.layers.findIndex((l: Layer) => l.id === clone.id);

        const newLayer = this.makeClone(clone, p, newTypeId);

        p.addLayerAtIndex(layerIndex, newLayer, UpdateAction.DUPLICATE);
      }
    }
  }

  mirrorPrevHoriz(p: Project): void {
    const pallet = p.getActivePallet();

    const clone = this.cloneLayer(pallet.getTopLayer());

    const newLayer = clone
      ? this.makeClone(clone, pallet, true)
      : new Layer({
          palletPosition: p.getActivePalletPosition(),
          type: LayerType.LAYER,
          typeId: '1',
        });

    this.mirrorLayerHoriz(newLayer, p);

    pallet.addLayer(newLayer, UpdateAction.MIRRORED_PREV_HORIZ);
  }

  mirrorPrevVert(p: Project): void {
    const pallet = p.getActivePallet();

    const clone = this.cloneLayer(pallet.getTopLayer());

    const newLayer = clone
      ? this.makeClone(clone, pallet, true)
      : new Layer({
          palletPosition: p.getActivePalletPosition(),
          type: LayerType.LAYER,
          typeId: '1',
        });

    this.mirrorLayerVert(newLayer, false, p);

    pallet.addLayer(newLayer, UpdateAction.MIRRORED_PREV_VERT);
  }

  rotatePrev(p: Project): void {
    const pallet = p.getActivePallet();
    const clone = this.cloneLayer(pallet.getTopLayer());

    const newLayer = clone
      ? this.makeClone(clone, pallet, true)
      : new Layer({
          palletPosition: p.getActivePalletPosition(),
          type: LayerType.LAYER,
          typeId: '1',
        });

    pallet.addLayer(newLayer, UpdateAction.ROTATED_PREV);

    this.rotateLayer(newLayer, p);
  }

  cloneLayer(layer: Layer): Layer {
    return ObjectUtils.cloneObject(layer);
  }

  makeClone(layer: Layer, p: Pallet, newTypeId: boolean): Layer {
    const clone = this.cloneLayer(layer);

    const newLayer = new Layer({
      palletPosition: p.position,
      approach: clone.approach,
      type: clone.type,
      typeId: !newTypeId
        ? clone.getTypeId('layer-no').toString()
        : (p.getHighestTypeId(clone.type) + 1).toString(),
      height: clone.height,
      boxes: clone.boxes,
    });

    return newLayer;
  }
}
