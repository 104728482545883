<mat-card
  appearance="outlined"
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  [ngStyle]="divideEqually ? { 'grid-template-columns': 'max-content' } : {}"
>
  <mat-card-header *ngIf="title && title !== ''">
    <mat-card-title [style.color]="titleColor">{{ title }}</mat-card-title>
  </mat-card-header>

  <div
    class="content-wrapper"
    [ngStyle]="
      lowHeigth ? { 'padding-top': '1em', 'padding-bottom': '0em' } : {}
    "
  >
    <!-- Inject content -->
    <ng-content></ng-content>
  </div>

  <!-- Inject artwork -->
  <ng-content select="[split-card-artwork]"></ng-content>
</mat-card>
