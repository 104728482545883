import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, shareReplay, take, takeUntil } from 'rxjs';
import { IAboutContent } from 'src/app/models_new/types/about-card-content';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  private templateType: string;
  private fallbackContentTag: string = 'about-content';
  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @Input() pageContent?: IAboutContent;

  contentfulAboutCollection$: Observable<IAboutContent[]>;

  constructor(
    private infoApiService: InfoApiService,
    private activeRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private location: Location
  ) {
    this.contentfulAboutCollection$ = this.infoApiService
      .getAboutContentCards()
      .pipe(take(1), shareReplay({ bufferSize: 1, refCount: false }));
  }

  ngOnInit(): void {
    this.activeRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        this.templateType = queryParams.content || this.fallbackContentTag;
      });
  }

  public getContentByTag(contentfulCollection: IAboutContent[]): IAboutContent {
    let contentToLoad = contentfulCollection.find(
      (content) => content.tag == this.templateType
    );
    if (!contentToLoad)
      contentToLoad = contentfulCollection.find(
        (content) => content.tag == this.fallbackContentTag
      );
    return contentToLoad;
  }

  public isAssetOnlyDisplay(content: IAboutContent['content']): boolean {
    return content.text || content.list || content.faq || content.actionButton
      ? false
      : true;
  }

  public navigateBack(): void {
    this.location.back();
  }
}
