import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatestWith, Subject, switchMap, tap } from 'rxjs';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import { Project } from 'src/app/models_new/classes/project';
import { ObjectUtils } from 'src/app/utils/object';
import { PdfGeneratorData } from '../../../../models_new/classes/pdfGenerator-data';
import { AppLayoutService } from '../../../../services/app-layout.service';
import { IApiPattern } from '../../../../models_new/classes/api-models/ApiPattern';
import { Unit } from '../../../../models_new/enums/unit';
import { ExportImportService } from '../../../../services/export-import.service';
import { PatternApiService } from '../../../../services/api/pattern-api.service';
import * as THREE from 'three';
import { NotificationService } from '../../../../services/notification.service';
import { CompanyLogoComponent } from '../../../gui/company-logo/company-logo.component';
import * as pdfUtils from '../../../../utils/pdf-utils';
import { UnitConversionPipe } from 'src/app/pipes/unit-conversion.pipe';

@Component({
  selector: 'app-pallet-report',
  templateUrl: './pallet-report.component.html',
  styleUrls: ['./pallet-report.component.scss'],
})
export class PalletReportComponent implements OnDestroy, AfterViewInit {
  @ViewChild('orgLogo') orgLogo: CompanyLogoComponent;

  protected readonly Math = Math;

  reportColumns: string[] = ['Detail', 'Case', 'Unit Load'];
  reportTableData = [];

  @Input() pattern: string;
  @Input() assetUrl: string = '/assets/dummy/dummy-logo.jpg';
  @Input() orgId: string;

  @Output() ready$: EventEmitter<void> = new EventEmitter<void>();

  pdfData: PdfGeneratorData;
  project: Project;

  palletScreenShot$: EventEmitter<string> = new EventEmitter<string>();
  palletTopScreenShot$: EventEmitter<string> = new EventEmitter<string>();

  ThreeHandlerMode = ThreeHandlerMode;
  objUtil = ObjectUtils;
  topCameraPos: THREE.Vector3 = new THREE.Vector3(0, 1, 0);

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public appLayout: AppLayoutService,
    private exportImportService: ExportImportService,
    private patternApi: PatternApiService,
    private unitsPipe: UnitConversionPipe,
    private notifier: NotificationService
  ) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.orgLogo.ready$
      .pipe(
        switchMap(() => this.patternApi.fetchPatternById(this.pattern)),
        tap((pattern: IApiPattern) => {
          this.project = new Project(
            'pallet-report',
            this.exportImportService.mapToProjectFormat(
              pattern.data,
              'pallet-report',
              true,
              false
            )
          );
        }),
        combineLatestWith(this.palletScreenShot$, this.palletTopScreenShot$)
      )
      .subscribe({
        next: ([_, pallet, palletTop]) => {
          this.pdfData = new PdfGeneratorData(
            this.project,
            Unit.METRIC,
            this.unitsPipe,
            pallet,
            palletTop
          );
          this.reportTableData = this.pdfData.getPalletTableData();

          pdfUtils
            .waitForElement('.report-summary', document)
            .then(() => this.ready$.emit());
        },
        error: (err) => this.notifier.showError(err),
      });
  }
}
