import { LayerType } from '../../enums/layer-type';
import * as THREE from 'three';
import { track } from 'src/app/utils/resource-tracker';
import { palletViewColors } from '../../config/pallet-view-colors';
import { Dimensions } from '../../types/dimensions';

export class ThreeLayer extends THREE.Object3D {
  constructor(
    public layerType: LayerType,
    private zPosition: number,
    private dimensions: Dimensions
  ) {
    super();

    this.makeVisualObject();
  }

  makeVisualObject(): void {
    if (this.layerType === LayerType.SHIMPAPER) {
      // Boxes are handled by ThreePallet for simplicity sake
      /**
       * Give shim paper one box that is the shim paper.
       */
      const layerMesh = new THREE.Mesh(
        track(
          new THREE.BoxGeometry(
            this.dimensions.width + 0.05, // Make sligtly bigger than pallet
            this.dimensions.height,
            this.dimensions.length + 0.05 // Make sligtly bigger than pallet
          )
        ),
        track(
          new THREE.MeshLambertMaterial({
            color: palletViewColors.unselectedPallet,
          })
        )
      );

      layerMesh.position.set(
        this.dimensions.width / 2, // Set same position as pallet has size
        this.zPosition,
        -this.dimensions.length / 2 // Set same position as pallet has size
      );

      this.add(layerMesh);
    }
  }

  /**
   * Gets Height
   * @returns Height in millimeters
   */
  getHeight(): number {
    return this.dimensions.height;
  }
}
