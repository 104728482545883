<mat-card
  appearance="outlined"
  [ngClass]="cardStyleClass()"
  *ngIf="rcConfig$ | async as robotConfiguration"
>
  <div class="left">
    <div class="hstack" style="justify-content: space-between">
      <div class="vstack" style="width: 100%">
        <div class="hstack" style="margin-bottom: 6px; width: 100%">
          <h3 class="tagline">ROBOT CONFIGURATION</h3>

          <button
            style="margin-left: auto"
            mat-icon-button
            *ngIf="doesAllowActions"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let action of actions">
              <button
                *appIfCommandAvailable="
                  action.roleAction ? action.roleAction : 'default_action'
                "
                mat-menu-item
                (click)="
                  actionClick.emit({
                    label: action.label,
                    actionId: action.actionId,
                    element: robotConfig
                  })
                "
              >
                <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
                <span>{{ action.label }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
        <h1 class="title">{{ robotConfiguration.name | titlecase }}</h1>
        <div class="hstack">
          <h3 class="tagline">Last edited</h3>
          <h3 class="tagline" style="color: #070a17">
            {{
              timeUtils.getFormattedDate(
                robotConfiguration.updated_at,
                'time-since'
              ) + ' ago'
            }}
          </h3>
        </div>

        <mat-chip-listbox class="hstack chips">
          <mat-chip-option
            [selected]="viewMode === 'summary'"
            (click)="setViewMode('summary'); $event.stopPropagation()"
            >Summary
          </mat-chip-option>
          <mat-chip-option
            [selected]="viewMode === 'hardware'"
            (click)="setViewMode('hardware'); $event.stopPropagation()"
            >Hardware
          </mat-chip-option>
          <mat-chip-option
            [selected]="viewMode === 'software'"
            (click)="setViewMode('software'); $event.stopPropagation()"
            >Software
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <div style="overflow-y: auto">
      <p
        class="spaced-wrapper"
        *ngFor="let column of displayedColumns[viewMode]"
      >
        <span class="key">{{ column.name }}</span>
        <span class="val">{{
          (get(robotConfiguration, column.key) || 'N/A') + (column.suffix ?? '')
            | unitSystem
        }}</span>
      </p>
    </div>
  </div>

  <div style="display: flex" class="right">
    <div
      style="width: 100%"
      mat-card-image
      class="hw-image rounded-corners-left"
    >
      <img
        mat-card-image
        [src]="robotConfiguration.scene.image.url"
        class="rounded-corners-left"
        style="object-fit: cover; height: 100%; width: 50%; margin: 0"
      />
    </div>
    <ng-container
      *appVar="robotConfiguration.strategy.data?.software as software"
    >
      <div
        #URVersion
        class="ur-version no-select"
        [ngStyle]="
          polyscopeVersionBackground(software?.polyscope_version || 'N/A')
        "
      >
        <img
          style="width: 70%"
          src="../../../../assets/icons/ur_logo.png"
          alt="ur_logo"
        />
        <div
          class="version-text"
          *ngIf="software?.polyscope_version !== 'latest'"
        >
          {{ 'V' + (software?.polyscope_version || ' N/A') }}
        </div>
      </div>

      <div
        #PallyVersion
        class="pally-version no-select"
        [ngStyle]="pallyVersionBackground(software?.pally_version || 'N/A')"
      >
        <img
          style="width: 70%"
          src="../../../../assets/icons/Pally_logo.png"
          alt="pally_logo"
        />
        <div class="version-text" *ngIf="software?.pally_version !== 'latest'">
          {{ 'V' + (software?.pally_version || ' N/A') }}
        </div>
      </div>
    </ng-container>
  </div>
</mat-card>
