import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubnavViewService } from 'src/app/services/subnav-view.service';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { IGripperOrientationOption } from 'src/app/models_new/types/gripper-orientation-option';
import { ObjectUtils } from 'src/app/utils/object';
import { projectDataNavs } from 'src/app/models_new/config/project-data-steps';
import { Field } from 'src/app/models_new/classes/field';
import { EditorBox } from 'src/app/models_new/classes/editor-box';
import { BehaviorSubject, combineLatest, of, skipWhile, switchMap } from 'rxjs';
import { FieldType } from 'src/app/models_new/types/field-type';

@Component({
  selector: 'app-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss'],
})
export class SubNavbarComponent {
  pagesPATH = pagesPATH;
  projectDataNavs: any[] = projectDataNavs;

  indexField: Field;
  isFieldReady$ = new BehaviorSubject<boolean>(null);

  @Input() boxesInLayer: EditorBox[];
  @Output() indexBoxUpdate: EventEmitter<{ prevId: number; nextId: number }> =
    new EventEmitter();

  constructor(public subnavView: SubnavViewService) {
    combineLatest([
      this.subnavView.patternSelectedBoxes$,
      this.subnavView.currentLayer$,
    ]).subscribe(([selectedBoxes, layer]) => {
      // Enable the field when there is only one box selected
      this.isFieldReady$.next(false);
      if (selectedBoxes?.length === 1) {
        // Create index-edit field and set the selected box as default value.
        this.indexField = new Field(
          FieldType.SELECT_SINGLE,
          null,
          selectedBoxes[0].getId() + 1,
          [],
          null,
          Array.from(layer.boxes?.keys()).map((index) => index + 1),
          null,
          { label: 'Change box index' },
          'index'
        );
        this.isFieldReady$.next(true);
      }
    });

    // When ready, keep track of the index field value changes to update the box index.
    this.isFieldReady$
      .pipe(
        skipWhile((isReady) => isReady === null),
        switchMap(() => this.indexField?.formControl?.valueChanges || of(null))
      )
      .subscribe((res) => {
        if (!res) return;
        this.indexBoxUpdate.emit({
          prevId: this.indexField?.defaultValue - 1,
          nextId: res - 1,
        });
        this.unselectBoxes();
      });
  }

  changeApproach(checked: boolean) {
    this.subnavView.inverseApproachClicked$.next(true);
    this.subnavView.inverseApproach$.next(checked);
  }

  changeGripperOrientation(
    gripperOrientations: IGripperOrientationOption[],
    orientation: IGripperOrientationOption
  ) {
    const clone: IGripperOrientationOption[] =
      ObjectUtils.cloneObject(gripperOrientations);
    clone
      .filter((m) => m.name === orientation.name)
      .map((m) => (m.selected = orientation.selected));
    this.subnavView.patternSetEnforcedGripperOrientation$.next(clone);
  }

  public unselectBoxes(): void {
    this.subnavView.patternSelectedBoxes$.next(null);
    this.subnavView.view$.next(pagesPATH.PATTERNEDIT);
  }

  public toggleCustomIndexLayer(currentState: boolean): void {
    this.subnavView.customBoxIndexLayer$.next(!currentState);
  }
}
