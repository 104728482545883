<div class="top-bar-wrapper">
  <div class="buttons">
    <!-- DELETE -->

    <ng-container *ngIf="enableDelete && selected.length > 0">
      <ng-container
        *appIfCommandAvailable="
          objectType ? 'delete_' + objectType : 'default_action'
        "
      >
        <button
          delete
          mat-raised-button
          pally-icon-button-m
          color="warn"
          (click)="delete.emit(true)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <mat-divider
          style="margin: 0px 15px; height: 38px"
          [vertical]="true"
        ></mat-divider>
      </ng-container>
    </ng-container>

    <!-- COMPARE -->
    <ng-container *ngIf="viewCompare">
      <ng-container *appIfCommandAvailable="'compare'">
        <div matTooltip="Compare items!">
          <button
            [disabled]="selected.length < 2"
            mat-raised-button
            pally-rounded-button-m
            color="accent"
            class="button"
            (click)="compare.emit(true)"
          >
            <mat-icon>compare</mat-icon>Compare
          </button>
        </div>
      </ng-container>
    </ng-container>

    <!-- TOGGLE VIEW -->
    <button
      mat-raised-button
      pally-icon-button-m
      pally-hollow-button-blue
      class="button"
      (click)="switchContent()"
      *ngIf="enableToggle"
    >
      <mat-icon *ngIf="mode === 'table'">list</mat-icon>
      <mat-icon *ngIf="mode === 'card-list'">table_view</mat-icon>
    </button>

    <ng-content select="app-header-actions"></ng-content>

    <!-- FILTER -->
    <button
      *ngIf="enableFiltering"
      mat-raised-button
      pally-rounded-button-m
      pally-hollow-button-blue
      class="button"
      [disabled]="!(setupFilter || resultFilter)"
      [matMenuTriggerFor]="menuFilter"
    >
      <mat-icon svgIcon="filter-alt"></mat-icon>Filters
    </button>

    <mat-menu
      #menuFilter="matMenu"
      backdropClass="mat-menu-no-padding"
      xPosition="before"
    >
      <div
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        <app-filter-menu
          [resultFilter]="resultFilter"
          [setupFilter]="setupFilter"
          (filtering$)="updateFiltering($event)"
        ></app-filter-menu>
      </div>
    </mat-menu>

    <!-- SORT -->
    <button
      *ngIf="enableSorting"
      mat-raised-button
      pally-rounded-button-m
      pally-hollow-button-blue
      class="button"
      [matMenuTriggerFor]="menuSort"
      [disabled]="!sortingColumns"
    >
      <mat-icon>sort</mat-icon>Sort
    </button>

    <mat-menu
      backdropClass="mat-menu-no-padding"
      #menuSort="matMenu"
      xPosition="before"
    >
      <div
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        <app-sort-menu
          [initSort]="initSort"
          [orderByColumns]="sortingColumns"
          (orderBy$)="updateSorting($event)"
        ></app-sort-menu>
      </div>
    </mat-menu>
  </div>

  <!-- SEARCH -->
  <div class="d-flex flex-row" style="align-items: center" *ngIf="enableSearch">
    <mat-icon style="margin-right: 8px">search</mat-icon>
    <mat-form-field class="search-field" app-minimalistic-border>
      <input
        type="text"
        (input)="searchEvent($event)"
        matInput
        placeholder="Search"
      />
    </mat-form-field>
  </div>
</div>

<!-- CONTENT -->
<div *ngIf="mode === 'table'">
  <ng-container *ngTemplateOutlet="tableRef"></ng-container>
</div>

<div *ngIf="mode === 'card-list'">
  <ng-container [ngTemplateOutlet]="cardRef"></ng-container>
</div>
