import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReplaySubject, combineLatest, Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { RoleAction } from '../models_new/config/role-gui/available-actions';
import { RoleApiService } from '../services/api/role-api.service';
import { RXJSUtils } from '../utils/rxjs-utils';

export function isCommandAvailable([command, availableActions]: [
  string,
  RoleAction[]
]) {
  return command === undefined || availableActions.find((f) => f === command);
}

@Directive({
  selector: '[appIfCommandAvailable]',
})
export class IfCommandAvailableDirective implements OnDestroy, OnInit {
  private command$ = new ReplaySubject<string>(1);
  private subscription = new Subscription();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleApi: RoleApiService
  ) {}

  @Input()
  set appIfCommandAvailable(val: string) {
    this.command$.next(val);
  }

  ngOnInit() {
    this.subscription = combineLatest([
      this.command$,
      this.roleApi.availableActions$.pipe(RXJSUtils.skipWhileNotDefined()),
    ])
      .pipe(map(isCommandAvailable), distinctUntilChanged())
      .subscribe((commandAvailable) => {
        if (commandAvailable) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
