import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { StateService } from 'src/app/auth/state.service';
import {
  AvailableActions,
  RoleAction,
} from 'src/app/models_new/config/role-gui/available-actions';
import { Role } from 'src/app/models_new/types/role';
import { ClientApiService } from './client-api.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleApiService {
  availableActions$: Observable<RoleAction[]> = new Observable();
  sources$: Observable<any>[];

  constructor(
    private clientApi: ClientApiService,
    private stateService: StateService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.availableActions$ = combineLatest([
      this.stateService.isUserLogged$,
      this.route.queryParams,
      this.auth.user$,
    ]).pipe(
      switchMap(([isLogged, qp, user]) => {
        return combineLatest([
          of(isLogged),
          of(qp),
          of(user),
          this.strictRf(user),
        ]);
      }),
      switchMap(([isLogged, qp, user, isBo]) => {
        if (!isBo) {
          if (!isLogged && (qp.public === 'true' || qp.o === '1')) {
            return combineLatest([of(null), of(null), of(false)]);
          } else {
            return combineLatest([
              of(user),
              this.stateService.getCustomerOrSalesOrganization(),
              of(false),
            ]);
          }
        } else {
          return combineLatest([of(user), of(null), of(true)]);
        }
      }),
      switchMap(([user, org, isBo]) => {
        if (!isBo) {
          return org !== null
            ? this.getUserRoles([org.id], user.sub)
            : of(['public'] as Role[]);
        } else {
          return this.getUserRoles(environment.backofficeOrgs, user.sub);
        }
      }),
      switchMap((roles: Role[]) => {
        return of(roles.map((r) => AvailableActions(r)).flat());
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getUserRoles(orgIds: string[], userId: string): Observable<Role[]> {
    const q = gql`
      subscription getUserRolesInOrg($orgIds: [uuid], $userId: String!) {
        organization_member(
          where: {
            organization_id: { _in: $orgIds }
            user_id: { _eq: $userId }
          }
        ) {
          type
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
        variables: {
          orgIds: orgIds,
          userId: userId,
        },
      })
      .pipe(
        map((res: any) => {
          return res.data.organization_member.map((m: any) => m.type);
        })
      );
  }

  strictRf(auth0User: User): Observable<boolean> {
    if (!auth0User) {
      return of(false);
    } else {
      const emailValid =
        auth0User['https://login.myrobot.cloud/user_metadata'].email.includes(
          '@rocketfarm.no'
        );
      const backofficeOrgs = environment.backofficeOrgs;

      return combineLatest([
        this.getUserRoles(backofficeOrgs, auth0User.sub),
        of(emailValid),
      ]).pipe(
        map(([orgs, emailValid]) => {
          return orgs.includes('rf_backoffice') && emailValid;
        })
      );
    }
  }
}
