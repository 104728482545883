import { FileType } from '../enums/file-type';
import { IModelValues } from '../types/model-values';
import { GeneratorParams } from './generator-params';

export class Generator {
  values: IModelValues;
  pointCount: number;
  outputBoundingBox: boolean;

  constructor(
    modelInfo: GeneratorParams,
    visualPath: string,
    collisionPath: string
  ) {
    this.pointCount = modelInfo.type.pointCount;

    const originOffsets: string[] = [];
    const originRpys: string[] = [];
    const scales: string[] = [];

    for (let i = 0; i < modelInfo.transforms.length; i++) {
      const transform = modelInfo.transforms[i];
      const scale = transform.scale();
      const translation = transform.translation();
      const rotation = transform.rotation();

      originOffsets.push(`${translation.x} ${translation.y} ${translation.z}`);
      originRpys.push(`${rotation.x} ${rotation.y} ${rotation.z}`);
      scales.push(`${scale.x} ${scale.y} ${scale.z}`);
    }
    const boundingBox = modelInfo.boundingBox;
    this.outputBoundingBox = modelInfo.type.shouldOutputBoundingBox;

    this.values = {
      name: modelInfo.name,
      linkName: modelInfo.type.id,
      visualPath: visualPath,
      collisionPath: collisionPath,
      originOffsets: originOffsets,
      originRpys: originRpys,
      scales: scales,
      boundingBox: '',
    };

    if (boundingBox) {
      this.values[
        'boundingBox'
      ] = `${boundingBox.x} ${boundingBox.y} ${boundingBox.z}`;
    }
  }

  generate(fileType: FileType) {
    switch (fileType) {
      case FileType.URDF:
        return this.generateURDF();
      case FileType.YAML:
        return this.generateYAML(this.outputBoundingBox);
    }
  }

  generateURDF() {
    let extraJoints = '';
    for (let i = 1; i < this.pointCount; i++) {
      extraJoints += `<joint name="mount_joint" type="fixed">
            <parent link="${this.values.linkName}"/>
            <child link="child"/>
            <origin rpy="${this.values.originOffsets[i]}" xyz="${this.values.originRpys[i]}"/>
          </joint>
          <link name="child"/>`;
    }
    const r = `<?xml version="1.0" encoding="utf-8"?>
    <robot name="${this.values.name}">
      <link name="world"/>
      <joint name="${this.values.linkName}_joint" type="fixed">
        <parent link="world"/>
        <child link="${this.values.linkName}"/>
        <origin rpy="${this.values.originRpys[0]}" xyz="${this.values.originOffsets[0]}"/>
      </joint>
      <link name="${this.values.linkName}">
        <inertial>
          <origin xyz="${this.values.originOffsets[0]}"/>
          <mass value="1.0"/>
          <inertia ixx="1.0" ixy="0.0" ixz="0.0" iyy="100.0" iyz="0.0" izz="1.0"/>
        </inertial>
        <visual>
          <origin rpy="${this.values.originRpys[0]}" xyz="${this.values.originOffsets[0]}"/>
          <geometry>
            <mesh filename="${this.values.visualPath}" scale="${this.values.scales[0]}"/>
          </geometry>
          <material name="LightGrey">
            <color rgba="0.7 0.7 0.7 1.0"/>
          </material>
        </visual>
        <collision>
          <geometry>
            <mesh filename="${this.values.collisionPath}" scale="${this.values.scales[0]}"/>
          </geometry>
          <origin rpy="${this.values.originRpys[0]}" xyz="${this.values.originOffsets[0]}"/>
        </collision>
      </link>
      ${extraJoints}
    </robot>`;
    return r;
  }

  generateYAML(addBoundingBox: boolean) {
    let extraJoints = '';
    for (let i = 1; i < this.pointCount; i++) {
      extraJoints += `mount_xyz: "${this.values.originOffsets[i]}"
mount_rpy: "${this.values.originRpys[i]}"`;
    }

    let boundingBox = '';
    if (addBoundingBox) {
      boundingBox = `bounding_box: "${this.values.boundingBox}"`;
    }

    const r = `name: "${this.values.name}"
visual_mesh: "${this.values.visualPath}"
collision_mesh: "${this.values.collisionPath}"
visual_scale: "${this.values.scales[0]}"
collision_scale: "${this.values.scales[0]}"

origin_xyz: "${this.values.originOffsets[0]}"
origin_rpy: "${this.values.originRpys[0]}"
${boundingBox}
${extraJoints}`;
    return r;
  }
}
