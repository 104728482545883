import { Unit } from '../../enums/unit';
import { IProjectData } from 'src/app/models_new/types/project-data';
import { defaultPallet } from './default-pallet';
import { defaultBox } from './default-box';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { PPBText } from '../../enums/text';

export const defaultData: IProjectData = {
  id: undefined,
  name: PPBText.PROJECTNAME,
  description: '',
  dateModified: new Date().toISOString(),
  unit: Unit.METRIC,
  pallet: {
    dimensions: defaultPallet.dimensions,
    overhang: {
      enabled: false,
      sides: defaultPallet.overhangSides,
      ends: defaultPallet.overhangEnds,
    },
  },
  box: {
    maxGrip: 1,
    dimensions: defaultBox.dimensions,
    weight: defaultBox.weight,
    padding: defaultBox.boxPadding,
    label: {
      enabled: false,
      orientation: LabelOrientation.NULL,
      direction: 'optimized',
    },
  },
  shimPaper: {
    height: 3,
    enabled: true,
  },
};
