import { ElementRef, Component, ViewChild, Input, Inject } from '@angular/core';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { AltLayout } from '../../../../models_new/enums/alt-layout';
import { Project } from '../../../../models_new/classes/project';
import { environment } from '../../../../../environments/environment';
import { Transform } from '../../../../models_new/classes/transform';
import * as THREE from 'three';
import { GeneratorParams } from '../../../../models_new/classes/generator-params';
import { Export } from '../../../../models_new/classes/export';
import { ModelType } from '../../../../models_new/classes/model-type';
import { ModelTypes } from '../../../../models_new/enums/model-types';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { ColladaExporter } from 'three/examples/jsm/exporters/ColladaExporter';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap, take, tap } from 'rxjs/operators';
import { BackofficeService } from '../../../../services/api/backoffice.service';
import { StateService } from '../../../../auth/state.service';
import { combineLatestWith, from, Observable } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-model-upload',
  templateUrl: './model-upload.component.html',
  styleUrls: ['./model-upload.component.scss'],
})
export class ModelUploadComponent {
  pagesPATH = pagesPATH;
  altLayout = AltLayout;
  project: Project;
  step = 0;
  version: string = environment.version;

  generatorParams: GeneratorParams;

  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  controls: OrbitControls;
  models: Array<any>;
  pointCount: number;
  grid: any;
  boundingBoxMesh;

  export: Export;
  shouldUseRad: boolean;
  showBoundingBox: boolean;
  showGrid: boolean;

  @Input() modelType: ModelType;
  @ViewChild('stepper') stepperElement;
  @ViewChild('summary') summaryElement;

  constructor(
    private el: ElementRef,
    public dialogRef: MatDialogRef<ModelUploadComponent>,
    private bos: BackofficeService,
    private stateService: StateService,
    private notifier: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      type: string;
    }
  ) {
    this.render = this.render.bind(this);
    this.generatorParams = new GeneratorParams();
    this.shouldUseRad = false;
    this.showBoundingBox = false;
    this.showGrid = true;
    this.generatorParams.transforms = [];
    this.generatorParams.transforms.push(new Transform());
    this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    this.resetCamera();

    this.generatorParams.type = ModelTypes[input.type];

    this.nextClick = this.nextClick.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  async resetCamera() {
    this.camera.position.set(1, 1, 1);
    this.camera.lookAt(0, 0, 0);
  }

  setStep(step: number) {
    this.step = step;
  }

  setupScene(onProgress: (event: ProgressEvent) => void, onLoaded: () => void) {
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor(0xffffff, 0);

    this.pointCount = this.generatorParams.type.pointCount;
    this.models = [];

    this.setupControls();
    this.setupLights();

    this.makeAxes();
    this.updateGrid();
    this.render();

    new ColladaLoader().load('./assets/models/ur10.dae', (object) => {
      this.scene.add(object.scene);
      if (this.generatorParams.type.id === 'gripper') {
        object.scene.position.set(0.235, -0.795, -0.175);
      }
      this.loadFile(this.generatorParams.file, onProgress, onLoaded);
    });
  }

  setupLights() {
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(1, 1, 0).normalize();
    this.scene.add(directionalLight);
  }

  setupControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 0, 0);
    this.controls.update();
  }

  getFileExtension(file: File): String {
    const splits = file.name.split('.');
    return splits[splits.length - 1].toLowerCase();
  }

  loadFile(
    file,
    onProgress: (event: ProgressEvent) => void,
    onLoaded: () => void
  ) {
    const url = URL.createObjectURL(file);
    const extension = this.getFileExtension(file);
    switch (extension) {
      case 'stl':
        this.loadSTLFile(url, onProgress, onLoaded);
        break;
      case 'dae':
        this.loadColladaFile(url, onProgress, onLoaded);
        break;
      case 'stp':
        console.error('STEP is not implemented yet.');
        break;
      default:
        break;
    }
  }

  loadSTLFile(
    url: string,
    onProgress: (event: ProgressEvent) => void,
    onLoaded: () => void
  ) {
    const loader = new STLLoader();
    loader.loadAsync(url, onProgress).then((geo: THREE.BufferGeometry) => {
      this.addModel(geo);
      onLoaded();
    });
  }

  loadColladaFile(
    url: string,
    onProgress: (event: ProgressEvent) => void,
    onLoaded: () => void
  ) {
    let object;
    const loader = new ColladaLoader();
    loader.load(
      url,
      (obj) => {
        object = obj.scene;
        // Make sure materials are visible.
        // Materials become hidden if warning `Invalid opaque type "null" of transparent tag.` is thrown.
        // Deliberatley hidden parts should be removed or use `visible: false`
        object.traverse((object) => {
          if (object.material) {
            if (object.material.opacity === 0) object.material.opacity = 1;
          }
        });
        this.scene.add(object);
        object.scale.x = object.scale.y = object.scale.z = 1;
        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);
        object.updateMatrix();
        this.didLoadModel(object);
        onLoaded();
      },
      onProgress
    );
  }

  addModel(
    // The STL loader adds some non-standard properties to the geometry so we need to extend the type
    geometry: THREE.BufferGeometry & { hasColors?: boolean; alpha?: number }
  ) {
    let material = new THREE.MeshLambertMaterial({ color: 0xff00ff });

    if (geometry.hasColors) {
      material = new THREE.MeshLambertMaterial({
        opacity: geometry.alpha,
        vertexColors: true,
      });
    }
    const mesh = new THREE.Mesh(geometry, material);
    this.scene.add(mesh);
    this.didLoadModel(mesh);
  }

  didLoadModel(model) {
    this.models.push(model);
    //this.export = this.exportScene();

    for (let i = 0; i < this.pointCount - 1; i++) {
      this.models.push(this.addSphere());
    }

    this.updateTransformOnModel();
    this.updateBoundingBoxMesh();
    if (!this.shouldUseRad) {
      const rotationInputs =
        this.el.nativeElement.querySelectorAll('.Rotation');
      rotationInputs.forEach((inp) => {
        const rad = parseFloat(inp.value);
        inp.value = (180 / Math.PI) * rad;
      });
    }
  }

  makeAxes() {
    const xColor = 0xff0000,
      yColor = 0x00ff00,
      zColor = 0x0000ff;

    const length = 1000;
    this.makeLine(xColor, new THREE.Vector3(0, 0, 1), length);
    this.makeLine(yColor, new THREE.Vector3(1, 0, 0), length);
    this.makeLine(zColor, new THREE.Vector3(0, 1, 0), length);

    const axesHelper = this.setAxesHelperColors(
      new THREE.AxesHelper(length),
      yColor,
      zColor,
      xColor
    );
    this.scene.add(axesHelper);

    this.grid = new THREE.GridHelper(length, length);
    this.grid.material = new THREE.LineBasicMaterial({
      vertexColors: true,
      toneMapped: false,
      transparent: true,
    });
    this.scene.add(this.grid);
  }

  setAxesHelperColors(helper, xAxisColor, yAxisColor, zAxisColor) {
    const color = new THREE.Color();
    const array = helper.geometry.attributes.color.array;

    color.set(xAxisColor);
    color.toArray(array, 0);
    color.toArray(array, 3);

    color.set(yAxisColor);
    color.toArray(array, 6);
    color.toArray(array, 9);

    color.set(zAxisColor);
    color.toArray(array, 12);
    color.toArray(array, 15);

    helper.geometry.attributes.color.needsUpdate = true;

    return helper;
  }

  makeLine(color: number, direction: THREE.Vector3, length: number) {
    const points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(
      new THREE.Vector3(1, 1, 1).multiply(direction).multiplyScalar(length)
    );
    points.push(new THREE.Vector3(0, 0, 0));

    const geometry = new THREE.TubeGeometry(
      new THREE.CatmullRomCurve3(points),
      4,
      0.01,
      6,
      false
    );
    const material = new THREE.MeshBasicMaterial({ color: color });
    const mesh = new THREE.Mesh(geometry, material);
    this.scene.add(mesh);
  }

  updateBoundingBoxMesh() {
    const model = this.models[0];
    let helper = new THREE.BoxHelper(model, 0xff0000);
    //const box = helper.geometry.boundingBox.max;

    //this.generatorParams.boundingBox = new Vector3(box.x, box.y, box.z);

    if (this.showBoundingBox) {
      if (!this.boundingBoxMesh) {
        helper.update();
        this.scene.add(helper);
        this.boundingBoxMesh = helper;
      } else {
        this.boundingBoxMesh.update();
      }
    } else {
      this.scene.remove(this.boundingBoxMesh);
      this.boundingBoxMesh = null;
    }
  }

  updateGrid() {
    if (this.showGrid) {
      this.grid.material.opacity = 1;
    } else {
      this.grid.material.opacity = 0;
    }
  }

  addSphere(color = 0x00ffff) {
    const geometry = new THREE.SphereGeometry(0.1, 32, 32);
    const material = new THREE.MeshLambertMaterial({ color: color });
    const mesh = new THREE.Mesh(geometry, material);

    const arrow = new THREE.Mesh(
      new THREE.BoxGeometry(0.2, 0.05, 0.05),
      material
    );
    arrow.position.x = 0.1;
    mesh.add(arrow);

    this.scene.add(mesh);
    return mesh;
  }

  render() {
    requestAnimationFrame(this.render);
    this.renderer.render(this.scene, this.camera);
  }

  exportCollada(model) {
    const exp = new ColladaExporter();
    const txt = exp.parse(model, undefined, {});
    return new Blob([txt.data], { type: 'text/plain' });
  }

  exportSTL(model) {
    const exp = new STLExporter();
    const txt = exp.parse(model, { binary: true });
    return new Blob([txt], { type: 'text/plain' });
  }

  exportScene() {
    //Uncomment to export
    //const stl = this.exportSTL(this.models[0]);
    //const dae = this.exportCollada(this.models[0]);
    this.export = new Export(undefined, undefined, this.generatorParams.file);
  }

  updateTransformOnModel(i = 0) {
    const pos = this.generatorParams.transforms[i].translation();
    const rot = this.generatorParams.transforms[i].rotation();
    const scale = this.generatorParams.transforms[i].scale();

    if (!isNaN(pos.y) && !isNaN(pos.z) && !isNaN(pos.x)) {
      this.models[i].position.set(pos.y, pos.z, pos.x);
    }
    if (!isNaN(rot.y) && !isNaN(rot.z) && !isNaN(rot.x)) {
      this.models[i].rotation.set(rot.y, rot.z, rot.x);
    }
    if (!isNaN(scale.y) && !isNaN(scale.z) && !isNaN(scale.x)) {
      this.models[i].scale.set(scale.y, scale.z, scale.x);
    }

    if (i === 0) {
      this.updateBoundingBoxMesh();
    }
  }

  backClick() {
    this.setStep(this.step - 1);
    if (this.step < 0) {
      this.dialogRef.close();
    }
  }

  nextClick() {
    this.exportScene();
    this.modelType = this.generatorParams.type;

    //Comment out this line to disable the email sending
    this.blobToBase64(this.export.step)
      .pipe(
        take(1),
        combineLatestWith(
          this.stateService.user$,
          this.stateService.getCustomerOrSalesOrganization()
        ),
        switchMap(([stp, user, org]) => {
          return this.bos.sendModelEmail(
            user.email,
            org.id,
            this.generatorParams.type,
            this.generatorParams.name,
            stp
          );
        }),
        tap(() => {
          this.dialogRef.close();
          this.notifier.showMessage(`Model sent for verification!`);
        }),
        take(1)
      )
      .subscribe();
    //Uncomment this line to re-enable the 3D editor and GitHub implementation
    //this.setStep(this.step + 1);
  }

  blobToBase64(blob: Blob): Observable<any> {
    return from(
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () => {
          if (
            typeof reader.result === 'string' &&
            reader.result.includes(',')
          ) {
            return resolve(reader.result.split(',')[1]);
          }
          return resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      })
    );
  }
}
