import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RobotConfigurationRoutingModule } from './robot-config-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared.module';
import { ThreeModule } from '../three.module';
import { HardwareModule } from '../hardware/hardware.module';
import { RobotConfigComponent } from '../../components/robot-configs/robot-config/robot-config.component';
import { AdvancedRobotConfigComponent } from './advanced-robot-config/advanced-robot-config.component';
import { SimConfigFormComponent } from './sim-config-form/sim-config-form.component';
import { ConfigOverviewComponent } from './config-overview/config-overview.component';
import { SummaryComponent } from './summary/summary.component';
import { RobotWrapperComponent } from '../../components/inventory/robot-configs/robot-wrapper/robot-wrapper.component';

@NgModule({
  declarations: [
    RobotConfigComponent,
    AdvancedRobotConfigComponent,
    SimConfigFormComponent,
    ConfigOverviewComponent,
    RobotWrapperComponent,
    SummaryComponent,
  ],
  exports: [RobotConfigComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    CommonModule,
    RobotConfigurationRoutingModule,
    HardwareModule,
  ],
})
export class RobotConfigurationModule {}
