<div
  *ngIf="notification"
  [ngClass]="notification.type"
  class="global-notification"
>
  <ng-container [ngSwitch]="notification.type">
    <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
    <mat-icon *ngSwitchCase="'error'">error</mat-icon>
    <mat-icon *ngSwitchCase="'warning'">warning</mat-icon>
    <mat-icon *ngSwitchCase="'info'">info</mat-icon>
  </ng-container>
  <span
    style="margin-left: 1em"
    [innerHTML]="marked.parse(notification.message)"
  ></span>
  <button style="margin-left: auto" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
