import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  from,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  toArray,
} from 'rxjs';

interface ISpeedTest {
  size: number; // MB
  url: string;
  speed?: number; // MB/s
  time?: number; // seconds
  response?: number; // seconds
}

/**
 * @property {number} amount - Total amount data downloaded for the test in MB (all requests stacked).
 * @property {number} time - Total time for the test in seconds (all requests stacked).
 * @property {number} speed - Average speed achieved in MB/s. Yes, in MB/s due all assets being in megabytes, not bits...
 * @property {number} response - Average response time.
 */
export interface ISpeedTestResult {
  amount: number; // MB
  time: number; // seconds
  speed: number; // MB/s
  response: number; // seconds
}

const speedTestFiles: ISpeedTest[] = [
  {
    size: 1,
    url: 'https://pallydescriptions.blob.core.windows.net/public-models/1mb-speedtest.dat',
  },
  {
    size: 2,
    url: 'https://pallydescriptions.blob.core.windows.net/public-models/2mb-speedtest.dat',
  },
];

@Injectable({
  providedIn: 'root',
})
export class NetSpeedService {
  speed$: Observable<ISpeedTestResult> = this.getNetworkSpeed().pipe(
    shareReplay({ bufferSize: 1, refCount: false })
  );

  constructor() {
    this.speed$.subscribe();
  }

  getNetworkSpeed(): Observable<ISpeedTestResult> {
    const startTime = new Date().getTime();
    return merge(
      ...speedTestFiles.map((f) =>
        // Can't cache these as it would spoil subsequent results if any.
        from(fetch(f.url, { cache: 'no-store' })).pipe(
          switchMap((response) => {
            f.response = (new Date().getTime() - startTime) / 1000;
            return from(response.arrayBuffer()).pipe(
              map(() => {
                f.time = (new Date().getTime() - startTime) / 1000;
                f.speed = f.size / f.time;
                return f;
              })
            );
          })
        )
      )
    ).pipe(
      toArray(),
      map((tests: (ISpeedTest | undefined)[]) => {
        let result: ISpeedTestResult = {
          amount: 0,
          speed: 0,
          time: 0,
          response: 0,
        };
        for (const test of tests) {
          result.amount += test.size;
          result.speed += test.speed;
          result.time += test.time;
          result.response += test.response;
        }
        result.speed /= tests.length;
        result.response /= tests.length;
        return result;
      }),
      catchError((error) => {
        console.error(
          'Failed to check internet speeds. Assuming slow speeds of 0.3 MB/s.'
        );
        console.error(error);
        return of({
          // 3 MB over 10 seconds.
          amount: 3,
          speed: 0.3,
          time: 10000,
          response: 0,
        });
      })
    );
  }
}
