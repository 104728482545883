import { Validators } from '@angular/forms';
import { Field } from 'src/app/models_new/classes/field';
import { FieldType } from 'src/app/models_new/types/field-type';

export const customerEditCardFields: Field[] = [
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required],
    null,
    null,
    null,
    { label: 'Organization name', name: 'name' },
    'name'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [],
    null,
    null,
    null,
    { label: 'Address', name: 'address' },
    'address'
  ),
];
