import { Injectable, OnDestroy, Type } from '@angular/core';
import * as THREE from 'three';
import {
  Collada,
  ColladaLoader,
} from 'node_modules/three/examples/jsm/loaders/ColladaLoader';
import { STLLoader } from 'node_modules/three/examples/jsm/loaders/STLLoader';
import { Asset } from 'src/app/models_new/classes/asset/new-asset';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { IAssetStoreLoadingConfig } from '../../models_new/types/asset/new-asset-store-loading-config';
import { AssetType } from '../../models_new/types/asset/new-asset';
import { openAssetConfig } from '../../models_new/config/asset/asset-config';
import { URDFRobot } from '@rocketfarm/urdf-loader/src/URDFClasses';
import RF_URDFLoader from '@rocketfarm/urdf-loader';
import {
  BehaviorSubject,
  concat,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  retryWhen,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { RXJSUtils } from '../../utils/rxjs-utils';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { settings } from '../../models_new/config/application-settings';
import { ApiService } from '../api/api.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Object3D } from 'three';

@Injectable({
  providedIn: 'root',
})
export class NewAssetStoreService implements OnDestroy {
  private assets = new Map<string, Asset<any>>();

  private azureToken$: Observable<string>;
  private user$: Observable<User>;

  /*
    TODO: LOOK INTO THIS!
    NOTE! This is needed as router events are somehow dissapearing 
    unless something subscribes fast enough?
  */
  private routerEvents$ = new ReplaySubject<NavigationEnd>(1);

  destroy$ = new Subject<Boolean>();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private auth: AuthService
  ) {
    merge(
      // This handles specific refreshes on without navigation
      this.router.events.pipe(
        filter(
          (e): e is NavigationStart =>
            e instanceof NavigationStart && !this.router.navigated
        ),
        map(
          () => new NavigationEnd(0, window.location.href, window.location.href)
        )
      ),
      // This handles any navigation other than refreshes.
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
    )
      .pipe(take(1))
      .subscribe(this.routerEvents$);

    this.user$ = this.routerEvents$.pipe(
      switchMap((e: NavigationEnd) => this.activateUser(e)),
      shareReplay({ bufferSize: 1, refCount: false }) // Allow reuse and sharing
    );

    this.azureToken$ = this.user$.pipe(
      switchMap((user) => {
        return this.activateAzureToken(user);
      }),
      shareReplay({ bufferSize: 1, refCount: false }) // Allow reuse and sharing
    );

    this.loadConfigs(openAssetConfig);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Tries to fetch an asset with the given id.
   * @param id string - id of asset.
   * @returns IAsset<Ttype> - Asset in question.
   * @throws Error - If asset wasn't found.
   */
  public get(id: string): Asset<any> {
    // Asset doesn't exist, complain.
    if (!this.assets.has(id)) {
      throw new Error(`No asset with id: ${id}`);
    }

    return this.load(id);
  }

  /**
   * Makes an asset with the given id and loads it if told to.
   * @param id string - Identifier of the asset.
   * @param src string | undefined - Source of asset.
   * @param type AssetType - Type of asset.
   * @param prefetch boolean - Wether to fetch immediately or not. **Optional**. **Default: false**
   * @param needsSASToken boolean - Wether a sas token is needed. **Optional**. **Default: false**
   * @returns Asset<any (URDFRobot | THREE.Object3D | Font | THREE.Texture | any)> - Asset in question.
   * @throws Error - If asset wasn't found and could not be made.
   */
  public register(
    id: string,
    src: string | undefined,
    type: AssetType,
    prefetch?: boolean,
    needsSASToken?: boolean
  ): Asset<any> {
    // Asset exists already
    if (this.assets.has(id)) {
      return this.assets.get(id);
    }

    let asset: Asset<any>;

    if (type === 'urdf') {
      asset = new Asset<URDFRobot>(id, type, src);
    } else if (
      type === 'dae' ||
      type === 'stl' ||
      type === 'fbx' ||
      type === 'gltf'
    ) {
      asset = new Asset<THREE.Object3D>(id, type, src);
    } else if (type === 'three_font') {
      asset = new Asset<Font>(id, type, src);
    } else if (type === 'texture') {
      asset = new Asset<THREE.Texture>(id, type, src);
    } else if (type === 'json') {
      asset = new Asset<any>(id, type, src);
    } else {
      throw new Error(`Unsupported asset type! "${type}"`);
    }
    asset.needsSASToken = needsSASToken;

    this.assets.set(asset.id, asset);

    if (prefetch) {
      this.load(asset.id);
    }

    return asset;
  }

  /**
   * Find an asset with the given id and loads it if not already loaded.
   * @param id string - id of asset.
   * @param src string | undefined - src of asset.
   * @param type AssetType - type of asset.
   * @returns IAsset<Ttype> - Asset in question.
   */
  public load<Ttype = any>(
    id: string,
    src?: string | undefined,
    type?: AssetType,
    needsSASToken?: boolean
  ): Asset<Ttype> {
    let asset = this.assets.get(id) as Asset<Ttype>;
    // Asset doesn't exists. Make it and load if enough info is given.
    if (!asset && src && type) {
      asset = this.register(
        id,
        src,
        type,
        // This has to be false, or-else an infinite loop will occur.
        false, // Don't load the asset since it will be loaded here.
        needsSASToken
      ) as Asset<Ttype>;
    }
    if (!asset) {
      throw new Error(
        `No asset found with id: ${id}. Cound't make new asset, "src" and/or "type" is undefined`
      );
    }

    if (asset.isLoaded() || asset.isLoading()) {
      return asset;
    }

    if (!asset.src) {
      console.debug(`Skipping loading of asset "${asset.id}". Missing url!`);
      return asset;
    }

    // Start loading
    asset.progress$.next(0);

    if (asset.needsSASToken) {
      this.azureToken$
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe((token: string) => {
          this.handleLoading(asset, token);
        });
    } else {
      this.handleLoading(asset);
    }
    return asset;
  }

  clear(id: string): void {
    // NOTE! Need to find a better way of "clearing" assets
    const asset = this.assets.get(id) as Asset<any>;
    if (asset) {
      asset.progress$.unsubscribe();
      asset.progress$ = new BehaviorSubject<number>(-1);
    }
  }

  loadConfigs(configs: IAssetStoreLoadingConfig<any>[]): void {
    for (const config of configs) {
      this.register(
        config.id,
        config.src,
        config.type,
        config.prefetch,
        config.needsSASToken
      );
    }
  }

  /* --------------------------- Utillity functions --------------------------- */

  private getLoadingInfo(asset: Asset<any>): {
    loader: Type<any>;
    handlerFunc: any;
  } {
    switch (asset.type) {
      case 'dae': {
        return {
          loader: ColladaLoader,
          handlerFunc: (collada: Collada) => {
            collada.scene.name = asset.id;
            asset.set(collada.scene);
          },
        };
      }
      case 'stl': {
        return {
          loader: STLLoader,
          handlerFunc: (geo: THREE.BufferGeometry) => {
            const mesh = new THREE.Mesh(geo, new THREE.MeshPhongMaterial());
            mesh.name = asset.id;
            asset.set(mesh);
          },
        };
      }
      case 'fbx': {
        return {
          loader: FBXLoader,
          handlerFunc: (model: THREE.Object3D) => {
            model.name = asset.id;
            asset.set(model);
          },
        };
      }
      case 'three_font': {
        return {
          loader: FontLoader,
          handlerFunc: (font: Font) => {
            asset.set(font);
          },
        };
      }
      case 'texture': {
        return {
          loader: THREE.TextureLoader,
          handlerFunc: (texture: THREE.Texture) => {
            asset.set(texture);
          },
        };
      }
      case 'json': {
        return {
          loader: THREE.FileLoader,
          handlerFunc: (data: any) => {
            asset.set(JSON.parse(data));
          },
        };
      }

      default: {
        return undefined;
      }
    }
  }

  handleLoadingURDF(
    manager: THREE.LoadingManager,
    asset: Asset<any>,
    token: string
  ): void {
    this.user$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((user: User) => {
        let robot: URDFRobot;
        const loader = new RF_URDFLoader(manager);

        // Need to add packages and the token into the loader, so it can fetch assets.
        this.setupURDFLoader(loader, user, token);

        // Load the asset
        loader.load(
          asset.src,
          (r: URDFRobot) => {
            robot = r;
          },
          undefined
        );
        // We're done with all components of the structure, not only the generic structure.
        manager.onLoad = () => {
          asset.set(robot as any);
          asset.progress$.next(100);
          asset.progress$.complete();
        };
      });
  }

  handleLoadingGLTF(
    manager: THREE.LoadingManager,
    asset: Asset<any>,
    token: string
  ): void {
    let model: Object3D;
    // Instantiate a loader
    const loader = new GLTFLoader(manager);
    const dracoLoader = new DRACOLoader(manager);
    dracoLoader.setDecoderPath('/examples/js/libs/draco/');
    loader.setDRACOLoader(dracoLoader);

    // Load the asset
    loader.load(
      this.resolveURL(asset.src, token),
      (gltf) => {
        model = gltf.scene;
      },
      undefined,
      (event) => {
        if (event.error) {
          asset.reportError(event.error);
        }
      }
    );
    // We're done with all components of the structure, not only the generic structure.
    manager.onLoad = () => {
      asset.set(model as any);
      asset.progress$.next(100);
      asset.progress$.complete();
    };
  }

  private handleLoading(asset: Asset<any>, token?: string): void {
    const manager = new THREE.LoadingManager();
    // URDF and GLB/GLFT requires special handling.
    if (asset.type == 'urdf') {
      this.handleLoadingURDF(manager, asset, token);
    } else if (asset.type == 'gltf') {
      this.handleLoadingGLTF(manager, asset, token);

      // Any other format
    } else {
      const loadingInfo = this.getLoadingInfo(asset);

      from(
        new loadingInfo.loader(manager).loadAsync(
          this.resolveURL(asset.src, token)
        )
      )
        .pipe(
          switchMap((model: any) => {
            // Wait for progress to complete (model is completely loaded)
            // then provide the data.
            return concat(
              asset.progress$.pipe(
                // Ignore all progress numbers, dont
                // want them coming through the data$ observable.
                map(() => null),
                RXJSUtils.filterNull()
              ),
              of(model)
            );
          })
        )
        .subscribe({
          next: loadingInfo.handlerFunc,
          error: (error) => asset.reportError(error),
        });

      manager.onLoad = () => {
        asset.progress$.next(100);
        asset.progress$.complete();
      };
    }

    manager.onProgress = (_: string, loaded: number, total: number) =>
      asset.progress$.next(loaded / total);
    manager.onError = (u: string) =>
      console.error(`Couldn\'t load asset with url "${u}"`);
  }

  private resolveURL(url: string, token?: string): string {
    let location = url;
    if (token) {
      location = `${location}?${token}`;
    }
    return location;
  }

  private activateUser(routerEvent: NavigationEnd): Observable<User> {
    if (
      !routerEvent.urlAfterRedirects.includes('user') &&
      !routerEvent.urlAfterRedirects.includes('public') &&
      !routerEvent.urlAfterRedirects.includes('o=1') &&
      window.location.pathname !== '/' + 'about' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/' + settings.fastTrackMainRoute &&
      !settings.fastTrackRoutes.some((v) =>
        window.location.pathname.includes(v)
      ) &&
      window.location === window.parent.location
    ) {
      return this.auth.user$.pipe(
        map((user: User) => {
          if (!user) {
            throw new Error('missing user');
          } else {
            return user;
          }
        }),
        retryWhen(RXJSUtils.genericRetryStrategy())
      );
    }

    return this.auth.user$;
  }

  private activateAzureToken(user: User | null): Observable<string> {
    const tokensource = user
      ? this.apiService.getPallydescriptionsSASToken()
      : this.apiService.getPublicPallydescriptionsSASToken();

    return tokensource.pipe(
      map((token) => {
        token = decodeURIComponent(token);
        const sigDec = token.substring(token.indexOf('sig=') + 4);
        const sigEnc = encodeURIComponent(sigDec);
        token = token.replace(sigDec, sigEnc);
        return token;
      })
    );
  }

  private setupURDFLoader(
    loader: RF_URDFLoader,
    user: User | null,
    token?: string
  ): void {
    loader.packages = {
      pally_descriptions: settings.pallyDescriptionsURL + 'pally_descriptions',
      ur_description: settings.pallyDescriptionsURL + 'ur_description',
      dsr_description2: settings.pallyDescriptionsURL + 'dsr_description2',
      ur_e_description: settings.pallyDescriptionsURL + 'ur_e_description',
    };

    if (token) {
      // Sideeffect: Update the urdf loader options when we have a token
      loader.queryStringOptions = [
        [settings.pallyDescriptionsURL, '?' + token],
      ];
    }
  }
}
