import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Field } from 'src/app/models_new/classes/field';
import { FieldType } from 'src/app/models_new/types/field-type';

@Component({
  selector: 'app-delete-prompt',
  templateUrl: './delete-prompt.component.html',
  styleUrls: ['./delete-prompt.component.scss'],
})
export class DeletePromptComponent implements OnInit, OnDestroy {
  title: string;
  type: string;
  id: string;
  usageTitle: string;
  usageType: string;
  usageId: string;
  usage: { name: string; id: string; type: string }[];
  usageIdx = 0;
  deleteUsagePrompt = false;
  deleteItems: {
    id: string;
    name: string;
    type: string;
  }[] = [];
  fields: Field[] = [
    new Field(
      FieldType.TEXT,
      true,
      '',
      [Validators.required],
      null,
      null,
      null,
      { label: 'Name', name: 'name' },
      'name'
    ),
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<DeletePromptComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      selectedStepId: any;
      data: {
        element: string;
        type: string;
        id: string;
        usage?: {
          name: string;
          id: string;
          type: string;
        }[];
      };
    }
  ) {}

  ngOnInit(): void {
    this.title = this.input.data.element;
    this.type = this.input.data.type;
    this.id = this.input.data.id;
    this.usage = this.input.data.usage;
    if (this.usage && this.usage.length) {
      this.usage.forEach((u) =>
        this.fields.push(
          new Field(
            FieldType.TEXT,
            true,
            '',
            [Validators.required],
            null,
            null,
            null,
            { label: 'Name', name: 'name' },
            'name'
          )
        )
      );
    }
  }

  checkValidity(fieldNo: number, check: string) {
    if (this.fields[fieldNo].formControl.value === check) {
      return true;
    } else {
      return false;
    }
  }

  setDeletingUsage() {
    this.usageTitle = this.usage[this.usageIdx].name;
    this.usageType = this.usage[this.usageIdx].type;
    this.usageId = this.usage[this.usageIdx].id;
    this.deleteUsagePrompt = true;
  }

  skipDeleteUsage() {
    if (this.usageIdx === this.usage.length - 1) {
      this.closeWithDelete();
    } else {
      this.usageIdx++;
      this.setDeletingUsage();
    }
  }

  deleteUsage() {
    this.deleteItems.push({
      id: this.usageId,
      name: this.usageTitle,
      type: this.usageType,
    });

    if (this.usageIdx === this.usage.length - 1) {
      this.closeWithDelete();
    } else {
      this.usageIdx++;
      this.setDeletingUsage();
    }
  }

  delete() {
    this.deleteItems.push({
      id: this.id,
      name: this.title,
      type: this.type,
    });

    if (this.input.data.usage && this.input.data.usage.length) {
      this.setDeletingUsage();
    } else {
      this.closeWithDelete();
    }
  }

  closeWithDelete() {
    this.dialogRef.close(this.deleteItems);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
