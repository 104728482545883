<section>
  <div class="left">
    <ng-content select="[app-heading]"></ng-content>
  </div>
  <div class="right">
    <div class="field-container">
      <ng-content select="[app-content]"></ng-content>
    </div>
    <div class="description">
      <ng-content select="[app-heading-description]"></ng-content>
    </div>
  </div>
</section>
<mat-divider *ngIf="bottomDivider"></mat-divider>
