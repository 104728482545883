import {
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dialogButtons } from 'src/app/models_new/config/project-data-buttons';
import { IGuiButtonInput } from 'src/app/models_new/types/button-gui-input';
import { IDialogData } from 'src/app/models_new/types/dialog-data';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
})
export class DialogWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true })
  entry: ViewContainerRef;
  componentRef: ComponentRef<any>;
  contentAsString: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  buttonConfig: IGuiButtonInput[] = dialogButtons;

  constructor(
    public dialogRef: MatDialogRef<DialogWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) public input: IDialogData
  ) {}

  ngOnInit() {
    if (this.input.button?.text) {
      this.buttonConfig[1].text = this.input.button.text;
    }
    if (this.input.button?.type) {
      this.buttonConfig[1].type = this.input.button.type;
    }

    if (this.input.content.component) {
      this.componentRef = this.entry.createComponent(
        this.input.content.component
      );
      this.componentRef.instance.input = this.input.content;

      if (this.componentRef.instance.output) {
        this.componentRef.instance.output
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            this.input.button.clickFn = false;
            this.onOkClick(res);
          });
      }
    }
    if (this.input.content.contentAsString) {
      this.contentAsString = this.input.content.contentAsString;
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.entry.clear();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isLoading(): boolean {
    if (
      this.componentRef &&
      this.componentRef.instance &&
      this.componentRef.instance.loading !== null &&
      this.componentRef.instance.loading !== undefined &&
      this.componentRef.instance.loading
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkValidity(): boolean {
    if (
      this.componentRef &&
      this.componentRef.instance &&
      this.componentRef.instance.disableButton !== null &&
      this.componentRef.instance.disableButton !== undefined &&
      this.componentRef.instance.disableButton
    ) {
      return true;
    } else {
      return false;
    }
  }

  onOkClick(res?: any) {
    if (!this.input.button.clickFn) {
      if (!res) {
        res = true;
      }
      if (
        (this.componentRef && !this.componentRef.instance.disableButton) ||
        !this.componentRef
      ) {
        this.dialogRef.close(res);
      }
    } else {
      this.componentRef.instance.doAction();
    }
  }
}
