import { IExportCombination } from '../types/export-combination';
import { settings } from './application-settings';

export const exportCombinations: IExportCombination[] = [
  {
    primary: settings.primaryPallet,
    secondary: settings.secondaryPallet,
  },
  // Ex.: {
  //   primary: 2,
  //   secondary: 0
  // }
];
