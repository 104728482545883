import { IApiUser } from 'src/app/models_new/classes/api-models/ApiUser';

export const defaultApiUser: IApiUser = {
  id: '0001',
  email: 'ppb@rocketfarm.no',
  user_metadata: {
    company: 'Rocketfarm',
    firstName: 'Pally',
    job_title: 'Developer',
    lastName: 'Pallet Builder',
    picture: undefined,
  },
};
