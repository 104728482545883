import { ValueValidator } from '../../classes/validators/value-validators';
import { AltLayout } from '../../enums/alt-layout';
import { BoxRotation } from '../../enums/box-rotation';
import { GripperOrientation } from '../../enums/gripper-orientation';
import { LabelOrientation } from '../../enums/label-orientation';
import { LayerApproach } from '../../enums/layer-approach';
import { LayerType } from '../../enums/layer-type';

export const nameRegexp: string = '^[0-9a-zA-Z-_.() ]{1,}$';

const validDecimals: number[] = [0.5, 0];

const palletDimensions = {
  width: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 5000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  length: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 5000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  height: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 16000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  palletHeight: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 5000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
};

const productDimensions = {
  width: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 1500),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  length: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 1500),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  height: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 5000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  weight: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.validDecimals(value, validDecimals),
  ],
};

const guiSettings = {
  boxPadding: (value: number) => [
    ValueValidator.minNumber(value, 0),
    ValueValidator.maxNumber(value, 100),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  units: (value: string) => [ValueValidator.maxLength(value, 20)],
  overhangSides: (value: number) => [
    ValueValidator.minNumber(value, 0),
    ValueValidator.maxNumber(value, 1500),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  overhangEnds: (value: number) => [
    ValueValidator.minNumber(value, 0),
    ValueValidator.maxNumber(value, 1500),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  altLayout: (value: string) => [
    ValueValidator.regex(value, nameRegexp, 'a-z A-Z 0-9 . _ - ()'),
    ValueValidator.maxLength(value, 200),
    ValueValidator.isValue(
      [value],
      [
        AltLayout.COPY,
        AltLayout.CUSTOM,
        AltLayout.MIRROR,
        AltLayout.NULL,
        AltLayout.ROTATE,
      ]
    ),
  ],
};

const box = {
  x: (value: number) => [
    ValueValidator.minNumber(value, 0),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  y: (value: number) => [
    ValueValidator.minNumber(value, 0),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  r: (value: any[]) => [
    ValueValidator.isValue(value, [
      BoxRotation.ZERO,
      BoxRotation.ONE,
      BoxRotation.TWO,
      BoxRotation.THREE,
    ]),
  ],
  g: (value: any[]) => [
    ValueValidator.isValue(value, [
      GripperOrientation.LEFT,
      GripperOrientation.FRONT,
      GripperOrientation.RIGHT,
      GripperOrientation.BACK,
      GripperOrientation.BACK_ALT,
      GripperOrientation.LEFT_ALT,
      GripperOrientation.RIGHT_ALT,
    ]),
  ],
  f: (value: number) => [ValueValidator.isValue([value], [0, 1])],
};

const layerTypes = {
  name: (value: string) => [
    ValueValidator.minLength(value, 1),
    ValueValidator.maxLength(value, 200),
  ],
  class: (value: string) => [
    ValueValidator.isValue([value], [LayerType.LAYER, LayerType.SHIMPAPER]),
    ValueValidator.regex(value, '^[0-9a-zA-Z-_. ]{1,}$', 'a-z A-Z 0-9 . _ -'),
    ValueValidator.maxLength(value, 200),
  ],
  pattern: box,
  altPattern: box,
  height: (value: number) => [
    ValueValidator.minNumber(value, 1),
    ValueValidator.maxNumber(value, 5000),
    ValueValidator.validDecimals(value, validDecimals),
  ],
  approach: (value: string) => [
    ValueValidator.maxLength(value, 20),
    ValueValidator.isValue(
      [value],
      [null, LayerApproach.NULL, LayerApproach.INVERSE]
    ),
  ],
  altApproach: (value: string) => [
    ValueValidator.maxLength(value, 20),
    ValueValidator.isValue(
      [value],
      [null, LayerApproach.NULL, LayerApproach.INVERSE, 'normal']
    ),
  ],
};

export const pallyFileValuesValidators = {
  name: (value: string) => [
    ValueValidator.regex(
      value,
      '(?=.*[^ ].*)(?=^[0-9a-zA-Z-_. ]{1,}$)',
      'a-z A-Z 0-9 . _ -'
    ),
    ValueValidator.maxLength(value, 200),
  ],
  description: (value: string) => [ValueValidator.maxLength(value, 200)],
  maxGrip: (value: number) => [
    ValueValidator.maxNumber(value, 100),
    ValueValidator.maxDecimals(value, 0),
  ],
  labelOrientation: (value: number) => [
    ValueValidator.isValue(
      [value],
      [
        LabelOrientation.NULL,
        LabelOrientation.FRONT,
        LabelOrientation.RIGHT,
        LabelOrientation.BACK,
        LabelOrientation.LEFT,
      ]
    ),
    ValueValidator.minNumber(value, LabelOrientation.LEFT),
    ValueValidator.maxNumber(value, LabelOrientation.BACK),
    ValueValidator.maxDecimals(value, 0),
  ],
  dimensions: palletDimensions,
  productDimensions: productDimensions,
  guiSettings: guiSettings,
  layerTypes: layerTypes,
};
