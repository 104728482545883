import { ITableColumnOverrideTitle } from './../../../gui/table/table.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { forkJoin, Observable, Subject, tap } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ITableAction,
  ITableData,
} from 'src/app/components/gui/table/table.component';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DataRequestState } from '../../../../data-request/model';
import { toRequestState } from '../../../../data-request/operators';
import { PickerType } from '../../../../models_new/enums/picker-type';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { ObjectUtils } from 'src/app/utils/object';
import { SortDirection } from '@angular/material/sort';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { getConvertedLabelValue } from 'src/app/utils/unit-utils';

type SoftwareActions = 'favourite' | 'modify' | 'delete' | 'create';

@Component({
  selector: 'app-software-list',
  templateUrl: './software-list.component.html',
  styleUrls: ['./software-list.component.scss'],
})
export class SoftwareListComponent implements OnInit, OnDestroy {
  overrideColumns: ITableColumnOverrideTitle[] = [
    {
      columnId: 'robot_os',
      columnTitle: 'Robot OS',
    },
  ];

  listColumns = [
    'select',
    'name',
    'robot_os',
    'pally_software_version',
    'gripper_optimization',
    'max_speed',
    'max_acceleration',
    'approach_distance',
  ];
  cardColumns = this.listColumns.slice(1);

  data$: Observable<DataRequestState<ITableData<SoftwareActions>[]>>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  strategies$: Observable<ApiStrategy[]>;
  pickerType: PickerType = PickerType.SOFTWARE_CONFIGURATION;
  sortingTypes: string[] = ['name', 'updated_at', 'created_at'];
  blockSelected: ApiStrategy[] = [];
  objUtil = ObjectUtils;

  setupFilter: ISortingOption[] = [
    {
      id: 'favourite',
      label: 'Favourite',
    },
    {
      id: 'robot_os',
      label: 'Robot OS',
      children: [],
    },
    {
      id: 'pally_software_version',
      label: 'Pally Software Version',
      children: [],
    },
    {
      id: 'gripper_optimization',
      label: 'Gripper Optimization',
      children: [],
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'robot_os',
      label: 'Robot OS',
    },
    {
      id: 'pally_software_version',
      label: 'Pally Software Version',
    },
    {
      id: 'gripper_optimization',
      label: 'Gripper Optimization',
    },
    {
      id: 'max_speed',
      label: 'Max Speed',
    },
    {
      id: 'max_acceleration',
      label: 'Max Acceleration',
    },
    {
      id: 'approach_distance',
      label: 'Approach Distance',
    },
  ];

  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private softwareApi: SoftwareApiService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.strategies$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        this.softwareApi.fetchStrategies(
          (data as IOrganizationContextResolverData).organization_id
        )
      )
    );
    this.data$ = this.strategies$.pipe(
      takeUntil(this.destroy$),
      map((softwares) =>
        softwares.map((row) => {
          row.data.max_speed = getConvertedLabelValue(
            row.data.max_speed + ' mm/s'
          );
          row.data.max_acceleration = getConvertedLabelValue(
            row.data.max_acceleration + ' mm/s²'
          );
          row.data.approach_distance = getConvertedLabelValue(
            row.data.approach_distance + ' mm'
          );
          return this.toTableRowData(row);
        })
      ),
      tap((configs) => {
        // Populate result filter
        for (let sc of configs) {
          for (let option of this.setupFilter) {
            if (option.id === 'favourite') continue;
            const id = ObjectUtils.getNested(sc.data, option.id);
            const child: ISortingOption = {
              id: id,
              label: id,
            };
            // Add option child if it doesn't exist in option children
            if (option.children.filter((o) => o.id === id).length === 0)
              option.children.push(child);
          }
        }
      }),
      toRequestState()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onDelete(): void {
    if (this.blockSelected.length === 0) {
      this.notifier.showError('Nothing is selected!');
      return;
    }

    this.deleteStrategiesAction(this.blockSelected)
      .pipe(take(1))
      .subscribe({
        next: () =>
          this.notifier.showMessage(`The softwares were deleted successfully!`),
        error: (e) => this.notifier.showError(e.message),
      });
  }

  public actionClicked(e: ITableAction<SoftwareActions>): void {
    // Favourite action
    if (e.actionId === 'favourite') {
      this.strategies$
        .pipe(
          map((strategies) => strategies.find((sw) => sw.id === e.element.id)),
          switchMap((strategy) => {
            e.element.favourite = !e.element.favourite;
            strategy.data.favourite = !strategy.data.favourite;
            return this.softwareApi.updateStrategy(
              e.element.id,
              strategy.name,
              null,
              strategy.data
            );
          }),
          take(1)
        )
        .subscribe();
    }
    // Modify action
    else if (e.actionId === 'modify') {
      this.navigateToSoftware(e.element.id);
    }
    // Delete action
    else if (e.actionId === 'delete') {
      this.deleteStrategiesAction([e.element])
        .pipe(take(1))
        .subscribe({
          next: (_) =>
            this.notifier.showMessage(
              `The softwares were deleted successfully!`
            ),
          error: (e) => this.notifier.showError(e.message),
        });
    } else if (e.actionId === 'create') {
      this.navigateToSoftware('new');
    }
  }

  public rowClicked(e: ITableData['data']): void {
    this.navigateToSoftware(e.id);
  }

  private navigateToSoftware(id: string): void {
    this.router.navigate([id], {
      relativeTo: this.route,
    });
  }

  private deleteStrategiesAction(strategies: ApiStrategy[]): Observable<any> {
    const dialogRef = this.notifier.deletePrompt(
      'Delete',
      'software configuration',
      strategies.map((m) => m.name)
    );
    return dialogRef.afterDismissed().pipe(
      take(1),
      filter(Boolean),
      switchMap(() => {
        return this.deleteStrategies(strategies.map((m) => m.id));
      })
    );
  }

  private deleteStrategies(ids: string[]): Observable<any> {
    const operations = {};
    for (let i = 0; i < ids.length; i++) {
      operations[i] = this.softwareApi.deleteStrategyById(ids[i]);
    }
    return forkJoin(operations);
  }

  private toTableRowData(swRow: ApiStrategy): ITableData<SoftwareActions> {
    return {
      data: {
        id: swRow.id,
        name: swRow.name,
        description: swRow.description,
        data: swRow.data,
        updated_at: swRow.updated_at,
        robot_os: swRow.data.software.polyscope_version,
        pally_software_version: swRow.data.software.pally_version,
        gripper_optimization: swRow.data.gripper_optimization,
        max_speed: swRow.data.max_speed,
        max_acceleration: swRow.data.max_acceleration,
        approach_distance: swRow.data.approach_distance,
        favourite: swRow.data['favourite'] || swRow['favourite'],
      },
      actions: [
        {
          label: 'favourite',
          actionId: 'favourite',
        },
        {
          label: 'Modify',
          actionId: 'modify',
        },
        {
          label: 'Delete',
          actionId: 'delete',
          txtColor: 'red',
        },
      ],
    };
  }
}
