import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

export interface IPromptDeletionData {
  title?: string;
  items: string[];
  type: string;
  extraWarning: {
    title: string;
    text: string;
  };
}

@Component({
  selector: 'app-prompt-deletion',
  templateUrl: 'delete-prompt.component.html',
  styleUrls: ['./delete-prompt.component.scss'],
})
export class PromptDeletionComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PromptDeletionComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IPromptDeletionData
  ) {}

  deleteItems(): void {
    this._bottomSheetRef.dismiss(true);
  }

  cancelDeletion() {
    this._bottomSheetRef.dismiss();
  }
}
