import { ConveyorDirection } from '../enums/sim-config-conveyor-dir';
import { IStrategyApiFileType } from '../types/simulation-api-file-type';

export interface ISmartExitConfig {
  id: string;
  direction: ConveyorDirection;
  image: string;
  fields: IStrategyApiFileType['smart_exits'];
}

export const softwareSmartExitDefaultValues: ISmartExitConfig[] = [
  {
    id: '0',
    image: '../../../assets/illustrative/front_conveyor_dir.png',
    direction: ConveyorDirection.FRONT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 700,
        },
        y: {
          from: 50,
          to: 250,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 700,
        },
        y: {
          from: 50,
          to: 250,
        },
      },
    },
  },
  {
    id: '1',
    image: '../../../assets/illustrative/left_conveyor_dir.png',
    direction: ConveyorDirection.LEFT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: -400,
          to: 0,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: 100,
          to: 800,
        },
      },
    },
  },
  {
    id: '2',
    image: '../../../assets/illustrative/right_conveyor_dir.png',
    direction: ConveyorDirection.RIGHT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: 100,
          to: 800,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: -400,
          to: 0,
        },
      },
    },
  },
];
