import * as THREE from 'three';

export class ArrowGeometry extends THREE.ExtrudeGeometry {
  constructor(depth: number) {
    const headLength = 0.5;
    const headWidth = 0.8;

    const lineLength = 1 - headLength;
    const lineWidth = 0.3;
    const shape = new THREE.Shape();
    shape.moveTo(0, 0);
    shape.lineTo(headWidth / 2, headLength);
    shape.lineTo(lineWidth / 2, headLength);
    shape.lineTo(lineWidth / 2, headLength + lineLength);
    shape.lineTo(-lineWidth / 2, headLength + lineLength);
    shape.lineTo(-lineWidth / 2, headLength);
    shape.lineTo(-headWidth / 2, headLength);

    const extrudeSettings = {
      steps: 1,
      depth,
      bevelEnabled: false,
      bevelThickness: 0.1,
      bevelSize: 0.1,
      bevelOffset: 0,
      bevelSegments: 1,
    };
    super(shape, extrudeSettings);
  }
}

export class FrontArrow extends THREE.Mesh {
  constructor(depth = 0.05) {
    super(
      new ArrowGeometry(depth),
      new THREE.MeshPhongMaterial({
        color: 0x156289,
        emissive: 0x072534,
        flatShading: true,
      })
    );
  }
}
