import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Project } from '../models_new/classes/project';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { ProjectData } from '../models_new/classes/project-data';
import { PalletAdvancedSettings } from '../models_new/classes/pallet-advanced-settings';
import { Pallet } from '../models_new/classes/pallet';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IProjectUpdate } from '../models_new/types/project-update';
import { Layer } from '../models_new/classes/layer';
import { Box } from '../models_new/classes/box';
import { ObjectUtils } from '../utils/object';
import { UpdateAction } from '../models_new/enums/update-action';
import { SubnavViewService } from './subnav-view.service';
import { StackingMethod } from '../models_new/enums/stacking-method';
import { defaultProject } from '../models_new/config/default/default-project';
import { settings } from '../models_new/config/application-settings';

@Injectable({
  providedIn: 'root',
})

/**
 * Keeps track of project, pallet, layer and boxes updates.
 * Stores and gets from local store service
 */
export class DataService {
  public project$: BehaviorSubject<Project>;
  public projectUpdates$: BehaviorSubject<Project>;
  updatesSubscriber: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private subNavView: SubnavViewService
  ) {
    const projectInitData = this.getProjectLocalStorage();
    this.projectUpdates$ = new BehaviorSubject<Project>(projectInitData);
    this.project$ = new BehaviorSubject<Project>(projectInitData);

    // When project.update updates
    this.projectUpdates$.subscribe((project: Project) => {
      if (project) {
        this.setProjectLocalStorage(project);
      }
    });

    // When project itself updates
    this.project$.subscribe((project: Project) => {
      this.subscribeToUpdates(project);
    });
  }

  subscribeToUpdates(project: Project) {
    if (this.updatesSubscriber) {
      this.updatesSubscriber.unsubscribe();
    }
    this.updatesSubscriber = project.update$.subscribe(
      (update: IProjectUpdate) => {
        if (update && update.save) {
          const stackingMethod =
            this.subNavView.selectedStackingMethod$.getValue();
          const baseIndex =
            this.subNavView.selectedBasePatternIndex$.getValue();
          const activePallet = project.getActivePallet();

          // If selected stacking method is customized. Set pallet.preservedLayers key
          if (stackingMethod && stackingMethod === StackingMethod.CUSTOMIZED) {
            activePallet.preserveLayers = true;
            activePallet.autoStack.method = stackingMethod;
          }

          if (stackingMethod && stackingMethod !== StackingMethod.CUSTOMIZED) {
            activePallet.preserveLayers = false;
            activePallet.autoStack.method = stackingMethod;
          }

          if (baseIndex !== null) {
            activePallet.autoStack.baseIndex = baseIndex;
          }

          if (update.updateAction === UpdateAction.ACTIVE_PALLETS) {
            this.subNavView.selectedStackingMethod$.next(
              StackingMethod.CUSTOMIZED
            );
          }

          // Save data
          this.projectUpdates$.next(project);

          // Add data to revisions
          const clone: Project = ObjectUtils.cloneObject(
            this.project$.getValue()
          );
          this.deleteObservables(clone);
        }
      }
    );
  }

  /**
   * @param project: Project
   */
  setProject(project: Project) {
    this.project$.next(project);
  }

  /**
   * @param project: Project
   */
  setProjectLocalStorage(
    project: Project,
    location: LocalStorageKey = LocalStorageKey.PROJECT
  ) {
    const clone: Project = ObjectUtils.cloneObject(project);
    this.deleteObservables(clone);

    this.localStorageService.setData(location, clone);
  }

  deleteObservables(clone: Project) {
    // Remove to avoid circular structure error
    delete clone.update$;
    delete clone.palletAdvancedSettings.update$;
    delete clone.palletView.update$;
    if (clone.pallets) {
      clone.pallets.forEach((p: Pallet) => {
        delete p.update$;
        if (p.layers) {
          p.layers.forEach((l: Layer) => {
            delete l.update$;
            if (l.boxes) {
              l.boxes.forEach((b: Box) => {
                delete b.update$;
              });
            }
          });
        }
      });
    }
  }

  clearLocalStorage(LocalStorageKeys?: LocalStorageKey[]): void {
    this.subNavView.dirtyPallet$.next(false);
    this.subNavView.selectedStackingMethod$.next(
      settings.defaultStackingMethod
    );
    for (const key of LocalStorageKeys) {
      this.localStorageService.removeData(key);
    }
    this.setProject(
      new Project(
        'dataService-clear-localstorage',
        ObjectUtils.cloneObject(defaultProject)
      )
    );
  }

  getProjectLocalStorage(): Project {
    const projectLocalStorage: Project = new Project(
      'dataService-get-project-local-storage',
      this.localStorageService.getData(LocalStorageKey.PROJECT)
    );

    // Preserve layers if present!
    if (projectLocalStorage.pallets && projectLocalStorage.pallets.length) {
      projectLocalStorage.getPallets().forEach((p: Pallet) => {
        if (p.layers.length) {
          this.subNavView.dirtyPallet$.next(true);
          this.subNavView.selectedStackingMethod$.next(
            StackingMethod.CUSTOMIZED
          );
        }
      });
    }

    return projectLocalStorage;
  }

  getProject(): Project {
    return this.project$.getValue();
  }

  /**
   * @param projectData: ProjectData
   */
  setProjectData(projectData: ProjectData, project?: Project) {
    const useDataServiceProject: boolean = !project;
    if (useDataServiceProject) {
      project = this.project$.getValue();
    }
    project.data = projectData;

    // Update 3dview label orientations
    project.getPalletView().palletViewSettings.labelOrientation =
      projectData.box.label.orientation;

    project.updateAllBoxesData();
    project.updateAllPalletsData();

    if (useDataServiceProject) {
      this.project$.next(project);
    }
  }

  getProjectData(): ProjectData {
    return this.getProject() ? this.getProject().data : null;
  }

  /**
   * @param PAsettings: PalletAdvancedSettings
   */
  setPalletAdvancedSettings(PAsettings: PalletAdvancedSettings) {
    const project = this.getProject();
    project.palletAdvancedSettings = PAsettings;
    this.setProject(project);
  }

  getPalletAdvancedSettings(): PalletAdvancedSettings {
    return this.getProject().getPalletAdvancedSettings();
  }

  /**
   * @param setDirty: boolean
   */
  setImportDirty(setDirty: boolean) {
    const dirty = {
      dirty: setDirty,
    };
    this.localStorageService.setData(LocalStorageKey.IMPORT_NOT_DIRTY, dirty);
  }

  public getImportDirty(): boolean {
    return (
      this.localStorageService.getData(LocalStorageKey.IMPORT_NOT_DIRTY)
        ?.dirty ?? false
    );
  }

  /**
   * @param setValid: boolean
   */
  setValidProjectData(setValid: boolean) {
    this.localStorageService.setData(
      LocalStorageKey.VALID_PROJECT_DATA,
      setValid
    );
  }

  getValidProjectData(): boolean {
    return this.localStorageService.getData(LocalStorageKey.VALID_PROJECT_DATA);
  }

  /**
   * This value can be used to add/remove/hide info if you create a 'debug' or 'debugging' entry (boolean value) in the localStorage.
   * @returns Boolean
   */
  public getDebugStatus(): boolean {
    let value = this.localStorageService.getData(LocalStorageKey.DEBUG);
    if (!value) {
      value = this.localStorageService.getData(LocalStorageKey.DEBUGGING);
    }
    return value ?? false;
  }
}
