import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { environment as env } from 'src/environments/environment';
import { ErrorLogEntry } from '../models_new/types/error';

/**
 * @class DatadogLoggerService
 * @deprecated DataDog no longer available; If not re-implemented, this service should be removed. 020724
 * @description Manages DataDog logging client, for collecting data from the customers who decide to report their issues.
 * Learn more {@link https://docs.datadoghq.com/integrations/rum_angular/ | here}
 */
@Injectable({
  providedIn: 'root',
})
export class DatadogLoggerService {
  constructor() {
    datadogLogs.init({
      clientToken: env.datadogSiteKey,
      site: env.datadogSiteUrl,
      service: env.datadogService,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      silentMultipleInit: true,
    });
  }

  /**
   * @description Composes a summary of the logs given+context and sends it to DataDog.
   * @param logEntry
   * @param error
   */
  public sendLog(logEntry: ErrorLogEntry, error: Error): void {
    /**
     * @deprecated DataDog no longer available.
     * Code below kept for reference in the event we either enable it back, or implement any other service.
     */
    return;
    /**
     * To control the amount of logs sent to DataDog (as requested; as the service is payed by log sent), this function will
     * enable the fwd of errors just to let the one pass through, then block it again. Otherwise will send most errors from console.
     * DataDog doesn't provide much documentation about Angular integration, and there isn't barely any community thread regarding
     * set-up/troubleshooting.
     */
    datadogLogs.logger.addContext('forwardConsoleLogs', ['error']);
    datadogLogs.onReady(() => {
      if (
        !datadogLogs.getUser() ||
        datadogLogs.getUser().id !== logEntry?.userId
      ) {
        datadogLogs.setUser({ id: logEntry.userId });
      }
      datadogLogs.logger.error(
        `Error Reported | Context: ${JSON.stringify(logEntry?.context)}`,
        {},
        error
      );
      datadogLogs.logger.removeContext('forwardConsoleLogs');
    });
  }
}
