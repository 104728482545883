<div class="main">
  <h4 class="tagline">UPLOAD COMPONENT</h4>
  <h1 class="title" #mainTitle>Upload a file</h1>
  <p class="helpText">
    Can’t find a
    {{ this.modelUpload.generatorParams.type.name.toLowerCase() }} that suits
    your needs? You can upload a custom component to use in your simulations.
    After providing a STEP file, the model is sent to us for verification and
    added to your hardware library in a couple of days.
  </p>
  <mat-form-field appearance="outline" style="margin-top: 8px">
    <mat-label #inputLabel>Project Name</mat-label>
    <input
      #nameInput
      type="text"
      matInput
      required
      (input)="onNameChange($event)"
    />
  </mat-form-field>

  <div
    class="upload_container"
    [ngClass]="{ dashed_container: !hasUploaded() }"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragenter)="onDropEnter($event)"
    (dragleave)="onDragLeave($event)"
  >
    <div
      id="gradientOverlay"
      [style]="
        'opacity: ' +
        (showOverlay ? '0.7' : '0') +
        '; z-index: ' +
        (showOverlay ? '10' : '-1')
      "
    ></div>

    <div class="file_info_container">
      <mat-icon class="upload_icon">upload_file</mat-icon>
      <h1 style="font-size: 18px" class="title" #fileLabel>
        Drop your model here
      </h1>

      <div *ngIf="!hasUploaded()" class="file_info_container">
        <div>
          <p class="subtitle" style="margin-bottom: 0" #subTitle>
            Accepted file format is <b>.step</b>
          </p>
          <p *ngIf="fileTooLarge" class="subtitle" style="color: #f05145">
            {{ fileTooLarge }}
          </p>
        </div>
        <button
          class="button_outlined"
          mat-stroked-button
          (click)="fileUploader.click()"
        >
          BROWSE FILES
        </button>
      </div>

      <div *ngIf="hasUploaded()" class="file_info_container">
        <p class="progress_counter" #progressCounter>Done</p>
        <div class="progress_holder">
          <div class="progress_bar" #progressBar></div>
        </div>
      </div>

      <input type="file" (change)="onUpload($event)" #fileUploader />
    </div>
  </div>

  <div class="horizontal_container" *ngIf="hasUploaded()">
    <button
      class="button_outlined"
      (click)="uploadAnotherFile()"
      style="margin-right: 4px; width: 100%"
      mat-stroked-button
    >
      UPLOAD ANOTHER FILE
    </button>
    <button
      [disabled]="submitDisabled"
      [ngClass]="isLoaded ? 'button_filled' : 'button_deactivated'"
      (click)="nextClick()"
      style="margin-left: 4px"
      mat-flat-button
    >
      CONFIRM
    </button>
  </div>
</div>
