import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Subject, combineLatest } from 'rxjs';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Field } from 'src/app/models_new/classes/field';
import { excludedConversionFieldIds } from 'src/app/models_new/config/form_fields';
import { SimulationUpdateAction } from 'src/app/models_new/enums/simulation-view-update';
import { FieldType } from 'src/app/models_new/types/field-type';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { UnitSystemPipe } from 'src/app/pipes/unit-system.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import {
  ImperialUnit,
  MetricUnit,
  UnitSystemType,
  completeUnitRegex,
  getDecimalFromFraction,
  getFractionalDecimal,
  imperialUnits,
  isValidUnitType,
  validUnitRegex,
} from 'src/app/utils/unit-utils';
import { environment } from 'src/environments/environment';

export class EagerStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    /** Error when invalid control is dirty, touched, or submitted. */
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

interface FieldError {
  id: string;
  valid: boolean;
  message: string;
}
@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})

/**
 * @class
 * FieldComponent dynamically handles all possible types of input fields, including change events, formControl validity, and more.
 * @desc This component represents a field and provides comprehensive functionality for managing various types of input fields.
 * @implements {OnInit}
 * @implements {OnChanges}
 */
export class FieldComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  /** @type {Subject<boolean>} @desc A subject used for managing the component's destruction. */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** @type {FieldType}  @desc The type of the field.*/
  fieldType = FieldType;
  /** @type {EagerStateMatcher} @desc An eager state matcher for the component. */
  eagerMatcher = new EagerStateMatcher();
  /** @type {boolean} @desc Indicates whether the field should be highlighted. */
  highlightField = false;
  /** @type {any} @desc The initial value of the field. */
  initValue: any;
  /** @type {boolean} @desc Indicates whether the password input field is focused. */
  pwInputFocused = false;
  /** @type {any} @desc The filtered options for the field. */
  filteredOptions: any;
  /** @type {Field} @desc The number field for the component. Used to handle the unit system conversion. */
  numberField: Field;
  /** @type {Field} @desc To be used complementairly with numberField when using imperial unit system. Will display a dropdown with fraction options. */
  numberFieldFractional: Field;
  /** @type {string} @desc reCaptcha's component site key, stored as env-var */
  siteKey: string = environment.reCaptchaSiteKey;
  /** @type {boolean} @desc Will hide the value of the field. */
  hide = true;
  /** @type {Object} @desc List of current errors reporded by each field's FormControl. */
  errors: FieldError[] = [];
  /** @type {boolean} @desc Will prevent this field from being pipèd through the UnitSistemConversion. */
  isConvertible: boolean;
  /** @type {number} Stores the last converted value in order to prevent of looping through a self-emitted event. */
  private lastConverted: number;
  /** @input @type {Field} @desc The field object provided as an input. */
  @Input() field: Field;
  /** @input @type {boolean} @desc Indicates whether the field is disabled. */
  @Input() disabled: boolean = false;
  /** @input @type {boolean} @desc Indicates whether the field is deletable. */
  @Input() deletable?: boolean;
  /** @input @type {string} @desc The color of the label for the field. */
  @Input() labelColor?: string;
  /** @input @type {string} @desc The appearance type for the field. Outline as default. */
  @Input() appearance: MatFormFieldAppearance = 'outline';
  /** @input @type {string} @desc The title of the sub-menu. */
  @Input() subMenuTitle?: string;
  /** @input @type {Function} @desc The handler function for the sub-menu. */
  @Input() subMenuHandler?: Function;
  /** @input @type {boolean} @desc Indicates whether the modified field should be highlighted. Will enables the "Restore" action-hint */
  @Input() highlightModified?: boolean = false;
  /** @input @type {boolean} @desc Indicates whether the unit system should be preserved. */
  @Input() preserveUnitSystem: boolean = false;
  /** @output @type {EventEmitter<FieldUpdate>} @desc Event emitted when the field is updated. */
  @Output() fieldChange?: EventEmitter<FieldUpdate> = new EventEmitter();
  /** @output @desc Event emitted when the delete button is clicked. @type {EventEmitter<string>} */
  @Output() deleteClick?: EventEmitter<string> = new EventEmitter();
  /** @output @desc Event emitted when the info popup is opened. @type {EventEmitter<void>} */
  @Output() openInfoPopup?: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (
      this.field.type === FieldType.SELECT_SINGLE_OBJECT &&
      this.field.formControl.value === null
    ) {
      this.field.formControl.setValue({
        value: null,
        name: null,
      });
    }

    if (this.field.type == FieldType.AUTOCOMPLETE) {
      this.applyTextFilter();
    }
    if (this.field.formControl.errors) {
      for (let error in this.field.formControl.errors) {
        // Has to contain a message. Has to be unvalid
        if (
          this.field.formControl.errors[error].message &&
          !this.field.formControl.errors[error].valid
        ) {
          this.errors.push({
            ...this.field.formControl.errors[error],
            id: error,
          });
        }
      }
      if (
        this.field.formControl.errors ||
        this.numberField?.formControl?.errors
      ) {
        this.handleFieldErrors(
          this.field.type == FieldType.NUMBER ? this.numberField : this.field
        );
      }

      if (this.field.infoPopupClick.observed) {
        this.openInfoPopup.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.field.infoPopupClick.emit();
        });
      }
      this.isConvertible = !excludedConversionFieldIds?.find(
        (id) => this.field.id == id
      );
      this.initValue = this.field.formControl.value;
    }
  }

  ngAfterViewInit(): void {
    if (this.field.type == FieldType.SELECT_MULTIPLE_OBJECT) {
      this.field.formControl.setValue(this.field.defaultValue);
    }
    if (this.field.type == FieldType.NUMBER) {
      /**
       * To handle the unit conversion system without interfering with none-metric data,
       * this Field component will bypass the data conversion when needed through the
       * "numberField" class-property, but keeping all event-emitters through the original
       * input/output, rendering the conversion invisible to the rest of the app.
       * ┌──────────────────────────────────────────────────────────────────────────────┐
       * │                                              [Original Flow]                 │▒
       * │     ┌──────────────┐                                          ┌────────────┐ │▒
       * │═══> │@input() field│ ════════════════════╤═> valueChanges()═> │EventEmitter│ │▒
       * │     └─────┬────────┘                     │                    │fieldChanges│ │▒
       * │   (field.type == Number?)            setValue()               └────────────┘ │▒
       * │           │                              │                                   │▒
       * ╞═══════════╪══════════════════════════════╪═══════════════════════════════════╡▒
       * │           │                              │   [Unit Conversion Bypass]        │▒
       * │           │                              │                                   │▒
       * │   <Is Convertible?>                      │                                   │▒
       * │           ├ N >─── OnValueChanges() >────┤                                   │▒
       * │           Y                              │                                   │▒
       * │           │                              │                                   │▒
       * │           │                              │                                   │▒
       * │ (Convert to preferred Unit)      (Convert to Metric)                         │▒
       * │           │                              │                                   │▒
       * │           ├────── OnValueChanges() >─────┤                                   │▒
       * │           │                              │                                   │▒
       * │    <Has Decimals?>                       │                                   │▒
       * │           ├ N >─ Do nothing.             │                                   │▒
       * │           Y                              │             __  _______  ______   │▒
       * │           |                              │            /  |/  / __ \/ ____/   │▒
       * │(Display Fraction dropdown)               │           / /|_/ / /_/ / /        │▒
       * │           |                              │          / /  / / _, _/ /__       │▒
       * │           └────── OnValueChanges() >─────┘         /_/  /_/_/ |_|\____/ ®    │▒
       * │                                                                              │▒
       * └──────────────────────────────────────────────────────────────────────────────┘▒
       *  ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
       */

      combineLatest([
        this.numberField.formControl.valueChanges.pipe(startWith('')),
        this.numberFieldFractional?.formControl?.valueChanges.pipe(
          startWith(null)
        ),
      ])
        .pipe(takeUntil(this.destroy$), pairwise())
        .subscribe(([_, [integerValue, fractionalValue]]) => {
          if (completeUnitRegex.test(integerValue)) {
            this.field.formControl.markAsDirty();
            this.field.formControl.setValue(
              (this.lastConverted = this.handleUnitConversion(
                fractionalValue
                  ? Number(integerValue) +
                      Number(getDecimalFromFraction(fractionalValue))
                  : integerValue,
                this.numberField?.text?.suffix,
                'metric'
              ).value)
            );
          }
          if (this.initValue !== integerValue) {
            this.highlightField = this.numberField.formControl.errors?.length
              ? false
              : this.highlightModified;
          } else {
            this.highlightField = false;
          }
        });

      this.field.formControl.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          startWith(''),
          pairwise(),
          filter(([_, newVal]) => newVal !== this.lastConverted)
        )
        .subscribe(([oldValue, newValue]) => {
          if (newValue !== undefined) {
            if (this.field.type == FieldType.NUMBER && oldValue) {
              let convertedValue = this.handleUnitConversion(
                this.field.getValue(),
                this.field?.text?.suffix
              )?.value;
              let integerValue = Math.floor(convertedValue);
              let fractionalValue = getFractionalDecimal(
                this.roundToEights(convertedValue - integerValue),
                true,
                true
              );
              this.numberField.formControl.setValue(integerValue);
              if (this.numberFieldFractional) {
                this.numberFieldFractional.formControl.setValue(
                  fractionalValue
                );
              }
              return;
            }
            if (this.errors.length) {
              this.errors.forEach((e) => {
                const currentErrors = this.field.formControl.errors;
                // No errors => all is valid
                if (!currentErrors) {
                  e.valid = true;
                } else {
                  // Errors => set missing to valid
                  if (!currentErrors[e.id]) {
                    e.valid = true;
                  } else {
                    e.valid = false;
                  }
                }
              });
            }
            if (
              this.initValue !== newValue &&
              this.field.type !== FieldType.NUMBER
            ) {
              this.highlightField = this.field.formControl.errors?.length
                ? false
                : this.highlightModified;
            } else {
              this.highlightField = false;
            }
            this.fieldChange.emit({
              action: SimulationUpdateAction.FIELD_UPDATE,
              fieldId: this.field.id,
              fieldName: this.field.text.label,
              prevVal: !isNaN(oldValue) ? +oldValue : oldValue,
              newVal: !isNaN(newValue) ? +newValue : newValue,
            });
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.field) {
      if (this.field.type == FieldType.NUMBER) {
        if (changes.field?.isFirstChange()) {
          this.numberField = this.convertField(
            ObjectUtils.cloneObject(this.field)
          );
        }
        if (this.numberField) {
          const convertedValue = this.handleUnitConversion(
            this.field.getValue(),
            this.field?.text?.suffix
          )?.value;
          let integerValue = Math.floor(convertedValue);
          this.numberField.formControl.setValue(integerValue);
          if (this.numberFieldFractional) {
            let fractionalValue = getFractionalDecimal(
              this.roundToEights(convertedValue - integerValue),
              true,
              true
            );
            this.numberFieldFractional.formControl.setValue(fractionalValue);
          }
        }
      }
      if (changes.disabled?.currentValue) {
        this.field.type !== FieldType.NUMBER
          ? this.field.disable()
          : this.numberField?.disable();
      } else {
        this.field.type !== FieldType.NUMBER
          ? this.field.enable()
          : this.numberField?.enable();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * @desc Analyzes a given Field object for errors, and pushes them into the this.error property.
   * @param {Field} inputField Field to analyze for contained errors.
   */
  private handleFieldErrors(inputField: Field): void {
    for (let error in inputField.formControl.errors) {
      // Has to contain a message. Has to be invalid
      if (
        inputField.formControl.errors[error].message &&
        !inputField.formControl.errors[error].valid
      ) {
        this.errors.push({
          ...inputField.formControl.errors[error],
          id: error,
        });
      }
    }
  }

  /**
   * @desc If necessary, will handle a conversion of values.
   * The intention is to always receive and emit the units as metric.
   * @param inputField
   */
  private convertField(inputField: Field): Field {
    function unitConversion(
      value: number,
      unit: ImperialUnit | MetricUnit,
      preserveUnitSys?: boolean
    ): { value: number; unit: string } {
      if (preserveUnitSys) {
        return { value: value, unit: unit };
      } else {
        return new UnitSystemPipe(new LocalStorageService()).transform(
          value,
          unit
        ) as { value: number; unit: string };
      }
    }
    if (
      !validUnitRegex.test(
        `${inputField.getValue()} ${inputField?.text?.suffix}`
      )
    ) {
      return inputField;
    }
    const inputUnit = isValidUnitType(inputField?.text?.suffix)
      ? inputField.text.suffix
      : null;
    const convertedValueAndUnit = unitConversion(
      Number(inputField?.getValue()),
      inputUnit,
      this.preserveUnitSystem
    );

    const fieldText: Field['text'] = {
      hint: inputField.text?.hint,
      label: inputField.text?.label,
      name: inputField.text?.name,
      suffix: convertedValueAndUnit?.unit,
      showProperty: inputField.text?.showProperty,
    };

    const min =
      unitConversion(inputField?.min, inputUnit, this.preserveUnitSystem)
        ?.value || null;
    const max =
      unitConversion(inputField?.max, inputUnit, this.preserveUnitSystem)
        ?.value || null;
    let validators = [...inputField.validators];
    if (min) validators.push(Validators.min(min));
    if (max) validators.push(Validators.max(max));
    const isImperial = imperialUnits.includes(convertedValueAndUnit.unit);

    const convertedValue = this.handleUnitConversion(
      this.field.getValue(),
      this.field?.text?.suffix
    )?.value;

    const fieldVaule = unitConversion(
      Number(inputField?.getValue()),
      inputUnit,
      this.preserveUnitSystem
    )?.value;

    const outputField = new Field(
      inputField.type as FieldType,
      inputField.required,
      isImperial ? fieldVaule : Math.floor(fieldVaule),
      validators,
      inputField.tabGroupId,
      inputField.options,
      inputField.guiOrderIdx,
      fieldText,
      inputField.id,
      inputField.prefixIcon,
      inputField.errorMsgs
    );
    // TODO: Check out the behavior to detect changes when using US conversion. Temporarily disabled.
    this.highlightModified = false;
    if (!isImperial) {
      this.numberFieldFractional = null;
    } else {
      outputField.step = 1;
      this.numberFieldFractional = new Field(
        this.fieldType.MINI_SELECT_SINGLE,
        false,
        getFractionalDecimal(
          this.roundToEights(convertedValue - Math.floor(convertedValue)),
          true,
          true
        ),
        [],
        'fractional',
        ['0', '⅛', '¼', '⅜', '½', '⅝', '¾', '⅞'],
        null,
        { label: 'Fractional', name: 'fractional' },
        'fractional'
      );
    }
    return outputField;
  }

  /**
   * @description Channels the class's unit conversion through the conversion pipe.
   * @param {any} inputValue
   * @param {ImperialUnit | MetricUnit} inputUnit
   * @param {UnitSystemType} outputSystem
   * @returns { value, unit}
   */
  private handleUnitConversion(
    inputValue,
    inputUnit,
    outputSystem?: UnitSystemType
  ): { value: number; unit: string } {
    if (!isValidUnitType(inputUnit)) {
      return { value: inputValue, unit: inputUnit };
    }
    return new UnitSystemPipe(new LocalStorageService()).transform(
      inputValue,
      inputUnit as ImperialUnit | MetricUnit,
      outputSystem
    ) as {
      value: number;
      unit: string;
    };
  }

  private roundToEights(value: number): number {
    const inv = 1 / 0.125;
    return Math.round((Math.round(value * inv) / inv) * 100) / 100;
  }

  /**
   * Prevents the input of comma and dot characters in the number field.
   * @param {KeyboardEvent} event - The keyboard event that triggered the function.
   */
  public ignoreCharacters(event: KeyboardEvent): void {
    if (this.numberFieldFractional != null) {
      // List of special keys that are not allowed to be used.
      const pattern = /[,.]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  /**
   * @desc Restores the default value of the field.
   */
  public restoreDefaultValue(): void {
    this.field.formControl.setValue(this.initValue);
  }

  public compareFn(
    c1: { name: any; value: any },
    c2: { name: any; value: any }
  ): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  public doOpenInfoPopup(e: Event) {
    e.stopPropagation();
    this.openInfoPopup.emit();
  }

  public selectMultipleValueDisplay(value: object[]): string {
    return value.map((v) => v[this.field.text.showProperty]).join(', ');
  }

  public applyTextFilter(event?: Event): void {
    const filterValue = (event?.target as HTMLInputElement)?.value;
    if (!filterValue) {
      this.filteredOptions = ObjectUtils.cloneObject(this.field.options);
    } else {
      this.filteredOptions = [];
      this.filteredOptions = (
        this.field.options as [{ label: string; value: string }]
      ).filter((o) =>
        o.label.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
  }

  public autocompleteDisplayFn(value: any): string {
    return value?.label ?? '';
  }

  public doPersistNumberFieldValue(): void {
    const fractionalValue = this.numberFieldFractional?.getValue() as string;
    const integerValue = this.numberField.getValue();
    this.field.formControl.setValue(
      this.handleUnitConversion(
        fractionalValue
          ? Number(integerValue) +
              Number(getDecimalFromFraction(fractionalValue))
          : integerValue,
        this.numberField?.text?.suffix,
        'metric'
      ).value
    );
  }
}
