import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { IAssetStoreLoadingConfig } from '../../types/asset/asset-store-loading-config';
import * as THREE from 'three';

export enum AssetType {
  URDF = 'urdf',
  DAEModel = 'daemodel',
  STLModel = 'stlmodel',
  Font = 'font',
  Texture = 'texture',
}

export class Asset<Ttype> {
  private id: string;
  private type: AssetType;
  private urls: Map<string, string> = new Map<string, string>();
  private mode: string;
  private models: Map<string, Ttype> = new Map<string, Ttype>();
  public loading$ = new BehaviorSubject<boolean>(false);
  public loaded$ = new BehaviorSubject<boolean>(false);
  private model$ = new ReplaySubject<Ttype>(1);
  private unload$ = new ReplaySubject<boolean>(1);

  public config: IAssetStoreLoadingConfig;

  public metadata: any;

  constructor(config: IAssetStoreLoadingConfig) {
    this.setConfig(config);

    this.loaded$.pipe(RXJSUtils.filterFalse()).subscribe((_: any) => {
      this.loading$.next(false);

      // If a model is available, for the current mode, assign it.
      if (this.mode && this.models.size > 0) {
        this.model$.next(this.models.get(this.mode));
      }
    });
  }

  public getID(): string {
    return this.id;
  }

  public getType(): AssetType {
    return this.type;
  }

  public getURL(mode?: string): string {
    return this.urls.get(mode ? mode : this.mode);
  }

  public getURLs(): Map<string, string> {
    return this.urls;
  }

  public isOnDemand(): boolean {
    return this.config.onDemand;
  }

  public getMode(): string {
    return this.mode;
  }

  public getModeFromURL(location: string): string {
    for (const [mode, url] of this.urls) {
      if (url === location) {
        return mode;
      }
    }

    // No mode found, default to ID
    return this.id;
  }

  public getMetadata(): string {
    return this.metadata;
  }

  public setMetadata(data: any): void {
    this.metadata = data;
  }

  public set(data: Ttype, mode?: string): void {
    this.models.set(mode ? mode : this.mode, data);
  }

  public setURL(url: string, mode?: string): void {
    if (this.mode) {
      this.mode = this.resolveDefaultMode();
    }

    this.urls.set(mode ? mode : this.mode, url);
  }

  public setType(type: AssetType): void {
    this.type = type;
  }

  public setMode(mode: string): void {
    this.mode = mode;
    if (this.models.has(mode)) {
      this.model$.next(this.models.get(mode));
    }
  }

  public resetMode(): void {
    this.setMode(this.resolveDefaultMode());
  }

  public setConfig(config: IAssetStoreLoadingConfig): void {
    this.config = config;
    this.id = config.id;
    this.type = config.type;
    this.mode = this.resolveDefaultMode();
    if (config.urls) {
      for (const [mode, url] of config.urls) {
        this.urls.set(mode, url);
      }
    }
  }

  public isLoaded(): boolean {
    return this.loaded$.getValue();
  }

  public setLoaded(state: boolean): void {
    this.loaded$.next(state);
  }

  public isLoaded$(): Observable<boolean> {
    return this.loaded$;
  }

  public isLoading(): boolean {
    return this.loading$.getValue();
  }

  public setLoading(state: boolean): void {
    this.loading$.next(state);
  }

  public isLoading$(): Observable<boolean> {
    return this.loading$;
  }

  public onModel$(): Observable<Ttype> {
    return this.model$.pipe(
      RXJSUtils.filterUndefinedAndNull(),
      map((value: any) => {
        if (this.getType() === AssetType.URDF) {
          return URDFUtils.clone(value);
        } else if (
          this.getType() === AssetType.DAEModel ||
          this.getType() === AssetType.STLModel
        ) {
          return ThreeUtils.clone(value);
        } else {
          return value;
        }
      })
    );
  }

  public onModelUnloaded$(): Observable<boolean> {
    return this.unload$.pipe(filter(Boolean));
  }

  public clear() {
    this.model$.next(undefined);
    this.unload$.next(true);
    this.unload$.next(false);
  }

  public reset() {
    this.mode =
      this.config && this.config.defaultMode
        ? this.config.defaultMode
        : this.id;
    this.setLoaded(false);
    this.setLoading(false);
    this.clear();
    this.models = new Map();
  }

  // Makes a unique asset
  public clone(newID: string): Asset<Ttype> {
    const asset = new Asset<Ttype>(this.config);
    asset.id = newID;
    for (const [mode, url] of this.urls) {
      asset.setURL(url, mode);
    }
    for (const [mode, model] of this.models) {
      if (model instanceof THREE.Object3D) {
        const twin: unknown = ThreeUtils.clone(model);
        asset.set(twin as Ttype, mode);
      }
    }
    this.setMode(this.mode);
    asset.loading$.next(this.loading$.getValue());
    asset.loaded$.next(this.loaded$.getValue());
    asset.unload$.next(false);
    return asset;
  }

  private resolveDefaultMode(): string {
    return this.config.defaultMode ? this.config.defaultMode : this.config.id;
  }
}
