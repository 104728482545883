import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ThreeViewContent } from '../../../../../../../models_new/classes/3dview/three-content';
import { New_ThreeViewComponent } from '../../../../three-view/three-view.component';

@Component({
  selector: 'app-orbit-controls',
  templateUrl: './orbit-controls.component.html',
  styleUrls: ['./orbit-controls.component.scss'],
})
export class OrbitControlsComponent
  extends ThreeViewContent
  implements OnChanges, AfterViewInit, OnDestroy
{
  controls: OrbitControls;

  @Input() camera: THREE.Camera;
  @Input() canvas: HTMLCanvasElement;

  @Input() target: THREE.Vector3 | THREE.Object3D | undefined;
  @Input() minDistance: number;
  @Input() maxDistance: number;

  @Input() enableRotate: boolean;
  @Input() enablePan: boolean;
  @Input() enableZoom: boolean;

  @Output() change$ = new EventEmitter<void>();

  destroy$ = new ReplaySubject<void>(1);

  constructor(threeView: New_ThreeViewComponent) {
    super(threeView);
    this.updateOrder = 50; // Update this late
  }

  override update(dt: number): void {
    if (this.target) {
      this.updateControlsToTarget(this.target);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Changes to canvas or camera and both are defined
    if (
      (changes.camera?.currentValue || changes.canvas?.currentValue) &&
      this.camera &&
      this.canvas
    ) {
      if (this.controls) {
        this.controls.dispose();
      }

      this.controls = this.makeControls();
    }

    if (changes.minDistance) {
      this.controls.minDistance = changes.minDistance.currentValue;
    }
    if (changes.maxDistance) {
      this.controls.maxDistance = changes.maxDistance.currentValue;
    }
    if (changes.enableRotate) {
      this.controls.enableRotate = changes.enableRotate.currentValue;
    }
    if (changes.enablePan) {
      this.controls.enablePan = changes.enablePan.currentValue;
    }
    if (changes.enableZoom) {
      this.controls.enableZoom = changes.enableZoom.currentValue;
    }
    if (changes.target?.currentValue) {
      this.updateControlsToTarget(changes.target.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.controls.update();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private makeControls(): OrbitControls {
    const controls = new OrbitControls(this.camera, this.canvas);
    controls.addEventListener('change', () => {
      this.change$.emit();
    });
    return controls;
  }

  updateControlsToTarget(
    target: THREE.Vector3 | THREE.Object3D | undefined
  ): void {
    if (target) {
      this.controls.target.copy(
        target instanceof THREE.Object3D ? target.position : target
      );

      this.controls.update();
    }
  }
}
