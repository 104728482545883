import { PalletPosition } from '../../enums/pallet-position';
import { defaultPalletView } from './default-pallet-view';
import { defaultAdvancedSettings } from './default-advanced-settings';
import { defaultData } from './default-data';
import { Pallet } from 'src/app/models_new/classes/pallet';
import { ProjectData } from '../../classes/project-data';
import { PalletView } from '../../classes/pallet-view';
import { PalletAdvancedSettings } from '../../classes/pallet-advanced-settings';
import { IProject } from '../../types/project';
import { settings } from '../application-settings';

export const defaultProject: IProject = {
  data: new ProjectData(defaultData),
  activePallet: settings.primaryPallet,
  pallets: [new Pallet(PalletPosition.LEFT), new Pallet(PalletPosition.RIGHT)],
  archive: [
    new Pallet(null),
    new Pallet(null),
    new Pallet(null),
    new Pallet(null),
    new Pallet(null),
  ],
  palletView: new PalletView(
    defaultPalletView.palletViewSettings,
    defaultPalletView.palletViewLayersNo
  ),
  palletAdvancedSettings: new PalletAdvancedSettings(
    defaultAdvancedSettings,
    'default'
  ),
};
