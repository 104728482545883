import { IStandardPallet } from './../../../../models_new/types/standard-pallet';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Observable,
  ReplaySubject,
  skipWhile,
  takeUntil,
  tap,
  take,
  map,
  shareReplay,
} from 'rxjs';
import { InfoPopupComponent } from 'src/app/components/dialogs/info-popup/info-popup.component';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { PatternGenerator } from 'src/app/models_new/classes/pattern-generator';
import { settings } from 'src/app/models_new/config/application-settings';
import { defaultApiPattern } from 'src/app/models_new/config/default/api-default/default-api-pattern';
import { defaultData } from 'src/app/models_new/config/default/default-data';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MagicStackService } from 'src/app/services/magic-stack.service';
import { PalletEditorService } from 'src/app/services/pallet-editor.service';
import { ThreeHandlerMode } from '../../../../models_new/classes/3dview/three-handler-mode';
import { ApiProduct } from '../../../../models_new/classes/api-models/ApiProduct';
import { Project } from '../../../../models_new/classes/project';
import {
  removePalletFromList,
  ListOfAllPallets,
} from '../../../../models_new/config/form_fields';
import { StackingMethod } from '../../../../models_new/enums/stacking-method';
import { ExportImportService } from '../../../../services/export-import.service';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { CustomPalletDialogComponent } from '../../../dialogs/custom-pallet-dialog/custom-pallet-dialog.component';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { StandardPallet } from 'src/app/models_new/types/standard-pallet';
import {
  FormBuilder,
  FormControlStatus,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { nameRegexp } from 'src/app/models_new/config/validation/pally-file-values';
import { ILabelDirection } from 'src/app/models_new/types/labelDirection';
import { EagerStateMatcher } from 'src/app/components/gui/field/field.component';
import { PatternInfoInput } from '../pattern-info/pattern-info.component';
import { ObjectUtils } from 'src/app/utils/object';
import * as PPBUnitUtils from '../../../../utils/unit-utils';
import { AlternativeLayoutService } from 'src/app/services/alternative-layout.service';
import { IProjectData } from 'src/app/models_new/types/project-data';

interface fgRawValue {
  name: string;
  description: string;
  pallet: StandardPallet;
  stacking_method: StackingMethod;
  nr_layers: number;
  padding: number;
  overhang_ends: number;
  overhang_sides: number;
  label_orientation: 'Optimized' | 'Outwards' | 'Locked';
  max_grip: 'Auto' | '1' | '2' | '3' | '4' | '5' | '6';
  cpm: number;
}
interface fgv {
  name: (fgRawValue['name'] | ValidatorFn[])[];
  description: (fgRawValue['description'] | ValidatorFn[])[];
  pallet: (fgRawValue['pallet'] | ValidatorFn[])[];
  stacking_method: (fgRawValue['stacking_method'] | ValidatorFn[])[];
  nr_layers: (fgRawValue['nr_layers'] | ValidatorFn[])[];
  padding: (fgRawValue['padding'] | ValidatorFn[])[];
  overhang_ends: (fgRawValue['overhang_ends'] | ValidatorFn[])[];
  overhang_sides: (fgRawValue['overhang_ends'] | ValidatorFn[])[];
  label_orientation: (fgRawValue['label_orientation'] | ValidatorFn[])[];
  max_grip: (fgRawValue['max_grip'] | ValidatorFn[])[];
  cpm: (fgRawValue['cpm'] | ValidatorFn[])[];
}
@Component({
  selector: 'app-pattern-maker-card',
  templateUrl: './pattern-maker-card.component.html',
  styleUrls: ['./pattern-maker-card.component.scss'],
})
export class NewPatternMakerCardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  ThreeHandlerMode = ThreeHandlerMode;
  eagerMatcher = new EagerStateMatcher();

  project$: Observable<DataRequestState<Project>>;
  patternGenerator: PatternGenerator;
  sticker$: Observable<THREE.Texture>;
  PatternInfoInput: PatternInfoInput;

  customPallet: StandardPallet | null;
  initialOverhang: number = 0;

  @Input() isFastTrack: boolean = false;
  @Input() product: ApiProduct;
  @Output() patternEmit = new EventEmitter<ApiPattern>();
  @Output() formGroupStatus = new EventEmitter<FormControlStatus>();

  destroy$ = new ReplaySubject<boolean>(1);

  formGroup: FormGroup;

  DefaultNoOfLayers = settings.defaultNoOfLayers;
  ListOfAllPallets = ListOfAllPallets;
  MaxGrips = [1, 2, 3, 4, 5, 6, 7, 8];
  ZeroToThirty = new Array(31).fill(null).map((_, index) => index);
  ZeroToHoundred = new Array(101).fill(null).map((_, index) => index);
  LabelOrientations = ['Optimized', 'Outwards', 'Locked'];
  LabelOrientationsFastTrack = [
    { label: 'Yes', id: 'Outwards' },
    { label: "Doesn't matter", id: 'Optimized' },
  ];

  unit: PPBUnitUtils.UnitSystemType = 'metric';
  PPBUnitUtils = PPBUnitUtils;

  constructor(
    private infoApi: InfoApiService,
    private dialogService: DialogService,
    private palletEditor: PalletEditorService,
    private magicStackService: MagicStackService,
    private exportImport: ExportImportService,
    private orgLogoService: OrganizationLogoService,
    private localStorageService: LocalStorageService,
    public appLayout: AppLayoutService,
    private fb: FormBuilder,
    private altLayoutService: AlternativeLayoutService
  ) {
    this.unit = this.localStorageService.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
    this.initialOverhang = this.unit === 'imperial' ? 10 : 0;
    this.formGroup = this.fb.group<fgv>({
      name: ['', [Validators.pattern(nameRegexp), Validators.required]],
      description: ['', [Validators.maxLength(200)]],
      pallet: [this.ListOfAllPallets[2], [Validators.required]],
      nr_layers: [5, [Validators.required]],
      overhang_ends: [this.initialOverhang, [Validators.required]],
      overhang_sides: [this.initialOverhang, [Validators.required]],
      padding: [0, [Validators.required]],
      label_orientation: ['Optimized', [Validators.required]],
      max_grip: ['Auto', [Validators.required]],
      stacking_method: [StackingMethod.ROTATE, [Validators.required]],
      cpm: [10, [Validators.required]],
    });

    this.customPallet =
      this.localStorageService.getData(LocalStorageKey.CUSTOM_PALLET) || null;
    if (
      this.customPallet &&
      !this.ListOfAllPallets.find((f) => f.name === this.customPallet.name)
    ) {
      this.ListOfAllPallets.push(this.customPallet);
    }

    this.sticker$ = this.orgLogoService.eitherLabelOrLogo$.pipe(
      map((d) => d.texture)
    );

    this.patternGenerator = new PatternGenerator(
      this.palletEditor,
      this.exportImport,
      this.magicStackService,
      this.altLayoutService
    );

    this.project$ = this.patternGenerator.project$.pipe(
      takeUntil(this.destroy$),
      tap((p: Project) => {
        this.checkAndApplyLabelsOutwards(p);
        this.checkAndApplyLabelsLocked(p);
        this.createPalletInfoInput(p);

        const pattern = new ApiPattern(
          ObjectUtils.cloneObject(defaultApiPattern)
        );

        const formGroupValue: fgRawValue = this.formGroup.getRawValue();
        p.data.name = formGroupValue.name;
        pattern.name = formGroupValue.name;
        pattern.product_id = this.product.id;

        p.data.description = formGroupValue.description;
        p.data.cpm = formGroupValue.cpm;

        if (formGroupValue.max_grip === 'Auto') {
          p.data.box.maxGrip = 8;
        } else {
          p.data.box.maxGrip = +formGroupValue.max_grip;
        }

        p.data.box.label.orientation = this.product.data.label_orientation;

        let exported = this.exportImport.mapToExportFormat(p);
        let settings = { ...exported.guiSettings };
        settings.altLayout = p.palletAdvancedSettings.altLayout;
        pattern.data = exported;
        exported.guiSettings = settings;
        this.patternEmit.emit(pattern);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      toRequestState()
    );
  }

  ngOnInit(): void {
    this.patternGenerator
      .setProduct(this.product)
      .setStackingMethod(settings.defaultStackingMethod)
      .setProjectData(this.getProjectDefaultData())
      .generateNewPattern();
  }

  ngOnDestroy(): void {
    this.patternGenerator.destroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.formGroupStatus.emit(this.formGroup.status);
    const status$ = this.formGroup.statusChanges;
    const changes$ = this.formGroup.valueChanges;

    changes$.pipe(takeUntil(this.destroy$)).subscribe((_v) => {
      this.generateNewPallet();
    });

    status$.pipe(takeUntil(this.destroy$)).subscribe((s) => {
      this.formGroupStatus.emit(s);
    });

    setTimeout(() => {
      this.formGroup.markAllAsTouched();
      this.formGroup.markAsDirty();
    }, 100);
  }

  patchFormGroup(project: Project): void {
    this.formGroup.patchValue({
      nr_layers: project.getActivePallet().layers.length,
    });
  }

  onPatternSelect(index: number) {
    this.patternGenerator.setSelectedBasePatternIndex(index);
    this.patternGenerator.generateNewPattern();
  }

  prevPatternSelect() {
    // If start of list, go to end
    if (this.patternGenerator.selectedBasePatternIndex === 0) {
      this.patternGenerator.setSelectedBasePatternIndex(
        this.patternGenerator.basePatternIndexes$.getValue().length - 1
      );
    } else {
      this.patternGenerator.setSelectedBasePatternIndex(
        this.patternGenerator.selectedBasePatternIndex - 1
      );
    }
    // Generate new pallet
    this.patternGenerator.generateNewPattern();
  }

  nextPatternSelect() {
    // If end of list, go to start
    if (
      this.patternGenerator.selectedBasePatternIndex ===
      this.patternGenerator.basePatternIndexes$.getValue().length - 1
    ) {
      this.patternGenerator.setSelectedBasePatternIndex(0);
    } else {
      this.patternGenerator.setSelectedBasePatternIndex(
        this.patternGenerator.selectedBasePatternIndex + 1
      );
    }
    // Generate new pallet
    this.patternGenerator.generateNewPattern();
  }

  openInfoPopup(id: string, _values?: { [key: string]: any }) {
    const substitutions = {};

    const data = {
      id: id,
      dataSource: this.infoApi.getPatternInfoCards(),
      substitutions: substitutions,
    };

    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      data,
      true
    );
  }

  generateNewPallet() {
    this.project$
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((val) => !val.value),
        take(1),
        map((d) => d.value)
      )
      .subscribe((p: Project) => {
        const formGroupValue: fgRawValue = this.formGroup.getRawValue();

        p.data.box.dimensions = p.data.box.dimensions;

        p.data.box.padding = +formGroupValue.padding;
        p.data.box.label.direction =
          formGroupValue.label_orientation.toLowerCase() as ILabelDirection;
        p.data.cpm = formGroupValue.cpm;

        p.data.pallet.dimensions.loadHeight =
          formGroupValue.nr_layers * p.data.box.dimensions.height;
        p.data.pallet.overhang.ends = +formGroupValue.overhang_ends;
        p.data.pallet.overhang.sides = +formGroupValue.overhang_sides;

        if (formGroupValue.max_grip === 'Auto') {
          p.data.box.maxGrip = 8;
        } else {
          p.data.box.maxGrip = +formGroupValue.max_grip;
        }

        p.data.pallet.dimensions.length = +formGroupValue.pallet.length;
        p.data.pallet.dimensions.width = +formGroupValue.pallet.width;
        p.data.pallet.dimensions.palletHeight =
          +formGroupValue.pallet.palletHeight;

        p.data.stackingMethod = formGroupValue.stacking_method;

        this.patternGenerator.setStackingMethod(p.data.stackingMethod);
        this.patternGenerator.setNoOfLayers(formGroupValue.nr_layers);

        this.patternGenerator.setProjectData(p.data).generateNewPattern();
      });
  }

  private checkAndApplyLabelsOutwards(p: Project) {
    if (
      p.data.box.label.direction === 'outwards' &&
      p.data.box.label.orientation !== null &&
      p.data.box.label.enabled
    ) {
      for (const pallet of p.pallets) {
        pallet.setLabelsFacingOut();
      }
    }
  }
  private checkAndApplyLabelsLocked(p: Project) {
    if (
      p.data.box.label.direction === 'locked' &&
      p.data.box.label.orientation !== null &&
      p.data.box.label.enabled
    ) {
      for (const pallet of p.pallets) {
        pallet.setLabelsLocked();
      }
    }
  }

  handleCustomPalletClick() {
    if (!this.customPallet) {
      this.dialogService.showCustomDialog(
        CustomPalletDialogComponent,
        DialogSize.MEDIUM,
        null,
        {
          createHandler: this.createAndSelectCustomPallet.bind(this),
        },
        true
      );
    } else if (this.customPallet) {
      this.dialogService.showCustomDialog(
        CustomPalletDialogComponent,
        DialogSize.MEDIUM,
        null,
        {
          createHandler: this.createAndSelectCustomPallet.bind(this),
          customPallet: this.customPallet,
        },
        true
      );
    } else {
      this.formGroup.get('pallet').setValue(
        ListOfAllPallets.find((p) => {
          return p.name === this.customPallet.name;
        })
      );
    }
  }

  createAndSelectCustomPallet(
    customPalletSize: IStandardPallet
  ): StandardPallet {
    // Remove old custom pallet if it is defined in local store
    if (
      ListOfAllPallets.find((p) => {
        return p.name === settings.customPalletSettings.name.default;
      })
    ) {
      removePalletFromList(settings.customPalletSettings.name.default);
    }

    const cp = new StandardPallet(
      {
        name: settings.customPalletSettings.name.default,
        width: +customPalletSize.width,
        length: +customPalletSize.length,
        palletHeight: +customPalletSize.palletHeight,
        loadHeight: 1200,
      },
      new LocalStorageService()
    );

    this.localStorageService.setData(LocalStorageKey.CUSTOM_PALLET, cp);
    this.customPallet = cp;
    this.ListOfAllPallets.push(cp);
    this.formGroup.get('pallet').setValue(cp);

    return cp;
  }

  createPalletInfoInput(project: Project) {
    this.PatternInfoInput = {
      boxCount: project.getActivePallet().palletSummary.boxCount,
      boxWeight: project.data.box.weight,
      boxHeight: project.data.box.dimensions.height,
      boxWidth: project.data.box.dimensions.width,
      boxLength: project.data.box.dimensions.length,
      numberOfLayers: project.getActivePallet().layers.length,
      palletCubeEfficiency:
        project.getActivePallet().palletSummary.palletCubeEfficiency,
      palletWeight: project.getActivePallet().palletSummary.palletWeight,
      palletHeight: project.getActivePallet().palletSummary.palletHeight,
      palletCenterOfMass:
        project.getActivePallet().palletSummary.palletCenterOfMass,
    };
  }

  private getProjectDefaultData(): IProjectData {
    const data = ObjectUtils.cloneObject(defaultData);
    if (this.isFastTrack) {
      data.pallet.overhang.ends = this.initialOverhang;
      data.pallet.overhang.sides = this.initialOverhang;
    }
    return data;
  }
}
