import {
  IPallyFileType,
  IPallyLayer,
  IPallyBox,
} from '../../types/pally-file-type';
import {
  pallyDataType,
  pallyLayerType,
  pallyBoxType,
  pallyLayerName,
  pallyPalletDimensionType,
  pallyProductDimensionType,
  pallyGuiSettingsType,
} from '../../config/validation/pally-file-types';
import { IExportImportResult } from '../../types/export-import-result';
import { ErrorType } from '../../enums/error-type';

interface IcompareObject {
  value: any;
  name: string;
  type: string | string[];
}

export class ImportTypeValidator {
  projectDataType = pallyDataType;
  palletDimType = pallyPalletDimensionType;
  productDimType = pallyProductDimensionType;
  guiSettingsType = pallyGuiSettingsType;
  layerType = pallyLayerType;
  layerNameType = pallyLayerName;
  boxType = pallyBoxType;

  errors: IExportImportResult[] = [];

  constructor(json: IPallyFileType) {
    this.typeCheckImport(json);
  }

  /**
   * @param value: any
   * @param shouldBeType: string[]
   * @returns has equal types
   */
  isEqual(value: any, shouldBeType: string[] | string): boolean {
    if (Array.isArray(shouldBeType)) {
      if (
        shouldBeType.includes(typeof value) ||
        shouldBeType.includes(value + '')
      ) {
        return true;
      }
    }

    if (shouldBeType === 'array' && Array.isArray(value)) {
      return true;
    } else {
      return typeof value === shouldBeType;
    }
  }

  /**
   * @param data: IcompareObject
   */
  addError(data: IcompareObject) {
    const error: IExportImportResult = {
      message: `${data.name} should be '${data.type}' not '${data.value}'`,
      type: ErrorType.BREAKING,
    };

    this.errors.push(error);
  }

  /**
   * @param data: IcompareObject
   */
  compareData(data: IcompareObject): void {
    /**
     * If data type is undefined than that key should not be defined.
     * This should be caught by the key validator and give a
     * minor issue feedback
     */
    if (data.type !== undefined && !this.isEqual(data.value, data.type)) {
      this.addError(data);
    }
  }

  /**
   * @param json: IPallyFileType
   */
  typeCheckImport(json: IPallyFileType): void {
    const checkValues: IcompareObject[] = [];

    const addCompareObject = (value, key, type) => {
      const obj: IcompareObject = {
        value: value,
        name: key,
        type: type,
      };
      checkValues.push(obj);
    };

    // Check parents

    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        // Add project data
        if (Object.keys(this.projectDataType).includes(key)) {
          addCompareObject(json[key], key, this.projectDataType[key]);
        }

        // Add layer names
        if (key === 'layers') {
          addCompareObject(json[key], key, this.layerNameType);
        }
      }
    }

    // Check all parents
    checkValues.forEach((toCheck: IcompareObject) => {
      this.compareData(toCheck);
    });

    if (!this.errors.length) {
      // Check nested if no errors so far
      for (const key in json) {
        if (json.hasOwnProperty(key)) {
          if (key === 'dimensions') {
            for (const value in json[key]) {
              if (json[key].hasOwnProperty(value)) {
                addCompareObject(
                  json[key][value],
                  value,
                  this.palletDimType[value]
                );
              }
            }
          }

          if (key === 'productDimensions') {
            for (const value in json[key]) {
              if (json[key].hasOwnProperty(value)) {
                addCompareObject(
                  json[key][value],
                  value,
                  this.productDimType[value]
                );
              }
            }
          }

          if (key === 'guiSettings') {
            for (const value in json[key]) {
              if (json[key].hasOwnProperty(value)) {
                addCompareObject(
                  json[key][value],
                  value,
                  this.guiSettingsType[value]
                );
              }
            }
          }

          // Add layers
          if (key === 'layerTypes') {
            json[key].forEach((layer: IPallyLayer, layerIndex) => {
              for (const value in layer) {
                if (layer.hasOwnProperty(value)) {
                  addCompareObject(layer[value], value, this.layerType[value]);
                }
              }

              // Add altPattern in layer
              if (layer.altPattern && layer.altPattern.length) {
                layer.altPattern.forEach((box: IPallyBox, boxIndex) => {
                  for (const value in box) {
                    if (box.hasOwnProperty(value)) {
                      addCompareObject(
                        box[value],
                        'altPattern box no: ' +
                          boxIndex +
                          ' in layer: ' +
                          layer.name,
                        this.boxType[value]
                      );
                    }
                  }
                });
              }

              // Add pattern in layer
              if (layer.pattern && layer.pattern.length) {
                layer.pattern.forEach((box: IPallyBox, boxIndex) => {
                  for (const value in box) {
                    if (box.hasOwnProperty(value)) {
                      addCompareObject(
                        box[value],
                        'pattern box no: ' +
                          boxIndex +
                          ' in layer: ' +
                          layer.name,
                        this.boxType[value]
                      );
                    }
                  }
                });
              }
            });
          }
        }
      }

      // Check all nested
      checkValues.forEach((toCheck: IcompareObject) => {
        this.compareData(toCheck);
      });
    }
  }

  clearImportErrors() {
    this.errors = [];
  }
}
