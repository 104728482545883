import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ApiPattern } from '../models_new/classes/api-models/ApiPattern';
import { ImportKeyValidator } from '../models_new/classes/validators/import-key-validator';
import { ImportTypeValidator } from '../models_new/classes/validators/import-type-validator';
import { ImportValuesValidator } from '../models_new/classes/validators/import-value-validator';
import { ErrorType } from '../models_new/enums/error-type';
import { IExportImportResult } from '../models_new/types/export-import-result';
import { IPallyFileType } from '../models_new/types/pally-file-type';

export interface IValidationEvent {
  errors: {
    breaking: IExportImportResult[];
    major: IExportImportResult[];
    minor: IExportImportResult[];
  };
  valid: 'validating' | 'valid' | 'invalid';
  msg: string;
}

@Injectable({
  providedIn: 'root',
})
export class ValidatorService implements OnDestroy {
  validationProgress$ = new BehaviorSubject<number>(0);
  public noOfValidators: number;

  destroy$ = new ReplaySubject<boolean>(1);

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  proceed() {
    const iterator = 100 / this.noOfValidators;
    const currProg = this.validationProgress$.getValue();
    const progress = currProg + iterator;
    if (progress <= 100) {
      this.validationProgress$.next(progress);
    }
  }

  /**
   * @param json: IPallyFileType
   */
  validateImportKeys(json: IPallyFileType): Observable<IExportImportResult[]> {
    const importKeysChecker: ImportKeyValidator = new ImportKeyValidator(json);

    return of(importKeysChecker.errors);
  }

  /**
   * @param json: IPallyFileType
   */
  validateImportTypes(json: IPallyFileType): Observable<IExportImportResult[]> {
    const importTypeChecker: ImportTypeValidator = new ImportTypeValidator(
      json
    );

    return of(importTypeChecker.errors);
  }

  /**
   * @param json: IPallyFileType
   */
  validateImportValues(
    json: IPallyFileType
  ): Observable<IExportImportResult[]> {
    const importValuesChecker: ImportValuesValidator =
      new ImportValuesValidator(json);

    return of(importValuesChecker.errors);
  }

  /**
   * Function for running a full validation of a project.
   */
  validatePattern(pattern: ApiPattern): Observable<IValidationEvent> {
    const result: IValidationEvent = {
      errors: {
        breaking: [],
        major: [],
        minor: [],
      },
      valid: 'validating',
      msg: '',
    };

    if (!pattern?.data) {
      result.errors.breaking = [
        { message: 'missing data', type: ErrorType.BREAKING },
      ];
      result.valid = 'invalid';
      result.msg = 'Missing data!';
      return of(result);
    }

    result.errors.breaking = [];
    result.errors.major = [];
    result.errors.minor = [];

    return combineLatest([
      this.validateImportKeys(pattern.data as IPallyFileType),
      this.validateImportTypes(pattern.data as IPallyFileType),
      this.validateImportValues(pattern.data as IPallyFileType),
    ]).pipe(
      takeUntil(this.destroy$),
      map((reports) => {
        for (const report of reports) {
          for (const error of report) {
            switch (error.type) {
              case ErrorType.BREAKING:
                result.errors.breaking.push(error);
                break;
              case ErrorType.MAJOR:
                result.errors.major.push(error);
                break;
              case ErrorType.MINOR:
                result.errors.minor.push(error);
                break;
            }
          }
        }

        if (result.errors.breaking.length > 0) {
          result.msg = 'Please fix shown errors before re-importing project!';
          result.valid = 'invalid';
        } else if (result.errors.major.length > 0) {
          result.msg = 'Please fix shown issues before re-importing project!';
          result.valid = 'invalid';
        } else if (result.errors.minor.length > 0) {
          result.msg = 'We found some minor issues with your import.';
          result.valid = 'invalid';
        } else {
          result.valid = 'valid';
        }
        return result;
      })
    );
  }
}
