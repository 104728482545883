import { Pipe, PipeTransform } from '@angular/core';

/**
 * Custom pipe to remove underscores (_) from a string and replace them with spaces.
 */
@Pipe({
  name: 'removeLodash',
})
export class RemoveLodashPipe implements PipeTransform {
  /**
   * Transforms the input text by replacing underscores (_) with spaces.
   * If the input is not a string, it returns the input as is.
   * @param text The input text to be transformed.
   * @returns The transformed text with underscores replaced by spaces, or the input text if it is not a string.
   */
  transform(text: any) {
    if (typeof text !== 'string') {
      return text;
    }
    return text?.replace(/\_/g, ' ') || text;
  }
}
