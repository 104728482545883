export const ModelUploadDescriptions = {
  fileUpload:
    'Choose a project name, select model type,<br> and upload a <i>.dae</i>- or <i>.stl</i>-file',

  editor: {
    gripper:
      'Position the model such that the mounting point is in the origin. Rotate the model such that the y-axis(green) is pointing towards the robot and z-axis(blue) is pointing downwards.',

    frame:
      'Position the model in the origin(Point 1), then rotate the model such that the y-axis(green) is pointing towards the conveyor, and the z-axis(blue) is pointing upwards. Lastly, position the origin of the frame(Point 2) where the robot is to be placed',

    any: 'Position the origin- and supply correct rotation of the model.',
  },

  summary:
    'Make sure the metadata is correct. Then choose to upload the component to the pally-descriptions repo, or download the files locally to add them yourself.',
};
