<ng-container [ngSwitch]="radioState">
  <ng-container *ngSwitchCase="'loading'">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngSwitchCase="'checked'">
    <mat-icon class="icon selected">check_circle_outline</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'unchecked'">
    <mat-icon class="icon">radio_button_unchecked</mat-icon>
  </ng-container>
</ng-container>
