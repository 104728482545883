import { Injector } from '@angular/core';
import * as THREE from 'three';
import { URDFRobot } from '@rocketfarm/urdf-loader';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { defaultRobotPose } from 'src/app/models_new/config/default-robot-pose';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { MathUtils } from 'three';
import { Task, taskNameSymbol } from '../task';

export class RobotTask extends Task {
  static [taskNameSymbol] = 'Robot arm';

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateRobot(this.data, resolve, reject);
  }

  public updateRobot(
    s: FieldUpdate,
    resolve: () => void,
    reject: (reason?: any) => void
  ): void {
    const baseBracket_robotBase = this.robot.getJointByID(
      JointNames.BasebracketRobotbase
    ) as THREE.Object3D;
    const toolmount_offsetBracket = this.robot.getJointByID(
      JointNames.ToolmountOffsetBracket
    ) as THREE.Object3D;

    this.partStoreService
      .getPart<URDFRobot>(
        HwPartUtils.getPartAssetID(PartType.ROBOT, s.newVal.name)
      )
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((part) => {
        // Set to default pose
        for (const jointname of Object.keys(defaultRobotPose)) {
          const joint = part.joints[jointname];
          if (Type.isDefined(joint)) {
            URDFUtils.setJointAngle(
              joint,
              MathUtils.degToRad(defaultRobotPose[jointname])
            );
          }
        }
        const partJoint = (part as THREE.Object3D).getObjectByName(
          'robot_joint'
        );
        const lastPartLink = URDFUtils.findLinkBeforeMountJoint(
          part as URDFRobot
        );

        lastPartLink.children = lastPartLink.children.filter(
          (child) => !URDFUtils.isURDFJoint(child)
        );
        lastPartLink.add(toolmount_offsetBracket);

        baseBracket_robotBase.children = [];
        for (const child of partJoint.children) {
          baseBracket_robotBase.add(child);
        }

        resolve();
      });
  }
}
