import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, map, Observable, of, switchMap, take } from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { assetData, assetType } from 'src/app/services/api/asset-api.service';
import { NotificationService } from 'src/app/services/notification.service';

// Kudos to ibern ;)

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
  animations: [
    trigger('fileover', [
      state(
        'true',
        style({
          border: 'solid 1px #395692',
          background: 'rgba(57, 86, 146, 0.15)',
        })
      ),
      state(
        'false',
        style({
          border: 'dashed 1px #395692',
        })
      ),
      transition('true <=> false', [animate('125ms')]),
    ]),
  ],
})
export class ImportFileComponent implements OnChanges {
  orgID$: Observable<string>;
  files: any[] = [];
  cardText: {
    label: string;
    hint?: string;
  };

  /** File type. Can be either 'Logo', 'Document' or 'Zip' according to AssetType enum from the DB */
  @Input()
  fileCategory?: assetType = 'documentation';

  /**  Allows picking multiple files at once. Defaulted to false */
  @Input()
  multiple?: boolean = false;

  /** Supported formats. Limits the file formats supported if given */
  @Input()
  accept?: string[];

  /** Size limit. Filters the input files by file-size. Value must be given in Bytes */
  @Input()
  sizeLimit?: number;

  /** Label text to be showned at the file-picker container */
  @Input()
  label?: string;

  /* Feedback to show loading icon */
  @Input()
  loading?: boolean = false;

  /* Feedback to show loading icon */
  @Input()
  includeOrgIdInFileName?: boolean = true;

  /** Returns an array with the successfully uploaded asset's IUploadAsset responses */
  @Output()
  assets?: EventEmitter<assetData[]> = new EventEmitter();

  constructor(
    private notification: NotificationService,
    private stateService: StateService
  ) {
    this.orgID$ = this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(map((o) => o?.id));
    this.setCardText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.sizeLimit ||
      changes.accept ||
      changes.label ||
      changes.multiple
    ) {
      this.setCardText();
    }
  }

  /** Handles drag-and-drop file input  */
  onFileDropped($event) {
    if (!this.loading) {
      this.prepareFilesList(Array.from($event));
    }
  }

  /** Handles file-browser input  */
  onFileBrowsed($event) {
    if (!this.loading) {
      this.prepareFilesList(Array.from($event.target.files));
    }
  }

  /** When multiple files, handles removing items from loaded file-list */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /** Checks every file uploaded and converts them to base64. */
  private prepareFilesList(files: Array<any>) {
    // Notify about any non-valid files.
    files.forEach((file) => {
      const fileCheck = this.checkInputParams(file);
      if (!fileCheck.valid) {
        this.notification.showError(fileCheck.msg);
      }
    });

    // Extract only valid files
    const validFiles = files.filter(
      (file) => this.checkInputParams(file).valid
    );

    // If this component is configured as a single file input, we want to immediately upload.
    if (!this.multiple && validFiles.length) {
      const file = files[0];
      this.convertToBase64(file)
        .pipe(
          take(1),
          switchMap((base64: string) =>
            combineLatest([
              of(base64),
              this.includeOrgIdInFileName ? this.orgID$ : of(''),
            ])
          )
        )
        .subscribe(([base64, orgID]) => {
          file.data = base64;
          this.files = [file];
          this.assets.emit([this.getUploadAssetObject(file, orgID)]);
        });
      return;
    }

    // Convert file content to base64.
    validFiles.forEach((file) => {
      this.convertToBase64(file)
        .pipe(
          switchMap((base64: string) =>
            combineLatest([
              of(base64),
              this.includeOrgIdInFileName ? this.orgID$ : of(''),
            ])
          )
        )
        .subscribe(([base64, orgID]) => {
          file.data = base64;
          this.files.push(file);
          this.assets.emit(
            files.map((file) => this.getUploadAssetObject(file, orgID))
          );
        });
    });
  }

  /** Filter files if any filter is applied. */
  private checkInputParams(file): { valid: boolean; msg?: string } {
    /** Size limit below file's size; valid: false. If undefined filter; valid: true */
    const isSizeValid = this.sizeLimit ? this.sizeLimit > file.size : true;
    /** File format not accepted; Valid: false. If undefined filter; valid: true  */
    const isFormatValid = this.accept
      ? this.accept.some((format) => file.name.endsWith(`${format}`))
      : true;

    /** Compose error message if any isValid: false */
    return {
      valid: isSizeValid && isFormatValid,
      msg:
        (!isSizeValid
          ? `The file ${file.name} cannot not exceed ${this.formatBytes(
              this.sizeLimit
            )}. `
          : '') +
        (!isFormatValid ? `Incompatible format for file ${file.name}.` : ''),
    };
  }

  /** Conversion from bytes to its shorter human-friendly format */
  formatBytes(bytes: number, decimals?: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private convertToBase64(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    return new Observable((observer) => {
      reader.onload = (event) => {
        observer.next(btoa(event.target.result.toString()));
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }

  private getUploadAssetObject(file, orgID: string): assetData {
    const date = new Date().toISOString();
    return {
      asset_type: this.fileCategory,
      data: file.data,
      file_type: file.type,
      name: orgID ? `${orgID}_${file.name}_${date}` : `${file.name}_${date}`,
      organization_id: orgID,
    };
  }

  private setCardText(): void {
    const accept = this.accept
      ? `supported file types: ${this.accept.toString().replace(/,/g, ', ')}`
      : '';
    const size = this.sizeLimit
      ? this.accept
        ? `(max  ${this.formatBytes(this.sizeLimit)})`
        : `max file size  ${this.formatBytes(this.sizeLimit)}`
      : '';
    const labelSubject = this.fileCategory
      ? this.fileCategory.toLowerCase()
      : 'file';
    const defaultLabel = this.fileCategory
      ? `Drag and drop your ${labelSubject}${
          this.multiple && labelSubject !== 'documentation' ? 's' : ''
        } here`
      : '';
    this.cardText = {
      label: this.label || defaultLabel,
      hint: `${accept} ${size}`,
    };
  }

  public getFileType(fileName: string): string {
    return fileName?.split('.')[fileName?.split('.')?.length - 1];
  }
}
