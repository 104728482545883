import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, Subject, take } from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { IHasuraUser } from 'src/app/models_new/types/hasura-user';
import { OrganizationLogoService } from 'src/app/services/organization-logo.service';
import { OrganizationType } from '../../../../models_new/enums/organization-type';
import { DataRequestState } from 'src/app/data-request/model';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { IButtonContent } from 'src/app/models_new/types/info-card-config';
import { toRequestState } from 'src/app/data-request/operators';
import { getElementByNameTag } from 'src/app/utils/contentful-utils';
import { settings } from 'src/app/models_new/config/application-settings';
import {
  LocalStorageService,
  StorageMethod,
} from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { UnitSystemType } from 'src/app/utils/unit-utils';

enum RoutesId {
  INVITE_PAGE = 'org_invite_page',
  CREATE_SALES_ORG = 'create_sales_org',
  EDIT_SALES_ORG = 'edit_sales_org',
}

@Component({
  selector: 'app-state-chips-component',
  templateUrl: './state-chips.component.html',
  styleUrls: ['./state-chips.component.scss'],
})
export class StateChipsComponent implements OnInit {
  OrganizationType = OrganizationType;
  ready = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  pagesPATH = pagesPATH;
  logoutPath = window.location.origin;
  routeId = RoutesId;
  eulaUrl = settings.eulaUrl;

  @Input() user: IHasuraUser;
  @Input() customer_organizations: ApiOrganization[];
  @Input() sales_organizations: ApiOrganization[];
  customerOrgs: ApiOrganization[];
  salesOrgs: ApiOrganization[];
  @Input() sales_org?: ApiOrganization;
  @Input() customer_org?: ApiOrganization;

  userMenuClosed = true;
  salesOrgMenuClosed = true;
  customerOrgMenuClosed = true;
  inviteCount$: Observable<number>;
  contentfulUserMenuButtons$: Observable<DataRequestState<IButtonContent[]>>;
  preferredUnitSystem: UnitSystemType = 'metric';

  constructor(
    private infoApi: InfoApiService,
    public auth: AuthService,
    private stateService: StateService,
    private router: Router,
    public organizationLogoService: OrganizationLogoService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.inviteCount$ = this.stateService.organizationInvites$.pipe(
      map((invites) => invites.length)
    );

    this.contentfulUserMenuButtons$ = this.infoApi
      .getButtonContentByTag('userMenuButton')
      .pipe(take(1), toRequestState());

    this.preferredUnitSystem = this.localStorageService.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
  }

  public getElementByNameTag(
    buttonElements: IButtonContent[],
    nameTag: string
  ): IButtonContent {
    return getElementByNameTag(buttonElements, nameTag);
  }

  public selectOrganization(org: ApiOrganization): void {
    org.type === OrganizationType.SALES_ORGANIZATION
      ? this.stateService.setSalesOrganization(org)
      : this.stateService.setCustomerOrganization(org);
  }

  public doAction(actionId: string, value: string): void {
    if (actionId === 'setUnitSystem') {
      this.localStorageService.setData(
        LocalStorageKey.PREFERRED_UNIT_SYSTEM,
        value,
        StorageMethod.LOCAL
      );
      window.location.reload();
    } else if (actionId === 'navigateTo') {
      const isExternal =
        value?.includes('http://') || value?.includes('https://');
      if (isExternal) {
        window.open(value, '_blank');
      } else {
        switch (value) {
          case RoutesId.INVITE_PAGE:
            this.router.navigate([pagesPATH.ORG_INVITE]);
            break;
          case RoutesId.CREATE_SALES_ORG:
            this.router.navigate([pagesPATH.USER, pagesPATH.NEW_ORGANIZATION]);
            break;
          case RoutesId.EDIT_SALES_ORG:
            this.router.navigate([
              pagesPATH.CUSTOMERS,
              pagesPATH.VIEW_ORG,
              this.sales_org.id,
            ]);
            break;
          default:
            console.warn(`Unknown navigation option for ID: ${value}`);
        }
      }
    }
  }
}
