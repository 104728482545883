import { ITableData } from 'src/app/components/gui/table/table.component';
import { IExpandableTableData } from './expandable-table-data';
import * as PPBTimeUtil from 'src/app/utils/time-utils';
import { ObjectUtils } from 'src/app/utils/object';

export interface ISortingOption {
  id: string;
  label: string;
  selected?: boolean;
  children?: ISortingOption[];
}

export interface IFilterTableData {
  searchText: string;
  setupFilter: Map<string, string[]>;
  resultFilter: Map<string, string[]>;
  resultSlider: Map<string, number>;
}

export class FilterTableData implements IFilterTableData {
  searchText: string = '';
  setupFilterSingle: string[] = [];
  setupFilter: Map<string, string[]> = new Map([]);
  resultFilter: Map<string, string[]> = new Map([]);
  resultSlider: Map<string, number> = new Map([]);

  private timeUtils = PPBTimeUtil;

  constructor() {}

  // Filter based on specific object keyword and value
  public keywordFilter(data: IExpandableTableData<any> | ITableData<any>) {
    if (this.setupFilterSingle?.length > 0) {
      for (let singleFilter of this.setupFilterSingle) {
        if (!ObjectUtils.getNested(data, singleFilter)) {
          return false;
        }
      }
    }
    if (this.setupFilter || this.resultFilter) {
      let combined: Map<string, string[]> = new Map([
        ...this.setupFilter.entries(),
        ...this.resultFilter.entries(),
      ]);
      if (combined.size > 0) {
        for (let filterEntry of combined.entries()) {
          // Get the keyword value, due to possible array value: stringify
          let values = JSON.stringify(
            ObjectUtils.getNested(data, filterEntry[0])
          );
          if (!values) {
            return false;
          }
          if (
            filterEntry[1].length > 0 &&
            !(
              filterEntry[1].filter((filter) => {
                return JSON.parse(values) === filter;
              }).length > 0
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public sliderFilter(data: IExpandableTableData<any> | ITableData<any>) {
    if (this.resultSlider) {
      for (let sliderEntry of this.resultSlider.entries()) {
        let value = ObjectUtils.getNested(data, sliderEntry[0]);

        if (typeof sliderEntry[1] === 'number' && typeof value === 'string') {
          value = parseInt(value);
        }
        if (
          (sliderEntry[1] > 0 && value < sliderEntry[1]) ||
          value === null ||
          value === undefined
        ) {
          return false;
        }
      }
    }

    return true;
  }

  //Pure text filter(search), searches row and items inside expanded content

  public textFilter(data: ITableData<any>) {
    const squash = Object.entries(data).map((entry) =>
      entry[0] === 'updated_at'
        ? this.timeUtils.getFormattedDate(
            Date.parse(entry[1].toString()),
            '2-digit'
          )
        : entry
    );

    const serialized = JSON.stringify(squash).toLowerCase();
    return serialized.includes(this.searchText.toLowerCase());
  }

  public textFilterExandable(data: IExpandableTableData) {
    if (this.searchText) {
      const items = data['items'].concat(data.expandableContent['items']);
      const serialized = JSON.stringify(
        items.map((item) =>
          item.label === 'updated_at'
            ? this.timeUtils.getFormattedDate(
                Date.parse(item.value.toString()),
                '2-digit'
              )
            : item.value
        )
      ).toLowerCase();
      if (!serialized.includes(this.searchText.toLowerCase())) {
        return false;
      }
    }
    return true;
  }
}
