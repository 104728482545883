/**
 * A comprenhensive enumeration for every possible SimulationState.
 */
export enum SimulationStatus {
  CREATED = 'Created',
  QUEUED = 'Queued',
  INITIALIZING = 'Initializing',
  RUNNING = 'Running',
  FINISHED = 'Finished',
  SUCCESSFUL = 'Successful',
  ERROR = 'Error',
  CANCELED = 'Canceled',
  VALIDATE = 'Validate',
  SETTING_UP = 'Setting_up',
  STARTING_ROBOT = 'Starting_robot',
  PALLETIZING = 'Palletizing',
}

// --------------------------------------------------------------------------------
// The following subsets are intended to be used as alternative to long conditions
// to determinate if a simulations is done, still running or hasn't started at all.
// --------------------------------------------------------------------------------

/**
 * A simulation is considered 'Initialized' when:
 * The simulation has just been created, and it's on its way to be set up. At this point there is no simultion data available.
 */
const simulationInitialized: SimulationStatus[] = [
  SimulationStatus.QUEUED,
  SimulationStatus.CREATED,
  SimulationStatus.INITIALIZING,
];

/**
 * A simulation is considered to be 'SettingUp' when:
 * The simulation is being prepared to be executed, but doesn't yet has any data to display.
 */
const simulationSettingUp: SimulationStatus[] = [
  SimulationStatus.SETTING_UP,
  SimulationStatus.STARTING_ROBOT,
];

/**
 * A simulation is considered 'NotStarted' when:
 * The simulation is not running yet and there is no simultion data available.
 * -> This state groups [Initialized & SettingUp]
 */
const simulationNotStarted: SimulationStatus[] = [
  ...simulationInitialized,
  ...simulationSettingUp,
];

/**
 * A simulation is considered 'InProgress' when:
 * The simulation is beign set-up or running and there is already some data to display.
 */
const simulationInProgress: SimulationStatus[] = [
  SimulationStatus.PALLETIZING,
  SimulationStatus.RUNNING,
  SimulationStatus.VALIDATE,
];

/**
 * A simulation is considered 'Failed' when:
 * The simulation has been abruptly stopped for some reason.
 */
const simulationFailed: SimulationStatus[] = [
  SimulationStatus.ERROR,
  SimulationStatus.CANCELED,
];

/**
 * A simulation is considered 'Done' when:
 * The simulation finalized, regardless of being successful or not.
 */
const simulationDone: SimulationStatus[] = [
  SimulationStatus.FINISHED,
  SimulationStatus.SUCCESSFUL,
];

/**
 * A simulation is considered 'Concluded' when:
 * The simulation has been running and, it either finalized, or stopped.
 * -> This state groups [Failed & Done]
 */
const simulationConcluded: SimulationStatus[] = [
  ...simulationDone,
  ...simulationFailed,
];

export const simulationStatuses = {
  initialized: simulationInitialized,
  settingUp: simulationSettingUp,
  notStarted: simulationNotStarted,
  inProgress: simulationInProgress,
  failed: simulationFailed,
  done: simulationDone,
  concluded: simulationConcluded,
};
