<!-- Kudos to ibern ;) -->
<div
  class="import-file-wrapper"
  appDropTarget
  (fileDropped)="onFileDropped($event)"
  [ngClass]="{ loading: loading }"
>
  <div class="loader-container" [ngClass]="{ hidden: !loading }">
    <app-loading></app-loading>
  </div>
  <div [ngClass]="{ hidden: loading }">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      (change)="onFileBrowsed($event)"
      [disabled]="loading"
      [multiple]="multiple"
      [accept]="accept ? accept.toString() : ''"
    />
    <mat-icon>cloud_upload</mat-icon>
    <h3>{{ cardText ? cardText.label : '' }}</h3>
    <h4>or</h4>
    <div style="width: fit-content; margin: auto">
      <app-button
        type="flat"
        color="primary"
        icon="search"
        label="Browse for files"
        [effects]="['rounded', 'normal']"
      ></app-button>
    </div>
    <p class="hint" *ngIf="cardText.hint">
      {{ cardText.hint }}
    </p>
  </div>
</div>
<div class="files-list row">
  <div
    class="single-file col-12"
    [ngClass]="{ loading: loading }"
    *ngFor="let file of files; let i = index"
  >
    <div class="file-icon" style="width: 50px">
      <mat-icon>insert_drive_file</mat-icon>
    </div>
    <div class="file-card">
      <h4 class="card-name">
        {{ file.name }}
      </h4>
      <p>
        {{ formatBytes(file.size) }}
      </p>
      <p>
        File type:
        {{ file.type || getFileType(file.name) }}
      </p>
    </div>
    <button mat-icon-button (click)="deleteFile(i)" [disabled]="loading">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
