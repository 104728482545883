import { Component } from '@angular/core';

/**
 * Provides a routing point for the robot configuration module. Children will provide the actual content
 */
@Component({
  selector: 'app-robot-wrapper',
  templateUrl: './robot-wrapper.component.html',
  styleUrls: ['./robot-wrapper.component.scss'],
})
export class RobotWrapperComponent {}
