export function mapPrivileges(type: string): string {
  let mapped = '';
  switch (type) {
    case 'org_view':
      mapped = 'View';
      break;
    case 'org_edit':
      mapped = 'Edit';
      break;
    case 'org_admin':
      mapped = 'Admin';
      break;
    case 'org_delete':
      mapped = 'Delete';
      break;
    default:
      break;
  }
  return mapped;
}
