import { AssetType } from '../../classes/asset/asset';
import { AssetIDs } from '../../enums/asset-ids';
import { IAssetStoreLoadingConfig } from '../../types/asset/asset-store-loading-config';
import { settings } from '../application-settings';

export const robotConfigAssetConfig: IAssetStoreLoadingConfig[] = [
  {
    id: AssetIDs.SimconfigViewRobot,
    urls: new Map<string, string>([
      [
        AssetIDs.SimconfigViewRobot,
        settings.pallyDescriptionsURL +
          'pally_descriptions/urdfs/simconfig_robot_BLANK.urdf',
      ],
    ]),
    type: AssetType.URDF,
  },
];
