<app-page class="page-container" [ngClass]="{ dialog: isDialog }">
  <ng-container *ngIf="!route.snapshot.queryParams.strategyId">
    <app-breadcrumb-nav *ngIf="!isDialog"></app-breadcrumb-nav>
    <app-header>
      <h1 app-overline *ngIf="!isDialog">
        {{ (isCreating$ | async) ? 'Create' : 'Edit' }}
      </h1>
      <h2 app-title>{{ title }}</h2>
    </app-header>
  </ng-container>

  <mat-divider *ngIf="!route.snapshot.queryParams.strategyId"></mat-divider>

  <ng-container *ngIf="strategy$ | async as strategy; else loading">
    <form [formGroup]="formGroup" style="margin-bottom: 8em">
      <app-edit-page>
        <h3 app-heading>Name your configuration</h3>
        <section app-content formGroupName="general" class="fields-section">
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Software configuration name</mat-label>
              <input matInput formControlName="name" />
              <mat-error>Required field!</mat-error>
            </mat-form-field>
          </div>
        </section>
      </app-edit-page>

      <app-edit-page>
        <h3 app-heading>Software versions</h3>
        <section app-content formGroupName="software" class="fields-section">
          <div class="fill-width">
            <mat-form-field appearance="fill">
              <mat-label>Robot OS (Polyscope version)</mat-label>
              <mat-select formControlName="polyscope_version">
                <mat-option
                  *ngFor="let v of polyscopeVersions$ | async"
                  [value]="v.name"
                >
                  {{ v.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Pally software version</mat-label>
              <mat-select formControlName="pally_version">
                <mat-option
                  *ngFor="let v of pallyVersions$ | async"
                  [value]="v.name"
                >
                  {{ v.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
      </app-edit-page>

      <app-edit-page>
        <h3 app-heading>Robot safety settings</h3>
        <section
          app-content
          class="fields-section"
          formGroupName="robot_safety"
        >
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Safety presets</mat-label>
              <mat-select formControlName="safety_preset">
                <mat-option
                  *ngFor="let preset of safetyPresets$ | async"
                  (click)="updateSafetyPresets(preset)"
                  [value]="preset"
                >
                  <div>
                    {{ preset.label }}
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
        <span
          app-heading-description
          *ngIf="
            getFieldValue('robot_safety', 'safety_preset')
              ?.metadata as selectedPreset
          "
          ><i>
            <ng-container *ngIf="selectedPreset?.maxTcpSpeed as maxSpeed">
              Max speed:
              {{ +maxSpeed * 1000 + ' mm/s' | unitSystem }}.
            </ng-container>
            <ng-container
              *ngIf="selectedPreset?.maxStoppingTime as maxStoppingTime"
              >Max stopping time: {{ maxStoppingTime + ' s' }}.
            </ng-container>
            <ng-container *ngIf="selectedPreset?.maxForce as maxForce">
              Max force: {{ maxForce + ' N' }}.
            </ng-container>
          </i></span
        >
      </app-edit-page>

      <app-edit-page>
        <h3 app-heading>Pally speed settings</h3>
        <section
          app-content
          formGroupName="specifications"
          class="fields-section"
        >
          <div class="fill-width">
            <mat-form-field appearance="fill">
              <mat-label>Max speed</mat-label>
              <input
                matInput
                type="number"
                min="0"
                formControlName="max_speed"
                matRipple
              />
              <mat-error>Min: 0</mat-error>
              <span matSuffix>
                <span matTextSuffix>mm/s</span>
                <button class="infoPopupButton" (click)="showInfo('max_speed')">
                  <mat-icon svgIcon="info"></mat-icon>
                </button>
              </span>
              <span
                matPrefix
                style="padding: 10px"
                *ngIf="changedFields.includes('max_speed')"
              >
                <mat-icon color="accent" class="rotate"
                  >published_with_changes</mat-icon
                >
              </span>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Max acceleration</mat-label>
              <input
                matInput
                type="number"
                min="0"
                formControlName="max_acceleration"
              />
              <mat-error>Min: 0</mat-error>
              <span matSuffix>
                <span matTextSuffix>mm/s²</span>
                <button
                  class="infoPopupButton"
                  (click)="showInfo('max_acceleration')"
                >
                  <mat-icon svgIcon="info"></mat-icon>
                </button>
              </span>
            </mat-form-field>
          </div>
        </section>
        <span app-heading-description
          ><i>
            These values are set independent of the Safety preset, and will
            never exceed any settings from the safety preset.</i
          ></span
        >
      </app-edit-page>

      <app-edit-page>
        <h3 app-heading>Gripper settings</h3>
        <section
          app-content
          formGroupName="specifications"
          class="fields-section"
        >
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Gripper optimization</mat-label>
              <mat-select formControlName="gripper_optimization">
                <mat-option
                  *ngFor="let v of gripperOptimizations$ | async"
                  [value]="v.name"
                >
                  {{ v.label.replaceAll('_', ' ') }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
      </app-edit-page>

      <app-edit-page>
        <h3 app-heading>Advanced Pally Settings</h3>
        <section app-content formGroupName="advanced" class="fields-section">
          <mat-expansion-panel
            class="expansion-panel"
            (opened)="expandedTab = 'advanced'"
            [expanded]="expandedTab === 'advanced'"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Edit Advanced settings</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-content-wrapper">
              <div class="content-column">
                <mat-form-field appearance="fill">
                  <mat-label>Smart acceleration</mat-label>
                  <mat-select formControlName="smart_acceleration">
                    <mat-option
                      *ngFor="let v of smartAccelerations$ | async"
                      [value]="v"
                    >
                      {{ v }}
                    </mat-option>
                  </mat-select>
                  <span matSuffix>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('smart_acceleration'); $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Approach distance</mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    max="999999"
                    formControlName="approach_distance"
                  />
                  <mat-error>Min: 0</mat-error>
                  <span matSuffix>
                    <span matTextSuffix>mm</span>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('approach_distance'); $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Above pick position</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="above_pick_position_mm"
                  />
                  <span matSuffix>
                    <span matTextSuffix>mm</span>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('above_pick_position_mm');
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>
                <mat-slide-toggle formControlName="single_pallet"
                  >Palletize single pallet</mat-slide-toggle
                >
              </div>
              <div class="content-column">
                <mat-form-field appearance="fill">
                  <mat-label>High approach boost</mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    max="100"
                    formControlName="high_approach_boost_percentage"
                  />
                  <mat-error>Min: 0, Max: 100</mat-error>
                  <span matSuffix>
                    <span matTextSuffix>%</span>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('high_approach_boost_percentage');
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>Fixed approach</mat-label>
                  <mat-select formControlName="fixed_approach">
                    <mat-option
                      *ngFor="let v of fixedApproaches$ | async"
                      [value]="v"
                    >
                      {{ v }}
                    </mat-option>
                  </mat-select>
                  <span matSuffix>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('fixed_approach'); $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Box free height</mat-label>
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    matInput
                    formControlName="box_free_height"
                  />
                  <mat-error>Min: 0, Max: 1000</mat-error>
                  <span matSuffix>
                    <span matTextSuffix>mm</span>
                    <button
                      class="infoPopupButton"
                      (click)="
                        showInfo('box_free_height'); $event.stopPropagation()
                      "
                    >
                      <mat-icon svgIcon="info"></mat-icon>
                    </button>
                  </span>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </section>
      </app-edit-page>

      <app-edit-page [bottomDivider]="false">
        <h3 app-heading>Smart exits</h3>
        <section app-content class="fields-section">
          <mat-expansion-panel
            (opened)="expandedTab = 'smart_exits'; initializedPanel = true"
            [expanded]="expandedTab === 'smart_exits'"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Edit smart exits</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-panel" formGroupName="smart_exits">
              <mat-slide-toggle
                formControlName="smart_exits_override"
                matTooltip="If not overriden, the simulation will use the standard smart exit values given by the conveyor direction."
                (change)="toggleSmartExitsOverride($event.checked)"
              >
                Smart exits override
              </mat-slide-toggle>
              <app-config-preset-picker
                *ngIf="initializedPanel"
                app-content
                [disableAdd]="true"
                [presetType]="'smart exit'"
                [presets]="smartExits$ | async"
                [disableCards]="!smartExitsOverrideState"
                (pickerSelect)="updateSmartExits($event)"
              ></app-config-preset-picker>
              <div class="panel-content-wrapper">
                <div class="content-column" formGroupName="left_pallet">
                  <span formGroupName="x">
                    <mat-form-field appearance="fill">
                      <mat-label>Left pallet X from</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        formControlName="from"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('left_x_from')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Left pallet X to</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        formControlName="to"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('left_x_to')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                  </span>
                  <span formGroupName="y">
                    <mat-form-field appearance="fill">
                      <mat-label>Left pallet Y from</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        formControlName="from"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('left_y_from')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Left pallet Y to</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        formControlName="to"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('left_y_to')"
                      >
                        <mat-icon color="accent" class="rotate">
                          published_with_changes
                        </mat-icon>
                      </span>
                    </mat-form-field>
                  </span>
                </div>
                <div class="content-column" formGroupName="right_pallet">
                  <span formGroupName="x">
                    <mat-form-field appearance="fill">
                      <mat-label>Right pallet X from</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        formControlName="from"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('right_x_from')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Right pallet X to</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        matInput
                        formControlName="to"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('right_x_to')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                  </span>
                  <span formGroupName="y">
                    <mat-form-field appearance="fill">
                      <mat-label>Right pallet Y from</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        matInput
                        formControlName="from"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('right_y_from')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Right pallet Y to</mat-label>
                      <input
                        matInput
                        type="number"
                        min="-1000"
                        max="1000"
                        matInput
                        formControlName="to"
                      />
                      <mat-error>Min: -1000, Max: 1000</mat-error>
                      <span matTextSuffix>mm</span>
                      <span
                        matPrefix
                        style="padding: 10px"
                        *ngIf="changedFields.includes('right_y_to')"
                      >
                        <mat-icon color="accent" class="rotate"
                          >published_with_changes</mat-icon
                        >
                      </span>
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </section>
      </app-edit-page>
    </form>
  </ng-container>
  <ng-template #loading>
    <div class="page-about-wrapper">
      <app-loading style="margin-top: 4em"></app-loading>
    </div>
  </ng-template>
</app-page>

<ng-container *ngIf="isDialog; else pageActions">
  <div class="dialog-actions-container">
    <app-dialog-bottom-navbar
      title="Robot configuration"
      primaryButton="confirm"
      [secondaryButton]="'cancel'"
      (nextClick)="saveSoftwareConfiguration()"
      (backClick)="closeDialog()"
    ></app-dialog-bottom-navbar>
  </div>
</ng-container>
<ng-template #pageActions>
  <app-wizard-bottom-navbar
    *ngIf="!this.presetStrategy"
    [title]="'Software configuration'"
    [primaryButton]="'confirm'"
    (nextClick)="saveSoftwareConfiguration()"
  ></app-wizard-bottom-navbar>
</ng-template>
