import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { pallyVersionBackground } from 'src/app/models_new/enums/pally-background';

@Component({
  selector: 'app-selectable-card',
  templateUrl: './selectable-card.component.html',
  styleUrls: ['./selectable-card.component.scss'],
})
export class SelectableCardComponent {
  @Input() selected: boolean;
  @Input() tagline: string;
  @Input() label: string;
  @Input() image: string;
  @Input() renderVersionNo: string;
  @Input() disable: boolean = false;
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter();

  pallyVersionBackground = pallyVersionBackground;

  constructor(private renderer: Renderer2) {}

  startMarqueeOverflow(parent: HTMLElement, target: HTMLElement) {
    let left = target.scrollWidth - parent.clientWidth;
    this.renderer.setStyle(target, 'margin-left', '-' + left + 'px');
  }

  stopMarqueeOverflow(target: HTMLElement) {
    this.renderer.setStyle(target, 'margin-left', '0px');
  }
}
