import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ILandingOnboardContentful } from 'src/app/models_new/types/landing-contentful-data';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import { ViewEncapsulation } from '@angular/core';
import { style, transition, trigger, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { Router } from '@angular/router';
import { Validators, UntypedFormControl } from '@angular/forms';
import { SupportWidgetService } from 'src/app/services/support-widget.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CaptchaDialogComponent } from '../dialogs/captcha-dialog/captcha-dialog.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('blur', [
      transition('false=>true', [
        style({ opacity: 0 }),
        animate('150ms', style({ filter: 'blur(0)', opacity: 1 })),
      ]),
      transition('true=>false', [
        animate('10ms', style({ filter: 'blur(1.5em)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class LandingComponent implements OnInit, AfterViewInit, OnDestroy {
  landingContent$: Observable<DataRequestState<ILandingOnboardContentful>>;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(_) {
    if (window.scrollY > 2) {
      document.getElementById('main-toolbar')?.classList.add('scrolled');
    } else {
      document.getElementById('main-toolbar')?.classList.remove('scrolled');
    }
  }

  // Index
  userGroup: {
    index: number;
    nextIndex?: number;
    toggle?: boolean;
    learnMoreIndex?: number;
    learnMoreToggle?: boolean;
  } = {
    index: 0,
    toggle: false,
    learnMoreToggle: false,
  };
  carousel: {
    index: number;
    nextIndex?: number;
    toggle?: boolean;
    isTouched?: boolean;
    length?: number;
  } = {
    index: 0,
    toggle: false,
  };
  contactForm: UntypedFormControl;

  // TODO: Remove once final version of new Landing Page is deployed.
  public isDevelopment = environment.development;

  constructor(
    private router: Router,
    private publicApi: PublicApiService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
    private pipedriveService: PipedriveService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private supportWidgetService: SupportWidgetService
  ) {
    this.contactForm = new UntypedFormControl('', Validators.email);
  }

  ngOnInit(): void {
    this.supportWidgetService.hideSupportButton();
    this.landingContent$ = this.publicApi.getLandingContent().pipe(
      tap(
        (content) => (this.carousel.length = content?.header?.images?.length)
      ),
      toRequestState()
    );
  }

  ngAfterViewInit(): void {
    this.carouelAutoPlay();
  }

  ngOnDestroy(): void {
    this.supportWidgetService.resetSupportButtonStyling();
  }

  /**
   * Converts number input into star-icons to represent valorations.
   * @param valoration Number (0-5)
   * @returns String[]
   */
  public getValorationIcons(valoration: number): string[] {
    let result = [];
    const stringifiedVal = valoration.toString().split('.');
    for (let i = 0; i < Number(stringifiedVal[0]); i++) {
      result.push('star');
    }
    if (stringifiedVal[1]) {
      result.push('star_half');
    }
    while (result.length < 5) {
      result.push('star_border');
    }
    return result;
  }

  /**
   * Handles input actions from template buttons.
   * @param actionId
   * @param value
   */
  public handleAction(actionId: string, value?: any): void {
    const url =
      value instanceof Array ? this.getActionUrl(actionId, value) : null;
    if (url) {
      window.open(url, '_blank');
    } else if (actionId === 'fast-track') {
      this.router.navigate([pagesPATH.FAST_TRACK_PRODUCT]);
    } else if (actionId === 'contact-me') {
      if (!value?.errors) this.contactFormCaptcha(value.value);
    } else if (actionId === 'contact-form') {
      this.auth.user$.pipe(take(1)).subscribe((usr) => {
        if (!usr) {
          this.auth.loginWithRedirect({
            authorizationParams: {
              initialScreen: 'signUp',
            },
          });
        } else {
          this.router.navigate([pagesPATH.USER, pagesPATH.USER_SIGN_UP]);
        }
      });
    } else {
      console.warn('No action available for actionId:', actionId);
    }
  }

  /**
   * Keeps rotating the Carousel's images until user interacts.
   */
  private carouelAutoPlay(): void {
    const carouselPlay = setInterval(() => {
      if (!this.carousel.isTouched) {
        this.carousel.nextIndex =
          this.carousel.index + 1 === this.carousel.length
            ? 0
            : this.carousel.index + 1;
        this.carousel.toggle = false;
      } else {
        clearInterval(carouselPlay);
      }
    }, 4000);
  }

  /**
   * Returns the matching Action-id URL given from contentful.
   * @param actionId
   * @param contentfulLinks
   * @returns string || null
   */
  private getActionUrl(
    actionId: string,
    contentfulLinks: { id: string; url: string }[]
  ): string {
    return contentfulLinks?.find((entry) => entry.id === actionId)?.url || null;
  }

  /**
   * Prompts a Captcha check for the user to resolve. Triggers postPipedriveContactMe() if succeeded.
   * @param formContent :string
   */
  private contactFormCaptcha(formContent: string): void {
    this.dialogService
      .showCustomDialog(
        CaptchaDialogComponent,
        DialogSize.SMALL,
        null,
        null,
        true
      )
      .afterClosed()
      .pipe(
        switchMap((validated) => {
          if (validated) {
            return this.pipedriveService.postPipedriveContactMe(formContent);
          } else return of(null);
        })
      )
      .subscribe((succeed) => {
        if (succeed) {
          this.notificationService.showSuccess(
            `Request successfully submitted!`
          );
          this.contactForm = new UntypedFormControl('', Validators.email);
        } else {
          this.notificationService.showError(
            `Couldn't verify the Captcha. Please try again.`
          );
        }
      });
  }
}
