export class ValueValidator {
  /**
   * @param values as array
   * @param valid array of valid values
   */
  static isValue(values: any[], valid: any[]) {
    let err = false;

    values.forEach((value) => {
      if (!valid.includes(value)) {
        err = true;
      }
    });

    return !err ? true : `Valid values are: ${valid.join(', ')}`;
  }

  /**
   * @param value: string
   * @param regex: string
   * @param regexAsReadable: string(optional)
   */
  static regex(
    value: string,
    regex: string,
    regexAsReadable?: string
  ): boolean | string {
    value = value ? value.toString() : '';
    const match = value.match(regex);

    const rule = regexAsReadable ? regexAsReadable : regex;

    return match ? true : `Allowed characters are: ${rule}`;
  }

  /**
   * @param value: string
   * @param min: number
   */
  static minLength(value: string, min: number): boolean | string {
    return value && value.length >= min ? true : `Min length allowed ${min}`;
  }

  /**
   * @param value: string
   * @param max: number
   */
  static maxLength(value: string, max: number): boolean | string {
    return !value || value.length <= max ? true : `Max length allowed: ${max}`;
  }

  /**
   * @param value: number
   * @param min: number
   */
  static minNumber(value: number, min: number): boolean | string {
    return value >= min ? true : `Min value allowed: ${min}`;
  }

  /**
   * @param value: number
   * @param max: number
   */
  static maxNumber(value: number, max: number): boolean | string {
    return value <= max ? true : `Max value allowed: ${max}`;
  }

  /**
   * @param value: number
   * @param valid: number[]
   */
  static validDecimals(value: number, valid: number[]): boolean | string {
    let validDec = false;
    let i = 0;

    while (i < valid.length) {
      if (value % 1 === valid[i]) {
        validDec = true;
      }
      i++;
    }

    return validDec ? validDec : `Allowed decimals: ${valid.join(' and ')}`;
  }

  /**
   * @param value: number
   * @param max: number
   */
  static maxDecimals(value: number, max: number): boolean | string {
    const toString = value ? value.toString() : '';
    if (toString.split('.')[1]) {
      return toString.split('.')[1].length <= max
        ? toString.split('.')[1].length <= max
        : `Max decimals allowed: ${max}`;
    } else {
      return true;
    }
  }
}
