import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-software-dialog',
  templateUrl: './edit-software-dialog.component.html',
  styleUrls: ['./edit-software-dialog.component.scss'],
})
export class EditSoftwareDialogComponent implements OnInit {
  public configId: string;

  constructor(
    public dialogRef: MatDialogRef<EditSoftwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private input: {
      data: { sw_id: string; org_id: string };
    }
  ) {}

  ngOnInit(): void {
    this.configId = this.input.data?.sw_id || 'new';
  }

  closeDialog(event: any) {
    this.dialogRef.close(event);
  }
}
