import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { Observable, of, skipWhile, switchMap, tap } from 'rxjs';
import { pagesPATH } from '../models_new/config/pages';
import { RoleApiService } from '../services/api/role-api.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private auth: AuthService,
    private roleApi: RoleApiService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      skipWhile((user: User) => !user),
      switchMap((auth0User: User) => {
        const auth0RoleValid = auth0User[
          'https://login.myrobot.cloud/roles'
        ].includes(route.data.role);

        if (route.data.strictRF && auth0RoleValid) {
          return this.roleApi.strictRf(auth0User);
        } else {
          return of(auth0RoleValid);
        }
      }),
      tap((accept: boolean) => {
        if (!accept) {
          this.router.navigate([pagesPATH.LANDING_PAGE]);
        }
      })
    );
  }
}
