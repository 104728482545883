import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as THREE from 'three';
import { URDFJoint, URDFLink } from '@rocketfarm/urdf-loader/src/URDFClasses';
import { PartType } from '../models_new/enums/sim-config-part-type';

export enum JointNames {
  WorldFrame = 'world_frame',
  FrameLiftkit = 'frame_liftkit',
  LiftkitBasebracket = 'liftkit_basebracket',
  BasebracketRobotbase = 'basebracket_robotbase',
  ToolmountOffsetBracket = 'toolmount_offsetbracket',
  OffsetBracketGripper = 'offsetbracket_gripper',
  PalletRight = 'pallet_right',
  PalletRightLip = 'pallet_right_lip',
  PalletLeft = 'pallet_left',
  PalletLeftLip = 'pallet_left_lip',
  WorldConveyor = 'world_conveyor',
  ConveyorBoxFreeHeight = 'conveyor_boxFreeHeight_joint',
  ConveyorGuideOffsetX = 'conveyor_guide_xoffset',
  ConveyorGuide = 'conveyor_guide',
  ConveyorBox = 'conveyor_box',
  Tool0 = 'tool0',
  SceneJoint = 'world_pally_joint',
}
export enum LinkNames {
  World = 'world',
  Frame = 'frame',
  Basebracket = 'base_bracket',
  RobotWrist3 = 'wrist_3',
  RobotTool0 = 'tool0',
  OffsetBracket = 'offset_bracket',
  Gripper = 'gripper',
  ConveyorMock = 'conveyor_mock',
  ConveyorBoxFreeHeight = 'conveyor_boxFreeHeight',
  ConveyorGuideOffsetX = 'guide_offsetx',
  ConveyorGuide = 'guide',
  ConveyorBox = 'conveyor_box',
  PalletRight = 'p1',
  PalletRightLip = 'p1_lip',
  PalletLeft = 'p2',
  PalletLeftlip = 'p2_lip',
  Scene = 'pally',
}

@Injectable({
  providedIn: 'root',
})
export class ProjectRobotDescriptorService implements OnDestroy {
  connection_joints: Map<string, URDFJoint> = new Map<string, URDFJoint>();
  connection_links: Map<string, URDFLink> = new Map<string, URDFLink>();
  partType: Map<string, PartType> = new Map<string, PartType>();
  root: THREE.Object3D;

  mapped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor() {}

  ngOnDestroy(): void {
    this.connection_joints.clear();
    this.connection_links.clear();
    this.partType.clear();
    this.root = undefined;
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setRootObject(root: THREE.Object3D): void {
    this.root = root;
  }

  getJointByID(id: string): URDFJoint {
    return this.connection_joints.get(id);
  }

  getJointIDs(): string[] {
    return Array.from(this.connection_joints.keys());
  }

  getJoints(): Map<string, URDFJoint> {
    return this.connection_joints;
  }

  setJoint(id: string, joint: URDFJoint, type: PartType): void {
    this.connection_joints.set(id, joint);
    this.partType.set(id, type);
  }

  removePartByName(name: string): void {
    this.connection_joints.delete(name);
    this.partType.delete(name);
  }

  getPartTypeByID(id: string): PartType {
    return this.partType.get(id);
  }

  getPartTypes(): Map<string, PartType> {
    return this.partType;
  }

  getLinkByID(id: string): URDFLink {
    return this.connection_links.get(id);
  }

  getLinkIDs(): string[] {
    return Array.from(this.connection_links.keys());
  }

  getLinks(): Map<string, URDFLink> {
    return this.connection_links;
  }

  setLink(id: string, link: URDFLink, type: PartType = PartType.NONE): void {
    this.connection_links.set(id, link);
    this.partType.set(id, type);
  }

  clear(): void {
    this.connection_joints.clear();
    this.connection_links.clear();
    this.partType.clear();
    this.setRootObject(new THREE.Object3D());
    this.mapped$.next(false);
  }

  mapRobotStructure(robot: THREE.Object3D): void {
    this.clear();
    this.setRootObject(robot);
    robot.traverse((child) => {
      for (const joint of this.joints) {
        if (joint.name === child.name) {
          this.setJoint(joint.tag, child as URDFJoint, joint.type);
          // Hide pallet lips by default
          if (
            joint.tag === JointNames.PalletLeftLip ||
            joint.tag === JointNames.PalletRightLip
          ) {
            (child as URDFJoint).visible = false;
          }
        }
      }
      for (const link of this.links) {
        if (link.name === child.name) {
          this.setLink(link.tag, child as URDFLink);
        }
      }
    });
    this.mapped$.next(true);
  }

  readonly joints = [
    {
      name: 'world_frame_joint',
      tag: JointNames.WorldFrame,
      type: PartType.FRAME,
    },
    {
      name: 'frame_liftkit_joint',
      tag: JointNames.FrameLiftkit,
      type: PartType.LIFTKIT,
    },
    {
      name: 'liftkit_basebracket_joint',
      tag: JointNames.LiftkitBasebracket,
      type: PartType.BASE_BRACKET,
    },
    {
      name: 'bracket_base_link_joint',
      tag: JointNames.BasebracketRobotbase,
      type: PartType.ROBOT,
    },
    {
      name: 'tool0_offset_bracket_joint',
      tag: JointNames.ToolmountOffsetBracket,
      type: PartType.OFFSET_BRACKET,
    },
    {
      name: 'gripper_joint',
      tag: JointNames.OffsetBracketGripper,
      type: PartType.GRIPPER,
    },
    { name: 'p1_joint', tag: JointNames.PalletRight, type: PartType.PALLET },
    {
      name: 'p1_lip_joint',
      tag: JointNames.PalletRightLip,
      type: PartType.PALLET_LIP,
    },
    { name: 'p2_joint', tag: JointNames.PalletLeft, type: PartType.PALLET },
    {
      name: 'p2_lip_joint',
      tag: JointNames.PalletLeftLip,
      type: PartType.PALLET_LIP,
    },
    {
      name: 'world_joint_conveyor',
      tag: JointNames.WorldConveyor,
      type: PartType.CONVEYOR,
    },
    {
      name: 'conveyor_boxFreeHeight_joint',
      tag: JointNames.ConveyorBoxFreeHeight,
      type: PartType.NONE,
    },
    {
      name: 'conveyor_guide_x_offset_joint',
      tag: JointNames.ConveyorGuideOffsetX,
      type: PartType.NONE,
    },
    {
      name: 'conveyor_guide_joint',
      tag: JointNames.ConveyorGuide,
      type: PartType.CONVEYOR_GUIDE,
    },
    {
      name: 'conveyor_box_joint',
      tag: JointNames.ConveyorBox,
      type: PartType.NONE,
    },
    { name: 'wrist_3_link', tag: JointNames.Tool0, type: PartType.NONE },
    {
      name: 'world_pally_joint',
      tag: JointNames.SceneJoint,
      type: PartType.SCENE,
    },
  ];

  readonly links = [
    { name: 'simconfig_view_robot', tag: LinkNames.World },
    { name: 'base_bracket', tag: LinkNames.Basebracket },
    { name: 'wrist_3_link', tag: LinkNames.RobotWrist3 },
    { name: 'tool0', tag: LinkNames.RobotTool0 },
    { name: 'offset_bracket', tag: LinkNames.OffsetBracket },
    { name: 'gripper', tag: LinkNames.Gripper },
    { name: 'conveyor_mock', tag: LinkNames.ConveyorMock },
    { name: 'conveyor_boxFreeHeight', tag: LinkNames.ConveyorBoxFreeHeight },
    { name: 'guide_offsetx', tag: LinkNames.ConveyorGuideOffsetX },
    { name: 'guide', tag: LinkNames.ConveyorGuide },
    { name: 'conveyor_box', tag: LinkNames.ConveyorBox },
    { name: 'p1', tag: LinkNames.PalletRight },
    { name: 'p1_lip', tag: LinkNames.PalletRightLip },
    { name: 'p2', tag: LinkNames.PalletLeft },
    { name: 'p2_lip', tag: LinkNames.PalletLeftlip },
    { name: 'pally_link', tag: LinkNames.Scene },
  ];
}
