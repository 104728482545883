<div class="captcha-dialog-wrapper">
  <div class="captcha-dialog-title">
    <h2>Please verify you are not a robot</h2>
  </div>
  <div class="captcha-dialog-challange">
    <form [formGroup]="reCaptchaCheck">
      <ngx-recaptcha2
        #captchaElem
        [siteKey]="siteKey"
        formControlName="recaptcha"
        (success)="onSuccess()"
      >
      </ngx-recaptcha2>
    </form>
  </div>
  <div *ngIf="isLoggable" class="captcha-dialog-challange-disclaimer">
    <i>Log-in to skip this step in the future</i>
  </div>

  <div class="captcha-dialog-actions">
    <button
      mat-button
      color="warn"
      (click)="doClose()"
      [disabled]="reCaptchaCheck?.valid"
    >
      <mat-icon>close</mat-icon>
      Close
    </button>
  </div>
</div>
