import { getRotation } from '../config/labels-out-rotation-config';
import { BoxRotation } from '../enums/box-rotation';
import { LabelsOutFreeDirection } from '../enums/labels-out-free-direction';
import { Box } from './box';
import { Pallet } from './pallet';

interface IBoxCorners {
  topLeft: { x: number; y: number };
  topRight: { x: number; y: number };
  bottomLeft: { x: number; y: number };
  bottomRight: { x: number; y: number };
}

interface IBoxWithWorldView {
  hasWorldView: boolean;
  blocking: Box[][];
  freeDirection: string[];
  blockingDirection: string[];
  box?: Box;
}

/**
 * Should force lock direction to achieve this
 */

export class BoxOrientor {
  private boxes: Box[];
  private freeBoxes: IBoxWithWorldView[];
  private blockedBoxes: IBoxWithWorldView[];

  constructor(boxes: Box[], palletDimensions: Pallet['dimensions']) {
    this.boxes = boxes;
    this.freeBoxes = [];
    this.blockedBoxes = [];

    this.boxes.forEach((b: Box, index: number) => {
      // Determine which boxes have world view.
      const worldView = this.hasWorldView(b, index);

      /**
       * if box:
       *
       * is rotated
       * has label orientation left or right
       * has free directions left or right
       * has NOT free direction top or bottom
       *
       * Set no free direction
       */
      if (
        (b.rotation.includes(90) || b.rotation.includes(270)) &&
        (b.labelOrientations.includes(0) ||
          b.labelOrientations.includes(180)) &&
        (worldView.freeDirection.includes(LabelsOutFreeDirection[2]) ||
          worldView.freeDirection.includes(LabelsOutFreeDirection[3])) &&
        !worldView.freeDirection.includes(LabelsOutFreeDirection[0]) &&
        !worldView.freeDirection.includes(LabelsOutFreeDirection[1])
      ) {
        worldView.hasWorldView = false;
      }

      // Checks which side of pallet the box is on. E.q more on the left side than on right.
      this.checkForMultipleOppositeFree(b, worldView, palletDimensions);

      if (worldView.hasWorldView) {
        this.freeBoxes.push({ box: b, ...worldView });
      } else {
        this.blockedBoxes.push({ box: b, ...worldView });
      }
    });

    // Unlock direction for blocked boxes
    this.blockedBoxes.forEach((b: IBoxWithWorldView) => {
      const newDirections =
        b.box.rotation[0] === BoxRotation.ZERO ||
        b.box.rotation[0] === BoxRotation.TWO
          ? [BoxRotation.ZERO, BoxRotation.TWO]
          : [BoxRotation.ONE, BoxRotation.THREE];
      b.box.setRotation(newDirections);
    });

    // Unlock direction on free boxes.
    this.freeBoxes.forEach((b: IBoxWithWorldView) => {
      const newDirections =
        b.box.rotation[0] === BoxRotation.ZERO ||
        b.box.rotation[0] === BoxRotation.TWO
          ? [BoxRotation.ZERO, BoxRotation.TWO]
          : [BoxRotation.ONE, BoxRotation.THREE];
      b.box.setRotation(newDirections);
    });

    // Rotate label outwards on free boxes.
    this.freeBoxes.forEach((b: IBoxWithWorldView) => this.rotateByLabel(b));
  }

  private rotateByLabel(b: IBoxWithWorldView) {
    const boxLabelOrientations = b.box.labelOrientations[0];
    const boxRotation: number = b.box.rotation[0];
    const boxFreeDirections: string[] = b.freeDirection;
    const isRotated = boxRotation === BoxRotation.ZERO ? false : true;

    const lockDirection = (b1: IBoxWithWorldView) => {
      b1.box.setRotation([b1.box.rotation[0]]);
      b1.box.setLabelOrientations([b1.box.labelOrientations[0]]);
    };

    const rotate = (b3, goal) => {
      lockDirection(b3);
      let turns = 0;
      while (!b3.box.rotation.includes(goal) && turns < 4) {
        b3.box.setRotation([b3.box.rotation[0] + 90]);
        turns++;
      }
    };

    // Get rotation from config
    const rotateTo = getRotation(
      isRotated,
      boxLabelOrientations,
      boxFreeDirections
    );

    // Lock and rotate box
    if (rotateTo !== undefined) {
      rotate(b, rotateTo);
    }
  }

  public getBoxes() {
    return this.boxes;
  }

  private hasWorldView(b: Box, boxIndex: number): IBoxWithWorldView {
    const otherBoxes: Box[] = this.boxes.filter(
      (f: Box, idx: number) => idx !== boxIndex
    );

    const XoverLap = (b1: Box, b2: Box) => {
      const b1Corners: IBoxCorners = this.getBoxCorners(b1, b1.rotation[0]);
      const b2Corners: IBoxCorners = this.getBoxCorners(b2, b2.rotation[0]);

      if (
        b1Corners.bottomLeft.x === b2Corners.bottomLeft.x ||
        b1Corners.bottomRight.x === b2Corners.bottomRight.x
      ) {
        return true;
      } else {
        return (
          (b2Corners.bottomRight.x > b1Corners.bottomLeft.x &&
            b2Corners.bottomLeft.x < b1Corners.bottomLeft.x) ||
          (b2Corners.bottomLeft.x < b1Corners.bottomRight.x &&
            b2Corners.bottomRight.x > b1Corners.bottomRight.x)
        );
      }
    };

    const YoverLap = (b1: Box, b2: Box) => {
      const b1Corners: IBoxCorners = this.getBoxCorners(b1, b1.rotation[0]);
      const b2Corners: IBoxCorners = this.getBoxCorners(b2, b2.rotation[0]);

      if (
        b1Corners.topRight.y === b2Corners.topRight.y ||
        b1Corners.topLeft.y === b2Corners.topLeft.y
      ) {
        return true;
      } else {
        return (
          (b2Corners.topRight.y > b1Corners.bottomRight.y &&
            b2Corners.topRight.y < b1Corners.topRight.y) ||
          (b2Corners.bottomRight.y > b1Corners.bottomRight.y &&
            b2Corners.bottomRight.y < b1Corners.topRight.y) ||
          (b2Corners.topRight.y > b1Corners.topRight.y &&
            b2Corners.bottomRight.y <= b1Corners.bottomRight.y)
        );
      }
    };

    const isOver = (b1: Box, b2: Box) => {
      return b2.getPosition().y > b1.getPosition().y;
    };

    const isUnder = (b1: Box, b2: Box) => {
      return b2.getPosition().y < b1.getPosition().y;
    };

    const isLeft = (b1: Box, b2: Box) => {
      const b1Corners: IBoxCorners = this.getBoxCorners(b1, b1.rotation[0]);
      const b2Corners: IBoxCorners = this.getBoxCorners(b2, b2.rotation[0]);
      return b2Corners.bottomLeft.x < b1Corners.bottomLeft.x;
    };

    const isRight = (b1: Box, b2: Box) => {
      const b1Corners: IBoxCorners = this.getBoxCorners(b1, b1.rotation[0]);
      const b2Corners: IBoxCorners = this.getBoxCorners(b2, b2.rotation[0]);
      return b2Corners.bottomRight.x > b1Corners.bottomRight.x;
    };

    const aboveBoxes = otherBoxes
      .filter((f1: Box) => isOver(b, f1))
      .filter((f2: Box) => XoverLap(b, f2));

    const belowBoxes = otherBoxes
      .filter((f1: Box) => isUnder(b, f1))
      .filter((f2: Box) => XoverLap(b, f2));

    const outsideLeft = otherBoxes
      .filter((f1: Box) => isLeft(b, f1))
      .filter((f2: Box) => YoverLap(b, f2));

    const outsideRight = otherBoxes
      .filter((f1: Box) => isRight(b, f1))
      .filter((f2: Box) => YoverLap(b, f2));

    const otherBoxesInWorld = [
      aboveBoxes,
      belowBoxes,
      outsideLeft,
      outsideRight,
    ];
    const blockingBoxes = (arr) => arr.length === 0;

    return {
      blocking: otherBoxesInWorld.filter((f) => f.length),
      blockingDirection: otherBoxesInWorld.map((f, i) => {
        if (f.length) {
          return LabelsOutFreeDirection[i];
        }
        return null;
      }),
      freeDirection: otherBoxesInWorld.map((f, i) => {
        if (!f.length) {
          return LabelsOutFreeDirection[i];
        }
        return null;
      }),
      hasWorldView: otherBoxesInWorld.some(blockingBoxes),
    };
  }

  private getBoxCorners(b: Box, boxRotation: BoxRotation): IBoxCorners {
    if (boxRotation === BoxRotation.ZERO || boxRotation === BoxRotation.TWO) {
      return {
        topLeft: {
          x: b.getPosition().x - b.getDimensions().width / 2,
          y: b.getPosition().y + b.getDimensions().length / 2,
        },
        topRight: {
          x: b.getPosition().x + b.getDimensions().width / 2,
          y: b.getPosition().y + b.getDimensions().length / 2,
        },
        bottomLeft: {
          x: b.getPosition().x - b.getDimensions().width / 2,
          y: b.getPosition().y - b.getDimensions().length / 2,
        },
        bottomRight: {
          x: b.getPosition().x + b.getDimensions().width / 2,
          y: b.getPosition().y - b.getDimensions().length / 2,
        },
      };
    } else {
      return {
        topLeft: {
          x: b.getPosition().x - b.getDimensions().length / 2,
          y: b.getPosition().y + b.getDimensions().width / 2,
        },
        topRight: {
          x: b.getPosition().x + b.getDimensions().length / 2,
          y: b.getPosition().y + b.getDimensions().width / 2,
        },
        bottomLeft: {
          x: b.getPosition().x - b.getDimensions().length / 2,
          y: b.getPosition().y - b.getDimensions().width / 2,
        },
        bottomRight: {
          x: b.getPosition().x + b.getDimensions().length / 2,
          y: b.getPosition().y - b.getDimensions().width / 2,
        },
      };
    }
  }

  private checkForMultipleOppositeFree(
    b: Box,
    worldView: IBoxWithWorldView,
    palletDimensions: Pallet['dimensions']
  ) {
    // If both top and buttom or right and left is free directions, choose based on pallet posisiton.
    // Since 'TOP' and/or 'LEFT' is favorised already, skip these scenarios
    // Favorisation is based on array index. First one is favorised.

    if (
      worldView.freeDirection.includes(LabelsOutFreeDirection[0]) &&
      worldView.freeDirection.includes(LabelsOutFreeDirection[1])
    ) {
      // box is under half of pallet length, move to array front
      if (b.getPosition().y < palletDimensions.length / 2) {
        // We know that 'BOTTOM' is at index 1.
        // Remove 'TOP' from index 0
        worldView.freeDirection.splice(0, 1);
      }
    }
    if (
      worldView.freeDirection.includes(LabelsOutFreeDirection[2]) &&
      worldView.freeDirection.includes(LabelsOutFreeDirection[3])
    ) {
      // box is over half of pallet width, move to array front
      if (b.getPosition().x > palletDimensions.width / 2) {
        // We know that 'RIGHT' is at index 3.
        // Remove 'LEFT' from index 2
        worldView.freeDirection.splice(2, 1);
      }
    }
  }
}
