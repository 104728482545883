import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { ApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { RobotConfigApiService } from 'src/app/services/api/robot-config-api.service';

@Component({
  selector: 'app-config-overview',
  templateUrl: './config-overview.component.html',
  styleUrls: ['./config-overview.component.scss'],
})
export class ConfigOverviewComponent implements OnDestroy {
  sceneId$: Observable<string>;
  robotConfiguration$: Observable<ApiRobotConfiguration>;
  @Input() hardwareTouched = false;
  @Input() strategyTouched = false;
  @ViewChild('sceneFileInput') sceneFileInput: ElementRef;
  nameInputKeyup$ = new Subject<string>();
  destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private robotConfigService: RobotConfigApiService
  ) {
    // TODO PALLY-2342 remove/simplify workaround after bugfix
    this.robotConfiguration$ = combineLatest([
      this.activatedRoute.params,
      this.stateService.getCustomerOrSalesOrganization().pipe(take(1)),
    ]).pipe(
      switchMap(([params, organization]) =>
        this.robotConfigService
          .fetchRobotconfigs(organization.id)
          .pipe(
            map((robotConfigs) =>
              robotConfigs.find(
                (config) => config.id === params.robotConfigurationId
              )
            )
          )
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    combineLatest([
      this.robotConfiguration$.pipe(take(1)),
      this.nameInputKeyup$.pipe(
        filter(Boolean),
        debounceTime(150),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(([rConfig, name]) =>
          this.robotConfigService.updateRobotConfig(
            rConfig.id,
            name,
            rConfig.scene.id,
            rConfig.strategy.id
          )
        )
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectSceneFile() {
    this.sceneFileInput.nativeElement.click();
  }
}
