import { ProjectDataNavItem } from '../enums/project-data-nav-item';

export interface IStepperStep {
  value: string;
  name: string;
  step: string;
  id: number;
  img?: string;
}

export const getProjectDataNavImg = (navIndex: number, imgIndex: number) => {
  const imgs = [
    {
      imgs: [
        '../../../assets/illustrative/Box_dimentions_all.png',
        '../../../assets/illustrative/Box_dimentions_lenght.png',
        '../../../assets/illustrative/Box_dimentions_width.png',
        '../../../assets/illustrative/Box_dimentions_height.png',
      ],
    },
    {
      imgs: ['../../../assets/illustrative/Pallet_dimensions.png'],
    },
    {
      imgs: ['../../../assets/illustrative/Box_padding.png'],
    },
  ];

  return imgs[navIndex].imgs[imgIndex];
};

export const projectDataNavs: IStepperStep[] = [
  {
    step: '01 Box',
    value: ProjectDataNavItem.PRODUCT,
    name: 'Package data',
    id: 0,
  },
  {
    step: '02 Pallet',
    value: ProjectDataNavItem.PALLET,
    name: 'Pallet data',
    id: 1,
  },
  {
    step: '03 Layers',
    value: ProjectDataNavItem.PALLETIZING,
    name: '3D Pallet',
    id: 2,
  },
];

export const modelUploadNavs: IStepperStep[] = [
  {
    step: '01 Upload file',
    value: ProjectDataNavItem.PRODUCT,
    name: 'Upload file',
    id: 0,
  },
  {
    step: '02 Position',
    value: ProjectDataNavItem.PALLET,
    name: 'Position',
    id: 1,
  },
  {
    step: '03 Generate Configs',
    value: ProjectDataNavItem.PALLETIZING,
    name: 'Summary',
    id: 2,
  },
];
