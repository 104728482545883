import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { ThreeViewContent } from '../../../../../../../models_new/classes/3dview/three-content';
import { New_ThreeViewComponent } from '../../../../three-view/three-view.component';

@Component({
  selector: 'app-ambient-light',
  templateUrl: './ambient-light.component.html',
  styleUrls: ['./ambient-light.component.scss'],
})
export class AmbientLightComponent
  extends ThreeViewContent
  implements OnChanges
{
  @Input() color = '#ffffff';
  @Input() intensity = 1;

  light = new THREE.AmbientLight(this.color, this.intensity);

  constructor(threeView: New_ThreeViewComponent) {
    super(threeView);
    this.scene.add(this.light);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color?.currentValue) {
      this.light.color = changes.color.currentValue;
    }
    if (changes.intensity?.currentValue) {
      this.light.intensity = changes.intensity.currentValue;
    }
  }
}
