import * as THREE from 'three';
import { ThreeUtils } from '../../../utils/three-utils';

/**
 * Shared create method for both the Sticker and InstancedSticker class
 */
function create(texture: THREE.Texture, size = 1) {
  if (!texture.image) {
    throw new Error('Texture not initialized with image yet.');
  }

  const imageWidth = texture.image.width;
  const imageHeight = texture.image.height;

  const ratio = imageWidth / imageHeight;

  let geometry;
  if (ratio > 1) {
    // Wide image
    geometry = new THREE.PlaneGeometry(size, size / ratio);
  } else {
    // High image
    geometry = new THREE.PlaneGeometry(size * ratio, size);
  }

  const material = new THREE.MeshBasicMaterial({
    map: texture,
    polygonOffset: true,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -4.0,
  });

  return [geometry, material];
}

/**
 * Sticker. Supports any image texture
 */
export class Sticker extends THREE.Mesh {
  public aspect: number;
  public texture: THREE.Texture;

  constructor(texture: THREE.Texture) {
    const [geometry, material] = create(texture);
    super(geometry, material);
    this.texture = texture;

    this.aspect = this.texture.image.width / this.texture.image.height;
  }

  setSize(size: number) {
    this.scale.setScalar(size);
  }

  dispose(): void {
    this.texture = null;
    ThreeUtils.disposeObject(this);
  }
}

/**
 * Optimized version for multiple stickers
 */
export class InstancedSticker extends THREE.InstancedMesh {
  public texture: THREE.Texture;
  public aspect: number;

  constructor(texture: THREE.Texture, count) {
    const [geometry, material] = create(texture);
    super(geometry, material, count);

    this.texture = texture;
    this.aspect = texture.image.width / texture.image.height;
  }

  getTexture(): THREE.Texture {
    return this.texture;
  }

  setTexture(texture: THREE.Texture): void {
    this.texture = texture;

    const [geometry, material] = create(texture);
    this.aspect = texture.image.width / texture.image.height;

    this.geometry = geometry;
    this.material = material;
  }

  setCount(count: number): void {
    this.count = count;
    this.instanceMatrix.needsUpdate = true;
  }

  dispose(): void {
    // Save the texture, most likely will be re-used and destroyed else where.
    this.texture = null;
    ThreeUtils.disposeObject(this);

    super.dispose();
  }
}
