<div class="upload_prompt_wrapper">
  <h1>Upload file</h1>

  <ng-container *ngIf="!suggestedAction">
    <p>
      You can upload a file to create a new pattern. <br />
      The file must be in the correct format (.json).
    </p>
  </ng-container>

  <ng-container *ngIf="suggestedAction">
    <mat-card appearance="outlined">
      <div mat-card-avatar>
        <mat-icon>warning</mat-icon>
      </div>
      <div>
        <mat-card-title>{{ suggestedAction }}</mat-card-title>
        <mat-card-content>
          <mat-list>
            <mat-list-item *ngFor="let res of result.breaking">
              - {{ res.message }}
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </div>
    </mat-card>
  </ng-container>

  <section class="file_section" (click)="uploadSectionClick()" matRipple>
    <div class="file_input_wrapper">
      <input
        #file
        type="file"
        id="file_input"
        (change)="onFileChange($event)"
        accept=".json"
        style="display: none"
      />
      <ng-container *ngIf="!fileName || suggestedAction">
        <div class="file_upload_text">
          <mat-icon>upload_file</mat-icon>
          <span>Choose a file</span>
        </div>
      </ng-container>
      <ng-container *ngIf="fileName && !suggestedAction">
        <div class="file_upload_text">
          <span>File ready for upload:</span>
          <span
            ><b>{{ fileName }}</b></span
          >
        </div>
      </ng-container>
    </div>
  </section>

  <div class="actions_wrapper">
    <button
      mat-stroked-button
      color="primary"
      pally-rounded-button-m
      (click)="cancelUpload()"
    >
      Cancel
    </button>
    <button
      [disabled]="!fileName || suggestedAction"
      mat-raised-button
      pally-rounded-button-m
      color="accent"
      (click)="uploadFile()"
      style="white-space: nowrap"
    >
      <mat-icon>upload</mat-icon>
      Upload
    </button>
  </div>
</div>
