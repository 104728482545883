import * as THREE from 'three';
import { palletViewColors } from '../../config/pallet-view-colors';
import { IPalletEdgeOffset } from '../../types/pallet-edge-offset';
import { Pallet } from '../pallet';
import { ThreeLayer } from './three-layer';
import { LayerType } from '../../enums/layer-type';
import { InstancedDynamicBox } from './dynamic-box';
import { LabelOrientation } from '../../enums/label-orientation';
import { calcPalletEdgeOffset, milliToMeter } from '../../../utils/div';
import { ThreeUtils } from '../../../utils/three-utils';
import { MathUtils, Mesh } from 'three';
import { radians } from '../../../utils/geometry-utils';
import { Box } from '../box';

export class NewThreePallet extends THREE.Object3D {
  width: number;
  length: number;
  height: number;
  edgeOffset: IPalletEdgeOffset;
  nrBoxes = 0;
  active: boolean = true;
  showLabel: boolean = true;
  showFront: boolean = false;
  showFrontPallet: boolean = false;
  showDebug: boolean = false;
  showFirstLayerOnlyValue: boolean = false;

  boxes: InstancedDynamicBox;
  layerList: ThreeLayer[] = [];
  palletFront: THREE.Mesh;

  palletBB = new THREE.Box3();
  boundingBox = new THREE.Box3();
  helper = new THREE.Box3Helper(this.boundingBox);
  min: Mesh;
  max: Mesh;
  shouldUpdateBoundingBox: boolean = false;

  defaultBoxMaterials: THREE.Material | THREE.Material[];

  constructor(
    private palletModel: THREE.Mesh,
    private boxModel: THREE.Mesh,
    private pallet: Pallet
  ) {
    super();
    // Visualizes the bounding box (prepresents the space taken up by the pallet)
    this.helper.visible = false;
    this.add(this.helper);

    // Default materials
    this.defaultBoxMaterials = boxModel.material;
    this.length = milliToMeter(this.pallet.dimensions.length);
    this.width = milliToMeter(this.pallet.dimensions.width);
    this.height = milliToMeter(this.pallet.dimensions.palletHeight);

    // Map pallet edges
    this.edgeOffset = calcPalletEdgeOffset(this.width, this.length);

    // Add pallet model.
    this.add(this.palletModel);

    // Count layers
    for (const layer of pallet.layers.values()) {
      if (layer.type !== LayerType.SHIMPAPER) {
        this.nrBoxes += layer.boxes.length;
      }
    }

    // Setup boxes
    this.boxes = new InstancedDynamicBox(this.boxModel, this.nrBoxes);
    this.add(this.boxes);
    this.boxes.position.copy(this.getLayerPosition());

    // Create layers
    for (const [index, layerData] of pallet.layers.entries()) {
      // Pallet dimensions are defined as millimeters
      // Define layer dimensions (in meters)
      const layerDimensions = {
        width: milliToMeter(pallet.dimensions.width),
        height: milliToMeter(layerData.height),
        length: milliToMeter(pallet.dimensions.length),
      };

      const threeLayer = new ThreeLayer(
        layerData.type,
        layerData.zPosition / 1000, // Convert to meters
        layerDimensions
      );

      threeLayer.name = `Layer ${index}`;

      this.setLayer(index, threeLayer);
    }

    // Place the boxes
    this.placeBoxes();

    this.updateBoundingBox();

    this.palletFront = this.makePalletFrontCone(this.showFrontPallet);
    this.palletFront.position.z = this.edgeOffset.front + 0.4;
    this.add(this.palletFront);
  }

  placeBoxes(): void {
    let i = 0;
    // Reverse the layers if we want to show the bottom layer only
    const layers = this.showFirstLayerOnlyValue
      ? [...this.pallet.layers].reverse()
      : this.pallet.layers;
    for (const layer of layers) {
      // Shim papers are handeled separatly
      if (layer.type === LayerType.SHIMPAPER) {
        continue;
      }

      for (const boxData of layer.boxes) {
        this.boxes.setMatrixAt(
          i,
          this.computeBoxMatrix(boxData),
          boxData.labelOrientations.length < 2
        );
        i += 1;
      }
    }
  }

  updateBoxes(showLabel: boolean, showFront: boolean): void {
    this.showLabel = showLabel;
    this.showFront = showFront;

    this.boxes.showLabels(showLabel);

    if (showFront) {
      this.boxes.showFrontIndicator();
    } else {
      this.boxes.hideFrontIndicator();
    }
  }

  updateBoundingBox(): void {
    if (!this.shouldUpdateBoundingBox) {
      return;
    }

    const overhangEnds = milliToMeter(this.pallet.overhangEnds);
    const overhangSides = milliToMeter(this.pallet.overhangSides);
    const min = new THREE.Vector3(
      this.edgeOffset.left - overhangSides,
      0,
      this.edgeOffset.front + overhangEnds
    );
    const max = new THREE.Vector3(
      this.edgeOffset.right + overhangSides,
      this.getTotalHeight(),
      this.edgeOffset.back - overhangEnds
    );

    this.boundingBox.makeEmpty();
    this.boundingBox.expandByPoint(min);
    this.boundingBox.expandByPoint(max);

    if (this.showDebug && this.min && this.max) {
      this.min.position.copy(min);
      this.max.position.copy(max);
    }
  }

  /**
   * Quality of life function to visualize the bounding box of
   * the pallet as well as it's minimum and maximum bounds,
   * visualized by a red (min) and green (max) sphere.
   *
   * Other possible things to add it the physical dimensions of the pallet
   * and display layer names on the sides, shimpaper heights next to
   * the appropriate shimpaper and so on. Much more can be built in.
   * @param showDebugInfo { boolean } - Enabled debug info or not.
   */
  debug(showDebugInfo?: boolean): void {
    this.showDebug = showDebugInfo;
    this.helper.visible = showDebugInfo ? true : false;

    // Make and show debug info
    if (this.showDebug) {
      const geo = new THREE.SphereGeometry(0.05);

      // Visualizes the minimum corner of the pallet
      this.min = new THREE.Mesh(
        geo,
        new THREE.MeshLambertMaterial({ color: '#ff0000' })
      );
      this.add(this.min);

      // Visualizes the maximum corner of the pallet
      this.max = new THREE.Mesh(
        geo,
        new THREE.MeshLambertMaterial({ color: '#00ff00' })
      );
      this.add(this.max);

      this.showPalletFront(true);

      // Hide and destroy debug info.
    } else if (this.min && this.max) {
      this.remove(this.min);
      this.remove(this.max);
      ThreeUtils.disposeObject(this.min);
      ThreeUtils.disposeObject(this.max);

      this.showPalletFront(false);
    }
  }

  setBoxStickerTexture(
    texture: THREE.Texture,
    labelOrientations: LabelOrientation[]
  ): void {
    this.boxes.setLabels(
      texture,
      // Remove any null values
      labelOrientations?.filter((orient) => orient !== null)
    );

    // Set visibility state immideatly to hide if needed
    this.boxes.showLabels(this.showLabel && this.active);
    this.updateMaterial();
  }

  showBoxFront(visible: boolean): void {
    this.showFront = visible;
    if (visible) {
      this.boxes.showFrontIndicator();
    } else {
      this.boxes.hideFrontIndicator();
    }
  }

  showPalletFront(visible: boolean): void {
    this.showFrontPallet = visible;

    this.palletFront.visible = this.showFrontPallet;
  }

  /**
   * Gets height
   * @returns Total pallet height in meters
   */
  getTotalHeight(): number {
    let totalHeight = this.height;
    for (const layer of this.layerList) {
      totalHeight += layer.getHeight();
    }
    return totalHeight;
  }

  private setLayer(index: number, layer: ThreeLayer): void {
    (layer as THREE.Object3D).position.copy(this.getLayerPosition());
    this.layerList[index] = layer;
    if (this.showFirstLayerOnlyValue && layer.type === LayerType.SHIMPAPER) {
      return;
    }
    this.add(layer);
  }

  private getLayerPosition(): THREE.Vector3 {
    return new THREE.Vector3(this.edgeOffset.left, 0, this.length / 2);
  }

  setPosition(pos: THREE.Vector3): void {
    this.position.copy(pos);
    this.updateBoundingBox();
  }

  setYPosition(y: number): void {
    this.position.y = this.height / 2 + y;
  }

  setXPosition(x: number): void {
    this.position.x = x;
  }

  setZPosition(z: number): void {
    this.position.z = z;
  }

  enable(): void {
    this.active = true;
    this.updateMaterial();
  }

  disable(): void {
    this.active = false;
    this.updateMaterial();
  }

  private updateMaterial(): void {
    // Update boxes
    this.boxes.boxMesh.material = this.active
      ? this.defaultBoxMaterials
      : new THREE.MeshLambertMaterial({
          color: palletViewColors.unselectedPallet,
        });

    // Update pallet
    this.palletModel.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material = new THREE.MeshLambertMaterial({
          color: this.active
            ? palletViewColors.selectedPallet
            : palletViewColors.unselectedPallet,
        });
      }
    });
  }

  /**
   * Computes the matrix for a box.
   */
  private computeBoxMatrix(b: Box): THREE.Matrix4 {
    const UP = new THREE.Vector3(0, 1, 0);

    // Convert from millimeters to meters
    const position = new THREE.Vector3(
      b.position.x,
      b.position.z,
      -b.position.y // The z axis is fliped between three.js and pattern space
    ).divideScalar(1000);
    const scale = new THREE.Vector3(
      b.dimensions.width,
      b.dimensions.height,
      b.dimensions.length
    ).divideScalar(1000);
    const orientation = new THREE.Quaternion().setFromAxisAngle(
      UP,
      radians(b.rotation[0])
    );

    return new THREE.Matrix4().compose(position, orientation, scale);
  }

  private makePalletFrontCone(visible: boolean = false): THREE.Mesh {
    const geo = new THREE.ConeGeometry(0.075, 0.15);
    const mat = new THREE.MeshLambertMaterial({ color: '#000000' });
    const mesh = new THREE.Mesh(geo, mat);
    mesh.name = 'pallet_front';
    mesh.visible = visible; // Assume they're hidden
    mesh.rotation.x = MathUtils.degToRad(-90);
    return mesh;
  }

  showOutLines(showOutLines: boolean) {
    this.boxes.showOutlines(showOutLines);
  }

  showFirstLayerOnly(firstLayerOnly: boolean) {
    if (this.showFirstLayerOnlyValue === firstLayerOnly) {
      return;
    }
    this.showFirstLayerOnlyValue = firstLayerOnly;
    this.layerList
      .filter((layer) => layer.layerType === LayerType.SHIMPAPER)
      .forEach((layer) => {
        if (firstLayerOnly) {
          this.remove(layer);
        } else {
          this.add(layer);
        }
      });

    const totalBoxCount = this.boxes.getCount();
    this.placeBoxes();
    this.boxes.setCount(totalBoxCount);
    const firstLayer = this.pallet.layers.filter(
      (layer) => layer.boxes !== null
    )[0];
    this.boxes.setCount(
      firstLayerOnly ? firstLayer.boxes.length : totalBoxCount
    );

    this.updateBoundingBox();
  }
}
