import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/services/notification.service';
import { Observable, take, tap } from 'rxjs';
import { ErrorContext, ErrorLogEntry } from 'src/app/models_new/types/error';
import { DatadogLoggerService } from 'src/app/services/datadog-logger.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit {
  /** @param error: Error type input param */
  @Input() error: Error;
  /**@param context Optional. Gives details about the failing cause of the error. Intended to be used for context details, such as OrgId and SimId.*/
  @Input() private context?: ErrorContext;
  /**  @param suggestion Optional. Pre-defined tip or suggestion to get around the problem. */
  @Input() private suggestion?: string;
  /** @param hideActions Optional. Hides the action row. Useful for none-full-page wrappers. */
  @Input() public hideActions?: boolean = false;
  /** @deprecated: Use error & suggestion instead. Kept for retro-compatibility. */
  @Input() public message?: string;

  processedMessage: string;
  processedSuggestion: string;
  errorLogEntry$: Observable<ErrorLogEntry>;
  isErrorReported: boolean;

  /** @property Sets and ID to avoid the same error to be logged twice. */
  private id: string;

  constructor(
    private location: Location,
    private router: Router,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private errorService: ErrorHandlerService,
    private datadogService: DatadogLoggerService
  ) {
    this.id = uuidv4();
  }

  ngOnInit(): void {
    console.error(this.error, this.router?.url);
    this.errorLogEntry$ = this.errorService
      .composeErrorLogEntry$(
        this.error,
        this.id,
        this.context,
        this.suggestion,
        this.message
      )
      .pipe(
        take(1),
        tap((entryLog) => this.errorService.setLocalErrorLog(null, entryLog))
      );

    this.processedMessage = this.message || 'Something went wrong...';
    this.processedSuggestion =
      this.suggestion ||
      'Please go back and try again in a while. Contact support if the problem persists.';
  }

  public doAction(actionId: string, value?: any): void {
    switch (actionId) {
      case 'navigateBack':
        this.location.back();
        break;
      case 'refresh':
        window.location.reload();
        break;
      case 'copyError':
        this.copyError(value);
        break;
      case 'report':
        this.reportError(value);
        break;
      default:
        console.warn('Unkown actionId: ' + actionId);
        break;
    }
  }

  private reportError(logEntry: ErrorLogEntry): void {
    this.datadogService.sendLog(logEntry, this.error);
    this.isErrorReported = true;
    this.notificationService.showMessage(
      'Error log sent, thank you for helping us to improve!'
    );
  }

  private copyError(logEntry: any): void {
    this.clipboard.copy(JSON.stringify(logEntry));
    this.notificationService.showMessage('Error copied to clipboard');
  }
}
