<app-new-three-view
  #view
  style="height: 100%"
  [camera]="camera.camera"
  (screenshot$)="processScreenshot($event)"
>
  <app-orthographic-camera
    #camera
    [position]="cameraPos"
    [target]="_palletPosition"
  ></app-orthographic-camera>

  <app-directional-light
    [position]="lightPos1"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos2"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos3"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos4"
    [intensity]="0.4"
  ></app-directional-light>
  <app-ambient-light [intensity]="0.3"></app-ambient-light>

  <app-three-pallet
    #threePallet
    *ngIf="_pallet"
    [pallet]="_pallet"
    [sticker]="sticker"
    [labelOrientations]="_labelOrientations"
    [position]="_palletPosition"
    [showOutLines]="showOutLines"
    [firstLayerOnly]="firstLayerOnly"
  ></app-three-pallet>
</app-new-three-view>
