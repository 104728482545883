import {
  Component,
  ContentChild,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject, combineLatestWith, map, Observable } from 'rxjs';
import { FilterTableData } from 'src/app/models_new/types/sorting-option';
import { ITableData } from '../table/table.component';
import { SortDirection } from '@angular/material/sort';

/**
 * Wraps around cards(but not exclusive to cards) to create lists. This component also handles filtering
 *
 * Usage:
 * ```
 * <app-card-list-wrapper [inputData]="inputData.value" [filter]="filter">
 *   <ng-template #card let-nameme>
 *     <app-some-card
 *       [inputData]='nameme'
 *       ...
 *       ...
 *       ...
 *       >
 *     </app-some-card>
 *   </ng-template>
 * </app-card-list-wrapper>
 * ```
 *
 */

@Component({
  selector: 'app-card-list-wrapper',
  templateUrl: './card-list-wrapper.component.html',
  styleUrls: ['./card-list-wrapper.component.scss'],
})
export class CardListWrapperComponent<ActionType> implements OnInit, OnChanges {
  @Input() inputData: ITableData<ActionType>[];

  @Input() set sortBy(sort: { column: string; order: SortDirection }) {
    if (sort) {
      this.orderBy$.next({ active: sort.column, direction: sort.order });
    }
  }

  @Input() set filter(filter: FilterTableData) {
    if (this.inputData?.length) {
      this.filter$.next(filter);
    }
  }
  filter$: BehaviorSubject<FilterTableData> =
    new BehaviorSubject<FilterTableData>(new FilterTableData());

  updateData: BehaviorSubject<ITableData<ActionType>[]> = new BehaviorSubject<
    ITableData<ActionType>[]
  >([]);
  filteredData$: Observable<ITableData<ActionType>[]>;

  orderBy$: BehaviorSubject<{
    active: string;
    direction: SortDirection;
  }> = new BehaviorSubject<{
    active: string;
    direction: SortDirection;
  }>({
    active: 'name',
    direction: 'asc',
  });

  page: number = 0;
  numItemsInPage: number = 8;

  @ContentChild('card') cardRef: TemplateRef<any>;

  // TODO: add sort
  ngOnInit(): void {
    this.updateData.next(this.inputData);

    this.filteredData$ = this.updateData.pipe(
      combineLatestWith(this.filter$, this.orderBy$),
      map(([table_data, filter, order]) => {
        let filtered_data: ITableData<ActionType>[] = [];
        if (table_data.length > 0) {
          // Ugly hack, due to structure difference between ITableData and IExpandableTable(is inside ITableData).
          const isExpandable = !!table_data[0].data.expandableContent;

          filtered_data = table_data.filter((entry) => {
            const data_to_filter = isExpandable ? entry.data.data : entry.data;
            return (
              filter.keywordFilter(data_to_filter) &&
              filter.sliderFilter(data_to_filter) &&
              filter.textFilter(data_to_filter)
            );
          });
        }
        return filtered_data.sort((a, b) => {
          const a_data = a.data;
          const b_data = b.data;
          if (order.direction === 'desc') {
            return a_data[order.active] < b_data[order.active] ? 1 : -1;
          } else {
            return a_data[order.active] < b_data[order.active] ? -1 : 1;
          }
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inputData) {
      this.updateData.next(this.inputData);
    }
  }

  didChangePage(event) {
    this.numItemsInPage = event.pageSize;
    this.page = event.pageIndex;
  }
}
