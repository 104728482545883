<div
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  class="landing-step-item-wrapper"
  [ngStyle]="
    lowHeigth
      ? {
          'padding-top': '0.5em',
          'padding-bottom': '1em',
          'margin-top': '0.5em'
        }
      : {}
  "
>
  <ng-container *ngIf="product$ | async as product">
    <!-- Loading state -->
    <app-loading *ngIf="product.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="product.error as error" [error]="error"></app-error>

    <ng-container *ngIf="product.didSucceed && product.value">
      <div class="card-header">
        <h1
          *appVar="openSimService.pagesSortedList$ | async as pagesSortedList"
          [ngStyle]="
            lowHeigth ? { 'margin-bottom': '0.5em', 'margin-top': '0.5em' } : {}
          "
        >
          {{
            pagesSortedList[openSimService.selectedPageIndex].content.cardTitle
          }}
        </h1>
        <mat-slide-toggle
          [checked]="unit === 'imperial'"
          labelPosition="before"
          style="color: #666"
          (change)="toggleUnits($event.checked); save(product.value)"
          >Use imperial units</mat-slide-toggle
        >
      </div>
      <app-new-product-card
        *ngIf="this.isCardVisible$ | async"
        #productCard
        [mode]="'edit'"
        [product]="product.value"
        [enableCancel]="false"
        (product$)="productUpdate($event)"
        [hideProductSelect]="true"
        [hideProdlineSelect]="true"
        [isPublic]="true"
        [unit]="unit"
      >
      </app-new-product-card>
    </ng-container>
  </ng-container>
</div>
