import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ThreeHandler } from '../three-handler';
import { RenderTrigger } from '../../../../public/render-trigger';
import { Project } from '../../project';
import { AssetStoreService } from '../../../../services/asset-store.service';
import { AssetIDs } from '../../../enums/asset-ids';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { ProductionLineHandlerData } from '../handler-services/production-line-handler-data.service';
import { ProductionLineHandlerDataMultiple } from '../handler-services/production-line-handler-data-multiple.service';
import { InstancedDynamicBox } from '../dynamic-box';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';

export class ProductionLineHandler extends ThreeHandler {
  private compLogoService: OrganizationLogoService;

  X_OFFSET = -5; // Offset along the x-axis to make sure the boxes starts outside of view
  PADDING = 3; // meters
  DIMENSION = 1; // meters
  COUNT = 4; // Number of boxes to display

  boxes: InstancedDynamicBox;

  speed = 0.0; // m/s
  position = 0.0; // The property we are animating

  // Helper to make it easy to create transform matrices
  dummy = new THREE.Object3D();

  constructor(ID: string, injector: Injector, project?: Project) {
    super(ID, injector, project);

    this.compLogoService = injector.get(OrganizationLogoService);
  }

  public init(): void {
    this.loading$.next(true);

    this.inputHandler.setCameraPostion(new THREE.Vector3(7, 0.8, 2.5));
    this.inputHandler.setCameraTarget(new THREE.Vector3(6, 0.0, 0.0));
    this.inputHandler.forbidAll();
    this.inputHandler.updateControls();

    const hasMultipleSenders = this.ID.includes('generic-inventory-picker-');

    const datahandler = hasMultipleSenders
      ? this.injector.get(ProductionLineHandlerDataMultiple)
      : this.injector.get(ProductionLineHandlerData);

    datahandler
      .getCasesPerMinute(hasMultipleSenders ? this.ID : undefined)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cpm) => {
        // We need to convert cases per minute to a speed
        // To convert to seconds we divide by 60. And since we need to process both
        // the box and the padding for each box, we multiply by DIMENSION + PADDING
        this.speed = (cpm * (this.DIMENSION + this.PADDING)) / 60;
      });

    const box$ = AssetStoreService.onAssetLoadedWithID<THREE.Object3D>(
      AssetIDs.Box
    ).pipe(
      map((box: THREE.Object3D) => {
        // We know the structure of the box model, the first child is the actual mesh
        this.boxes = new InstancedDynamicBox(
          box.children[0] as THREE.Mesh,
          this.COUNT
        );

        // Space boxes out along the x axis
        for (let i = 0; i < this.COUNT; i++) {
          this.dummy.position.x = (this.DIMENSION + this.PADDING) * i;

          // This is the default box, just 5x.
          this.dummy.scale.x = 1.45;
          this.dummy.scale.y = 1.0;
          this.dummy.scale.z = 0.95;

          this.dummy.updateMatrix();
          this.boxes.setMatrixAt(i, this.dummy.matrix);
        }

        this.scene.add(this.boxes);

        this.loading$.next(false);
      })
    );

    // Needs to be handled after we have a box to update
    box$
      .pipe(
        switchMap(() => {
          return this.compLogoService.logoTexture$;
        })
      )
      .subscribe((texture: THREE.Texture) => {
        this.boxes.setLabels(texture, [LabelOrientation.FRONT]);
      });
  }

  public afterViewInit(): void {
    this.threeEvents.update$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dt: number) => {
        this.updatePositions(dt);
      });

    RenderTrigger.enableRenderingLoop$.next(true);
  }

  addLighting(): void {
    const ambientIntensity = 0.2;
    const directionalIntensity = 0.8;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity);
    this.scene.add(ambient);

    const directional = new THREE.DirectionalLight('#ffffff');
    directional.position.set(-5, 3, 5);
    directional.intensity = directionalIntensity;
    directional.target.position.copy(new THREE.Vector3());
    this.scene.add(directional.target);
    this.scene.add(directional);
  }

  private updatePositions(dt: number) {
    if (!this.boxes) {
      return;
    }

    this.position += this.speed * dt;

    if (this.position > this.DIMENSION + this.PADDING) {
      // Make we never move the position more than the distane between boxes.
      // This will appears as if the box at the end is moved to the front of the queue.
      this.position = this.position % (this.DIMENSION + this.PADDING);
    }

    // Move the boxes
    this.boxes.position.x = this.position;
  }
}
