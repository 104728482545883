import { Component, Input, OnInit } from '@angular/core';

interface IDonutChartItem {
  label: string;
  value: number;
  color: string;
}

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
  @Input() chartData?: IDonutChartItem[];
  @Input() displayCounter?: boolean = false;
  segments: IDonutChartItem[] = [];
  ready = false;
  itemsTotal: number = 0;
  private _total = 0;

  constructor() {}
  ngOnInit(): void {
    if (this.chartData) {
      this.segments = this.chartData;
      this.chartData.forEach((element) => (this.itemsTotal += element.value));
    } else {
      this.segments = [
        { label: 'Orange', value: 2, color: '#7BD6DC' },
        { label: 'Apple', value: 2, color: '#395692' },
        { label: 'Pear', value: 2, color: '#E86464' },
      ];
    }

    if (this.segments.length > 0) {
      this._total = this.segments.map((a) => a.value).reduce((x, y) => x + y);
    }
    this.ready = true;
  }

  getPerimeter(radius: number): number {
    return Math.PI * 2 * radius;
  }

  getColor(index: number): string {
    return this.segments[index].color;
  }

  getOffset(radius: number, index: number): number {
    let percent = 0;
    for (let i = 0; i < index; i++) {
      percent += this.segments[i].value / this._total;
    }
    const perimeter = Math.PI * 2 * radius;
    return perimeter * percent;
  }
}
