<div class="loadingDiv" *ngIf="showSpinner">
  <div class="loadingContainer">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<div class="main">
  <h4 class="tagline">UPLOAD COMPONENT</h4>
  <h1 class="title">Summary</h1>

  <div style="margin-top: 12px">
    <div class="horizontal_container">
      <p>Make public</p>
      <mat-slide-toggle
        class="toggle"
        (toggleChange)="updateIsPublic()"
        color="primary"
      ></mat-slide-toggle>
    </div>
    <div *ngIf="this.modelUpload.generatorParams.type.id === 'lifting_column'">
      <mat-form-field
        appearance="outline"
        class="center-suffix"
        style="width: 100%"
      >
        <mat-label>Stroke</mat-label>
        <input matInput type="number" value="0" />
        <span matSuffix>mm</span>
      </mat-form-field>
    </div>
  </div>

  <div
    *ngFor="
      let transform of modelUpload.generatorParams.transforms;
      let transformPosition = index
    "
  >
    <h3 *ngIf="modelUpload.generatorParams.transforms.length > 1">
      Point {{ transformPosition + 1 }}
    </h3>
    <div class="summary-container">
      <div *ngFor="let type of transform.vectors; let vectorPosition = index">
        <p class="transform-title">{{ type.type }}</p>
        <div class="input-container">
          <mat-form-field
            appearance="outline"
            style="width: 100%"
            *ngFor="
              let vector of type.vector.entries();
              let entryPosition = index
            "
          >
            <mat-label>{{
              transformValueNames[vectorPosition][entryPosition]
            }}</mat-label>
            <input
              style="text-align: right"
              (change)="
                inputChange(
                  $event,
                  vectorPosition,
                  entryPosition,
                  transformPosition
                )
              "
              matInput
              type="number"
              value="{{ vector.value }}"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button
      class="stroked-button"
      (click)="modelUpload.backClick()"
      mat-stroked-button
    >
      <mat-icon class="icon mat-icon material-icons mat-icon-no-color">
        arrow_back
      </mat-icon>
      BACK
    </button>
    <button class="stroked-button" (click)="download()" mat-stroked-button>
      DOWNLOAD
      <mat-icon class="icon mat-icon material-icons mat-icon-no-color">
        download
      </mat-icon>
    </button>
    <button
      class="filled-button"
      (click)="uploadToGitHub()"
      mat-flat-button
      *appIfCommandAvailable="'create_hardware_type'"
    >
      UPLOAD
      <mat-icon class="icon mat-icon material-icons mat-icon-no-color">
        upload
      </mat-icon>
    </button>
  </div>
</div>
