import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { pagesPATH } from '../../../models_new/config/pages';
import { PublicApiService } from 'src/app/services/api/public-api.service';

@Component({
  selector: 'app-illegal-organization-subscription',
  templateUrl: './illegal-organization-subscription.component.html',
  styleUrls: ['./illegal-organization-subscription.component.scss'],
})
export class IllegalOrganizationSubscriptionComponent {
  constructor(
    public dialogRef: MatDialogRef<IllegalOrganizationSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        title: string;
        message?: string;
      };
    },
    private router: Router,
    public publicApi: PublicApiService
  ) {}

  navigateBack() {
    this.dialogRef.close();
    this.router.navigate([pagesPATH.USER, pagesPATH.LANDING_PAGE]);
  }
}
