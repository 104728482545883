<div class="customer-edit-url-dialog" style="padding: 2em">
  <h1 mat-dialog-title class="float-left w-100">Find your logo</h1>
  <div
    mat-dialog-content
    style="max-height: 80vh !important"
    class="float-left w-100 m-0"
  >
    <form (keydown)="enterHit($event)">
      <app-field [field]="field"></app-field>
    </form>

    <ng-container *ngIf="logo$ | async as logo">
      <!-- Loading state -->
      <app-loading *ngIf="logo.isLoading"></app-loading>

      <!-- Error state -->
      <app-error
        *ngIf="logo.error as error"
        [error]="error"
        suggestion="
          Please disable your adblocker for myrobot.cloud and try again.
        "
      ></app-error>

      <!-- Empty state -->
      <app-empty *ngIf="logo.didSucceed && logo === undefined"></app-empty>

      <ng-container *ngIf="logo.didSucceed">
        <div class="customer-edit-url-logos">
          <img
            [src]="logo.value | trust: 'resourceUrl'"
            style="
              width: 200px;
              height: 200px;
              border-radius: 50%;
              padding: 22px;
              background: white;
              border: 2px solid #eee;
            "
          />
          <img
            [src]="logo.value | trust: 'resourceUrl'"
            style="
              width: 200px;
              height: 200px;
              border-radius: 50%;
              padding: 22px;
              background: #494949;
              border: 2px solid #eee;
            "
          />
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions class="float-left w-100">
    <app-button
      [buttonType]="'stroked'"
      [label]="'Cancel'"
      [icon]="null"
      (buttonClick)="cancel()"
    >
    </app-button>

    <span class="actions-spacer" style="flex-grow: 1"></span>

    <ng-container *ngIf="state === 'search'">
      <app-button
        [type]="'edit'"
        [icon]="'search'"
        [label]="'SEARCH'"
        (buttonClick)="findLogo()"
      >
      </app-button>
    </ng-container>

    <ng-container *ngIf="state === 'save'">
      <app-button
        [type]="'cta'"
        [label]="'SELECT LOGO'"
        (buttonClick)="selectLogo()"
      >
      </app-button>
    </ng-container>
  </div>
</div>
