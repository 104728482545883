<button
  mat-icon-button
  [class.not-selected-perspective]="perspective !== perspectives.TWO"
  (click)="selectPerspective(perspectives.TWO)"
  matTooltip="Top view"
>
  <mat-icon svgIcon="2d_view"></mat-icon>
</button>
<button
  mat-icon-button
  [class.not-selected-perspective]="perspective !== perspectives.THREE"
  (click)="selectPerspective(perspectives.THREE)"
  matTooltip="3D view"
>
  <mat-icon svgIcon="3d_view"></mat-icon>
</button>
