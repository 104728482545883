import { Injector } from '@angular/core';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { ThreeHandler } from '../three-handler';
import { RenderTrigger } from '../../../../public/render-trigger';
import { Project } from '../../project';
import { track } from '../../../../utils/resource-tracker';
import { takeUntil, take } from 'rxjs/operators';
import { RXJSUtils } from '../../../../utils/rxjs-utils';
import { ThreeUtils } from '../../../../utils/three-utils';
import { Observable } from 'rxjs';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';

class CameraSplineAnimator {
  private points: THREE.Vector3[] = [];
  path: THREE.CatmullRomCurve3;

  offset: THREE.Vector3;
  speed = 1;

  progress = 0;

  constructor(
    points: THREE.Vector3[],
    speed: number = 1,
    offset: THREE.Vector3 = new THREE.Vector3()
  ) {
    this.points = points;
    this.offset = offset;
    this.speed = speed;
    this.makePath();
  }

  update(dt: number): void {
    this.progress += dt * this.speed;
    if (this.progress >= 1) {
      this.progress = 0;
    }
  }

  getPoints(): THREE.Vector3[] {
    return this.offsetPath(this.offset);
  }

  getCurrentPosition(): THREE.Vector3 {
    return this.path.getPointAt(this.progress);
  }

  setSpeed(speed: number): void {
    this.speed = speed;
  }

  setOffset(offset: THREE.Vector3): void {
    this.offset = offset;
    this.makePath();
  }

  private makePath(): void {
    this.path = new THREE.CatmullRomCurve3(
      this.offsetPath(this.offset),
      true,
      'catmullrom',
      1
    );
  }

  private offsetPath(offset: THREE.Vector3): THREE.Vector3[] {
    const offsetPoints = [];
    for (let i = 0; i < this.points.length; i++) {
      const offsetPos = new THREE.Vector3().copy(this.points[i]).add(offset);
      offsetPoints.push(offsetPos);
    }
    return offsetPoints;
  }
}

export class LogoViewHandler extends ThreeHandler {
  logo: THREE.Texture;

  backPlane: THREE.Object3D;
  logoPlane: THREE.Object3D;

  points: THREE.Vector3[] = [
    new THREE.Vector3(0, 0.6, 0), // Top
    new THREE.Vector3(0.25, 0.5, 1.5), // Top right
    new THREE.Vector3(0.75, 0, 3), // Right
    new THREE.Vector3(0.25, -0.5, 1.5), // Bottom right
    new THREE.Vector3(0, -0.6, 0), // Bottom
    new THREE.Vector3(0.25, -0.5, -1.5), // Bottom left
    new THREE.Vector3(0.75, 0, -3), // Left
    new THREE.Vector3(0.25, 0.5, -1.5), // Top left
  ];

  cameraAnimator: CameraSplineAnimator;
  showAnimatorPath = false;

  logo$: Observable<THREE.Texture>;

  constructor(ID: string, injector: Injector, project?: Project) {
    super(ID, injector, project);

    this.cameraAnimator = new CameraSplineAnimator(
      this.points,
      0.05,
      new THREE.Vector3(-7.5, 0, 0)
    );

    const compLogoService = injector.get(OrganizationLogoService);
    this.logo$ = compLogoService.logoTexture$.pipe(takeUntil(this.destroy$));
  }

  public init(): void {
    this.inputHandler.setCameraPostion(
      this.cameraAnimator.getCurrentPosition()
    );
    this.inputHandler.setCameraTarget(new THREE.Vector3(0, 0, 0));
    if (!this.showAnimatorPath) {
      this.inputHandler.forbidAll();
    }
    this.inputHandler.updateControls();
  }

  public afterViewInit(): void {
    this.buildScene();

    // ThreeUtils.addDebugAxies(this.scene);

    // Dont add update routine so camera is controllable
    if (!this.showAnimatorPath) {
      this.threeEvents.update$
        .pipe(takeUntil(this.destroy$), RXJSUtils.filterUndefinedAndNull())
        .subscribe((dt: number) => {
          this.cameraAnimator.update(dt);

          this.inputHandler.setCameraPostion(
            this.cameraAnimator.getCurrentPosition()
          );
          this.inputHandler.updateControls();
        });
    }

    if (this.showAnimatorPath) {
      const points = this.cameraAnimator.path.getPoints(50);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
      const curveObject = new THREE.Line(geometry, material);
      this.scene.add(curveObject);
    }

    RenderTrigger.enableRenderingLoop$.next(true);
  }

  addLighting(): void {
    const ambientIntensity = 0.2;
    const spotLightIntensity = 0.8;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity);
    this.scene.add(ambient);

    const spotLight = new THREE.SpotLight('#ffffff');
    spotLight.position.set(-10, 4, 10);
    spotLight.angle = Math.PI / 2;
    spotLight.intensity = spotLightIntensity;
    spotLight.penumbra = 0.25;
    spotLight.target.position.copy(new THREE.Vector3());
    ThreeUtils.enableShadows(spotLight);
    this.scene.add(spotLight.target);
    this.scene.add(spotLight);

    // const helper = new THREE.CameraHelper( spotLight.shadow.camera );
    // this.scene.add( helper );

    // const helper2 = new THREE.SpotLightHelper(spotLight);
    // this.scene.add(helper2);
  }

  buildScene(): void {
    this.backPlane = new THREE.Mesh(
      track(new THREE.PlaneGeometry(100, 100)),
      track(new THREE.MeshLambertMaterial({ color: '#F7F7FB' }))
    );
    this.backPlane.rotation.set(0, MathUtils.degToRad(-90), 0);
    ThreeUtils.enableShadows(this.backPlane);
    this.scene.add(this.backPlane);

    this.logo$.pipe(take(1)).subscribe((texture: THREE.Texture) => {
      this.logo = texture;

      if (this.logoPlane) {
        ThreeUtils.disposeObject(this.logoPlane);
      }

      this.logoPlane = new THREE.Mesh(
        track(
          new THREE.PlaneGeometry(
            this.logo.image.width / 100,
            this.logo.image.height / 100
          )
        ),
        track(new THREE.MeshLambertMaterial({ map: this.logo }))
      );
      this.logoPlane.position.set(-0.75, 0, 0);
      this.logoPlane.rotation.set(0, MathUtils.degToRad(-90), 0);
      ThreeUtils.enableShadows(this.logoPlane);
      this.scene.add(this.logoPlane);
    });
  }
}
