import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Header component
 *
 * Supports
 * 1. Overline text: provide an element with the `app-overline` attribute
 * 2. Title text: provide an element with the `app-title` attribute
 * 3. A logo image: Add a <img> tag
 * 4. Actions: Wrap your action buttons in <app-header-actions>...</..>
 * 5. Underline actions: Buttons for a horizontal bar underneath the header row. Wrap action
 *                       buttons in <app-underline-actions>...</..>
 *                       Currently supports up to three buttons
 *
 * The overline and title elements can be choosen by the user. You are encourage to use
 * the header tag that matches your semantics. For a page level header with a overline
 * and a title, it makes sense that the overline is H1 and the title is H2. For a page header
 * with only a title, it should be H1.
 *
 * Example 1: Simple title
 * ```
 * <app-header>
 *  <h1 app-title>This is my title</h1>
 * </app-header>
 * ```
 *
 * Exampe 2: Overline + title
 * ```
 * <app-header>
 *   <h1 app-overline>INVENTORY</h1>
 *   <h2 app-title>Items</h2>
 * </app-header>
 * ```
 *
 * Example: Title + actions
 * ```
 * <app-header>
 *   <h1 app-title>
 *   <app-header-actions>
 *     <button (click)="celebrate()">...</button>
 *   </app-header-actions>
 * </app-header>
 * ```
 *
 * Example: Title + Logo
 * ```
 * <app-header>
 *   <h1 app-title>
 *   <img src="logo.png"/>
 * </app-header>
 * ```
 *  * Example: Title + underline actions
 * ```
 * <app-header>
 *   <h1 app-title>
 *   <app-underline-actions>
 *     <button (click)="doSomething1()">...</button>
 *     <button (click)="doSomething2()">...</button>
 *     <button (click)="doSomething3()">...</button>
 *   </app-underline-actions>
 * </app-header>
 * ```
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() verticalLayout?: boolean;
  @Input() logoLayout?: boolean;
}

/**
 * Action wrapper for headers
 */
@Component({
  selector: 'app-header-actions',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None, // Warning no encapsulation
  styles: [
    `
      app-header-actions button.mat-mdc-button-base,
      app-header-actions app-button,
      app-header-actions a.mat-button-base {
        margin-left: 8px;
      }

      app-header-actions {
        display: flex;
      }
    `,
  ],
})
export class HeaderActionsComponent {}

@Component({
  selector: 'app-underline-actions',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None, // Warning no encapsulation
  styles: [
    `
      app-underline-actions button.mat-mdc-button-base {
        width: 33%;
        min-width: 36px !important;
      }

      app-underline-actions button.mat-mdc-button-base:not(:last-child) {
        margin-right: 8px;
      }

      app-underline-actions {
        margin: 10px 0;
        min-width: 700px;
        display: flex;
        flex-direction: row;
      }
    `,
  ],
})
export class HeaderUnderlineActionsComponent {}
