export enum TransformVectorType {
  TRANSLATION = 'Translation',
  ROTATION = 'Rotation',
  SCALE = 'Scale',
  BOUNDING_BOX = 'Bounding box',
}

export const transformValueNames = [
  ['X', 'Y', 'Z'], // TRANSFORM
  ['Roll', 'Pitch', 'Yaw'], // ROTATION
  ['X', 'Y', 'Z'], // SCALE
  ['Width', 'Height', 'Depth'], // BOUNDING_BOX
];
