import { Component, Input } from '@angular/core';
import { AppLayoutService } from 'src/app/services/app-layout.service';

@Component({
  selector: 'app-split-card',
  templateUrl: './split-card.component.html',
  styleUrls: ['./split-card.component.scss'],
})
export class SplitCardComponent {
  @Input() title: string;
  @Input() divideEqually: boolean = false;
  @Input() titleColor = 'rgba(0, 0, 0, 0.87)';

  constructor(public appLayout: AppLayoutService) {}
}
