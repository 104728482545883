import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Rectangle } from '@rocketfarm/cartesian-rectangle';
import { RectangleLocation } from '@rocketfarm/packing';
import { BoxSorting } from 'src/app/components/patterns/pattern/pattern-edit/boxsorting';
import { Layer } from 'src/app/models_new/classes/layer';
import { IDialogData } from 'src/app/models_new/types/dialog-data';
import {
  MagicStackService,
  MagicStackConfig,
} from 'src/app/services/magic-stack.service';

export const PATTERNS_PER_PAGES = 50;

type OptionPages = RectangleLocation[][][];

interface PatternDialogData {
  layer: Layer;
  options: MagicStackConfig;
}

@Component({
  selector: 'app-auto-stack',
  templateUrl: './auto-stack.component.html',
  styleUrls: ['./auto-stack.component.scss'],
})
export class AutoStackDialogComponent implements OnInit {
  optionPages: OptionPages;
  pageIndex = 0;
  selectedPageIndex: number;
  selectedPatternIndex: number;
  frame: Rectangle;
  maxPadding: number;
  paddingValue = 0;
  selectedCard: number | undefined = undefined;
  autoStackOptions: PatternDialogData;
  viewReady: boolean;

  @Input() input: IDialogData['content'];
  @Output() output: EventEmitter<Layer> = new EventEmitter();
  @Input() doAction = () => this.onOkClick();

  constructor(private magicStackService: MagicStackService) {}

  ngOnInit() {
    this.autoStackOptions = this.input.data;
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
    this.paddingValue = this.autoStackOptions.options.padding;
    this.frame = this.autoStackOptions.options.frame;
    const maxPaddingPinwheel =
      (Math.min(this.frame.getLength(), this.frame.getWidth()) -
        this.autoStackOptions.options.box.getWidth() -
        this.autoStackOptions.options.box.getLength()) /
      4;
    this.maxPadding = maxPaddingPinwheel;
    this.viewReady = true;
  }

  onOkClick() {
    this.save();
  }

  selectPattern(pageIndex: number, patternIndex: number) {
    this.selectedPatternIndex = patternIndex;
    this.selectedPageIndex = pageIndex;
  }

  nextPage() {
    this.pageIndex = (this.pageIndex + 1) % this.optionPages.length;
  }
  previousPage() {
    this.pageIndex =
      this.pageIndex === 0 ? this.optionPages.length - 1 : this.pageIndex - 1;
  }

  save() {
    if (
      typeof this.selectedPageIndex === 'number' &&
      typeof this.selectedPatternIndex === 'number'
    ) {
      const selectedPatternCard =
        this.optionPages[this.selectedPageIndex][this.selectedPatternIndex];
      const boxesToBoxSortingPattern = selectedPatternCard.map(
        this.magicStackService.rotatedRectangleLocationToPattern
      );
      const sorter = new BoxSorting(
        this.autoStackOptions.options.box.getLength(),
        this.autoStackOptions.options.box.getWidth()
      );

      this.autoStackOptions.layer.boxes = sorter.sortLayerType(
        boxesToBoxSortingPattern
      );

      this.output.emit(this.autoStackOptions.layer);
    } else {
      this.output.emit(null);
    }
  }

  setPadding($event) {
    this.autoStackOptions.options.padding = $event.value;
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
  }
  setBoxWidth($event) {
    this.autoStackOptions.options.box = new Rectangle(
      $event.value,
      this.autoStackOptions.options.box.getLength()
    );
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
  }

  setBoxLength($event) {
    this.autoStackOptions.options.box = new Rectangle(
      this.autoStackOptions.options.box.getWidth(),
      $event.value
    );
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
  }

  setFrameWidth($event) {
    this.autoStackOptions.options.frame = new Rectangle(
      $event.value,
      this.autoStackOptions.options.frame.getLength()
    );
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
  }

  setFrameLength($event) {
    this.autoStackOptions.options.frame = new Rectangle(
      this.autoStackOptions.options.frame.getWidth(),
      $event.value
    );
    this.optionPages = this.magicStackService.toOptionPages(
      this.autoStackOptions.options,
      PATTERNS_PER_PAGES
    );
  }
}
