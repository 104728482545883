import * as THREE from 'three';
import { ResourceTracker } from '../../../utils/resource-tracker';

/**
 * A scene that allows tracking all the objects being added
 */
export class TrackingScene extends THREE.Scene {
  resources = new ResourceTracker();

  shadowsEnabled = false;

  add(...objects: THREE.Object3D<THREE.Event>[]): this {
    objects.forEach((obj) => this.resources.track(obj));
    super.add(...objects);
    return this;
  }

  /*enableShadows(enabled: boolean): void {
    this.shadowsEnabled = enabled;
    /*if (this.shadowsEnabled) {
      this.children.forEach((obj) => ThreeUtils.enableShadows(obj));
    }*
  }*/

  remove(...objects: THREE.Object3D<THREE.Event>[]): this {
    objects.forEach((obj) => this.resources.untrack(obj));
    super.remove(...objects);
    return this;
  }

  dispose(): void {
    this.resources.dispose();
  }
}
