<!-- 
  Setting display: block on the canvas ensures that it will only take up
  the space specified by its width and height. Without it, canvas is an inline
  element with uses slightly more vertical space. Resulting in a "resizing" loop.
  
  Setting the property directly as a style attribute to ensure it is applied as
  soon as possible.
-->
<canvas #canvas style="display: block"></canvas>

<!-- Needs to be below canvas in the DOM tree, otherwise canvas invisibly covers it-->
<app-three-perspective
  *ngIf="choosePerspective"
  style="position: absolute; right: 0.5em"
></app-three-perspective>

<mat-progress-spinner
  *ngIf="loading$ | async"
  color="#D81B60DE"
  mode="indeterminate"
  [diameter]="35"
></mat-progress-spinner>
