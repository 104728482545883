<div class="table-expandable-wrapper">
  <div class="top-row" *ngIf="topRow">
    <h3>{{ tableName }}</h3>
  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
    <ng-container
      *ngFor="let column of columnsToDisplay"
      matColumnDef="{{ column }}"
    >
      <!-- Header/Columns: -->
      <ng-container *ngIf="column === 'select'">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngIf="column === ' '">
        <th mat-header-cell *matHeaderCellDef class="graphic-column"></th>
      </ng-container>
      <ng-container
        *ngIf="column !== ' ' && column !== 'avgPpm' && column !== 'buttons'"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="column === 'progress' ? 'graphic-column' : ''"
          (click)="expandedElement = null"
        >
          {{
            shouldDoTitleCase
              ? (column.replaceAll('_', ' ') | titlecase)
              : column.replaceAll('_', ' ')
          }}
        </th>
      </ng-container>
      <ng-container *ngIf="column === 'buttons'">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container *ngIf="column === 'avgPpm'">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (click)="expandedElement = null"
        >
          Avg. time per pallet
        </th>
      </ng-container>
      <!-- Rows: -->
      <td
        mat-cell
        *matCellDef="let element"
        class="generic-row-cell"
        [class.strictFirstColWidth-50]="strictFirstColWidth"
        (click)="expandedElement = expandedElement === element ? null : element"
      >
        <!-- Dropdown -->
        <ng-container *appIfCommandAvailable="'expand_simulation'">
          <ng-container *ngIf="column === ' '; else rowItem">
            <mat-icon style="vertical-align: middle">
              {{
                expandedElement === element ? 'arrow_drop_down' : 'arrow_right'
              }}
            </mat-icon>
          </ng-container>
        </ng-container>
        <ng-template #rowItem>
          <ng-container *ngFor="let rowItem of element.items">
            <ng-container *ngIf="column === rowItem.label">
              <ng-container *ngIf="!rowItem.link_id; else rowItemLink">
                <ng-container *ngIf="rowItem.label === 'progress'">
                  <!-- Progress spinner -->
                  <div class="progress-spinner">
                    <mat-progress-spinner
                      style="overflow: unset"
                      [mode]="'determinate'"
                      [value]="rowItem.value"
                      [diameter]="35"
                    >
                    </mat-progress-spinner>
                    <span
                      class="progress-spinner-text"
                      [style.font-size.px]="10"
                      [style.color]="'#6A6C74'"
                    >
                      {{ rowItem.value || '~'
                      }}{{ rowItem.value ? rowItem.unit : '' }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="rowItem.label !== 'progress'">
                  <!-- Text/Error hint -->
                  <div
                    [ngClass]="
                      column === 'name' && element.error ? 'error' : ''
                    "
                  >
                    <ng-container *ngIf="column === 'name' && element.error">
                      <mat-icon>error</mat-icon>
                      &nbsp;
                    </ng-container>

                    <ng-container [ngSwitch]="rowItem.label">
                      <span *ngSwitchCase="'updated_at'">
                        {{
                          timeUtils.getFormattedDate(rowItem.value, '2-digit')
                        }}
                      </span>
                      <span *ngSwitchCase="'created_at'">
                        {{
                          timeUtils.getFormattedDate(rowItem.value, '2-digit')
                        }}
                      </span>
                      <span *ngSwitchCase="'name'">
                        {{ rowItem.value }}
                      </span>
                      <span *ngSwitchCase="'status'">
                        {{ (rowItem.value | removeLodash) || '~' }}
                      </span>
                      <span *ngSwitchDefault>
                        {{ rowItem.value || '~' }}
                      </span>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #rowItemLink>
                <!-- Link -->
                <a
                  style="cursor: pointer; color: #477ff1"
                  (click)="
                    actionClicked.emit({
                      actionId: rowItem.actionId ? rowItem.actionId : -1,
                      label: column,
                      element: rowItem.link_id
                    });
                    $event.stopPropagation()
                  "
                >
                  {{ rowItem.value }}
                </a>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- Row Options -->
          <ng-container *ngIf="column === 'buttons'">
            <div class="button-container">
              <ng-container *ngFor="let rowButton of element.rowButtons">
                <!-- Buttons -->
                <a
                  style="
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  (click)="$event.stopPropagation()"
                  [routerLink]="rowButton.hrefLink"
                >
                  <button
                    color="primary"
                    mat-raised-button
                    *appIfCommandAvailable="
                      rowButton.roleAction
                        ? rowButton.roleAction
                        : 'default_action'
                    "
                    (click)="
                      actionClicked.emit({
                        actionId: rowButton.actionId ? rowButton.actionId : -1,
                        label: rowButton.action,
                        element: rowButton.link_id ?? element['id']
                      })
                    "
                    [disabled]="!rowButton.enabled"
                  >
                    <div
                      style="
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        white-space: nowrap;
                      "
                    >
                      <mat-icon *ngIf="rowButton.icon">{{
                        rowButton.icon
                      }}</mat-icon>
                      {{ rowButton.label }}
                    </div></button
                  >&nbsp;&nbsp;
                </a>
              </ng-container>
              <mat-icon
                [matMenuTriggerFor]="actionsMenu"
                (click)="$event.stopPropagation()"
                style="cursor: pointer"
                >more_vert</mat-icon
              >
              <mat-menu #actionsMenu="matMenu">
                <ng-container *ngFor="let rowAction of element.rowActions">
                  <!-- Actions -->
                  <ng-container *ngIf="rowAction">
                    <ng-container
                      *appIfCommandAvailable="
                        rowAction.roleAction
                          ? rowAction.roleAction
                          : 'default_action'
                      "
                    >
                      <ng-container *ngIf="rowAction.label !== 'hr'; else hr">
                        <ng-container
                          *ngIf="rowAction.hrefLink; else ordinaryButtonAction"
                        >
                          <a
                            [routerLink]="rowAction.hrefLink"
                            class="menu-item-link"
                          >
                            <button
                              [style.color]="rowAction.txtColor"
                              [style.background-color]="rowAction.bgColor"
                              [disabled]="rowAction.disabled"
                              mat-menu-item
                            >
                              <div
                                style="
                                  display: flex;
                                  align-items: center;
                                  flex-direction: row;
                                "
                              >
                                <ng-container *ngIf="rowAction.icon">
                                  <mat-icon
                                    [style.color]="rowAction.txtColor"
                                    >{{ rowAction.icon }}</mat-icon
                                  >
                                </ng-container>

                                {{ rowAction.label }}
                              </div>
                            </button>
                          </a>
                        </ng-container>

                        <ng-template #ordinaryButtonAction>
                          <button
                            [style.color]="rowAction.txtColor"
                            [style.background-color]="rowAction.bgColor"
                            [disabled]="rowAction.disabled"
                            mat-menu-item
                            (click)="
                              actionClicked.emit({
                                actionId: rowAction.actionId,
                                label: rowAction.label,
                                element: rowAction.actionParam
                                  ? rowAction.link_id
                                  : element['id']
                              })
                            "
                          >
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                              "
                            >
                              <ng-container *ngIf="rowAction.icon">
                                <mat-icon [style.color]="rowAction.txtColor">{{
                                  rowAction.icon
                                }}</mat-icon>
                              </ng-container>

                              {{ rowAction.label }}
                            </div>
                          </button>
                        </ng-template>
                      </ng-container>
                    </ng-container>

                    <ng-template #hr>
                      <hr style="margin: 5px 0" />
                    </ng-template>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </div>
          </ng-container>
        </ng-template>
      </td>
    </ng-container>

    <!-- Expandable row details -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="expandable-details"
          [@detailExpand]="
            expandedElement
              ? element.id === expandedElement['id']
                ? 'expanded'
                : 'collapsed'
              : 'collapsed'
          "
        >
          <div class="row">
            <app-highlighted-message-card
              style="width: 100%"
              *ngIf="element.error"
              [type]="'error'"
              [title]="'Error'"
              [message]="element.error"
            ></app-highlighted-message-card>

            <div class="row detail-section" style="width: 100%; padding: 1em 0">
              <!-- Expanded content left-side -->
              <div
                [ngClass]="element.expandableContent.leftContentWidth"
                style="padding: 10px 20px 0 20px"
              >
                <!-- simulation -->
                <div
                  class="image-container"
                  *ngIf="!element.expandableContent.headerLeft"
                >
                  <ng-container
                    *ngIf="
                      element.expandableContent?.graphicalResources;
                      else noSceneImage
                    "
                  >
                    <img
                      class="scene-image"
                      [src]=" element.id === expandedElement?.['id'] ? element.expandableContent.graphicalResources : fallbackSceneImage"
                    />
                  </ng-container>
                  <ng-template #noSceneImage>
                    <img class="scene-image" [src]="fallbackSceneImage" />
                  </ng-template>
                </div>

                <!-- donut chart -->
                <ng-container *ngIf="element.expandableContent.headerLeft">
                  <ng-container
                    *ngIf="
                      element.expandableContent.headerLeft.type === 'donut'
                    "
                  >
                    <div class="expanded_left">
                      <div class="donut">
                        <app-donut-chart
                          [chartData]="
                            element.expandableContent.headerLeft.value
                          "
                          [displayCounter]="true"
                        ></app-donut-chart>
                      </div>

                      <div class="content">
                        <h1>Project</h1>
                        <div class="name">
                          {{ element.expandableContent.headerLeft.label }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <!-- Expanded content right-side -->
              <div
                class="row left-alignment"
                [ngClass]="
                  getRightColumnWidth(
                    element.expandableContent.leftContentWidth
                  )
                "
              >
                <ng-container
                  *ngFor="let expandableItem of element.expandableContent.items"
                >
                  <ng-container *ngIf="expandableItem.title; else infoFields">
                    <div class="col-12">
                      <p
                        style="
                          font-size: small;
                          font-size: small;
                          color: #6c6c6c;
                          margin-bottom: 0;
                        "
                      >
                        {{ expandableItem.title }}
                      </p>
                      <h3 *ngIf="expandableItem.label">
                        {{ expandableItem.label }}
                      </h3>
                    </div>
                  </ng-container>
                  <ng-template #infoFields>
                    <!-- two column grid content -->
                    <ng-container
                      *ngIf="expandableItem.type === 'two_column_text'"
                    >
                      <div class="col-6">
                        <p style="font-weight: 500">
                          {{ expandableItem.label }}:
                        </p>
                      </div>
                      <div class="col-6">
                        <p>
                          {{ returnValue(expandableItem.value) }}
                          {{
                            expandableItem.value && expandableItem.unit
                              ? expandableItem.unit
                              : ''
                          }}
                        </p>
                      </div>
                    </ng-container>

                    <!-- chip content -->
                    <ng-container *ngIf="expandableItem.type === 'chip'">
                      <div class="chip">
                        <div class="header-text">
                          {{ expandableItem.label }}
                        </div>
                        <mat-chip-listbox
                          *ngIf="expandableItem.value.length > 0"
                        >
                          <div>
                            <mat-chip-option
                              *ngFor="let item of expandableItem.value"
                              (click)="
                                actionClicked.emit({
                                  actionId: expandableItem.action.actionId,
                                  element: item.id
                                });
                                $event.stopPropagation()
                              "
                              selected
                              [ngStyle]="{
                                'background-color': expandableItem.color
                              }"
                            >
                              {{ item.name }}
                            </mat-chip-option>
                          </div>
                        </mat-chip-listbox>
                        <div
                          style="color: rgba(7, 10, 23, 0.4)"
                          *ngIf="expandableItem.value.length === 0"
                        >
                          None Selected
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-table-data" colspan="4">
        <span>{{ noDataText }}</span>
        <button
          *ngIf="shouldShowButtonToCreateIfNoData"
          (click)="this.didPressCreate.emit()"
          class="linkButton"
        >
          Create one?
        </button>
      </td>
    </tr>
  </table>
  <mat-paginator *ngIf="showPaginator"></mat-paginator>
</div>
