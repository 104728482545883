import { Box } from '@rocketfarm/packing-webcomponents';
import { AxisDirection } from '@rocketfarm/cartesian-rectangle';
import { GripperOrientation } from '../enums/gripper-orientation';
import {
  gripperRotation,
  locked,
  stopMultigrip,
} from 'src/assets/icons/box-extra-svgs';

/**
 * EditorBox extends Box and makes creation of custom SVG for each box easier, given labelDirection, and boxFrontDirections
 * @param labelDirection is the box direction having a label. The direction is relative to box rotation
 * This property can not be set for individual boxes
 * @param boxFrontDirections is an array of box directions to be displayed.
 */
export class EditorBox extends Box {
  private labelColor = 'rgba(255, 255, 255, 1)';
  private boxFrontDirectionColor = 'rgba(102, 102, 102, 1)';
  private boxFrontDirectionWidth = 4;

  constructor(
    private box: Box,
    private boxFrontDirections: AxisDirection[],
    private labelDirection?: number,
    private groupId?: number,
    public gripperOrientations?: GripperOrientation[],
    public stopMultigripFlag?: boolean
  ) {
    super(
      box.getId(),
      box.getRectangleWidth(),
      box.getRectangleLength(),
      box.getXMin(),
      box.getYMin(),
      boxFrontDirections[0]
    );
  }

  /**
   * Rendering order is based on document order. Ex. Arrows should be rendered after labels
   */
  private getInnerBox(
    rectangleWidth: number,
    rectangleLength: number,
    boxFrontDirections: AxisDirection[]
  ) {
    const svgItems = [];
    boxFrontDirections.forEach((direction, index) => {
      if (this.labelDirection !== null) {
        const label =
          index > 0 ? (this.labelDirection + 2) % 4 : this.labelDirection;
        svgItems.push(
          this.getLabelSvg(
            rectangleWidth,
            rectangleLength,
            label,
            this.labelColor
          )
        );
      }
    });

    boxFrontDirections.forEach((direction, index) => {
      svgItems.push(
        this.getBoxFrontSvg(rectangleWidth, rectangleLength, index > 0)
      );
    });

    let iconsStartX = Math.floor(rectangleWidth - 20);
    const iconsStartY = Math.floor(rectangleLength - 20);

    // Add lock icon
    if (boxFrontDirections.length < 2) {
      svgItems.push(this.getLock(iconsStartX, iconsStartY));
      iconsStartX -= 25;
    }

    // Add stop multigrip icon
    if (this.stopMultigripFlag) {
      svgItems.push(this.getStopMultipleGrip(iconsStartX, iconsStartY));
      iconsStartX -= 25;
    }

    if (this.gripperOrientations && this.gripperOrientations.length) {
      svgItems.push(this.getGripperOrientation(iconsStartX, iconsStartY));
    }

    const group = this.getGroup(
      rectangleWidth,
      rectangleLength,
      this.groupId,
      super.getDirection()
    );
    if (group) {
      svgItems.push(group);
    }

    return svgItems.join('');
  }

  private getLabelSvg(
    rectangleWidth: number,
    rectangleLength: number,
    labelDirection: AxisDirection,
    labelColor: string
  ) {
    const labelWidth = Math.floor(
      Math.min(rectangleWidth, rectangleLength) / 2
    );
    const labelHeight = Math.floor(labelWidth / 2) + 15;
    switch (labelDirection) {
      case AxisDirection.Y_NEGATIVE:
        return this.getRectangle(
          labelColor,
          '',
          0,
          labelWidth,
          labelHeight,
          rectangleWidth / 2 - labelWidth / 2,
          1,
          'label'
        );
      case AxisDirection.X_POSITIVE:
        return this.getRectangle(
          labelColor,
          '',
          0,
          labelHeight,
          labelWidth,
          rectangleWidth - labelHeight - 1,
          rectangleLength / 2 - labelWidth / 2,
          'label'
        );
      case AxisDirection.Y_POSITIVE:
        return this.getRectangle(
          labelColor,
          '',
          0,
          labelWidth,
          labelHeight,
          rectangleWidth / 2 - labelWidth / 2,
          rectangleLength - labelHeight - 1,
          'label'
        );
      case AxisDirection.X_NEGATIVE:
        return this.getRectangle(
          labelColor,
          '',
          0,
          labelHeight,
          labelWidth,
          1,
          rectangleLength / 2 - labelWidth / 2,
          'label'
        );
    }
  }

  private getBoxFrontSvg(
    rectangleWidth: number,
    rectangleLength: number,
    flippedDirection: boolean
  ) {
    const arrowWidth = Math.floor(
      Math.min(rectangleWidth, rectangleLength) / 3
    );
    const arrowLength = Math.floor(arrowWidth / 2);
    const arrowPadding = 15;
    return this.getArrow(
      'none',
      this.boxFrontDirectionColor,
      this.boxFrontDirectionWidth,
      arrowWidth,
      arrowLength,
      (rectangleWidth - arrowWidth) / 2,
      flippedDirection
        ? rectangleLength - arrowLength - arrowPadding
        : arrowPadding,
      flippedDirection ? 180 : 0
    );
  }

  private getArrow(
    fill: string,
    stroke: string,
    strokeWidth: number,
    width: number,
    height: number,
    x: number,
    y: number,
    rotation: number
  ) {
    const xCenter = Math.floor(width / 2);
    const yCenter = Math.floor(height / 2);
    return `<polyline \
                    points='0,${height} ${xCenter},0 ${width},${height}' \
                    fill='${fill.toString()}' \
                    stroke='${stroke.toString()}' \
                    stroke-width='${strokeWidth.toString()}'
                    transform='translate(${x},${y}) rotate(${rotation}, ${xCenter}, ${yCenter})' />`;
  }

  private getGripperOrientation(x: number, y: number) {
    return `<g fill='${this.boxFrontDirectionColor}' \
        transform='${this.getSymbolTransform(
          x,
          y,
          super.getDirection()
        )}'>${gripperRotation}</g>`;
  }

  private getStopMultipleGrip(x: number, y: number) {
    return `<g fill='${this.boxFrontDirectionColor}' \
        transform='${this.getSymbolTransform(
          x,
          y,
          super.getDirection()
        )}'>${stopMultigrip}</g>`;
  }

  private getLock(x: number, y: number) {
    return `<g fill='${
      this.boxFrontDirectionColor
    }' transform='${this.getSymbolTransform(
      x,
      y,
      super.getDirection()
    )}'>${locked}</g>`;
  }

  private getRectangle(
    fill: string,
    stroke: string,
    strokeWidth: number,
    width: number,
    height: number,
    x: number,
    y: number,
    rectangleClass?: string
  ) {
    return `<rect class='${rectangleClass}' fill='${fill.toString()}' \
                stroke='${stroke.toString()}' \
                stroke-width='${strokeWidth.toString()}' \
                stroke-linecap='square' \
                width='${width.toString()}' \
                height='${height.toString()}' \
                x='${x.toString()}' \
                y='${y.toString()}' />`;
  }

  private getGroup(
    boxWidth: number,
    boxLength: number,
    groupId: number,
    direction: AxisDirection
  ) {
    if (!isNaN(groupId)) {
      return `<g transform='${this.getGroupTransform(
        boxWidth,
        boxLength,
        direction
      )}'> \
                        <text \
                            text-anchor='middle' \
                            fill='${this.boxFrontDirectionColor}' \
                            transform='rotate(180) scale(-2,2)'> \
                            ${(groupId + 1).toString()} \
                        </text>\
                     </g>`;
    }
    return null; // not found
  }

  private getSymbolTransform(x: number, y: number, direction: AxisDirection) {
    let r;
    switch (direction) {
      case AxisDirection.Y_NEGATIVE:
        r = 180;
        break;
      case AxisDirection.X_NEGATIVE:
        x = x - 20;
        r = 270;
        break;
      case AxisDirection.Y_POSITIVE:
        y = y - 20;
        x = x - 20;
        r = 0;
        break;
      case AxisDirection.X_POSITIVE:
        y = y - 20;
        r = 90;
        break;
    }
    return `translate(${x} ${y}) rotate(${r})`;
  }

  private getGroupTransform(
    boxWidth: number,
    boxLength: number,
    direction: AxisDirection
  ) {
    let x = Math.floor(boxWidth / 2);
    let y = Math.floor(boxLength / 2);
    let r;
    switch (direction) {
      case AxisDirection.Y_NEGATIVE:
        y = y - 10;
        r = 0;
        break;
      case AxisDirection.X_NEGATIVE:
        x = x + 10;
        r = 90;
        break;
      case AxisDirection.Y_POSITIVE:
        y = y + 10;
        r = 180;
        break;
      case AxisDirection.X_POSITIVE:
        x = x - 10;
        r = 270;
        break;
    }
    return `translate(${x} ${y}) rotate(${r})`;
  }

  getCustomSVG() {
    return this.getInnerBox(
      this.box.getRectangleWidth(),
      this.box.getRectangleLength(),
      this.boxFrontDirections
    );
  }

  getRotations(): number[] {
    const items: number[] = [];
    this.getBoxFrontDirections().forEach((dir) => {
      items.push(dir * 90);
    });

    return items;
  }

  getBoxFrontDirections(): AxisDirection[] {
    return this.boxFrontDirections;
  }

  setBoxFrontDirections(directions: AxisDirection[]) {
    this.boxFrontDirections = directions;
  }

  /**
   * Returns Xmin + product size in x-axis (getXMin() + getWidth()).
   * Be aware that the getXMax() function returns 1 mm less(getXMin() + getWidth() - 1).
   * I believe the getXMax() function is used for drawing boxes, without them overlapping.
   */
  getXMaxReal(): number {
    return this.getXMin() + this.getWidth();
  }

  /**
   * Returns Ymin + product size in y-axis (getYMin() + getWidth()).
   * Be aware that the getYMax() function returns 1 mm less(getYMin() + getWidth() - 1).
   * I believe the getYMax() function is used for drawing boxes, without them overlapping.
   */
  getYMaxReal(): number {
    return this.getYMin() + this.getLength();
  }
}
