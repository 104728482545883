const pallyBackgroundMap = new Map([
  [
    'N/A',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #696969 0%, #969696 100%)',
    },
  ],
  [
    '2.8.0',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #EAAA7B 0%, #CA793F 100%)',
    },
  ],
  [
    '2.9.0',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #DB3D80 0%, #971C4F 100%)',
    },
  ],
  [
    '2.10.0',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #3E62AE 0%, #2B4478 100%)',
    },
  ],
  [
    '2.10.2',
    {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #34D592 0%, #1F9C68 100%)',
    },
  ],
]);

export function pallyVersionBackground(version: string) {
  let backgroundGradient = pallyBackgroundMap.get(version);
  if (!backgroundGradient) {
    backgroundGradient = {
      background:
        'radial-gradient(100% 115.7% at 0% 0%, #ae3e3e 0%, #782b2b 100%)',
    };
  }
  return backgroundGradient;
}
