import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Unit } from '../models_new/types/unit';

@Pipe({
  name: 'units',
})
export class UnitConversionPipe implements PipeTransform {
  constructor() {}

  transform(value: number, name?: string, unit?: Unit): Observable<number> {
    if (value) {
      const countDecimals = function (val: number) {
        if (Math.floor(val) === val) {
          return 0;
        }
        return val.toString().split('.')[1].length || 0;
      };

      value = this.format(value, name, unit);

      if (countDecimals(value) > 2) {
        value = +value.toFixed(2);
      }
    }

    return of(+value);
  }

  format(value: number, name: string, unit?: Unit): number {
    if (!unit) {
      unit = {
        value: 'metric',
        name: 'Metric: mm / g',
        suffixLW: 'mm',
        suffixW: 'g',
        divideMM: 1,
        divideG: 1,
      };
    }

    if (unit && unit.value !== 'metric' && name !== 'weight') {
      value = value / unit.divideMM;
    }
    if (unit && unit.value !== 'metric' && name === 'weight') {
      value = value / unit.divideG;
    }

    // No decimal places when 'metric' units
    if (unit && unit.value === 'metric') {
      value = Math.ceil(value);
    }

    // No decimal places when no units
    if (!unit) {
      value = Math.ceil(value);
    }

    return value;
  }

  deFormat(value: number, unit: Unit, name: string): number {
    if (unit && unit.value !== 'metric' && name !== 'weight') {
      value = +value * unit.divideMM;
    }

    if (unit && unit.value !== 'metric' && name === 'weight') {
      value = +value * unit.divideG;
    }

    return value;
  }
}
