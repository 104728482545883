<!-- -------------------- COPY as much as possible from THREE-VIEW-COMPONENT --------------------- -->

<!-- 
  Setting display: block on the canvas ensures that it will only take up
  the space specified by its width and height. Without it, canvas is an inline
  element with uses slightly more vertical space. Resulting in a "resizing" loop.
  
  Setting the property directly as a style attribute to ensure it is applied as
  soon as possible.
-->
<canvas #canvas class="product-view-styling"> </canvas>

<!-- Allows debug rendering status component to display-->
<div id="anchor-wrapper">
  <div class="anchor" style="top: 0em; left: 0em">
    <ng-content select="[anchorTopLeft]"></ng-content>
  </div>
  <div class="anchor" style="top: 0em">
    <ng-content select="[anchorTop]"></ng-content>
  </div>
  <div class="anchor" style="top: 0em; right: 0em">
    <ng-content select="[anchorTopRight]"></ng-content>
  </div>
  <div class="anchor" style="right: 0em">
    <ng-content select="[anchorRight]"></ng-content>
  </div>
  <div class="anchor" style="left: 0em">
    <ng-content select="[anchorLeft]"></ng-content>
  </div>
  <div class="anchor" style="bottom: 0em; left: 0em">
    <ng-content select="[anchorBottomLeft]"></ng-content>
  </div>
  <div class="anchor" style="bottom: 0em">
    <ng-content select="[anchorBottom]"></ng-content>
  </div>
  <div class="anchor" style="bottom: 0em; right: 0em">
    <ng-content select="[anchorBottomRight]"></ng-content>
  </div>
  <ng-content></ng-content>
</div>

<div *ngIf="!hideNav" id="view_actions" style="height: 72px">
  <mat-icon
    matRipple
    (click)="placeCamera('front')"
    matTooltip="Show front side"
    style="border-radius: 50%; grid-area: 3 / 2 / 4 / 3"
    >expand_less</mat-icon
  >
  <mat-icon
    matRipple
    (click)="placeCamera('right')"
    matTooltip="Show right side"
    style="border-radius: 50%; grid-area: 2 / 3 / 3 / 4"
    >chevron_left</mat-icon
  >
  <mat-icon
    matRipple
    (click)="placeCamera('back')"
    matTooltip="Show back side"
    style="border-radius: 50%; grid-area: 1 / 2 / 2 / 3"
    >expand_more</mat-icon
  >
  <mat-icon
    matRipple
    (click)="placeCamera('left')"
    matTooltip="Show left side"
    style="border-radius: 50%; grid-area: 2 / 1 / 3 / 2"
    >chevron_right</mat-icon
  >
  <mat-icon
    matRipple
    (click)="placeCamera('default')"
    matTooltip="Reset camera"
    style="border-radius: 50%; grid-area: 2 / 2 / 3 / 3"
    >view_in_ar</mat-icon
  >
</div>

<div *appVar="loading$ | async as loading" class="product-view-styling">
  <app-three-state
    class="product-view-styling"
    [state]="loading$ | async"
    [contextLost]="renderingService.contextLost"
    (clickedRestore$)="renderingService.tryRestoreContext()"
  ></app-three-state>
</div>
