<mat-toolbar class="main-toolbar" color="primary" id="main-toolbar">
  <a routerLink="/">
    <img [src]="publicApi.mrcLogoWhite" alt="MyRobot.cloud logo" />
  </a>
  <span class="toolbar-spacer"></span>

  <ng-container *ngIf="!(auth.isAuthenticated$ | async); else loggedIn">
    <button
      mat-button
      color="accent"
      (click)="auth.loginWithRedirect()"
      [appAnalytics]="'Home - MyRobot.cloud	- Log In CTA - Click'"
    >
      LOG IN
    </button>
    <button
      mat-raised-button
      color="accent"
      (click)="toSignup()"
      [appAnalytics]="'Home - MyRobot.cloud	- Sign Up CTA - Click'"
    >
      SIGN UP
    </button>
  </ng-container>

  <ng-template #loggedIn>
    <button mat-raised-button color="accent" (click)="toDashboard()">
      DASHBOARD
    </button>
    <button
      mat-button
      color="accent"
      (click)="
        auth.logout({
          logoutParams: {
            returnTo: logoutPath
          }
        })
      "
    >
      SIGN OUT
    </button>
  </ng-template>
</mat-toolbar>
