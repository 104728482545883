import * as THREE from 'three';

/**
 * A simple line indicator.
 */
export class DimensionIndicator extends THREE.Line {
  start: THREE.Vector3;
  end: THREE.Vector3;

  constructor(
    start: THREE.Vector3,
    end: THREE.Vector3,
    color: number | THREE.Color,
    width = 3
  ) {
    const points = [start, end];
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = new THREE.LineBasicMaterial({ color, linewidth: width });
    super(geometry, material);

    this.start = start;
    this.end = end;
  }

  update(start: THREE.Vector3 = this.start, end: THREE.Vector3 = this.end) {
    const points = [start, end];
    (this as THREE.Line).geometry = new THREE.BufferGeometry().setFromPoints(
      points
    );
  }

  setStart(start: THREE.Vector3) {
    this.update(start, this.end);
    this.start = start;
  }

  setEnd(end: THREE.Vector3) {
    this.update(this.start, end);
    this.end = end;
  }
}
