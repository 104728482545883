<ng-container *ngIf="viewReady">
  <div class="select-pattern-card-wrapper">
    <mat-card
      *ngFor="let option of optionPages[pageIndex]; let optionIndex = index"
      class="select-card"
      (click)="
        selectPattern(pageIndex, optionIndex); selectedCard = optionIndex
      "
      [ngClass]="
        selectedCard === optionIndex ? 'card-selected' : 'card-not-selected'
      "
    >
      <mat-card-content>
        <app-pallet-canvas [frame]="frame" [boxes]="option"></app-pallet-canvas>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
